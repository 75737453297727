<div class="preload-logalty">
  <div class="inner">
    <div class="container-center">
      <div class="preload">
        <h2>Estás a un paso para contratar tu cobertura de EVO Protect Deportes</h2>
        <img src="assets/images/preload.gif">
        <div class="container-info">
          <div class="texto" *ngFor="let slide of slides; let i = index" [ngClass]="setSlideClass(i)">
            {{ slide }}
          </div>
          <div class="bullets">
            <div *ngFor="let slide of slides; let i = index" class="step" [ngClass]="selected == i ? 'on': 'off'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
