export class ResponseOTP{
  grant_type: string; //rsi,
  access_token: string; //82017f34-d3ea-4dc8-a3a3-eadb97651c4e,
  token_type: string; //Bearer,
  expires_in: string; //3600,
  refresh_token: string; //a1342b78-63a5-4594-a1f6-08ed974176af,
  scope: string; //rsi
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
