<header-profile></header-profile>
<div class="simulador">
  <div class="image thin-border"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>CUENTA DUO, PORQUE COMPARTIR ES VIVIR</h2>
        <p></p>
      </legend>
    </div>
    <div class="main-content">
      <div class="col-8">
        <div class="row info">
          <div class="item">
            <div class="text-info">
              <h6>SIN COMISIONES</h6>
              <div>CUENTA CORRIENTE</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info">
                <h6>ALERTAS GRATIS</h6>
                <div></div>
            </div>
          </div>
          <div class="item">
            <div class="text-info">
              <h6>TARJETAS DE DÉBITO GRATUITAS</h6>
              <div>UNA POR TITULAR</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info">
              <h6>TRANSFERENCIAS PERIÓDICAS GRATIS</h6>
              <div></div>
            </div>
          </div>
          <div class="right-item">
              <div class="text-info">
                 <h6>En 5 minutos puedes disfrutar de todas las ventajas de la CUENTA DUO ¿Comenzamos?</h6>
              </div>
              <div class="row">
                <div class="mobile-container center mobile">
                  <button class="btn-common btn-common_fix_bold" (click)="submit()">
                    Abrir una cuenta duo
                  </button>
                </div>
              </div>
              
            </div>
        </div>
        <!--
        <div class="row">
          <div class="video-container">
            <div *ngIf='showPlay' 
              (click)="hidePlayer();playVid()" 
              class="video" [ngStyle]="{'background':'url('+poster+')'}">
            </div>
            <div *ngIf='!showPlay' class="player-container">
              <iframe height="330"
              src="https://www.youtube.com/embed/Ge6vFzSZ1WU?autoplay=1&controls=0&rel=0">
              </iframe>
            </div>
          </div>
        </div>
      -->
      </div>
      <!--
      <div class="col-4">
        <div class="item">
          <div class="text-info">
             <h6>En 5 minutos puedes disfrutar de todas las ventajas de la CUENTA DUO ¿Comenzamos?</h6>
          </div>
        </div>
      </div> -->
    </div>
    <div class="req">
      <div class="text-req">
          Sabemos que convivir es una suerte. Y como en EVO queremos que sea lo más fácil posible, <span>ahora presentamos la CUENTA DUO</span>. Una cuenta para compartir y controlar los gastos y recibos de una manera sencilla, y sobre todo, con quien tú quieras.

          Para poder disfrutar de la CUENTA DUO sin que te cueste ni un euro, solo será necesario que al menos uno de los titulares mantenga una Cuenta Inteligente ¡Así de fácil!
          
          Por favor, <span>asegúrate de que la persona con la que quieres compartirla no es titular ya de otra CUENTA DUO</span>. Si es así, nuestra política de admisión de clientes no permitirá incluirla.
      </div>
      <div class="text-list">
        <h6>SI QUIERES SABER MÁS SOBRE LA CUENTA DUO</h6>
        <ul>
          <li>Tarifas de la Cuenta Duo <a>Descargar PDF</a></li>
          <li>Contrato de la Cuenta Duo  <a>Descargar PDF</a></li>
          <li>Condiciones Generales de la Cuenta Duo  <a>Descargar PDF</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
