import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'message-iban-load',
  templateUrl: './message-iban-load.component.html',
  styleUrls: ['./message-iban-load.component.scss']
})
export class MessageIbanLoadComponent   {


  mensaje = "No actualices ni cierres el navegador mientras estamos en ello, enseguida continuamos con tu contratación ;-)";


}
