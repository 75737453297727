import { Directive, ElementRef,EventEmitter, OnChanges} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[number-input]'
})

export class NumberInputDirective {
  ctrlDown = false;
  constructor(
    private elementRef: ElementRef,
    private control : NgControl
  ) {
    this.elementRef.nativeElement.addEventListener('keydown', this.onKeyDown.bind(this));
    this.elementRef.nativeElement.addEventListener('keyup', this.onKeyUp.bind(this));
    this.elementRef.nativeElement.addEventListener('paste', this.onPaste.bind(this));
  }

  // onPaste(e) {
  //   // this.validateString(e);
  // }

  onKeyDown(e) {
    this.validateNumber(e);
  }

  onPaste(e) {
    e.preventDefault()
    const pastedString = e.clipboardData.getData('text/plain')
    const isNumber = /^\d+$/;
    let validatedString : string = '';
    for(let i = 0; i < pastedString.length; i++) {
      let currentChar = pastedString.charAt(i);

      if(currentChar.match(isNumber)) {
         validatedString += currentChar;
      }
    }
    e.target.innerHtml = validatedString;
    return this.control.control.setValue(validatedString);
  }

  onKeyUp(e){
    this.ctrlPressed(e);
  }

  ctrlPressed(e){
    const ctrlKey = 17,
      cmdKey = 91;
    if (e.keyCode == ctrlKey || e.keyCode == cmdKey) this.ctrlDown = false;
  }

  validateNumber(e) {
    const ctrlKey = 17,
      cmdKey = 91,
      vKey = 86,
      cKey = 67,
      lArraw = 37,
      rArraw = 39,
      tab = 9,
      backspace = 8;
    const exceptions = [cmdKey, ctrlKey, backspace, tab, lArraw, rArraw],
          regEx = new RegExp('^[0-9]*$'),
          pressedKey = e.key,
          keyCode = e.keyCode;
    if(e.keyCode == ctrlKey || e.keyCode == cmdKey){
       this.ctrlDown = true;
    }
    if (this.ctrlDown && (e.keyCode == vKey || e.keyCode == cKey)) return;
    if ((exceptions.indexOf(keyCode) !== -1)) return;
    if(!regEx.test(pressedKey))  {
      e.preventDefault()
    }
  }
}
