import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Persona } from '../../collections/persona';
import { Observable } from 'rxjs';
import { ErrorService } from '../error.service';
import { HeaderService } from '../headers.service';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiSecondHolderService {
  options = {};
  constructor(
    private http: HttpClient,
    public errService: ErrorService,
    private headerSrv: HeaderService) {
    this.options = { withCredentials: true, observe: 'response' };
  }

  // API: POST /holders
  public createSecondHolder(holder) {
    return this.http
      .post(API_URL + '/fullonline/salesforce/personainfo/v1/miperfil', holder, this.options)
      .pipe(map(response =>  response['body']));
  }

}
