import { Component, OnInit, Input } from '@angular/core';
import { States } from 'app/collections/states-card';

@Component({
  selector: 'states-card',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss']
})

export class StatesComponent implements OnInit {

  @Input('stateCurrent') stateCurrent;
  public states = States;

  constructor() { }

  ngOnInit() {
  }

  setClassSelected(state) {
    return this.stateCurrent === state ? 'selected' : this.setActivatedColor(state) ? 'before-selected' : '';
  }

  setActivatedColor(state) {
    let activated = false;
    switch (state) {
      case States.solicitada:
        activated = this.stateCurrent === States.creada || this.stateCurrent === States.enviada || this.stateCurrent === States.recibida;
        break;

      case this.states.creada:
        activated = this.stateCurrent === States.enviada || this.stateCurrent === States.recibida;
        break;

      case this.states.enviada:
        activated = this.stateCurrent === States.recibida;
        break;
    }

    return activated;

  }
}
