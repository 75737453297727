<app-header></app-header>
<div class="error-less-25">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <span class="main-icon evo error-computer"></span>
        <h2>¡VAYA!</h2>
        <h3>LA CUENTA JOVEN ES PARA MENOS DE 25<span *ngIf='name'>, {{name}}</span></h3>
      </legend>
    </div>
    <div class="row">
      <p>
        Según tu DNI/NIE tienes más de 25 años. Tu producto es la CUENTA INTELIGENTE con muchas ventajas y sin límite de edad.
      </p>
    </div>
    <div class="btn-group">
      <button type="button" class="btn-common light btn-common_fix_bold" (click)='goToLanding()'>SALIR</button>
      <button type="button" class="btn-common btn-common_fix_bold" (click)='goToCI()'>IR A LA CUENTA INTELIGENTE</button>
    </div>
  </div>
</div>
