import { Component, OnInit, Input } from '@angular/core';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MyProductsService } from 'app/services/my-products.service';
import { LoginService } from 'app/services/login.service';
import { AuthService } from 'app/services/auth.service';
import { AuthUserService } from 'app/services/auth-user.service';
import { SecondHolderService } from 'app/services/second-holder.service';
import { HelperService } from 'app/services/helpers.service';
import { ModalService } from 'app/services/modal.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { PersonaService } from 'app/services/persona.service';
import { AmpliacionService } from 'app/services/ampliacion.service';
import { ApiUserService } from 'app/services/api/api.user.service';
import { SecurityService } from 'app/services/security-registration.service';
import { DeviceService } from 'app/services/device.service';
import { environment } from 'environments/environment';
import { SpecificAnalyticsService } from 'app/services/specific-analytics.service';
import { Observable } from 'rxjs';
import { User } from 'app/collections/user';
import { AuthTokenService } from 'app/services/token/auth.token.service';

@Component({
  selector: 'extend-limit-step1',
  templateUrl: './extend-limit-step1.component.html',
  styleUrls: ['./extend-limit-step1.component.scss'],
  providers: [ModalService]
})

export class ExtendLimitStep1Component implements OnInit {

  @Input() error: string;

  public clientID;
  public loading = false;
  public myLimit: any = 300;
  public disabledRange;
  public tarjetaSeleccionada;
  public limSolicitado;
  public lastDig;
  public limCredito: any = 300;
  public casoEnVuelo: boolean = false;
  public mostrarSubmit: boolean = false;
  public mostrarModificar: boolean = false;
  public nameError: string = '';
  public showError: boolean = false;
  public showValidar: boolean = false;
  public showPeticionRecibida: boolean = false;
  public param;
  public idCaso;
  public mostrarCheckLimite: boolean = true;
  public mostrarLimiteCero: boolean = true;
  public mostrarCuotaFija: boolean = false;
  public mostrarLimiteTemporal: boolean = false;
  public mostrarLimiteTemporalSlider: boolean = false;
  public porcentajeLimit: number = 0;
  public params: any;
  //limiteForm: FormGroup;
  public isParam: boolean = false;
  public puntoAbandono;


  stateCurrent = States.enviada;
  currentProduct: any;
  fullName: string;
  address: string;
  ACCOUNTS = ACCOUNTS;
  botonVolver: any;
  loadCustom = false;
  titulo = "ESTAMOS VALIDANDO TU PETICIÓN";
  imagen = "./assets/images/6_Rocket.gif";
  subtitulo = "¡No toques nada que ya casi estamos!";
  boton = '';

  public tarjetasBloqueadas: Array<any> = [];
  public tarjetasActivas: Array<any> = [];

  public bttModificarActive: boolean = false;
  public bttCancelarActive: boolean = false;
  public checkReducir;
  public minValue = 300;
  public maxValue = 6000;

  public cambioTarjetasActive: boolean = false;
  public showHeader: boolean = true;

  public limitChanged: boolean = false;
  public invalidLimit: boolean = false;

  public idCliente;
  public action = 'onboarding';

  public isBMI: boolean = false;

  public casoEnVueloEncontrado = false;

  public showCancelarModificar = false;

  //@ViewChild('div-show') errorShow: Hijo1Component;

  patternLimite: string = '[0-9]*';

  private creditLimitValue: string = '';
  private operacionAmpliacion: string = '';

  status: string = '';

  firmaNecesaria: boolean = false;

  origen: string = 'Desktop';

  constructor(
    //private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private secondHolderSrv: SecondHolderService,
    private loginSrv: LoginService,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    private authUserService: AuthUserService,
    private helpersService: HelperService,
    private authService: AuthService,
    private authSrv: AuthTokenService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService,
    public persona: PersonaService,
    public ampliacionService: AmpliacionService,
    private securitySrv: SecurityService,
    public apiUserService: ApiUserService,
    public deviceService: DeviceService,
    private specificAnalyticsSrv: SpecificAnalyticsService) {
    //this.createForm();
  }

  ngOnInit() {
    this.origen = this.deviceService.getDeviceExtended();
    this.ampliacionService.origen = this.origen;
    this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/Paso 01-Límite', 'creditLimit': '' });
    this.loading = true;
    this.errService.modDataError = true;
    sessionStorage.setItem('operacion-ampliacion', 'ampliacion');
    // Si incluye como parametro Uuid y usuarioBe
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      //this.isParam=true;
      const noAuthAndSSOUrl = this.authSrv.haveParamsToAutenticateByUuidAndUsuarioBE(params);


      if (params.origen) {
        if (params.origen.toLowerCase() === "bmi") {
          // Aqui recibimos Token, acuerdo y dni
          this.showHeader = false;
          this.isBMI = true;
          sessionStorage.setItem('isBMI', 'true');
          this.origen = 'Mobile';
          this.ampliacionService.origen = this.origen;
          this.gestionBmiBeToken(params);
        }
        else if (params.origen.toLowerCase() === "be" && sessionStorage.getItem("isLogin") !== "true") {
          // Aqui recibimos Token, acuerdo y dni
          this.showHeader = false;
          this.isBMI = false;
          this.origen = 'Mobile';
          this.ampliacionService.origen = this.origen;
          this.gestionBmiBeToken(params);
        }
      }
      else {
        if(noAuthAndSSOUrl){
          this.callSSO(params);
        }else{
          // Llamada al servicio para recuperar datos del cliente.
          this.obtenerDatosCliente().subscribe(data => {
            this.cargarTarjeta();
            this.changeLimit();
          }, _error => {
            this.triggerCreditLimitDataErrorNavData('KO Error servicio');
            this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
          });
        }
        
      }
    });
  }


  continuarAmpliacion() {

    this.loginSrv.comprobacionDatos();
    sessionStorage.setItem('submit', "true");

    this.showError = true;
    this.nameError = "loadingCustom";

    sessionStorage.setItem('limite-solicitado', this.myLimit);
    sessionStorage.setItem('limite-actual', this.tarjetaSeleccionada.limiteCredito);

    let limiteCero = this.disabledRange === true;
    let disminucionLimite = (this.limSolicitado < 3000 || this.limSolicitado > 3000) && parseFloat(this.tarjetaSeleccionada.limiteCredito) > parseFloat(this.myLimit);
    let ampliacionLimiteSinFirma = this.limSolicitado < 3000 && parseFloat(this.tarjetaSeleccionada.limiteCredito) < parseFloat(this.myLimit);
    let ampliacionLimiteConFirma = this.limSolicitado >= 3000 && parseFloat(this.tarjetaSeleccionada.limiteCredito) < parseFloat(this.myLimit);


    //Return early

    if (limiteCero) {
      this.firmaNecesaria = false;
      sessionStorage.setItem('operacion-ampliacion', 'disminuir0');
      this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/Aviso límite 0€', 'creditLimit': '' });
      this.showError = true;
      this.nameError = "limiteCero";
      return;
    }

    if (disminucionLimite) {
      this.firmaNecesaria = false;
      // Llamar a componente de error para mantener el limite o continuar
      sessionStorage.setItem('operacion-ampliacion', 'disminuir');
      this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/Aviso límite menor a 3.000€', 'creditLimit': 'Menor de 3K€' });
      this.showError = true;
      this.nameError = "limiteDisminucion";

      // En continuar hacer la llamada
      this.cargarTarjeta();
      this.tarjetaSeleccionada = JSON.parse(sessionStorage.getItem("tarjetaSeleccionada"));
      this.myLimit = sessionStorage.getItem('limite-solicitado');
      return;
    }

    if (ampliacionLimiteSinFirma) {
      this.firmaNecesaria = false;
      sessionStorage.setItem('operacion-ampliacion', 'ampliacion');
      this.aumentarLimite();
      return;
    }

    if (ampliacionLimiteConFirma) {
      this.firmaNecesaria = true;
      sessionStorage.setItem('operacion-ampliacion', 'ampliacion-firma');
      this.aumentarLimite();
      return;
    }

  }

  disminuirLimite() {
    if (parseInt(sessionStorage.getItem('limite-solicitado')) > 0) {
      this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/Aviso límite mayor a 300€', 'creditLimit': 'Menor de 3K€' });
    }

    let objeto: Object = {
      "canalOrigen": this.origen,
      "acuerdo": this.tarjetaSeleccionada.acuerdo,
      "limiteSolicitado": parseInt(this.myLimit),
      "limiteActual": parseInt(this.tarjetaSeleccionada.limiteCredito),
      "estado": "casi-lo-tenemos",
    };

    this.loading = true;

    this.persona.disminuirLimite(objeto).subscribe(data => {
      this.loading = false;

      if (data.codigo = "OK") {
        this.triggerExtendLimitFormData('Envio OK');
        console.log("peticion realizada", data);
        sessionStorage.setItem('origen', 'disminuir');
        this.helpers.navigateTo('/confirma-limite'); // si se hace la disminucion, mandar a confirmar limite
      } else {
        this.triggerExtendLimitFormData('Envio KO: Error en la petición a Disminuir Límite');
      }
    }, _error => {
      this.triggerExtendLimitFormData('Envio KO: Error en la petición a Disminuir Límite');
      this.triggerCreditLimitDataErrorNavData('KO Error servicio');
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }

  modificar() {

    sessionStorage.setItem('limite-solicitado', this.myLimit);
    if (this.disabledRange === true) {
      sessionStorage.setItem('operacion-ampliacion', 'disminuir0');
      this.disminuirLimite();
    }
    else {
      sessionStorage.setItem('operacion-ampliacion', 'modificar');
      this.cancelarLimite();
    }
  }

  /**
   * Cancelación Límite
   */
  anularLimite() {
    this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/Aviso cancelación', 'creditLimit': '' });
    sessionStorage.setItem('operacion-ampliacion', 'cancelar');
    this.showError = true;
    this.nameError = "limiteCancelar";
  }

  cancelarLimite() {
    this.showError = false;
    this.loadCustom = true;
    let data: Object = {
      'idCaso': sessionStorage.getItem('idCasoEnVuelo') ? sessionStorage.getItem('idCasoEnVuelo') : sessionStorage.getItem("casoSeleccionado"),
    };
    this.persona.cancelarAmpliacion(data).subscribe(data => {
      if (data.codigo === 'OK') {
        if (sessionStorage.getItem('operacion-ampliacion') === "modificar") {
          if (this.myLimit > this.tarjetaSeleccionada.limiteCredito) {
            this.aumentarLimite();
          } else {
            this.disminuirLimite();
          }
        }
        else if (sessionStorage.getItem('operacion-ampliacion') === "abandono-modificar") {
          let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
          this.router.navigateByUrl(url);
          //this.helpers.navigateTo('/ampliacion-step1?origen=abandono-modificar');
        }
        else if (sessionStorage.getItem('operacion-ampliacion') === "cancelar-status") {
          this.showError = false;
          this.nameError = "ocultarErrores";
          this.loading = false;
          this.loadCustom = false;
          let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
          if (url !== "") {
            this.router.navigateByUrl(url);
          }
          else {
            this.mostrarSubmit = true;
            this.mostrarModificar = false;
            this.showValidar = false;
          }
        }
        else {
          sessionStorage.setItem('operacion-ampliacion', 'cancelar');
          this.showError = false;
          this.loadCustom = false;
          this.nameError = "ocultarErrores";
          this.helpers.navigateTo('/confirma-limite');
        }
        // poner caso cancelacion abandono y redirigir a esta pantalla

      } else if (data.codigo === 'KO') {
        console.log("Error en la peticion a cancelaAmpliacionPut: " + data.msg);
        this.triggerCreditLimitDataErrorNavData('KO Error servicio');
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
      }
    }, error => {
      console.log("error en la peticion a ampliacionLimite");
      this.triggerCreditLimitDataErrorNavData('KO Error servicio');
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }

  /**
   *
   */
  aumentarLimite() {
    this.myLimit = sessionStorage.getItem('limite-solicitado');
    this.cargarTarjeta();
    this.abrirCasoAmpliacion();
  }

  /**
   *
   * @param pendienteRevision
   */
  abrirCasoAmpliacion() {
    let objeto: Object = {
      canalOrigen: this.origen,
      acuerdo: this.tarjetaSeleccionada.acuerdo.toString(),
      limitePrevio: this.tarjetaSeleccionada.limiteCredito,
      limiteSolicitado: sessionStorage.getItem("limite-solicitado"),
      IBAN: this.tarjetaSeleccionada.iban,
      estado: "registro",
      codigoLinea: this.tarjetaSeleccionada.codigoLinea,
      codigoGrupo: this.tarjetaSeleccionada.codigoGrupo,
      codigoGrupoDesc: this.tarjetaSeleccionada.codigoGrupoDesc,
      codigoPV: this.tarjetaSeleccionada.codigoPV,
      codigoTarifa: this.tarjetaSeleccionada.codigoTarifa
    };

    this.persona.crearCasoLimite(objeto).subscribe(data => {
      this.idCaso = data.idCaso;
      sessionStorage.setItem("casoSeleccionado", this.idCaso);

      //console.log("Peticion realizada", data);
      if (data.codigo === 'KO') {
        console.log("Peticion fallida")
        //Redirige al 404 de Modificación de datos
        this.triggerExtendLimitFormData('Envío KO: Error crearCasoLimite (comprobacionCliente)');
        this.triggerCreditLimitDataErrorNavData('KO Error servicio');
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
      } else {
        //Se hace un put añadiendole el idCaso
        this.putLimite("modificacion-datos");
      }
    }, error => {
      console.log("error en la peticion a ampliacionLimite");
      this.triggerExtendLimitFormData('Envío KO: Error crearCasoLimite (comprobacionCliente)');
      this.triggerCreditLimitDataErrorNavData('KO Error servicio');
      //Redirige al 404 de Modificación de datos
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }

  /**
   *
   * @param status
   */
  putLimite(status) {
    this.idCaso = sessionStorage.getItem("casoSeleccionado");
    this.modificarLimite(status);
  }

  /**
   *
   * @param valor
   */
  modificarLimite(status) {

    let obj: Object = {
      "estado": status,
      "idCaso": this.idCaso,
      "DRF": false,
      "obligacionesFiscales": false,
      "logaltySignatureId": "",
      "signatureId": ""
    };

    this.persona.modificarLimite(obj).subscribe(data => {

      this.showError = false;
      this.nameError = "ocultarErrores";

      //this.loading = false;
      /*Se añade la variable a la sesión y mediante el método ngOnChanges de div-show
      mostrará una u otra ventana al cliente*/

      switch (data.codigo) {
        case 'KO':
          this.triggerCreditLimitDataErrorNavData('KO Genérico');
          this.triggerExtendLimitFormData('Envío KO: KO Genérico');
          break;
        case 'OK':
          this.triggerExtendLimitFormData('Envío OK');
          this.continuarFlujo();
          break;
        default:
          this.triggerExtendLimitFormData('Envío KO: KO Genérico');
          this.triggerCreditLimitDataErrorNavData('KO Genérico');
          break;
      }
    }, _error => {
      console.log("Error en el put");
      this.triggerExtendLimitFormData('Envío KO: KO Error servicio');
      this.triggerCreditLimitDataErrorNavData('KO Error servicio');
      //Redirige al 404 de Modificación de datos
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }

  /**
   *
   */
  continuarFlujo() {
    let redirectUrl = `${environment.endpoint}#/documentacion`;
    this.helpers.goToConsola("201", this.idCaso, redirectUrl);
  }

  /**
   * Actualiza el valor del límite solicitado
   * @param limiteSolicitado
   */
  actualizaLimite(limiteSolicitado) {
    this.checkInvalidLimit();
    this.limitChanged = true;
    this.calcularPorcentaje();
    this.patternLimite = this.regexpLimite();
    this.limSolicitado = limiteSolicitado;
    if (this.limCredito === "0") {
      this.mostrarCheckLimite = false;
    }
    else if (this.limSolicitado > this.limCredito || this.limSolicitado === this.limCredito) {
      this.mostrarLimiteTemporalSlider = false;
      this.mostrarLimiteCero = false;
      this.mostrarCheckLimite = true;
      this.mostrarCuotaFija = true;
    }
    else if (this.limSolicitado < this.limCredito) {
      this.mostrarLimiteTemporalSlider = true;
      this.mostrarLimiteCero = true;
      this.mostrarCheckLimite = true;
      this.mostrarCuotaFija = false;
    }
  }

  /**
   * Comprueba si es un límite NO válido, distinto de cero y fuera del rango min-max
   */
  checkInvalidLimit() {
    this.invalidLimit = this.myLimit !== '0' && (parseInt(this.myLimit) < this.minValue || parseInt(this.myLimit) > this.maxValue);
  }

  /**
   * REduce en 50 el valor actual el valor del límite solicitado,
   * al pulsar el botón (-) siempre que no se inferior el máximo de 300
   */
  disminuirValor() {
    if (this.myLimit > 300) {
      this.myLimit = this.myLimit - 50;
    }
    this.actualizaLimite(this.myLimit);
  }

  /**
   * Incrmenenta en 50 el valor actual el valor del límite solicitado,
   * al pulsar el botón (+) siempre que no supere el máximo de 6000
   */
  aumentarValor() {
    if (this.myLimit < 6000) {
      this.myLimit = +this.myLimit + 50;
    }
    this.actualizaLimite(this.myLimit);
  }

  /**
   *
   * @param checked Marcado checkbox de reducir a cero
   */
  toggleReducirCheckbox(checked) {
    if (checked === true) {
      this.reducirLimiteSolicitadoACero();
    }
    else {
      this.resetLimiteSolicitado();
    }
  }

  /**
   * Reduce el límite solicitado a cero, deshabilita el slider y
   * muestra la advertencia en el formulario
   */
  reducirLimiteSolicitadoACero() {
    this.disabledRange = true;
    this.myLimit = 0;
    this.mostrarCuotaFija = false;
    this.mostrarLimiteTemporal = true;
    this.limitChanged = true;
  }

  /**
   * Devuelve el límite solicitado al valor actual y reactiva el slider
   */
  resetLimiteSolicitado() {
    this.myLimit = this.limCredito;
    this.calcularPorcentaje();
    this.disabledRange = false;
    this.mostrarCuotaFija = false;
    this.mostrarLimiteTemporal = false;
    this.limitChanged = false;
  }

  /**
   * Carga la pantalla de selección de tarjetas activas
   */
  cambiarTarjeta() {
    this.helpers.navigateTo('/seleccionar-tarjeta');
  }

  /**
   * Busca si ya hay previamente una tarjeta en la sesión,
   * si no es así toma la primera tarjeta operativa de la lista del cliente
   */
  cargarTarjeta() {

    if (sessionStorage.getItem('cambioTarjetasActivo') === 'true') {
      this.cambioTarjetasActive = true;
    } else {
      let numTarjetasActivas = this.tarjetasActivas.length;
      if (numTarjetasActivas > 0) {
        this.tarjetaSeleccionada = this.tarjetasActivas[0];
        if (numTarjetasActivas > 1) {
          this.cambioTarjetasActive = true;
        }
        sessionStorage.setItem('tarjetaSeleccionada', JSON.stringify(this.tarjetaSeleccionada));
      }
      else {
        this.cambioTarjetasActive = false;
      }
    }

    if (sessionStorage.getItem('tarjetaSeleccionada')) {
      this.tarjetaSeleccionada = JSON.parse(sessionStorage.getItem('tarjetaSeleccionada'));



    } else {
      this.searchFirstCard();
    }
    if (!sessionStorage.getItem('submit')) {
      this.consultarCasoTarjeta();
      this.showCard();
    }
  }

  /**Se busca la primera tarjeta operativa(no bloqueada) del cliente */
  searchFirstCard() {
    let tarjetas = JSON.parse(sessionStorage.getItem("userData")).listaTarjetas;
    //let i = 0;
    if (tarjetas.length > 0) {

      for (var i = 0; i < tarjetas.length; i++) {
        if (tarjetas[i].codigoLinea === "01") {
          if (tarjetas[i].codBloqueo === "10" || tarjetas[i].codBloqueo === "20" ||
            tarjetas[i].codBloqueo === "21" || tarjetas[i].codBloqueo === "32" ||
            tarjetas[i].codBloqueo === "33" || tarjetas[i].codBloqueo === "71" ||
            tarjetas[i].codBloqueo === "72" || tarjetas[i].codBloqueo === "80") {
            this.tarjetasBloqueadas.push(tarjetas[i]);
          }
          else {
            this.tarjetasActivas.push(tarjetas[i]);
          }
        }
      }
    }

    let numTarjetasActivas = this.tarjetasActivas.length;

    if (numTarjetasActivas > 0) {
      this.tarjetaSeleccionada = this.tarjetasActivas[0];
      if (numTarjetasActivas > 1) {
        this.cambioTarjetasActive = true;
      }
      sessionStorage.setItem('tarjetaSeleccionada', JSON.stringify(this.tarjetaSeleccionada));
    }
    else {
      this.cambioTarjetasActive = false;
    }
  }

  /**Se consulta si la tarjeta seleccionada tiene previamente casos abiertos y dependiendo de ello
   * se muestran los botones de modificar y cancelar.
   * - Si es disminución si se puede cancelar o modificar
   * - Si es para ampliación no se puede cancelar o modificar
  */
  consultarCasoTarjeta() {
    //this.casoEnVuelo = false;
    this.loading = true;
    this.mostrarSubmit = false;
    this.mostrarModificar = false;
    //Consula de solicitudes en vuelo
    this.persona.consultarCasosCliente().subscribe(data => {
      this.casoEnVueloEncontrado = false;
      if (this.tarjetaSeleccionada) {
        let acuerdo = this.tarjetaSeleccionada.acuerdo;
        // buscar dentro de data si coincide con el acuerdo de la tarjeta seleccionada
        if (data.listaCasos) {
          //console.log("Casos:", data.listaCasos);

          //si no hay casos mostrar continuar
          if (data.listaCasos.length === 0) {
            this.mostrarSubmit = true;
            this.mostrarModificar = false;
            this.showValidar = false;
            this.loading = false;
          }

          let consultaCaso: boolean = false;
          for (let i = 0; i < data.listaCasos.length; i++) {
            //console.log('i='+i);

            if (data.listaCasos[i].estado.toLowerCase() !== "cancelación cliente" && (data.listaCasos[i].tipoCaso == 201 || data.listaCasos[i].tipoCaso == 200)) {
              if (data.listaCasos[i].acuerdo == acuerdo) {
                // 200 es disminucion --> estos casos no se pueden cancelar ni modificar -> DESACTIVAR BOTONES
                sessionStorage.setItem('casoSeleccionado', data.listaCasos[i].idCaso);
                sessionStorage.setItem('codProdAmpliacion', data.listaCasos[i].tipoCaso);
                this.consultarCasoAmpliacion(data.listaCasos[i]);
                consultaCaso = true;
              }
              else {
                this.mostrarSubmit = true;
                this.mostrarModificar = false;
                this.showValidar = false;
              }
            }
            else { //si no hay casos del tipo mostrar continuar
              this.mostrarSubmit = true;
              this.mostrarModificar = false;
              this.showValidar = false;
            }
          }

          if (!consultaCaso) { //si no hay casos del tipo mostrar continuar
            this.mostrarSubmit = true;
            this.mostrarModificar = false;
            this.showValidar = false;
            this.loading = false;
          }


          //console.log('fin for casos');
          //sessionStorage.setItem('casoSeleccionado', sessionStorage.getItem('idCasoEnVuelo'));
          //sessionStorage.setItem('codProdAmpliacion', sessionStorage.getItem('tipoCasoEnVuelo'));

        } else if (data.result === "06") {
          this.mostrarSubmit = true;
          this.mostrarModificar = false;
          this.showValidar = false;
          this.loading = false;
        }
      }

    });

  }

  consultarCasoAmpliacion(casoActual) {
    this.loading = true;
    let idUser = JSON.parse(sessionStorage.getItem('userData')).persona.id_cliente_EVO;
    this.persona.consultarCasoAmpliacion(idUser, casoActual.idCaso).subscribe(data => {
      this.loading = false;
      this.actionToCase(data.datosCaso, casoActual);
      //Si se ha encontrado un caso en vuelo para mostrar dejar de recorrer la lista
      //ya que en teoría sólo debe existir uno
      if (this.casoEnVueloEncontrado) {
        sessionStorage.setItem('idCasoEnVuelo', casoActual.idCaso);
        sessionStorage.setItem('tipoCasoEnVuelo', casoActual.tipoCaso);
      } else {
        this.mostrarSubmit = true;
        this.mostrarModificar = false;
        this.showValidar = false;
      }
    }, _error => {
      this.triggerCreditLimitDataErrorNavData('KO Error servicio');
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }

  /**
   * Cancelar caso de ampliación (llamada al servicio CancelarAmpliacion)
   * @param idCaso Id del caso a cancelar
   */
  cancelarCaso(idCaso) {

    let data: Object = {
      'idCaso': idCaso,
    };

    this.persona.cancelarAmpliacion(data).subscribe(data => {
      this.loading = false;
      if (data.codigo === 'OK') {
        console.log("Caso " + idCaso + " cancelado");
      }
      else if (data.codigo === 'KO') {
        console.log("Error en la peticion a cancelaAmpliacionPut: " + data.msg);
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
      }
    }
      , _error => {
        console.log("error en la peticion a cancelaAmpliacion");
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
      });
  }

  /**
   * Obtienen los últimos 4 dígitos de la tarjeta y el límite actual de la misma,
   * si el limite tiene valor 1 quiere decir que no tiene límite asociado,
   * se establece límite cero en ese caso
   */
  showCard() {
    if (this.tarjetaSeleccionada) {
      this.lastDig = this.tarjetaSeleccionada.numTarjeta.trim().substr(-4, 4);
      this.limCredito = this.tarjetaSeleccionada.limiteCredito;
      sessionStorage.setItem('limite-actual', this.tarjetaSeleccionada.limiteCredito);
      if (this.limCredito === "1") {
        this.limCredito = "0";
      }
      if (this.limCredito === "0") {
        this.mostrarLimiteCero = false;
        this.mostrarCheckLimite = false;
      }
    }
  }

  /**
   * Se cambia el límite en función de la tarjeta
   */
  changeLimit() {
    this.mostrarLimiteTemporal = false;
    if (this.tarjetaSeleccionada) {
      this.limCredito = this.tarjetaSeleccionada.limiteCredito;
      switch (this.limCredito) {
        case '0':
          this.mostrarLimiteCero = false;
          this.mostrarCheckLimite = false;
          this.mostrarCuotaFija = false;
          break;
        case '1':
          this.limCredito = '0';
          this.myLimit = '300';
          this.mostrarCuotaFija = false;
          break;
        default:
          this.myLimit = this.limCredito;
          this.calcularPorcentaje();
          break;
      }
    }
  }

  /**Se recoge el evento que se genera en el componente div-show
   * y en función de el, se llama a un método u otro
  */
  updateComponent(event) {
    this.showError = false;
    if (event === 'cancelar') {
      this.cancelarLimite();
    }
    if (event === 'disminuir') {
      this.disminuirLimite();
    }
    if (event === 'disminuir0') {
      this.disminuirLimite();
    }
    if (event === 'ampliar') {
      this.aumentarLimite();
    }
    if (event === 'modificar') {
      this.cancelarLimite();
    }
    if (event === 'ocultarErrores') {
      this.showError = false;
      this.nameError = "ocultarErrores";
    }
  }

  calcularPorcentaje() {
    this.porcentajeLimit = (this.myLimit / 100) * 2;
    if (this.porcentajeLimit < 30) {
      this.porcentajeLimit = 30;
    }
  }

  obtenerDatosCliente(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.authUserService.getClientData().subscribe(data => {
        this.idCliente = data.persona.id_cliente_EVO;
        const dni = data.persona.idExterno;
        this.specificAnalyticsSrv.emitHashedDni(dni);
        sessionStorage.setItem("idClienteEvo", this.idCliente);
        sessionStorage.setItem("userData", JSON.stringify(data));

        let personaData = JSON.parse(sessionStorage.getItem("userData"));
        let codigo = personaData.listaContratados[0];

        this.persona.getPersonaById(personaData.persona.id_cliente_EVO, codigo.idProducto).subscribe(data => {
          this.loginSrv.userInfo = data;

          observer.next(true);
          return observer.complete();
        }, _error => {
          this.triggerCreditLimitDataErrorNavData('KO Error servicio');
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        });
      },
        _error => {
          this.errService.modDataError = true;
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        });
    });
  }

  gestionBmiBeToken(params) {
    // loguear el usuario y cargar
    this.authSrv.setToken(params.token);
    this.authSrv.setRefreshToken(params.refresh);

    this.obtenerDatosCliente().subscribe(data => {
      this.action = 'onboarding';
      this.buscarTarjetaAcuerdo(params);

    }, error => {
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }

  buscarTarjetaAcuerdo(params) {
    let tarjetas = JSON.parse(sessionStorage.getItem("userData")).listaTarjetas;
    if (tarjetas.length > 0) {

      for (var i = 0; i < tarjetas.length; i++) {
        if (tarjetas[i].codigoLinea === "01") {
          if (tarjetas[i].codBloqueo === "10" || tarjetas[i].codBloqueo === "20" ||
            tarjetas[i].codBloqueo === "21" || tarjetas[i].codBloqueo === "32" ||
            tarjetas[i].codBloqueo === "33" || tarjetas[i].codBloqueo === "71" ||
            tarjetas[i].codBloqueo === "72" || tarjetas[i].codBloqueo === "80") {
            this.tarjetasBloqueadas.push(tarjetas[i]);
          }
          else {
            this.tarjetasActivas.push(tarjetas[i]);
          }
        }
        if (tarjetas[i].acuerdo === params.acuerdo) {
          this.tarjetaSeleccionada = tarjetas[i];
          sessionStorage.setItem('tarjetaSeleccionada', JSON.stringify(this.tarjetaSeleccionada));
        }
      }
    }

    if (this.tarjetasActivas.length > 0) {
      if (this.tarjetasActivas.length > 1) {
        this.cambioTarjetasActive = true;
      }
    } else {
      this.cambioTarjetasActive = false;
    }
    this.cargarTarjeta();
    this.changeLimit();
  }

  actionToCase(datosCaso, caso) {

    let status = caso.estado.toLowerCase();
    //Los estados 'cancelación cliente', 'archivado', 'desestimado' y 'cancelado' son estados finales y no es necesario hacer nada
    //console.log('CASO: ' + status);
    let estadosCasosACancelar = ['pendiente consola', 'solicitud ampliación'];
    if (status === 'firma' && caso.puntoAbandono === 'firma') {
      estadosCasosACancelar.push(status);
    }
    if (estadosCasosACancelar.indexOf(status) !== -1) {
      sessionStorage.setItem('operacion-ampliacion', 'cancelar-status');
      this.loading = true;
      this.casoEnVueloEncontrado = false;
      this.cancelarCaso(caso.idCaso);

    } else if (caso.tipoCaso == 200) { // Disminución (200)
      this.myLimit = datosCaso.limiteSolicitado;
      this.showValidar = true;
      this.mostrarSubmit = false;
      this.mostrarModificar = false;
      this.disabledRange = true;
      this.mostrarCheckLimite = false;
      this.casoEnVueloEncontrado = true;
      this.showCancelarModificar = false;
    }
    else if (status === "en estudio" || status === "modificación de límite") {//Mostar banner y ocultar boton cancelarModificar
      this.myLimit = datosCaso.limiteSolicitado;
      this.showValidar = true;
      this.mostrarSubmit = false;
      this.mostrarModificar = false;
      this.disabledRange = true;
      this.minValue = this.tarjetaSeleccionada.limiteCredito > 300 ? this.tarjetaSeleccionada.limiteCredito : 300;
      this.mostrarCheckLimite = true;
      this.disabledRange = true;
      this.showCancelarModificar = false;
      this.casoEnVueloEncontrado = true;
    } else if (caso.puntoAbandono === "casi-lo-tenemos") {
      if (status === "modificación de límite" || status === "pendiente documentación muro" || status === "revisión bo mmpp") {
        this.myLimit = datosCaso.limiteSolicitado;
        this.showValidar = true;
        this.mostrarSubmit = false;
        this.mostrarModificar = false;
        this.disabledRange = true;
        this.minValue = this.tarjetaSeleccionada.limiteCredito > 300 ? this.tarjetaSeleccionada.limiteCredito : 300;
        this.showCancelarModificar = true;
        this.casoEnVueloEncontrado = true;
      }
      else if (status === "revisión perfil cliente" || status === "firma") {
        this.myLimit = datosCaso.limiteSolicitado;
        this.showValidar = true;
        this.mostrarSubmit = false;
        this.mostrarModificar = true;
        this.disabledRange = false;
        this.mostrarCheckLimite = false;
        this.casoEnVueloEncontrado = true;
        this.showCancelarModificar = true;
      }
    } else if (status === "revisión perfil cliente") {
      this.myLimit = datosCaso.limiteSolicitado;
      this.showValidar = true;
      this.mostrarSubmit = false;
      this.mostrarModificar = true;
      this.disabledRange = false;
      this.mostrarCheckLimite = false;
      this.casoEnVueloEncontrado = true;
      this.showCancelarModificar = true;
    } else {
      this.casoEnVueloEncontrado = false;
    }
  }

  regexpLimite() {

    var ranges = [];
    ranges.push(this.minValue);
    var increment = 1;
    var next = this.minValue;
    var higher = true;

    while (true) {
      next += increment;
      if (next + increment > this.maxValue) {
        if (next <= this.maxValue) {
          ranges.push(next);
        }
        increment /= 10;
        higher = false;
      } else {
        if (next % (increment * 10) == 0) {
          ranges.push(next);
          increment = higher ? increment * 10 : increment / 10;
        }
      }

      if (!higher && increment < 10) {
        break;
      }
    }

    ranges.push(this.maxValue + 1);
    var regex = '^(?:';

    for (var i = 0; i < ranges.length - 1; i++) {
      var str_from = ranges[i];
      str_from = str_from.toString();
      let str_to = (ranges[i + 1] - 1).toString();
      for (var j = 0; j < str_from.length; j++) {
        if (str_from[j] == str_to[j]) {
          regex += str_from[j];
        }
        else {
          regex += "[" + str_from[j] + "-" + str_to[j] + "]";
        }
      }
      regex += "|";
    }

    return regex.substr(0, regex.length - 1) + ')$';
  }

  triggerCreditLimitDataErrorNavData(errorDescription) {

    let creditLimitValue = '';
    if (this.firmaNecesaria) {
      creditLimitValue = 'Menor de 3K€'
    } else {
      creditLimitValue = 'Mayor de 3K€'
    }
    this.analytics.triggerCreditLimitDataNavData({
      'pageName': 'Modificación Límite/' + errorDescription,
      'creditLimit': creditLimitValue
    });
  }

  triggerExtendLimitFormData(submitStatus) {
    this.analytics.triggerExtendLimitFormData(submitStatus, this.myLimit);
  }

  callSSO(params){
    this.authUserService
    .postSoo(this.authSrv.getUrlParam(params, 'uuid'),this.authSrv.getUrlParam(params, 'usuarioBE')).subscribe(data => {
      this.authSrv.setToken(data.access_token);
      this.authSrv.setRefreshToken(data.refresh_token);
      // Llamada al servicio para recuperar datos del cliente.
      this.obtenerDatosCliente().subscribe(data => {
        this.cargarTarjeta();
        this.changeLimit();
      }, _error => {
        this.triggerCreditLimitDataErrorNavData('KO Error servicio');
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
      });

    }, _error => {
      this.triggerCreditLimitDataErrorNavData('KO Error servicio');
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }
}
