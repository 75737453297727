<section *ngIf="showProductHeader" class="product-header">
  <div class="content">
    <div class="title-section">
      <div class="title">
        <span>ALTA PLATAFORMA DE INVERSIÓN</span>
      </div>
      <div class="subtitle">
        <span>Conviértete en inversor en unos pasos.</span>
      </div>
    </div>
  </div>
</section>

