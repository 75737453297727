import { Injectable } from '@angular/core';
import { Persona } from '../collections/persona';
import { Observable } from 'rxjs';

import { ApiSecondHolderService } from './api/api.second-holder.service';
import { LoginService } from './login.service';

@Injectable()
export class SecondHolderService {
  private ID:number;
  private IBAN: string;
  constructor(
    private api: ApiSecondHolderService,
    private login: LoginService
  ) {}

  //GET
  getLoggedUser() {
    return this.login.loginData
  }

  //POST
  createSecondHolder(email, producto, id_cliente_EVO, idInternoPe = this.login.loginData.userinfo.idInternoPe) {
      const secondHolder = {
        idPersona: idInternoPe,
        idCliente: id_cliente_EVO,
        producto: producto,
        email2Titular: email
      }
      return this.api.createSecondHolder(secondHolder);
  }


}
