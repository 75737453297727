import { TimeZones } from "app/collections/timezones";

export var TIME_ZONES: TimeZones[] = [
    { id: "-11:00", value: "Midway", description: "GMT -11" },
    { id: "-10:00", value: "Honolulú", description: "GMT -10" },
    { id: "-09:00", value: "Anchorage, Fairbanks", description: "GMT -9" },
    { id: "-08:00", value: "Los Ángeles, San Francisco, Tijuana", description: "GMT -8" },
    { id: "-07:00", value: "Denver, Phoenix, Chihuahua", description: "GMT -7" },
    { id: "-06:00", value: "Chicago, Costa Rica, México DF", description: "GMT -6" },
    { id: "-05:00", value: "Bogotá, Montreal, Nueva York, Washington", description: "GMT -5" },
    { id: "-04:00", value: "Caracas, Barbados, Halifax, Manaos", description: "GMT -4" },
    { id: "-03:30", value: "St. Jhon’s",description: "GMT -3.30" },
    { id: "-03:00", value: "Buenos Aires, Chile, Montevideo", description: "GMT -3" },
    { id: "-02:00", value: "Georgia del Sur, Sao Paulo", description: "GMT -2" },
    { id: "-01:00", value: "Azores, Cabo Verde", description: "GMT -1"  },
    { id: "+0:00", value: "Londres, Casablanca", description: "GMT +0" },
    { id: "+01:00", value: "Madrid, Berlín, Bruselas, París, …", description: "GMT +1" },
    { id: "+02:00", value: "Atenas, Beirut, El Cairo, Jerusalén", description: "GMT +2" },
    { id: "+03:00", value: "Bagdad, Kuwait, Moscú", description: "GMT +3" },
    { id: "+03:30", value: "Teherán GMT ", description: "GMT +3.30" },
    { id: "+04:00", value: "Bakú, Dubái, Ereván", description: "GMT +4" },
    { id: "+04:30", value: "Kabul", description: "GMT +4.30" },
    { id: "+05:00", value: "Asjabad, Duchanbé, Oral", description: "GMT +5" },
    { id: "+05:30", value: "Calcuta, Colombo", description: "GMT +5.30" },
    { id: "+05:45", value: "Katmandú", description: "GMT +5.45" },
    { id: "+06:00", value: "Almaty, Bishkek", description: "GMT +6" },
    { id: "+06:30", value: "Rangún", description: "GMT +6.30" },
    { id: "+07:00", value: "Bangkok, Krasnoyarsk", description: "GMT +7" },
    { id: "+08:00", value: "Beijing, Hong Kong, Singapore", description: "GMT +8" },
    { id: "+09:00", value: "Seúl, Tokio, Yatutsk", description: "GMT +9" },
    { id: "+09:30", value: "Darwin", description: "GMT +9.30" },
    { id: "+10:00", value: "Brisbane, Guam, Vladivostok", description: "GMT +10" },
    { id: "+10:30", value: "Adelaida", description: "GMT +10.30" },
    { id: "+11:00", value: "Hobart, Magadán, Sídney", description: "GMT +11" },
    { id: "+12:00", value: "Fiyi, Majuro", description: "GMT +12" },
    { id: "+13:00", value: "Auckland, Tongatapu", description: "GMT +13" }
];
  