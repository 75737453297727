import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ErrorService } from 'app/services/error.service';
import { HelperService } from 'app/services/helpers.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CAPITALS } from 'app/resources/capital.model';
import { SPORTS } from 'app/resources/sports.model';
import { DURATIONS } from 'app/resources/duration.model';
import { REGIONS } from 'app/resources/region.model';
import { SIMULADO } from 'app/resources/simulador.model';
import { Persona } from '../../../../collections/persona';
import { ModalService } from 'app/services/modal.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { ACCOUNTS } from 'app/resources/account.model';


@Component({
  selector: 'simulador',
  templateUrl: './simulador.component.html',
  styleUrls: ['./simulador.component.scss'],
  providers: [ModalService]

})
export class SimuladorComponent implements OnInit {

  private positionInit = 0;
  public configureForm: FormGroup;
  public showPlay: boolean = true;
  public vidPlaying: boolean = false;
  public navigate = 'evo-protect/';
  public videoUrl;
  public poster;
  public tarificador:boolean;
  public videoSrc = 'https://www.youtube.com/embed/Ge6vFzSZ1WU'

  modalAdhesion = 'modal-adeshion';


  constructor(
    private helpers: HelperService,
    private modService: ModalService,
    private authService: AuthService,
    public router: Router
  ) { }

  @ViewChild('video') public video: ElementRef;

  ngOnInit() {
    this.videoUrl = "https://www.youtube.com/watch?v=Ge6vFzSZ1WU&feature=youtu.be";
    this.poster = "./assets/images/videos/ciclista.jpg";
    this.tarificador = (this.router.url === '/evo-protect/simulador');
    this.authService.product = ACCOUNTS.MSCode;
    //Se ocultan los steps (protect) para no mostrarlos en esta pantalla
    document.getElementById('user-path').classList.add('hide');
  }

  hidePlayer() {
    return this.showPlay = false;
  }

  playVid() {
    this.vidPlaying = !this.vidPlaying
  }

  goToWelcome() {
    this.helpers.navigateTo("/bienvenido");
  }
}
