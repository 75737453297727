import { Component, OnInit, Input, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, FormControl, NG_VALIDATORS } from '@angular/forms';
import { ModalService } from 'app/services/modal.service';
import { ModalComponent } from 'app/components/modal/modal.component';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'micro-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputModalComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputModalComponent),
      multi: true,
    },
    ModalService,
    ModalComponent
  ]
})
export class InputModalComponent implements ControlValueAccessor, Validator, OnInit {

  private _value = '';
  eventCloseModal = new Subject();

  @Input('arrow') arrow: boolean;
  @Input('placeholder') placeholder: string;
  @Input('description') description: string = '';
  @Input('id') id: string = '';
  @Input('name') name: string = '';
  @Input('modalTitle') modalTitle;
  @Input('modalId') modalId;
  @Input ('classModal') classModal;

  @Input('value')
  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  get value() {
    return this._value;
  }

  onChange: any = () => { };
  onTouched: any = () => { };


  constructor(private modService: ModalService) {}

  ngOnInit() {
    this.eventCloseModal.subscribe((data: any) => {
      this.modService.close(this.modalId);
      this._value = data.value;
      this.onChange(data);
    });
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(option) {
    if (option !== "") {
      this._value = option.value;
    }
  }


  public validate(c: FormControl) {
    return (c.value !== '') ? null : {
      validateError: {
        valid: false,
      },
    };
  }

  openModal() {
    this.modService.open(this.modalId);
  }

}
