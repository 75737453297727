<div class="error-container" *ngIf='screenWidth > 996 && errorsHaveText'
  [ngClass]="{'scroll' : scrollTop, 'datepickerzone': datepickerzone}">
  <ul>
    <li *ngFor='let error of errors; let i=index'>
      <p *ngIf='i<3 && error && error.text'>
        <i class="evo ico-warning-white" style="font-size: 28px;"></i>
        {{isError ? " Error: " : ""}}{{error.text}}
      </p>
    </li>
  </ul>
</div>