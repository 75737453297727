<loading [showMe]="loading"></loading>

<modal [modalTitle]="'modalLogin'" [blocking]='false' [modalId]='modalLogin' class='modal modal-login'>   
  <mobile-error-zone 
    *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray"
    class="custom-erros">
  </mobile-error-zone>
  <div class="container">
    <h2>¿Ya eres cliente?</h2>
    <p>Accede con tus datos a tu área personal.</p>
    <p *ngIf="errMsg" class="err-login">
      {{errMsg}}
    </p>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginUser" (ngSubmit)="sendClient()" novalidate>
      <div class="equal-buttons">
        <input autofocus 
          maxlength="9" 
          placeholder="Usuario (NIF/NIE)" 
          type="text" 
          name="user" 
          formControlName="user" 
          (blur)="validUser()" 
          autocomplete="off"
          (focus)="closeKeyboard()">
          <div class="error-text" *ngIf="errService.desktopErrors(loginUser.controls.user,'user')">
            {{errService.desktopErrors(loginUser.controls.user,'user')}}
          </div>
        <input placeholder="Contraseña" 
          type="password" 
          name="pass" 
          onfocus="blur()" 
          formControlName="pass" 
          autocomplete="off"
          keyboard 
          (numberEmmited)="numberEmmited($event)" 
          (deleteLast)="deleteLast($event)">
          <div class="error-text" *ngIf="errService.desktopErrors(loginUser.controls.pass,'pass')">
            {{errService.desktopErrors(loginUser.controls.pass,'pass')}}
          </div>
      </div>
      <div class="row">
        <button class="btn-common btn-common_fix_bold" type="submit" [disabled]="!loginUser.valid" (click)="closeKeyboard()">
          <span>Enviar</span>
        </button>
      </div>
    </form>
    <div class="row">
      <p class='label'>
        <b>¿No tienes claves para entrar?</b> Llámanos al
        <a class="link-blue" href="tel:910900900">910 900 900</a> y te ayudaremos.
      </p>
    </div>
  </div>
  <div class="footer-modal">
    <h3>AÚN NO SOY CLIENTE DE EVO</h3>
    <div class="row">
      <button class="btn-clear" (click)="newClient()">
        <span>HAZTE CLIENTE</span>
      </button>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Modal Error'" [blocking]='false' [modalId]='modalError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<header-profile></header-profile>
<div class="tarificador">
  <div class="image thin-border"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>LA TRANQUILIDAD, CON EVO PROTECT DEPORTES, TIENE UN PRECIO MUY PEQUEÑO</h2>
        <p>Asegura tus actividades deportivas desde 0.42€</p>
        <p>Para más información accede a nuestra <a class="link-underlined" href="https://www.evobanco.com/productos-inteligentes/prevision-y-seguros/evo-protect/"  target="_blank">web</a></p>
      </legend>
    </div>
    <div class="main-content">
      <tarificador [navigateModal]="'modalLogin'" (eventEmmit)="eventHandler($event)"></tarificador>
    </div>
  </div>
</div>
