<div class="relative-container ">
  <div class="left" (click)='prev()' [ngClass]='arrowStyle'>
    <i class="evo right-arrow"></i>
  </div>
  <div class="slider-container">
    <div (mouseenter)="pause()" (mouseleave)="play()" class="carousel slide">
      <div class="carousel-inner" id="carousel">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="right" (click)='next()' [ngClass]='arrowStyle'>
    <i class="evo right-arrow "></i>
  </div>
</div>
