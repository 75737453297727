
<modal [modalTitle]="modalTitle" 
       [blocking]='false' 
       [modalId]='modalId' 
       [removePadding]='true' 
       class='modal input-modal' 
       [ngClass]="classModal">
  <div class="container">
    <ng-content></ng-content>
  </div>
</modal>

<div class="input-modal-group" (click)="openModal()">
    <button type="button" class="input-modal-toggle"
    [title]="value" role="button">
      <p class="filter-option" [ngClass]="value == '' ? 'selected-placeholder': ''">
        {{ value == '' ? placeholder : value }}
      </p>
      <span *ngIf="description!= undefined && description.length > 0">{{description}}</span>
      <span class="caret" *ngIf="arrow"></span>
    </button>

    <input type="text" [value]="value" [id]="id" [name]="name" class="inputpicker" tabindex="-398">
</div>
