<header-profile class="mobile"></header-profile>
<div class="cancel-ok">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>¡TODO LISTO!</h2>
        <h3>ADHESIÓN CANCELADA</h3>
      </legend>
    </div>
    <div class="row">
      <p>
        Si en el futuro quieres activar cualquier cobertura del seguro EVO Protect Deportes necesitarás volver a firmar el Boletín de Adhesión.
      </p>
    </div>
    <div class="btn-group">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="goToProfile()">ACEPTAR</button>
    </div>
  </div>
</div>