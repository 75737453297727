import { SituacionLaboral } from '../collections/situacion-laboral';

export var SITUACIONESLABORALES: SituacionLaboral[] = [
  { name : 'Situación laboral', value : null},
  { name : 'TRABAJADOR POR CUENTA AJENA', value : '01'},
  { name : 'AUTÓNOMO', value : '02'},
  { name : 'DESEMPLEADO', value : '03'},
  { name : 'JUBILADO/PENSIONISTA', value : '05'},
  { name : 'AMA DE CASA', value : '06'},
  { name : 'ESTUDIANTE', value : '08'},
  { name : 'RENTISTA/PENSIONISTA', value : '11'},
  { name : 'FUNCIONARIO', value : '12'}
];
