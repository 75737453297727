import { Component, OnInit } from '@angular/core';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { MyProductsService } from 'app/services/my-products.service';
import { HelperService } from '../../../services/helpers.service';
import { ModalService } from '../../../services/modal.service';
import { ACCOUNTS } from '../../../resources/account.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'info-investment-account',
  templateUrl: './info-investment-account.component.html',
  styleUrls: ['./info-investment-account.component.scss'],
  providers: [ModalService]
})
export class InfoInvestmentAccountComponent implements OnInit {

  public clientID;
  public loading;

  stateCurrent = States.enviada;
  currentProduct: any;
  fullName: string;
  address: string;
  ACCOUNTS = ACCOUNTS;

  constructor(private helpers: HelperService,
    public myProductSrv: MyProductsService,
    public modService: ModalService,
    public errService: ErrorService) {
  }

  ngOnInit() {
  }

  submit() {
    this.helpers.navigateTo('obligaciones-cuenta-inversora');
  }
}
