
<div class="slider-wihout-feeder">
  <div class="header" [ngClass]="selected.background">
    <h3 class="title">{{ selected.title }}</h3>
  </div>
  <div class="content">
    <div class="description"><span>{{ selected.duration }}</span> de <span>{{ selected.name }} </span> en un país <span>{{ selected.region }} </span> <span *ngIf="selected.coberturaIncluida" class="font-regular">y <span>con cobertura sanitaria incluída.</span></span></div>
    <div class="price">DESDE <span>{{ selected.price | number:'1.2-2' | currencycustom }}</span></div>
    <div class="btn-group">
      <button type="submit" class="btn-common btn-common_fix_bold" (click)="goToCalculatePrice()">CALCULA TU PRECIO</button>
    </div>
    <div class="container-bullet">
      <div *ngFor="let item of items; let i=index" class="bullet" [ngClass]="selected == item ? 'selected' : ''" (click)="goToItem(i)"></div>
    </div>
  </div>
</div>