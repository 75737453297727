import { Component, OnInit } from "@angular/core";
import { ErrorService } from "app/services/error.service";
import { States } from "app/collections/states-card";
import { ActivatedRoute, Router, Params, NavigationEnd } from "@angular/router";
import { MyProductsService } from "app/services/my-products.service";
import { ModalService } from "../../../services/modal.service";
import { AnalyticsService } from "../../../services/analytics.service";
import { ACCOUNTS } from "../../../resources/account.model";
import { DeviceService } from "app/services/device.service";
import { SessionStorageService } from "app/services/session-storage.service";
import { ConstantsService } from "app/services/constants.service";

@Component({
  selector: 'final-investment-account',
  templateUrl: './final-investment-account.component.html',
  styleUrls: ['./final-investment-account.component.scss'],
  providers: [ModalService]
})
export class  FinalInvestmentAccountComponent implements OnInit {
  public clientID;
  private user;
  public loading;
  public showSecondStep = true;
  public cuentas: Array<any> = [];
  public cuentasCliente: any;
  public numRisks: number[] = [1, 2, 3, 4, 5, 6, 7];
  public currentPath: string;
  public step: number;
  public textInversora = false;
  public textInteligente = false;
  public mostrarTexto1Titular = false;
  public nameError = '';
  public showError = false;
  public isBmi = false;
  public isInversora = false;
  stateCurrent = States.enviada;
  currentProduct: any;
  fullName: string;
  address: string;
  ACCOUNTS = ACCOUNTS;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    public myProductSrv: MyProductsService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService,
    private deviceService: DeviceService,
    public sessionStorageSrv: SessionStorageService,
  ) {
    this.router.events.subscribe((url: any) => {
      if (url instanceof NavigationEnd) {
        this.currentPath = url.url;
        this.setSteps();
      }
    });
  }
  ngOnInit() {
    this.getQueryParams();

    if (sessionStorage.getItem('isSecondSigner') === 'true' ||
      sessionStorage.getItem('preparadoFirmaST') === 'true') {
      this.showError = true;
      this.nameError = 'preparadoFirmaST';
      this.triggerAnalyticsNavData();
      // Si el segundo titular aun no ha firmado y intenta firmar el 1º.
    } else if (sessionStorage.getItem('isSecondSigner') != 'true' &&
      sessionStorage.getItem('esperaFirmaST') === 'true') {
      this.showError = true;
      this.nameError = 'esperaFirmaST';
    }

    this.triggerNavData();

    this.setText();
  }
  
  private triggerNavData() {
    const isTwoHolders = sessionStorage.getItem('isTwoHolders') === 'true'
      || sessionStorage.getItem('segundoTit') === 'true'
      || sessionStorage.getItem('activarSegundoTitular') === 'true'
      || sessionStorage.getItem('isSecondSigner');
    const isSecondHolder = sessionStorage.getItem('isSecondSigner') === 'true';

    if (sessionStorage.getItem("productoContratando") == ConstantsService.AccountTypes.CuentaInversora.code) {
      if (!isSecondHolder) {
        if (!isTwoHolders) {
          this.triggerInvesmentNavData('Confirmación cuenta');
        }
        this.triggerInvesmentNavData('Cuenta firmada');
      }
    }
    if (sessionStorage.getItem("productoContratando") == ConstantsService.AccountTypes.FondoInteligente.code) {
      if (!isSecondHolder) {
        if (!isTwoHolders) {
          this.triggerInvesmentNavData('Confirmación fondo');
        }
        this.triggerInvesmentNavData('Fondo Inteligente firmado');
      }
    }
  }

  private triggerAnalyticsNavData() {
    if (sessionStorage.getItem("productoContratando") == ConstantsService.AccountTypes.CuentaInversora.code) {
      this.triggerInvesmentNavData('Confirmación cuenta');
    }
    else if (sessionStorage.getItem("productoContratando") == ConstantsService.AccountTypes.FondoInteligente.code) {
      this.triggerInvesmentNavData('Confirmación fondo');
    }
  }

  submit() {
    this.user = JSON.parse(sessionStorage.getItem('userData'));
    console.log(this.user);
    this.errService.deletePreviousSession();
    window.location.href='https://bancaelectronica.evobanco.com/';
    // this.helpers.navigateTo("/login");
  }
  botonVolver() {}
  setSteps() {
    if (this.currentPath){
      if (this.currentPath.indexOf('/mi-perfil') !== -1) {
        this.step = 1;
      } else if (this.currentPath.indexOf('/configuracion-cuenta-inversora') !== -1) {
        this.step = 2;
      } else if (this.currentPath.indexOf('/visor-cuenta-inversora') !== -1) {
        this.step = 3;
      } else if (this.currentPath.indexOf('/final-cuenta-inversora') !== -1) {
        this.step = 4;
      }
    }
    else if (!this.step){
      this.step = 1;
    }
  }
  /**
   * Establece el texto por defecto dependiendo del prodcucto que se está contratando
   */
  setText(){
    if(sessionStorage.getItem('productoContratando') === ConstantsService.AccountTypes.CuentaInversora.code || this.isInversora){
      this.textInversora = true;
      this.textInteligente = false;
    }else{
      this.textInversora = false;
      this.textInteligente = true;
    }
    if(sessionStorage.getItem('isTwoHolders') === 'true' && sessionStorage.getItem('isSecondSigner') !== 'true'){
      this.mostrarTexto1Titular = true;
    }
  }
  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.origen && params.origen.toLowerCase() === 'bmi') {
          this.isBmi = true;
      }
      // Se añade para que en un abandono podamos saber que producto es.
      if (params.codProductoContratado && params.codProductoContratado === ConstantsService.AccountTypes.CuentaInversora.code){
          this.isInversora = true;
      }
    });
    //  Si se accede mediante una redirección desde logalty, se comprueba si es bmi desde los datos de sesión.
    if (sessionStorage.getItem('isBMI') === 'true') {
      this.isBmi = true;
    }
  }
  isMobileOrTablet(): boolean {
    return this.deviceService.isMobileOrTablet();
  }
  triggerInvesmentNavData(pageName) {
    this.analytics.triggerInvestmentDataNavDataNew(pageName);

  }
}
