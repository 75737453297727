import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-header-protect',
  templateUrl: './header-protect.component.html',
  styleUrls: ['./header-protect.component.scss'],

})
export class HeaderProtectComponent  {
  
  menuShow = false;

  
  };




 




