<div class="load-logalty">
  <div class="inner">
    <div class="container-center">
      <div class="load">
        <h2>¡Ya te has adherido a la Poliza!<br>
       <b>Ahora solo te queda finalizar la contratación de tu primera actividad deportiva cubierta por EVO Protect Deportes</b></h2>
        <img src="assets/images/preload.gif">
        <div class="container-info">
            <div class="texto" >
                {{ mensaje }}
            </div>      
        </div>      
      </div>
    </div>
  </div>
</div>
