import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationExtras } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

const API_URL = environment.apiUrl;

@Injectable()
export class HeaderService {
  private _headers: Headers;
  private options = {};
  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.options = { withCredentials: true, observe: 'response' };
  }

  set refresh_token(params: string) {
    sessionStorage.setItem('refresh_token',params);
  }

  get refresh_token(): string {
    return sessionStorage.getItem('refresh_token');
  }

  //fullonline/refresh_token
  getRefreshToken(): Observable<any> {

    return this.httpClient
      .post(`${API_URL}/fullonline/refresh_token`, { refresh_token: this.refresh_token }, this.options)
      .pipe(
        map(res => {
          sessionStorage.setItem('auth_token', res['body']['access_token']);
          sessionStorage.setItem('refresh_token', res['body']['refresh_token']);
          this.refresh_token = res['body']['refresh_token'];
      }));
  }

  handlerError(error) {
    this.navToError();
    return Observable.throw(error);
  }

  navToError() {
    const nextStep = '/404',
          navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true
          };
    this.deletePreviousSession();
    this.router.navigate([nextStep], navigationExtras);
  }

  deletePreviousSession() {
    sessionStorage.clear();
  }
}
