<loading [showMe]="loading"></loading>

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<app-header-protect *ngIf="!bmi" id="logalty-ready-header"></app-header-protect>

<div class="logalty-ready">
  <div class="image"></div>
  <div class="container">
    <div class="content-top">
      <div class="title-container pad010">
        <legend>
          <span class="main-icon evo sign"></span>
          <h2>¡Empezamos!</h2>
          <h3>TE VAMOS A REDIRIGIR A NUESTRO PARTNER DE CONFIANZA</h3>
        </legend>
      </div>
      <div class="row pad010">
        <p>Te vamos a redigir a Logalty, nuestro tercero de confianza, para que puedas firmar de forma totalmente segura.</p>
      </div>
    </div>
    <div class="content-clear">
      <div class="row">
      <p>Recuerda, esta firma es un paso previo antes de contratar tu seguro
          <strong>EVO PROTECT</strong>.</p>
      </div>
      <form [formGroup]="logaltyForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="row">
          <div class="checkbox-group">
            <p class="text-left">
              <strong>¿Han cambiado tus datos personales y/o socioecónomicos?</strong>
            </p>
            <div class="form-checkbox">
              <input type="checkbox" #checkconfirma name="check_confirma" id="check_confirma" value="Acepto" formControlName="confirma">
              <label for="check_confirma" tabindex="1">
                Confirmo que mis datos personales y/o socioeconómicos no han cambiado
              </label>
            </div>
          </div>
        </div>
        <div class="row btn">
          <div class="btn-group">
            <button type="submit" tabindex="2" class="btn-common btn-common_fix_bold">
              <!-- <span class="hide-mobile">VER Y </span> -->
              <span>CONTINUAR</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
