<loading [showMe]="loading"></loading>

<header-profile class="mobile"></header-profile>

<div class="all-ready">
    <div class="image"></div>
    <div class="container">
      <div class="content-top">
        <div class="title-container">      
          <legend>
            <h2>¡TODO LISTO!</h2>
            <h3>YA TIENES TU EVO PROTECT DEPORTES</h3>
          </legend>      
        </div>
      </div>

      <div class="content-clear">
        <a role="option" (click)="onViewCertificate()">
          <div class="icon view-icon"></div>
          <span>Ver certificado</span>
        </a>
        <div class="container-how-manage">
            <div class="container-text">
                <h2>¿CÓMO GESTIONAR TUS EVO PROTECT DEPORTES?</h2>
                <ul class="square-bullet">
                  <li><span>Toda la información la tienes en tu <strong class="text-blue">Banca Móvil Inteligente.</strong></span></li>
                  <li><span>Pulsa en el menú y accede a <strong class="textbold">EVO PROTECT DEPORTES.</strong></span></li>
                  <li><span>Podrás consultar:</span></li>
                  <ul class="square-bullet">
                    <li><span>Si estás adherido a la póliza.</span></li>
                    <li><span>El histórico de actividades por estado: en vigor, cancelados y disfrutados.</span></li>
                  </ul>
                  <li><span>Podrás gestionar:</span></li>
                  <ul class="square-bullet">
                    <li><span>Cancelación y adhesión a la póliza, recuerda que la adhesión es completamente gratuita.</span></li>
                    <li><span>Activación y cancelación de las actividades.</span></li>
                  </ul>
                </ul>              
                <h3>¿Todavía no tienes nuestra app?</h3>
                <p>Descárgatela ahora:</p>
                <div class="container-stores">
                  <div class="apple-store" (click)="goToAppStore()"></div>
                  <div class="play-store" (click)="goToGooglePlay()"></div>
                </div>              
            </div>         
            <div class="app-mobile"></div>
        </div>
      </div>
    </div>
</div>
