<loading [showMe]="loading"></loading>
<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>
<modal [modalTitle]="'Selección método de identificación.'" [blocking]='false' [modalId]='modalIdVideo'>
  <div class="container container-video">
    <iframe allowfullscreen [src]="videoUrl | safe"></iframe>
  </div>
</modal>
<modal [modalTitle]="'Selección método de identificación.'" [blocking]='false' [modalId]='modalSpecVideo'>
  <div class="container">
    <p>A continuación te explicamos las
      <strong>especificaciones para el proceso de ScanID:</strong>
    </p>
    <ul class='titled-list'>
      <li class='title'>Navegadores que soportan el proceso:</li>
      <li>Chrome 36 o superior.</li>
      <li>Firefox 45 o superior.</li>
      <li>Microsoft Edge 25 o superior.</li>
      <li>Safari 5 o superior (*).</li>
      <li>* Requiere Flash Player instalado.</li>
    </ul>
    <ul class='titled-list'>
      <li class='title'>Notas:</li>
      <li>
        Para dispositivos iOS, versión 11 o superior.
      </li>
      <li>Para dispositivos Android, versión 4.4.2 o superior.</li>
    </ul>
    <ul class='titled-list'>
      <li class='title'>Webcam:</li>
      <li>1.3Mp (Megapixels) o superior.</li>
    </ul>
  </div>
</modal>

<modal [modalTitle]="'Guías para sacar las fotos de forma correcta:'" [blocking]='false' [modalId]='modalSpecIban'>
  <div class="container">
    <strong>1. ENCUADRE Y FONDO</strong>
    <p style='color: #999; line-height: 25px; margin-bottom:10px'>Toma la foto sobre un fondo liso (sin objetos ni fondo) y evita cortar información del documento.</p>
    <strong>2. ILUMINACIÓN</strong>
    <p style='color: #999; line-height: 25px; margin-bottom:10px'>Asegúrate de hacer la foto con buena iluminación, evitar brillos y desactivar el flash de tu cámara.</p>
    <strong>3. NITIDEZ Y CALIDAD DE LA IMAGEN</strong>
    <p style='color: #999; line-height: 25px; margin-bottom:10px'>Asegúrate de que la foto no este borrosa y que la resolución de la misma no sea inferior a 2 megapixeles ni supere 15MB
      de tamaño.</p>
    <strong>4. DOCUMENTO ORIGINAL</strong>
    <p style='color: #999; line-height: 25px; margin-bottom:10px'>La foto debe ser de tu DNI o Tarjeta de residencia original, evita fotocopias o fotos a pantallas.</p>
  </div>
</modal>

<modal [modalTitle]="'Iphone users'" [blocking]='false' [modalId]='modalIphone'>
  <div class="container">
    <h2>Ups!</h2>
    <p>
      Hemos detectado que estás realizando este proceso desde un dispositivo con sistema operativo IOS. Para continuar con el proceso
      puedes:
      <div class="equal-buttons">
        <div class="access" name="button1">
          <a href="https://itunes.apple.com/es/app/evo-contrata/id1190237547?mt=8" target="_blank">
            <span>Descargarte nuestra app</span>
          </a>
        </div>
        <div class="access" name="button2">
          <a (click)='redirectToIban()'>
            <span>Continuar con IBAN</span>
          </a>
        </div>
      </div>
  </div>
</modal>
<modal [modalTitle]="'Iphone users'" [blocking]='false' [modalId]='modalInfoEid' class='modal'>
  <div class="body-modal-info-eid">
    <h2>¡RECUERDA!</h2>
    <p>Para que la video-identificación salga bien tienes que dar permiso de acceso a la cámara de tu dispositivo.</p>
  </div>
  <div class="equal-buttons">
    <div  id="video" class="access btn-eid" (click)="toggleIdentify(identify, $event); redirect()" name="button2">
      <a>
        <span>Entendido</span>
      </a>
    </div>
  </div>
</modal>

<div class="identifyDetails" >
  <div class="image" [ngClass]="{ 'young' : isYoungAccount }"></div>
  <div class="container">
    <div class="title-container" >
      <legend>
        <h2>VERIFICA TU IDENTIDAD</h2>
        <p>Para poder hacerlo todo desde el móvil necesitamos estar seguros de que eres tú.
        </p>
        <br/>
        <p>
          Es muy sencillo. Solo tienes que elegir una de estas dos opciones.</p>
      </legend>
    </div>
    <div class="row" #identify>
      <div class="identify-container not-selected" *ngIf="noIexplorer && cameraAvailable">
        <div (click)="showModalEid()" [class]="setVideoClass()">
          <div class="header">
            <i class="evo eid"></i>
            <div class="title">
              <h3>VIDEOIDENTIFICACIÓN</h3>
              <p>Con la videollamada, tu identificación será inmediata.</p>
            <p> En tan solo unos minutos tu Cuenta estará activa y ya podremos emitir tu Tarjeta Inteligente</p>
            <br>
              <span class="need">¿Qué necesitas?</span>
            </div>
            <br/>
          </div>
          <div class="need-container" >
            <span class="need">¿Qué necesito?</span>
            <span>
              <strong>
                1. Imágenes de tu documento de identificación en vigor (DNI o Tarjeta de residencia)
              </strong>
            </span>
            <span>
              <strong>
                2. Sitio iluminado y la cara despejada
                <br/> (sin gafas, etc)
              </strong>
            </span>
            <span>
              <strong>3. Conexión a internet</strong>
            </span>
            <div class="btn-group" >
              <button id="video" class="btn-common btn-vc" (click)="showModalEid()">CONFIRMAR</button>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="item" (click)="getSpecify('video');">
            <i class='evo list-menu'></i>
            <span>ESPECIFICACIONES</span>
          </div>
          <div class="item" (click)="getVideo('U7QoYXoBDGg')">
            <i class="evo vid-tutorial"></i>
            <span>VIDEOTUTORIAL</span>
          </div>
        </div>
      </div>
      <div class="identify-container">
        <div id="iban" (click)="clickLink(identify, $event)">
          <div class="header">
            <i class="evo iiban"></i>
            <div class="title">
              <h3>IBAN</h3>
              <p>Con la cuenta de otro banco, tu identificación no es inmediata y puede tardar hasta 8 días. A partir de este momento, activaremos tu cuenta y procederemos a emitir tu Tarjeta Inteligente
              </p>
              <br/>
              <span class="need">¿Qué necesitas?</span>
            </div>
          </div>
          <div class="need-container iban">
           <br>
            <span class="need">¿Qué necesito?</span>
            <span>
              <strong>
                1. Imágenes de tu documento de identificación en vigor (DNI o Tarjeta de residencia)
              </strong>
            </span>
            <span>
              <strong>2. Tu IBAN en cualquier otro banco (debes ser el titular de la cuentas)
                <br/>
                <br/>
              </strong>
            </span>
            <div class="btn-group">
              <button id="iban" class="btn-common btn-iban" (click)="toggleIdentify(identify, $event); redirect()">CONFIRMAR</button>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="item" (click)="getSpecify('iban')">
            <i class="evo list-menu"></i>
            <span>ESPECIFICACIONES</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="btn-group" *ngIf="devService.isMobile()">
      <button type="button" class="btn-common btn-common_fix_bold" [disabled]="isDisabled(identify)" (click)="onSubmit()">¡ADELANTE!</button>
    </div> -->

    <!-- formulario valido : {{identifyDetailsForm.valid}}
  {{identifyDetailsForm.value | json}} -->

  </div>
</div>
