import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { ErrorService } from 'app/services/error.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HelperService } from 'app/services/helpers.service';
import { FormValidatorService } from 'app/services/form-validator.service';
import { ModalService } from 'app/services/modal.service';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { Simulador } from 'app/collections/simulador';
import { LoginService } from 'app/services/login.service';
import { User } from 'app/collections/user';
import { ResponseLogin } from 'app/collections/login';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { CheckBalance, CheckBalanceResponse } from 'app/collections/check-balance';
import { zip } from 'rxjs';
import { Observable } from 'rxjs';
import { HelpersMicroinsurancesService } from 'app/services/helpers-microinsurances.service';
import { MicroinsRegistryResponse, MicroinsRegistry } from 'app/collections/microins-registry';
import { AuthService } from '../../../../services/auth.service';
import { Persona } from '../../../../collections/persona';
import { MyProductsService } from '../../../../services/my-products.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';


@Component({
  selector: 'app-payout-method',
  templateUrl: './payout-method.component.html',
  styleUrls: ['./payout-method.component.scss'],
  providers: [ModalService]
})
export class PayoutMethodComponent implements OnInit {

  payoutForm: FormGroup;
  otherContainer = false;

  amount = 0;
  accounts = [];

  private settingsMicroSession: Simulador = this.authService.getMicroSettingsSession();
  loginData: ResponseLogin = this.loginService.loginData;
  userData = this.loginService.userData;
  listpolicies = this.loginService.listPolicies;

  loading = false;
  disabledForm = false;

  public modalError = 'modal-srv-error';
  public modalBalance = 'modal-balance';

  public msgError= "";
  public msgErrorInvalidForm = "Revisa que hayas seleccionado una cuenta.";
  public msgeErrorEVOProtectTemp = 'No puedes  activar dos  o más EVO Protect en el mismo período de tiempo';
  public msgErrorEdad = 'La edad para contratar EVO Protect es de 18 a 65 años';
  public msgError30Minutes = 'Se puede activar tu cobertura EVO Protect  hasta 30 minutos antes de su inicio de tu activad. ¡Deja 5 min. más que es el tiempo que tardarás en completar el proceso!';
  public msgErrorChargeAccount = '¡Vaya! No hemos podido realizar el cargo en tu cuenta para activar la cobertura, por favor revisa tu saldo.';
  public msgErrorNoPolicy = '¡Vaya! Actualmente no estás adherido al Boletín del seguro EVO Protect. Para activar una cobertura es necesario que te adhieras.';
  public messageErrorGeneric = 'Error genérico, vuelva a intentarlo o contacte con el responsable.';
  public messageErrorScopeNotValid = 'Ambito no válido, valores correctos: 1 o 2.';
  public messageErrorCalculation = 'Error en el cálculo';
  public messageErrorNoProducts = 'No se ha podido recuperar ningún producto contratado.';
  public messageErrorNoBalance = "No tienes saldo suficiente para realizar el cargo en ninguna de tus cuentas para activar la cobertura. Por favor revisa tu saldo.";
  public messageErrorReadResponse = 'Póliza: ' + this.listpolicies.policyNumber + '. Existe un error al leer la respuesta del servicio web  WSCargoEnCuentaSoapClient';

  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
   this.authService.deleteMicroSettingsSession();
  }

  constructor(private fb: FormBuilder,
    private elRef: ElementRef,
    public errService: ErrorService,
    private evoValidator: FormValidatorService,
    private helperService: HelperService,
    private helpersMicroService: HelpersMicroinsurancesService,
    private loginService: LoginService,
    private authService: AuthMicroService,
    private auth: AuthService,
    private myProductService: MyProductsService,
    private analytics: AnalyticsService,
    private confService: MicrosegConfigService,
    public modService: ModalService) {
      this.createForm();
  }

  ngOnInit() {
    this.errService.mobileErrors(this.payoutForm);
    this.auth.product = ACCOUNTS.MSCode;
    this.loading = true;
    const persona: Persona = this.auth.getSessionProspect(),
          productosContratados = this.loginService.userData.listaContratados,
          listaContratados = [];
    this.loading = false;
    productosContratados.forEach((productoContratado) => {
      const iban = productoContratado.ibanCC,
        relacionAcuerdoPersona = productoContratado.relacionAcuerdoPersona,
        idProducto = productoContratado.idProducto,
        productos = productoContratado.productos;

      productos.forEach((product) => {
        const productContract = {
          idProducto: idProducto,
          iban: iban,
          identificador: product.identificador.substring(0, 4),
          typeUser: this.myProductService.getTypeUser(relacionAcuerdoPersona)
        }
        listaContratados.push(productContract);
      });
    });

    if (listaContratados.length > 0) {
      //Cantidad a cargar
      this.amount = this.settingsMicroSession.configure.totalPrice;
      // Parseamos las cuentas para adaptarlas al check balance
      // debugger;
      const parseAccounts = this.helpersMicroService.parseAccounts(listaContratados, this.loginData.userinfo.idInternoPe, this.amount),
        zipItems = parseAccounts.zipItems;
      this.accounts = parseAccounts.accounts;
      // Calculamos el saldo de cada cuenta
      this.loading = true;
      const zip$ = (a$) => zip(...zipItems),
        subscribe = zip$(zipItems).subscribe(res => {
          this.loading = false;
          res.forEach((item, index) => {
            if (item['resultCode'] === 0) {
              this.msgError = item['operationErrorMessage'];
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
              this.modService.open(this.modalError);
              return true;
            } else {
              this.accounts[index].disponible = item['positiveBalance'];
              this.setFields();
              if (!this.helpersMicroService.isThereSomeAccountActive(this.accounts)) {
                this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.modalBalance)
                this.modService.open(this.modalBalance);
                this.disabledForm = true;
              }
            }
          });
        },
          error => {
            this.loading = false;
            this.errService.navToError();
          });
    } else {
      this.msgError = this.messageErrorNoProducts;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
      this.modService.open(this.modalError);
      return true;
    }
  }

  goToProfile() {
    this.modService.close(this.modalBalance);
    this.analytics.triggerTarificationNotificationAction('Descartar notificación', this.modalBalance)
    this.helperService.navigateTo("/perfil");
  }


  setFields() {
    // Si tenemos guardada alguna cuenta, la activamos
    if(this.settingsMicroSession != null && this.settingsMicroSession.methodpayout != null) {
      this.helperService.updateFieldValue(this.payoutForm, 'cuenta', this.settingsMicroSession.methodpayout.id);
    // En caso contrario, vemos si tenemos alguna cuenta inteligente activa
    } else {
      this.setOrDisabledAccountIntelligent();
    }
  }

  setOrDisabledAccountIntelligent() {
    const accountIntelligent = this.accounts.find((account) => {
      return account.id == ACCOUNTS.CICode;
    });
    if(typeof accountIntelligent != 'undefined') {
      if(accountIntelligent.disponible) {
        this.helperService.updateFieldValue(this.payoutForm, 'cuenta', ACCOUNTS.CICode);
      } else {
        const element:any = document.getElementById(ACCOUNTS.CICode);
        element.disabled = true;
      }
    }
  }

  private createForm() {
    this.payoutForm = this.fb.group({
      cuenta: ['', [Validators.required]]
    });
    this.formSubscribe();
  }

  formSubscribe() {
    this.payoutForm.valueChanges.subscribe(form => {
      // debugger;
      this.errService.mobileErrors(this.payoutForm);
    });
  }


  onSubmit() {
    this.helperService.updateFormStatus(this.payoutForm);

    if (!this.payoutForm.controls.cuenta.valid) {
      this.msgError = this.msgErrorInvalidForm;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
      this.modService.open(this.modalError);
      window.scrollTo(0, 0);
      return false;
    }

    if (this.payoutForm.valid) {
      // Cuenta seleccionada
      const accountOption = this.payoutForm.controls.cuenta.value,
            accountSelected = this.accounts.find(account => {
              // debugger;
        return account.iban === accountOption;
      });
      // Asignamos la cuenta seleccionado al objeto simulador
      this.settingsMicroSession.methodpayout = Object.assign({
        id: accountSelected.id,
        tipo: accountSelected.tipo,
        iban: accountSelected.iban,
        disponible: accountSelected.disponible,
        condicion: accountSelected.condicion
      });
      // Calculamos los parámetros de entrada
      const params = this.getMicroRegistryParams(accountSelected);
      this.loading = true;
      this.confService.microinsRegistryFirstCall(params).subscribe(res => {
        this.loading = false;
        const response: MicroinsRegistryResponse = res;
        // Errores MPM
        if (response.error) {
          const code = response.errorMessages[0].code;
          switch (code) {
            case 'ERG001':
              this.msgError = this.messageErrorGeneric;
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
              this.modService.open(this.modalError);
              break;
              case 'ERR000':
              this.msgError = this.messageErrorScopeNotValid;
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
              this.modService.open(this.modalError);
              break;
            case 'NPOI001':
              this.msgError = this.messageErrorCalculation;
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
              this.modService.open(this.modalError);
              break;
            case 'ERR0099':
              this.msgError = this.messageErrorReadResponse;
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
              this.modService.open(this.modalError);
              break;
            default:
              this.msgError = response.errorMessages[0].messageText;
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
              this.modService.open(this.modalError);
          }
          return;
        }
        // Si el alta necesita OTP
        if (response.mfaType === 'OTP') {
          this.settingsMicroSession.methodpayout.mfaId = response.mfaId;
          this.authService.setMicroSettingsSession(this.settingsMicroSession);
          this.helperService.navigateTo('/evo-protect/confirmacion');
        } else {
          const infoSalesforceError = response.infoSalesforceError;
          // Errores controlados
          if (response.operationErrorCode) {
            this.msgError  = response.operationErrorMessage;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
            this.modService.open(this.modalError);
            return ;
          }
          // Errores de Salesforce
          if (infoSalesforceError != null) {
            const status = infoSalesforceError.status;
            switch (status) {
              case '001':
                this.msgError = this.msgeErrorEVOProtectTemp;
                this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
                this.modService.open(this.modalError);
                break;
              case '002':
                this.msgError = this.msgErrorEdad;
                this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
                this.modService.open(this.modalError);
                break;
              case '003':
                this.msgError = this.msgError30Minutes;
                this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
                this.modService.open(this.modalError);
                break;
              case '004':
                this.msgError = this.msgErrorChargeAccount;
                this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
                this.modService.open(this.modalError);
                break;
              case '005':
                this.msgError = this.msgErrorChargeAccount;
                this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
                this.modService.open(this.modalError);
                break;

              default:
                this.msgError = infoSalesforceError.messageText;
                this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
                this.modService.open(this.modalError);
            }
            return;
          }
          this.settingsMicroSession.requestId = response.requestId;
          this.authService.setMicroSettingsSession(this.settingsMicroSession);
          this.helperService.navigateTo('/evo-protect/todo-listo');
        }
      },
      error => {
        this.loading = false;
        this.errService.navToError();
      });
    }
  }

  getMicroRegistryParams(accountSelected) {
    const configure = this.settingsMicroSession.configure,
          date = new Date(configure.date.time),
          beneficiary = this.settingsMicroSession.beneficiary,
          // Formateo iban (número de iban, oficina, entidad,...)
          fieldsIban = this.helpersMicroService.getFieldsIban(accountSelected.iban),
          microinsRegistry: MicroinsRegistry = Object.assign({
              policyId: this.listpolicies.policyId,
              clientId: this.listpolicies.clientId,
              clientNif: this.userData.persona.idExterno,
              policyNumber: this.listpolicies.policyNumber,
              sport1: configure.actividad,
              sport2: null,
              sport3: null,
              sport4: null,
              sport5: null,
              discount: '1',
              startDate: this.helpersMicroService.formatDateWithTimeZone(date, configure.timezone),
              scope: configure.region,
              duration: configure.duration,
              refund: configure.cobertura,
              deseaseImport: configure.cfAmount,
              disabilityImport: configure.cipAmount,
              capitalLiability: configure.crcAmount,
              effectDate: this.helpersMicroService.formatStartEffectDate(date),
              accountNumber: fieldsIban.accountNumber,
              ibanNumber: fieldsIban.ibanNumber,
              controlDigit: fieldsIban.controlDigit,
              beneficiary: beneficiary.tipo,
              otherBeneficiary: this.helpersMicroService.prepareFormatOtherBeneficiary(beneficiary.beneficiario),
              entity: fieldsIban.entity,
              office: fieldsIban.office,
              expense: configure.totalPrice.toString()
          });
    return microinsRegistry;
  }

  setStateAccount(disponible, opaque) {
    if (!disponible) {
      if (opaque) {
        return 'not';
      } else {
        return 'disabled';
      }
    }
    return '';
  }
}
