<div class="heading">
    <h3>MIS PRODUCTOS EVO</h3>
</div>
<app-breadcrumb id="breadcrumb" class="mobile" [breadcrumbs]="breadcrumbNav" breadcrumbAdd="MIS PRODUCTOS"></app-breadcrumb>

<div class="my-products container-wrapper has-breadcrumb">
  <div class="background-image"></div>

    <div class="subtitle-content">
        <h2>MIS PRODUCTOS CONTRATADOS</h2>
        <h3>En la ficha de producto podrás encontrar toda la información relativa al producto: características, titulares e IBAN.</h3>
    </div>

    <div class="products" [ngClass]='productClass()'>
      <div class="product-container" *ngFor="let productContract of productosContracts; let i = index" [ngClass]='productosContracts.length === 4? "center" : ""'>
        <div class="bg" [ngClass]="myProductServ.getImageProducto(productContract)">
        <div class="info">
            <div class="description">
              <span class="mobile-only evo" [ngClass]='myProductServ.setProductIcon(productContract)'></span>
              <h2>{{productContract.nombreProducto}}</h2>
              <p [innerHTML]="myProductServ.getDescriptionProducto(productContract)"></p>
              <p *ngIf="!productContract.branchAlias">{{ myProductServ.getTypeUser(productContract.relacionAcuerdoPersona) }}: <strong>{{fullName}}</strong></p>
              <p *ngIf="productContract.segundoTitular != null">Segundo titular: <strong>{{ productContract.segundoTitular.nombreCliente }} {{ productContract.segundoTitular.apellido1Cliente }} {{ productContract.segundoTitular.apellido2Cliente }}</strong></p>
              <p *ngIf="productContract.iban || productContract.ibanCC">IBAN C.C: {{ productContract.iban || productContract.ibanCC }}</p>
              <p *ngIf="productContract.ibanCD">IBAN C.D: {{ productContract.ibanCD }}</p>
            </div>
            <button class="view-info" (click)="myProductServ.goToProductDetail(productContract)">
                <span [innerHTML]='productContract.branchAlias ? "CÓMO GESTIONO MI EVO PROTECT DEPORTES" : "VER INFORMACIÓN"'></span>
                <span class="arrow-icon evo right-arrow"></span>
            </button>
          </div>
          </div>
      </div>
    </div>

    <div class="row">
        <div class="center container">
            <h2>también te puede interesar</h2>
            <h3>Saca el máximo partido a tu banco contratando otros productos.</h3>

        <div class="no-padding">
          <small-slider [interval]="photoInterval"
            [noWrap]="noLoopSlides"
            [text]=""
            [arrowStyle]='slides.length < 4? "hide":""'>
            <small-slide *ngFor="let slide of slides; let index=index"
              [slideObj]='slide'
              [clientID]='userData.persona.id_cliente_EVO'>
              <img class="restricted-carousel" [src]="slide.image" >
            </small-slide>
          </small-slider>
        </div>
    </div>

</div>
