import { RegimenEconomico } from '../collections/regimen-economico';

export const REGIMENES: RegimenEconomico[] = [

  { name: 'Régimen Económico', value: null},
  { name : 'BIENES GANANCIALES', value : '1'},
  { name : 'SEPARACIÓN DE BIENES', value : '2'},
  { name : 'COMUNIDAD DE BIENES', value : '3'},
  { name : 'REGIMEN ESPECIAL', value : '4'}
  // { name : 'DESCONOCIDO', value : '9'}
];
