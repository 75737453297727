import { Injectable, ElementRef, Output, EventEmitter, OnDestroy  } from '@angular/core';
import { ModalComponent } from './../components/modal/modal.component';
import { Modal } from '../collections/modal';
import { DeviceService } from 'app/services/device.service';

@Injectable()
export class ModalService implements OnDestroy {

  @Output() onclose: EventEmitter<any> = new EventEmitter();
  @Output() onopen: EventEmitter<any> = new EventEmitter();

  private modals: any[] = [];

  constructor(private elementRef: ElementRef, private deviceService: DeviceService) { }

  registerModal(newModal: ModalComponent): void {
    const modal = {};
    modal['element'] = newModal;
    modal['find'] = this.findModal(newModal.modalId);
    const modalComponent = document.getElementById(newModal.modalId);
    if (modalComponent != null) {
      modal['domElement'] = modalComponent.parentElement.removeChild(document.getElementById(newModal.modalId));
      if (modal['find']) {
        this.modals.splice(this.modals.indexOf(modal['find']));
      }
      this.modals.push(modal);
    }
  }

  ngOnDestroy() {
    document.body.classList.remove('no-scroll');
  }

  open(modalId: string, blocking?) {
    const modal = this.findModal(modalId);
    if (modal) {
      if (blocking) {
        modal['element'].blocking = blocking;
      }
      this.elementRef.nativeElement.appendChild(modal['domElement']);
      this.elementRef.nativeElement.lastChild.children[0].children[0].classList.remove('hidden');
      if (modalId && modalId === 'specs'){
        document.documentElement.classList.add('noscroll');
      }
      this.onopen.emit();
    }
  }

  close(modalId: string, checkBlocking = false): void {
    const modal = this.findModal(modalId);
    if (modal) {
      if (checkBlocking && modal['element'].blocking) {
        return
      }
      this.onclose.emit(null);
      const modalID = modal['domElement'];
      if (modalId && modalId === 'specs'){
        document.documentElement.classList.remove('noscroll');
      }
      if (modalID) {
        modalID.parentElement.removeChild(modalID);
      }
    }
  }

  private findModal(modalId: string): ModalComponent {
    for (const modal of this.modals) {
      if (modal['element'].modalId === modalId) {
        return modal;
      }
    }
    return null;
  }

  selectStatusModal(data): Modal {
    const response = new Modal();
    response.withImage = true;
    response.name = 'modal-errores-genericos';
    const telephoneMsg = `<a class="link-blue" href="tel:910900900">910 900 900<a/>`;

    switch (data.codigo) {
      case '1001':
      case '1003':
        response.title = '¡Oh-Oh!';
        response.message = `Existe un Problema con tu registro. Revisa tus datos.`

        break;
      case '1004':
        response.name = 'already-client';
        break;
      case '99':
        response.title = '¡Vaya!';
        response.blocking = false;
        break;
      case '1002':
        response.title = '¡Vaya!';
        response.message = `¿Tienes otro número de móvil? Necesitamos un número diferente al facilitado para continuar con el proceso de contratación.`

        response.blocking = false;
        break;
      case 'KO':
        response.title = '¡Ups!';
        response.message = data.message ? data.message : 'Error genérico';
        response.blocking = false;
        response.withImage = false;
        break;
      default:
        response.title = '¡Ups!';
        response.message = data.message ? data.message : 'Error genérico';
        response.blocking = false;
        response.withImage = false;
    }
    return response;
  }
}
