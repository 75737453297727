import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  localStName: string;
  localStConfig: any;

  constructor() {}

  setItem(keyName, value) {
      return sessionStorage.setItem(keyName, value);
  }

  setStringifiedItem(keyName, value) {
    return sessionStorage.setItem(keyName, JSON.stringify(value));
  }

  getItem(keyName) {
      return sessionStorage.getItem(keyName);
  }

  isItemTrue(keyName) {
    return sessionStorage.getItem(keyName) === 'true';
}

  getItemParsed(keyName) {
    return JSON.parse(sessionStorage.getItem(keyName));
  }

  removeItem(keyName) {
      sessionStorage.removeItem(keyName);
  }
}
