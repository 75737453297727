import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { HelperService } from '../../services/helpers.service';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'curtain',
  templateUrl: './curtain.component.html',
  styleUrls: ['./curtain.component.scss']
})
export class CurtainComponent implements OnInit, OnChanges {

  public EdgeClass: boolean = this.checkNavigator();
  public mostrarBoton: boolean = false;
  _showMe: boolean;
  public todoListo: boolean = false;
  public revisandoDocumentacionCampanya: boolean = false;
  public revisandoDocumentacionEnviada: boolean = false;

  imgLapiz = "./assets/images/1_Lapiz.gif";
  imgSatelite = "./assets/images/2_Satelite.gif";
  imgAlien = "./assets/images/3_Alien_ID.gif";
  imgAstroboy = "./assets/images/4_Astroboy.gif";
  imgVayaVaya = "./assets/images/5_VayaVaya.gif";
  imgRocket = "./assets/images/6_Rocket.gif";
  imgAmigosOk = "./assets/images/7_Amigos_OK.gif";
  imgLock = "./assets/images/8_Lock.gif";
  imgMaletin = "./assets/images/9_Maletin.gif";
  imgSecuestro = "./assets/images/10_Secuestro.gif";
  imgAltavoz = "./assets/images/11_altavoz.gif";
  imgTodoOK = "./assets/images/todo-correcto.png";


  @Input('nameError') nameError;

  @Output('update')
  change: EventEmitter<any> = new EventEmitter<any>();

  constructor(public helpers: HelperService,
              public devService: DeviceService) {

  }

  @Input()
  public set showMe(value: boolean) {
    if (value) {
      document.documentElement.classList.add('noscroll');
      //document.body.classList.add('pointer-events');
    } else {
      document.documentElement.classList.remove('noscroll');
      //document.body.classList.remove('pointer-events');
    }
    this._showMe = value;
  }

  public get showMe(): boolean {
    return this._showMe;
  }

  ngOnInit() {
    if (this.nameError === "todoListo") {
      this.todoListo = true;
      this.revisandoDocumentacionCampanya = false;
      this.revisandoDocumentacionEnviada = false;
    }
    else if (this.nameError === "revisandoDocumentacionCampanya") {
      this.todoListo = false;
      this.revisandoDocumentacionCampanya = true;
      this.revisandoDocumentacionEnviada = false;
    }
    else if (this.nameError === "revisandoDocumentacionEnviada") {
      this.todoListo = false;
      this.revisandoDocumentacionCampanya = false;
      this.revisandoDocumentacionEnviada = true;
    }
    const subidaDoc = JSON.parse(sessionStorage.getItem("subidaDocumentacion"));
    if (subidaDoc){
      this.updateSession();
    }
  }

  private updateSession() {
    if (this.todoListo || this.revisandoDocumentacionCampanya || this.revisandoDocumentacionEnviada) {
      sessionStorage.setItem("telonActivo", "true");
    }
    else {
      sessionStorage.removeItem("telonActivo");
    }
  }

  ngOnChanges(changes) {
    if (changes && changes.nameError && changes.nameError.currentValue === 'todoListo') {
      this.todoListo = true;
    }
    if (changes && changes.nameError && changes.nameError.currentValue === 'revisandoDocumentacionCampanya') {
      this.revisandoDocumentacionCampanya = true;
    }
    if (changes && changes.nameError && changes.nameError.currentValue === 'revisandoDocumentacionEnviada') {
      this.revisandoDocumentacionEnviada = true;
    }
  }

  checkNavigator() {
    const UA = ["Edge", "MSIE"];
    const UA_IE11 = ["Mozilla/5.0", "Windows NT", "Trident/7.0", "rv:11.0", "like Gecko"];
    let count = 0;
    //Edge y Explorer 10
    for (var i = 0; i < UA.length; i++) {
      if (navigator.userAgent.indexOf(UA[i]) > -1) return true;
    }
    //Explorer 11
    for (var i = 0; i < UA_IE11.length; i++) {
      if (navigator.userAgent.indexOf(UA_IE11[i]) == -1) return false;
      count++;
    }
    if (count == UA_IE11.length) return true;
    return false;
  };

  goToLogin() {
    document.documentElement.classList.remove('noscroll');
    document.body.classList.remove('pointer-events');
    sessionStorage.clear();
    this.helpers.navigateTo('login');
  }

  goToProfile() {
    document.documentElement.classList.remove('noscroll');
    document.body.classList.remove('pointer-events');
    this.helpers.navigateTo('perfil');
  }

}
