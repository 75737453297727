import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalService } from './../../../services/modal.service';
import { AuthService } from './../../../services/auth.service';
import { DeviceService } from '../../../services/device.service';
import { ErrorService } from '../../../services/error.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { Persona } from '../../../collections/persona';
import { HelperService } from '../../../services/helpers.service';
import { environment } from 'environments/environment';
import { HeaderService } from '../../../services/headers.service';
import { Modal } from '../../../collections/modal';
import { ACCOUNTS } from 'app/resources/account.model';
import { ERRORSFORM } from 'app/resources/errors-form';

@Component({
  selector: 'app-logalty-ready',
  templateUrl: './logalty-ready.component.html',
  styleUrls: ['./logalty-ready.component.scss'],
  providers: [ModalService, DeviceService]
})
export class LogaltyReadyComponent implements OnInit {
  loading: boolean = false;
  logaltyForm: FormGroup;
  persona: Persona;
  msgSrvError = '';
  modalSrvError = 'modal-srv-error';
  noCheckModal = 'nocheck';
  legalModal = 'legal';
  modal = new Modal();
  public isYoungAccount: boolean = false;
  public errors = ERRORSFORM;

  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    return $event.returnValue = '¿Estás seguro?';
  }
  constructor(private fb: FormBuilder,
    private router: Router,
    public modService: ModalService,
    private authService: AuthService,
    public deviceService: DeviceService,
    public errService: ErrorService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService
  ) {
    this.createForm();
  }


  ngOnInit() {
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
  }
  createForm() {
    this.logaltyForm = this.fb.group({
      condiciones: [false, Validators.required],
      cesion: [false , ]
    });
  }

  checkCondiciones() {
    this.modService.close(this.legalModal);
    this.helpers.updateFieldValue(this.logaltyForm, 'condiciones', true)
  }

  openLegal(e) {
    e.preventDefault();
    if (!this.logaltyForm.get('condiciones').value) {
      this.modService.open(this.legalModal);
    }
    else {
      this.logaltyForm.controls['condiciones'].setValue(false);
    }
  }

  onSubmit() {

    if (!this.logaltyForm.get('condiciones').value) {
      this.modService.open(this.noCheckModal);
      this.logaltyForm.get('condiciones').setErrors({ notChecked: true });
      return;
    }

    this.sendLogaltyDetails();
  }

  sendLogaltyDetails() {
    if (this.authService.isLoggedIn) {
      const nextStep = '/proceso-firma';
      this.loading = true;
      this.persona = Object.assign({
        consentimientoTGSS: true,
        indPoliticaPrivacidad: this.helpers.returnValue(this.logaltyForm.get('condiciones')) || false,
        indCesiEmpGrupo: this.helpers.returnValue(this.logaltyForm.get('cesion')) || false,
      });
      this.authService.updateProspect(this.persona).subscribe((data) => {
        this.loading = false;
        if (data.response.codigo !== 'OK') {
          this.modal = this.modService.selectStatusModal(data.response);
          this.analytics.triggerFormData('Previa firma', `Envio KO: ${this.modal.message}`)
          this.modService.open(this.modal.name, this.modal.blocking);
          return
        }
        if (this.authService.isLoggedIn) {
          this.analytics.triggerFormData('Previa firma', 'Envio OK');
          this.loading = true;
          this.headerSrv.getRefreshToken().subscribe(data => { this.loading = false; this.helpers.navigateTo(nextStep); }, error => { this.loading = false; console.log(error); });
        }
      }, error => {
        this.error();
      });
    }
  }

  selectCheck(e) {

    if (e.key !== ' ') { return }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(this.logaltyForm, 'condiciones', newValue);

  }


  selectCheckPubli(e) {
    if (e.key !== ' ') return
    e.preventDefault();
    this.helpers.updateFieldValue(this.logaltyForm, 'cesion', !e.currentTarget.parentElement.firstElementChild.checked)
  }

  error() {
    this.errService.navToError();
  }


}
