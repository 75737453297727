import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'logalty-load',
  templateUrl: './logalty-load.component.html',
  styleUrls: ['./logalty-load.component.scss']
})
export class LogaltyLoadComponent   {


  mensaje = "No actualices ni cierres el navegador mientras estamos en ello, enseguida continuamos con tu contratación ;-)";


}
