import { Component, HostListener, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalService } from './../../../../services/modal.service';
import { ErrorService } from '../../../../services/error.service';
// import { AnalyticsService } from '../../../../services/analytics.service';
import { Persona } from '../../../../collections/persona';
import { HelperService } from '../../../../services/helpers.service';
import { environment } from 'environments/environment';
import { HelpersMicroinsurancesService } from '../../../../services/helpers-microinsurances.service';

@Component({
  selector: 'app-data-eco',
  templateUrl: './data-eco.component.html',
  styleUrls: ['./data-eco.component.scss'],
  providers: [ModalService]
})
export class DataEcoComponent implements OnInit {
  loading:boolean = false;
  logaltyForm : FormGroup;
  persona:Persona;
  msgSrvError = 'Si tus datos socioeconómicos y/o personales han cambiado ponte en contacto con el 910 900 900 para actualizarlos. Si no, marca la casilla y continua la contratación.';
  modalSrvError = 'modal-srv-error';
  bmi= false;
  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    return $event.returnValue = '¿Estás seguro?';
  }
  constructor(private fb: FormBuilder,
              public modService: ModalService,
              public errService:ErrorService,
              private helpersMicroinsurance: HelpersMicroinsurancesService,
              private helpers: HelperService) {
  this.createForm();
}

  ngOnInit() {
    this.bmi = JSON.parse(sessionStorage.getItem("bmiSession"));
    //Se añade la clase stepMarginTop
    if(document.getElementById('user-path')){
      document.getElementById('user-path').classList.add('stepMarginTop');
      document.getElementById('user-path').classList.add('paddingHeader');
    }else{
      this.bmi = true;
    }
  }

  createForm() {
    this.logaltyForm = this.fb.group({
      confirma: [false, Validators.required]
    });
  }

  onSubmit() {

    if (!this.logaltyForm.get('confirma').value){
      this.modService.open(this.modalSrvError);
      return;
    }
    this.sendLogaltyDetails();
  }

  sendLogaltyDetails() {
    const nextStep = this.helpersMicroinsurance.getParentPathMicro('logalty');
    //this.analytics.triggerFormData('Previa firma', 'Envio OK');
    this.helpers.navigateTo(nextStep);
  }

  error() {
    this.errService.errEco = true;
    this.errService.navToError();
  }
}
