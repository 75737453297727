import { Injectable, OnInit, OnDestroy, Inject } from '@angular/core';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { NavigationMicro } from 'app/collections/navigation-micro';
import { AuthGuardMicro } from 'app/services/auth-guard-micro.service';
import { Simulador } from 'app/collections/simulador';
import { LoginService } from 'app/services/login.service';
import { HelperService } from './helpers.service';

@Injectable()
export class NavigationMicroService implements OnDestroy {

  public currentStep: string;
  private nextStep: string;

  navsMicro = NavigationMicro;

  constructor(private authMicroService: AuthMicroService,
              private loginService: LoginService,
              private helpers: HelperService,
              private route: ActivatedRoute) {
  }

  ngOnDestroy() {
    this.currentStep = undefined;
    this.nextStep = undefined;
  }

  emptyNavigation() {
    this.currentStep = undefined;
  }

  isNavigationAllow(nextStep : string, state: RouterStateSnapshot, lastStep:number) : any {

    let isAllowed = false;

    //Adhesión
    if([0, 1, 2, 3, 4, 5, 6, 7].indexOf(this.navsMicro[nextStep]) != -1) {
      if (this.navsMicro[nextStep] === 0 || this.navsMicro[nextStep] === 5)  {
        isAllowed = true;
        this.currentStep = nextStep;
        lastStep = 0;
      // } else if (lastStep == 0 && this.navsMicro[nextStep] > 1) {
      //   isAllowed = false;
      } else if (this.navsMicro[nextStep] > lastStep) {
        lastStep = this.navsMicro[nextStep];
        isAllowed = true;
        this.currentStep = nextStep;
      } else {
        isAllowed = false;
        this.currentStep = "/perfil";
      }
    //Cancelar adhesión
    } else if ([8, 9].indexOf(this.navsMicro[nextStep]) != -1){
      if(this.loginService.isLoggedIn) {
        let evoProtectMicroSession = this.authMicroService.getMicroEvoProtectSession();
        if (this.navsMicro[nextStep] === 8 && evoProtectMicroSession == null && this.loginService.listPolicies != null) {
          lastStep = this.navsMicro[nextStep];
          isAllowed = true;
          this.currentStep = nextStep;
        } else if (this.navsMicro[nextStep] == 9
          && evoProtectMicroSession != null
          && evoProtectMicroSession.cancel) {
          lastStep = this.navsMicro[nextStep];
          isAllowed = true;
          this.currentStep = nextStep;       
        } else {
          isAllowed = false;
        }
      }
    //Settings tarificador
    } else if ([10, 11, 12, 13, 14].indexOf(this.navsMicro[nextStep]) != -1) {
      if(this.loginService.isLoggedIn && this.loginService.listPolicies != null) {
        let settingsMicroSession: Simulador = this.authMicroService.getMicroSettingsSession();
        let configure = null;
        let beneficiary = null;
        let methodpayout = null;
        if(settingsMicroSession != null) {
          configure = settingsMicroSession.configure;
          beneficiary = settingsMicroSession.beneficiary;
          methodpayout = settingsMicroSession.methodpayout;
        }
        if((this.navsMicro[nextStep] < lastStep  && lastStep == 13) || lastStep == 14) {
          isAllowed = false;
        }else if (this.navsMicro[nextStep] == 10) {
          lastStep = this.navsMicro[nextStep];
          isAllowed = true;
          this.currentStep = nextStep;
        } else if(this.navsMicro[nextStep] == 11 && configure != null && this.isValidConfigure(configure)) {
          lastStep = this.navsMicro[nextStep];
          isAllowed = true;
          this.currentStep = nextStep;
        } else if(this.navsMicro[nextStep] == 12 && beneficiary != null && beneficiary.tipo != '') {
          lastStep = this.navsMicro[nextStep];
          isAllowed = true;
          this.currentStep = nextStep;
        } else if(this.navsMicro[nextStep] == 13 && methodpayout != null && methodpayout.id != '') {
          lastStep = this.navsMicro[nextStep];
          isAllowed = true;
          this.currentStep = nextStep;
        } else if(this.navsMicro[nextStep] == 14 && settingsMicroSession != null && settingsMicroSession.requestId != '') {
          lastStep = this.navsMicro[nextStep];
          isAllowed = true;
          this.currentStep = nextStep;
        } else {
          isAllowed = false;
        }
      }

    //Simulador
    } else if ([15].indexOf(this.navsMicro[nextStep]) != -1) {
      if(this.loginService.isLoggedIn && 
        this.loginService.listPolicies == null && 
        this.helpers.isAdultNot65Years(this.loginService.userData.persona.fechaNacimiento)) {
        lastStep = this.navsMicro[nextStep];
        isAllowed = true;
        this.currentStep = nextStep;
      }
    //Tarificador público, hacemos el navigate si no tenemos ninguna poliza adherida
    } else if(this.loginService.listPolicies == null){ 
      lastStep = this.navsMicro[nextStep];
      isAllowed = true;
      this.currentStep = nextStep;
    }

    return {
      'isAllowed' : isAllowed,
      'lastStep': lastStep,
      'defaultPath': this.currentStep
    };

  }

  isValidConfigure(configure) {
    const hasUndefined = Object.keys(configure).filter(key => {
      return configure[key] === '';
    });
    return Object.keys(hasUndefined).length === 0;
  }

}
