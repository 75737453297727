import { Component, ElementRef, NgZone, HostListener, ViewChild, OnInit, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { Persona } from 'app/collections/persona';

import { SITUACIONES } from 'app/resources/situacion-vivienda.model';
import { VIAS } from 'app/resources/tipo-via.model';

import { ESTADO_CIVIL } from 'app/resources/civil-status.model';
import { GENEROS } from 'app/resources/genders.model';
import { PAISES } from 'app/resources/countries.model';
import { REGIMENES } from 'app/resources/regimen-economico.model';
import { DOCUMENTOS } from 'app/resources/document.model';
import { PROVINCES } from 'app/resources/provincias.model';

import { AuthService } from 'app/services/auth.service';
import { FormValidatorService } from 'app/services/form-validator.service';
import { ErrorService } from 'app/services/error.service';
import {  ModalService } from 'app/services/modal.service';
import { DeviceService } from 'app/services/device.service';
import { ApiLocalidadesService } from 'app/services/api/api.localidades.service';
import { LocalidadesService } from 'app/services/localidades.service';
import { JsonService } from 'app/services/json.service';
import {  AnalyticsService } from 'app/services/analytics.service';
import {  HelperService } from 'app/services/helpers.service';
import { environment } from 'app/../environments/environment';
import { HeaderService } from 'app/services/headers.service';

import { MapsAPILoader } from '@agm/core';
import { Modal } from 'app/collections/modal';
import { InjectScriptService } from 'app/services/inject-script.service';
import { ACCOUNTS } from 'app/resources/account.model';
declare var google;

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  providers: [FormValidatorService,
    ModalService,
    DeviceService,
    LocalidadesService,
    ApiLocalidadesService,
    JsonService]
})
export class ContactDetailsCICJComponent implements OnInit, AfterViewInit {
  loading = false;
  modal = new Modal();
  alreadyClientModal = 'already-client';
  telephoneMsg = '<a class="link-blue" href="tel:910900900">910 900 900<a/>';
  exceptionMsg = `Lo sentimos, no podemos seguir con la solicitud online.
  Llámanos al `+ this.telephoneMsg + ` y te explicamos los motivos.`;
  incompleteMsg = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.';
  isBlocking = false;
  private persona: Persona = this.authService.getSessionProspect();
  selectLocalidades = [{ name: 'Selecciona una localidad', value: null }];
  situaciones = SITUACIONES;
  vias = VIAS;
  private ACTUALIZE = false;

  hidden = true;
  secondHolder = false;
  modalLog  = false;

  public latitude: number;
  public longitude: number;
  private localidad: string;
  private noLocalidadCounter = false;
  contactDetailsForm: FormGroup;
  searchDirection: FormControl;
  loginUser: FormGroup;
  localidadLiteral = { name: 'Selecciona una localidad', value: null };
  private passStr = '';
  errMsg;
  public firstTouch = true;
  public firstTouchPrevented = true;
  public isYoungAccount = false;

  documents = DOCUMENTOS;
  countries = PAISES;
  genders = GENEROS;
  provinces = PROVINCES;
  civilStatus = ESTADO_CIVIL;
  economicSystems = REGIMENES;
  localizations;

  @ViewChild("direction")
  public DirectionElementRef: ElementRef;

  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    this.authService.preventAbandon(this, $event);
  }

  constructor(private fb: FormBuilder,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    public modService: ModalService,
    private authService: AuthService,
    private deviceService: DeviceService,
    private localidadesSrv: LocalidadesService,
    private jsonService: JsonService,
    private analytics: AnalyticsService,
    private agm: MapsAPILoader,
    private ngZone: NgZone,
    private helpers: HelperService,
    private headerSrv: HeaderService,
    private changeDetector: ChangeDetectorRef,
    private inject: InjectScriptService
  ) {
    this.sortCountries(this.countries);
    this.initForm();
    document.body.style.position = 'relative'
  }

  initForm() {
    this.createForm();
    this.createFormLogin();
    this.validateAdditionalFields();
  }

  ngAfterViewInit() {
    this.checkIfPostalCode();
    this.changeDetector.detectChanges();
  }

  checkIfPostalCode() {
    const provincia = this.jsonService.getKeyFromJSON(this.persona,
      'datosDomicilio.nombreProvinciaDomicilio');
    const localidad = this.jsonService.getKeyFromJSON(this.persona,
      'datosDomicilio.nombreLocalidad');
    const codigoPostal = this.jsonService.getKeyFromJSON(this.persona,
      'datosDomicilio.codigoPostal');
    if (provincia && localidad && codigoPostal) {
      this.ACTUALIZE = true;
      this.loading = true;
      this.getCodPostal(codigoPostal).subscribe(data => {
        this.loading = false;
        this.setLocalidades(data.EE_O_Consulta);
      }, error => {
        this.goToError();
      });
    }
  }

  ngOnInit() {
    this.localidades();
    this.noLocalidadCounter = false;
    this.errService.mobileErrors(this.contactDetailsForm);
    this.validateAdditionalFields();
    this.setCurrentPosition();
    this.initializeAutocomplete();
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
  }

  sendClient() {
    this.errMsg = undefined;

    this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    this.helpers.updateFormStatus(this.loginUser);

    const passInput = this.loginUser.get('pass');
    const userInput = this.loginUser.get('user');

    if (userInput.invalid || passInput.invalid) {
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: Usuario/Contraseña inválido');
      return
    };

    const login: string = `username=${userInput.value.toUpperCase()}&password=${passInput.value}`;

    this.loading = true;
    const tag = 'GTM-TFMGJVG';
    this.inject.addScriptGoogle('script', tag);
    //this.inject.addScriptGoogle('noscript', tag);
    this.authService.login(login).subscribe(data => {

      this.analytics.triggerFormData('Log in', 'Envio OK');
      this.helpers.navigateTo('perfil');
      this.modService.close(this.alreadyClientModal);
    }, error => {
      this.analytics.triggerFormData('Log in', 'Envio KO: Error 500 Servidor');
      this.loading = false;
      this.errMsg = `Parece que hay algún error, comprueba que has introducido
      correctamente los datos. Recuerda que por seguridad,
      tres errores consecutivos en la contraseña bloquearán el acceso`;
      if (passInput.value.length === 6) {
        this.helpers.updateFieldValue(this.loginUser, 'pass', "");
      }
    });
  }


  validUser() {
    const userInput = this.loginUser.get('user');
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    }
    userInput.setErrors(this.evoValidator.validateID(userInput));
  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: [''],
      pass: ['']
    });

    this.loginUser.controls.user.valueChanges.subscribe(user => {
      if (user.length === 9) {
        this.validUser();
      } else if (user.length === 0) {
        this.helpers.resetFieldsState(this.loginUser, ['user']);
      }
    });

    this.loginUser.valueChanges.subscribe(loginUser => {
      this.errService.mobileErrors(this.loginUser);
    });
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get('pass').value.length < inputMaxLength) {
      this.passStr = this.loginUser.get('pass').value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, 'pass', []);
      this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    }
  }

  closeKeyboard() {
    this.helpers.updateFieldValidation(this.loginUser, 'user', []);
    this.helpers.closeLoginKeyboard();
  }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.firstTouchPrevented = false;
    this.passStr = "";
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
  }


  hasNoPassword() {
    //mail with pass
    this.modService.close(this.alreadyClientModal);
    this.modal = Object.assign({
      title: '¡Vaya!',
      withImage: true,
      blocking: false,
      message: 'En este caso es necesario que vengas a visitarnos en cualquiera de nuestras oficinas o que te pongas en contacto con nosotros por teléfono.'
    })
    this.modService.open(this.modal.name);
  }

  isNotMe() {
    //mail user
    this.modService.close(this.alreadyClientModal);
  }

  goToMap() {
    window.open('https://www.evobanco.com/donde-estamos/');
  }

  initializeAutocomplete() {
    this.searchDirection = new FormControl();
    this.agm.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(document.getElementById('direccion'),
        {
          types: ['address'],
          componentRestrictions: { country: "es" }
        });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          this.autocompleteAddress(place);
        })
      })
    })
  }

  onSubmit() {
    this.helpers.updateFormStatus(this.contactDetailsForm);
    this.triggerErrorAnalytics();
    if (this.contactDetailsForm.get('paisResidencia').value === 'noES') {
      this.modal = Object.assign({
        title: '¡Vaya!',
        withImage: true,
        blocking: false,
        message: this.exceptionMsg
      })
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }
    if (!this.contactDetailsForm.valid) {
      this.modal = Object.assign({
        title: '¡Ups!',
        withImage: true,
        blocking: false,
        message: this.incompleteMsg
      })

      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }
    this.setFields();
    this.sendProspect();
  }

  triggerErrorAnalytics() {
    const invalidFields = this.errService.errorsArray.map(error => {
      return error = error.text
    })
    const invalidString = invalidFields.join(', ');

    if (invalidString !== '')
      return this.analytics.triggerFormData('Datos contacto', `Envio KO: ${invalidString}`);
  }

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    }
  }

  autocompleteAddress(address) {
    let hasPostalCode = false;

    if (!address.address_components) return

    address.address_components.filter(component => {
      switch (component.types[0]) {
        case 'postal_code':
          this.helpers.updateFieldValue(this.contactDetailsForm, 'codigoPostal', component.long_name);
          hasPostalCode = true;
          break;
        case 'route':
          this.helpers.updateFieldValue(this.contactDetailsForm, 'direccion', component.long_name);
          break;
        case 'locality':
          this.localidad = component.long_name;
          break;
        case 'street_number':
          this.helpers.updateFieldValue(this.contactDetailsForm, 'numero', component.long_name);
          break;
      }
    })
    if (!hasPostalCode) {
      this.cleanValues();
    }

  }

  cleanValues() {
    this.helpers.updateFieldValue(this.contactDetailsForm, 'codigoPostal');
    this.helpers.updateFieldValue(this.contactDetailsForm, 'numero');
    this.helpers.updateFieldValue(this.contactDetailsForm, 'provincia');
    this.selectLocalidades = [this.localidadLiteral];
  }

  sendProspect() {

    const nextStep = '/datos-laboral';

    this.loading = true;
    this.authService.updateProspect(this.persona).subscribe((data) => {
      this.loading = false;
      if (data.response.codigo !== 'OK') {
        window.scrollTo(0, 0);
        this.modal = this.modService.selectStatusModal(data.response);
        this.modService.open(this.modal.name, this.modal.blocking);
        this.analytics.triggerFormData('Datos Contacto', `Envio KO: ${this.modal.message}`);
        return;
      }
      if (this.authService.isLoggedIn) {
        this.authService.pageSubmitted = 'datos-contacto';
        this.analytics.triggerFormData('Datos Contacto', `Envio OK`);
        //this.helpers.navigateTo(nextStep);
        this.loading = true;
        this.headerSrv.getRefreshToken().subscribe(data => { this.loading = false; this.helpers.navigateTo(nextStep); }, error => { this.loading = false; console.log(error); });
      }
    }, error => {
      this.goToError();
    });
  }

  goToError() {
    this.analytics.triggerFormData('Datos Contacto', `Envio KO: 500 Server error`);
    this.errService.navToError();
  }

  get isResident() {
    if (!this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.paisResidenciaEsp')) {
      return 'ESP';
    }
    return this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.paisResidenciaEsp') ? 'ESP' : 'noES';
  }

  createForm() {

    const data = new Date(),
          date = data.getDate(),
          month = data.getMonth() + 1,
          year = data.getFullYear();

    this.contactDetailsForm = this.fb.group({
      pais: [this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.codigoPaisNacimiento') || null, [Validators.required]],
      provinciaNacimiento: [this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.codigoProvincia') || null],
      localidadnacimiento: [this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.nombreLocalidadNacimiento') || '', [Validators.required, Validators.pattern('(?:(?![×Þß÷þø])[-A-Za-zÀ-ÿ/() .\'·])+')]],
      estadoCivil: [this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.codigoEstadoCivil') || '', [Validators.required]],
      regimenEconomico: [this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.codigoRegimenEconomico') || ''],
      paisResidencia: [this.isResident, [Validators.required]],
      obligacionesFiscales: ['no', [Validators.required]],
      tipoVia: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.codigoDomicilioTipoVia') || 'CL'],
      direccion: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.nombreVia') || '',
      [Validators.required,
      Validators.minLength(3),
      Validators.maxLength(45),
      Validators.pattern(/^[À-ÿA-z\d\-_\s.\'·]+$/i)
      ]
      ],
      numero: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.domicilioNumero') || '',
      [
        Validators.required,
        Validators.pattern(this.helpers.alphanumericRexp)
      ]
      ],
      planta: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.domicilioPlanta') || '',
      [
        Validators.maxLength(3),
        Validators.pattern(this.helpers.alphanumericRexp)
      ]
      ],
      puerta: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.domicilioPuerta') || '',
      [
        Validators.maxLength(2),
        Validators.pattern(this.helpers.alphanumericRexp)
      ]
      ],
      portal: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.domicilioPortal') || '',
      [
        Validators.maxLength(2),
        Validators.pattern(this.helpers.alphanumericRexp)
      ]
      ],
      bloque: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.bloque') || '',
      [
        Validators.maxLength(2),
        Validators.pattern(this.helpers.alphanumericRexp)
      ]
      ],
      escalera: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.domicilioEscalera') || '',
      [
        Validators.maxLength(2),
        Validators.pattern(this.helpers.alphanumericRexp)
      ]
      ],
      fase: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.fase') || '',
      [
        Validators.maxLength(3),
        Validators.pattern(this.helpers.alphanumericRexp)
      ]
      ],
      parcela: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.parcela') || '',
      [
        Validators.maxLength(45),
        Validators.pattern(/^[A-zÀ-ÿ\d\-_\s.\'·]+$/i)
      ]
      ],
      provincia: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.nombreProvinciaDomicilio') || '', [Validators.required]],
      localidad: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.nombreLocalidad') || null, [Validators.required]],
      codigoPostal: [this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.codigoPostal') || '',
      [Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      Validators.pattern("^[0-9]+$")
      ]
      ],
      situacionVivienda: [this.jsonService.getKeyFromJSON(this.persona,
        'datosSocioEco.situacionVivienda') || '', [Validators.required]]
    });

    this.formSubscribe();

  }

  get isHiddenAditional() {
    return this.hidden = !this.hidden;
  }
  get hideRental() {
    return (!this.contactDetailsForm.get('situacionVivienda').value ||
      this.contactDetailsForm.get('situacionVivienda').value != '2');
  }

  get hideMortgagedHousing() {

    return (!this.contactDetailsForm.get('situacionVivienda').value ||
      this.contactDetailsForm.get('situacionVivienda').value !== '4' &&
      this.contactDetailsForm.get('situacionVivienda').value !== '1');
  }

  setFields() {

    const canal = this.deviceService.getDevice(),
      selectLocalidad = this.searchSelectCode(this.selectLocalidades),
      nombreProvinciaNacimiento = this.searchSelectCodeFromValue(this.provinces, this.contactDetailsForm.get('provinciaNacimiento').value);
    const data = new Date(),
      date = data.getDate(),
      month = data.getMonth() + 1,
      year = data.getFullYear();

    this.persona = Object.assign({

      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina,
        codigoPaisNacimiento: this.helpers.returnValue(this.contactDetailsForm.get('pais')),
        codigoProvincia: this.helpers.returnValue(this.contactDetailsForm.get('provinciaNacimiento')),
        nombreProvinciaNacimiento: nombreProvinciaNacimiento ? nombreProvinciaNacimiento.name : undefined,
        nombreLocalidadNacimiento: this.helpers.returnValue(this.contactDetailsForm.get('localidadnacimiento')),
        codigoEstadoCivil: this.helpers.returnValue(this.contactDetailsForm.get('estadoCivil')),
        codigoRegimenEconomico: this.helpers.returnValue(this.contactDetailsForm.get('regimenEconomico')),
        paisResidenciaEsp: this.helpers.returnValue(this.contactDetailsForm.get('paisResidencia')) === 'ESP' ? true : false
      },
      datosDomicilio: {
        codigoProvinciaDomicilio: selectLocalidad ? selectLocalidad.codigoProvincia : undefined, // sent the ID
        nombreProvinciaDomicilio: selectLocalidad ? selectLocalidad.nombreProvincia : undefined,
        nombreLocalidad: selectLocalidad && selectLocalidad.value && selectLocalidad.value !== null ? selectLocalidad.name : undefined,
        codigoDomicilioTipoVia: this.helpers.returnValue(this.contactDetailsForm.get('tipoVia')),
        nombreVia: this.helpers.returnValue(this.contactDetailsForm.get('direccion')),
        domicilioNumero: this.helpers.returnValue(this.contactDetailsForm.get('numero')),
        domicilioPortal: this.helpers.returnValue(this.contactDetailsForm.get('portal')),
        domicilioEscalera: this.helpers.returnValue(this.contactDetailsForm.get('escalera')),
        domicilioPlanta: this.helpers.returnValue(this.contactDetailsForm.get('planta')),
        domicilioPuerta: this.helpers.returnValue(this.contactDetailsForm.get('puerta')),
        codigoPaisResidencia: this.helpers.returnValue(this.contactDetailsForm.get('paisResidencia')) === 'ESP' ? '011' : undefined,
        codigoPostal: this.helpers.returnValue(this.contactDetailsForm.get('codigoPostal')),
        parcela: this.helpers.returnValue(this.contactDetailsForm.get('parcela')),
        fase: this.helpers.returnValue(this.contactDetailsForm.get('fase')),
        bloque: this.helpers.returnValue(this.contactDetailsForm.get('bloque')),
        fechaAltaAlquiler: `${date}/${month}/${year}`
      },
      datosSocioEco: {
        situacionVivienda: this.helpers.returnValue(this.contactDetailsForm.get('situacionVivienda')),
        obligacionesFiscales: (this.helpers.returnValue(this.contactDetailsForm.get('obligacionesFiscales')) === 'no') ? false : true,
      }
    });
    this.setLocalidadName();
  }

  searchSelectCode(values) {
    let name;
    values.forEach((item) => {
      if (item['selected']) {
        name = item;
      }
    })
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  isNotResident() {
    this.persona = Object.assign({
      datosDomicilio: {
        codigoPaisResidencia: '999'
      }
    })
    this.authService.setSessionProspect(this.persona);
    this.authService.updateProspect(this.persona).subscribe(data => {
      this.loading = false;
    }, error => {
      this.goToError()
    })
  }

  checkUser() {
    const canal = this.deviceService.getDevice();
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: '0239',
      datosPersonales: {
        oficinaCliente: environment.oficina,
      }
    });
  }

  formSubscribe() {
    this.contactDetailsForm.get('estadoCivil').valueChanges.subscribe(eCivil => {
      if (!this.isMarried) {
        this.helpers.updateFieldValue(this.contactDetailsForm, 'regimenEconomico');
        this.helpers.updateFieldValidation(this.contactDetailsForm, 'regimenEconomico');
      } else {
        this.helpers.updateFieldValidation(this.contactDetailsForm, 'regimenEconomico', [Validators.required]);
      }
      this.helpers.resetFieldsState(this.contactDetailsForm, ['regimenEconomico']);
    });

    this.contactDetailsForm.get('provinciaNacimiento').valueChanges.subscribe(provincia => {
      this.helpers.updateFieldValue(this.contactDetailsForm, 'localidadnacimiento', this.setLocalidadName());
    })

    this.contactDetailsForm.get('pais').valueChanges.subscribe(pais => {
      this.evaluateCountry(pais);
      this.helpers.updateFieldValue(this.contactDetailsForm, 'provinciaNacimiento');
      this.helpers.updateFieldValue(this.contactDetailsForm, 'localidadnacimiento');
    });

    this.contactDetailsForm.get('obligacionesFiscales').valueChanges.subscribe(documento => {
      if (documento === 'si') {
        // this.hasInvalidFiscalObligations();
        this.modal = Object.assign({
          name: 'modal-errores-genericos',
          title : '¡Vaya!',
          withImage : true,
          blocking : false,
          message : this.exceptionMsg
        })
        this.modService.open(this.modal.name);
      }
    });

    this.contactDetailsForm.valueChanges.subscribe(form => {
      this.authService.saveUpdatedForm(this.contactDetailsForm, this, form);
      this.errService.mobileErrors(this.contactDetailsForm);
    });
    this.contactDetailsForm.get("paisResidencia").valueChanges.subscribe(pais => {
      if (pais === 'noES') {
        this.contactDetailsForm.get('paisResidencia').setErrors({ valid: false });
        this.isNotResident();

        this.modal = Object.assign({
          name: 'modal-errores-genericos',
          title: '¡Vaya!',
          message: this.exceptionMsg,
          withImage: true,
          blocking: false
        })
        this.modService.open(this.modal.name);
      }
    });

    this.contactDetailsForm.get('localidad').valueChanges.subscribe(localidad => {
      const selectLocalidad = this.searchSelectCode(this.selectLocalidades);
      if (selectLocalidad) {
        this.helpers.updateFieldValue(this.contactDetailsForm, 'provincia', selectLocalidad.nombreProvincia);
      }
    });

    this.contactDetailsForm.get('codigoPostal').valueChanges.subscribe(cp => {
      if (cp.length === 5 && this.contactDetailsForm.get('codigoPostal').valid) {
        this.loading = true;
        this.getCodPostal(cp).subscribe(data => {
          this.loading = false;
          this.setLocalidades(data.EE_O_Consulta);
        }, error => {
          this.goToError();
        });
      } else {
        this.selectLocalidades = [this.localidadLiteral]
        setTimeout(() => this.helpers.updateFieldValue(this.contactDetailsForm, 'localidad', this.localidadLiteral.value), 0);
      }
    })

  }

  getCodPostal(cp) {
    const codigoPostal = { EE_I_Consulta: { codigoEntidad: "0239", codigoPostal: cp } };
    return this.localidadesSrv.getLocalidades(codigoPostal);
  }

  private _hasDataError(data): boolean {
    if (data.Errores) {
      if (!this.noLocalidadCounter) {
        this.helpers.updateFieldValue(this.contactDetailsForm, 'codigoPostal');
        this.noLocalidadCounter = true;
        return true;
      } else {
        this.helpers.updateFieldValue(this.contactDetailsForm, 'codigoPostal');
        this.modal = Object.assign({
          title: '¡Ups!',
          message: 'Por favor introduce otro código postal',
          withImage: false,
          blocking: false
        })
        this.modService.open(this.modal.name);
        return true;
      }
    }
    return false;
  }

  setLocalidades(data) {

    if (this._hasDataError(data)) { return };
    if (this.ACTUALIZE) {
      this.ACTUALIZE = false;
      this.localidad = this.jsonService.getKeyFromJSON(this.persona,
        'datosDomicilio.nombreLocalidad');
    }
    this.selectLocalidades = this.formatList(data.ListaLocalidades);
    let singleLocalidad = this.selectLocalidades.filter((filteredItem) => {
      if (filteredItem.name && this.localidad) {
        return filteredItem.name.toUpperCase() === this.localidad.toUpperCase();
      } else {
        return false
      }
    })
    if (this.selectLocalidades.length === 1) {
      singleLocalidad = this.selectLocalidades;
    }
    if (!this.helpers.isEmpty(singleLocalidad)) {
      setTimeout(() => this.helpers.updateFieldValue(this.contactDetailsForm, 'localidad', singleLocalidad[singleLocalidad.length - 1]['value']), 0);
      const selectLocalidad = this.searchSelectCode(this.selectLocalidades);
      if (selectLocalidad) {
        this.helpers.updateFieldValue(this.contactDetailsForm, 'provincia', selectLocalidad.nombreProvincia)
      }
    } else {
      const localidadLiteral = { name: 'Selecciona una localidad', value: null };
      this.selectLocalidades.unshift(localidadLiteral);
      setTimeout(() => this.helpers.updateFieldValue(this.contactDetailsForm, 'localidad', this.selectLocalidades[0].value), 0);
    }
  }

  formatList(list) {
    let isList = [];
    if (this.helpers.isEmpty(list)) {
      this.modal = Object.assign({
        title: '¡Ups!',
        message: 'No existen localidades con ese código postal',
        withImage: false,
        blocking: false
      })
      this.modService.open(this.modal.name);
    }

    if (Array.isArray(list)) {
      isList = list;
    } else if (this.isObject(list)) {
      isList.push(list);
    }
    // TODO tratar la lista;
    return isList.map((item) => {
      return {
        name: item.nombreLocalidad,
        value: item.nombreLocalidad,
        codigoProvincia: item.codigoProvincia,
        nombreProvincia: item.nombreProvincia
      }
    });
  }

  isObject(obj) {
    return obj === Object(obj);
  }

  public getProduct() {
    const sessionProduct = sessionStorage.getItem('product');
    return sessionProduct;
  }

  updateRentalValidation() {
    const rentalValue = this.contactDetailsForm.get('situacionVivienda').value,
      alquilerFields = ['alquilerVivienda'],
      mortgageFields = ['adquisicion'];

    this.cleanRentalFields();

    switch (rentalValue) {
      case '2':
        this.helpers.updateFieldValidation(this.contactDetailsForm, 'alquilerVivienda', [
          Validators.required,
          this.evoValidator.validateRent,
          Validators.pattern("[0-9]*")
        ]);
        this.helpers.resetFieldsState(this.contactDetailsForm, mortgageFields);
        break;
      case '1':
      case '4':
        this.helpers.updateFieldValidation(this.contactDetailsForm, 'adquisicion', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern("[0-9]+"),
          this.evoValidator.validateMortgage
        ]);
        this.helpers.resetFieldsState(this.contactDetailsForm, alquilerFields);
        break;
    }
  }

  cleanRentalFields() {
    this.helpers.resetFieldsState(this.contactDetailsForm, ['alquilerVivienda', 'adquisicion']);
    this.helpers.updateFieldValue(this.contactDetailsForm, 'alquilerVivienda');
    this.helpers.updateFieldValue(this.contactDetailsForm, 'adquisicion');
    this.helpers.updateFieldValidation(this.contactDetailsForm, 'alquilerVivienda');
    this.helpers.updateFieldValidation(this.contactDetailsForm, 'adquisicion');
  }

  validateAdditionalFields() {
    const fieldsToUpdate = [
      'portal',
      'bloque',
      'escalera',
      'fase',
      'parcela'
    ];

    this.helpers.resetFieldsState(this.contactDetailsForm, fieldsToUpdate);
    this.helpers.cleanMultipleValidations(this.contactDetailsForm, fieldsToUpdate)

    if (!this.hidden) {
      this.helpers.updateFieldValidation(this.contactDetailsForm, 'portal', [Validators.maxLength(2), Validators.pattern(this.helpers.alphanumericRexp)]);
      this.helpers.updateFieldValidation(this.contactDetailsForm, 'bloque', [Validators.maxLength(10), Validators.pattern(this.helpers.alphanumericRexp)]);
      this.helpers.updateFieldValidation(this.contactDetailsForm, 'escalera', [Validators.maxLength(2), Validators.pattern(this.helpers.alphanumericRexp)]);
      this.helpers.updateFieldValidation(this.contactDetailsForm, 'fase', [Validators.maxLength(3), Validators.pattern(this.helpers.alphanumericRexp)]);
      this.helpers.updateFieldValidation(this.contactDetailsForm, 'parcela', [Validators.maxLength(45), Validators.pattern(/^[A-zÀ-ÿ\d\-_\s.]+$/i)]);
    }
  }

  setLocalidadName() {
    if (this.localizations) {
      if (this.persona.datosPersonales.codigoProvincia) {
        const provincia = this.persona.datosPersonales.codigoProvincia
        const localidad = this.localizations.filter( localidad => {
          return localidad.value === this.persona.datosPersonales.codigoProvincia
        })
        this.persona.datosPersonales.nombreLocalidadNacimiento = localidad[0].name;
        return localidad[0].name;
      }
    } else {
      this.localidades((cb) => { this.setLocalidadName(); });
    }
  }

  sortCountries(arrayCountries) {

    arrayCountries.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    const countryLiteral = arrayCountries.filter((obj) => {
      return obj.value === null;
    })
    const spain = arrayCountries.filter((obj) => {
      return obj.name === 'ESPAÑA';
    });
    arrayCountries.splice(arrayCountries.indexOf(spain[0]), 1);
    arrayCountries.unshift(spain[0]);
    arrayCountries.splice(arrayCountries.indexOf(countryLiteral[0]), 1);
    arrayCountries.unshift(countryLiteral[0]);

  }

  evaluateCountry(pais) {
    const estadosUnidos = '400',
          españa = '011';

    switch (pais) {
      case estadosUnidos:
        this.modal = Object.assign({
          title : '¡Vaya!',
          withImage : true,
          message : this.exceptionMsg
        })
        this.modService.open(this.modal.name);
        break;
      case españa:
        this.helpers.updateFieldValidation(this.contactDetailsForm, 'localidadnacimiento');
        this.helpers.updateFieldValidation(this.contactDetailsForm, 'provinciaNacimiento', [Validators.required]);
        break;
      default:
        this.helpers.updateFieldValidation(this.contactDetailsForm, 'localidadnacimiento', [
          Validators.required,
          Validators.pattern('(?:(?![×Þß÷þø])[-A-Za-zÀ-ÿ/() .\'·])+')
        ])
        this.helpers.updateFieldValidation(this.contactDetailsForm, 'provinciaNacimiento');
        this.helpers.updateFieldValue(this.contactDetailsForm, 'provinciaNacimiento', );
        break;
    }
    this.helpers.updateFieldValue(this.contactDetailsForm, 'localidadnacimiento')
    this.helpers.resetFieldsState(this.contactDetailsForm, ['provinciaNacimiento', 'localidadnacimiento'])
  }


  get isMarried() {
    return (String(this.contactDetailsForm.get('estadoCivil').value).toUpperCase() === '05');
  }

  get isSpain() {
    return this.contactDetailsForm.get('pais').value === '011';
  }

  get isCountrySelected() {
    return this.contactDetailsForm.get('pais').value !== null;
  }

  localidades(cb?) {
    this.localidadesSrv.getLocalidadesData().subscribe(data => {
      this.localizations = data.localidades;
      return cb;
    },
      error => {
        console.log(error);
      });
  }

  hasInvalidFiscalObligations() {
    this.persona = Object.assign({
      datosSocioEco: {
        obligacionesFiscales: true
      }
    })
    this.authService.updateProspect(this.persona).subscribe(data => {
      this.loading = false;
    }, error => {
      this.handleError()
    })
  }

  handleError() {
    this.analytics.triggerFormData('Datos Contacto', `Envio KO: 500 Server error`);
    this.loading = false;
    this.errService.navToError();
  }

  searchSelectCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item['value'] === value && item['value'] !== null) {
        name = item;
      }
    })
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  continuarSubmit(){
    const estadosUnidos = '400',
          españa = '011',
          incompleteModal = {
            title: '¡Ups!',
            blocking: false,
            withImage: true,
            message: this.incompleteMsg
          }
    if (this.contactDetailsForm.get('pais').value === españa && this.contactDetailsForm.get('localidadnacimiento').value === '') {
      this.helpers.updateFieldValue(this.contactDetailsForm, 'localidadnacimiento', this.setLocalidadName())
    }
    this.helpers.updateFormStatus(this.contactDetailsForm);
    this.triggerErrorAnalytics();

    if (this.contactDetailsForm.get('pais').value === estadosUnidos ||
    this.contactDetailsForm.get('obligacionesFiscales').value === 'si') {
      this.modal = Object.assign({
        title : '¡Vaya!',
        message : this.exceptionMsg,
        blocking : false,
        withImage : true
      })

      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }

    if (!this.contactDetailsForm.valid) {
      this.modal = Object.assign(incompleteModal)
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }

    this.sendProspect();
  }

}
