import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { STEPS_MICROBREADCRUMBS } from 'app/resources/steps-micro-breadCrumbs.model';
import { AuthService } from 'app/services/auth.service';


@Component({
  selector: 'app-protect-screen',
  templateUrl: './protect-screen.component.html',
  styleUrls: ['./protect-screen.component.scss']
})
export class ProtectScreenComponent implements OnInit {

  public stepsInfo;
  
  constructor(private authService: AuthService) { }

  ngOnInit() {
    const persona = this.authService.getSessionProspect();
    this.showStepsInfo(persona);
  }

  showStepsInfo(persona){
      this.stepsInfo = STEPS_MICROBREADCRUMBS;
  }


}
