export class ConfirmPersonal {
  name: string;
  firstName: string;
  secondName:string;
  documentNumber:string;
  expiryDate: any;
  nationality: string;
  birthCountry:string;
  birthProvince:string;
  birthLocation: string;
  birthDate: any;
  sex: string;
  civilStatus:string;
  economicSystem: string;
  residenceCountry:string;
  residenceProvince:string;
  residenceLocation:string;
  contactDetails: {
    postalCode:string;
    telephone:string;
    email:string;
    streetType:string;
    streetName:string;
    streetNumber:string;
    piso:string;
    puerta:string;
    portal:string;
    bloque:string;
    escalera:string;
    fase:string;
    parcela:string;
    situacionVivienda:string;
  };
  laborDetails: {
    situacionLaboral:string;
    tipoContrato:string;
    tipoActividad:string;
    fechaAltaAutonomo:string;
    nombreEmpresa:string;
    antiguedadLaboral:string;
  };
  personalNumber: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
