import { Injectable } from '@angular/core';

@Injectable()
export class ConstantsService {

  static imgLogout = 'assets/images/ic_power_pink.svg';
  static imgAntenaParabolica = 'assets/images/ic_antena_parabolica.svg';

  // Posibles Respuestas que consola enviará a los procesos de contratación
  public static RespuestaConsola = {
    TodoOk: 'CMD_1',
    ResidenciaFueraDeEspania: 'CMD_2',
    SinDRF: 'CMD_3',
    ObligacionesFiscalesFueraDeEspania: 'CMD_4',
    RelacionEEUU: 'CMD_5',
    ModificacionDatosCliente: 'CMD_6',
    ModificacionDocumentacion: 'CMD_7',
    KO: 'CMD_99'
  };

  public static AccountTypes = {

    CuentaInteligente: {
      code: '006' ,
      nombre: 'Cuenta Inteligente',
      constPath: '',
      product: {
        ibanAccount: '03111030637',
        ibanInvestment: '0321CINP632',
        cardNumber: '01511020632',
        userAlerts: '05581010002',
        userElectronicBank: '05551010001',
      }
    },
    CuentaDuo: {
      code: '007' ,
      nombre: 'Cuenta DUO',
      constPath: '',
      product: {
        ibanAccount: '03111010137',
        ibanInvestment: '0321CINP632',
        cardNumber: '05511010137',
        userAlerts: '05581010002',
        userElectronicBank: '05551010001',
      }
    },
    CuentaJoven: {
      code: '008' ,
      nombre: 'Cuenta Joven',
      constPath: 'cuenta-joven',
      product: {
        ibanAccount: '03111010632',
        ibanInvestment: '0321CINP632',
        cardNumber: '05511010138',
        userAlerts: '05581010002',
        userElectronicBank: '05551010001',
      }
    },
    SegundoTitular: {
      code: '999' ,
      nombre: 'Segundo titular',
      constPath: '',
      product: ''
    },
    Microseguros: {
      code: '998' ,
      nombre: 'Microseguros',
      constPath: '',
      product: ''
    },
    CuentaInversora: {
      code: '009' ,
      nombre: 'Cuenta Inversora',
      constPath: 'cuentainversora',
      product: 'Cuenta Inversora',
      flujo: 'Largo'
    },
    CuentaInversoraCorto: {
      code: '109' ,
      nombre: 'Cuenta Inversora',
      constPath: 'cuentainversora',
      product: 'Cuenta Inversora',
      flujo: 'corto'
    },
    FondoInteligente: {
      code: '010' ,
      nombre: 'Fondo Inteligente',
      constPath: 'fondointeligente',
      product: 'Fondo Inteligente'
    },
    TipoA: {
      code: '060' ,
      nombre: 'Tipo A',
      constPath: '',
      product: ''
    },
    AmpliacionLimite: {
      code: '201' ,
      nombre: 'Ampliación de Límite',
      constPath: '',
      product: ''
    },
    DisminucionLimite: {
      code: '200' ,
      nombre: 'Disminución de Límite',
      constPath: '',
      product: ''
    },
  }

  public static RelacionAcuerdoPersona: {
    Titular: '01',
    Autorizado: '02',
    Representante: '15',
    Usufructuario: '24'
  }

  public static Inversis: {
    tipoSuscripcion: 'SUSCRIPCION',
    tipoValoracion: 'VALORACION CARTERA',
    soloOperativa: 'N',
    numeroCuentaExternaPrefix: '0239'
  }

  public static buscadorMagnolia = "https://inversis.es/evobanco/buscador-de-fondos";

  public static FinalMessages = {
    SesionFinalizada: {
      image: ConstantsService.imgLogout,
      title: 'Tu sesión ha finalizado',
      description: 'Vuelve a entrar para continuar con el proceso.',
      btnPrimary: 'Volver a entrar',
      btnPrimaryAction: 'LOGOUT',
      btnSecondary: '',
      btnSecondaryAction: '',
      btnTertiary: '',
      btnTertiaryAction: '',
      footer: '',
    },
    Batch: {
      image: ConstantsService.imgAntenaParabolica,
      title: 'Lo sentimos...',
      description: 'En estos momentos el servicio no está disponible. Por favor, inténtalo más tarde.',
      btnPrimary: 'Entendido',
      btnPrimaryAction: 'GOTOEVO',
      btnSecondary: '',
      btnSecondaryAction: '',
      btnTertiary: '',
      btnTertiaryAction: '',
      footer: '',
    },
  }

  public static firstPageAmpliacion = '/ampliacion-step1';
  public static firstPageCuentaInversora = '/configuracion-cuenta-inversora?codProductoContratado=009&origen=BE';
  public static firstPageFondoInteligente = '/configuracion-cuenta-inversora?codProductoContratado=010&origen=BE';
  public static firstPageProductoInversion = '/configuracion-cuenta-inversora';

}
