import { Component, OnInit, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'info-content',
  templateUrl: './info-content.component.html',
  styleUrls: ['./info-content.component.scss']
})

export class InfoContentComponent implements OnInit {

  private baseMarginLeft = 25;
  public marginLeft = 0;
  private textos: string[] = [];
  private init: number;
  public valorFallecimiento: string;
  public valorInvalidezParcial: string;
  public valorInvalidezAbsoluta: string;

  private timeout = 0;

  constructor(private elRef: ElementRef) { }

  ngOnInit() {

  }

  @Input()
  public set valueDead(value: string) {
    this.hideContainerInfo();
    setTimeout(() => this.valorFallecimiento = value, this.timeout);
  }

  @Input()
  public set valueInvalidParcial(value: string) {
    this.hideContainerInfo();
    setTimeout(() => this.valorInvalidezParcial = value, this.timeout);
  }

  @Input()
  public set valueInvalidAbsolute(value: string) {
    this.hideContainerInfo();
    setTimeout(() => this.valorInvalidezAbsoluta = value, this.timeout);
  }


  @Input()
  public set positionInit(position: number) {
    this.init = position;
  }

  hideContainerInfo() {
    const divContainerMain = this.elRef.nativeElement.querySelector('.divContainerMain');
    divContainerMain.classList.remove("showInfo");
  }

  showContainerInfo() {
    const divContainerMain = this.elRef.nativeElement.querySelector('.divContainerMain');
    divContainerMain.classList.add("showInfo");
  }


  @Input()
  public set position(position: number) {
    this.hideContainerInfo();
    setTimeout(() => {
      const x = position;
      if (typeof x !== 'undefined') {
        if (x === this.init) {
          this.marginLeft = this.baseMarginLeft;
        } else {
          this.marginLeft  = x - this.init + this.baseMarginLeft
        }
      } else {
        this.marginLeft = this.baseMarginLeft;
      }
      this.showContainerInfo();
      this.timeout = 700;
    }, this.timeout);
  }
}
