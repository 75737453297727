import { Injectable } from "@angular/core";
import { NestedObject } from "app/services/nestedObject.service";

@Injectable()
export class AuthMicroService {

  constructor(public nestedObject: NestedObject) {}

  setMicroSession(microseguros){
    const previus = JSON.parse(sessionStorage.getItem('microsegurossign')) || {};
    let current = this.nestedObject.nestedObjectAssign({}, previus, microseguros);
    sessionStorage.setItem('microsegurossign', JSON.stringify(current) || null);
  }

  getMicroSession():any{
    return sessionStorage.getItem('microsegurossign') || null;
  }


  setMicroSettingsSession(microseguros){
    const previus = JSON.parse(sessionStorage.getItem('microsegurossettings')) || {};
    let current = this.nestedObject.nestedObjectAssign({}, previus, microseguros);
    sessionStorage.setItem('microsegurossettings', JSON.stringify(current) || null);
  }

  getMicroSettingsSession(){
    return JSON.parse(sessionStorage.getItem('microsegurossettings') || null);
  }

  deleteMicroSettingsSession() {
    sessionStorage.removeItem("microsegurossettings");
  }

  setMicroEvoProtectSession(microseguros){
    const previus = JSON.parse(sessionStorage.getItem('microsegurosevoprotect')) || {};
    let current = this.nestedObject.nestedObjectAssign({}, previus, microseguros);
    sessionStorage.setItem('microsegurosevoprotect', JSON.stringify(current) || null);
  }

  getMicroEvoProtectSession(){
    return JSON.parse(sessionStorage.getItem('microsegurosevoprotect') || null);
  }

  deleteMicroProtectSession() {
    sessionStorage.removeItem("microsegurosevoprotect");
  }

}
