// modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingComponent } from '../../components/loading/loading.component';
// components
import { DropdownComponent } from '../../components/dropdown/dropdown.component';
import { DropdownCustomComponent } from '../../components/dropdown-custom/dropdown-custom.component';
import { AutocompleteComponent } from '../../components/autocomplete/autocomplete.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { DesktopErrorZoneComponent } from '../../components/desktop-error-zone/desktop-error-zone.component';
import { HeaderProfileComponent } from '../profile/header-profile/header-profile.component'
import { HeaderProtectComponent } from '../../components/header-protect/header-protect.component'
import { ProtectScreenComponent } from '../profile/protect-screen/protect-screen.component';
import { FooterComponent } from '../profile/footer/footer.component';
import { FooterCenterComponent } from '../profile/footer-center/footer-center.component';
import { Slide } from '../profile/slider/slide/slide.component';
import { SliderComponent } from '../profile/slider/slider.component';
import { SmallSlide } from '../profile/small-slider/small-slide/small-slide.component';
import { SmallSliderComponent } from '../profile/small-slider/small-slider.component';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { KeyboardComponent, KeyboardDirective } from '../../components/numeric-keyboard/keyboard.component';

//pipes
import { CapitalizePipe } from '../../pipes/capitalize/capitalize.pipe';
import { SafePipe } from '../../pipes/sanitizer/sanitizer.pipe';
import { AutocompletePipe } from '../../pipes/autocomplete/autocomplete.pipe';

//directives
import { ErrorTooltipDirective } from '../../directives/desktop-error.directive';
import { UserTooltipDirective } from '../../directives/user-path-tooltip.directive';
import { DateInputDirective } from '../../directives/date-input.directive';
import { NumberInputDirective } from '../../directives/number.directive';
import { DragDropDirective } from '../../directives/drag-drop.directive';
import { NoWhitespaceDirective } from '../../directives/no-whitespace.directive';
import { IbanInputDirective } from '../../directives/iban-input.directive';
import { InfoContentComponent } from 'app/modules/profile/info-content/info-content.component';
import { MobileErrorZoneComponent } from 'app/components/mobile-error-zone/mobile-error-zone.component';
import { HelperStepsDirective } from '../../directives/helper-steps.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StepsComponent } from 'app/components/steps/steps.component';
import { OtpDetailsComponent } from 'app/components/otp-details/otp-details.component';
import { CurrencyCustomPipe } from 'app/pipes/currency/currency-custom.pipe';
import { ObfuscatePipe } from '../../pipes/obfuscate/obfuscate.pipe';
import { ElementScrollDirective } from '../../directives/element-scroll.directive';
import { DesktoperrorbarzoneComponent } from 'app/components/desktop-error-bar-zone/desktop-error-bar-zone.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild([
      { path: '', component: StepsComponent }
    ])
  ],
  declarations: [
    DesktopErrorZoneComponent,
    DesktoperrorbarzoneComponent,
    MobileErrorZoneComponent,
    DropdownComponent,
    DropdownCustomComponent,
    AutocompleteComponent,
    LoadingComponent,
    HeaderProfileComponent,
    HeaderProtectComponent,
    ProtectScreenComponent,
    FooterComponent,
    FooterCenterComponent,
    BreadcrumbComponent,
    InfoContentComponent,
    ErrorTooltipDirective,
    UserTooltipDirective,
    DateInputDirective,
    DragDropDirective,
    NumberInputDirective,
    NoWhitespaceDirective,
    IbanInputDirective,
    ModalComponent,
    OtpDetailsComponent,
    StepsComponent,
    Slide,
    SliderComponent,
    SmallSlide,
    SmallSliderComponent,
    CapitalizePipe,
    SafePipe,
    AutocompletePipe,
    CurrencyCustomPipe,
    ObfuscatePipe,
    HelperStepsDirective,
    KeyboardDirective,
    KeyboardComponent,
    ElementScrollDirective
  ],
  exports: [
    DesktopErrorZoneComponent,
    DesktoperrorbarzoneComponent,
    MobileErrorZoneComponent,
    DropdownComponent,
    DropdownCustomComponent,
    AutocompleteComponent,
    LoadingComponent,
    HeaderProfileComponent,
    HeaderProtectComponent,
    FooterComponent,
    FooterCenterComponent,
    BreadcrumbComponent,
    InfoContentComponent,
    ErrorTooltipDirective,
    UserTooltipDirective,
    DateInputDirective,
    DragDropDirective,
    NumberInputDirective,
    NoWhitespaceDirective,
    IbanInputDirective,
    ModalComponent,
    OtpDetailsComponent,
    StepsComponent,
    Slide,
    SliderComponent,
    SmallSlide,
    SmallSliderComponent,
    CapitalizePipe,
    ObfuscatePipe,
    SafePipe,
    AutocompletePipe,
    CurrencyCustomPipe,
    HelperStepsDirective,
    KeyboardDirective,
    KeyboardComponent,
    ElementScrollDirective
  ],
  entryComponents: [
    DesktopErrorZoneComponent,
    ModalComponent
  ]
})
export class SharedModule { }
