<div *ngIf="_showMe" class="holaDiv">
  <div *ngIf="showHeader" class="fixed-wrapper">
    <header-profile></header-profile>
  </div>
  <!-- Mensaje TODO OK -->
  <div class="cmessage" *ngIf="todoListo">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡TODO LISTO!</div>
        <img class="cmessage__imagen" src="{{imgTodoOK}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Hemos recibido con éxito tu documento de identidad</p>
        </div>
      </div>
      <div class="btn-group_fix">
        <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold" (click)="goToLogin()">Entendido</button>
      </div>
    </div>
  </div>
  <!-- Mensaje ASTRONAUTA -->
  <div class="cmessage" *ngIf="revisandoDocumentacionCampanya">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡COMUNICACIÓN ENVIADA A HOUSTON!</div>
        <img class="cmessage__imagen" src="{{imgAstroboy}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Algo no nos cuadra, tenemos que revisar tu solicitud. Nos pondremos en contacto contigo por email lo antes posible.</p>
        </div>
      </div>
      <div class="btn-group_fix">
        <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold" (click)="goToLogin()">Entendido</button>
      </div>
    </div>
  </div>
  <!-- Mensaje ENVIADA LA COMUNICACIÓN -->
   <div class="cmessage" *ngIf="revisandoDocumentacionEnviada">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          ¡COMUNICACIÓN ENVIADA A HOUSTON!</div>
        <img class="cmessage__imagen" src="{{imgAstroboy}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">Estamos revisando tu documentación, cuando esté todo listo te avisaremos para que puedas continuar con tu contratación.</p>
        </div>
      </div>
      <div class="btn-group_fix">
        <button type="submit" class="btn-common btn-common_fix btn-common_fix_bold"  (click)="goToLogin()">Entendido</button>
      </div>
    </div>
  </div>
  <app-footer-center *ngIf="showFooter"></app-footer-center>
