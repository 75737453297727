import { Component, Output, EventEmitter, OnInit, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { Observable } from 'rxjs/Observable'
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { Persona } from '../../../collections/persona';

import { AuthService } from '../../../services/auth.service';
import { FormValidatorService } from './../../../services/form-validator.service';
import { ErrorService } from './../../../services/error.service';
import { ModalService } from './../../../services/modal.service';
import { DeviceService } from '../../../services/device.service';
import { HeaderService } from '../../../services/headers.service';
import { OtpService } from 'app/services/otp.service';
import { ApiRecoveryService } from '../../../services/api/api.recovery.service';
import { RecoveryService } from '../../../services/recovery.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { SpecificAnalyticsService } from '../../../services/specific-analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { LoginService } from '../../../services/login.service';
import { environment } from 'environments/environment';
import { AuthGuard } from '../../../services/auth-guard.service';
import { Modal } from '../../../collections/modal';
import { REGISTERLITERALS } from 'app/resources/register-literals';
import { ACCOUNTS } from 'app/resources/account.model';
import { SecurityService } from '../../../services/security-registration.service';
import { ERRORSFORM } from 'app/resources/errors-form';

@Component({
  selector: 'app-reactive-email',
  templateUrl: './reactive-email.component.html',
  styleUrls: ['./reactive-email.component.scss'],
  providers: [FormValidatorService,
    ModalService,
    DeviceService,
    RecoveryService,
    ApiRecoveryService]
})
export class ReactiveEmailComponent implements OnInit {
  public _isTokenized: boolean = false;
  private isEqualsValid: boolean = true;
  private isEmptyInput: boolean = false;
  modalLegal = 'legal-modal';
  modalLegalCI = 'legal-modal-ci';
  public modal = new Modal();
  loading: boolean = false;
  isCheckedPublicidad : boolean;
  isCheckedReactiveCheckConditions : boolean;
  public emailPhoneForm: FormGroup;
  private email: Boolean;
  private persona: Persona;
  private phoneNumber: Boolean = true;
  private recoveryToken: string;
  private clientID: string;
  private isSecondSigner: boolean = false;
  private signatureId: string;
  private codProduct: string;
  private origenPromocion: string;
  private codOriginacion = true;
  public loginUser: FormGroup;
  public action = 'onboarding';
  //propiedad para activar/desactivar otp primer paso
  activeOtpFirstStep: boolean = true;
  public confirmEmailPhone = {
    text: '',
    class: '',
    errorText: [],
    valid: false,
    pristine: false,
    equals: false,
    filled: false,
    isEmail: false,
    isPhone: false,
    isChecks: false,
    isPhoneIncomplete: false,
  }
  public isYoungAccount: boolean = location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1;

  public literals = REGISTERLITERALS[(this.isYoungAccount)?'young':'default'];
  public literals_mod = REGISTERLITERALS['modal'];
  public errors = ERRORSFORM;

  @ViewChild('ephone') private ephone: ElementRef;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    public modService: ModalService,
    public deviceService: DeviceService,
    private recoveryService: RecoveryService,
    private analytics: AnalyticsService,
    private specificAnalytics: SpecificAnalyticsService,
    private helpers: HelperService,
    private loginSrv: LoginService,
    private authGuard: AuthGuard,
    private securitySrv: SecurityService,
    private otpSrv: OtpService
  ) {
    this.createForm();
    this.createFormLogin();
    this.initModal();
  }

  initModal() {
    this.modal = Object.assign({
      title : '',
      message : ' Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.'
    })
  }

  ngOnInit() {
    this.authGuard.lastStep = 0;
    this.authService.pageSubmitted = "";
    this.getQueryParams();
    this.errService.mobileErrors(this.emailPhoneForm);
    this.sessionControl();
  }

  ngAfterContentInit() {
    if (!this.isTokenizedURL) {
      this.ephone.nativeElement.focus();
    }
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.token) { this.recoveryToken = params.token };
      if (params.clientId) { this.clientID = params.clientId};
      if (params.isSecondSigner) { this.isSecondSigner = params.isSecondSigner};
      if (params.signatureId) { this.signatureId = params.signatureId};
      if (params.codProductoContratado) { this.codProduct = params.codProductoContratado};
      if (!!params.codRecommendation) { this.origenPromocion = params.codRecommendation};
      if (!!params.codOriginacion) {
        if (params.codOriginacion === 'false'){
          this.codOriginacion = false;
        }
      };
    });
  }

  get isTokenizedURL() {
    return (this.recoveryToken && this.clientID);
  }

  sessionControl() {
    if (this.isTokenizedURL) {
      this._isTokenized = true;
      this.loading = true;
      this.authService.product = this.codProduct;
      this.recoveryService.recoveryValidation(this.recoveryToken,
        this.clientID, this.isSecondSigner, this.signatureId, this.codProduct).subscribe(isValid => {
          this.loading = false;
          this.analytics.triggerFormData('Url tokenizada', isValid);
          this.action = 'abandono';
        }, _error => {
          this._isTokenized = false;
          this.loading = false;
          this.modal = this.modService.selectStatusModal({ codigo: '1001' });
          this.modService.open(this.modal.name);
        });
    }
    else {
      this.authService.deletePreviousSession();
    }
  }
  setFields() {

    const phone = this.confirmEmailPhone.isPhone ?
      this.emailPhoneForm.get('reactiveEmailPhone').value : undefined;

    const email = this.confirmEmailPhone.isEmail ?
      this.emailPhoneForm.get('reactiveEmailPhone').value : undefined;

    const canal = this.deviceService.getDevice();
    const codProductoContratado = (this.isYoungAccount) ? ACCOUNTS.CJCode : ACCOUNTS.CICode;
    const codOrigenPromocion = this.origenPromocion || '';
    this.authService.product = codProductoContratado;
    this.persona = Object.assign({

      canal: canal,
      estado: 'registro',
      codigoEntidad: "0239",
      origenPromocion: codOrigenPromocion,
      datosPersonales: {
        oficinaCliente: environment.oficina,
        telefonoMovil: phone,
        email: email
      },
      otrosDatos: {
        indPubliPe: this.helpers.returnValue(this.emailPhoneForm.get('publicidad')) || false
      },
      activeOtpFirstStep: this.activeOtpFirstStep,
      promocion: this.codOriginacion,
      indConsentimientoTratamientoTipoA: this.helpers.returnValue(this.emailPhoneForm.get('reactiveCheckConditions')) || false,
    });
    sessionStorage.setItem('persona', JSON.stringify(this.persona));
  }

  checkCondiciones() {
    this.emailPhoneForm.controls['reactiveCheckConditions'].setValue(true);
    this.modService.close(this.modalLegal);
  }

  closeModalCi() {
    this.modService.close(this.modalLegalCI);
  }

  checkCondicionesCI(value: boolean) {
    this.emailPhoneForm.controls['publicidad'].setValue(value ? value : this.isCheckedPublicidad);
    this.emailPhoneForm.controls['reactiveCheckConditions'].setValue(value ? value : this.isCheckedReactiveCheckConditions);
    if (!this.emailPhoneForm.get('reactiveCheckConditions').value) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        title : '¡Ups!',
        message : 'Debes haber aceptar los términos y condiciones que tienen * .',
        withImage : true
      })
      this.modService.open(this.modal.name);
      return false;
    } else {
      this.specificAnalytics.emitRegisterType(this.isMobileRegistered);
      this.helpers.navigateTo('/confirmacion-otp-primer-paso');
    }
  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: ['', Validators.required],
      pass: ['', Validators.required]
    });
  }

  triggerErrorAnalytics() {
    const noLegal = ', Condiciones legales no firmadas';
    const invalidFields = this.errService.errorsArray.map(error => {
      return error = error.text
    })

    let invalidString = invalidFields.join(', ');

    if (!this.emailPhoneForm.get('reactiveEmailPhone').valid && this.errService.errorsArray.length === 0) {
      invalidString = invalidString.concat('Móvil o email no válido')
    }

    if (!this.emailPhoneForm.get('reactiveCheckConditions').value) {
      invalidString = invalidString.concat(noLegal);
    }

    if (invalidString === noLegal) {
      invalidString = invalidString.replace(', ', '')
    }

    if (invalidString !== '')
      return this.analytics.triggerFormData('Selección identificación', `Envio KO: ${invalidString}`);
  }

  onSubmit() {
    Object.keys(this.emailPhoneForm.controls).forEach(key => {
      this.emailPhoneForm.get(key).markAsTouched();
      this.emailPhoneForm.get(key).markAsDirty();
    });

    this.triggerErrorAnalytics();
    sessionStorage.setItem('urlOrigen', window.location.href);
    if (!this.emailPhoneForm.get('reactiveEmailPhone').valid) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        title : '¡Ups!',
        message : 'Rellena el campo con un formato correcto.',
        withImage : true
      })
      this.isEmptyInput = true;
      this.modService.open(this.modal.name);
      return false
    }

    if (!this.emailPhoneForm.get('reactiveCheckConditions').value) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        title : '¡Ups!',
        message : 'Debes haber leído y aceptado los términos y condiciones legales para poder continuar.',
        withImage : true
      })
      this.modService.open(this.modal.name);
      this.emailPhoneForm.get('reactiveCheckConditions').setErrors({ notChecked: true });
      return false;
    } else {
      this.specificAnalytics.emitRegisterType(this.isMobileRegistered);
      this.helpers.navigateTo('/confirmacion-otp-primer-paso');
    }
  }

  changeLegalCondiciones(openModal) {
    if (!this.emailPhoneForm.get('reactiveCheckConditions').value){
      //this.modService.open(this.modalLegal);
      this.emailPhoneForm.controls['reactiveCheckConditions'].setValue(true);
    }
    else {
      this.emailPhoneForm.controls['reactiveCheckConditions'].setValue(false);
    }
    if(openModal){
      this.modService.open(this.modalLegal);
    }
  }
  modalOpenCondiciones() {
    if (!this.emailPhoneForm.get('reactiveCheckConditions').value){
      this.modService.open(this.modalLegal);
    }
    else {
      this.emailPhoneForm.controls['reactiveCheckConditions'].setValue(false);
    }
  }
  modalOpenCondicionesCI() {
    if (!this.emailPhoneForm.get('reactiveEmailPhone').valid) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        title : '¡Ups!',
        message : 'Rellena el campo con un formato correcto.',
        withImage : true
      })
      this.isEmptyInput = true;
      this.modService.open(this.modal.name);
      return false
    }else{
      this.modService.open(this.modalLegalCI);
    }
  }

  selectCheck(e) {
    if (e.key !== ' ') return
    e.preventDefault();
    this.helpers.updateFieldValue(this.emailPhoneForm, 'reactiveCheckConditions', !e.currentTarget.parentElement.firstElementChild.checked)
  }
  selectCheckPubli(e) {
    if (e.key !== ' ') return
    e.preventDefault();
    this.helpers.updateFieldValue(this.emailPhoneForm, 'publicidad', !e.currentTarget.parentElement.firstElementChild.checked)
  }

  openLegal() {
    this.modService.open(this.modalLegal);
  }

  sendProspect() {

  }

  goToMap() {
    window.open('https://www.evobanco.com/donde-estamos/');
  }

  isFormFilled(initForm, lengthField) {

    if (initForm.reactiveEmailPhone && initForm.reactiveEmailPhone.length > lengthField &&
      initForm.reactiveEquals.length > lengthField) {
      return true;
    }
    if (initForm.reactiveEmailPhone === initForm.reactiveEquals) {
      return true;
    }
    return false;
  }

  updateFormProperties(initForm) {
    const emailRexp = /.+@.+\..+/i,
      phoneRexp = /^[6-7][0-9]{8}|8{9}/i,
      phoneIncomplete = /^[6-7][0-9]/i,
      isEmail = emailRexp.test(initForm.reactiveEmailPhone),
      isPhone = phoneRexp.test(initForm.reactiveEmailPhone),
      isPhoneIncomplete = phoneIncomplete.test(initForm.reactiveEmailPhone),
      lengthField: number = isPhone ? 8 : 6;

    return {
      textField: isPhone ? 'móvil' : 'correo',
      textClass: isPhone ? 'phone' : 'email',
      isEmail: isEmail,
      isPhone: isPhone,
      isPhoneIncomplete: isPhoneIncomplete,
      filled: this.isFormFilled(initForm, lengthField),
      equals: this.helpers.hasEqualsChars(initForm.reactiveEmailPhone, initForm.reactiveEquals) && this.emailPhoneForm.get('reactiveEquals').valid,
      isChecks: initForm.reactiveCheckConditions === true
    }
  }

  setFirstTooltip(_reactiveEmailPhone) {
    if (this.isEmptyInput) {
      return this.errService.getErrorFromField('reactiveEmailPhone');
    } else {
      return this.errService.reactiveDesktopErrors(_reactiveEmailPhone, 'reactiveEmailPhone');
    }
  }
  setClass(camp) {
    if (this.confirmEmailPhone.equals) {
      return this.confirmEmailPhone.class;
    } else  {
      if (!this.phoneNumber) {
        return this.confirmEmailPhone.class;
      } else {
        return 'error';
      }
    }
  }
  setFirstInputClass(ephone) {
    if (this.isEmptyInput) {
      return 'error';
    }
    if (ephone.value.length < 5) {
      return '';
    } else {
      if (this.confirmEmailPhone.valid) {
        return this.confirmEmailPhone.class;
      } else {
        return 'error';
      }
    }

  }
  setSecondInputClass(dequals) {
    if (!this.isEqualsValid) {
      return 'error';
    }
    if (dequals.value.length < 1)
      return this.confirmEmailPhone.class;
    else
      if (this.confirmEmailPhone.equals) {
        return this.confirmEmailPhone.class;
      } else  {
        return 'error';
      }
  }

  setDivClass(dequals) {
    if (!this.isEqualsValid) {
      return '';
    }
    if (dequals.value.length < 1)
      return this.confirmEmailPhone.class;
    else
      if (this.confirmEmailPhone.equals)
        return this.confirmEmailPhone.class
      else
        return '';
  }

  setPropertiesForm(formProperties) {

    this.confirmEmailPhone = Object.assign({
      text: 'Confirma tu ' + formProperties.textField,
      class: formProperties.textClass,
      errorText: [{ Error: 'Formato de ' + formProperties.textField + ' no válido' }],
      valid: this.emailPhoneForm.get('reactiveEmailPhone').valid,
      pristine: this.emailPhoneForm.get('reactiveEmailPhone').pristine,
      equals: formProperties.equals,
      filled: formProperties.filled,
      isEmail: formProperties.isEmail,
      isPhone: formProperties.isPhone,
      isChecks: formProperties.isChecks,
      isPhoneIncomplete: formProperties.isPhoneIncomplete
    });
  }

  private createForm() {

    this.emailPhoneForm = this.fb.group({
      reactiveEmailPhone: ['', this.evoValidator.validateEmailPhone],
      reactiveEquals: ['', Validators.required],
      reactiveCheckConditions: [false, Validators.required],
      publicidad: [false]
    });

    this.formSubscribe();

  }

  private formSubscribe() {
    this.emailPhoneForm.get('reactiveEmailPhone').valueChanges.subscribe(() => {
      this.isEmptyInput = false;
      if (!this.confirmEmailPhone.valid) {
        this.helpers.updateFieldValue(this.emailPhoneForm, 'reactiveEquals');
      }
    })

    this.emailPhoneForm.get('reactiveEquals').valueChanges.subscribe(equals => {
      this.isEqualsValid = true;
    })

    this.emailPhoneForm.valueChanges.subscribe(emailForm => {
      const formProperties = this.updateFormProperties(emailForm);

      this.setPropertiesForm(formProperties); //always before of setFields
      this.setFields(); //assign values to the global object persona
      this.phoneNumber = isNaN(emailForm.reactiveEquals) && this.confirmEmailPhone.isPhone;

      this.errService.reactiveErrors(this.emailPhoneForm);
    });
  }

  get isMobileRegistered() {
    return this.persona.datosPersonales.telefonoMovil ? 'móvil' : 'correo';
  }


}
