<loading [showMe]="loading"></loading>

<mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone>

<modal [modalTitle]="'Cobertura Médica'" [removePadding]='true' [blocking]='false' [modalId]='modalCoverage' class='modal'>
    <div class="container">
        <micro-coverage (selectCoverage)="onSelectCoverage()"></micro-coverage>
    </div>
</modal>

<modal [modalTitle]="'Modal Error'" [blocking]='false' [modalId]='modalError' class='modal'>
    <div class="container">
        <h2>¡Ups!</h2>
        <p class="invalid-text">{{messageError}}</p>
    </div>
</modal>

<div class="configure">
    <div class="image"></div>
    <div class="container">
        <div class="title-container">
            <legend>
                <h2>TU EVO PROTECT DEPORTES</h2>
                <p>Configura tu EVO PROTECT DEPORTES.</p>
            </legend>
        </div>
        <div class="main-content">
            <form [formGroup]="configureForm"
                (keydown.enter)="$event.preventDefault()"
                (ngSubmit)="onSubmit()" novalidate>
                <div class="row">
                    <!-- Actividad -->
                    <div class="mobile-container">
                        <label for="actividad">Actividad a asegurar</label>
                        <micro-input-modal formControlName="actividad" id="actividad" name="actividad" placeholder="Selecciona una actividad" arrow="true" [modalId]="'modalActivities'">
                            <selector-option
                            [setItems]="sports"
                            title="ACTIVIDAD"
                            warm="La práctica de estas actividades cuenta con un límite de hasta 5.000 metros de altura."
                            tipoOption="tipoDeporte"
                            [selectedItem]="selectedActivity">
                            </selector-option>
                        </micro-input-modal>
                    </div>
                    <!-- Lugar -->
                    <div class="mobile-container">
                        <label for="actividad">Lugar de actividad</label>
                        <micro-input-modal formControlName="region" id="region" name="region" placeholder="Selecciona un lugar" arrow="true" [modalId]="'modalRegion'">
                            <selector-option
                            [setItems]="regions"
                            title="LUGAR DE LA ACTIVIDAD"                           
                            tipoOption="tipoRegion"
                            [selectedItem]="selectedRegion">
                        </selector-option>
                        </micro-input-modal>
                    </div>
                </div>
                <div class="row">
                    <!-- Fecha -->
                    <div class="mobile-container">
                        <label for="actividad">Fecha y hora de inicio</label>
                        <micro-input-modal formControlName="date" id="date" name="date" placeholder="Selecciona una fecha y hora de inicio" [modalTitle]="'Fecha y hora de inicio'" [modalId]="'modalDate'" [classModal]="'datepicker'">
                            <micro-datepicker [selectedDate]="selectedDate.date"></micro-datepicker>
                        </micro-input-modal>
                    </div>
                    <!-- Capital -->
                    <div class="mobile-container" *ngIf="capitalsGet.length > 0" [@shrinkOut]>
                        <label for="capital">Selecciona el capital asegurado en caso de accidente</label>
                        <custom-radio-buttons formControlName="capital" id="capital" name="capital" [options]="capitalsGet" getPosition="true" (onPosition)="onPosition($event)"></custom-radio-buttons>
                    </div>
                </div>

                <div class="row row-adjust row-inverse">
                    <!-- Huso horario -->
                    <div class="mobile-container">
                        <label for="actividad">Zona horaria (Lugar de la actividad)</label>
                        <micro-input-modal formControlName="timezone" id="timezone" name="timezone" placeholder="Selecciona una zona horaria" arrow="true" [modalId]="'modalTimeZone'" [description]="selectedTimeZone.description">
                            <selector-option
                            [setItems]="timezones"
                            title="Zona horaria de la actividad"
                            warm="Elige la zona horaria donde tendrá lugar la actividad"
                            tipoOption="tipoTimeZone"
                            [selectedItem]="selectedTimeZone"></selector-option>
                        </micro-input-modal>
                        <br><br>
                        <!-- Duración -->
                        <ng-container *ngIf="durations.length > 0">
                            <label for="duracion">Duración (mínimo 3h. - máximo 5 días)</label>
                            <custom-radio-buttons formControlName="duration" id="duration" name="duration" [options]="durations" getPosition="false" flexwrap="true" (onValue)="onDuration($event)"></custom-radio-buttons>
                            <h3 *ngIf="selectedDuration && selectedDate.value!=undefined">Hasta el {{dateEndActivity.dateText}}</h3>
                        </ng-container>
                    </div>
                    <div class="mobile-container without-space">
                        <!-- Info content -->
                        <info-content class="desktop" *ngIf="selectedCapital" [position]="position" [positionInit]="positionInit" [valueDead]="valueDead" [valueInvalidParcial]="valueInvalidParcial" [valueInvalidAbsolute]="valueInvalidAbsolute"></info-content>
                        <br class="mobile-only">
                        <br class="mobile-only">
                        <!-- Coberturas gastos médicos -->
                        <div *ngIf="durations.length > 0">
                            <label>Cobertura gastos médicos<span (click)="openModal(modalCoverage)"><img class="icon-info" src="assets/images/icon-info.svg"></span></label>
                            <div class="group-radio-vertical">
                                <div class="options wrap-normal">
                                    <input type="radio" id="1" value="1" formControlName="cobertura" name="cobertura">
                                    <label class="button-label" for="1">
                                        <p class="color-white">Añadir coberturas de gastos médicos <span *ngIf="coverageHeath > 0">por</span>
                                            <strong *ngIf="coverageHeath > 0">{{coverageHeath | number:'1.2-2' | currencycustom}}</strong>
                                        </p>
                                    </label>
                                    <br><br>
                                    <input type="radio" id="0" value="0" formControlName="cobertura" name="cobertura">
                                    <label class="button-label" for="0"><p class="color-white">Sin coberturas  de gastos médicos</p></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row row-adjust">
                    <!-- Tarificación calculada -->
                    <div class="mobile-container" [style.visibility]="tarificationShow ? 'visible': 'hidden'">
                        <p class="price-hour">{{ pricePerDuration.value | number:'1.2-2' | currencycustom }}/{{pricePerDuration.label}}</p>
                        <div class="your-evo-protect">Tu <span class="title">EVO PROTECT</span> por <span class="price">{{ totalPrice | number:'1.2-2' | currencycustom }}</span></div>
                    </div>
                    <!-- Contratar -->
                    <div class="mobile-container">
                        <div class="btn-group">
                            <button type="submit" class="btn-common btn-common_fix_bold">CONTRATAR</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
