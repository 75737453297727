<loading [showMe]="loading"></loading>

<modal [modalTitle]="'Cancelar Boletín de Adhesión'" [blocking]='false' [modalId]='modalCancel' class='modal'>
    <div class="container-modal">
      <h2></h2>
      <p><strong>Cancelar el Boletín de Adhesión</strong></p>
      <p class="w90">
        implica que no podrás activar o desactivar ninguna cobertura de tu seguro EVO Protect Deportes.
      </p>
      <br><br>
      <p>¿Desea continuar?</p>
      <div class="btn-group">
        <button type="button" class="btn-common light btn-common_fix_bold" (click)="abortCancelAdhesion()">NO CONTINUAR</button>
        <button type="button" class="btn-common btn-common_fix_bold" (click)="acceptCancelAdhesion()">CONTINUAR</button>
      </div>
    </div>
</modal>

<modal [modalTitle]="'Tienes seguros en vigor'" [blocking]='false' [modalId]='modalSeguros' class='modal'>
    <div class="container-modal">
      <h2></h2>
      <p class="w90">
        <strong>Tienes seguros en vigor o próximos a entrar en vigor.</strong>
      </p>
      <br><br>
      <p class="w90">No puedes cancelar tu seguro EVO Protect si tienes una cobertura activa.</p>
      <div class="btn-group">
        <button type="button" class="btn-common btn-common_fix_bold" (click)="closeModal(modalSeguros)">ACEPTAR</button>
      </div>
    </div>
</modal>

 <modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
    <div class="container">
      <h2>¡Ups!</h2>
      <p class="invalid-text">{{msgSrvError}}</p>
    </div>
</modal>

<header-profile class="mobile"></header-profile>

<div class="heading">
    <h3>EVO PROTECT DEPORTES</h3>
    <h4>Gestiona todo tu EVO Protect Deportes usando la app móvil</h4>
</div>

<app-breadcrumb id="breadcrumb" class="mobile" [breadcrumbs]="breadcrumbNav" breadcrumbAdd="MI EVO PROTECT"></app-breadcrumb>

<div class="my-evo-protect">
    <div class="image"></div>
    <div class="container">
      <div class="content-top">
        <div class="title-container pad010">
          <div class="text-title">
              <div class="text">Gestionar Boletín de Adhesión</div>
          </div>
          <legend>
            <div class="main-icon"></div>
          </legend>
        </div>
        <div class="row pad010">
          <p>Desde aquí puedes gestionar tu Boletín de Adhesión.</p>
        </div>
      </div>

      <div class="container-how-manage">
          <div class="container-text">
              <h2>¿CÓMO GESTIONAR TUS EVO PROTECT DEPORTES?</h2>
              <ul class="square-bullet">
                <li><span>Toda la información la tienes en tu <strong class="text-blue">Banca Móvil Inteligente.</strong></span></li>
                <li><span>Pulsa en el menú y accede a <strong class="textbold">EVO PROTECT DEPORTES.</strong></span></li>
                <li><span>Podrás consultar:</span></li>
                <ul class="square-bullet">
                  <li><span>Si estás adherido a la póliza.</span></li>
                  <li><span>El histórico de actividades por estado: en vigor, cancelados y disfrutados.</span></li>
                </ul>
                <li><span>Podrás gestionar:</span></li>
                <ul class="square-bullet">
                  <li><span>Cancelación y adhesión a la póliza, recuerda que la adhesión es completamente gratuita.</span></li>
                  <li><span>Activación y cancelación de las actividades.</span></li>
                </ul>
            </ul>
              <h3>¿Todavía no tienes nuestra app?</h3>
              <p>Descárgatela ahora:</p>
              <div class="container-stores">
                <div class="apple-store" (click)="goToAppStore()"></div>
                <div class="play-store" (click)="goToGooglePlay()"></div>
              </div>
          </div>
          <div class="app-mobile"></div>
      </div>

      <div class="content-clear">
        <h2>BOLETÍN DE ADHESIÓN</h2>
        <p class="info-first">Te adheriste al contrato el {{ dateRegistryPolicty }}.
          <br>
          Tu adhesión se renovará automáticamente cada año.
        </p>
        <p class="info-second">
          Para activar o desactivar cualquier cobertura de tu seguro EVO Protect Deportes es necesario estar adherido al boletín.
        </p>
        <p class="info-second">
            Adherirte al boletín no tiene ningún coste. Recuerda que solo pagarás por la cobertura que actives en cada momento.
        </p>
        <div class="view-adhesion">
          <a role="option" (click)="downloadContract()">
            <div class="icon view-icon"></div>
            <span class="view-bulletin">Ver Boletín de Adhesión</span>
          </a>
          <a role="option" class="cancel-button" (click)="onClickCancel()">
            <div class="icon delete-icon"></div>
            <span>Cancelar Boletín de Adhesión</span>
          </a>
        </div>
      </div>
    </div>
</div>

<app-footer class="mobile"></app-footer>
