<loading [showMe]="loading"></loading>

<mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone>

<modal [modalTitle]="'Modal Error'" [blocking]='false' [modalId]='modalError' class='modal'>
  <div class="container">
      <h2>¡Ups!</h2>
      <p class="invalid-text">{{msgError}}</p>
  </div>
</modal>

<modal [modalTitle]="'No tienes saldo suficiente'" [blocking]='false' [modalId]='modalBalance' class='modal'>
  <div class="container-modal">
    <h2></h2>
    <br><br>
    <p class="w90">
      <strong>{{ messageErrorNoBalance }}</strong>
    </p>
    <br><br>
    <div class="btn-group">
      <button type="button" class="btn-common  w50 btn-common_fix_bold" (click)="goToProfile()">VOLVER</button>
    </div>
  </div>
</modal>

<div class="method-payout">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
          <h2>SELECCIONA MEDIO DE PAGO</h2>
          <p>Pagar con:</p>
      </legend>
    </div>
    <form [formGroup]="payoutForm"
    (keydown.enter)="$event.preventDefault()"
    (ngSubmit)="onSubmit()" novalidate>
    <div class="main-content">
      <div class="group-radio-vertical">
        <div class="options row">
          <div class="col" *ngFor="let account of accounts">
            <input type="radio" [ngClass]="setStateAccount(account.disponible)" [id]="account.index" [value]="account.iban" name="cuenta" formControlName="cuenta">
            <label class="button-label round" [ngClass]="setStateAccount(account.disponible)" [for]="account.index"><span class="color-white">{{ account.tipo | uppercase}}</span></label>
            <div class="info-account">
              <p class="iban" [ngClass]="setStateAccount(account.disponible)"><strong>{{account.iban.substring(0, 4)}}</strong> {{ account.iban.substring(4)}}</p>
              <p class="available not" *ngIf="!account.disponible" [ngClass]="setStateAccount(account.disponible, true)">No dispone saldo suficiente</p>
              <p class="condition" [ngClass]="setStateAccount(account.disponible)">Eres {{ account.condicion }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-group">
          <button type="submit" class="btn-common btn-common_fix_bold" [disabled]="disabledForm">CONTINUAR</button>
      </div>
    </div>
    </form>
  </div>
</div>
