<div-show [showMe]="showError" [nameError]="nameError"></div-show>
<section class="c-header" *ngIf="showHeader">
  <div class="responsive-header">
    <div class="page-title">
      <span>Confirmación</span>
    </div>
    <div class="closebutton">
      <i class="evo close"></i>
    </div>
  </div>
</section>
<form class="form" class="form" action="">
    <div class="main-content">
      <section class="info">
        <p class="info-title">{{titulo}}</p>
        <div class="info-description_img">
          <i class="evo icon-okhand info-description_img--icon"></i>
          <p class="info-description">{{subtitulo}}</p>
        </div>
      </section>
      <section class="cards">
        <div class="cardselected">
          <span class="cardselected-title">Tarjeta seleccionada</span>
        </div>
        <div class="cardinfo">
          <div class="cardinfo-item">
              <i class="evo icon-creditcard cardinfo-item-img"></i>
            <div class="cardinfo-data">
              <p class="title">Tarjeta de crédito</p>
              <p class="description">************{{lastNum}}</p>
            </div>
          </div>
          <div class="cardinfo-item" >
              <i class="evo open-wallet cardinfo-item-img"></i>
            <div class="cardinfo-data">
              <p class="title">Límite actual</p>
              <p class="description">{{limiteActual}} €</p>
            </div>
          </div>
        </div>
        <div class="carddata" *ngIf="mostrarInfo">
          <p class="title">Resumen</p>
          <p class="description">Nuevo límite de crédito solicitado:</p>
          <p class="title_price">{{myLimit}} €</p>
          <div class="carddata-message_resume" *ngIf="!msgRecuerda">
            <div class="resume-img">
              <i class="evo icon-alien"></i>
            </div>
            <p *ngIf="!bajadaLimite" class="resume-info"> 
              En los próximos días recibirás un correo electrónico con la respuesta a tu petición</p>
            <p *ngIf="bajadaLimite" class="resume-info">
                <span class="resume-info_bold">Recuerda: </span>
                El importe pendiente que tengas en tu tarjeta, se cargará al mes siguiente con independencia de tu forma
                de pago, siempre y cuando el saldo dispuesto sea superior a tu nueva línea de crédito.
             </p>
          </div>
          <div class="carddata-message_resume" *ngIf="msgRecuerda">
            <div class="resume-img">
              <i class="evo icon-alien"></i>
            </div>
            <p *ngIf="!bajadaLimite" class="resume-info">
            <span class="resume-info_bold">Recuerda: </span>
            En los próximos días recibirás un correo electrónico con la respuesta a tu petición</p>
            <p *ngIf="bajadaLimite" class="resume-info">
              <span class="resume-info_bold">Recuerda: </span>
              El importe pendiente que tengas en tu tarjeta, se cargará al mes siguiente con independencia 
              de tu forma de pago, siempre y cuando el saldo dispuesto sea superior a tu nueva línea de crédito.
            </p>
          </div>
        </div>
      </section>
      <div class="link-arrow" *ngIf="showHeader">
        <a (click)="close()">
          Cerrar
          <i class="evo right-arrow"></i>
        </a>
      </div>
    </div>
  </form>