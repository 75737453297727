import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { HelperService } from 'app/services/helpers.service';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { NavigationMicroService } from 'app/services/navigation-micro.service';
import { AuthGuardMicro } from 'app/services/auth-guard-micro.service';
import { AnalyticsService } from 'app/services/analytics.service';

@Component({
  selector: 'app-cancel-adhesion-ok',
  templateUrl: './cancel-adhesion-ok.component.html',
  styleUrls: ['./cancel-adhesion-ok.component.scss']
})
export class CancelAdhesionOkComponent implements OnInit, OnDestroy {


  constructor(private helperService: HelperService,
              private authguard: AuthGuardMicro,
              private authService: AuthMicroService,
              public analytics: AnalyticsService) { }

  ngOnInit() {
    sessionStorage.setItem("reloadUserData", "true");
    this.analytics.cancelAdhesionbulletin(2);
  }

  goToProfile() {
    this.helperService.navigateTo("/perfil");
  }

  ngOnDestroy() {
    this.authService.deleteMicroProtectSession();
    this.authguard.empty();
  }

}
