import { Component, HostListener, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ESTADO_CIVIL } from 'app/resources/civil-status.model';
import { GENEROS } from 'app/resources/genders.model';
import { PAISES } from 'app/resources/countries.model';
import { REGIMENES } from 'app/resources/regimen-economico.model';
import { DOCUMENTOS } from 'app/resources/document.model';
import { PROVINCES } from 'app/resources/provincias.model';
import { ACCOUNTS } from 'app/resources/account.model';

import { Register } from 'app/collections/register';
import { Persona } from 'app/collections/persona';

import { AuthService } from 'app/services/auth.service';
import { AuthUserService } from 'app/services/auth-user.service';
import { ApiLocalidadesService } from 'app/services/api/api.localidades.service';
import { LocalidadesService } from 'app/services/localidades.service';
import { FormValidatorService } from 'app/services/form-validator.service';
import { ErrorService } from 'app/services/error.service';
import { ModalService } from 'app/services/modal.service';
import { DeviceService } from 'app/services/device.service';
import { JsonService } from 'app/services/json.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { HelperService } from 'app/services/helpers.service';
import { HeaderService } from 'app/services/headers.service';
import { InjectScriptService } from 'app/services/inject-script.service';
import { LoginService } from 'app/services/login.service';
import { PersonaService } from 'app/services/persona.service';

import { Modal } from 'app/collections/modal';
import { environment } from 'environments/environment';
import { ProductSelection } from 'app/services/product-selection.service';
import { User } from 'app/collections/user';
import { SpecificAnalyticsService } from 'app/services/specific-analytics.service';
import { SecurityService } from 'app/services/security-registration.service';
import { ERRORSFORM } from 'app/resources/errors-form';


@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
  providers: [FormValidatorService,
    ModalService,
    DeviceService,
    JsonService,
    LocalidadesService,
    ApiLocalidadesService
  ]
})

export class PersonalDetailsCICJComponent implements OnInit {

  loading = false;
  personalDetailsForm: FormGroup;
  personalDetailsValues: Register;
  private persona: Persona = this.authService.getSessionProspect();
  firstElement: any;
  modalTipoA = 'legal-modal';
  modal = new Modal();
  public bureauModal= 'bureauModal';
  alreadyClientModal = 'already-client';
  telephoneMsg = '<a class="link-blue" href="tel:910900900">910 900 900<a/>';
  exceptionMsg = 'Lo sentimos, no podemos seguir con la solicitud online. Llámanos al ' + this.telephoneMsg + ' y te explicamos los motivos.';
  incompleteMsg = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.';
  dniErrorMsg = 'Parece que ha habido algún error. Por favor, vuelve a introducir tus datos.';
  public modalBureau = false;
  documents = DOCUMENTOS;
  countries = PAISES;
  genders = GENEROS;
  provinces = PROVINCES;
  civilStatus = ESTADO_CIVIL;
  economicSystems = REGIMENES;
  localizations;
  loginUser: FormGroup;
  errMsg;
  private passStr = '';
  public firstTouch = true;
  public firstTouchPrevented = true;
  public isYoungAccount = false;
  private userData: User;
  modalLog  = false;
  dniExist = false;
  public idUser: string;
  public mouseover = false;
  public mostrarFaq = false;
  // Propiedad para activar/desactivar otp primer paso
  activeOtpFirstStep = true;
  sessionDatosPrimerTitular: any;
  public codigoRetornoDni: number;
  // Variables collapse
  public estadoCollapse0 = false;
  public estadoCollapse1 = false;
  public estadoCollapse2 = false;
  public estadoCollapse3 = false;
  public estadoCollapse4 = false;
  public errors = ERRORSFORM;

  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    this.authService.preventAbandon(this, $event);
  }

  constructor(private fb: FormBuilder,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    public modService: ModalService,
    private authService: AuthService,
    private authUserService: AuthUserService,
    private loginSrv: LoginService,
    public deviceService: DeviceService,
    private jsonService: JsonService,
    private analytics: AnalyticsService,
    private specificAnalytics: SpecificAnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService,
    private product: ProductSelection,
    private inject: InjectScriptService,
    private personaSrv: PersonaService,
    private securitySrv: SecurityService
  ) {
    this.createForm();
    this.createFormLogin();
    this.initModal();
  }

  private createForm() {
    // Si SF devuelve la cadena "A RELLENAR" en los apellidos, se toma como un campo vacío.
    let primerApellido: string = this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.apellido1Cliente') || '';
    if (primerApellido && primerApellido.toUpperCase().indexOf("A RELLENAR") > -1) {
      primerApellido = '';
    }

    this.personalDetailsForm = this.fb.group({
      numeroDocumento: [this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.idExterno') || '', [Validators.required, this.evoValidator.validateID]],
      documento: ['dni', [Validators.required]],
      nombre: [this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.nombreCliente') || '', [Validators.required, this.evoValidator.validateName]],
      primerApellido: [primerApellido || '', [Validators.required, this.evoValidator.validateName]],
      segundoApellido: [this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.apellido2Cliente') || '', []],
      email: [this.jsonService.getKeyFromJSON(this.persona, 'datosPersonales.email') || '',
        [Validators.minLength(1), Validators.maxLength(50), this.evoValidator.validateEmail]],
      reactiveCheckTipoA: [this.jsonService.getKeyFromJSON(this.persona, 'indConsentimientoTipoA') || false, Validators.required],
      reactiveCheckSolvencia: [this.jsonService.getKeyFromJSON(this.persona, 'indPerfiladoCliente') || false],
      publicidad: [false]
    });

    this.formSubscribe();
  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: [''],
      pass: ['']
    });

    this.loginUser.controls.user.valueChanges.subscribe(user => {
      if (user.length === 9) {
        this.validUser();
      } else if (user.length === 0) {
        this.helpers.resetFieldsState(this.loginUser, ['user']);
      }
    });

    this.loginUser.valueChanges.subscribe(() => {
      this.errService.mobileErrors(this.loginUser);
    });
  }



  initModal() {
    this.modal = Object.assign({
      name: 'modal-errores-genericos',
      title : '¡Vaya!',
      withImage : false,
      message : `Tenemos un problema con tu solicitud online.
      <br>
      Llámanos al <a href='tel:910900900'>910 900 900</a> para que podamos ayudarte a continuar.`
    })
  }


  ngOnInit() {
    this.modService.open(this.bureauModal);
    this.idUser = JSON.parse(sessionStorage.getItem('currentProspect')).datosPersonales.id_cliente_EVO;
    this.errService.mobileErrors(this.personalDetailsForm);
    this.firstElement = this.documents[0];
    this.validateDocuments(this.personalDetailsForm.get('documento').value);
    //control if the users has sent the id (email|phone)
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
  }


  setFields(modalLog) {
    const canal = this.deviceService.getDevice();

    if (!modalLog) {
        this.persona = Object.assign({
          canal: canal,
          codigoEntidad: "0239",
          datosPersonales: {
            email: this.helpers.returnValue(this.personalDetailsForm.get('email')),
            nombreCliente: this.helpers.returnValue(this.personalDetailsForm.get('nombre')),
            apellido1Cliente: this.helpers.returnValue(this.personalDetailsForm.get('primerApellido')),
            apellido2Cliente: this.helpers.returnValue(this.personalDetailsForm.get('segundoApellido')),
            oficinaCliente: environment.oficina,
            codigoIdExterno: this.getDocumentType,
            idExterno: this.helpers.returnValue(this.personalDetailsForm.get('numeroDocumento')) ? this.helpers.returnValue(this.personalDetailsForm.get('numeroDocumento')).toUpperCase() : undefined,
          },
          // otrosDatos: {
          //   indPubliPe: this.personalDetailsForm.get('publicidad').value
          // },
          activeOtpFirstStep: this.activeOtpFirstStep,
          indConsentimientoTipoA: this.helpers.returnValue(this.personalDetailsForm.get('reactiveCheckTipoA')) || false,
          indPerfiladoCliente: this.helpers.returnValue(this.personalDetailsForm.get('reactiveCheckSolvencia')) || false,
        });

    } else {

      this.persona = Object.assign({
        canal: canal,
        codigoEntidad: "0239",
        estado: "cliente-existente"
      });
    }
  }

  goToMap() {
    window.open('https://www.evobanco.com/donde-estamos/');
  }

  filterValidControls() {
  }


  sendClient() {
    this.errMsg = undefined;

    this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    this.helpers.updateFormStatus(this.loginUser);

    const passInput = this.loginUser.get('pass');
    const userInput = this.loginUser.get('user');
    if (userInput.invalid || passInput.invalid) {
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: Usuario/Contraseña inválido');
      return
    };

    const login: string = `username=${userInput.value.toUpperCase()}&password=${passInput.value}`;

    this.loading = true;
    const tag = 'GTM-TFMGJVG';
    this.inject.addScriptGoogle('script', tag);
    this.authService.login(login).subscribe(() => {

      if (this.loginSrv.isLoggedIn) {

        if (!this.helpers.isEmpty(this.loginSrv.userData) && !sessionStorage.getItem("reloadUserData")) {

          this.userData = this.loginSrv.userData;
          this.redirectLog();
        }
        else {
          sessionStorage.removeItem("reloadUserData");
          this.authUserService.getUserData().subscribe(data => {
            this.userData = data;
            this.loginSrv.userData = this.userData;
            this.redirectLog();

          }, () => {
            this.errService.rsi = true;
            this.errService.navToError();
          });
        }
      } else {
        this.helpers.navigateTo('/login');
      }

      this.analytics.triggerProfileFormData('Log in', 'Envio OK');

    }, () => {
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: 500 Server error');
      this.loading = false;
      this.errMsg = `Parece que hay algún error, comprueba que has introducido
      correctamente los datos. Recuerda que por seguridad,
      tres errores consecutivos en la contraseña bloquearán el acceso`;
      if (passInput.value.length === 6) {
        this.helpers.updateFieldValue(this.loginUser, 'pass', "");
      }
    });
  }

  redirectLog() {
    if (this.product.product && this.product.product.codProductoContratado === ACCOUNTS.STCode) {

      const listaContratados =  this.userData.listaContratados;
      for (const product of listaContratados) {
        if (product.idProducto === ACCOUNTS.CICode) {
          this.errService.isTitularInteligente = true;
          this.errService.navToError();
          break;
        }
        if (product.idProducto === ACCOUNTS.CJCode) {
          this.errService.isTitularJoven = true;
          this.errService.navToError();
          break;
        }
        this.helpers.navigateTo('confirmacion-datos');
      }


    } else {
      this.helpers.navigateTo('perfil');

    }
  }

  loadUserData() {
    if (this.loginSrv.isLoggedIn) {
      this.loading = true;
      if (!this.helpers.isEmpty(this.loginSrv.userData) && !sessionStorage.getItem("reloadUserData")) {
        this.loading = false;
        this.userData = this.loginSrv.userData;
        console.log("USERDATA", this.userData);
        return
      }
      sessionStorage.removeItem("reloadUserData");
      this.authUserService.getUserData().subscribe(data => {
        this.loading = false;
        this.userData = data;
        console.log("USERDATA2", this.userData);
        this.loginSrv.userData = this.userData;

      }, () => {
        this.errService.rsi = true;
        this.errService.navToError();
      });
    } else {
      this.helpers.navigateTo('/login');
    }
  }

  validUser() {
    const userInput = this.loginUser.get('user');
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    }
    userInput.setErrors(this.evoValidator.validateID(userInput));
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get('pass').value.length < inputMaxLength) {
      this.passStr = this.loginUser.get('pass').value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, 'pass', []);
      this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, 'pass',[Validators.required, Validators.minLength(6)]);
    }
  }

  closeKeyboard() {
    this.helpers.updateFieldValidation(this.loginUser, 'user', []);
    this.helpers.closeLoginKeyboard();
  }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.firstTouchPrevented = false;
    this.passStr = "";
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
  }

  hasNoPassword() {
    // mail with pass
    this.modService.close(this.alreadyClientModal);
  }

  isNotMe() {
    // mail user
    this.modService.close(this.alreadyClientModal);
  }

  triggerErrorAnalytics() {
    const invalidFields = this.errService.errorsArray.map(error => {
      return error = error.text
    })
    const invalidString = invalidFields.join(', ');

    if (invalidString !== '')
      return this.analytics.triggerFormData('Datos personales', `Envio KO: ${invalidString}`);
  }

  onSubmit() {
    this.helpers.updateFormStatus(this.personalDetailsForm);
    //Se comprueba si el formulario es válido
    if (!this.personalDetailsForm.valid) {
      this.modal = Object.assign({
        name: 'modal-errores-genericos',
        title: '¡Ups!',
        withImage: true,
        blocking: false,
        message: this.incompleteMsg
      })

      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }
    else if (!this.personalDetailsForm.get('reactiveCheckTipoA').value) {
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        name: 'modal-errores-genericos',
        title : '¡Ups!',
        message : 'Debes haber leído y aceptado los términos y condiciones legales para poder continuar.',
        withImage : true
      })

      this.modService.open(this.modal.name);
      this.personalDetailsForm.get('reactiveCheckTipoA').setErrors({ notChecked: true });
      window.scrollTo(0, 0);
      return false;
    }

    this.loading = true;
    this.setFields(this.modalLog);
    this.authService.updateProspect(this.persona).subscribe((data) => {
    console.log("dataonsubmit",data)
    if (this.personalDetailsForm.get('numeroDocumento').value == '00000000T' && JSON.parse(sessionStorage.getItem('currentProspect')).datosPersonales.telefonoMovil === '888888888'){
      this.continuarSubmit();
    }

    else {
      if (data.response.codigo !== 'OK') {
        this.loading = false;
        let modalToShow = this.modService.selectStatusModal(data.response);
        this.modal = modalToShow;
        this.analytics.triggerFormData('Datos personales', `Envio KO: ${this.modal.message}`);
        this.modService.open(this.modal.name, this.modal.blocking);
        window.scrollTo(0, 0);
      } else {
        this.dniExist = false;
        this.continuarSubmit();
      }
    }
  });
  }

  continuarSubmit() {
    const incompleteModal = {
            name: 'modal-errores-genericos',
            title: '¡Ups!',
            blocking: false,
            withImage: true,
            message: this.incompleteMsg
          }

    this.helpers.updateFormStatus(this.personalDetailsForm);
    this.triggerErrorAnalytics();

    const numeroDocumento = this.personalDetailsForm.get('numeroDocumento');

    if (numeroDocumento && (numeroDocumento.value.length !== 9 || this.isValidID(numeroDocumento.value) === false)) {

      this.loading = false;
      this.personalDetailsForm.get('numeroDocumento').setErrors({ valid: false });

      this.modal = Object.assign(incompleteModal)
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }
    /**
     * El mensaje aparece si no se ha marcado la casilla de aceptar las condiciones
     */
    if (!this.personalDetailsForm.get('reactiveCheckTipoA').value) {
      this.loading = false;
      window.scrollTo(0, 0);
      this.modal = Object.assign({
        name: 'modal-errores-genericos',
        title : '¡Ups!',
        message : 'Debes haber leído y aceptado los términos y condiciones legales para poder continuar.',
        withImage : true
      })
      this.modService.open(this.modal.name);
      return false;
    } else {
      this.specificAnalytics.emitRegisterType(this.isMobileRegistered);
    }

    if (!this.personalDetailsForm.valid) {
      this.loading = false;
      this.modal = Object.assign(incompleteModal)
      this.modService.open(this.modal.name);
      window.scrollTo(0, 0);
      return false;
    }

    this.sendProspect(this.modalLog);
  }

  sendProspect(modalLog) {

    this.loading = true;
    this.setFields(modalLog);
    this.authService.updateProspect(this.persona).subscribe((data) => {
      this.loading = false;
      if (this.personalDetailsForm.get('numeroDocumento').value === '00000000T') {
        this.continueRegister();
      }
      else {
        if (data.response.codigo !== 'OK') {
          let modalToShow = this.modService.selectStatusModal(data.response);
          this.modal = modalToShow;
          this.analytics.triggerFormData('Datos personales', `Envio KO: ${this.modal.message}`);
          this.modService.open(this.modal.name, this.modal.blocking);
        } else {
          this.continueRegister();
        }
    }
    }, () => {
      this.analytics.triggerFormData('Datos personales', `Envio KO: 500 Server Error`);
      this.handleError()
    });
  }

  continueRegister() {
    const nextStep = '/datos-contacto';
    if (this.authService.isLoggedIn) {
      this.authService.pageSubmitted = 'datos-personal';
      if (this.persona.datosPersonales){
        this.analytics.createHasProperty('mail', this.persona.datosPersonales.email);
        this.analytics.createHasProperty('dni', this.persona.datosPersonales.idExterno);
      }
      this.analytics.triggerFormData('Datos personales', 'Envio OK');

      this.headerSrv.getRefreshToken().subscribe( () => {
        if (this.dniExist === false) {
          this.helpers.navigateTo(nextStep);
        }
      }, error => {
        console.log(error);
      });
    }
  }

  private formSubscribe() {

    this.personalDetailsForm.get('numeroDocumento').valueChanges.subscribe(numero => {
      if (this.isDNI) {this.validateDocuments('dni')};
      if (!this.isDNI) {this.validateDocuments('nie')};
    })


    this.personalDetailsForm.valueChanges.subscribe(form => {
      const numDocumento = this.personalDetailsForm.get('numeroDocumento').value
      if (this.isValidID(numDocumento)) {
        this.authService.saveUpdatedForm(this.personalDetailsForm, this, form);
      }
      this.errService.mobileErrors(this.personalDetailsForm);
    });

  }

  validateDocuments(documento) {

    if (documento === 'dni') {
      this.personalDetailsForm.get('segundoApellido').setValidators([Validators.required, this.evoValidator.validateName]);
    } else {
      this.personalDetailsForm.get('segundoApellido').setValidators([]);
    }

    this.personalDetailsForm.get('segundoApellido').updateValueAndValidity();

    const numDocumento = this.personalDetailsForm.get('numeroDocumento');

    this.helpers.updateFieldValue(this.personalDetailsForm, 'documento', documento);

    if (numDocumento.value.length === 9 && this.isValidID(numDocumento.value) === true) {
          this.checkDNI(numDocumento);
      }
  }

  checkDNI(documento) {
    const consulta = { EE_I_Consulta: { codigoEntidad: "0239", idExterno: documento.value, idCliente: this.idUser } };
    this.loading = true;
    this.setFields(this.modalLog);
    this.authService.checkDuplicateDNI(consulta).subscribe(data => {
      // Si en la respuesta se devuelve data.response, se toma como un error.
      if (data.response) {
        this.modal = Object.assign({
          name: 'modal-errores-genericos',
          title: '¡VAYA!',
          withImage: true,
          blocking: false,
          message: this.dniErrorMsg
        })

        this.modService.open(this.modal.name);
        this.loading = false;
        window.scrollTo(0, 0);

        this.personalDetailsForm.patchValue({numeroDocumento: ''});
        return false;
      }

      this.codigoRetornoDni = data.codigoRetorno;
      if (Number(data.codigoRetorno) === 1 && documento.value !== '00000000T') {
        if (this.persona.datosPersonales.id_cliente_EVO !== data.idCliente) {
          this.persona.datosPersonales.id_cliente_EVO = data.idCliente;
          this.idUser = data.idCliente;
          this.authService.setSessionProspect(this.persona);
          if (data.access_token && data.refresh_token) {
            sessionStorage.setItem('auth_token', data.access_token);
            sessionStorage.setItem('refresh_token', data.refresh_token);
          }
        }
        this.dniExist = true;
        this.modalLog = true;
        this.sendProspect(this.modalLog);
        this.analytics.triggerFormData('Datos Personales', `Envio KO: DNI ya existente`);
        this.initModal();
        this.modal.blocking = false;
        this.modal.withImage = true;
        this.analytics.triggerInteractionData('Log in', 'Pop up DNI ya existente');
        this.modService.open(this.alreadyClientModal);
      }
      else {
        this.dniExist = false;
        this.persona.datosPersonales.id_cliente_EVO = data.idCliente;
        this.authService.setSessionProspect(this.persona);
        if (data.access_token && data.refresh_token) {
          sessionStorage.setItem('auth_token', data.access_token);
          sessionStorage.setItem('refresh_token', data.refresh_token);
        }
      }
      this.loading = false;
    }, () => {
      this.authService.pageSubmitted = '';
      this.handleError()
    })
  }

  handleError() {
    this.analytics.triggerFormData('Datos Personales', `Envio KO: 500 Server error`);
    this.loading = false;
    this.errService.navToError();
  }

  get isDNI() {
    const reg = /^\d+$/;
    return reg.test(this.personalDetailsForm.get('numeroDocumento').value[0]);
  }

  get getDocumentType() {
    return this.personalDetailsForm.get('documento').value === 'dni' ? '51' : '66';
  }

  get hideDNI() {
    return this.getDocumentType === '51';
  }

  get hideNIF() {
    return this.getDocumentType === '66';
  }

  modalClick(_ev) {
    _ev.stopPropagation();
  }

  closeModal() {
    this.modalBureau = false;
    this.helpers.navigateTo('/bienvenido');
  }

  searchSelectCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item['value'] === value && item['value'] !== null) {
        name = item;
      }
    })
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  searchSelectCode(values) {
    let name;
    values.forEach((item) => {
      if (item['selected'] && item['value'] !== null) {
        name = item;
      }
    })
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  private isValidID(value) {
    const invalid = false,
          validChars = 'TRWAGMYFPDXBNJZSQVHLCKET',
          nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
          nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i,
          str = value.toString().toUpperCase(),
          nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2'),
          letter = str.substr(-1),
          charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (str.length !== 9) { return invalid };

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      return invalid;
    }

    if (validChars.charAt(charIndex) === letter) {
      return true;
    }

    return invalid;
  }

  get isMobileRegistered() {
    return this.persona.datosPersonales.telefonoMovil ? 'móvil' : 'correo';
  }

  modalOpenCondiciones(e) {
    e.preventDefault();
    if (!this.personalDetailsForm.get('reactiveCheckTipoA').value) {
      this.modService.open(this.modalTipoA);
    }
    else {
      this.personalDetailsForm.controls['reactiveCheckTipoA'].setValue(false);
    }
  }

  checkTipoA() {
    this.personalDetailsForm.controls['reactiveCheckTipoA'].setValue(true);
    this.modService.close(this.modalTipoA);
  }

  openLegal() {
    this.modService.open(this.modalTipoA);
  }

  selectCheckTipoA(e) {
    if (e.key !== ' ') { return }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(this.personalDetailsForm, 'reactiveCheckTipoA', newValue);
  }

  selectCheckSolvencia(e) {
    if (e.key !== ' ') { return }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(this.personalDetailsForm, 'reactiveCheckSolvencia', newValue);
  }

  selectCheckPubli(e) {
    if (e.key !== ' ') { return }
    e.preventDefault();
    const newValue = !e.currentTarget.parentElement.firstElementChild.checked;
    this.helpers.updateFieldValue(this.personalDetailsForm, 'publicidad', newValue);
  }

  public onMouseClick() {
    this.closeQuestCollapse();
    this.modService.open('faqModal');
  }

  public onMouseOver() {
    this.closeQuestCollapse();
    this.modService.open('faqModal');
  }

  public closeQuestCollapse() {
    this.estadoCollapse0 = false;
    this.estadoCollapse1 = false;
    this.estadoCollapse2 = false;
    this.estadoCollapse3 = false;
    this.estadoCollapse4 = false;
  }

  public esCuentaInteligente() {
    return this.persona.logalty && this.persona.logalty.codProductoContratado === '006';
  }

}
