<div class="logalty-load">
  <div class="inner">
    <div class="container-center">
      <div class="preload">
        <h2>Estamos verificando que está todo correcto. </h2>
        <img src="assets/images/preload.gif">
        <div class="container-info">
            <div class="texto" >
                {{ mensaje }}
            </div>      
        </div>      
      </div>
    </div>
  </div>
</div>
