<div class="banner banner-narrow">
  <div class="banner-content">
    <div class="message-container">
      <i class="evo casi-esta"></i>
      <span class="banner-message">
        Estamos validando tu petición
      </span>
    </div>
    <i class="evo close"></i>
  </div>
</div>
<section class="c-header">
  <div class="responsive-header">
    <div class="backbutton" (click)="botonVolver()">
      <i class="evo flecha"></i>
    </div>
    <div class="page-title">
      <span>Modificar límite de crédito</span>
    </div>
    <div class="closebutton">
      <i class="evo close"></i>
    </div>
  </div>
</section>
<form class="form" class="form" action="">
  <div class="main-content">
    <section class="info">
      <p class="info-title">Límite de Crédito</p>
      <div class="info-description_img">
        <i class="evo icon-okhand info-description_img--icon"></i>
        <p class="info-description">Ya hemos recibido tu petición, cuando esté finalizada recibirás un email.</p>
      </div>
    </section>
    <section class="info">
      <p class="info-title">Petición recibida</p>
      <p class="info-description">Amplia o reduce tu límite de crédito</p>
    </section>
    <section class="cards">
      <div class="cardselected">
        <span class="cardselected-title">Tarjeta SELECCIONADA</span>
        <span class="link-arrow">
          <a href="#">
            Cambiar
            <i class="evo right-arrow"></i>
          </a>
        </span>
      </div>
      <div class="cardinfo">
        <div class="cardinfo-item">
          <i class="evo icon-creditcard cardinfo-item-img"></i>
          <div class="cardinfo-data">
            <p class="title">Tarjeta de crédito</p>
            <p class="description">************2351</p>
          </div>
        </div>
        <div class="cardinfo-item">
            <i class="evo open-wallet cardinfo-item-img"></i>
          <div class="cardinfo-data">
            <p class="title">Límite actual</p>
            <p class="description">0 €</p>
          </div>
        </div>
      </div>
      <div class="carddata">
        <p class="title">Modifíca tu límite</p>
        <div class="slidecontainer">
          <div class="slider-level">
            <span class="min">-</span>
            <span class="max">+</span>
          </div>
          <input type="range" min="1" max="100" value="50" class="slider" id="myRange">
          <div class="slider-level">
            <div class="level-min">
              <span>300 €</span>
              <span>Min</span>
            </div>
            <div class="level-max">
              <span>6.000 €</span>
              <span>Max</span>
            </div>
          </div>
        </div>
        <div class="carddata-limit">
          <input type="number" class="inputslider" id="limite" name="limite" placeholder="0" value="1.500" maxlength="5">
          <span class="cardata-limit-currency">€</span>
          <div class="carddata-limit-message">
            <p>Si tu forma de pago es cuota fija tu nueva cuota mínima será: XXX€</p>
          </div>
        </div>
        <div class="carddata-check">
            <div class="form-checkbox">
              <input id="check_conditions" class="i-checkbox" name="check_reducir" type="checkbox" value="Acepto">
              <label class="l-checkbox" for="check_conditions">Reducir a 0 euros tu límite de crédito</label>
            </div>
        </div>
        <div class="carddata-message">
          <p>Si quieres reducir tu límite de uso temporalmente, manteniendo tu límite de crédito actual, llama al 910
            900 900. Así podrás cambiarlo cuando quieras con efecto inmediato.</p>
        </div>
      </div>
    </section>
    <div class="btn-group_fix">
      <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="submit()">
        Continuar
      </button>
    </div>
    <div class="btn-group_fixreverse">
      <button class="btn-common btn-secondary_fix btn-common_fix_bold" (click)="submit()">
        Cancelar solicitud
      </button>
      <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="submit()">
        Modificar
      </button>
    </div>
  </div>
</form>