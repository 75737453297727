import { Component, OnInit, ElementRef, Input, OnDestroy } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Persona } from '../../../collections/persona'
import { VideoParams, VerifiEID } from '../../../collections/video'
import { environment } from '../../../../environments/environment';
import { DeviceService } from '../../../services/device.service';
import { ErrorService } from '../../../services/error.service';
import { AuthService } from '../../../services/auth.service';
import { PersonaService } from '../../../services/persona.service';
import { VideoEidService } from '../../../services/video-eid.service';
import { ModalService } from './../../../services/modal.service';
import { HelperService } from '../../../services/helpers.service';
import { ApiVideoeidService } from '../../../services/api/api.video-eid.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { map } from 'rxjs/operators';
declare var EID;
declare var $;

@Component({
  selector: 'identify-video',
  templateUrl: './identify-video.component.html',
  styleUrls: ['./identify-video.component.scss'],
  providers: [DeviceService, VideoEidService, ModalService, ApiVideoeidService]
})
export class IdentifyVideoComponent implements OnInit, OnDestroy {

  currentStep = "1";
  auth;
  videoId;
  caption: string = '';
  koCounter: number = 0;
  videoElementId: any = 'identifyVideo';
  videoParams: any;
  API_URL;
  modal_id = 'modal-identify-video';
  modal_notAdult = 'modal-not-adult';
  modal_ko = 'modal-ko';
  msg: string = '';
  title: string = '';
  video: string = 'Probar con video';
  iban: string = 'Probar con IBAN';
  link: string = 'Continuar más tarde';
  persona: Persona;
  // isSpecifError: boolean = false;
  loading: boolean = false;
  public logaltyLoaded = false;
  public timeShowModal;

  msgSrvError: string = '';
  msghelper: any = {
    current: "1",
    copy: "Coloca la parte delantera de tu DNI frente a la cámara y espera a que se capture la imagen.",
    total: "3"
  };
  modalSrvError: string = 'modal-srv-error';
  public isYoungAccount: boolean = false;

  timeout: boolean = false;

  constructor(private elementRef: ElementRef,
    private router: Router,
    private deviceService: DeviceService,
    public errService: ErrorService,
    private videoEidSrv: VideoEidService,
    public modService: ModalService,
    private authService: AuthService,
    private personaSrv: PersonaService,
    private analytics: AnalyticsService,
    private helpers: HelperService) {
    this.API_URL = environment.apiUrl;
    this.persona = this.authService.getSessionProspect();
    // this.modService.open(this.modal_id)
  }

  ngOnDestroy() {
    this.videoId = undefined;
    this.caption = '';
    this.koCounter = 0;
    this.videoElementId = 'identifyVideo';
    this.videoParams = undefined;
    this.API_URL = '';
    this.modal_id = 'modal-identify-video';
    this.modal_notAdult = 'modal-not-adult';
    this.modal_ko = 'modal-ko';
    this.msg = '';
    this.title = '';
    this.video = 'Probar con video';
    this.iban = 'Probar con IBAN';
    this.link = 'Continuar más tarde';
    this.persona = undefined;
    this.loading = false;
    this.logaltyLoaded= false;
    this.msgSrvError = '';
    this.modalSrvError = 'modal-srv-error';
    this.msghelper = { current: "", copy: "", total: "" }
  }

  ngOnInit() {
    // window.scrollTo(0,document.body.scrollHeight);
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    const width = window.innerWidth,
      height = window.innerHeight;
    $('#' + this.videoElementId).css({
      width: width,
      height: height
    });

    const cameraUnavailableMessage = "¡Ups! Estamos teniendo problemas para identificarte a través de video.\n" +
      "No te preocupes, tiene solución. Para poder validar tu identidad puedes: volver a intentarlo de nuevo por video o facilitarnos un IBAN de otra entidad\
             en la que seas titular";
    this.videoParams = new VideoParams(width, height, cameraUnavailableMessage).getVideoParams();
    this.checkIsVideoProcess();
  }

  initLiterals() {
    this.video = 'Probar con video';
    this.iban = 'Probar con IBAN';
  }

  checkIsVideoProcess() {
    this.loading = true;
    this.personaSrv.getPersonaById(this.persona.datosPersonales.id_cliente_EVO, this.authService.product).subscribe(data => {
      this.loading = false;
      if (data.electronicID && data.electronicID.electronicId) {
        this.videoEidSrv.getVideo(data.electronicID.electronicId).subscribe( data => {
          this.loading = false;
          if (data.status.toLowerCase() === 'completed') {
            this.checkDocument(data);
          } else {
            this.initVideo();
          }
        }, error => {
          this.errService.navToError();
        })
      } else {
        this.initVideo();
      }
    }, error => {
      this.errService.navToError();
    });
  }

  initVideo() {
    this.videoEidSrv.getAuthorizationEID()
    .pipe(map(data => {
          this.auth = data.body;
          this.videoId = EID.videoId('#' + this.videoElementId, {lang: "es"});
          this.startRecording();
        }))
        .subscribe(
          ok => {
            console.log(ok);
          },
          ko => {
            this.msg = 'No podemos captar correctamente el documento que estás utilizando.';
            this.video = '';
            this.iban = 'Continuar por IBAN';
            this.modService.open(this.modal_id);
          }
        );
  }

  destroyVideo(videoService) {
    if (videoService && videoService.turnOff) {
      $('#' + this.videoElementId).html('');
      videoService.turnOff();
    }
  }
  destroyVideoKO(videoService) {
    $('#' + this.videoElementId).html('');
    videoService.turnOff();
    videoService.destroy();
  }

  startRecording() {
    return this.validationProcess(this.videoId)
  }

  validationProcess(videoService) {
    const that = this;
    this.persona = this.authService.getSessionProspect();
    videoService.on("phaseStarting", (phase) => {
      console.log(phase.name + " - Starting");
      that.analytics.triggerInteractionData('Identificación por video', 'Inicio correcto', this.persona.datosPersonales.id_cliente_EVO);
      switch (phase.name) {
        case 'FRONT':
          this.showModalTimeout();
          this.msghelper = {
            current: "1",
            copy: "Coloca la parte delantera de tu DNI/Tarjeta de Residencia frente a la cámara y espera a que se capture la imagen.",
            total: "3"
          };
          that.analytics.triggerInteractionData('Identificación por video', 'Captura DNI cara', this.persona.datosPersonales.id_cliente_EVO);
        break;
        case 'BACK':
          this.msghelper = {
            current: "2",
            copy: "Coloca la parte trasera de tu DNI frente a la cámara",
            total: "3"
          };
          that.analytics.triggerInteractionData('Identificación por video', 'Captura DNI dorso', this.persona.datosPersonales.id_cliente_EVO);
          break;
        case 'FACE':
          this.msghelper = {
            current: "3",
            copy: "Pon tu cara en el centro de la pantalla. Evita llevar accesorios en la cabeza o gafas.",
            total: "3"
          };
          that.analytics.triggerInteractionData('Identificación por video', 'Captura rostro ok', this.persona.datosPersonales.id_cliente_EVO);
          break;
        default:
          break;
      }
      phase.continue();
    });
    this.cancelTimeOut();
    let idType;
    if (this.persona.datosPersonales.codigoIdExterno === '51') {
      idType = 62
    } else if (this.persona.datosPersonales.codigoIdExterno === '66') {
      idType = 188
    } else {
      this.analytics.triggerInteractionData('Identificación por video', 'Error documento guardado no es correcto')
      this.errService.navToError();
    }
    return this.turnOnVideoService(videoService, idType, that);

  }

  turnOnVideoService(videoService, idType, that){

    videoService.turnOn();

    videoService.start({
      id: this.auth.id,
      authorization: this.auth.authorization,
      idType: idType,
      minSimilarityLevel: "Low"
    });

    videoService.on("completed", (video) => {
      this.cancelTimeOut();
      this.videoId = video.id;
      this.destroyVideo(videoService);
      this.loading = true;
      this.videoEidSrv.getVideo(video.id).subscribe((data) => {
        this.loading = false;
        this.logaltyLoaded=true;
        that.checkDocument(data);
      }
    )});

    videoService.on("failed", (error) => {
      this.koCounter++;
        that.analytics.triggerInteractionData('Identificación por video', 'Error proceso', this.persona.datosPersonales.id_cliente_EVO);
        this.initLiterals();
        this.destroyVideoKO(videoService);
        const isExceed = this.koCounter > 2;
        this.title = '¡Vaya!';
        this.msg = 'No te preocupes, tiene solución. Para poder validar tu identidad puedes: volver a intentarlo de nuevo por video o facilitarnos un IBAN\
                  de otra entidad en la que seas titular.';
        if (error && error.error &&  error.error.error && !isExceed) {
          that.isSpecificError(error.error.error);
        }
        if (isExceed) {
          this.msg = 'No te preocupes, tiene solución. Para poder validar tu identidad puedes facilitarnos un IBAN.'
          this.video = '';
        }
        this.modService.open(this.modal_id);
      }
    );
  }

  changeStep() {
    this.loading = true;
    const redirect = 'confirmacion-datos';
    this.helpers.navigateTo(redirect);
  }

  changeToIban() {
    this.cancelTimeOut();
    this.loading = true;
    const redirect = 'identificacion-iban',
          canal = this.deviceService.getDevice();
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      estado: redirect,
      otrosDatos: {
        tipoIdentificacion: redirect
      }
    });
    this.helpers.navigateTo(redirect);
    this.authService.updateProspect(this.persona).subscribe((data) => {
    }, error => {
      this.errService.navToError();
    });
  }

  destroy() {
    this.modService.close(this.modal_id, false);
    $('#' + this.videoElementId).html('');
    this.videoId = undefined;
    this.caption = '';
    this.koCounter = 0;
    this.videoElementId = 'identifyVideo';
    this.videoParams = undefined;
    this.API_URL = '';
    this.modal_id = 'modal-identify-video';
    this.modal_notAdult = 'modal-not-adult';
    this.modal_ko = 'modal-ko';
    this.msg = '';
    this.title = '';
    this.video = 'Probar con video';
    this.iban = 'Probar con IBAN';
    this.link = 'Continuar más tarde';
    this.persona = undefined;
    this.loading = false;
    this.logaltyLoaded= false;
    this.msgSrvError = '';
    this.modalSrvError = 'modal-srv-error';
    this.msghelper = { current: "", copy: "", total: "" }
  }

  closeModalTimeout() {
    this.modService.close(this.modal_id, false);
    this.timeout = false;
    this.showModalTimeout();
  }
  isSpecificError(error) {
    // this.isSpecifError = true;
    console.log("isSpecifError",error);
    //Reseteamos el modal de 30 segundos.
    this.cancelTimeOut();
    this.timeout = false;
    switch (error) {
      case 'VideoID.MaxLengthExceeded':
        this.title = '¡Vaya!';
        this.msg = 'La sesión ha caducado. No te preocupes, tiene solución. Puedes volver a intentar validar tu identidad de nuevo, o si lo prefieres, te enviaremos un email para que puedas hacerlo en otro momento.';
        this.video = 'Probar con video';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
        break;
      case 'VideoID.ConnectionLost':
        this.title = '¡Vaya!';
        this.msg = 'Parece que tu señal de internet es muy débil. Prueba a cambiar de red, recuerda que el 4G va muy bien ;-)';
        this.video = 'Volver a intentarlo';
        this.iban = '';
        this.link ='';
        break;
      case 'VideoID.MaxFpsExceeded':
        this.title = '¡Vaya!';
        this.msg = 'Parece que tu señal de internet es muy débil. Prueba a cambiar de red, recuerda que el 4G va muy bien ;-)';
        this.video = 'Volver a intentarlo';
        this.iban = '';
        this.link ='';
        break;
      case 'VideoID.NotSupported':
        this.title = '¡Vaya!';
        this.msg = 'Parece que el navegador que estás usando, o el Sistema Operativo de tu móvil está un poco desactualizado. Pero tranquilo, puedes actualizar la versión o probar por otro método de identificación si lo prefieres.';
        this.video = 'Volver a probar';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
        break;
      case 'Connection.Lost':
      case 'Bandwidth.TooLow':
        this.title = '¡Vaya!';
        this.msg = 'Parece que tu señal de internet es muy débil. Prueba a cambiar de red, recuerda que el 4G va muy bien ;-)';
        this.video = 'Volver a intentarlo';
        this.iban = '';
        this.link ='';
        break;
      case 'Faces.Mismatch':
        this.title = '¡Vaya!';
        this.msg = 'Estamos teniendo problemas para identificarte. Recuerda que debes estar tú solo frente a la cámara';
        this.video = 'Volver a probar';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
        break;
      case 'Faces.MultipleFaces':
        this.title = '¡Vaya!';
        this.msg = 'Estamos teniendo problemas para identificarte. Recuerda que debes estar tú solo frente a la cámara';
        this.video = 'Volver a probar';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
        break;
      case 'DocumentSides.DataMismatch':
      case 'Document.NotRecognized':
      case 'Document.NotAllowed':
        this.title = '¡Vaya!';
        this.msg = 'Parece que el documento que estás utilizando no es válido. Recuerda que debe ser un DNI o NIE (con foto).';
        this.video = 'Probar con video';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
      break;
      case 'Document.WrongSide':
        this.title = '¡Vaya!';
        this.msg = 'Parece que el documento que estás utilizando no es válido. Recuerda que debe ser un DNI o NIE (con foto).';
        this.video = 'Volver a intentarlo';
        this.iban = '';
        this.link = '';
      break;
      case 'Document.Illegible':
        this.title = '¡Vaya!';
        this.msg = 'No podemos captar correctamente el documento que estás utilizando.';
        this.video = 'Probar con video';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
      break;
      case 'Luminance.TooLow':
        this.title = '¡Vaya!';
        this.msg = 'Parece que no podemos verte bien con la luz que hay en la habitación. Intenta tener mayor iluminación para que podamos identificarte correctamente.';
        this.video = 'Probar con video';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
      break;
      case 'Luminance.TooHigh':
        this.title = '¡Vaya!';
        this.msg = 'Parece que no podemos verte bien con la luz que hayen la habitación. Intenta tener menos iluminación para que podamos identificarte correctamente. ';
        this.video = 'Volver a probar';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
        break;
      case 'Camera.NotFound':
        this.title = '¡Vaya!';
        this.msg = 'Parece que tu dispositivo no es compatible con la opción de vídeo identificación. Si lo deseas, puedes continuar el proceso con tu número IBAN.';
        this.video = '';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
        break;
      case 'Camera.Unavailable':
          this.title = '¡Vaya!';
          this.msg = 'Parece que tu dispositivo no es compatible con la opción de vídeo identificación. Si lo deseas, puedes continuar el proceso con tu número IBAN.';
          this.video = '';
          this.iban = 'Probar con IBAN';
          this.link = 'Continuar más tarde';
          break;
      case 'Camera.PermissionDenied':
        this.title = '¡Vaya!';
        this.msg = 'Parece  que tu dispositivo no es compatible con la opción de vídeo identificación. Si lo deseas, puedes continuar el proceso con tu número IBAN.';
        this.iban = 'Probar con IBAN';
        this.video = '';
        this.link = 'Continuar más tarde';
        break;
      case 'Service.NotAvailable':
      this.title = '¡Vaya!';
      this.msg = 'Parece  que tu dispositivo no es compatible con la opción de vídeo identificación. Si lo deseas, puedes continuar el proceso con tu número IBAN.';
      this.iban = 'Probar con IBAN';
      this.video = '';
      this.link = 'Continuar más tarde';
      break;
      case 'CameraPermission.Denied':
        this.title = '¡Vaya!';
        this.msg = 'Parece  que tu dispositivo no es compatible con la opción de vídeo identificación. Si lo deseas, puedes continuar el proceso con tu número IBAN.';
        this.iban = 'Probar con IBAN';
        this.video = '';
        this.link = 'Continuar más tarde';
      break;
      case 'Browser.NotSupported':
        this.title = '¡Vaya!';
        this.msg = 'Parece que el navegador que estás usando, o el Sistema Operativo de tu móvil está un poco desactualizado. Pero tranquilo, puedes actualizar la versión o probar por otro método de identificación si lo prefieres.';
        this.video = 'Probar con video';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
      break;
      case 'Could not locate DOM element to attach to.':
        this.title = '¡Vaya!';
        this.msg = 'Estamos tiendo problemas para identificarte. Por favor vuelve a intentarlo seleccionando uno de nuestros procesos,o si lo prefieres, puedes finalizar el proceso en otro momento a través del correo electrónico que te acabamos de enviar.';
        this.video = 'Probar con video';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
        break;
      default:
        this.title = '¡Vaya!';
        this.msg = 'Estamos tiendo problemas para identificarte. Por favor vuelve a intentarlo seleccionando uno de nuestros procesos,o si lo prefieres, puedes finalizar el proceso en otro momento a través del correo electrónico que te acabamos de enviar.';
        this.video = 'Probar con video';
        this.iban = 'Probar con IBAN';
        this.link = 'Continuar más tarde';
    };
    this.setTipoIdentificacionProspect(error);
    this.authService.updateProspect(this.persona);
  }

  isExpired(date) {
    const expire = new Date(Number(date));
    const today = new Date();
    if (expire.getTime() < today.getTime()) {
      return true;
    }
    return false;
  }

  isAdult(birth) {
    return this.helpers.isAdult(Number(birth));
  }

  tryAgain() {
    if (this.koCounter < 2) {
      this.modService.close(this.modal_ko);
      this.loading = true;
      this.videoEidSrv.getVideo(this.videoId).subscribe(data => {
        this.loading = false;
        if (data.status.toLocaleLowerCase() == 'pending') {
          this.destroy();
          this.initVideo();
        }
      }, error => {
        this.errService.navToError();
      });
    } else {
      this.errService.navToError();
    }
  }

  isServerKo() {
    const name = this.persona.datosPersonales && this.persona.datosPersonales.nombreCliente ? this.persona.datosPersonales.nombreCliente : '';
    if (this.koCounter < 2) {
      this.title = '¡Oh-Oh!';
      this.msg = `Parece que algo está fallando ${name}`;
      this.link = 'Inténtalo de nuevo';
      this.modService.open(this.modal_ko, true);
    } else {
      this.errService.navToErrorEid();
    }
  }

  checkDocument(data) {
    if (!this.isExpired(data.document.expiryDate)) {
      if (!this.isAdult(data.document.subject.birthDate)) {
        this.title = '¡UPS!';
        this.msg = !this.isYoungAccount ? 'Lo sentimos pero por política de admisión, de momento no puedes contratar la Cuenta Inteligente. Es necesario que seas mayor de edad.' : 'Por política de admisión no puedes contratar tu Cuenta Joven ya que el titular debe ser mayor de edad.';
        this.link = '¡Hasta pronto!';
        this.modService.open(this.modal_notAdult);
        data.electronicID = {};
        data.electronicID.descripcionError = 'Person.Underage';
        this.sendParamsProspec(data);
      } else {
        this.sendParamsProspec(data);
      }
    } else {
      this.title = '¡Vaya!';
      this.msg = 'Parece que tu DNI está caducado, comprueba que la fecha de caducidad es correcta. Chequea tu email para que puedas finalizar el proceso cuando lo hayas renovado. ';
      this.video = '', this.iban = '' , this.link='Continuar más tarde';
      this.modService.open(this.modal_id);
      data.electronicID = {};
      data.electronicID.descripcionError = 'Document.Expired';
      this.sendParamsProspec(data);
    }
  }
  // crm verify
  setDataVerificated(data) {
    const videoVerifi: VerifiEID = new VerifiEID();
    videoVerifi.videoId = data.id;
    videoVerifi.rauthorityId = environment.videoId;
    if (data.electronicID === undefined) {
      this.videoEidSrv.verificationEID(videoVerifi).subscribe(data => {
        console.info(data);
      }, error => {
        console.error(data);
      });
    }
  }

  setTipoIdentificacionProspect(data) {
    const canal = this.deviceService.getDevice();
    let description;
    let noErrorInEID = !!data.id || !!(data.electronicID && !data.electronicID.descripcionError);
    if (data.electronicID && data.electronicID.descripcionError) {
      description = data.electronicID.descripcionError;
      noErrorInEID = false;
    }
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina
      },
      electronicID: {
        electronicId: data.id ? data.id : 'ERROR',
        descripcionError: typeof data === 'object' ? description : data
      }
    })
  }

  sendParamsProspec(data) {
    this.setTipoIdentificacionProspect(data);
    this.loading = true;
    this.setDataVerificated(data);
    this.authService.updateProspect(this.persona).subscribe((datos) => {
      this.loading = false;
      this.logaltyLoaded=true;
      if (datos.response.codigo === 'KO') {
        this.msgSrvError = !!datos.response.message ? datos.response.message : 'Error genérico';
        this.modService.open(this.modalSrvError);
        return
      }
      // this.analytics.triggerInteractionData('Captura DNI', 'validacion_ok', this.persona.datosPersonales.id_cliente_EVO);
      if (this.persona.electronicID && !this.persona.electronicID.descripcionError){
        this.changeStep();
      }
    }, (error) => {
      this.loading = false;
      this.errService.navToError();
      // TODO irse a la pagina de error o preguntar?
    });
  }

  click(param) {
    switch (param) {
      case 'video':
          this.destroyVideoKO(this.videoId);
          this.initVideo();
          this.closeModalTimeout();
		break;
      case 'iban':
        this.changeToIban();
        break;
      case 'continuar':
        this.errService.navToError();
        break;
      case 'welcome':
        this.errService.navToWelcome();
        break;
      default:
        this.destroy();
        this.initVideo();
    }
  }
  /**Muestra el modal_timeout una vez que han pasado 90 segundos */
  showModalTimeout(){
    this.cancelTimeOut();
    this.timeShowModal = setTimeout(() =>{
      this.title = '¡Vaya!';
      this.msg = 'No podemos captar correctamente el documento que estás utilizando.';
      this.video = 'Continuar por vídeo';
      this.iban = 'Probar con IBAN';
      this.timeout = true;
      this.modService.open(this.modal_id);
    }, 90000);
  }
  reloadModal(event){
    if(this.video){
      this.reiniciarProceso();
    }else{
      this.changeToIban();
    };
    //  this.reiniciarProceso();
  }
  reiniciarProceso(){
    this.destroyVideoKO(this.videoId);
    this.initVideo();
    this.closeModalTimeout();
  }
  cancelTimeOut(){
    this.modal_id = 'modal-identify-video';
    if(this.timeShowModal){
      clearTimeout(this.timeShowModal);
      this.timeShowModal = null;
    }
  }
}
