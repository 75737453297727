import { Component, OnInit } from '@angular/core';
import { NOTIFICATIONS } from '../../../resources/notifications.model'
import { HelperService } from '../../../services/helpers.service'
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'app-notifications-profile',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications = NOTIFICATIONS;
  _notifications = [];
  breadcrumbNav = [{ name: 'INICIO', link: '/perfil' }, { name: 'NOTIFICACIÓN', link: '/notificacion' }];

  constructor(private helpers: HelperService, private analytics: AnalyticsService,
  ) {
    this.summariseContent();
   }

  ngOnInit() {
  }

  goToDetail(notification) {
    this.analytics.triggerNotificationAction("Abrir notificación", notification.title);
    this.helpers.navigateTo(`/notificacion/${notification.title}`);
  }

  summariseContent() {
    const etc = '...'
    this._notifications = this.notifications.map(item => Object.assign({}, item))
    // this._notifications.forEach(notification => {
    //   notification['content'] = notification['content'].substring(0,60);
    //   notification['content'] = notification['content'].concat(etc);
    // })
  }

}
