import { Component, OnInit, ElementRef, ChangeDetectorRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helpers.service';
import { ErrorService } from 'app/services/error.service';
import { SIMULADO } from 'app/resources/simulador.model';
import { ModalService } from 'app/services/modal.service';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { HelpersMicroinsurancesService } from 'app/services/helpers-microinsurances.service';
import { zip } from 'rxjs';
import { TarificationOpt } from 'app/collections/tarification-opt';
import { error } from 'util';
import { AuthService } from 'app/services/auth.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { LoginService } from '../../../../../services/login.service';

@Component({
  selector: 'tarificador',
  templateUrl: './tarificador.component.html',
  styleUrls: ['./tarificador.component.scss'],
  providers: [ModalService]
})

export class TarificadorComponent implements OnInit, AfterViewInit {

  private position: number;

  private value = '';
  public loading = false;

  public modalError = 'modal-srv-error';
  public mavigateModal = 'modalLogin';

  public messageError = '';
  public messageErrorGeneric = 'Error genérico, vuelva a intentarlo o contacte con el responsable.';
  public messageErrorScopeNotValid = 'Ambito no válido, valores correctos: 1 o 2.';
  public msgErrorNotAdult = 'La edad para contratar EVO Protect es de 18 a 65 años.';
  public messageErrorCalculation = 'Error en el cálculo';

  public formValid = false;

  CAPITALES_OBTENIDOS = [];
  CAPITAL_GLOBAL = [];
  AMBITO = [];
  DURACION = [];
  DEPORTE1 = [];
  REEMBOLSOGASTOS = [];
  selectedCapitalObject = [];

  public inputs = ['actividad', 'duration', 'region', 'capital', 'cobertura', 'cobertura'];

  public selectedDuration;
  public selectedActivity;
  public selectedRegion;
  public selectedCapital;
  public selectedCobertura;
  public totalPrice;
  public sendTotalPrice;
  public priceByDay;
  public calcType;

  private sendModel = SIMULADO;

  public configureForm: FormGroup;

  @Input('navigateTo') navigateTo: string = '';
  @Input('navigateModal') navigateModal: string = '';
  @Input('onSendModal') onSendModal: string = '';
  @Output('eventEmmit') eventEmmit = new EventEmitter();

  microSettingsSession = this.authMicroService.getMicroSettingsSession();

  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private helpers: HelperService,
    private authService: AuthService,
    private authMicroService: AuthMicroService,
    private modService: ModalService,
    public errService: ErrorService,
    private confService: MicrosegConfigService,
    private microService: HelpersMicroinsurancesService,
    private loginSrv: LoginService,
    public analytics: AnalyticsService
  ) {
    this.createForm();
    this.subscribeToForm();
  }

  ngOnInit() {
    this.errService.mobileErrors(this.configureForm);
  }

  ngAfterViewInit() {
    this.loading = true;

    const callStack = zip(
      this.confService.getConfigLists('PDF_DEPORTE1'),        // [0]
      this.confService.getConfigLists('PDF_DURACION'),        // [1]
      this.confService.getConfigLists('PDF_AMBITO'),          // [2]
      this.confService.getConfigCapitales(),                  // [3]
      this.confService.getConfigLists('PDF_REEMBOLSOGASTOS'), // [4]
    );
    const subscriptions = callStack.subscribe(res => {
      this.loading = false;
      const configs = this.microService.fillConfigSettings(res); //Devuelve un array con el formato mapeado.
      this.DEPORTE1 = configs.DEPORTE1;
      this.DURACION = configs.DURACION;
      this.AMBITO = configs.AMBITO;
      this.CAPITALES_OBTENIDOS = this.microService.fillCapitalSettings(res[3]);
      //Seleccionamos por defecto el capital de la UE
      this.CAPITAL_GLOBAL = this.CAPITALES_OBTENIDOS.filter(capital => capital.scope === "1");
      this.REEMBOLSOGASTOS = configs.REEMBOLSOGASTOS;

      (this.microSettingsSession != null) ? this.restoreForm() : this.loading = false;
    },
    error => {
      this.loading = false;
      this.errService.navToError();
    });
    this.cdRef.detectChanges();
  }

  private createForm() {
    this.configureForm = this.fb.group({
      actividad: ['', [Validators.required]],
      duration: ['', [Validators.required]],
      region: ['', [Validators.required]],
      capital: ['', [Validators.required]],
      cobertura: ['', [Validators.required]]
    });
  }

  restoreForm() {
    const formValues = this.microSettingsSession.configure;
    for (let a = 0; a < this.inputs.length; a++) {
      this.helpers.updateFieldValue(this.configureForm, this.inputs[a], (formValues[this.inputs[a]]));
    };
    this.showCapitals(this.selectedRegion);
    this.checkValid();
  }

  showCapitals(region) {
    this.CAPITAL_GLOBAL = this.CAPITALES_OBTENIDOS.filter(capital => capital.scope === region);
  }

  subscribeToForm() {
    this.configureForm.get('actividad').valueChanges.subscribe(actividad => {
      this.selectedActivity = actividad;
    });
    this.configureForm.get('duration').valueChanges.subscribe(duration => {
      this.selectedDuration = duration;
    });
    this.configureForm.get('region').valueChanges.subscribe(region => {
      this.selectedRegion = region;
      this.showCapitals(region);
    });
    this.configureForm.get('capital').valueChanges.subscribe(capital => {
      this.selectedCapital = capital;
      if (typeof this.selectedRegion === 'undefined') {
        this.selectedRegion = "1";
      }
      this.selectedCapitalObject = this.CAPITALES_OBTENIDOS.filter(obj => obj.scope === this.selectedRegion);
      this.selectedCapitalObject = this.selectedCapitalObject.filter(obj => obj.id === capital);
    });
    this.configureForm.get('cobertura').valueChanges.subscribe(cobertura => {
      this.selectedCobertura = cobertura;
    });
    this.configureForm.valueChanges.subscribe(configureForm => {
      this.errService.mobileErrors(this.configureForm);
      this.updateMicroSettingsSession();
      this.checkValid();
    });
  }

  checkValid() {
    let valids = 0;
    for (let a = 0; a < this.inputs.length; a++) {
      if (this.configureForm.get(this.inputs[a]).value != "") valids++
    }
    this.formValid = (valids == this.inputs.length);
    if (this.formValid) {
      const finalConf: TarificationOpt = Object.assign({
        clientNif: "0",
        entity: "2",
        scope: this.selectedRegion,
        sport1: this.selectedActivity,
        duration: this.selectedDuration,
        refund: this.selectedCobertura,
        deseaseImport: this.selectedCapitalObject[0].cfAmount,
        disabilityImport: this.selectedCapitalObject[0].cipAmount
      });

      this.loading = true;

      this.confService.getTarificationOpt(finalConf)
      .subscribe(res => {
          if(this.loginSrv.isLoggedIn) {
            this.analytics.addPageLikeEvent(3);
          }
          this.loading = false;
          if (res.error) {
            const code = res.errorMessages[0].code;
            switch (code) {
              case 'ERG001':
                this.messageError = this.messageErrorGeneric;
                this.modService.open(this.modalError);
                break;
                case 'ERR000':
                this.messageError = this.messageErrorScopeNotValid;
                this.modService.open(this.modalError);
                break;
              case 'NPOI001':
                this.messageError = this.messageErrorCalculation;
                this.modService.open(this.modalError);
                break;
              default:
                this.messageError = res.errorMessages[0].messageText;
                this.modService.open(this.modalError);
            }
            return;
          }
          this.totalPrice = res.totalPrice;
          this.analytics.addTarificationEvent(2, this.totalPrice);
          this.calcDetailPrice();
        }, (err => {
          this.loading = false;
          this.errService.navToError();
        }));
    }
  }

  calcDetailPrice() {
    const divisor = parseFloat(this.selectedDuration.slice(0, -1)),
          dividend = parseFloat(this.totalPrice);

    this.calcType = (this.selectedDuration.indexOf('d') >= 0) ? 'Día' : 'Hora';
    this.priceByDay = (dividend / divisor).toFixed(3).toString();
  }

  openModal(modalId) {
    this.modService.open(modalId);
  }

  updateMicroSettingsSession(){

    this.sendModel.configure.actividad = this.selectedActivity;
    this.sendModel.configure.duration = this.selectedDuration;
    this.sendModel.configure.region = this.selectedRegion;
    this.sendModel.configure.capital = this.selectedCapital;
    this.sendModel.configure.cobertura = this.selectedCobertura;

    this.authMicroService.setMicroSettingsSession(this.sendModel);
  }

  onSubmit() {
    this.analytics.addTarificationEvent(3, this.totalPrice);
    this.helpers.updateFormStatus(this.configureForm);

    this.updateMicroSettingsSession();

    if (this.navigateModal) {
      this.eventEmmit.emit(this.navigateModal);
    } else {
      this.helpers.navigateTo(this.navigateTo);
    }

    return true;
  }
}
