<app-header></app-header>
<div class="error-eid">
  <div class="image"></div>
  <div class="container">
    <ng-container *ngIf="!notCorrectCEF">
      <div class="title-container" >
        <legend>
          <span class="main-icon evo error-computer"></span>
          <h2>¡Oh-oh!</h2>
          <h3>Parece que algo está fallando {{userName}}</h3>
        </legend>
      </div>
      <div class="row">
        <p>
          Nuestro equipo está chequeando tu solicitud. Recibirás un email para que puedas seguir con el proceso desde el último paso que completaste.
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="notCorrectCEF">
      <div class="title-container">
        <legend>
          <span class="main-icon evo error-computer"></span>
          <h2>¡Vaya!</h2>
          <h3>En estos momentos no podemos hacer la actualización online de tus datos.</h3>
        </legend>
      </div>
      <div class="row">
        <p>
          Llámanos al <a class="link-blue text-white" href="tel:910 900 900">910 900 900</a> y te ayudamos a continuar con tu proceso de contratación.
        </p>
      </div>
    </ng-container>
  </div>
</div>
