import { Component, OnInit} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-error-eid',
  templateUrl: './error-eid.component.html',
  styleUrls: ['./error-eid.component.scss'],
})
export class ErrorEidComponent implements OnInit {
  userName: string;
  notCorrectCEF: boolean;
  constructor(
    private router: Router,
    private errService: ErrorService
) {  }

  ngOnInit() {
    this.userName = this.errService.errUsername;
    this.notCorrectCEF = this.errService.errNoCorrectCEF;
  }

}
