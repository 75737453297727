import { TipoContrato } from '../collections/tipo-contrato';

export var TIPOSCONTRATO: TipoContrato[] = [

  { name: 'Tipo de contrato', value : null},
  { name: 'CONTRATO INDEFINIDO' , value : '01' },
  { name: 'CONTRATO TEMPORAL' , value : '02' },
  { name: 'A TIEMPO PARCIAL' , value : '03' },
  { name: 'A TIEMPO PARCIAL MENOS DE 12H/SEMANA' , value : '04' },
  { name: 'A TIEMPO PARCIAL RELEVO' , value : '05' },
  { name: 'CONTRATO EN PRACTICAS' , value : '06' },
  { name: 'CONTRATO PARA LA FORMACION' , value : '07' },
  { name: 'CONTRATO DE APRENDIZAJE' , value : '08' },
  { name: 'SIN CONTRATO(DESEMPLEO, JUBILADO, ESTUDIANTE...)' , value : '09' }
];
