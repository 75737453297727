export class IbanImage {
  mrzRequest: {
    guid: string;
    image1: string;
    image2: string;
  }
  responseType:string = "COMPLETE";
  responseImages = {
    responseImage: [
      "image1cut",
      "image2cut",
      "imageSignature",
      "imagePhoto"
    ]
  }
  ocrData = "true";

  constructor(values : Object = {}){
    Object.assign(this, values);
  }
}

export class getIban {
  confirma: {
    result: string;
    requestNumber:string;
    integrityValidated: Boolean;
    guid: string;
    resultMessage: string;
    OCRData: {
      field: any;//[
        // {
        //   name: NAME,
        //   content: JUAN JESUS
        // },
        // {
        //   name: SURNAME,
        //   content: GARCIA
        // },
        // {
        //   name: SURNAME,
        //   content: JIMENEZ
        // },
        // {
        //   name: ID_NUMBER,
        //   content: 04845354J
        // },
        // {
        //   name: DOC_NUMBER,
        //   content: AJA196256
        // },
        // {
        //   name: STREET_ADDRESS,
        //   content: C. NUEVO GIIADARRAMA 23 IIZ 004 C
        // },
        // {
        //   name: CITY_ADDRESS,
        //   content: GUADARRAMA
        // },
        // {
        //   name: STATE_ADDRESS,
        //   content: MADRID
        // },
        // {
        //   name: ADDRESS,
        //   content: C. NUEVO GIIADARRAMA 23 IIZ 004 C
        // },
        // {
        //   name: ADDRESS,
        //   content: GUADARRAMA
        // },
        // {
        //   name: ADDRESS,
        //   content: MADRID
        // },
        // {
        //   name: BIRTHPLACE,
        //   content: IADRID
        // },
        // {
        //   name: BIRTHPLACE,
        //   content: MADRIO
        // },
        // {
        //   name: PARENTS,
        //   content: JFSLJS / CON'SOLACIO'J
        // },
        // {
        //   name: BIRTHDATE,
        //   content: 1978-08-08
        // },
        // {
        //   name: EXPIRY,
        //   content: 2021-04-05
        // },
        // {
        //     name: SEX,
        //     content: M
        // },
        // {
        //     name: AUTHORITY,
        //     content: 28551L6D1
        // },
        // {
        //     name: EXPEDITOR_MRZ,
        //     content: ESP
        // },
        // {
        //     name: NATIONALITY_MRZ,
        //     content: ESP
        // },
        // {
        //     name: NAME_MRZ,
        //     content: JUAN JESUS
        // },
        // {
        //     name: SURNAME_MRZ,
        //     content: GARCIA JIMENEZ
        // },
        // {
        //     name: ID_NUMBER_MRZ,
        //     content: 04845354J
        // },
        // {
        //     name: DOC_NUMBER_MRZ,
        //     content: AJA196256
        // },
        // {
        //     name: BIRTHDATE_MRZ,
        //     content: 1978-08-08
        // },
        // {
        //     name: EXPIRY_MRZ,
        //     content: 2021-04-05
        // },
        // {
        //     name: SEX_MRZ,
        //     content: M
        // },
        // {
        //     name: EXPEDITOR_VIZ,
        //     content: ESP
        // },
        // {
        //     name: NAME_VIZ,
        //     content: JUAN JESIJS
        // },
        // {
        //     name: SURNAME_VIZ,
        //     content: GARCIA
        // },
        // {
        //     name: SURNAME_VIZ,
        //     content: JIMENEZ
        // },
        // {
        //     name: ID_NUMBER_VIZ,
        //     content: 04845354J
        // },
        // {
        //     name: DOC_NUMBER_VIZ,
        //     content: AJA196256
        // },
        // {
        //     name: STREET_ADDRESS_VIZ,
        //     content: C. NUEVO GIIADARRAMA 23 IIZ 004 C
        // },
        // {
        //     name: CITY_ADDRESS_VIZ,
        //     content: GUADARRAMA
        // },
        // {
        //     name: STATE_ADDRESS_VIZ,
        //     content: MADRID
        // },
        // {
        //     name: ADDRESS_VIZ,
        //     content: C. NUEVO GIIADARRAMA 23 IIZ 004 C
        // },
        // {
        //     name: ADDRESS_VIZ,
        //     content: GUADARRAMA
        // },
        // {
        //     name: ADDRESS_VIZ,
        //     content: MADRID
        // },
        // {
        //     name: BIRTHPLACE_VIZ,
        //     content: IADRID
        // },
        // {
        //     name: BIRTHPLACE_VIZ,
        //     content: MADRIO
        // },
        // {
        //     name: PARENTS_VIZ,
        //     content: JFSLJS / CON'SOLACIO'J
        // },
        // {
        //     name: BIRTHDATE_VIZ,
        //     content: 1978-08-08
        // },
        // {
        //     name: EXPIRY_VIZ,
        //     content: 2021-04-05
        // },
        // {
        //     name: SEX_VIZ,
        //     content: M
        // },
        // {
        //     name: AUTHORITY_VIZ,
        //     content: 28551L6D1
        // },
        // {
        //     name: MRZ,
        //     content: IDESPAJA196256204845354J<<<<<<
        // },
        // {
        //     name: MRZ,
        //     content: 7808087M2104050ESP<<<<<<<<<<<4
        // },
        // {
        //     name: MRZ,
        //     content: GARCIA<JIMENEZ<<JUAN<JESUS<<<<
        // },
        // {
        //     name: QUALITY_IMAGE_RESOLUTION,
        //     content: IQ_POOR 113dpi 110dpi
        // },
        // {
        //     name: QUALITY_IMAGE_LUMINANCE,
        //     content: IQ_GOOD [0.01, 0.06] [0.01, 0.02]
        // },
        // {
        //     name: QUALITY_IMAGE_HIGHLIGHTS,
        //     content: IQ_GOOD
        // },
        // {
        //     name: QUALITY_IMAGE_BLURRING,
        //     content: IQ_GOOD 1% 16%
        // }
    //]
    }
  }
  constructor(values : Object = {}){
    Object.assign(this, values);
  }
}
