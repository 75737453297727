// modules
import { NgModule, enableProdMode, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';

// other modules
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from './modules/shared/shared.module';
import { RegisterModule } from './modules/register/register.module';
import { ProfileModule } from './modules/profile/profile.module';

// services
import { NavigationService } from './services/navigation.service';
import { ErrorService } from './services/error.service';
import { HeaderService } from './services/headers.service';
import { ApiRecoveryService } from './services/api/api.recovery.service';
import { RecoveryService } from './services/recovery.service';
import { SpecificAnalyticsService } from './services/specific-analytics.service';
import { DeviceService } from './services/device.service';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { AnalyticsService } from './services/analytics.service';
import { HelperService } from './services/helpers.service';
import { LoginService } from './services/login.service';
import { ApiLoginService } from './services/api/api.login.service';
import { RecomiendaService } from './services/recomienda.service';
import { FormValidatorService } from './services/form-validator.service';
import { ProductSelection } from './services/product-selection.service';
import { NestedObject } from './services/nestedObject.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenService } from './services/token/auth.token.service';
import { InjectScriptService } from './services/inject-script.service';
import { ElementScrollService } from './services/element-scroll.service';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';

// components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderNewComponent } from './components/header-new/header-new.component';
import { MainComponent } from './components/main/main.component';

import { environment } from '../environments/environment';
import { OtpService } from 'app/services/otp.service';
import { ApiOtpService } from 'app/services/api/api.otp.service';
import { InterceptorErrorSrv } from 'app/services/token/interceptor.errors.service';

import { ConstantsService } from './services/constants.service';
import { AppSSOAuthGuard } from './services/app-SSO-auth.guard';
import { CookieModule } from 'ngx-cookie';
import { HttpTracingInterceptor, MONITORING_CONFIG, MonitoringModule } from '@evobanco/arq-otel-ng-monitoring-legacy';
import { ApiSsoService } from './services/api/api-sso.service';
const ENV_MONITORING_CONFIG = environment.monitoringConfig;

if (environment.production) {
  enableProdMode();
}

export function kcInitializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init(environment.keycloakOptions);
        console.log('Keycloak is initialized');
        // console.log(keycloak['_instance']);
        resolve();
      } catch (error) {
        console.log('Error thrown in init ' + error);
        reject(error);
      }
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent
  ],
  // only components, directives and pipes
  imports: [
    AppRoutingModule,
    HttpModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RegisterModule,
    ProfileModule,
    SharedModule,
    KeycloakAngularModule,
    CookieModule.forRoot(), 
    MonitoringModule
  ], // only ngModules
  providers: [
    { provide: APP_INITIALIZER, useFactory: kcInitializer, multi: true, deps: [KeycloakService] },
    { provide: MONITORING_CONFIG, useValue: ENV_MONITORING_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTracingInterceptor, multi: true },
    NavigationService,
    ErrorService,
    HeaderService,
    RecoveryService,
    SpecificAnalyticsService,
    AnalyticsService,
    DeviceService,
    AuthGuard,
    AuthService,
    LoginService,
    ApiLoginService,
    HelperService,
    ApiOtpService,
    OtpService,
    FormValidatorService,
    ApiRecoveryService,
    ProductSelection,
    NestedObject,
    AuthTokenService,
    InterceptorErrorSrv,
    InjectScriptService,
    Title,
    ElementScrollService,
    ConstantsService,
    AppSSOAuthGuard,
    ApiSsoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
