import { Directive, HostListener, ElementRef, Output, Input, EventEmitter, ViewContainerRef, ComponentRef } from '@angular/core';

@Directive({
  selector: '[drag]'
})

export class DragDropDirective  {

  private maxSizeMb = 15;
  private extensions: Array<string> = [
    'bmp', 'dib', 'jpeg', 'jpg', 'jpe', 'png', 'pbm', 'pgm', 'ppm', 'tiff', 'tif', 'jp2',
    'BMP', 'DIB', 'JPEG', 'JPG', 'JPE', 'PNG', 'PBM', 'PGM', 'PPM', 'TIFF', 'TIF', 'JP2'
  ];
  @Output() private filesChangeEmitter: EventEmitter <Object> = new EventEmitter();

  @HostListener('drop', ['$event']) public onDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    const eFiles = e.dataTransfer.files,
          side = e.currentTarget.id;
    let file = {};


    if (eFiles.length > 0) {
      for (let i = 0; i < eFiles.length; i++) {
        const ext = eFiles[i].name.split('.')[eFiles[i].name.split('.').length - 1];
        if (this.extensions.lastIndexOf(ext) !== -1 && eFiles[i].size < this.maxSizeMb * 1048576) {
          file = Object.assign({
            valid : true,
            file: eFiles[i],
            side: side
          })
        }
      }

        this.filesChangeEmitter.emit(file);
    }
  }

  // @HostListener('dragover', ['$event']) public onDragOver(e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  // }

  // @HostListener('dragleave', ['$event']) public onDragLeave(e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  // }

  constructor(
    private elementRef: ElementRef,
  ) {

  }


}
