import { Component, Input, OnInit, ElementRef, ComponentRef, Output, EventEmitter} from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() modalId:string;
  @Input() modalTitle: string;
  @Input() blocking = false;
  @Input() removePadding = false;
  @Input("full-screen") fullScreen: boolean = false;
  @Input("modal-wide") modalWide: boolean = false;

  @Output('reload') reload: EventEmitter<any> = new EventEmitter<any>();

  backdropId:string;

  hidden: boolean = true;

  constructor(public modService: ModalService, private el:ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.id = this.modalId;
    this.backdropId = `backdrop-${this.modalId}`;
    this.modService.registerModal(this);
  }

  close(): void {
    if(this.modalId === "modal-identify-video"){
      this.reload.emit(this.modalId);
    }
    this.modService.close(this.modalId, true);
  }
  //Evitamos que se al clickar sobre el modal se cierre
  modalClick(_ev) {
    _ev.stopPropagation();
  }

  getPadding() {
    if(this.removePadding) {
      return "0";
    } else {
      return "";
    }
  }
}
