<loading [showMe]="loading"></loading>

<mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone>

<modal [modalTitle]="'Modal Error'" [blocking]='false' [modalId]='modalError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<modal [modalTitle]="'Incomplete'" [blocking]='false' [modalId]='incompleteModal' class='modal'>
    <div class="container">
      <h2>¡Ups!</h2>
      <p class="invalid-text">{{msgSrvError}}</p>
    </div>
  </modal>

<modal [modalTitle]="'Condiciones Legales'" [blocking]='false' [modalId]='modalLegal' class='modal'>
  <div class="container">
    <h2>Aceptación de las condiciones legales</h2>
    <p>Confirmo haber obtenido el consentimiento para que EVO Banco pueda contactar y tratar lo datos personales de la persona que facilito en este apartado, con la finalidad de solicitar la contratación de la Cuenta Inteligente como segundo titular.</p>
  </div>
</modal>

<div class="heading">
    <h3>{{ myProductSrv.getLabelProducto(currentProduct)}}</h3>
    <h4>Toda la información que necesitas sobre tu <span> {{ currentProduct.nombreProducto.toLowerCase() }}. </span></h4>
</div>
<app-breadcrumb id="breadcrumb" class="mobile" [breadcrumbs]="breadcrumbNav" breadcrumbAdd="{{myProductSrv.getLabelProducto(currentProduct)}}"></app-breadcrumb>

<div class="my-product-details content-wrapper has-breadcrumb">
    <div class="row-detail">
      <h3>Datos de {{ currentProduct.nombreProducto }}</h3>
      <p [innerHTML]='myProductSrv.getDescriptionProducto(currentProduct)'></p>
      <div class="description">
        <p>Nombre del {{ myProductSrv.getTypeUser(currentProduct.relacionAcuerdoPersona) }}: <strong>{{ fullName }} </strong></p>
        <p *ngIf="currentProduct.segundoTitular != null">Segundo titular: <strong>{{ currentProduct.segundoTitular.nombreCliente }} {{ currentProduct.segundoTitular.apellido1Cliente }} {{ currentProduct.segundoTitular.apellido2Cliente }}</strong></p>
        <p *ngIf="currentProduct.iban || currentProduct.ibanCC">IBAN C.C: {{ currentProduct.iban || currentProduct.ibanCC }}</p>
        <p *ngIf="currentProduct.ibanCD">IBAN C.D: {{ currentProduct.ibanCD }}</p></div>
    </div>

    <div class="row-detail" *ngIf="currentProduct.idProducto == ACCOUNTS.CICode">
      <h3>MI CONTRATO DE {{ currentProduct.nombreProducto  }}</h3>
      <p>Y como nos gustan las cosas claras, aquí te dejamos tu contrato:</p>
      <div class="icon-download"></div>
      <div class="btn-group">
          <button class="btn-common btn-common_fix_bold" (click)="downloadContract()">VER MI CONTRATO</button>
      </div>
    </div>

    <div class="row-detail" *ngIf="false">
      <div class="flex-col-betwen">
        <div class="top-bloq">
          <h3>Estado de {{myProductSrv.getCardProducto(currentProduct)}}</h3>
          <states-card [stateCurrent]="stateCurrent"></states-card>
        </div>
        <div class="container-address">
          <div class="send-address row-flex-col">
            <h4>DIRECCIÓN DE ENVÍO</h4>
            <p class="address">{{ address }}</p>
          </div>
          <div class="need-help row-flex-col">
            <p>¿Necesitas ayuda?</p>
            <div class="phone"><i class="phone-image"></i><span class="phone-text pink"><a href='tel:910900900'>LLÁMANOS 910 900 900</a></span></div>
          </div>
          <!-- <button class="view-info">
              <span>VER INFORMACIÓN</span>
              <span class="arrow-icon evo right-arrow"></span>
          </button> -->
        </div>
      </div>
    </div>

    <!-- <div class="row-detail" *ngIf="currentProduct.idProducto != '008' && currentProduct.segundoTitular == null && currentProduct.idProducto != ACCOUNTS.STCode"> -->
    <div class="row-detail" *ngIf="canHaveSecondHolder">
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="secondHolderForm" (ngSubmit)="onSubmit()" *ngIf='!isSendSecondUser' novalidate>
        <h3>{{addSecondHolder}}</h3>
        <p>Indícanos la cuenta de correo del segundo titular para que podamos enviarle un correo y que inicie el proceso.</p>
        <div class="row">
            <div class="mobile-container div-secondholder">
                <input type="text"
                  #emailSecond
                  name="email"
                  formControlName="email"
                  value="" placeholder="Email del segundo titular"
                  autocomplete="off"
                  (blur)="outFocus(emailSecond)">
                  <div class="error-text" *ngIf="errService.desktopErrors(secondHolderForm.controls.email, 'email')">
                    {{errService.desktopErrors(secondHolderForm.controls.email, 'email')}}
                  </div>
            </div>
            <div class="mobile-container div-secondholder">
                <input type="text"
                  #confirmSecond
                  onCopy='return false' onPaste='return false'
                  name="confirmEmail"
                  formControlName="confirmEmail"
                  value="" placeholder="Confirma su email"
                  autocomplete="off"
                  (blur)="outFocus(confirmSecond)">
                  <div class="error-text" *ngIf="errService.desktopErrors(secondHolderForm.controls.confirmEmail, 'reactiveEquals')">
                    {{errService.desktopErrors(secondHolderForm.controls.confirmEmail, 'reactiveEquals')}}
                  </div>
            </div>
            <div class="mobile-container order-4 ">
              <div class="checkbox-group first">
                <div class="form-checkbox">
                  <input type="checkbox" 
                  name="checkConditions" 
                  formControlName="checkConditions"
                  id="checkConditions" 
                  (click)='openLegal()'
                  value="">
                  <label class="text-black" for="checkConditions" tabindex="3">He leido y acepto las <a class='legal-conditions'>condiciones legales</a></label>
                </div>
              </div>
            </div>
            <div class="mobile-container btn-add">
              <button type="submit" class="btn-common btn-common_fix_bold" name="button">Añadir segundo titular</button>
            </div>
          </div>
      </form>
      <div *ngIf='isSendSecondUser' class="secondHolderConfirm">
          <h3>segundo titular enviado</h3>
          <p>Has enviado un email a {{secondHolderForm.controls.email.value}} para darle de alta como segundo titular.</p>
          <!--
          <div class="btn-group top-margin">
              <button class="btn-common btn-common_fix_bold" (click)="addOtherSecondHolder()">Añadir otro titular</button>
          </div>
        -->
          <div class="row">
            <button class="btn-common btn-common_fix_bold" (click)="addOtherSecondHolder()">
              Añadir otro titular
            </button>
          </div>
          <!--
          <div class="row top-margin">
            <div class="mobile-container center mobile">
                <form (keydown.enter)='$event.preventDefault()' (ngSubmit)="addOtherSecondHolder()">
                  <button class="btn-common btn-common_fix_bold" type="submit">
                    Añadir otro titular
                  </button>
                  
                </form>
            </div>
          </div>-->
          <!--
          <div class="need-help">
            <p>¿Necesitas ayuda?</p>
            <div class="phone">
              <i class="phone-image"></i>
              <span class="phone-text pink">
                LLÁMANOS <a class="link-blue" href='tel:910900900'>910 900 900</a>
              </span>
            </div>
          </div>-->

          <div class="need-help">
            <p>¿Necesitas ayuda?</p>
            <div class="phone">
              <i class="phone-image"></i>
              <span class="phone-text pink">
                <a href='tel:910900900'>LLÁMANOS 910 900 900</a>
              </span>
            </div>
          </div>
      </div>
    </div>

</div>
