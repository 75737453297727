import { ConstantsService } from 'app/services/constants.service';
import {
  Component,
  Input,
  ViewChild,
  OnDestroy,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { AmpliacionService } from "../../services/ampliacion.service";
import { HelperService } from "../../services/helpers.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { LocationStrategy } from "@angular/common";

@Component({
  selector: "div-show",
  templateUrl: "./div-show.component.html",
  styleUrls: ["./div-show.component.scss"],
})
export class DivShowComponent implements OnInit, OnChanges {
  //public showMe: boolean;

  public EdgeClass: boolean = this.checkNavigator();
  public mostrarBoton: boolean = false;

  public limiteDisminucion: boolean = false;
  public limiteCero: boolean = false;
  public limiteCancelar: boolean = false;
  public revisandoDocumentacion: boolean = false;
  public modificarDisminucion: boolean = false;
  public imprevistoMorosidad: boolean = false;
  public imprevistoRiesgos: boolean = false;
  public loadingCustom: boolean = false;
  public ocultarErrores: boolean = false;
  public faltaInformacion: boolean = false;
  public astronauta: boolean = false;
  public preparadoFirma: boolean = false;
  public preparadoFirmaCI: boolean = false;
  public preparadoFirmaFI: boolean = false;
  public activarSegundoTitular: boolean = false;
  public preparadoFirmaST: boolean = false;
  public esperaFirmaST: boolean = false;
  public backFromLogalty: boolean = false;
  public textProduct: String = "";
  public isNotBmi: boolean = true;
  public pretitulo: String = "";
  public mostrarPretitulo: boolean = true;
  public nameFirstHolder: string;
  public emailSecondHolder: string;
  public isInversora: boolean = false;

  public imagen = "./assets/images/6_Rocket.gif";
  public imgLapiz = "./assets/images/1_Lapiz.gif";
  public imgSatelite = "./assets/images/2_Satelite.gif";
  public imgAlien = "./assets/images/3_Alien_ID.gif";
  public imgAstroboy = "./assets/images/4_Astroboy.gif";
  public imgVayaVaya = "./assets/images/5_VayaVaya.gif";
  public imgRocket = "./assets/images/6_Rocket.gif";
  public imgAmigosOk = "./assets/images/7_Amigos_OK.gif";
  public imgAmigosOk2 = "./assets/images/ic_telefono_bocadillo_persona.svg";
  public imgLock = "./assets/images/8_Lock.gif";
  public imgMaletin = "./assets/images/9_Maletin.gif";
  public imgSecuestro = "./assets/images/10_Secuestro.gif";
  public imgAltavoz = "./assets/images/11_altavoz.gif";
  public imgTodoOK = "./assets/images/todo-correcto.png";
  public titulo = "ESTAMOS VALIDANDO TU PETICIÓN";
  public subtitulo = "¡No toques nada que ya casi estamos ahí!";
  public _showMe = true;

  @Input("showMe") showMe: boolean;
  @Input("nameError") nameError;
  @Input("nombreSegundoTitular") nombreSegundoTitular;
  @Input("mailCliente") mailCliente;

  @Output("update")
  change: EventEmitter<any> = new EventEmitter<any>();

  /*@Input('imagen') imagen;
  @Input('titulo') titulo;
  @Input('subtitulo') subtitulo;
  @Input ('boton') boton;*/

  constructor(
    public helpers: HelperService,
    private activatedRoute: ActivatedRoute,
    private locationStrategy: LocationStrategy
  ) {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  ngOnInit() {
    console.log(this.nameError);
    /*
    if(this.boton !== ""){
      this.mostrarBoton = true;
    }
    */

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // PREPARADO PARA CASO DE
      //this.isParam=true;
      if (params.origen) {
        if (params.origen.toLowerCase() === "bmi") {
          // ocultar cabecera
          this.isNotBmi = false;
          this.mostrarPretitulo = false;
        }
      }
    });

    // Si se accede mediante una redirección desde logalty, se comprueba si es bmi desde los datos de sesión.
    if (sessionStorage.getItem("isBMI") === "true") {
      this.isNotBmi = false;
      this.mostrarPretitulo = false;
    }

    this.setTextProduct();
    if (sessionStorage.getItem("operacion-inversora")) {
      this.setNameAndEmail();
    }
    if (this.nameError === "limiteDisminucion") {
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.modificarDisminucion = false;
      this.limiteDisminucion = true;
      this.revisandoDocumentacion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "limiteCero") {
      this.limiteDisminucion = false;
      this.limiteCancelar = false;
      this.modificarDisminucion = false;
      this.limiteCero = true;
      this.revisandoDocumentacion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "limiteCancelar") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.modificarDisminucion = false;
      this.limiteCancelar = true;
      this.revisandoDocumentacion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "revisandoDocumentacion") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.modificarDisminucion = false;
      this.revisandoDocumentacion = true;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "limiteModificar") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = true;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "imprevistoMorosidad") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = true;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "imprevistoRiesgos") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = true;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "loadingCustom") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = true;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "ocultarErrores") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "faltaInformacion") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = true;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "astronauta") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = true;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "preparadoFirma") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = true;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "preparadoFirmaCI") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = true;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "preparadoFirmaFI") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = true;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "activarSegundoTitular") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = true;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "preparadoFirmaST") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = true;
      this.esperaFirmaST = false;
      this.backFromLogalty = false;
    } else if (this.nameError === "esperaFirmaST") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = true;
      this.backFromLogalty = false;
    } else if (this.nameError === "backFromLogalty") {
      this.limiteDisminucion = false;
      this.limiteCero = false;
      this.limiteCancelar = false;
      this.revisandoDocumentacion = false;
      this.modificarDisminucion = false;
      this.imprevistoMorosidad = false;
      this.imprevistoRiesgos = false;
      this.loadingCustom = false;
      this.faltaInformacion = false;
      this.astronauta = false;
      this.preparadoFirma = false;
      this.preparadoFirmaCI = false;
      this.preparadoFirmaFI = false;
      this.activarSegundoTitular = false;
      this.preparadoFirmaST = false;
      this.esperaFirmaST = false;
      this.backFromLogalty = true;
    }
  }

  ngOnChanges(changes) {
    document.body.classList.remove("pointer-events");
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "limiteDisminucion"
    ) {
      this.limiteDisminucion = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "limiteCero"
    ) {
      this.limiteCero = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "limiteCancelar"
    ) {
      this.limiteCancelar = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "revisandoDocumentacion"
    ) {
      this.revisandoDocumentacion = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "limiteModificar"
    ) {
      this.modificarDisminucion = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "imprevistoMorosidad"
    ) {
      this.imprevistoMorosidad = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "imprevistoRiesgos"
    ) {
      this.imprevistoRiesgos = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "loadingCustom"
    ) {
      this.loadingCustom = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "ocultarErrores"
    ) {
      this.ocultarErrores = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "faltaInformacion"
    ) {
      this.faltaInformacion = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "astronauta"
    ) {
      this.astronauta = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "preparadoFirma"
    ) {
      this.preparadoFirma = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "preparadoFirmaCI"
    ) {
      this.preparadoFirmaCI = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "preparadoFirmaFI"
    ) {
      this.preparadoFirmaFI = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "activarSegundoTitular"
    ) {
      this.activarSegundoTitular = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "preparadoFirmaST"
    ) {
      this.preparadoFirmaST = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "esperaFirmaST"
    ) {
      this.esperaFirmaST = true;
    }
    if (
      changes &&
      changes.nameError &&
      changes.nameError.currentValue === "backFromLogalty"
    ) {
      this.backFromLogalty = true;
    }
  }

  @Input()
  public set showMeb(value: boolean) {
    console.log("LoadingCustomComponent", value);
    /*
    if (value) {
      document.body.classList.add('pointer-events');
    } else {
      document.body.classList.remove('pointer-events');
    }
    this._showMe = value;
    */
    //console.log(this.titulo);
  }

  checkNavigator() {
    const UA = ["Edge", "MSIE"];
    const UA_IE11 = [
      "Mozilla/5.0",
      "Windows NT",
      "Trident/7.0",
      "rv:11.0",
      "like Gecko",
    ];
    let count = 0;
    //Edge y Explorer 10
    for (var i = 0; i < UA.length; i++) {
      if (navigator.userAgent.indexOf(UA[i]) > -1) return true;
    }
    //Explorer 11
    for (var i = 0; i < UA_IE11.length; i++) {
      if (navigator.userAgent.indexOf(UA_IE11[i]) == -1) return false;
      count++;
    }
    if (count == UA_IE11.length) return true;
    return false;
  }

  onSubmit() {
    /*
    if(this.boton == "Reintentar"){
      this.updateDni.tryAgain();
    }
    */
  }

  submit() {
    this.helpers.navigateTo("confirma-limite");
  }

  mantenerSolicitudDisminucion() {
    console.log("mantener solicitud");
  }

  /**
   * Se recoge por sesión la operación que se desea hacer y se llama
   * mediante el servicio ampliacionService al método correspondiente
   */
  continuarSolicitud() {
    console.log("continuar solicitud");
    let action = sessionStorage.getItem("operacion-ampliacion");

    if (action === "disminuir") {
      this.change.emit("disminuir");
    } else if (action === "disminuir0") {
      this.change.emit("disminuir0");
    } else if (action === "cancelar") {
      this.change.emit("cancelar");
    } else if (action === "ampliar") {
      this.change.emit("ampliar");
    } else if (action === "modificar") {
      this.change.emit("modificar");
    }
  }

  mantenerSolicitud() {
    this.showMe = false;
    this.nameError = "ocultarErrores";
    this.change.emit("ocultarErrores");
  }

  entendido() {
    let isAmpliacion = sessionStorage.getItem("operacion-ampliacion");
    let isInversora = sessionStorage.getItem("operacion-inversora");

    if (isAmpliacion || isInversora) {
      window.location.href = "https://bancaelectronica.evobanco.com/";
      return;
    }

    this.helpers.navigateTo("login");
  }

  /**
   * Establece el texto del producto en función de lo que se esté contratando
   * 009 -> Cuenta Inversora, 010 -> Fondo Inteligente
   */
  setTextProduct() {
    if (sessionStorage.getItem("productoContratando") === ConstantsService.AccountTypes.CuentaInversora.code) {
      this.isInversora = true;
      this.textProduct = "Fondos y Valores";
      this.pretitulo = "FONDOS Y VALORES";
    } else if (sessionStorage.getItem("productoContratando") === ConstantsService.AccountTypes.FondoInteligente.code) {
      this.textProduct = "Fondo Inteligente";
      this.pretitulo = "FONDO INTELIGENTE";
    } else if (sessionStorage.getItem("productoContratando") === ConstantsService.AccountTypes.CuentaInversoraCorto.code) {
      this.textProduct = "Alta plataforma de inversión";
      this.pretitulo = "ALTA PLATAFORMA DE INVERSIÓN";
      this.titulo = "Estamos revisando tus datos";
      this.subtitulo = "Este proceso puede que tarde un poco. Por favor, no cierres la pantalla."
    }
  }

  /**
   * Establece el nombre del primer titular y el email del segundo titular y se recoge de la sesión,
   * en concreto de getCaso.
   */
  setNameAndEmail() {
    if (sessionStorage.getItem("getCaso")) {
      let data = JSON.parse(sessionStorage.getItem("getCaso"));
      this.nameFirstHolder =
        data.infoCaso.datosPrimerTitular.datosPersonales.nombreCliente;
      if (data.infoCaso.datosSegundoTitular) {
        this.emailSecondHolder =
          data.infoCaso.datosSegundoTitular.datosPersonales.email;
      }
    }
  }
}
