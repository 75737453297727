import { Injectable } from '@angular/core';
import { ACCOUNTS } from 'app/resources/account.model';

@Injectable()
export class MyInterestService {

  constructor() { }

  prepareSlides(listToContract:any[], listPolicies) {
    let slideToContract = [];

    for(let toContract of listToContract) {
      let idProducto = toContract.idProducto;
      switch(idProducto) {
        case ACCOUNTS.CICode:
          slideToContract.push({
            image: './assets/images/profile/profile-extras/inversion.jpg',
            title: toContract.nombreProducto,
            ibanCC:toContract.ibanCC,
            ibanCD:toContract.ibanCD,
            text: '',
            goTo: 'cuentaInteligente',
            idProducto: idProducto
          });
          break;

        case ACCOUNTS.CDCode:
          slideToContract.push({
            image: './assets/images/profile/profile-extras/inversion.jpg',
            title: toContract.nombreProducto,
            ibanCC:toContract.ibanCC,
            ibanCD:toContract.ibanCD,
            text: '',
            goTo: 'cuentaDuo',
            idProducto: idProducto
          });
          break;

        case ACCOUNTS.CJCode:
          slideToContract.push({
            image: './assets/images/profile/profile-extras/inversion.jpg',
            title: toContract.nombreProducto,
            ibanCC:toContract.ibanCC,
            ibanCD:toContract.ibanCD,
            text: '',
            goTo: 'cuentaJoven',
            idProducto: idProducto
          });
          break;

        case ACCOUNTS.STCode:
          slideToContract.push({
            image: './assets/images/profile/profile-extras/inversion.jpg',
            title: toContract.nombreProducto,
            ibanCC:toContract.ibanCC,
            ibanCD:toContract.ibanCD,
            text: '',
            goTo: 'segundoTitular',
            idProducto: idProducto
          });
          break;
      }
    }

    return slideToContract;

  }

}
