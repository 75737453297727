import { Injectable  } from '@angular/core';

@Injectable()
export class VideoSelectionService {

  videoUrl:string='';
  poster:string='';
  hour;
  day;

  constructor() {
    let date = new Date();
    this.hour = date.getHours();
    this.day = date.getDay();
    this.selectVideo();
   }
   selectVideo(day = this.day) {
     if(day == 6 || day == 0) {
       this.isWeekend();
     } else {
       this.isWeekdays();
     }
   }

   isWeekend(hour = this.hour) {
     if(0 <= hour && hour < 6 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/8_alarma_MASTER.mp4';
     if(6 <= hour && hour < 10 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/1_bicicleta_MASTER.mp4';
     if(10 <= hour && hour < 15 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/9_piraguismo_MASTER.mp4';
     if(15 <= hour && hour < 17 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/10_tortilla_MASTER.mp4';
     if(17 <= hour && hour < 21 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/11_piano_MASTER.mp4';
     if(21 <= hour && hour < 23 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/12_pizza_MASTER.mp4';
     if(23 <= hour ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/7_libro_MASTER.mp4';
   }

   isWeekdays(hour = this.hour) {
     if(0 <= hour && hour < 6 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/8_alarma_MASTER.mp4';
     if(6 <= hour && hour < 9 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/1_bicicleta_MASTER.mp4';
     if(9 <= hour && hour < 12 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/2_reunion_MASTER.mp4';
     if(12 <= hour && hour < 14 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/3_manzanas_MASTER.mp4';
     if(14 <= hour && hour < 16 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/4_lentejas_MASTER.mp4';
     if(16 <= hour && hour < 20 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/5_taza_MASTER.mp4';
     if(20 <= hour && hour < 23 ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/6_fotosistesis_MASTER.mp4';
     if(23 <= hour ) this.videoUrl = 'https://videosevobanco.azureedge.net/public/fullonline/assets/videos_evo/7_libro_MASTER.mp4';
   }

}
