import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VideoId, VerifiEID } from '../../collections/video';
import { ErrorService } from '../error.service';
import { HeaderService } from '../headers.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { of } from 'rxjs';

import { NestedObject } from '../nestedObject.service';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiVideoeidService {
  sessionVideoId: VideoId;
  headerSrv: HeaderService;
  options = {};
  body = {};
  constructor(private http: HttpClient,
    private errService: ErrorService,
    @Inject(HeaderService) headerSrv: HeaderService,
    public nestedObject: NestedObject) {
    this.headerSrv = headerSrv;
    this.sessionVideoId = this.getSessionVideo();
    this.options = { withCredentials: true, observe: 'response' };
  };

  public getVideo(id: string) {
    if (this.sessionVideoId.id === id && this.sessionVideoId.status.toLowerCase() !== 'completed') {
      return of(this.sessionVideoId);
    }
    return this.http
      .get(`${API_URL}/fullonline/terceros/electronicId/videoid/${id}`, this.options)
      .pipe(map(response => {
        const video = response['body'];
        video.document.expiryDate = this.formatDate(video.document.expiryDate);
        video.document.subject.birthDate = this.formatDate(video.document.subject.birthDate);
        this.setSessionVideo(video);
        return video;
      }));
  };

  private formatDate(date): string {
    const now: Date = new Date();
    const past: Date = new Date(date.substr(0, 2), date.substr(2, 2) - 1, date.substr(4, 2));
    const diff: number = now.getFullYear() - past.getFullYear();
    if (Math.abs(diff) >= 70) {
      past.setFullYear(past.getFullYear() + 100);
    }
    return past.getTime().toString();
  };

  public verificationEID(video: VerifiEID) {
    return this.http
      .post(`${API_URL}/fullonline/terceros/electronicId/verification_requests`, video, this.options)
      .pipe(map(response => {
        const video = response['body'];
      }));
  };

  private setSessionVideo(videoId: VideoId) {
    const previousVideo = JSON.parse(sessionStorage.getItem('currentVideoId')) || {},
          currentVideo = this.nestedObject.nestedObjectAssign({}, previousVideo, videoId);

    sessionStorage.setItem('currentVideoId', JSON.stringify(currentVideo) || null);
  };

  public getSessionVideo(): VideoId {
    const videoId = JSON.parse(sessionStorage.getItem('currentVideoId'));
    if (videoId && videoId.id) { return videoId };
    return new VideoId();
  };
  
  public getAuthorizationEID(){
    this.options = { withCredentials: true, observe: 'response'}
    this.body = {};
    return this.http
    .post(`${API_URL}/fullonline/terceros/electronicId/videoid.request`, this.body, this.options)
    .pipe(map(response => {return response}));
  }

}
