<header id="header">
  <h1 class="logo desktop" (click)='goToProfile()' *ngIf="menuShow"> </h1>
  <h1 class="evo responsive-logo mobile-only" (click)='goToProfile()' *ngIf="menuShow"></h1>
  <h1 class="logo desktop logo-img" *ngIf="!menuShow"> </h1>
  <h1 class="evo responsive-logo mobile-only"*ngIf="!menuShow"></h1>
  <div class="title"><a class="title-text" [innerHTML]="title"></a></div>
  <div *ngIf='isLogin' class="full-height">
 <!-- PONER MENUSHOW-->
    <div class="mobile-only">
      <div class="nav-items" *ngIf="menuShow">
        <div class="container-icon">
          <button class="container-icon" (click)='goToNotification()'>
            <div class="icon notification"></div>
            <div class="count">{{notifications.length}}</div>
          </button>
          <a class="evo burguer-menu" (click)='toggleMenu()'></a>
          <div class="burguer-background" [ngClass]='showMenu ? "active" : ""'></div>
          <div class="burguer-content" [ngClass]='showMenu ? "active" : ""'>
            <div class="row avatar-container">
              <div class="avatar"></div>
            </div>
            <div class="row user">
              <span class="username">{{username}}</span>
            </div>
            <!-- <div class="row" (click)='goToProfile()'>
              <a class="link-text">Mi perfil</a>
              <span class="evo right-arrow"></span>
            </div> -->
            <div class="row" (click)='goToMyProducts()'>
              <a class="link-text" >Mis productos</a>
              <span class="evo right-arrow"></span>
            </div>
            <!-- <div class="row">
              <a class="link-text" (click)='goToNotification() '>Mis notificaciones</a>
              <span class="evo right-arrow"></span>
            </div> -->
            <div class="row" (click)='logOut()'>
              <div class="logout-wrap">
                <i class="evo logout"></i><a class="link-text">Desconectar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="desktop flex">
      <div class="nav-items" *ngIf="menuShow">
        <div id="products">
          <span class="link-text" [ngClass]="selected == 'mis-productos' ? 'selected' : ''" (click)='goToMyProducts()'>Mis productos</span>
        </div>
        <div id="notifications" (mouseover)='placeConnector("notifications")' (mouseleave)='placeConnector()'>
          <button class="container-icon" (click)='goToNotification()'>
            <div class="icon notification"></div>
            <div class="count">{{notifications.length}}</div>
          </button>
          <div class="nav-extra notifications" id="notifications-extra">
            <div class="calendar">
              <div class="title">
                <span>Notificaciones</span>
                <span class="blue-link" (click)='goToNotification()'>ver todas</span>
              </div>
              <ul>
                <li *ngFor='let notification of notifications'
                (click)='goToNotification(notification)'>
                  <div class="row timetable">
                    <b>{{notification['title']}}</b>
                  </div>
                  <div class="row timetable">
                    <p>{{notification['date']}}</p>
                  </div>
                </li>
            </ul>
          </div>
          </div>
        </div>
        <!-- <div id="shopping-cart">
          <button class="container-icon hide">
            <div class="icon shopping"></div>
            <div class="count">2</div>
          </button>
        </div> -->
        <div id="user">
          <span class="link-text">{{username}}</span>
        </div>
        <div id="avatar" class="avatar-container" (mouseover)='placeConnector("avatar")' (mouseleave)='placeConnector()'>
          <div class="avatar"></div>
          <div class="arrow evo right-arrow"></div>
          <div class="nav-extra logout" id="avatar-extra">
            <i class="evo logout"></i>
            <span class="nav-text" (click)='logOut()'>desconectar</span>
          </div>
        </div>
        <div class="connector" id="connector"></div>
      </div>
    </div>
  </div>
</header>
