import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../collections/user';
import { map, first } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { GetContractResponse } from '../../collections/get-contract';
import { AuthTokenService } from '../token/auth.token.service';
import { SsoResponse } from 'app/collections/ssoResponse';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiUserService {
  private refresh: object = {};
  private options = {};
  constructor(
    private http: HttpClient,
    public auth: AuthTokenService) {
    this.options = { withCredentials: true, observe: 'response' };
  }

  // TODO: Eliminar (deprecated)
  // API: POST /holders
  public getUserData(user): Observable<User> {

    return this.http
      .post(`${API_URL}/fullonline/salesforce/personainfo/v1/login`,
        user,
        this.options)
      .pipe(map(response => response['body']));
  }

  public getMyContract(codigoProducto): Observable<GetContractResponse> {
    return this.http
      .get(API_URL + '/fullonline/integracion/firma/contract?codProductoContratado=' + codigoProducto,
        this.options)
      .pipe(map(response => response['body']));
  }

  public getClientData(): Observable<User> {

    return new Observable<User>(observer => {

      this.http.post(API_URL + '/fullonline/salesforce/v1/datosCliente', null,
        this.options)
        .subscribe(
          (data: HttpResponse<any>) => {
            if (data.headers.get('authorization')){
              this.auth.setToken(data.headers.get('authorization'));
            }
            observer.next(new User(data['body']));
            return observer.complete();
          },
          (error: any) => {
            observer.error(error);
            return observer.complete();
          }
        );
    });
  }

  public getConsoleRedirection(params): Observable<any> {

    return new Observable<any>(observer => {

      let url = `${API_URL}/evobanco/sso/web/portals/consola?${params}`

      this.http.get(url, this.options)
        .subscribe(
          (data: HttpResponse<any>) => {
            observer.next(data.headers.get('Location'));
            return observer.complete();
          },
          (error: any) => {
            observer.error(error);
            return observer.complete();
          }
        );
    });
  }
  public postSso(uuid: string, usuarioBE: string): Observable<any> {
    const body = new HttpParams()
      .set('tpbe_pass', uuid)
      .set('usuarioBE', usuarioBE);

    return this.http
      .post(`${API_URL}/evobanco/v1/sso`,body,
      {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Accept', 'application/json')
      })
      .pipe(map(response => response));
  };
}
