
<loading [showMe]="loading"></loading>
<loading-custom [showMe]="loadCustom" 
                [titulo]="titulo" 
                [imagen]="imagen"
                [subtitulo]="subtitulo"
                [boton]="boton">
</loading-custom>
<div-show [showMe]="showError" [nameError]="nameError" (update)="updateComponent($event)"></div-show>
<div class="banner banner-narrow" *ngIf="showValidar && showHeader">
  <div class="banner-content">
    <div class="message-container">
      <i class="evo casi-esta"></i>
      <span class="banner-message">
        Estamos validando tu petición
      </span>
    </div>
    <!--<i class="evo close"></i>-->
  </div>
</div>
<div class="banner-bmi banner-narrow" *ngIf="showValidar && !showHeader">
  <div class="banner-content">
    <div class="message-container">
      <i class="evo casi-esta"></i>
      <span class="banner-message">
        Estamos validando tu petición
      </span>
    </div>
    <!--<i class="evo close"></i>-->
  </div>
</div>
<section class="c-header" *ngIf="showHeader">
  <div class="responsive-header top10">
    <!-- <div class="backbutton" (click)="botonVolver()">
      <i class="evo flecha"></i>
    </div> -->
    <div class="page-title">
      <span>Modificar límite de crédito</span>
    </div>
    <div class="closebutton">
      <i class="evo close"></i>
    </div>
  </div>
</section>
<form class="form" action=""> <!-- [formGroup]="limiteForm" novalidate-->
  <div class="main-content">
    <section class="info text-amplia" *ngIf="showPeticionRecibida">
      <p class="info-title">Petición recibida</p>
      <div class="info-description_img">
        <i class="evo icon-okhand info-description_img--icon"></i>
        <p class="info-description">Ya hemos recibido tu petición, cuando esté finalizada recibirás un email.</p>
      </div>
    </section>
    <section class="info">
      <p class="info-title">Límite de Crédito</p>
      <p class="info-description">Amplia o reduce tu límite de crédito. 
        Tu nuevo límite de disposición en cajero a crédito será máximo 600€, 
        salvo que tu nuevo límite de crédito sea inferior, en tal caso se ajustará al importe solicitado.</p>
    </section>
    <section class="cards card-ampliacion">
      <div class="cardselected">
        <span class="cardselected-title">Tarjeta SELECCIONADA</span>
        <span class="link-arrow" (click)="cambiarTarjeta()" *ngIf="cambioTarjetasActive">
            Cambiar
            <i class="evo right-arrow"></i>
        </span>
      </div>
      <div class="cardinfo">
        <div class="cardinfo-item">
          <i class="evo icon-creditcard cardinfo-item-img"></i>
          <div class="cardinfo-data">
            <p class="title">Tarjeta de crédito</p>
            <p class="description">************{{lastDig}}</p>
          </div>
        </div>
        <div class="cardinfo-item">
          <i class="evo open-wallet cardinfo-item-img"></i>
          <div class="cardinfo-data">
            <p class="title">Límite actual</p>
            <p class="description">{{limCredito}} €</p>
          </div>
        </div>
      </div>
      <div class="carddata">
        <p class="title">Modifíca tu límite</p>
        <div class="slidecontainer">
          <div class="slider-level">
            <span *ngIf="disabledRange" class="min">-</span>
            <span *ngIf="disabledRange" class="max">+</span>
            <span *ngIf="!disabledRange" class="min" (click)="disminuirValor()">-</span>
            <span *ngIf="!disabledRange" class="max" (click)="aumentarValor()">+</span>
          </div>
          <input type="range" [disabled]="disabledRange" min="{{minValue}}" max="6000" step="100" value="rangeLimit" 
          class="slider" id="myRange" name="rango" [(ngModel)]="myLimit" (ngModelChange)="actualizaLimite($event)">
          <div class="slider-level">
            <div class="level-min">
              <span>{{minValue}} €</span>
              <span>Min</span>
            </div>
            <div class="level-max">
              <span>6.000 €</span>
              <span class="text-max">Max</span>
            </div>
          </div>
        </div>
        <div class="carddata-message" *ngIf="!disabledRange && mostrarLimiteTemporalSlider">
          <p>Si quieres reducir tu límite de uso temporalmente, manteniendo tu límite de crédito actual, 
            llama al 910900900. Así podrás cambiarlo cuando quieras con efecto inmediato.</p>
            <br>
        </div>
        <div class="carddata-limit">
          <input type="text" [disabled]="disabledRange" [(ngModel)]="myLimit" (ngModelChange)="actualizaLimite($event)"
          class="inputslider" id="limite" name="limite" [pattern]="patternLimite" minlength="3" maxlength="4" min="{{minValue}}" max="{{maxValue}}">
          <!--formControlName="limite"  (onFocus)="resetValor($event)"-->
          <!--VALIDAR NUMEROS MAYORES 6000-->
          <span class="cardata-limit-currency">€</span>
          <div class="carddata-limit-message carddata-limit-message-line-height-one">
              <p *ngIf="showValidar && !mostrarCuotaFija && showCancelarModificar">
                Si quieres disminuir tu límite de crédito actual o reducirlo a cero, tienes que cancelar esta solicitud.
              </p>
              <p *ngIf="mostrarCuotaFija">
                Si tu forma de pago es cuota fija tu nueva cuota mínima será: {{porcentajeLimit}}€
              </p>
          </div>
        </div>
        <div class="carddata-check" *ngIf="mostrarCheckLimite && !showValidar">
            <div class="form-checkbox">
              <input id="check_reducir" class="i-checkbox" name="check_reducir"[(ngModel)]="checkReducir" (ngModelChange)="toggleReducirCheckbox($event)" type="checkbox" value="Acepto">
              <label class="l-checkbox" for="check_reducir">Reducir a 0 euros tu límite de crédito</label>
            </div>
        </div>
        <div class="carddata-message" *ngIf="mostrarLimiteTemporal">
          <p>Si quieres reducir tu límite de uso temporalmente, manteniendo tu límite de crédito actual, 
            llama al 910900900. Así podrás cambiarlo cuando quieras con efecto inmediato.</p>
        </div>
      </div>
    </section>
    <div class="btn-group_fix"  *ngIf="mostrarSubmit">
      <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="continuarAmpliacion()" [disabled]="!limitChanged || invalidLimit">
        Continuar
      </button>
    </div>
    <div class="btn-group_fixreverse margin-top-50" *ngIf="showValidar && showCancelarModificar">
      <button class="btn-common btn-secondary_fix btn-common_fix_bold" [disabled]="bttCancelarActive" (click)="anularLimite()">
        Cancelar solicitud
      </button>
      <button class="btn-common btn-common_fix btn-common_fix_bold" [disabled]="bttModificarActive" (click)="modificar()" [disabled]="!limitChanged || invalidLimit">
        Modificar
      </button>
    </div>
  </div>
</form>