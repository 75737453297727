import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obfuscate',
  pure: true
})
export class ObfuscatePipe implements PipeTransform {

  transform(value: string, type?: string): string {
    let finalResult = '';
    const maskMail = (email: string): string => {
      let result = '';
      const preAtsubstr: string = email ? email.substring( 0,  2) : '';
      result = email ? email.replace(preAtsubstr, '*'.repeat(preAtsubstr.length)) : '';
      const postAtsubstr: string = email ? email.substring(email.indexOf('@'), email.lastIndexOf('.')) : '';
      result = result.replace(postAtsubstr, '*'.repeat(postAtsubstr.length));
        return result;
    }

    const maskPhone = (phone: string | number, startAt: number = 3, count: number = 3): string => {
      let result = '';
      const inputPhone = phone ? typeof(phone) === 'string' ? phone : phone.toString() : '';
      const phoneSubstr = inputPhone ? inputPhone.substring(startAt, (startAt + count)) : '';
      const prePart = inputPhone ? inputPhone.substring(0, startAt) : '';
      const hiddenPart = '*'.repeat(phoneSubstr.length);
      const postPart = inputPhone ? inputPhone.substring(startAt + count) : '';
      result = `${prePart}${hiddenPart}${postPart}`;
        return result;
    };
    switch (type) {
      case 'maskEmail':
          finalResult = maskMail(value);
          break;
      case 'maskPhone':
          finalResult = maskPhone(value);
          break;
      default:
        return finalResult;
    }
    return finalResult;
  }

}
