import { Component, ElementRef, OnInit, NgZone, ViewChild, Input, AfterViewInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from 'app/services/auth.service';
import { AuthUserService } from 'app/services/auth-user.service';
import { ModalService } from 'app/services/modal.service';
import { DeviceService } from 'app/services/device.service';
import { ErrorService } from 'app/services/error.service';
import { HelperService } from 'app/services/helpers.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ConfirmPersonal } from 'app/collections/confirmPersonal';
import { ModifyData } from 'app/collections/modifyData';
import { Persona } from 'app/collections/persona';
import { FormValidatorService } from 'app/services/form-validator.service';
import { AmpliacionService } from 'app/services/ampliacion.service';
import { ApiLocalidadesService } from 'app/services/api/api.localidades.service';
import { LocalidadesService } from 'app/services/localidades.service';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';

import { environment } from 'environments/environment';
import { LoginService } from 'app/services/login.service';
import { RecoveryService } from 'app/services/recovery.service';
import { ApiRecoveryService } from 'app/services/api/api.recovery.service';
import { HeaderService } from 'app/services/headers.service';
import { PersonaService } from 'app/services/persona.service';
import { JsonService } from 'app/services/json.service';
import { Modal } from 'app/collections/modal';

import { PAISES } from 'app/resources/countries.model';
import { GENEROS } from 'app/resources/genders.model';
import { SITUACIONESLABORALES } from 'app/resources/situacion-laboral.model';
import { SITUACIONES } from 'app/resources/situacion-vivienda.model';
import { VIAS } from 'app/resources/tipo-via.model';
import { TIPOSCONTRATO } from 'app/resources/tipo-contrato.model';
import { ESTADO_CIVIL } from 'app/resources/civil-status.model';
import { TIPOACTIVIDADPROFESIONAL } from 'app/resources/tipo-actividad-profesional.model';
import { ACTIVEFIELDS } from 'app/resources/active-fields';
import { ERRORSFORM } from 'app/resources/errors-form';
import { ACCOUNTS } from 'app/resources/account.model';
import { REGIMENES } from 'app/resources/regimen-economico.model';
import { FIELDS } from 'app/resources/modify-fields.model';
import { PROVINCES } from 'app/resources/provincias.model';
import { CNAE } from 'app/resources/cnae.model';
import { PROFESIONES } from 'app/resources/profesiones.model';
import { CAMPOSCONSOLA } from 'app/resources/campos-consola.model';
declare var google;

@Component({
  selector: 'my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  providers: [
    FormValidatorService,
    ModalService,
    DeviceService,
    JsonService,
    LocalidadesService,
    ApiLocalidadesService
  ]
})

export class MyProfileComponent implements OnInit {

  public isDataLoaded = false;
  modifyDataForm: FormGroup;
  loading: boolean = false;
  username: string = '';
  confirmPersonal: ConfirmPersonal;
  modifyData: ModifyData;
  nameInit: string = '';
  firstNameInit: string = '';
  secondNameInit: string = '';
  expiryDateInit: string = '';
  birthDateInit: string = '';
  sexInit: string = '';
  nacionality: string = '';
  exceptionMsg = "Lo sentimos, no podemos seguir con la solicitud online.";
  msgErrorName = "El valor del campo Nombre debe tener la misma longitud y solo puede diferir en dos caracteres del valor actual";
  msgErrorFirstName = "El valor del campo Primer apellido debe tener la misma longitud y solo puede diferir en dos caracteres del valor actual";
  msgErrorSecondName = "El valor del campo Segundo apellido debe tener la misma longitud y  solo puede diferir en dos caracteres del valor actual";
  msgDniExpired = "¡Vaya! Tenemos tu documento de identificación caducado, necesitamos que lo actualices.";
  incompleteMsg = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.';
  msgSrvError: string = '';
  modalSrvError: string = 'modal-srv-error';
  private persona: Persona  = this.authService.getSessionProspect();
  surnames: string[];
  surnameOptions = new FormGroup({
    surnameOption: new FormControl()
  })
  public isLoggedUser: boolean = false;
  public modalSurname = 'surname';
  modal = new Modal();
  public isYoungAccount: boolean = false;
  public title: string = 'REVISA LOS DATOS DE TU DNI';
  public cod: string;
  public userData: any;
  public userInfo: any;
  public personData: any;
  public expiryDate;
  public sex;
  public birthCountry;
  public birthProvince;
  public residenceCountry;
  public activeFields = ACTIVEFIELDS;
  public camposConsola = CAMPOSCONSOLA;
  public situaciones = SITUACIONES;
  public civilStatus = ESTADO_CIVIL;
  public vias = VIAS;
  public tiposActividad = TIPOACTIVIDADPROFESIONAL;
  public tipoActividadProfesional = [{ name: 'Tipo de actividad profesional', value: null, profesion: null }];
  public noSelectedProfession = true;
  public tiposContrato = TIPOSCONTRATO;
  public camposForm = FIELDS;
  public economicSystems = REGIMENES;
  public countries = PAISES;
  public provinces = PROVINCES;
  public situacioneslab = SITUACIONESLABORALES;
  public cnae = CNAE;
  public profesiones = PROFESIONES;
  public errors = ERRORSFORM;
  public direccion: string = '';
  public situacionVivienda;
  public situacionLaboral;
  public editable: boolean = false;
  public btnShow: boolean = true;
  public hiddeBanner: boolean = false;
  public hideBannerNotificacionOk: boolean = false;
  public showEdit: boolean = false;
  hidden = true;
  public showDataFlex: boolean = false;
  public codProductoContratado;
  public isMarried: boolean = false;
  public isMortgage: boolean = false;
  public isRental: boolean = false;
  public isAutonomo: boolean = false;
  public isCuentaAjena: boolean = false;
  public validacionPendiente: boolean = false;
  public updateDni: boolean = false;
  public EE_I_Inicial: any;
  public EE_I_Modificacion: any;

  public showWarningUpdateDocument: boolean = false;
  public showWarningValidating: boolean = false;
  public showWarningNoDocument: boolean = false;
  public tipoSolicitud: any;
  public tieneSolicitudes: boolean = false;
  public solicitudesEnCurso = [];
  public clientID;
  public hiddeNotification: boolean = false;
  public btnModificar: boolean = false;
  public porcentajeBarra = 0;
  public barraStyle: object;
  private currentProfesion = null;
  public titlePage: string = "MIS DATOS";
  public subtitlePage: string;
  public fechaInvalida: boolean = false;
  public fechaAltaAutonomoInvalida: boolean = false;
  public btnConfirmar:boolean = false;
  public objAmpliacion: any;
  public clase = "dropdown-group--wauto";
  public wizard:boolean = false;
  public dia;
  public mes;
  public anyo;
  public link: string = 'Entendido';
  public currentPath: string;
  public step: number;
  public solicitud: string = "";
  public camposErroneos: any;
  public showWithContract: boolean = false;
  public nombreProceso: string = "";
  public dateAntiguedad: any;
  public peticionModificacion: boolean = false;
  //div-show
  public showError: boolean = false;
  public nameError: string = "";

  //BMI
  public accessToken: any;
  public idExterno: any;
  public refreshToken: any;
  public origen: any;
  public isBmi: boolean = false;

  public user: any;

  public showErrorObligacionesFiscales: boolean = false;

  showCurtain: boolean = false;
  nameMessage: string = '';
  public showObligaciones: boolean = false;
  public fechaInitAlquiler: string = "";
  public showInfo: boolean = true;
  public isContratacion: boolean = false;

  public localidad;
  public localidadLiteral = { name: 'Localidad', value: null };
  private noLocalidadCounter: boolean = false;
  selectLocalidades = [{ name: 'ciudad', value: null }];
  private ACTUALIZE: boolean = false;
  commonLaborDetailsFiels = ['profesion', 'tipoActividad'];
  autonomoFields = ['cnae', 'fechaAltaAutonomo'];
  cuentaAjenaFields = ['tipoContrato', 'nombreEmpresa', 'antiguedadLaboral','ingresosMensuales', 'numeroPagas'];
  searchDirection: FormControl;

  private isCampanyaSubidaDoc: boolean = false;

  private operacionAmpliacion: string = '';

  private idProducto;
  private sessionUserData;

  private hasValidacionPendiente:boolean = false;
  private hasSubidaDocCorrecta:boolean = false;

  private hasDNIcaducado:boolean = false;
  private hasNotDNIinRSI:boolean = false;

  private hasCamposErroneos:boolean = false;

  private backFromSubidaDoc:boolean = false;

  private allOKAnalyticsPageName:string = '';

  private comprobadaSolicitudEnTramite = false;
  private existeSolicitudEnTramite = false;

  public _isMobileOrTablet: boolean = false;

  @ViewChild("direction")
  public DirectionElementRef: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private authUserService: AuthUserService,
    public modService: ModalService,
    private deviceService: DeviceService,
    public errService: ErrorService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private loginSrv: LoginService,
    private recoveryService: RecoveryService,
    private headerSrv: HeaderService,
    private personaSrv: PersonaService,
    private jsonService: JsonService,
    private evoValidator: FormValidatorService,
    private activatedRoute: ActivatedRoute,
    private ampliacionService: AmpliacionService,
    private localidadesSrv: LocalidadesService,
    private agm: MapsAPILoader,
    private ngZone: NgZone
  )
  {
    this.modifyData = new ModifyData();

    this.operacionAmpliacion = '';
    this.hasValidacionPendiente = false;
    this.hasSubidaDocCorrecta = false;

    this.router.events.subscribe((url:any) => {
      if(url instanceof NavigationEnd) {
        this.currentPath = url.url;
        this.setSteps();
      }
    });

  }

  ngOnInit() {

    this.getQueryParams();
    if (this.loginSrv.campanyaSubDoc){
      this.analytics.stopProfileNavTags();
    }
    // //Si venimos de la pantalla de login continuamos con el flujo.
    if(sessionStorage.getItem("isLogin") === "true" ||
       sessionStorage.getItem("abandono") === "true" ||
       (this.accessToken && !this.isCampanyaSubidaDoc)){
      this.continueFlow();
    }
  }

  private getPersona(){
    this.loading = true;
    this.personaSrv.getPersonaById(this.clientID, this.idProducto).subscribe(data => {
      this.loading = false;
      this.inicializarDatosForm(data);

     if (this.tipoSolicitud === null)
      {
        this.getWarnings();
      }
      else {
        if (this.operacionAmpliacion){

        }
        else if (sessionStorage.getItem('updateData')  &&  sessionStorage.getItem('updateData') !== 'updateDni' &&
        (sessionStorage.getItem('peticion-modificacion') !== 'true')) {
          this.doEditable();
        }
        else {
          this.checkTipoSolicitud();
          this.loading = false;
        }
      }
      this.validarCampos(false);
      this.loading = false;
    }, error => {
      if(this.tipoSolicitud !=='04') {
        this.errService.navToError();
      }
    });
  }

  inicializarDatosForm(data){
    this.personData = data;

      if (this.isBmi) {
        this.loginSrv.userInfo = data;
        this.loginSrv.comprobacionDatos();
      }

      //Datos para probar los dropdowns
      this.personData.datosSocioEco.codigoCNO = this.helpers.cleanObjValues(this.personData.datosSocioEco.codigoCNO);
      this.personData.datosSocioEco.codigoContrato = this.helpers.cleanObjValues(this.personData.datosSocioEco.codigoContrato);
      this.personData.datosSocioEco.profesion = this.helpers.cleanObjValues(this.personData.datosSocioEco.profesion);

      //Asignamos valor de situacionVivienda
      this.situacionVivienda = this.personData.datosSocioEco.situacionVivienda;

      if (this.situacionVivienda === '1' || this.situacionVivienda === '4') {
        this.isMortgage = true;
        this.isRental = false;
      }
      if (this.situacionVivienda === '2') {
        this.isMortgage = false;
        this.isRental = true;
      }

      this.initForm();

      this.addGeneralData();
      this.addPersonalData();
      this.addContactData();
      this.addLaborData();

      this.direccion = this.direccion.length > 0 ? this.direccion : this.modifyData.postalContact.streetName + ' ' + this.modifyData.postalContact.streetNumber + ', ' + this.modifyData.postalContact.postalCode + ' ' + this.modifyData.postalContact.residenceProvince;

      this.setEEIInicial();

      if (this.modifyData.personalData.civilStatus === 'Casado/a') {
        this.isMarried = true;
      }

      if (this.modifyData.laborData.situacionLaboral === 'TRABAJADOR POR CUENTA AJENA') {
        this.isCuentaAjena = true;
        this.validateAdditionalLaborFields();
      } else if (this.modifyData.laborData.situacionLaboral === 'AUTONOMO') {
        this.isAutonomo = true;
        this.validateAdditionalLaborFields();
      } else {
        this.cleanAllLaborDetailsValues();
      }

      this.isDataLoaded = true;
      this.isLoggedUser = true;
  }


  /**
   * Añade los datos generales al objeto modifyData
   */
  private addGeneralData(){
    this.modifyData.name = this.helpers.cleanObjValues(this.personData.datosPersonales.nombreCliente);
    this.modifyData.firstName = this.helpers.cleanObjValues(this.personData.datosPersonales.apellido1Cliente);
    this.modifyData.secondName = this.helpers.cleanObjValues(this.personData.datosPersonales.apellido2Cliente);
    this.modifyData.documentNumber = this.helpers.cleanObjValues(this.personData.datosPersonales.idExterno);
    this.modifyData.expiryDate = new Date(this.helpers.cleanObjValues(this.personData.datosPersonales.fechaCaducidadDocumento));
    this.modifyData.email = this.helpers.cleanObjValues(this.personData.datosPersonales.email);
    this.modifyData.telephone = this.helpers.cleanObjValues(this.personData.datosPersonales.telefonoMovil);
    this.modifyData.residenceCountry = this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosPersonales.paisResidencia), PAISES);
  }

  /**
   * Añade los datos personales al objeto modifyData
   */
  private addPersonalData(){
    this.modifyData.personalData = Object.assign({
      civilStatus: this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosPersonales.codigoEstadoCivil), ESTADO_CIVIL),
      economicSystem: this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosPersonales.codigoRegimenEconomico), REGIMENES),
      obligacionesFiscales: (this.helpers.returnValue(this.personData.datosSocioEco.obligacionesFiscales) === 'no') ? false : true,
      });
  }

  /**
   * Añade los datos de contacto al objeto modifyData
   */
  private addContactData(){
    this.modifyData.postalContact = Object.assign({
      streetType: this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosDomicilio.codigoDomicilioTipoVia), VIAS),
      streetName: this.helpers.cleanObjValues(this.personData.datosDomicilio.nombreVia),
      streetNumber: this.helpers.cleanObjValues(this.personData.datosDomicilio.domicilioNumero),
      planta: this.helpers.cleanObjValues(this.personData.datosDomicilio.domicilioPlanta),
      puerta: this.helpers.cleanObjValues(this.personData.datosDomicilio.domicilioPuerta),
      portal: this.helpers.cleanObjValues(this.personData.datosDomicilio.domicilioPortal),
      bloque: this.helpers.cleanObjValues(this.personData.datosDomicilio.bloque),
      escalera: this.helpers.cleanObjValues(this.personData.datosDomicilio.escalera),
      fase: this.helpers.cleanObjValues(this.personData.datosDomicilio.fase),
      parcela: this.helpers.cleanObjValues(this.personData.datosDomicilio.parcela),
      postalCode: this.helpers.cleanObjValues(this.personData.datosDomicilio.codigoPostal),
      residenceProvince: this.helpers.cleanObjValues(this.personData.datosDomicilio.nombreProvinciaDomicilio),
      residenceLocality: this.helpers.cleanObjValues(this.personData.datosDomicilio.nombreLocalidad),
      situacionVivienda: this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosSocioEco.situacionVivienda), SITUACIONES)
    });
  }

  /**
   * Añade los datos personales al objeto modifyData
   */
  private addLaborData(){
    //DATOS LABORALES
    this.modifyData.laborData = Object.assign({
      situacionLaboral: this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosSocioEco.codigoSituacionLaboral), SITUACIONESLABORALES),
      tipoContrato: this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosSocioEco.codigoContrato), TIPOSCONTRATO),
      tipoActividad: this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosSocioEco.codigoCNO),TIPOACTIVIDADPROFESIONAL),
      tipoActividadEconomica: this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosSocioEco.codigoCNAE), CNAE),
      fechaAltaAutonomo: this.helpers.cleanObjValues(this.helpers.toDDMMYYYY(this.personData.datosSocioEco.fechaAltaAutonomo)),
      nombreEmpresa: this.helpers.cleanObjValues(this.personData.datosSocioEco.nombreEmpresa),
      profesion: this.getValuefromName(this.helpers.cleanObjValues(this.personData.datosSocioEco.profesion), PROFESIONES),
      antiguedadLaboral: this.helpers.cleanObjValues(this.helpers.toDDMMYYYY(this.personData.datosSocioEco.fechaAntiguedadLaboral)),
      ingresosMensuales: this.helpers.cleanObjValues(this.personData.datosSocioEco.ingresosMensuales),
      numeroPagas: this.helpers.cleanObjValues(this.personData.datosSocioEco.numeroPagas),
    })
  }

  /**
   * Genera el objeto que se enviará a modificar datos
   */
  private setEEIInicial(){
    this.EE_I_Inicial = Object.assign({
      codigoPostal: this.helpers.cleanObjValues(this.modifyData.postalContact.postalCode),
      codigoCNO: this.getValuefromName(this.helpers.cleanObjValues(this.modifyData.laborData.tipoActividad), TIPOACTIVIDADPROFESIONAL),
      codigoProvinciaDomicilio: this.getValuefromName(this.helpers.cleanObjValues(this.modifyData.postalContact.residenceProvince), PROVINCES),
      nombreProvinciaDomicilio: this.helpers.cleanObjValues(this.modifyData.postalContact.residenceProvince),
      nombreLocalidad: this.helpers.cleanObjValues(this.personData.datosDomicilio.nombreLocalidad),
      codigoDomicilioTipoVia: this.getValuefromName(this.helpers.cleanObjValues(this.modifyData.postalContact.streetType), VIAS),
      nombreVia: this.helpers.cleanObjValues(this.modifyData.postalContact.streetName),
      domicilioNumero: this.helpers.cleanObjValues(this.modifyData.postalContact.streetNumber),
      domicilioPortal: this.helpers.cleanObjValues(this.modifyData.postalContact.portal),
      domicilioEscalera: this.helpers.cleanObjValues(this.modifyData.postalContact.escalera),
      domicilioPlanta: this.helpers.cleanObjValues(this.personData.datosDomicilio.domicilioPlanta),
      domicilioPuerta: this.helpers.cleanObjValues(this.modifyData.postalContact.puerta),
      bloque: this.helpers.cleanObjValues(this.modifyData.postalContact.bloque),
      parcela: this.helpers.cleanObjValues(this.modifyData.postalContact.parcela),
      fase: this.helpers.cleanObjValues(this.modifyData.postalContact.fase),
      anioAdquisicion: this.helpers.padWith(this.helpers.cleanObjValues(this.personData.datosDomicilio.anioAdquisicion)),
      regimenOcupacion: this.getValuefromName(this.helpers.cleanObjValues(this.modifyData.postalContact.situacionVivienda), SITUACIONES),
      codigoSituacionLaboral: this.getValuefromName(this.helpers.cleanObjValues(this.modifyData.laborData.situacionLaboral), SITUACIONESLABORALES),
      codigoCNAE: this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosSocioEco.codigoCNAE), CNAE),
      fechaAltaAutonomo: this.helpers.cleanObjValues(this.helpers.toYYYYMMDD(this.modifyData.laborData.fechaAltaAutonomo)),
      fechaAntiguedadLaboral: this.helpers.cleanObjValues(this.helpers.toYYYYMMDD(this.modifyData.laborData.antiguedadLaboral)),
      nombreEmpresa: this.helpers.cleanObjValues(this.modifyData.laborData.nombreEmpresa),
      nivelSalarial: this.helpers.cleanObjValues(this.modifyData.laborData.ingresosMensuales),
      codigoContrato: this.getValuefromName(this.helpers.cleanObjValues(this.modifyData.laborData.tipoContrato), TIPOSCONTRATO),
      duracion: "",
      numeroPagas: this.helpers.cleanObjValues(this.personData.datosSocioEco.numeroPagas),
      fechaInicioAlquiler: "",
      cuotaAlquiler: this.helpers.cleanObjValues(this.personData.datosDomicilio.cuotaAlquilerMensual),
      codigoRegimenEconomico: this.getValuefromName(this.helpers.cleanObjValues(this.modifyData.personalData.economicSystem), REGIMENES),
      codigoEstadoCivil: this.getValuefromName(this.helpers.cleanObjValues(this.modifyData.personalData.civilStatus), ESTADO_CIVIL),
      profesion:  this.getValuefromName(this.helpers.cleanObjValues(this.modifyData.laborData.profesion), PROFESIONES)
    });
  }

  /**
   * Recoge de session los datos de las variables de control de flujo y de los datos de usuario
   */
  private checkSessionStorage(){
    //Recogemos de sesion si ha cerrado el banner de todo OK.
    this.hideBannerNotificacionOk = JSON.parse(sessionStorage.getItem("notificacionOk"));
    this.isCampanyaSubidaDoc = JSON.parse(sessionStorage.getItem("subidaDocumentacion")) == true;
    this.hasSubidaDocCorrecta = JSON.parse(sessionStorage.getItem("updateDniCorrect"));
    console.log('DNI OK' + this.hasSubidaDocCorrecta);

    this.operacionAmpliacion = sessionStorage.getItem('operacion-ampliacion');

    this.idProducto = sessionStorage.getItem("productoContratando")?sessionStorage.getItem("productoContratando"):'';

    this.tipoSolicitud = sessionStorage.getItem('tipoSolicitud');

    this.sessionUserData = JSON.parse(sessionStorage.getItem('userData'));

    this.backFromSubidaDoc = sessionStorage.getItem('vueltaSubDoc') === 'true';
  }

  private setVisibleInfo() {

    const operacionesConfimaAmpliacion = ['ampliacion', 'ampliacion-firma', 'modificar'];
    let showConfirmationPage = operacionesConfimaAmpliacion.indexOf(this.operacionAmpliacion) !== -1;

    if (showConfirmationPage) {
      this.btnConfirmar = true;
      this.titlePage = 'CONFIRMA TUS DATOS';
      this.showObligaciones = true;
      this.showInfo = false;
      this.isContratacion = true;
      this.triggerExtendLimitAnalytics();
    }
    else {
      this.showObligaciones = false;
      this.btnConfirmar = false;
      this.showInfo = true;
      this.isContratacion = false;
    }
  }

  /**
   * Lanza Analytics de Ampliación de Límite
   */
  private triggerExtendLimitAnalytics() {

    let limiteSolicitado = parseInt(sessionStorage.getItem("limite-solicitado"));

    if (this.operacionAmpliacion === "ampliacion") {
      this.analytics.triggerCreditLimitDataNavData({
        pageName: "Modificación Límite/Paso 02.1-Revisión de datos",
        creditLimit: "Menor de 3K€"
      });
    } else if (this.operacionAmpliacion === "ampliacion-firma") {
      this.analytics.triggerCreditLimitDataNavData({
        pageName: "Modificación Límite/Paso 02.1-Revisión de datos",
        creditLimit: "Mayor de 3K€"
      });
    } else if (limiteSolicitado < 3000) {
      this.analytics.triggerCreditLimitDataNavData({
        pageName: "Modificación Límite/Paso 02.1-Revisión de datos",
        creditLimit: "Menor de 3K€"
      });
    } else if (limiteSolicitado >= 3000) {
      this.analytics.triggerCreditLimitDataNavData({
        pageName: "Modificación Límite/Paso 02.1-Revisión de datos",
        creditLimit: "Mayor de 3K€"
      });
    }
  }

  /**
   * Recupera los datos de usuario logeado y valida si tiene campos pendientes
   */
  private checkValidUserData() {
    this.loading = true;
    this.clientID = this.sessionUserData.persona.id_cliente_EVO;
    this.idProducto = this.sessionUserData.listaContratados[0].idProducto;
    this.personaSrv.getPersonaById(this.clientID, this.idProducto).subscribe(data => {
      this.loading = false;
      this.loginSrv.userInfo = data;
      this.loginSrv.comprobacionDatos();
      console.log(this.loginSrv.camposError);
      this.camposErroneos = this.loginSrv.camposError;
      this.inicializarDatosForm(data);
        setTimeout(() => {
          if(this.tipoSolicitud !=='04') {
            this.updateWarnigsAndRedirect();
          }else{
            this.loading = false;
            this.showCurtain = false;
          }
        }, 100);
    }, _error => {
      if(this.tipoSolicitud !=='04') {
        this.errService.navToError();
      }
    });
  }

  initForm() {
    this.checkIfPostalCode();
    this.checkTipoActividad();
    this.createForm();
    console.log(this.modifyDataForm);
  }

  checkTipoActividad() {
    this.noSelectedProfession = true;
    this.tipoActividadProfesional = [];
    this.tipoActividadProfesional.push({ name: 'Tipo de actividad profesional', value: null, profesion: null });
    const profesion = this.getValuefromName(this.helpers.cleanObjValues(this.personData.datosSocioEco.profesion), PROFESIONES);
    //const tipoActividad = this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosSocioEco.codigoCNO), TIPOACTIVIDADPROFESIONAL);
    if (profesion !== '' && profesion !== null){
      this.noSelectedProfession = false;
      this.tipoActividadProfesional = this.tipoActividadProfesional.concat(TIPOACTIVIDADPROFESIONAL);
      this.tipoActividadProfesional = this.tipoActividadProfesional.filter(item => {
        return profesion === item.profesion;
      });
      this.tipoActividadProfesional.unshift({ name: 'Tipo de actividad profesional', value: null, profesion: null });
    }
  }

  checkIfPostalCode() {
    const provincia = this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.codigoProvinciaDomicilio') || '';
    const localidad = this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.nombreLocalidad') || '';
    const codigoPostal = this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.codigoPostal') || '';
    if (provincia && localidad && codigoPostal) {
      this.ACTUALIZE = true;
      this.loading = true;
      this.getCodPostal(codigoPostal).subscribe(data => {
        this.loading = false;
        this.setLocalidades(data.EE_O_Consulta);
      }, error => {
        this.goToError();
      });
    }
  }

  /**
   * Activa la edicion de datos
   */
  doEditable() {
      if (!this.editable){
        this.editable = true;
        this.triggerEditDataAnalytics();
      }
      setTimeout(() => {
        if(!this.modifyDataForm){
          this.getPersona()
        } else {
          this.validarCampos(true);
        }
      }, 100);
  }

  /**
   * Valida los campos de formulario y marca los errores
   */
  validarCampos(editable) {

    if (this.camposErroneos && this.camposErroneos.length > 0) {
      this.ponerCamposRojos(this.camposErroneos);
    }
    else {
      this.loginSrv.comprobacionDatos();
      if (this.loginSrv.camposError.length > 0) {
        this.ponerCamposRojos(this.camposErroneos);
      }
      else if (editable){
        if (!this.editable){
          this.editable = editable;
          this.triggerEditDataAnalytics();
        }
      }
    }

    this.loading = false;
  }

  /**
   * Marca el rojo aquellos campos del formulario con errores.
   */
  ponerCamposRojos(campos) {
    for (let i = 0; campos && i < campos.length; i++) {
      let nombre = this.getValuefromName(campos[i], CAMPOSCONSOLA);
      if (nombre) {
        this.modifyDataForm.get(nombre).setErrors({ valid: false, required: true, touched: true });
        this.helpers.updateFieldStatus(this.modifyDataForm, nombre);
      }
    }
    this.loading = false;
  }


  get filterTipoActividad() {
    const selection = this.modifyDataForm.get('profesion').value;
    let tipoActividadProfesional = [];
    tipoActividadProfesional.push({ name: 'Tipo de actividad profesional', value: null, profesion: null });
    if (selection === '' && selection === null) return tipoActividadProfesional;
    tipoActividadProfesional = tipoActividadProfesional.concat(TIPOACTIVIDADPROFESIONAL);
    tipoActividadProfesional = tipoActividadProfesional.filter(item => {
      return selection === item.profesion
    });
    const filteredActividadProfesional = tipoActividadProfesional.unshift({ name: 'Tipo de actividad profesional', value: null, profesion: null });
    return tipoActividadProfesional
  }

  get isHiddenAditional() {
    return this.hidden = !this.hidden;
  }

  initModal() {
    this.modal = Object.assign({
      title : '¡Vaya!',
      withImage : false,
      message : `Tenemos un problema con tu solicitud online.
      <br>
      Llámanos al <a href='tel:910900900'>910 900 900</a> para que podamos ayudarte a continuar.`
    })
  }

  createForm() {
    this.modifyDataForm = this.fb.group({
      obligacionesFiscales: ['no', [Validators.required]],
      estadoCivil: [this.getNamefromValue(this.personData.datosPersonales.codigoEstadoCivil, ESTADO_CIVIL) || '', [Validators.required]],

      regEconomico: [this.getNamefromValue(this.jsonService.getKeyFromJSON(this.personData, 'datosPersonales.codigoRegimenEconomico'), REGIMENES) || '', []],

      tipoVia: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.codigoDomicilioTipoVia') || '',
                [Validators.pattern(/^[À-ÿA-z\d\-_\s.\'·]+$/i)]],

      direccion: [this.jsonService.getKeyFromJSON(this.personData,'datosDomicilio.nombreVia') || '', []],

      numero: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.domicilioNumero') || '',
                [Validators.maxLength(3), Validators.pattern(this.helpers.alphanumericRexp)]],

      planta: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.domicilioPlanta') || '',
                [Validators.maxLength(2), Validators.pattern(this.helpers.alphanumericRexp)]],

      puerta: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.domicilioPuerta') || '',
                [Validators.maxLength(2), Validators.pattern(this.helpers.alphanumericRexp)]],

      portal: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.domicilioPortal') || '',
                [Validators.maxLength(2), Validators.pattern(this.helpers.alphanumericRexp)]],

      bloque: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.bloque') || '',
                [Validators.maxLength(2), Validators.pattern(this.helpers.alphanumericRexp)]],

      escalera: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.domicilioEscalera') || '',
                [Validators.maxLength(2), Validators.pattern(this.helpers.alphanumericRexp)]],

      parcela: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.parcela') || '',
                [Validators.maxLength(45), Validators.pattern(/^[A-zÀ-ÿ\d\-_\s.\'·]+$/i)]],

      fase: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.fase') || '',
                [Validators.maxLength(3), Validators.pattern(this.helpers.alphanumericRexp)]],

      codPostal: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.codigoPostal') || '',
                [Validators.minLength(5), Validators.maxLength(5), Validators.pattern("^[0-9]+$")]],

      residenceProvince: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.codigoProvinciaDomicilio') || '', []],
      residenceLocality: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.nombreLocalidad') || '', []],
      provincia: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.codigoProvinciaDomicilio') || '', [Validators.maxLength(3)]],
      residenceCountry: [this.jsonService.getKeyFromJSON(this.personData, 'datosPersonales.paisResidencia') || '', []],
      situacionVivienda: [this.getNamefromValue(this.personData.datosSocioEco.situacionVivienda, SITUACIONES) || '', []],
      adquisicion: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.anioAdquisicion') || '', [Validators.minLength(4), Validators.maxLength(4)]],
      alquilerVivienda: [this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.cuotaAlquilerMensual') || '', []],
      situacionLaboral: [this.jsonService.getKeyFromJSON(this.personData, 'datosSocioEco.codigoSituacionLaboral') || '', []],
      fechaAltaAutonomo: [this.helpers.toDDMMYYYY(this.jsonService.getKeyFromJSON(this.personData, 'datosSocioEco.fechaAltaAutonomo')) || '', []],
      cnae: [this.jsonService.getKeyFromJSON(this.personData, 'datosSocioEco.codigoCNAE') || '', []],
      tipoContrato: [this.getNamefromValue(this.personData.datosSocioEco.codigoContrato, TIPOSCONTRATO) || '', []],
      numeroPagas: [this.jsonService.getKeyFromJSON(this.personData, 'datosSocioEco.numeroPagas') || '', [Validators.maxLength(2)]],
      antiguedadLaboral: [this.helpers.toDDMMYYYY(this.jsonService.getKeyFromJSON(this.personData, 'datosSocioEco.fechaAntiguedadLaboral')) || '', []],
      profesion: [this.jsonService.getKeyFromJSON(this.personData, 'datosSocioEco.profesion') || '', []],
      nombreEmpresa: [this.jsonService.getKeyFromJSON(this.personData, 'datosSocioEco.nombreEmpresa') || '', [Validators.maxLength(45)]],
      ingresosMensuales: [this.jsonService.getKeyFromJSON(this.personData, 'datosSocioEco.ingresosMensuales') || '', []],
      tipoActividad: [this.getNamefromValue(this.personData.datosSocioEco.codigoCNO, TIPOACTIVIDADPROFESIONAL) || '', []],

    });
        //this.checkIfPostalCode();
        this.formSubscribe();
    }

    formSubscribe() {
    /*
    this.modifyDataForm.valueChanges.subscribe(form => {


    this.authService.saveUpdatedForm(this.modifyDataForm, this, form);
    this.errService.mobileErrors(this.modifyDataForm);
    });
    */
    this.modifyDataForm.get("obligacionesFiscales").valueChanges.subscribe(documento => {
      // HACER UN PUT A AMPLIACION O A CUENTA INVERSORA PARA PARAR LA CONTRATACION
      if (documento === 'si') {

        this.showErrorObligacionesFiscales = true;

        if(sessionStorage.getItem('operacion-ampliacion') === "ampliacion") {
          this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/KO Obligaciones tributarias', 'creditLimit': 'Menor de 3K€'});
        }
        else if(sessionStorage.getItem('operacion-ampliacion') === "ampliacion-firma") {
          this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/KO Obligaciones tributarias', 'creditLimit': 'Mayor de 3K€'});
        }

        this.loading = false;
        // this.modal = Object.assign({
        //   title: '¡Vaya!',
        // //  withImage: true,
        //   blocking: true,
        //   link: this.link,
        //   message: this.exceptionMsg
        // })

        // this.modService.open(this.modal.name);
        window.scrollTo(0, 0);
        this.btnModificar = true;

      }
      else {
        this.showErrorObligacionesFiscales = false;
        this.btnModificar = false;
      }
    });
    this.modifyDataForm.get('estadoCivil').valueChanges.subscribe(eCivil => {
      if (eCivil === 'Casado/a') {
        this.isMarried = true;
      }
      else {
        this.isMarried = false;
      }

      if (!this.isMarried) {
        this.helpers.updateFieldValue(this.modifyDataForm, 'regEconomico');
        this.helpers.updateFieldValidation(this.modifyDataForm, 'regEconomico');
      } else {
        this.helpers.updateFieldValidation(this.modifyDataForm, 'regEconomico', [Validators.required]);
      }
      this.helpers.resetFieldsState(this.modifyDataForm, ['regEconomico']);
    });

    //CAMBIAR PAIS POR LOCALIDAD
    this.modifyDataForm.get("residenceCountry").valueChanges.subscribe(pais => {
      if (pais !== '011') {
        this.modifyDataForm.get('residenceCountry').setErrors({ valid: false });

        this.modal = Object.assign({
          title: '¡Vaya!',
          message: this.exceptionMsg,
          withImage: true,
          blocking: false
        })
        this.modService.open(this.modal.name);
      }
    });

    this.modifyDataForm.get('situacionVivienda').valueChanges.subscribe(sVivienda => {
      const codVivienda = this.getValuefromName(sVivienda, SITUACIONES);
      if (codVivienda === '1' || codVivienda === '4') {
        this.isMortgage = true;
        this.isRental = false;
        this.fechaInitAlquiler = "";
      }
      else if (codVivienda === '2'){
        this.isMortgage = false;
        this.isRental = true;
        // añadir año de alquier fecha actual
        var fechaActual = new Date();
        this.dia = fechaActual.getDate();
        this.mes = fechaActual.getMonth()+1; //January is 0!
        this.anyo = fechaActual.getFullYear();

        if(this.dia < 10) {
          this.dia = '0'+this.dia;
        }

        if(this.mes < 10) {
          this.mes = '0'+this.mes;
        }

        var fechaAlquiler = this.dia + '/' + this.mes + '/' + this.anyo;
        this.fechaInitAlquiler = fechaAlquiler;
      }
      else {
        this.isMortgage = false;
        this.isRental = false;
        this.fechaInitAlquiler = "";
      }

      this.updateRentalValidation();

    });

    this.modifyDataForm.get('situacionLaboral').valueChanges.subscribe(situacionLaboral => {
      if (situacionLaboral === '01') {
        this.isCuentaAjena = true;
        this.isAutonomo = false;
        this.validateAdditionalLaborFields();
      }
      else if (situacionLaboral === '02'){
        this.isCuentaAjena = false;
        this.isAutonomo = true;
        this.validateAdditionalLaborFields();
      }
      else {
        this.isCuentaAjena = false;
        this.isAutonomo = false;
        this.noLaborValidators();
      }

      if (!this.isMarried) {
        this.helpers.updateFieldValue(this.modifyDataForm, 'regEconomico');
        this.helpers.updateFieldValidation(this.modifyDataForm, 'regEconomico');
      } else {
        this.helpers.updateFieldValidation(this.modifyDataForm, 'regEconomico', [Validators.required]);
      }
      this.helpers.resetFieldsState(this.modifyDataForm, ['regEconomico']);
    });

    this.modifyDataForm.get('profesion').valueChanges.subscribe(profesion => {

      if (!!profesion && profesion !== this.currentProfesion){
        this.currentProfesion = profesion;
        this.helpers.updateFieldValue(this.modifyDataForm, 'tipoActividad');
      } else if (!profesion && this.currentProfesion){
        setTimeout(() => {this.helpers.updateFieldValue(this.modifyDataForm, 'profesion',this.currentProfesion);}, 100);
      }

      this.tipoActividadProfesional = this.filterTipoActividad;
      const tipoActividad = this.getNamefromValue(this.helpers.cleanObjValues(this.personData.datosSocioEco.codigoCNO), TIPOACTIVIDADPROFESIONAL);
      if (tipoActividad !== '' && tipoActividad !== null){
        setTimeout(() => {this.helpers.updateFieldValue(this.modifyDataForm, 'tipoActividad',tipoActividad);}, 100);
      }

    })

    if (this.modifyDataForm.get('profesion').value) {
      this.currentProfesion = this.modifyDataForm.get('profesion').value;
      this.tipoActividadProfesional = this.filterTipoActividad;
      setTimeout(() => {
        this.helpers.updateFieldValue(
          this.modifyDataForm,
          'tipoActividad',
          this.initCNO(this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.codigoCNO'))), 0
      }
      );
    }


    //Localidades

    this.modifyDataForm.get('codPostal').valueChanges.subscribe(cp => {
      if (cp.length === 5 && this.modifyDataForm.get('codPostal').valid) {
        this.loading = true;
        this.getCodPostal(cp).subscribe(data => {
          this.loading = false;
          this.setLocalidades(data.EE_O_Consulta);
        }, error => {
          this.goToError();
        });
      } else {
        this.selectLocalidades = [this.localidadLiteral]
        setTimeout(() => this.helpers.updateFieldValue(this.modifyDataForm, 'residenceLocality', this.localidadLiteral.value), 0);
      }
    })

    this.modifyDataForm.get('residenceLocality').valueChanges.subscribe(localidad => {
      const selectLocalidad = this.searchSelectCode(this.selectLocalidades);
      if (selectLocalidad) {
        setTimeout(() => this.helpers.updateFieldValue(this.modifyDataForm, 'residenceProvince', selectLocalidad.codigoProvincia), 0);
        //this.helpers.updateFieldValue(this.modifyDataForm, 'residenceProvince', selectLocalidad.nombreProvincia);
      }
    });

  }

  getCodPostal(cp) {
    const codigoPostal = { EE_I_Consulta: { codigoEntidad: "0239", codigoPostal: cp } };
    return this.localidadesSrv.getLocalidades(codigoPostal);
  }

  setLocalidades(data) {

    if (this._hasDataError(data)) return;
    if (this.ACTUALIZE) {
      this.ACTUALIZE = false;
      this.localidad = this.jsonService.getKeyFromJSON(this.personData, 'datosDomicilio.nombreLocalidad')
    }
    this.selectLocalidades = this.formatList(data.ListaLocalidades);
    let singleLocalidad = this.selectLocalidades.filter((filteredItem) => {
      if (filteredItem.name && this.localidad) {
        return filteredItem.name.toUpperCase() === this.localidad.toUpperCase();
      } else {
        return false
      }
    })
    if (this.selectLocalidades.length === 1) {
      singleLocalidad = this.selectLocalidades;
    }
    if (!this.helpers.isEmpty(singleLocalidad)) {
      setTimeout(() => this.helpers.updateFieldValue(this.modifyDataForm, 'residenceLocality', singleLocalidad[singleLocalidad.length - 1]['value']), 0);
      const selectLocalidad = this.searchSelectCode(this.selectLocalidades);
      if (selectLocalidad) {
        this.helpers.updateFieldValue(this.modifyDataForm, 'residenceProvince', selectLocalidad.nombreProvincia)
      }
    } else {
      const localidadLiteral = { name: 'Localidad', value: null };
      this.selectLocalidades.unshift(localidadLiteral);
      setTimeout(() => this.helpers.updateFieldValue(this.modifyDataForm, 'residenceLocality', this.selectLocalidades[0].value), 0);
    }
  }

  private _hasDataError(data): boolean {
    if (data.Errores) {
      if (!this.noLocalidadCounter) {
        this.helpers.updateFieldValue(this.modifyDataForm, 'codPostal');
        this.noLocalidadCounter = true;
        return true;
      } else {
        this.helpers.updateFieldValue(this.modifyDataForm, 'codPostal');
        //this.ponerCamposRojos(['codPostal']);
        return true;
      }
    }
    return false;
  }


  searchSelectCode(values) {
    let name;
    values.forEach((item) => {
      if (item['selected']) {
        name = item;
      }
    })
    if (name === undefined && values.length === 1) {
      name = values[0];
    }
    return name;
  }

  formatList(list) {
    let isList = [];
    if (this.helpers.isEmpty(list)) {
      this.modal = Object.assign({
        title: '¡Ups!',
        message: 'No existen localidades con ese código postal',
        withImage: false,
        blocking: false
      })
      this.link="";
      this.modService.open(this.modal.name, this.modal.blocking);
    }

    if (Array.isArray(list)) {
      isList = list;
    } else if (this.isObject(list)) {
      isList.push(list);
    }
    // TODO tratar la lista;
    return isList.map((item) => {
      return {
        name: item.nombreLocalidad,
        value: item.nombreLocalidad,
        codigoProvincia: item.codigoProvincia,
        nombreProvincia: item.nombreProvincia
      }
    });
  }

  isObject(obj) {
    return obj === Object(obj);
  }

  isNotResident() {
    this.persona = Object.assign({
      datosDomicilio: {
        codigoPaisResidencia: '999'
      }
    })
    this.authService.setSessionProspect(this.persona);
    this.loading = true;
    this.authService.updateProspect(this.persona).subscribe(data => {
      this.loading = false;
    }, error => {
      this.goToError()
    })
  }

  updateRentalValidation() {
    const rentalValue = this.modifyDataForm.get("situacionVivienda").value,
      alquilerFields = ['alquilerVivienda'],
      mortgageFields = ['adquisicion'];

    this.cleanRentalFields();

    switch (rentalValue) {
      case '2':
        this.helpers.updateFieldValidation(this.modifyDataForm, 'alquilerVivienda', [
          Validators.required,
          this.evoValidator.validateRent,
          Validators.pattern("[0-9]*")
        ]);
        this.helpers.resetFieldsState(this.modifyDataForm, mortgageFields);
        break;
      case '1':
      case '4':
        this.helpers.updateFieldValidation(this.modifyDataForm, 'adquisicion', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern("[0-9]+"),
          this.evoValidator.validateMortgage
        ]);
        this.helpers.resetFieldsState(this.modifyDataForm, alquilerFields);
        break;
    }
  }

  cleanRentalFields() {
    this.helpers.resetFieldsState(this.modifyDataForm, ['alquilerVivienda', 'adquisicion']);

    this.helpers.updateFieldValue(this.modifyDataForm, 'alquilerVivienda');
    this.helpers.updateFieldValue(this.modifyDataForm, 'adquisicion');
    this.helpers.updateFieldValidation(this.modifyDataForm, 'alquilerVivienda');
    this.helpers.updateFieldValidation(this.modifyDataForm, 'adquisicion');
  }

  editForm () {
    this.showEdit = !this.showEdit;
    if (this.showEdit){
      this.triggerEditDataAnalytics();
    }
  }
  recoveryData(clientID) {
    this.loading = true;
    this.recoveryService.recoverSignUser(clientID).subscribe(data => {
      this.loading = false;
    }, error => {
      this.errService.navToError();
    });
  }

  setPersona() {

  }

  updateSurnames() {
    const selectedOption = this.surnameOptions.get('surnameOption').value

    if (!selectedOption) return

    this.modService.close(this.modalSurname);
    const splitSurnames = selectedOption.split('/');
    if (splitSurnames.length > 1) {
      this.confirmPersonal.firstName = splitSurnames[0];
      this.confirmPersonal.secondName = splitSurnames[1];
    } else {
      this.confirmPersonal.firstName = splitSurnames[0];
      this.confirmPersonal.secondName = '';
    }
    if (!this.confirmPersonal.secondName) this.confirmPersonal.secondName = '';

  }

  setFields(isOk) {
    const canal = this.deviceService.getDevice();
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      producto: "",
      estado: 'mi-perfil',
      datosPersonales: {
        oficinaCliente: environment.oficina,
        datosCorrectos: isOk,
        telefonoMovil: this.modifyData.telephone,
        nombreCliente: this.modifyData.name,
        apellido1Cliente: this.modifyData.firstName,
        apellido2Cliente: this.modifyData.secondName,
        fechaNacimiento: this.formatDate(this.modifyData.birthDate),
        fechaCaducidadDocumento: this.formatDate(this.modifyData.expiryDate),
        paisNacionalidad: this.searchCodeFromValue(PAISES, this.modifyData.nationality), //TODO tener en cuenta por que habria que poner un buscador por nacionalidad
        id_cliente_EVO: this.userData.persona.id_cliente_EVO,

      }
    });

  }

  getNamefromValue(value:string, collection) {
    if (value === undefined || value ==="Desconocido" || value ==="DESCONOCIDO") return '';
    if (value === '') return value;

    let name = collection.filter(obj => obj.value === value);
    /*
    let name = collection.filter(obj => {
      if(obj.value === value){
        return obj.name;
      }
    });
*/
    if (name.length === 0) {
      return "";
    }
    else {
      if (name[0].name === ""){
        return "";
      }
      else {
        return name[0].name;
      }
    }
    /*
    collection.filter(obj => {
      if (obj.value === value){
        let name = obj.name;
      }
      else {
        let name = "DESCONOCIDO"
      }
    })
    return name;
    */
    /*
    return collection.filter(obj => {
      return obj.value === value
    })[0]['name']
    */
  }

  getValue(val) {
    if (val === undefined || val === null) return '';
    return val;
  }

  getValuefromName(val, collection) {
    if (val === undefined || val ==="Desconocido" || val ==="DESCONOCIDO") return '';
    if (val === '') return val;

    let valor = collection.filter(obj => obj.name === val);
    /*
    let valor = collection.filter(obj => {
      return obj.name === val
    })[0]['value'];
 */
    if (valor.length === 0) {
      return "";
    }
    else {
      if (valor[0].value === ""){
        return "";
      }
      else {
        return valor[0].value;
      }
    }
    /*
    return collection.filter(obj => {
      return obj.name === val
    })[0]['value']
    */
  }

  searchValueFromCode(values, value) {
    let name = '';
    values.forEach((item) => {
      if (item['value'] &&
        value &&
        item['value'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    if (name)
      return name['code'];
    return name;
  }

  searchCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item['code'] &&
        value &&
        item['code'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    if (name)
      return name.value;
    return name;
  }

  searchValueFromName(values, value) {
    let name;
    values.forEach((item) => {
      if (item['name'] &&
        value &&
        item['name'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    if (name)
      return name.value;
    return name;
  }

  searchField(fields, prop) {
    const field = fields.filter(item => {
      if (!item.name) return false
      return item.name.toUpperCase() === prop.toUpperCase();
    });
    if (field && field.length === 1) {
      return field[0];
    } else {
      return field;
    }
  }

  formatDate(date: Date) {
    if (!date) return undefined;
    date = new Date(Number(date));
    const day = date.getDate().toString().length === 1 ? '0' + date.getDate().toString() : date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    month = month.toString().length === 1 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
    return `${day}/${month}/${date.getFullYear()}`;
  }

  get isExpired() {
    if (this.errService.hasExpiredDocument) {
      return true;
    }
    const expire = new Date(Number(this.confirmPersonal.expiryDate)),
      today = new Date();

    if (expire.getTime() < today.getTime()) {
      return true;
    }
    return false;
  }

  get isAdult() {
    if (this.errService.isMinor) {
      return false;
    }
    const birthDate = Number(this.confirmPersonal.birthDate);
    return this.helpers.isAdult(birthDate);
  }

  isSameDocument() {
    return this.authService.getSessionProspect().datosPersonales.idExterno === this.confirmPersonal.personalNumber;
  }



  checkFieldDistinctLength(field1, field2) {
    let countDistinct = 0;
    field1 = field1.split('');
    for (let i = 0; i < field2.length; i++) {
      const character = field2.charAt(i);
      const indexFound = field1.findIndex(item => {
        return item == character;
      });

      if (indexFound != -1) {
        field1.splice(indexFound, 1);
      } else {
        countDistinct++;
      }
    }

    return countDistinct;
  }

  areFieldDiff2Characters(field, fieldInit) {
    field = field.toUpperCase();
    let countDistinct = 0;
    countDistinct = this.checkFieldDistinctLength(field, fieldInit);

    return countDistinct <= 2;
  }

  change(event) {
    let name = event.target.name;


    switch (name) {

      case 'nombre':

        console.log(this.nameInit)
        if (event.target.value != this.nameInit) {
          event.target.classList.add("editRosa");
        } else {
          event.target.classList.remove("editRosa");
        }
        break;

      case 'primerApellido':
        if (event.target.value != this.firstNameInit) {
          event.target.classList.add("editRosa");
        } else {
          event.target.classList.remove("editRosa");
        }
        break;

      case 'segundoApellido':
        if (event.target.value != this.secondNameInit) {
          event.target.classList.add("editRosa");
        } else {
          event.target.classList.remove("editRosa");
        }
        break;

    }
  }

  onSubmit() {
    sessionStorage.setItem("modDatos", "true");

    if (!this.hasSubidaDocCorrecta && (this.tipoSolicitud === "01" || this.tipoSolicitud === "02" || this.tipoSolicitud === "03")){
      sessionStorage.setItem("updateData", "modDatos");
    }

    if(this.modifyDataForm.controls.obligacionesFiscales.value === 'si'){
      this.hasInvalidFiscalObligations();
      this.showError = true;
      this.nameError = 'imprevistoRiesgos';
    }else{
      this.loading = true;
      if (this.editable === true) {
        this.validarFormulario();
      }
      else {
        if(sessionStorage.getItem("updateData") === "modDatos"){
          //Tiene subida de documentación
          this.helpers.navigateTo("subir-documentacion");
        }else{
          // si da al boton confirmar, hacemos el put y pasamos a subida de documentacion
          if (sessionStorage.getItem('operacion-ampliacion')) {
            this.ampliacionService.putLimite("aportar-documentacion", "aportar-documentacion");
          }
        }
      }

    }

  }

  goToNickname(idCaso){
    let obj: Object = {
      "estado": 'nickname',
      "idCaso": idCaso,
    };
    this.loading = true;
    this.personaSrv.modificarCuenta(obj).subscribe(data =>{
      this.loading = false;
      if(data.codigo === 'OK'){
        if(sessionStorage.getItem('operacion-inversora') === 'operacion-inversora'){
          //Se añade para que cuando vuelva a entrar al componente de config-investment
          //no vuelve a hacer las comprobaciones de los warnings, crear el caso etc
          //sino que entre directamente al componente para pedir el nickname, en el caso de
          //fondo inteligente es igual.
          sessionStorage.setItem('operacion-inversora', 'operacion-inversora-nickname');
          this.helpers.navigateTo('configuracion-cuenta-inversora');
        }else if(sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente'){
          sessionStorage.setItem('operacion-inversora', 'fondo-inteligente-nickname');
          this.helpers.navigateTo('configuracion-cuenta-inversora');
        }

      }else{
        //console.log("Error en modificarCuenta myprofile");
        this.errService.modDataError = true;
        this.errService.navToErrorModData('SATELITE-C', 'REINTENTAR', 'mi-perfil');
      }
    });
  }

  validarFormulario() {

    if (this.isAutonomo) {
      let altaAutonomo = this.modifyDataForm.controls.fechaAltaAutonomo.value;
      if (altaAutonomo === "" || altaAutonomo === undefined) {
        this.helpers.updateFieldValue(this.modifyDataForm,'fechaAltaAutonomo',this.helpers.toDDMMYYYY(this.EE_I_Inicial.fechaAltaAutonomo));
        altaAutonomo = this.modifyDataForm.controls.fechaAltaAutonomo.value;
      }
    }
    //Se comprueba que regimen economico esté informado
    if(this.modifyDataForm.controls.regEconomico.value === "Régimen Económico"){
      this.helpers.updateFieldValue(this.modifyDataForm,'regEconomico',"");
    }

    let tipoActividadInvalida = false;
    if (this.isCuentaAjena === true) {
        document.getElementById('antiguedadLaboral').classList.remove('ng-invalid');
        document.getElementById('antiguedadLaboral').classList.add('ng-valid');

        //VALIDAMOS SI LA FECHA DE ANTIGUEDAD ES VALIDA.
        let nacimiento =this.helpers.cleanObjValues(this.personData.datosPersonales.fechaNacimiento);
        let dateNacimiento = new Date(nacimiento);
        let dateAntiguedadLaboral = this.modifyDataForm.controls.antiguedadLaboral.value;
        if (dateAntiguedadLaboral === "" || dateAntiguedadLaboral === undefined) {
          dateAntiguedadLaboral = this.helpers.toDDMMYYYY(this.EE_I_Inicial.fechaAntiguedadLaboral);
        }
        this.validateDateAntLab(dateAntiguedadLaboral, dateNacimiento);

        if(this.modifyDataForm.get("tipoActividad").value === "DESCONOCIDO") {
          tipoActividadInvalida = true;
        }
      }

    const form = this.modifyDataForm;
    if (!form.valid || this.fechaInvalida || tipoActividadInvalida) { // ver si todos los campos del formulario son validos
      const invalidFields = [];
      const formFields = form.controls;
      for (var camp in formFields) {
        if(formFields[camp].valid === false){
          invalidFields.push(camp);
        }
        if(camp==="antiguedadLaboral" && this.fechaInvalida){
          document.getElementById('antiguedadLaboral').classList.remove('ng-valid');
          document.getElementById('antiguedadLaboral').classList.add('ng-invalid');
          invalidFields.push(camp);
        }
        // if(camp==="fechaAltaAutonomo" && this.fechaAltaAutonomoInvalida){
        //   document.getElementById('autonomoFecha').classList.remove('ng-valid');
        //   document.getElementById('autonomoFecha').classList.add('ng-invalid');
        //   invalidFields.push(camp);
        // }
      }
      this.loading=false;
      this.helpers.updateFormStatus(form);
    }else {
      this.editable = false;
      this.sendModifyData();
    }

  }

  private validateDateAntLab(dateAntiguedadLaboral: any, dateNacimiento: Date) {
      this.dateAntiguedad = this.helpers.stringToDate(dateAntiguedadLaboral);
      //sumamos 14 años a la fecha de nacimiento
      let anioMax = dateNacimiento.getFullYear() + 14;
      let fechaMax = new Date();
      fechaMax.setDate(dateNacimiento.getDate());
      fechaMax.setMonth(dateNacimiento.getMonth());
      fechaMax.setFullYear(anioMax);
      if (fechaMax <= this.dateAntiguedad) {
        this.fechaInvalida = false;
      }
      else {
        this.fechaInvalida = true;
      }
  }

  sendModifyData() {
    const form = this.modifyDataForm;


    const estadoCivil = this.getValuefromName(this.modifyDataForm.get('estadoCivil').value, ESTADO_CIVIL);
    const regEconomico = this.getValuefromName(this.modifyDataForm.get("regEconomico").value, REGIMENES);
    const tipovia = this.modifyDataForm.get("tipoVia").value;
    const dir = this.modifyDataForm.get("direccion").value;
    const numero = this.modifyDataForm.get("numero").value;
    const planta = this.modifyDataForm.get("planta").value;
    const puerta = this.modifyDataForm.get("puerta").value;
    const portal = this.modifyDataForm.get("portal").value;
    const bloque = this.modifyDataForm.get("bloque").value;
    const escalera = this.modifyDataForm.get("escalera").value;
    const parcela = this.modifyDataForm.get("parcela").value;
    const fase = this.modifyDataForm.get("fase").value;
    const codPostal = this.modifyDataForm.get("codPostal").value;
    const codigoProvinciaDomicilio =  codPostal ? codPostal.substring(0,2) :
                                      this.getNamefromValue(this.modifyDataForm.get("residenceProvince").value.toString(), PROVINCES);
    const residenceProvince = this.modifyDataForm.get("provincia").value;
    const residenceProvinceName = this.getNamefromValue(this.modifyDataForm.get("residenceProvince").value.toString(), PROVINCES);
    //const provincia = this.modifyDataForm.get("provincia").value;
    const residenceLocality = this.modifyDataForm.get("residenceLocality").value;
    const situacionVivienda = this.getValuefromName(this.modifyDataForm.get("situacionVivienda").value, SITUACIONES);
    const anioAdquisicion = this.helpers.padWith(this.modifyDataForm.get("adquisicion").value);
    const cuotaAlquiler = this.modifyDataForm.get("alquilerVivienda").value;
    const tipoActividad = this.getValuefromName(this.modifyDataForm.get("tipoActividad").value, TIPOACTIVIDADPROFESIONAL);
    const situacionLaboral = this.modifyDataForm.get("situacionLaboral").value;
    const fechaAltaAutonomo = this.helpers.toYYYYMMDD(this.modifyDataForm.get("fechaAltaAutonomo").value);
    const codigoCNAE = this.modifyDataForm.get("cnae").value;
    const tipoContrato = this.getValuefromName(this.modifyDataForm.get("tipoContrato").value, TIPOSCONTRATO);
    const numeroPagas = this.modifyDataForm.get("numeroPagas").value;
    const antiguedadLaboral = this.helpers.toYYYYMMDD(this.modifyDataForm.get("antiguedadLaboral").value);
    // const profesion = this.getValuefromName(this.modifyDataForm.get('profesion').value, PROFESIONES);
    const profesion = this.getValue(this.modifyDataForm.get('profesion').value);
    const nombreEmpresa = this.modifyDataForm.get("nombreEmpresa").value;
    const ingresosMensuales = this.modifyDataForm.get("ingresosMensuales").value;
    this.setFechaAltaAlquiler(situacionVivienda);
    const fechaInitAlquiler = this.helpers.toYYYYMMDD(this.fechaInitAlquiler);


    if (sessionStorage.getItem('operacion-ampliacion') === "ampliacion" || sessionStorage.getItem('operacion-ampliacion') === "ampliacion-firma"
    || sessionStorage.getItem('operacion-inversora')) {

      this.EE_I_Modificacion = Object.assign({

      codigoPostal: codPostal,
      codigoCNO: tipoActividad,
      codigoProvinciaDomicilio: codigoProvinciaDomicilio,
      nombreProvinciaDomicilio: residenceProvinceName,
      nombreLocalidad: residenceLocality,
      codigoDomicilioTipoVia: tipovia,
      nombreVia: dir,
      domicilioNumero: numero,
      domicilioPortal: portal,
      domicilioEscalera: escalera,
      domicilioPlanta: planta,
      domicilioPuerta: puerta,
      bloque: bloque,
      parcela: parcela,
      fase: fase,
      anioAdquisicion: anioAdquisicion,
      regimenOcupacion: situacionVivienda,
      codigoSituacionLaboral: situacionLaboral,
      codigoCNAE: codigoCNAE,
      fechaAltaAutonomo: fechaAltaAutonomo,
      fechaAntiguedadLaboral: antiguedadLaboral,
      nombreEmpresa: nombreEmpresa,
      nivelSalarial: ingresosMensuales,
      codigoContrato: tipoContrato,
      duracion: "",
      numeroPagas: numeroPagas,
      fechaInicioAlquiler: fechaInitAlquiler,
      cuotaAlquiler: cuotaAlquiler,
      codigoRegimenEconomico: regEconomico,
      codigoEstadoCivil: estadoCivil,
      profesion: profesion,
      idCaso : sessionStorage.getItem("casoSeleccionado")

      /*
      codigoEstadoCivil: esCivil,
      codigoRegimenEconomico: rEconomico,
      codigoDomicilioTipoVia: tipovia,
      nombreVia: dir,
      domicilioNumero: numero,
      domicilioPlanta: planta,
      domicilioPuerta: puerta,
      domicilioPortal: portal,
      bloque: bloque,
      domicilioEscalera: escalera,
      parcela: parcela,
      fase: fase,
      codPostal: codPostal,
      residenceProvince: residenceProvince,
      residenceCountry: residenceCountry,
      sitVivienda: sitVivienda,
      tipoActividad: tipoActividad,
      codigoSituacionLaboral: sitLaboral,
      codigoContrato: tipoContrato,
      numeroPagas: numPagas,
      antiguedadLaboral: antiguedadLaboral,
      profesion: profesion,
      nombreEmpresa: empresa,
      ingresosMensuales: ingresosMensuales
      */
    });
  }
  else {
    this.EE_I_Modificacion = Object.assign({
      codigoPostal: codPostal,
      codigoCNO: tipoActividad,
      codigoProvinciaDomicilio: codigoProvinciaDomicilio,
      nombreProvinciaDomicilio: residenceProvinceName,
      nombreLocalidad: residenceLocality,
      codigoDomicilioTipoVia: tipovia,
      nombreVia: dir,
      domicilioNumero: numero,
      domicilioPortal: portal,

      domicilioEscalera: escalera,
      domicilioPlanta: planta,
      domicilioPuerta: puerta,
      bloque: bloque,
      parcela: parcela,
      fase: fase,
      anioAdquisicion: anioAdquisicion,
      regimenOcupacion: situacionVivienda,
      codigoSituacionLaboral: situacionLaboral,
      codigoCNAE: codigoCNAE,
      fechaAltaAutonomo: fechaAltaAutonomo,
      fechaAntiguedadLaboral: antiguedadLaboral,
      nombreEmpresa: nombreEmpresa,
      nivelSalarial: ingresosMensuales,
      codigoContrato: tipoContrato,
      duracion: "",
      numeroPagas: numeroPagas,
      fechaInicioAlquiler: fechaInitAlquiler,
      cuotaAlquiler: cuotaAlquiler,
      codigoRegimenEconomico: regEconomico,
      codigoEstadoCivil: estadoCivil,
      profesion: profesion,

    });
  }

    for (var clave in this.EE_I_Modificacion) {
      if(this.EE_I_Modificacion[clave] == '' || this.EE_I_Modificacion[clave] == this.EE_I_Inicial[clave]){
        delete this.EE_I_Modificacion[clave]
      }
    }
    sessionStorage.setItem('datos-modificar', JSON.stringify(this.EE_I_Modificacion));
    //this.helpers.navigateTo('confirma-otp');

       //if (sessionStorage.getItem('operacion-ampliacion') === "ampliacion") {
    // AÑADIR SI VIENE DE AMPLIACION DE LIMITE
    //let idCaso = { idCaso: sessionStorage.getItem("casoSeleccionado") };
    //EE_I_Modificacion.push(idCaso);
    //}

    const EE_I_Modificacion = Object.assign(this.EE_I_Modificacion);

    const datos = { EE_I_Modificacion };
    sessionStorage.setItem('datos-modificar', JSON.stringify(datos));
    this.helpers.navigateTo('confirma-otp');
  }

  onClose() {
    this.analytics.triggerInteractionData('Confirmacion de datos DNI', 'No es correcto')
    this.loading = true;
    this.setFields(false);
    this.loading = true;
    this.authService.updateProspect(this.persona).subscribe(() => {
      this.loading = false;
      this.errService.errNoCorrectCEF = this.loginSrv.isLoggedIn;
      this.errService.navToErrorEid();
    }, error => {
      this.errService.navToError();
    });
  }

  closeModal(e) {
    this.errService.navToError();
  }

  hasIncorrectSurnames() {
    this.errService.navToErrorEid();
  }

  separateSurname(surname) {
    let firstConnector = '';
    const options = [],
      solution = [],
      surnameArray = surname.split(" "),
      sortSolutions = (arr) => {
        const originalArr = arr.slice();
        const possibleSolutions = [];
        for (let i = 1; i <= originalArr.length; i++) {
          arr = originalArr.slice()
          const firstSurname = arr.splice(0, i).join(' ');
          const secondSurname = arr.join(' ');

          if (secondSurname) {
            possibleSolutions.push(firstSurname.concat("/ " + secondSurname))
          } else {
            possibleSolutions.push(firstSurname)
          }
        }
        return possibleSolutions
      }

    if (surnameArray.length <= 2) return surnameArray;

    surnameArray.forEach((surname) => {
      let option = '';
      if (surname.length > 3) {
        if (firstConnector !== "") {
          option = firstConnector.concat(' ' + surname);
          options.push(option);
          firstConnector = '';
          return
        }
        options.push(surname);
        return
      }
      if (surname.length <= 3) {
        if (firstConnector !== '') {
          firstConnector = firstConnector.concat(' ' + surname);
          return
        }
        firstConnector = surname;
      }
    });

    const filteredSolutions = sortSolutions(options).filter((surname, index, self) => {
      return index === self.indexOf(surname);
    })

    return this.surnames = filteredSolutions;
  }


  sendProspect(persona) {
    const nextStep = '/confirma-otp';
    this.loading = true;
    this.authService.setSessionProspect(persona);
    this.codProductoContratado = Object.assign({ codProductoContratado: persona.producto });
    sessionStorage.setItem('product-selected', JSON.stringify(this.codProductoContratado));
    this.loading = true
    this.authService.updateProspect(persona).subscribe((data) => {
      this.loading = false;
      if (data.response.codigo !== 'OK') {
        this.modal = this.modService.selectStatusModal(data.response);
        this.modService.open(this.modal.name, this.modal.blocking);
        return
      }
      if (this.authService.isLoggedIn) {
        this.authService.pageSubmitted = 'mi-perfil';
        this.loading = true;
        this.headerSrv.getRefreshToken().subscribe(data => {
          this.loading = false;
          this.helpers.navigateTo(nextStep); },
           error => {
            this.loading = false;
          });
      }
    }, error => {
      this.goToError();
    });
  }


  goToError() {
    this.errService.navToError();
  }

  editSitVivienda() {

    this.editData();
    //Llevamos a la posición del campo
    const target = document.getElementById('situacionVivienda'),
    headerHeight = 92,
    position = target.offsetTop - target.clientHeight - headerHeight;
    document.body.scrollTop = position;
    window.scrollTo(0,position);
    //Marcamos el aviso de error
    this.helpers.updateFieldStatus(this.modifyDataForm, 'situacionVivienda');
  }

  goToErrorModData(){
    const subidaDoc = JSON.parse(sessionStorage.getItem("subidaDocumentacion"));
    if (subidaDoc === true){

      this.errService.navToErrorModData('SATELITE', 'ENTENDIDO', 'login'); // campaña - login
    }
  }


  editData() {

    if (!this.editable){
      this.editable = true;
      this.triggerEditDataAnalytics();
    }

    setTimeout(()=>{
      this.validarCampos(true);
      this.initializeAutocomplete();
    }, 100);

  }

  noEditable() {
    this.editable = false;
  }

  hideBanner() {
    this.hiddeBanner = true;
    //metemos en sesion si ha cerrado el baner.
  }

  hideBannerNotifyOk() {
    this.hideBannerNotificacionOk = true;
    //metemos en sesion si ha cerrado el baner.
    sessionStorage.setItem("notificacionOk", "true");


  }

  hideNotification(){
    this.hiddeNotification = true;
  }

  botonVolver(){
    if (sessionStorage.getItem('operacion-ampliacion') === "ampliacion" || sessionStorage.getItem('operacion-ampliacion') === "ampliacion-firma"
      || sessionStorage.getItem('operacion-ampliacion') === "modificar") {
        this.helpers.navigateTo('/ampliacion-step1');
      } else {
        this.helpers.navigateTo("/perfil");
      }

  }

  botonVolverEditar(){
    this.editable = false;
  }

  toggleDataFlex(){
    this.showDataFlex= !this.showDataFlex;
  }

  initCNO(value) {
    if (value) {
      const filteredTipo = TIPOACTIVIDADPROFESIONAL.filter(tipoActividad => {
        return tipoActividad.value === value;
      })
      return filteredTipo[0]['name'];
    }
    return undefined;
  }

  validateAdditionalLaborFields() {

    const cuentaAjenaAdditionalFields =  this.cuentaAjenaFields.concat(this.commonLaborDetailsFiels);
    const autonomoAdditionalFields =  this.autonomoFields.concat(this.commonLaborDetailsFiels);

    this.noLaborValidators();

    if (this.isCuentaAjena) {
      this.helpers.resetFieldsState(this.modifyDataForm, cuentaAjenaAdditionalFields)
      this.helpers.updateFieldValidation(this.modifyDataForm, 'profesion', [Validators.required]);
      this.helpers.updateFieldValidation(this.modifyDataForm, 'tipoActividad', [Validators.required]);
      this.helpers.updateFieldValidation(this.modifyDataForm, 'tipoContrato', [Validators.required]);
      this.helpers.updateFieldValidation(this.modifyDataForm, 'nombreEmpresa', [
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.required
      ]
      );
      this.helpers.updateFieldValidation(this.modifyDataForm, 'antiguedadLaboral', [Validators.required, this.evoValidator.validateDateDDMMYYYY, this.evoValidator.validateNoFutureDate]);
      this.helpers.updateFieldValidation(this.modifyDataForm, 'ingresosMensuales', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(6),
        this.evoValidator.validateIncomings
      ]
      );
      this.helpers.updateFieldValidation(this.modifyDataForm, 'numeroPagas', [
        Validators.minLength(1),
        Validators.maxLength(2),
        this.evoValidator.validateAnnualPayments
      ]
      )
      this.cleanSpecificFields(autonomoAdditionalFields);
    } else if (this.isAutonomo) {
      this.helpers.resetFieldsState(this.modifyDataForm, autonomoAdditionalFields)
      this.helpers.updateFieldValidation(this.modifyDataForm, 'profesion', [Validators.required]);
      this.helpers.updateFieldValidation(this.modifyDataForm, 'tipoActividad', [Validators.required]);
      this.helpers.updateFieldValidation(this.modifyDataForm, 'fechaAltaAutonomo', [
        Validators.required,
        this.evoValidator.validateDate,
        this.evoValidator.validateDateDDMMYYYY,
        this.evoValidator.validateNoFutureDate
      ]
      );
      this.helpers.updateFieldValidation(this.modifyDataForm, 'cnae', [Validators.required]);
      this.cleanSpecificFields(cuentaAjenaAdditionalFields);
    } else if (!this.isAutonomo && !this.isCuentaAjena) {
      this.cleanAllLaborDetailsValues();
    }

  }

  /**
   * Borra valores introducidos en todos los campos incluidos en el array del formulario
   * @param arrayFields
   */
  cleanSpecificFields(arrayFields) {
    Object.keys(this.modifyDataForm.controls).forEach(key => {
      if (arrayFields.includes(key)) {
        this.helpers.updateFieldValue(this.modifyDataForm, key);
      }
    });
  }

  /**
   * Borra valores introducidos en todos los campos extras de datos laborales
   */
  cleanAllLaborDetailsValues() {
    const laborDetailsFields = this.commonLaborDetailsFiels.concat(
                                this.autonomoFields.concat(
                                  this.cuentaAjenaFields));
    this.cleanSpecificFields(laborDetailsFields);
  }

  /**
   * Elimina las validaciones de los campos incluidos en el array
   * @param arrayFields
   */
  noValidators(arrayFields) {
    Object.keys(this.modifyDataForm.controls).forEach(key => {
      if (arrayFields.includes(key)) {
        this.helpers.updateFieldValidation(this.modifyDataForm, key);
      }
    });
  }

   /**
   * Elimina las validaciones de los campos extras de datos laborales
   * @param arrayFields
   */
  noLaborValidators() {
    const laborDetailsFields = this.commonLaborDetailsFiels.concat(
                                this.autonomoFields.concat(
                                  this.cuentaAjenaFields));
    this.noValidators(laborDetailsFields);
  }

  /**
   * Redirige al usuario a la ventana de subir-documentación, además de indicar en la sesión
   * el tipo de solicitud.
   */
  subirDocumentacion() {
    sessionStorage.setItem('tipoSolicitud', this.tipoSolicitud);
    this.updateWarnigsAndRedirect();
  }

  /**
   * Se obtienen los warnings que tiene este usuario y dependiendo del tipo de solicitud
   * se muestran un banner u otro.
   * Preparado para añadir los demás banner
   */
  getWarnings() {
    this.loading = true;
    this.personaSrv.getWarnings(this.clientID).subscribe(response => {
      this.loading = false;
      this.hiddeBanner = false;
      this.tipoSolicitud = response.message;
      //this.tipoSolicitud = '04';
      sessionStorage.setItem("tipoSolicitud", this.tipoSolicitud);
      this.checkTipoSolicitud();
    }, _error => {
      this.loading = false;
      this.hideAllWarnigs();
      this.errService.modDataError = true;
      this.errService.navToError();
    });
  }

  checkTipoSolicitud() {
    if (this.tipoSolicitud === '00') {
      this.hasDNIcaducado = false;
      this.hasNotDNIinRSI = false;
      this.hideAllWarnigs();
      this.hiddeBanner = true;
      this.hasSubidaDocCorrecta = true;
    }
    else if (this.tipoSolicitud === '01') {
      this.hasDNIcaducado = true;
    }
    else if (this.tipoSolicitud === '02') {
      this.hasNotDNIinRSI = true;
    }
    else if (this.tipoSolicitud === '03') {
      this.hasDNIcaducado = true;
      this.hasNotDNIinRSI = true;
    }
    else if (this.tipoSolicitud === '04') {
      this.errService.navToErrorModData('ALIEN-B', 'ENTENDIDO', 'login');
    }
    else if (this.tipoSolicitud !== '00' && this.tipoSolicitud !== '01' && this.tipoSolicitud !== '02' && this.tipoSolicitud !== '03' && this.tipoSolicitud !== '04'){
      this.hideAllWarnigs();
      this.goToErrorModData();
    }

    this.hasCamposErroneos = this.camposErroneos && this.camposErroneos.length > 0;

    if (this.tipoSolicitud !== '04') {
      this.updateWarnigsAndRedirect();
    }

    if (this.hasCamposErroneos && (this.tipoSolicitud === '01' || this.tipoSolicitud === '02' || this.tipoSolicitud === '03'|| this.tipoSolicitud === '04')) {

      if (sessionStorage.getItem('operacion-ampliacion') || sessionStorage.getItem('operacion-inversora') ||
        sessionStorage.getItem('updateData')) {
        this.hideAllWarnigs();
        this.hiddeBanner = true;
        this.showWithContract = true;

        if (sessionStorage.getItem('operacion-ampliacion')) {
          this.nombreProceso = "de ampliación";
        }
        else if (sessionStorage.getItem('operacion-inversora') === 'operacion-inversora') {
          this.nombreProceso = "de cuenta inversora";
        }
        else if (sessionStorage.getItem('operacion-inversora') === 'fondo-inteligente') {
          this.nombreProceso = "de fondo inteligente";
        }
        else if (sessionStorage.getItem('updateData')) {
          this.nombreProceso = "de subida de documentación";
        }

        this.doEditable();
      }
    }
  }

  getInfoSolicitud() {
    this.loading = true;
    this.personaSrv.getInfoSolicitud(this.clientID).subscribe(response => {
      this.hiddeBanner = false;
      this.tieneSolicitudes = !!response.solicitudes || !!(response.result && response.result !== 'KO');
      this.solicitudesEnCurso = [];
      if(!this.tieneSolicitudes){
        this.showCurtain = false;
        this.showWarningValidating = false;
        this.hiddeBanner = true;
        this.getWarnings();
      }
      else{

        this.solicitudesEnCurso = response.solicitudes;

        for (const solicitud of this.solicitudesEnCurso) {
          if (solicitud.tipoSolicitud === "01" || solicitud.tipoSolicitud === "02") {
            if (solicitud.estado === "Nueva") {
              sessionStorage.setItem("validacionPendiente", "false");
              this.hideAllWarnigs();
              this.goToErrorModData();
              break;
            }
            else if (solicitud.estado === "Enviada") {
              this.hideAllWarnigs();
              sessionStorage.setItem("validacionPendiente", "true");
              this.updateWarnigsAndRedirect();
              break;
            }
            else {


                this.analytics.triggerProfileModDataNavData({ 'pageName': 'Perfil/DNI en Trámite' });



              this.showWarningUpdateDocument = false;
              this.showWarningValidating = true;

              if (!this.backFromSubidaDoc) {
                let message = this.isCampanyaSubidaDoc?'revisandoDocumentacionCampanya':'revisandoDocumentacionEnviada';
                this.showCurtainMessage(message);
              }
              //this.errService.navToErrorModData('ASTRONAUTA-C', 'ENTENDIDO','login');
              sessionStorage.setItem("validacionPendiente", "true");
              this.showWarningNoDocument = false;
              break;
            }
          }
          else{
            this.showCurtain = false;
            this.hideAllWarnigs();
            if (solicitud.tipoSolicitud === "03"){
              break; //TODO de momento no aplica y no dejarás hacer una nueva solicitud de modificación de datos.
            }
          }
        }
        sessionStorage.setItem('comprobadaSolicitudEnTramite', 'true');
        this.loading = false;
      }
    }, error => {
      this.showCurtain = false;
      this.hideAllWarnigs();
      this.errService.modDataError = true;
      this.errService.navToErrorModData('SATELITE', 'ENTENDIDO', 'mi-perfil');
    });
  }


  rellenarBarraProgreso(persona) {
    let porcentaje = 0;
    //Sumamos porcertanjes
    if (persona.nombreCliente) {
      porcentaje = porcentaje + 2.6;
    }
    if (persona.apellido1Cliente) {
      porcentaje = porcentaje + 2.6;
    }
    if (persona.apellido2Cliente) {
      porcentaje = porcentaje + 2.6;
    }
    if (persona.fechaNacimiento) {
      porcentaje = porcentaje + 2.6;
    }
    if (persona.id_cliente_EVO) {
      porcentaje = porcentaje + 1.9;
    }
    if (persona.idExterno) {
      porcentaje = porcentaje + 2.6;
    }
    if (persona.paisNacionalidad) {
      porcentaje = porcentaje + 2.6;
    }
    if (persona.nombreLocalidadNacimiento) {
      porcentaje = porcentaje + 1.9;
    }
    if (persona.nombreProvinciaNacimiento) {
      porcentaje = porcentaje + 2.6;
    }
    if (persona.codigoEstadoCivil) {
      porcentaje = porcentaje + 2.3;
    }
    if (persona.codigoRegimenEconomico) {
      porcentaje = porcentaje + 2, 3;
    }
    if (persona.sexo) {
      porcentaje = porcentaje + 1, 9;
    }
    //Direccion
    if (persona.direccion) {
      if (persona.direccion.codigoPostal) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.codigoPaisResidencia) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.fechaAltaAlquiler) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.cuotaAlquilerMensual) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.codigoProvinciaDomicilio) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.nombreProvinciaDomicilio) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.nombreLocalidad) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.codigoDomicilioTipoVia) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.nombreVia) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.domicilioNumero) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.domicilioPortal) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.domicilioEscalera) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.domicilioPlanta) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.domicilioPuerta) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.bloque) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.parcela) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.fase) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.anioAdquisicion) {
        porcentaje = porcentaje + 1.6;
      }
      if (persona.direccion.codigoPaisResidencia) {
        porcentaje = porcentaje + 1.6;
      }
    }
    //DatosSocioEco
    if (persona.datosSocioEco) {

      if (persona.datosSocioEco.regimenOcupacion) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.codigoCNO) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.codigoSituacionLaboral) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.codigoCNAE) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.fechaAltaAutonomo) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.fechaAntiguedadLaboral) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.codigoCNO) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.nombreEmpresa) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.nivelSalarial) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.duracion) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.numeroPagas) {
        porcentaje = porcentaje + 3.3;
      }
      if (persona.datosSocioEco.codigoContrato) {
        porcentaje = porcentaje + 3.3;
      }
    }
    this.porcentajeBarra = Math.round(porcentaje);
    //Cambiamos width con valor de porcentaje.
    let valorBarra = porcentaje.toString();

    this.barraStyle = Object.assign({
      width: valorBarra + "%",
    });
  }

  /**
   * Oculta banners y muestra el telón para el tipo de mensaje que se pasa como parámetro
   * @param message Tipo de mensaje
   */
  showCurtainMessage(message: string) {
      this.hiddeBanner = false;
      this.nameMessage = message;
      this.showCurtain = true;
  }

  infoMyProfile(){
    this.helpers.navigateTo('info-mi-perfil');
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.token && params.refresh && params.origen && params.dni) {
        this.accessToken = params.token;
        this.refreshToken = params.refresh;
        this.idExterno = params.dni;
        this.origen = params.origen.toLowerCase();
        sessionStorage.setItem('auth_token',this.accessToken);
        sessionStorage.setItem('refresh_token',this.refreshToken);
        if(this.origen === "bmi"){
          this.isBmi = true;
        }
          this.isCampanyaSubidaDoc = params.subidaDocumentacion;
          if(this.isCampanyaSubidaDoc){
            this.loading = true;
            this.loginSrv.campanyaSubDoc= true;
            this.isCampanyaSubidaDoc = true;

            if (this.loginSrv.isLoggedIn && this.sessionUserData){
              this.clientID = this.sessionUserData.persona.id_cliente_EVO;
              this.idProducto =  this.sessionUserData.listaContratados[0].idProducto;
              sessionStorage.setItem("idClienteEvo", this.clientID);
              sessionStorage.setItem("userData", JSON.stringify(this.sessionUserData));
              sessionStorage.setItem('idProductoContratado',  this.idProducto);
              this.getPersona();
              this.loading = false;
              this.continueFlow();
            }
            else {
              this.authUserService.getUserData(this.idExterno).subscribe(data => {
                console.log("userdata:"+  JSON.stringify(data));
                this.clientID = data.persona.id_cliente_EVO;
                this.idProducto =  data.listaContratados[0].idProducto;
                sessionStorage.setItem("idClienteEvo", this.clientID);
                sessionStorage.setItem("userData", JSON.stringify(data));
                sessionStorage.setItem('idProductoContratado',  this.idProducto);
                this.getPersona();
                this.loading = false;
                this.continueFlow();
              },
              _error => {
                this.loading = false;
                this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
              }
              );
            }


          }
      }
    });
  }

  get isTokenizedURL() {
    return (this.accessToken && this.idExterno);
  }


  hasInvalidFiscalObligations() {
    //this.loading = true;
    //this.showErrorObligacionesFiscales = true;
    if(sessionStorage.getItem('operacion-ampliacion')){
      this.ampliacionService.putLimite("obligaciones-tributarias","obligaciones-tributarias");
    }else if(sessionStorage.getItem('operacion-inversora')){
      this.obligacionesFiscales();
    }
  }

  /**
   * Método que hace un put con la modificación de las obligaciones tributarias
   * para que salesforce desestime el caso en caso de tener obligaciones el cliente
   */
  obligacionesFiscales() {
    if (sessionStorage.getItem('operacion-inversora')) {

      let idCaso = sessionStorage.getItem("casoSeleccionado");
      let isSecondSigner = sessionStorage.getItem('isSecondSigner') === 'true';

      let obj: object = {
        'idCaso': idCaso,
        'estado': !isSecondSigner ? 'obligaciones-tributarias-tit1' : 'obligaciones-tributarias-tit2',
        'obligacionesFiscales': true
      }

      this.modificarCuenta(obj);
    }
  }

  /**
   * Put con la modificación de las obligaciones tributarias para que salesforce
   * desestime el caso en caso de tener obligaciones el cliente
   */
  private modificarCuenta(obj) {
    this.loading = true;
    this.personaSrv.modificarCuenta(obj).subscribe(data => {
      this.loading = false;
      if (data.codigo === 'KO') {
        //modifiación realizada, devuelve un KO por tener obligaciones fiscales
      } else {
        this.errService.modDataError = true;
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'mi-perfil');
      }
    });
  }

  handleError() {
    this.analytics.triggerFormData('Datos Personales', `Envio KO: 500 Server error`);
    this.loading = false;
    this.errService.navToError();
  }

  setSteps() {
    if (this.currentPath){
      if (this.currentPath.indexOf('/mi-perfil') !== -1) {
        this.step = 1;
      } else if (this.currentPath.indexOf('/configuracion-cuenta-inversora') !== -1) {
        this.step = 2;
      } else if (this.currentPath.indexOf('/visor-cuenta-inversora') !== -1) {
        this.step = 3;
      } else if (this.currentPath.indexOf('/final-cuenta-inversora') !== -1) {
        this.step = 4;
      }
    }
    else if (!this.step){
      this.step = 1;
    }
  }

  hideNotificationObligaciones() {
    this.showErrorObligacionesFiscales = false;
  }

  initializeAutocomplete() {
    this.searchDirection = new FormControl();
    this.agm.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(document.getElementById('direccion'),
        {
          types: ['address'],
          componentRestrictions: { country: "es" }
        });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          this.autocompleteAddress(place);
        })
      })
    })
  }

  autocompleteAddress(address) {
    let hasPostalCode: boolean = false;

    if (!address.address_components) return

    address.address_components.filter(component => {
      switch (component.types[0]) {
        case 'postal_code':
          this.helpers.updateFieldValue(this.modifyDataForm, 'codPostal', component.long_name);
          hasPostalCode = true;
          break;
        case 'route':
          this.helpers.updateFieldValue(this.modifyDataForm, 'direccion', component.long_name);
          break;
        case 'locality':
          this.localidad = component.long_name;
          break;
        case 'street_number':
          this.helpers.updateFieldValue(this.modifyDataForm, 'numero', component.long_name);
          break;
      }
    })
    if (!hasPostalCode) {
      this.cleanValues();
    }

  }

  cleanValues() {
    this.helpers.updateFieldValue(this.modifyDataForm, 'codPostal');
    this.helpers.updateFieldValue(this.modifyDataForm, 'numero');
    this.helpers.updateFieldValue(this.modifyDataForm, 'provincia');
    this.selectLocalidades = [this.localidadLiteral];
  }

  setFechaAltaAlquiler(codVivienda){
    if (codVivienda === '1' || codVivienda === '4') {
      this.isMortgage = true;
      this.isRental = false;
      this.fechaInitAlquiler = "";
    }
    else if (codVivienda === '2'){
      this.isMortgage = false;
      this.isRental = true;
      // añadir año de alquier fecha actual
      var fechaActual = new Date();
      this.dia = fechaActual.getDate();
      this.mes = fechaActual.getMonth()+1; //January is 0!
      this.anyo = fechaActual.getFullYear();

      if(this.dia < 10) {
        this.dia = '0'+this.dia;
      }

      if(this.mes < 10) {
        this.mes = '0'+this.mes;
      }
      var fechaAlquiler = this.dia + '/' + this.mes + '/' + this.anyo;
      this.fechaInitAlquiler = fechaAlquiler;
    } else {
      this.isMortgage = false;
      this.isRental = false;
      this.fechaInitAlquiler = "";
    }
  }

  /**
   * Actualiza notificaciones y/o redirige en función del estado de los datos del usuario
   * y de sus peticiones de actualización de documentación
   */
  private updateWarnigsAndRedirect(){

    //Ya pantalla final ya mostrada
    if(this.showCurtain){
      return;
    }

    this.hasValidacionPendiente = (sessionStorage.getItem('validacionPendiente') === 'true');
    this.hasSubidaDocCorrecta = (sessionStorage.getItem('updateDni') === 'true');

    this.peticionModificacion = (sessionStorage.getItem('peticion-modificacion') === 'true');
    this.operacionAmpliacion = sessionStorage.getItem('operacion-ampliacion');

    let isNotProcesoContratacion = !this.operacionAmpliacion;

    if(!this.camposErroneos){
      this.camposErroneos = this.loginSrv.camposError;
    }

    this.hasCamposErroneos = this.camposErroneos && this.camposErroneos.length > 0;

    let faltanDatosDNI = this.loginSrv.dniCaducado || this.hasDNIcaducado || this.hasNotDNIinRSI;

    this.comprobadaSolicitudEnTramite = sessionStorage.getItem('comprobadaSolicitudEnTramite') == 'true';

    this.setAnalyticsPageNameAllOk();

    //Si estamos en campaña y no se ha comprobado si existe solicitud en trámite
    //Comprobar si existe solicitud en trámite en campaña para mostrar el astronauta o no
    if (this.isCampanyaSubidaDoc && !this.comprobadaSolicitudEnTramite){
      this.checkSolicitudEnTramite();
      return;
    }

    //Si ya se ha comprobado y existe solicitud en trámite en campaña no hacer nada
    if (this.isCampanyaSubidaDoc && this.comprobadaSolicitudEnTramite && this.existeSolicitudEnTramite){
      return;
    }

    //Si existe una subida correcta y estamos en campaña, lanzar Analytics y detener ejecución del resto de codigo
    if (this.hasSubidaDocCorrecta && this.isCampanyaSubidaDoc) {
      this.analytics.triggerProfileModDataNavData({ 'pageName': this.allOKAnalyticsPageName });
      this.updateDni = true;
      //this.showCurtainMessage('todoListo');
      return;
    }

    if (this.hasSubidaDocCorrecta && !this.isCampanyaSubidaDoc){
      this.updateDni = true;
      return;
    }

    //Si no estamos en el caso anterior y existe 'peticion-documentacion' (Ampliacion o C.Inv)
    if (this.peticionModificacion) {
      if (this.isCampanyaSubidaDoc && !faltanDatosDNI){
        this.analytics.triggerProfileModDataNavData({ 'pageName': this.allOKAnalyticsPageName });
        //this.showCurtainMessage('todoListo');
        this.updateDni = true;
      }
      else {
        this.updateDni = true;
      }


      return;
    }


    if (isNotProcesoContratacion && !this.isCampanyaSubidaDoc && !this.hasCamposErroneos) {
      //WARNIGS DE GETWARNIGNS

      // this.showWarningUpdateDocument = this.hasDNIcaducado;
      // this.showWarningNoDocument = this.hasNotDNIinRSI; //o caducadoy sin documentcion
      // this.showWarningValidating = this.hasValidacionPendiente;

      if (this.hasDNIcaducado && !this.hasNotDNIinRSI) {
        this.showWarningUpdateDocument = true;
        this.showWarningNoDocument = false;
        this.showWarningValidating = false;
        return;
      }
      else if (this.hasNotDNIinRSI) {
        this.showWarningUpdateDocument = true;
        this.showWarningNoDocument = true;
        this.showWarningValidating = false;
        return;
      }
      else if (this.hasValidacionPendiente) {
        this.showWarningUpdateDocument = true;
        this.showWarningNoDocument = false;
        this.showWarningValidating = true;
        return;
      }
      else {
        this.hideAllWarnigs();
        return;
      }
    }


    //Si existe una validación pendiente (Estado enviada) mostrar telón
    //Si campaña 'Todo OK' si no 'Estamos revisando'
    if (this.hasValidacionPendiente && this.comprobadaSolicitudEnTramite){
      this.wizard = false;
      if (this.isCampanyaSubidaDoc){
        this.analytics.triggerProfileModDataNavData({ 'pageName': this.allOKAnalyticsPageName });
        //this.showCurtainMessage('todoListo');
      } else {

          this.analytics.triggerProfileModDataNavData({ 'pageName': 'Perfil/DNI en Trámite' });

        this.showCurtainMessage('revisandoDocumentacionEnviada');
      }
      return;
    }

    //SIN CAMPAÑA en contratacion
    if (!this.isCampanyaSubidaDoc){

      //Falta DNI Y datos erroneos
      if(faltanDatosDNI && this.hasCamposErroneos){
        this.showWithContract = true;
          this.showWarningUpdateDocument = false;
          this.showWarningNoDocument = false;
          this.nombreProceso = "de subida de documentación";
          this.doEditable();
          return;
      }

      if(faltanDatosDNI && !this.hasCamposErroneos){
        //redirigir a subida DOC
        this.helpers.navigateTo('subir-documentacion');
        return;
      }

      if(!faltanDatosDNI && !this.hasCamposErroneos){
        //venimos de subida DOC correcta
        //faldón manita
        return;
      }

      if(!faltanDatosDNI && this.hasCamposErroneos){
        //Consola en modo confirmación
        return;
      }

    }

    // CAMPAÑA
    if (this.isCampanyaSubidaDoc){
      //Falta DNI Y datos erroneos
      if(faltanDatosDNI && this.hasCamposErroneos){
          this.showWithContract = true;
          this.showWarningUpdateDocument = false;
          this.showWarningNoDocument = false;
          this.nombreProceso = "de subida de documentación";
          this.doEditable();
          return;
      }

      if(faltanDatosDNI && !this.hasCamposErroneos){
        this.helpers.navigateTo('subir-documentacion');
        //Manita arriba si todo Ok tras envio
        return;
      }

      //TELON TODO LISTO
      if(!faltanDatosDNI && !this.hasCamposErroneos){
        this.analytics.triggerProfileModDataNavData({ 'pageName': this.allOKAnalyticsPageName });
        //this.showCurtainMessage('todoListo');
        return;
      }

      if(!faltanDatosDNI && this.hasCamposErroneos){
        //Pendiente de confirmacion
        this.doEditable();
        return;
      }
    }

  }

  private hideAllWarnigs(){
    this.showWarningUpdateDocument = false;
    this.showWarningValidating = false;
    this.showWarningNoDocument = false;
  }


  /**
   * Setea el pageName de Analytics para 'todo OK' para poder diferenciar
   * el flujo del que proviene
   */
  private setAnalyticsPageNameAllOk() {

    let faltanDatosDNI = this.loginSrv.dniCaducado || this.hasDNIcaducado || this.hasNotDNIinRSI;

    this.allOKAnalyticsPageName = sessionStorage.getItem('allOKAnalyticsPageName');

    if (!this.allOKAnalyticsPageName) {

      if (faltanDatosDNI) {
        //DATOS MAL Y DNI KO
        if (this.hasCamposErroneos) {
          this.allOKAnalyticsPageName = 'Perfil/Datos OK';
        } //SOLO DNI MAL
        else {
          this.allOKAnalyticsPageName = 'Perfil/DNI OK';
        }
      }
      else {
        this.allOKAnalyticsPageName = 'Perfil/Modificación de datos OK';
      }

      sessionStorage.setItem('allOKAnalyticsPageName',this.allOKAnalyticsPageName);
    }
  }

  checkSolicitudEnTramite(){

    this.loading = true;
    this.personaSrv.getInfoSolicitud(this.clientID).subscribe(response => {
      sessionStorage.setItem('comprobadaSolicitudEnTramite', 'true');
      this.hiddeBanner = false;
      this.tieneSolicitudes = !!response.solicitudes || !!(response.result && response.result !== 'KO');
      this.solicitudesEnCurso = [];
      if(this.tieneSolicitudes){
        this.solicitudesEnCurso = response.solicitudes;
        for (const solicitud of this.solicitudesEnCurso) {
          if (solicitud.tipoSolicitud === "01" || solicitud.tipoSolicitud === "02") {
            if (solicitud.estado === "Nueva") {
              sessionStorage.setItem("validacionPendiente", "false");
              this.hideAllWarnigs();
              this.goToErrorModData();
              break;
            }
            else if (solicitud.estado === "Enviada") {
              this.hideAllWarnigs();
              sessionStorage.setItem("validacionPendiente", "true");
              this.updateWarnigsAndRedirect();
              break;
            } else if (solicitud.estado === "En trámite") {
              this.existeSolicitudEnTramite = true;


                this.analytics.triggerProfileModDataNavData({ 'pageName': 'Perfil/DNI en Trámite' });

              this.showWarningUpdateDocument = false;
              this.showWarningValidating = true;

              if (!this.backFromSubidaDoc) {
                let message = this.isCampanyaSubidaDoc?'revisandoDocumentacionCampanya':'revisandoDocumentacionEnviada';
                this.showCurtainMessage(message);
              }
              //this.errService.navToErrorModData('ASTRONAUTA-C', 'ENTENDIDO','login');
              sessionStorage.setItem("validacionPendiente", "true");
              this.showWarningNoDocument = false;
              break;
            }
          }
        }
        this.loading = false;
        this.updateWarnigsAndRedirect();
      }
      this.loading = false;
      this.updateWarnigsAndRedirect();
    }, _error => {
      this.showCurtain = false;
      this.hideAllWarnigs();
      this.errService.modDataError = true;
      this.errService.navToErrorModData('SATELITE', 'ENTENDIDO', 'mi-perfil');
    });
  }

  triggerEditDataAnalytics(){
    if(sessionStorage.getItem('operacion-ampliacion')){
      if (parseInt(sessionStorage.getItem('limite-solicitado')) >= 3000) {
        this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/Paso 02.2-Edición de datos', 'creditLimit': 'Mayor de 3K€'});
      }
      else{
        this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/Paso 02.2-Edición de datos', 'creditLimit': 'Menor de 3K€'});
      }
    }
    else{
      this.analytics.triggerProfileModDataNavData({'pageName': 'Perfil/Edición de datos'});
    }
  }

  isMobileOrTablet(): boolean {
    return this.deviceService.isMobileOrTablet();
  }

  continueFlow(){
    this.checkSessionStorage();
    this.noLocalidadCounter = false;
    sessionStorage.removeItem("submit");

    //Si es no es BMI el updateWarnigsAndRedirect se ejecuta dentro del checkvaliduaserData
    if(!this.isBmi){
      this.checkValidUserData();
    } else {
      this.link="";
      this.checkValidUserData();
      //this.updateWarnigsAndRedirect();
    }

    this.setVisibleInfo();

    document.documentElement.classList.remove('noscroll');
    this.initModal();

    this.showCurtain = false;
    if (this.isCampanyaSubidaDoc){
      this.btnShow = false;
    }

    //let subidaDocCampanyaOK =  this.isCampanyaSubidaDoc && this.hasSubidaDocCorrecta && this.updateDni;

    if (!this.isCampanyaSubidaDoc && !this.showCurtain){

      sessionStorage.setItem("updateDniCorrect", "false");
      this.loading = true;

      if(!this.idExterno){
        this.idExterno = this.sessionUserData.persona.idExterno;
      }
      this.loading = true;

      if(this.loginSrv.isLoggedIn && this.sessionUserData){
        this.userData = this.sessionUserData;
        this.loginSrv.userData = this.userData;
        this.clientID = this.userData.persona.id_cliente_EVO;
        this.getInfoSolicitud();
        if (!this.tipoSolicitud) {
          this.getWarnings();
        }
        if(!this.idProducto){
          this.idProducto  = this.userData.listaContratados[0].idProducto;
        }
        this.getPersona();
      }
      else{
        this.authUserService.getUserData(this.idExterno).subscribe(data => {
          this.userData = data;
          this.loginSrv.userData = this.userData;
          this.clientID = this.userData.persona.id_cliente_EVO;
          //Rellenamos la barra de progreso
          this.rellenarBarraProgreso(this.userData.persona);
          if (!this.tipoSolicitud) {
            this.getInfoSolicitud();
            this.getWarnings();
          }
          if(!this.idProducto){
            this.idProducto  = this.userData.listaContratados[0].idProducto;
          }

          this.getPersona();

        }, error => {
          this.errService.rsi = true;
          this.errService.navToError();
        });
      }
    }


    const userExist: Persona = this.authService.getSessionProspect();

    this._isMobileOrTablet = this.isMobileOrTablet();
  }

  onKeydown(event){
    event.preventDefault();
  }
}
