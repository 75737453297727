import { Directive, ElementRef, Input, OnChanges, OnDestroy, AfterViewInit, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { Observable } from 'rxjs';

@Directive({
  selector: '[date-input]'
})

export class DateInputDirective  {

  limit: number = 10;

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.addEventListener('keypress', this.onKeyPress.bind(this));
    this.elementRef.nativeElement.addEventListener('keyup', this.onKeyUp.bind(this));
  }

  onKeyPress(e) {
    this.checkNumber(e);
    this.checkLength(e);
    this.validateDate(e);
  }

  onKeyUp(e) {
    this.validateDate(e);
  }

  checkLength(_e) {
    let textSelected = window.getSelection().toString();
    if(_e.target.value.length === this.limit && textSelected.length == 0) _e.preventDefault();
  }
  checkNumber(e) {
    const regEx = new RegExp('^\\d+$');
    let pressedKey = e.key;
    if(!regEx.test(pressedKey))  {
      e.preventDefault();
    }
  }

  validateDate(ev) {
    let cadena;
    const placeholder= 'dd/mm/yyyy'
    const selector = this.elementRef.nativeElement.selection;
    const elemento = this.elementRef.nativeElement;
    let char =  ev.key;
  	if (selector) {
          elemento.focus();                                        // Set focus on the element
          var oSel = selector.createRange();        // To get cursor position, get empty selection range
          oSel.moveStart('character', -elemento.value.length);    // Move selection start to 0 position
          elemento.selectionEnd = oSel.text.length;                    // The caret position is selection length
          oSel.setEndPoint('EndToStart', selector.createRange());
          elemento.selectionStart = oSel.text.length;
      }
  	let auxini = elemento.selectionStart;

    if((auxini == 2 || auxini == 5) && elemento.value[auxini] != '/' && char != 'Backspace'){
      elemento.value = elemento.value.substr(0, auxini) + "/" + elemento.value.substr(auxini);
    }

  	if(elemento.value.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/)){
      //TODO validacion extra?
    }
  }

}
