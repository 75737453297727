<loading [showMe]="loading"></loading>

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{messageError}}</p>
  </div>
</modal>

<form [formGroup]="configureForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()" novalidate>
  <h3>CALCULA TU PRECIO</h3>
  <div class="mobile-container row">
    <label for="actividad">Quiero practicar</label>
    <select formControlName="actividad" id="actividad" name="actividad" placeholder="Selecciona una actividad" arrow="true">
      <option [ngValue]="''" disabled selected>Selecciona un deporte</option>
      <option *ngFor="let activity of DEPORTE1" [ngValue]="activity.id" (click)="selectedActivity=activity.id">{{activity.value}}</option>
    </select>
  </div>

  <div class="mobile-container row">
    <label for="duration">durante</label>
    <select formControlName="duration" id="duration" name="duration" placeholder="Selecciona un periodo" arrow="true">
      <option [ngValue]="''" disabled selected>Selecciona un periodo</option>
      <option *ngFor="let duration of DURACION" [ngValue]="duration.id" (click)="selectedPeriod=duration.id">{{duration.value}}</option>
    </select>
  </div>

  <div class="mobile-container row">
    <label for="region">en un país</label>
    <select formControlName="region" id="region" name="region" placeholder="Selecciona una región" arrow="true">
      <option [ngValue]="''" disabled selected>Selecciona una región</option>
      <option *ngFor="let region of AMBITO" [ngValue]="region.id" (click)="selectedRegion=region.id">{{region.value}}</option>
    </select>
  </div>

  <div class="mobile-container col" *ngIf="CAPITAL_GLOBAL.length>0">
    <label for="capital">con un capital asegurado en caso de accidente de</label>
    <custom-radio-buttons override formControlName="capital" id="capital" name="capital" [slimbuttons]="true" [options]="CAPITAL_GLOBAL" getValue="true" (onValue)="onValue($event)">
    </custom-radio-buttons>
  </div>

  <div class="mobile-container row">
    <label for="cobertura">y cobertura sanitaria </label>
    <select formControlName="cobertura" id="cobertura" name="cobertura" placeholder="Selecciona una cobertura" arrow="true">
      <option [ngValue]="''" disabled selected>Selecciona una cobertura</option>
      <option *ngFor="let cobertura of REEMBOLSOGASTOS" [ngValue]="cobertura.id" (click)="selectedCobertura=cobertura.id">{{cobertura.value}}</option>
    </select>
  </div>

  <div class="row last-line" *ngIf="totalPrice">
    <div class="mobile-container">
      <p class="price-hour">{{priceByDay | number:'1.2-2' | currencycustom }}/{{calcType}}</p>
      <div class="your-evo-protect">Tu EVO PROTECT DEPORTES por sólo <span class="price">{{totalPrice | number:'1.2-2' | currencycustom }}</span>
      </div>
    </div>
  </div>

  <div class="btn-group">
    <button type="submit" class="btn-common  full-size btn-common_fix_bold" [disabled]="!formValid">ME INTERESA</button>
  </div>

</form>
