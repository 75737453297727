<div class="heading">
  <h3>{{notification['title']}}</h3>

</div>
<app-breadcrumb id="breadcrumb" class="mobile" [breadcrumbs]="breadcrumbNav"
  breadcrumbAdd="{{notification['title']}}"></app-breadcrumb>
<div class="notifications content-wrapper has-breadcrumb">
  <div class="content-contain">
    <div class="row title">
      <h3>{{notification['title']}}</h3>
    </div>
    <div class="row content">
      <div [innerHTML]='notification["content"]'></div>
    </div>
    <span class="center-span">Aprovecha y descarga nuestra App</span>
    <div class="badge-container">
      <a target="_blank" class="placeholder-badge" id="apple" href="https://itunes.apple.com/es/app/evo-banco-m%C3%B3vil/id542752481?mt=8"></a>
      <a target="_blank" class="placeholder-badge" id="google" href="https://play.google.com/store/apps/details?id=es.evobanco.bancamovil&hl=es"></a>
    </div>
    <div class="badge-container-m">
      <a target="_blank" class="placeholder-badge" id="apple" href="https://itunes.apple.com/es/app/evo-banco-m%C3%B3vil/id542752481?mt=8"></a>
    </div>
    <div class="badge-container-m">
      <a target="_blank" class="placeholder-badge" id="google" href="https://play.google.com/store/apps/details?id=es.evobanco.bancamovil&hl=es"></a>
    </div>
  </div>

</div>
