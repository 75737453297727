import { Injectable } from '@angular/core';

@Injectable()
export class JsonService {

  constructor() { }

  getKeyFromJSON(objectJSON, key){
    let keys = key.split('.');
    
    //empty values control
    if (keys.length === 0) return objectJSON[key] || null;
    if (typeof objectJSON[keys[0]] === 'undefined') return null; 

    let tempObject = objectJSON[keys[0]];

    for (var i=1; i < keys.length; i++){
      
      if (!tempObject.hasOwnProperty(keys[i])) return null;
      tempObject = tempObject[keys[i]]
    }

    return tempObject;
  }

}
