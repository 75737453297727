import { Component, OnInit, forwardRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, FormControl } from '@angular/forms';

@Component({
  selector: 'custom-radio-buttons',
  templateUrl: './custom-radio-buttons.component.html',
  styleUrls: ['./custom-radio-buttons.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomRadioButtonsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomRadioButtonsComponent),
      multi: true,
    }
  ]
})
export class CustomRadioButtonsComponent implements ControlValueAccessor, Validator, AfterViewInit  {

  private initValue;
  private _value;
  public optionstreated;

  onChange: any = () => { };
  onTouched: any = () => { };
  private propagateChange = (_: any) => { };


  @Input()
  set options(value: Array<Object>) {
    this.optionstreated = value;
  }
  @Input('value')
  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }
  @Input('getPosition') getPosition;
  @Input('flexwrap') flexwrap;
  @Input('slimbuttons') slimButton;

  @Output('onPosition') onPosition = new EventEmitter();
  @Output('onValue') onValue = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) { }

  buttonClass() {
    if (this.flexwrap && !this.slimButton) {
      return 'w90'
    }else if (this.slimButton) {
      return 'slim-height'
    }else {
      return 'margin-none';
    }
  }

  setState(option) {
    this._value = option.id;
    this.eventsInfoContent(option);
    this.propagateChange(this._value);
  }

  setStateClass(option) {
    return this._value === option.id ? 'selected' : '';
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this._value = value;
    if (value.length > 0) {
      this.initValue = value;
      const optionradio = document.getElementById(value);
      if (optionradio != null) {
        const id = this._value,
              value = optionradio.textContent;
        this.setState({id, value});
      }
    }
  }


  eventsInfoContent(option) {
    const optionradio = document.getElementById(option.id);
    if (this.getPosition && optionradio != null) {
      const position = optionradio.getBoundingClientRect().left;
      this.onPosition.emit(position);
    }
  }

  ngAfterViewInit() {
    const optionradio = document.getElementById(this.initValue);
    if (optionradio != null) {
        this.eventsInfoContent(optionradio);
    }
  }

  public validate(c: FormControl) {
    return (c.value !== '') ? null : {
      validateError: {
        valid: false,
      },
    };
  }
}
