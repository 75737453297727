import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute, Params  } from '@angular/router';


@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnDestroy {
  public _showMe: boolean;
  public EdgeClass: boolean = this.checkNavigator();
  public param;
  public showHeader = true;
  public showFooter = true;

  constructor(private activatedRoute: ActivatedRoute) {

  }

  @Input()
  public set showMe(value: boolean) {
    if (value) {
      document.body.classList.add('pointer-events');
    } else {
      document.body.classList.remove('pointer-events');
    }
    this._showMe = value;
  }

  public get showMe(): boolean {
    return this._showMe;
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.origen) {
        this.param = params.origen.toLowerCase();
        if (this.param === "bmi") {
          this.showHeader = false;
          this.showFooter = false;
        }
      }
    });
   
  }

  ngOnDestroy() {
    document.body.classList.remove('pointer-events');
  }

  checkNavigator() {
    const UA = ["Edge", "MSIE"];
    const UA_IE11 = ["Mozilla/5.0", "Windows NT", "Trident/7.0", "rv:11.0", "like Gecko"];
    let count = 0;
    //Edge y Explorer 10
    for (var i = 0; i < UA.length; i++) {
      if (navigator.userAgent.indexOf(UA[i]) > -1) return true;
    }
    //Explorer 11
    for (var i = 0; i < UA_IE11.length; i++) {
      if (navigator.userAgent.indexOf(UA_IE11[i]) == -1) return false;
      count++;
    }
    if (count == UA_IE11.length) return true;
    return false;
  };
}
