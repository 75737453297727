import { Step } from "app/collections/step";

export var STEPS_REGISTER: Step[] = [
    {
        id: 0,
        selected: false,
        icon: "user-face",
        URL: 'datos-personal',
        subURLs: [],
        tooltip: "Datos Personales"
    },
    {
        id: 1,
        selected: false,
        icon: "brujula",
        URL: 'datos-contacto',
        subURLs: [],
        tooltip: "Datos de Contacto"
    },
    {
        id: 2,
        selected: false,
        icon: "bag",
        URL: 'datos-laboral',
        subURLs: [],
        tooltip: "Datos Laborales"
    },
    {
        id: 3,
        selected: false,
        icon: "phone",
        URL: 'confirmacion-otp',
        subURLs: [],
        tooltip: "Confirmación teléfono"
    },
    {
        id: 4,
        selected: false,
        icon: "fingerprint",
        URL: 'metodo-validacion',
        tooltip: "Método de identificación",
        subURLs: [
            "identificacion-video",
            "identificacion-iban"
        ]
    }
];

export var STEPS_REGISTER_OTP: Step[] = [
    {
        id: 0,
        selected: false,
        icon: "phone",
        URL: 'confirmacion-otp-primer-paso',
        subURLs: [],
        tooltip: "Confirmación teléfono"
    },
    {
        id: 1,
        selected: false,
        icon: "user-face",
        URL: 'datos-personal',
        subURLs: [],
        tooltip: "Datos Personales"
    },
    {
        id: 2,
        selected: false,
        icon: "brujula",
        URL: 'datos-contacto',
        subURLs: [],
        tooltip: "Datos de Contacto"
    },
    {
        id: 3,
        selected: false,
        icon: "bag",
        URL: 'datos-laboral',
        subURLs: [],
        tooltip: "Datos Laborales"
    },
    {
        id: 4,
        selected: false,
        icon: "fingerprint",
        URL: 'metodo-validacion',
        tooltip: "Método de identificación",
        subURLs: [
            "identificacion-video",
            "identificacion-iban"
        ]
    }
];

export var STEPS_REGISTER_OTP_ST: Step[] = [
    {
        id: 0,
        selected: false,
        icon: "user-face",
        URL: 'datos-personales',
        subURLs: [],
        tooltip: "Datos Personales"
    },
    {
        id: 1,
        selected: false,
        icon: "brujula",
        URL: 'datos-de-contacto',
        subURLs: [],
        tooltip: "Datos de Contacto"
    },
    {
        id: 2,
        selected: false,
        icon: "bag",
        URL: 'datos-laboral',
        subURLs: [],
        tooltip: "Datos Laborales"
    },
    {
        id: 3,
        selected: false,
        icon: "phone",
        URL: 'confirmacion-otp',
        subURLs: [],
        tooltip: "Confirmación teléfono"
    },
    {
        id: 4,
        selected: false,
        icon: "fingerprint",
        URL: 'metodo-validacion',
        tooltip: "Método de identificación",
        subURLs: [
            "identificacion-video",
            "identificacion-iban"
        ]
    }
];
