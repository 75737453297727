


import { Injectable } from '@angular/core';

@Injectable()
export class NestedObject {
  constructor() { }

  private isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  public nestedObjectAssign(target, ...sources) {
    if (!sources.length)
      return target;
    const source = sources.shift();
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key])
            Object.assign(target, { [key]: {} });
          this.nestedObjectAssign(target[key], source[key]);
        }
        else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return this.nestedObjectAssign(target, ...sources);
  }
}
