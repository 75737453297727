import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SsoResponse } from '../../collections/ssoResponse';
import { SsoVersions } from '../../collections/ssoVersions';
import { Params, Router } from '@angular/router';
import { UserInfo } from 'app/collections/userInfo';

@Injectable({
  providedIn: 'root'
})
export class ApiSsoService {

  private userInfo: UserInfo;

  constructor(
    private http: HttpClient, private router: Router
  ) { }

  public getUserInfo(): UserInfo {
    return this.userInfo;
  }
  
  public async executeSso(params: Params){
    const ssoVersion = this.validateSsoVersion(params);
    if(ssoVersion.Version === SsoVersions.SSO_VERSION_1){
      this.cleanSsoParams();
      return ssoVersion;
    }
    else if(ssoVersion.Version === SsoVersions.SSO_VERSION_2){
      const credentials = await this.postSso(ssoVersion.uuid, ssoVersion.usuarioBE).toPromise();
      ssoVersion.token = credentials.access_token;
      ssoVersion.refresh = credentials.refresh_token;
      this.userInfo = credentials.userinfo;
      this.cleanSsoParams();
      return ssoVersion;
    }
    else{
      console.log('Invalid SSO version')
      throw new Error('Invalid SSO version');
    }
  }

  private async cleanSsoParams(){
    /*const path = window.location.pathname;
    await this.router.navigate([path], {
      queryParams: {
        'uuid': null,
        'usuarioBE': null,
        'token': null,
        'refresh': null,
      },
      queryParamsHandling: 'merge'
    });*/
  }

  private validateSsoVersion(params: Params){
    if(!params || Object.keys(params).length === 0) {
      return null;
    }

    const token = params["token"];
    const refresh = params["refresh"];
    const uuid = params["uuid"];
    const usuarioBE = params["usuarioBE"];

    if (!this.isEmptyOrBlank(token) && !this.isEmptyOrBlank(refresh)){
      return {
        Version: SsoVersions.SSO_VERSION_1,
        token,
        refresh
      };
    } else if (!this.isEmptyOrBlank(uuid) && !this.isEmptyOrBlank(usuarioBE)){
      return {
        Version: SsoVersions.SSO_VERSION_2,
        uuid,
        usuarioBE
      };
    } else {
      return {
        Version: SsoVersions.SSO_VERSION_UNDEFINED
      };
    }
  }

   // POST /sso
   private postSso(uuid: string, usuarioBE: string): Observable<SsoResponse> {
    const body = new HttpParams()
    .set('tpbe_pass', uuid)
    .set('usuarioBE', usuarioBE);
    return this.http.post(`${environment.apiUrl}/evobanco/v1/sso`, body.toString(),{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept', 'application/json')
    }).pipe(
      map((response) => {
        return response as SsoResponse;
      })
    );
  }

  private isEmptyOrBlank(str) {
    return this.isEmpty(str) || this.isBlank(str);
  }

  private isEmpty(str) {
    return (!str || str.length === 0 );
  }

  private isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }
}