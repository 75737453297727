
    <div [class.active]="active"
      class="item text-center"
      (click)='goToInterest(slideObj["goTo"])'>
    <div class='img-container'>
      <ng-content></ng-content>
    </div>
    <div class='text'>
    <h6>{{slideObj['title']}}</h6>
    <p>{{slideObj['text']}}</p>
    </div>
    </div>
  