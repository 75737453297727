import { Injectable } from '@angular/core';
import { MicrosegConfigResponse, MicrosegurosConfigCapitales } from 'app/collections/microseg-config';
import { Region } from 'app/collections/region';
import { Sport } from 'app/collections/sports';
import { DecimalPipe } from '@angular/common';
import { CurrencyCustomPipe } from 'app/pipes/currency/currency-custom.pipe';
import { REGIONS } from 'app/resources/region.model';
import { CheckBalance } from 'app/collections/check-balance';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { HelperService } from 'app/services/helpers.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { AuthMicroService } from './auth-micro.service';

@Injectable()
export class HelpersMicroinsurancesService {

  public tipoOtros = "6";

  constructor(private confService: MicrosegConfigService,
              private authMicroService: AuthMicroService,
              private helpers: HelperService) { }

  fillBeneficiaries(listBeneficiaries: MicrosegConfigResponse) {
    let beneficiaries: Sport[] = [];
    listBeneficiaries.list.forEach((item, index) => {
      let beneficiary = Object.assign({
        id: item.elementValue,
        value: item.elementDesc
      });
      beneficiaries.push(beneficiary);
    });
    return beneficiaries;
  }


  fillConfigSettings(res) {
    let configs = {
      DEPORTE1: [],
      DURACION: [],
      AMBITO: [],
      REEMBOLSOGASTOS: []
    };
    for (let i = 0; i < res.length; i++) {
      if (res[i].list) {
        let memoryElem = res[i].list[0].config.split("PDF_")[1];
        for (let item of res[i].list) {
          let obj = null;
          if (memoryElem == "AMBITO") {
            obj = obj = Object.assign({
              id: item.elementValue,
              value: item.elementDesc,
              description: REGIONS[(item.elementValue) - 1].description,
            });
          } else {
            obj = Object.assign({
              id: item.elementValue,
              value: item.elementDesc
            });
          }
          configs[memoryElem].push(obj);
        }
      }

      if(res[i].error != null) {
        configs = res[i].error;
        break;
      }
    }
    return configs;
  }

  fillCapitalSettings(res) {
    let capitals = [];
    if(res.error != null) {
      capitals = res.error;
    } else {
      for (let item of res.listGlobalCapital) {
        let obj = Object.assign({
          id: item.amount,
          value: this.formatDecimal(item.amount),
          cfAmount: item.cfAmount, //Fallecimiento
          cipAmount: item.cipAmount, //Invalidez permanente
          crcAmount: item.crcAmount, // Responsabilidad Civil
          ciaAmount: item.ciaAmount, //Invalidez absoluta
          scope: item.scope
        });
        capitals.push(obj);
      }
    }
    return capitals;
  }

  formatDecimal(value) {
    return new DecimalPipe('es').transform(value) + "€";
  }

  formatIban(iban) {
    let finalString = '';
      finalString = iban.substr(0, 4)
                    + ' ' + iban.substr(4, 4)
                    + ' ' + iban.substr(8, 4)
                    + ' ' + iban.substr(12, 4)
                    + ' ' + iban.substr(16, 4)
                    + ' ' + iban.substr(20);
    return finalString;
  }

  getFieldsIban(iban) {
    let ibanTrim = iban.replace(/ /g,'');
    let ibanNumber = ibanTrim.substr(0, 4);
    let entity = ibanTrim.substr(4, 4);
    let office = ibanTrim.substr(8, 4);
    let controlDigit = ibanTrim.substr(12, 2);
    let accountNumber = ibanTrim.substr(14);

    let fields = {
      ibanNumber: ibanNumber,
      entity: entity,
      office: office,
      controlDigit: controlDigit,
      accountNumber: accountNumber
    };

    return fields;
  }

  getNameProduct(idProducto) {
    let name = '';
    switch(idProducto) {
      case ACCOUNTS.CICode:
        name = "Cuenta inteligente";
        break;
      case ACCOUNTS.CDCode:
        name = "Cuenta Dúo";
        break;
      case ACCOUNTS.CJCode:
        name = "Cuenta Joven";
        break;
      case ACCOUNTS.STCode:
        name = "Segundo Titular";
        break;
    }
    return name;
  }


  makeid() {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }


  parseAccounts(listaContratados, idIris, amount) {
    let accounts = [];
    let zipItems = [];
    let index = 0;
    for(let producto of listaContratados) {
      if(producto.idProducto != ACCOUNTS.MSCode && producto.identificador == '0311') {
        let account = {
          id: producto.idProducto ? producto.idProducto : this.makeid(),
          index: index,
          tipo: this.getNameProduct(producto.idProducto),
          iban: this.formatIban(producto.iban),
          disponible: false,
          condicion: producto.typeUser
        };
        accounts.push(account);
        let checkBalance: CheckBalance = Object.assign({
          iban : producto.iban,
          idIris: idIris,
          expense: amount
        });
        zipItems.push(this.confService.getCheckBalance(checkBalance));
        index++;
      }
    }
    return {
      accounts: accounts,
      zipItems: zipItems
    }
  }

  isThereSomeAccountActive(accounts) {
    const accountWithBalance = accounts.filter(account => {
      return account.disponible == true;
    });

    return accountWithBalance.length > 0;
  }


  formatField(value) {
    let label = "";
    label = "" + value;
    if(value < 10) {
      label = "0" + label;
    }
    return label;
  }

  prepareFormatOtherBeneficiary(beneficiario) {
    if(beneficiario != null) {
      return beneficiario.documento + "|" + beneficiario.nombre + " " + beneficiario.apellidos;
    }
    return beneficiario;
  }


  formatStartEffectDate(date:Date) {
    return date.getFullYear() + "-" +
      this.formatField(date.getMonth() + 1) + "-" +
      this.formatField(date.getDate()) + "T" +
      this.formatField(date.getHours()) + ":" +
      this.formatField(date.getMinutes()) + ":" +
      this.formatField(date.getSeconds());
  }


  formatDateWithTimeZone(date:Date, timezone="+01:00") {
    return date.getFullYear() + "-" +
      this.formatField(date.getMonth() + 1) + "-" +
      this.formatField(date.getDate()) + "T" +
      this.formatField(date.getHours()) + ":" +
      this.formatField(date.getMinutes()) + ":" +
      this.formatField(date.getSeconds())  +
      timezone;
  }


  getParentPathMicro(pathChild: string) {
    const microSession = JSON.parse(this.authMicroService.getMicroSession());
    if(microSession.bmi !== '') {
      return '/evo-protect/' + pathChild;
    }
    return '/microseguros/' + pathChild;
  }


}
