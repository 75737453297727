import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { ErrorService } from 'app/services/error.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HelperService } from 'app/services/helpers.service';
import { FormValidatorService } from 'app/services/form-validator.service';
import { ModalService } from 'app/services/modal.service';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { Simulador } from 'app/collections/simulador';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { zip } from 'rxjs/observable/zip';
import { MicrosegConfigResponse } from 'app/collections/microseg-config';
import { HelpersMicroinsurancesService } from 'app/services/helpers-microinsurances.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { AnalyticsService } from 'app/services/analytics.service';

@Component({
  selector: 'app-beneficiaries',
  animations: [
    trigger('shrinkOut', [
      transition(':enter', [
        style({ height: 0, overflow: 'hidden', opacity: 0 }),
        animate('.5s ease-in-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', overflow: 'hidden', opacity: 1 }),
        animate('.5s ease-in-out', style({ height: 0, opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './beneficiaries.component.html',
  styleUrls: ['./beneficiaries.component.scss'],
  providers: [ModalService]

})
export class BeneficiariesComponent implements OnInit {

  beneficiaryForm: FormGroup;
  otherContainer = false;
  beneficiaries = [];

  public modalError = 'modal-error';
  public msgError = "Revisa que hayas seleccionado un beneficiario.";

  private settingsMicroSession: Simulador = this.authService.getMicroSettingsSession();
  public loading = false;

  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    this.authService.deleteMicroSettingsSession();
  }

  constructor(private fb: FormBuilder,
    public errService: ErrorService,
    private evoValidator: FormValidatorService,
    public helperService: HelperService,
    private helpersMicro: HelpersMicroinsurancesService,
    private authService: AuthMicroService,
    private cdRef: ChangeDetectorRef,
    private confService: MicrosegConfigService,
    public modService: ModalService,
    private analytics: AnalyticsService) {
    this.createForm();
  }

  ngOnInit() {
    this.errService.mobileErrors(this.beneficiaryForm);
    this.loading = true;
    this.confService.getConfigLists('PDF_BENEFICIARIO').subscribe(res => {
      const listBeneficiaries: MicrosegConfigResponse = res;
      this.beneficiaries = this.helpersMicro.fillBeneficiaries(listBeneficiaries);
      this.loading = false;
      this.setFields();
    },
      error => {
        this.loading = false;
        this.errService.navToError();
      });

  }

  setFields() {
    let beneficiary = this.settingsMicroSession.beneficiary;
    if (beneficiary != null) {
      if (beneficiary.tipo == this.helpersMicro.tipoOtros) {
        this.otherContainer = true;
        this.helperService.updateFieldValue(this.beneficiaryForm, 'beneficiario', beneficiary.tipo);
        this.setRequiredOtherContainer(beneficiary.beneficiario.nombre, beneficiary.beneficiario.apellidos, beneficiary.beneficiario.documento);
      } else {
        this.helperService.updateFieldValue(this.beneficiaryForm, 'beneficiario', beneficiary.tipo);
      }
    }
  }

  private createForm() {
    this.beneficiaryForm = this.fb.group({
      beneficiario: ['', [Validators.required]],
      nombre: ['', [Validators.maxLength(45)]],
      apellidos: ['', [Validators.maxLength(45)]],
      numeroDocumento: ['', [Validators.maxLength(9)]]
    });

    this.formSubscribe();
  }

  formSubscribe() {
    this.beneficiaryForm.get("beneficiario").valueChanges.subscribe(beneficiario => {
      if (beneficiario === this.helpersMicro.tipoOtros) {
        this.otherContainer = true;
        this.helperService.resetFieldsState(this.beneficiaryForm, ['nombre', 'apellidos', 'numeroDocumento']);
      } else {
        this.otherContainer = false;
      }
      this.setRequiredOtherContainer('', '', '');

    });

    this.beneficiaryForm.valueChanges.subscribe(form => {
      this.errService.mobileErrors(this.beneficiaryForm);
    });
  }

  setRequiredOtherContainer(nombre, apellidos, documento) {

    if (this.otherContainer) {
      this.helperService.updateFieldValue(this.beneficiaryForm, 'nombre', nombre);
      this.helperService.updateFieldValidation(this.beneficiaryForm, 'nombre', [Validators.required, Validators.maxLength(45)]);

      this.helperService.updateFieldValue(this.beneficiaryForm, 'apellidos', apellidos);
      this.helperService.updateFieldValidation(this.beneficiaryForm, 'apellidos', [Validators.required, Validators.maxLength(45)]);

      this.helperService.updateFieldValue(this.beneficiaryForm, 'numeroDocumento', documento);
      this.helperService.updateFieldValidation(this.beneficiaryForm, 'numeroDocumento', [Validators.required, Validators.maxLength(9), this.evoValidator.validateID]);
    } else {
      this.helperService.updateFieldValue(this.beneficiaryForm, 'nombre');
      this.helperService.updateFieldValidation(this.beneficiaryForm, 'nombre', [Validators.maxLength(45)]);

      this.helperService.updateFieldValue(this.beneficiaryForm, 'apellidos');
      this.helperService.updateFieldValidation(this.beneficiaryForm, 'apellidos', [Validators.maxLength(45)]);

      this.helperService.updateFieldValue(this.beneficiaryForm, 'numeroDocumento');
      this.helperService.updateFieldValidation(this.beneficiaryForm, 'numeroDocumento', [Validators.maxLength(9)]);
    }
  }

  onSubmit() {
    this.helperService.updateFormStatus(this.beneficiaryForm);

    if (!this.beneficiaryForm.controls.beneficiario.valid) {
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.msgError)
      this.modService.open(this.modalError);
      window.scrollTo(0, 0);
      return false;
    }

    if (this.beneficiaryForm.valid) {
      const beneficiarioValue = this.beneficiaryForm.controls.beneficiario.value;
      if (beneficiarioValue === this.helpersMicro.tipoOtros) {
        this.settingsMicroSession.beneficiary = Object.assign({
          tipo: beneficiarioValue,
          beneficiario: {
            nombre: this.beneficiaryForm.controls.nombre.value,
            apellidos: this.beneficiaryForm.controls.apellidos.value,
            documento: this.beneficiaryForm.controls.numeroDocumento.value
          }
        });
      } else {
        this.settingsMicroSession.beneficiary = Object.assign({
          tipo: beneficiarioValue,
          beneficiario: null
        });
      }

      this.authService.setMicroSettingsSession(this.settingsMicroSession);
      this.helperService.navigateTo("/evo-protect/configura-cuenta");
    }

  }

}
