<loading [showMe]="loading"></loading>
<mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray">
</mobile-error-zone>

<modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal>

<div class="laborDetails">
  <div class="image" [ngClass]="{ 'young' : isYoungAccount }"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>DATOS LABORALES</h2>
      </legend>
    </div>
    <form [class]="isOneFieldForm" [formGroup]="laborDetailsForm"
      (keydown.enter)="$event.preventDefault()"
      (ngSubmit)="onSubmit()" novalidate>
      <div class="row">
        <div class="mobile-container">
          <dropdown   class="dropdown" 
                      id="situacionLaboral" 
                      name="situacionLaboral"
                      formControlName="situacionLaboral" 
                      [options]="situaciones"></dropdown>
          <!-- {{laborDetailsForm.controls.situacionLaboral.valid}} -->
        </div>
        <div class="mobile-container" *ngIf='isCuentaAjena'>
          <dropdown class="dropdown" 
                    id="tipoContrato" 
                    name="tipoContrato"
                    formControlName="tipoContrato" 
                    [options]="contracts">
          </dropdown>
          <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.tipoContrato, 'tipoContrato')">
            {{errService.desktopErrors(laborDetailsForm.controls.tipoContrato, 'tipoContrato')}}
          </div>
        </div>
        <div class="mobile-container hollow" *ngIf='isCuentaAjena'></div>
        <!--TODO esto es CNAE-->
        <div class="mobile-container" *ngIf='isAutonomo'>
          <!-- TODO autocomplete -->
          <dropdown class="dropdown" 
                    id="cnae" 
                    name="cnae"
                    formControlName="cnae" 
                    [options]="cnae" 
                    [autocomplete]="true" 
                    maxHeight="300px"></dropdown>
          <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.cnae, 'cnae')">
            {{errService.desktopErrors(laborDetailsForm.controls.cnae, 'cnae')}}
          </div>
        </div>
        <div class="mobile-container hollow" *ngIf='isAutonomo'></div>
      </div>
      <div class="row"  *ngIf='isAutonomo'>
        <div class="mobile-container">
          <!--TODO esto es CNO-->
          <dropdown class="dropdown" 
                    id="profesionAutonomo" 
                    name="profesionAutonomo"
                    formControlName="profesion" 
                    [options]="profesion" 
                    maxHeight="300px">
          </dropdown>
          <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.profesion, 'profesion')">
            {{errService.desktopErrors(laborDetailsForm.controls.profesion, 'profesion')}}
          </div>
        </div>
        <div class="mobile-container">
          <dropdown class="dropdown" 
                    id="tipoActividadAutonomo" 
                    name="tipoActividad"
                    formControlName="tipoActividad" 
                    [nameSelect]='true' 
                    [options]="tipoActividadProfesional" 
                    [disabled]="noSelectedProfession">
          </dropdown>
          <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.tipoActividad, 'tipoActividad')">
            {{errService.desktopErrors(laborDetailsForm.controls.tipoActividad, 'tipoActividad')}}
          </div>
        </div>
        <div class="mobile-container hollow"></div>
      </div>
      <div class="row"  *ngIf='isAutonomo'>
        <div class="mobile-container">
          <label for="autonomoFecha">Fecha de alta como autónomo</label>
          <input date-input type="tel" class="form-control" id="autonomoFecha"
          maxlength="10"
          name="autonomoFecha" required placeholder="DD/MM/YYYY" date-input formControlName="fechaAltaAutonomo" 
          autocomplete="off">
          <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.fechaAltaAutonomo, 'fechaAltaAutonomo')">
            {{errService.desktopErrors(laborDetailsForm.controls.fechaAltaAutonomo, 'fechaAltaAutonomo')}}
          </div>
        </div>
        <div class="mobile-container hollow"></div>
        <div class="mobile-container hollow"></div>
      </div>
      <div class="row" *ngIf='isCuentaAjena'>
        <div class="mobile-container">
          <dropdown-custom  class="dropdown" 
                            id="profesion" 
                            name="profesion" 
                            [autocomplete]="true"
                            formControlName="profesion" 
                            [nameSelect]='true'
                            [isCorrect]="isCorrectDrowDown(errService.desktopErrors(laborDetailsForm.controls.profesion, 'profesion'))"
                            [options]="profesion" 
                            placeholder="Introduce las letras" 
                            tooltip="Introduce las letras de la profesión para seleccionar entre las distintas opciones"
                            [buttonClass]="true" 
                            maxHeight="300px"></dropdown-custom>
          <div  class="error-text" 
                *ngIf="errService.desktopErrors(laborDetailsForm.controls.profesion, 'profesion')">
            {{errService.desktopErrors(laborDetailsForm.controls.profesion, 'profesion')}}
          </div>
        </div>
        <div class="mobile-container">
          <dropdown-custom  class="dropdown" 
                            id="tipoActividad" 
                            name="tipoActividad" 
                            [autocomplete]="true"
                            formControlName="tipoActividad"
                            [isCorrect]="isCorrectDrowDown(errService.desktopErrors(laborDetailsForm.controls.tipoActividad, 'tipoActividad'))"
                            [nameSelect]='true' 
                            [options]="tipoActividadProfesional" 
                            [disabled]="noSelectedProfession" 
                            placeholder="Introduce el código CNO o las letras" 
                            tooltip="Introduce los primeros dígitos del código CNO o las letras de la actividad económica para seleccionar entre las distintas opciones"
                            [buttonClass]="true" 
                            maxHeight="300px"></dropdown-custom>
          <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.tipoActividad, 'tipoActividad')">
            {{errService.desktopErrors(laborDetailsForm.controls.tipoActividad, 'tipoActividad')}}
          </div>
        </div>
      </div>
      <div class="row" *ngIf='isCuentaAjena'>
        <div class="mobile-container">
            
        </div>
        <div class="mobile-container">
            
        </div>
      </div>
      <div class="row" *ngIf='isCuentaAjena'>
        <div class="mobile-container">
          <dropdown  class="dropdown" 
                     id="duracionTrabajo" 
                     name="duracionTrabajo"
                     formControlName="duracionTrabajo" 
                     [options]="years">
          </dropdown>
          <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.duracionTrabajo, 'duracionTrabajo')">
            {{errService.desktopErrors(laborDetailsForm.controls.duracionTrabajo, 'duracionTrabajo')}}
          </div>
        </div>
        <div class="mobile-container">
          <input  type="number" 
                  (ngModelChange)="maxAndminLengthCheck($event,'ingresosMensuales',0,99999)"
                  class="form-control" 
                  id="ingresosMensuales" 
                  name="ingresosMensuales"
                  required 
                  placeholder="Ingresos mensuales netos" 
                  formControlName="ingresosMensuales" 
                  autocomplete="off">

          <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.ingresosMensuales, 'ingresosMensuales')">
            {{errService.desktopErrors(laborDetailsForm.controls.ingresosMensuales, 'ingresosMensuales')}}
          </div>
        </div>
        <div class="mobile-container">
          <input  type="number"
                  (ngModelChange)="maxAndminLengthCheck($event,'numeroPagas',0,99)"
                  class="form-control" 
                  id="numeroPagas" 
                  name="numeroPagas"
                  required 
                  placeholder="Número de pagas"
                  formControlName="numeroPagas" 
                  autocomplete="off">
          <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.numeroPagas, 'numeroPagas')">
            {{errService.desktopErrors(laborDetailsForm.controls.numeroPagas, 'numeroPagas')}}
          </div>
        </div>
      </div>
      
      <!-- FUNCIONARIO INICIO -->
   
  <div class="mobile-container hollow" *ngIf='isFuncionario'></div>
  <div class="row"  *ngIf='isFuncionario'>
    <div class="mobile-container">
      <!--TODO esto es CNO-->
      <dropdown   class="dropdown" 
                  id="profesionAutonomo" 
                  name="profesionAutonomo"
                  formControlName="profesion" 
                  [options]="profesion" 
                  maxHeight="300px">
      </dropdown>
      <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.profesion, 'profesion')">
          {{errService.desktopErrors(laborDetailsForm.controls.profesion, 'profesion')}}
      </div>
    </div>
    <div class="mobile-container">
      <dropdown   class="dropdown" 
                  id="tipoActividadAutonomo" 
                  name="tipoActividad"
                  formControlName="tipoActividad" 
                  [nameSelect]='true' 
                  [options]="tipoActividadProfesional" 
                  [disabled]="noSelectedProfession" 
                  maxHeight="300px">
      </dropdown>
      <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.tipoActividad, 'tipoActividad')">
          {{errService.desktopErrors(laborDetailsForm.controls.tipoActividad, 'tipoActividad')}}
      </div>
    </div>
    <div class="mobile-container hollow"></div>
  </div>
  <div class="row"  *ngIf='isFuncionario'>
    <div class="mobile-container">
      <dropdown   class="dropdown" 
                  id="duracionTrabajo" 
                  name="duracionTrabajo"
                  formControlName="duracionTrabajo" 
                  [options]="years">
      </dropdown>
      <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.duracionTrabajo, 'duracionTrabajo')">
          {{errService.desktopErrors(laborDetailsForm.controls.duracionTrabajo, 'duracionTrabajo')}}
      </div>
    </div>
    <div class="mobile-container hollow"></div>
    <div class="mobile-container hollow"></div>
  </div>
   
      <!-- FUNCIONARIO FIN -->

      <!-- JubiladoPensionanistaRentista INICIO -->
      <div class="row" *ngIf='isJubiladoPensionanistaRentista || isFuncionario || isAutonomo'>
          <div class="mobile-container">
            <input  type="number" 
                    class="form-control" 
                    id="ingresosMensuales" 
                    name="ingresosMensuales"
                    (ngModelChange)="maxAndminLengthCheck($event,'ingresosMensuales',0,99999)"
                    placeholder="Ingresos mensuales netos" 
                    formControlName="ingresosMensuales" 
                    autocomplete="off">
                    <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.ingresosMensuales, 'ingresosMensuales')">
                      {{errService.desktopErrors(laborDetailsForm.controls.ingresosMensuales, 'ingresosMensuales')}}
                    </div>
          </div>
          <div class="mobile-container" *ngIf='!isAutonomo'>
              <input  type="number" 
                      class="form-control" 
                      id="numeroPagas" 
                      name="numeroPagas"
                      required
                      (ngModelChange)="maxAndminLengthCheck($event,'numeroPagas',0,99)"  
                      placeholder="Número de pagas"
                      formControlName="numeroPagas" 
                      autocomplete="off">
              <div class="error-text" *ngIf="errService.desktopErrors(laborDetailsForm.controls.numeroPagas, 'numeroPagas')">
                {{errService.desktopErrors(laborDetailsForm.controls.numeroPagas, 'numeroPagas')}}
              </div>
            </div>
      </div>
      <!-- JubiladoPensionanistaRentista FIN -->

      <div class="btn-group" [ngClass]='isOneField'
      *ngIf='laborDetailsForm.controls.situacionLaboral.value !== null'>
        <button type="submit" class="btn-common btn-common_fix_bold">CONTINUAR</button>
      </div>
    </form>
    <!-- {{laborDetailsForm.controls.situacionLaboral.value | json}} -->
    <!-- formulario valido : {{laborDetailsForm.valid}}
  {{laborDetailsForm.value | json}} -->
  </div>
</div>
