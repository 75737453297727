import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NOTIFICATIONS } from '../../../resources/notifications.model'
import { Notification } from '../../../collections/notification'
import { AnalyticsService } from '../../../services/analytics.service';


@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit, OnDestroy {

  notification: Notification;
  notifications = NOTIFICATIONS;
  title: string;
  breadcrumbNav = [{ name: 'INICIO', link: '/perfil' }, { name: 'NOTIFICACIÓN', link: '/notificacion' }];
  private paramSubscription: any;

  constructor(private analytics: AnalyticsService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.getNotificationId();
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }

  getNotificationId() {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.title = params['title'];
      this.getNotification();
    })
  }

  getNotification() {
    const filteredNotification = this.notifications.filter(notification => {
      return notification['title'] === this.title
    })
    this.notification = filteredNotification[0];

  }

}
