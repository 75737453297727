<div class="page" [ngClass]="isProductoInv ? 'page-cinv':'page'">
  <div class="content">
    <div class="message-row" [ngClass]="(message.title=='Ya casi está')?'responsive-casi-esta':'responsive-todo-listo'">
      <img class="image" alt="icon" [src]="message.image">
    </div>
    <div class="message-row">
      <h2 class="title">{{message.title}}</h2>
    </div>
    <div class="message-row">
      <div class="description col-10 col-md-6" [innerHtml]="message.description"></div>
    </div>
    <div *ngIf="message.btnPrimary" class="message-row">
      <button class="btn-common col-10 col-md-6 p-0" [innerHtml] ="message.btnPrimary" (click)="redirect(message.btnPrimaryAction)"></button>
      <div *ngIf="!message.btnSecondary" [ngClass]="(message.title=='Ya casi está')?'margin-casi-esta':'margin'"></div>
    </div>
    <div *ngIf="message.btnSecondary" class="message-row">
      <button class="btn-common col-10 col-md-6 p-0" (click)="redirect(message.btnSecondaryAction)">{{message.btnSecondary}}</button>
    </div>
    <div *ngIf="message.btnTertiary" class="message-row">
      <button class="btn-common col-10 col-md-6 p-0" (click)="redirect(message.btnTertiaryAction)">{{message.btnTertiary}}</button>
    </div>
    <div>
    <p *ngIf="message.footer && message.btnSecondary" [innerHtml]="message.footer" style="font-size:12px;"></p>
    <p *ngIf="!message.btnSecondary" [innerHtml]="message.footer" style="font-size:12px;"></p>
    </div>
  </div>
</div>
