<modal [modalTitle]="'Call me'" [blocking]='false' [modalId]='callMeModal'
class='modal exception-modal' >
  <div class="image-modal"></div>
  <div class="false-container">
    <h2>{{modalTitle}}</h2>
    <div *ngIf='!isPhoneSent'>
    <p>
      Déjanos un teléfono para que uno de nuestros agentes se pueda poner en contacto contigo
      y resolver tus dudas.
    </p>
    <form #bysidecarForm="ngForm" (ngSubmit)="callUser(bysidecarForm)" novalidate>
      <div class="equal-buttons">
        <input  placeholder="Tu nombre"
        autocomplete="off"
        type="text"
        name="name" ngModel #userName="ngModel">
        <input placeholder="Tu teléfono"
        autocomplete="off"
        type="text"
        name="phone" pattern="^[6-7][0-9]{8}|8{9}"
        maxlength="9" required
        ngModel #phoneNumber="ngModel" >
        <div class="error-text" *ngIf="errService.desktopErrors(bysidecarForm.controls.phone, 'telefonoMovil')">
          {{errService.desktopErrors(bysidecarForm.controls.phone, 'telefonoMovil')}}
        </div>
      </div>
      <div class="equal-buttons friendly">
          <button type="submit" class="btn-common btn-common_fix_bold" name="button1">
            Enviar
          </button>
      </div>
    </form>
  </div>
  <p *ngIf='isPhoneSent' class="invalid-text">
    Uno de nuestros agentes se pondrá en contacto contigo para resolver tus dudas.
  </p>

    <span class="info-text">O si lo prefieres, llámanos al <a class="link-blue" href="tel:910900900">910 900 900</a></span>
  </div>
</modal>


<header id="header">
  <h1 class="logo desktop"> </h1>
  <div class="mobile">
    <h1 class="evo responsive-logo"></h1>
    <div class="phone" *ngIf='(mobilePhone !== 0 && mobilePhone) && !isYoungAccount && !isCampanyaCaptacion'><i class="phone-image"></i><span class="phone-text pink"><a (click)='informAnalytics()' href='tel:{{mobilePhone}}'>LLÁMANOS</a></span></div>
    <div class="title" [ngClass]='(!mobilePhone || mobilePhone === 0 || isYoungAccount) ? "bottom-margin":""'><a class="title-text" [ngClass]="''">{{ getTitleResponsive() }}</a></div>
  </div>
  <div class="desktop" >
    <div class="title" [ngClass]='(!mobilePhone || mobilePhone === 0 || isYoungAccount) ? "bottom-margin":""'><a class="title-text" [ngClass]="''">{{ getTitleResponsive() }}</a></div>
    <div class="phone" *ngIf='((mobilePhone !== 0 && mobilePhone) && !isYoungAccount && !isCampanyaCaptacion)'><span class="phone-text pink">LLÁMANOS {{desktopPhone}}</span></div>
    <div class="access" *ngIf='((mobilePhone !== 0 && mobilePhone) && !isYoungAccount && !isCampanyaCaptacion)' (click)="openModal(); analytics.triggerInteractionData('C2C', 'Pop up abierto por clic')"><span class="phone-text" >Te llamamos</span>  </div>
  </div>
</header>
