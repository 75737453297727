<loading [showMe]="loading"></loading>
<div class="more-25">
  <div class="image young"></div>
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <span class="desktop main-icon evo error-computer"></span>
        <h2>TIENES MÁS DE 25</h2>
        <h3>LA CUENTA JOVEN ES PARA MENORES 25</h3>
      </legend>
    </div>
    <div class="row">
      <p>Si tienes 25 años o más, tu producto es la CUENTA INTELIGENTE, con muchas ventajas y sin límite de edad.</p>
    </div>
    <div class="btn-group">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="gotoCI()">QUIERO UNA CUENTA INTELIGENTE</button>
    </div>
  </div>
</div>
