export class User {
  persona: {
    nombreCliente: string;
    apellido1Cliente: string;
    apellido2Cliente: string;
    idExterno: string;
    codigoIdExterno: string;
    id_cliente_EVO: string;
    fechaNacimiento: string;
    direccion: {
      nombreVia: string;
      nombreProvinciaDomicilio: string;
      nombreLocalidad: string;
      domicilioPuerta: string;
      domicilioPortal: string;
      domicilioPlanta: string;
      domicilioNumero: string;
      domicilioEscalera: string;
      codigoProvinciaDomicilio: string;
      codigoPaisResidencia: string;
      codigoDomicilioTipoVia: string;
    };
  };
  listaContratados: [
    {
      nombreProducto: string;
      idProducto: string;
      productos: [
        {
          nombreGrupo: string,
          iban: string
        }
      ];
      segundoTitular: {
        nombreCliente: string;
        apellido1Cliente: string;
        apellido2Cliente: string;
        codigoIdExterno: string;
        idExterno: string;
        id_cliente_EVO: string;
      }
    }
  ];
  listaPendientes: [
    {
      nombreProducto: string;
      idProducto: string;
    }
  ];
  listaParaContratar: [
    {
      nombreProducto: string;
      idProducto: string;
    }
  ];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

// {
//   "persona": {
//     "nombreCliente": "MARIA BELEN",
//       "idExterno": "83752195H",
//         "id_cliente_EVO": "IDE-00005040",
//           "direccion": {
//       "nombreVia": "CALLE DE ANABEL SEGURA",
//         "nombreProvinciaDomicilio": "MADRID",
//           "nombreLocalidad": "ALCOBENDAS",
//             "domicilioPuerta": null,
//               "domicilioPortal": null,
//                 "domicilioPlanta": null,
//                   "domicilioNumero": 16,
//                     "domicilioEscalera": null,
//                       "codigoProvinciaDomicilio": 28,
//                         "codigoPaisResidencia": "011",
//                           "codigoDomicilioTipoVia": "CL"
//     },
//     "codigoIdExterno": "51",
//       "apellido2Cliente": "JUANIS",
//         "apellido1Cliente": "MARTINEZ"
//   },
//   "listaPendientes": [],
//     "listaParaContratar": [
//       {
//         "nombreProducto": "CUENTA INTELIGENTE",
//         "idProducto": "006"
//       },
//       {
//         "nombreProducto": "CUENTA DUO",
//         "idProducto": "007"
//       },
//       {
//         "nombreProducto": "CUENTA JOVEN",
//         "idProducto": "008"
//       },
//       {
//         "nombreProducto": "MICROSEGUROS",
//         "idProducto": "998"
//       },
//       {
//         "nombreProducto": "SEGUNDO TITULAR",
//         "idProducto": "999"
//       }
//     ],
//       "listaContratados": []
// }
