<loading [showMe]="loading"></loading>
<div-show [showMe]="showError" [nameError]="nameError"></div-show>
<div class="banner banner-error-formato" *ngIf="incorrectFormatBanner && !hiddeBanner">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBanner()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo ico-warning-white" style="font-size: 28px;"></i>
      </span>
      <span class="banner-message">
        {{incorrectText}}
      </span>
    </div>
  </div>
</div>

<div class="banner banner-todo-ok" *ngIf="showAllOk && !isMobile()">
    <div class="banner-content">
      <div class="evo close banner-close" (click)="hideBannerNotifyOk()">
      </div>
      <div class="message-container">
        <span class="banner-icon">
          <i class="evo icon-todo-ok"></i>
        </span>
        <span class="banner-message">
          ¡Todo en orden! Tus datos han sido modificados con éxito.
        </span>
      </div>
    </div>
  </div>

  <div class="banner banner-narrow banner-todo-ok" [ngClass]='!showHeader? "banner-bmi" : ""' *ngIf="showAllOk && isMobile()">
    <div class="banner-content">
      
      <div class="message-container">
        <span class="banner-icon">
          <i class="evo icon-todo-ok"></i>
        </span>
        <span class="banner-message">
          ¡Todo en orden! Tus datos han sido modificados con éxito.
        </span>
      </div>
      <div class="evo close banner-close" (click)="hideBannerNotifyOk()">
      </div>
    </div>
  </div>


<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<modal [modalTitle]="'documentDetail'" [blocking]='false' [modalId]='detailModal'>
  <div class="container">
    <div class="center-image">
      <img *ngIf='image[documentSide]' src="{{image[documentSide]}}" alt="">
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]='friendlyModal' class='modal'>
  <div class="image-modal"></div>
  <div class="false-container">
    <h2>{{friendlyTitle}}</h2>
    <p>{{friendlyMessage}}</p>
  </div>
</modal>

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='incorrectFormatModal' class='modal'>
  <div class="container">
    <h2>{{incorrectTitle}}</h2>
    <p class="invalid-text">{{incorrectText}}</p>
  </div>
</modal>

<section class="c-header" *ngIf="showHeader">
  <div class="responsive-header">
    <div  class="backbutton" (click)="botonVolver()">
      <i class="evo flecha"></i>
    </div>
    <div class="page-title">
      <span>Documentación</span>
    </div>
    <div class="closebutton">
      <i class="evo close"></i>
    </div>
  </div>
</section>
<!--
<section  *ngIf="!showHeader">
  <div class="responsive-header">
  
    <div class="page-title">
      <span>Documentación</span>
    </div>
    
  </div>
</section>
-->
<form class="form" class="form" action="">
    
  <div class="main-content">
     <!-- Documentación  -->
     <section class="info info_noborder">
      <p class="info-title">ADJUNTA TU JUSTIFICANTE DE INGRESOS</p>
      <p class="info-description">Dependiendo de tu situación profesional adjunta la documentación necesaria:  </p>
      <ul class="info-list">
        <li class="info-list-item">
          <span class="title">Empleado por cuenta ajena:</span>
           Última nómina.
        </li>
        <li class="info-list-item">
            <span class="title">Autónomo: </span>
            Última declaración IRPF.
        </li>
        <li class="info-list-item">
            <span class="title">Jubilado/pensionista:</span>
            Último justificante de ingresos para pensionistas/jubilados.
        </li>
      </ul>
    </section>
    <!--
    <section class="upload">
        <p class="upload-title">JUSTIFICANTE</p>
        <div class="upload-documents">
          <i class="evo subir-foto upload-documents-icon"></i>
          <p class="upload-documents-info">Escoge  una imagen o arrastra un archivo</p>
        </div>
        <div *ngIf='upload.front' class="row upload">
          <div class="image-container" (click)="openDetail(); documentSide = 'front'">
            <img class="preview" *ngIf='image.front' src="{{image.front}}" alt="">
          </div>
          <img class="lupa" src="./assets/images/lupa.png" alt="" (click)="openDetail(); documentSide = 'front'">
          <div class="flex-edit">
            <label for="editFront" class="edit-back">
              <p (click)='resetFrontImage()' class="edit-back__text">
                Volver a subir
              </p>
              <input class="transparent" (change)='updateFrontImage($event)' type="file" id="editFront" #back />
            </label>
          </div>
        </div>
    </section>-->
    <div class="upload-container">
        <div class="upload-box">
          <p class="header-2">
          JUSTIFICANTE
          </p>
          <div *ngIf='upload.front' class="row upload">
            <div class="image-container" (click)="openDetail(); documentSide = 'front'">
              <img class="preview" *ngIf='image.front' src="{{image.front}}" alt="">
            </div>
            <img class="lupa" src="./assets/images/lupa.png" alt="" (click)="openDetail(); documentSide = 'front'">
            <div class="flex-edit">
              <label for="editFront" class="edit-back">
                <p (click)='resetFrontImage()' class="edit-back__text">
                  Volver a subir
                </p>
                <input class="transparent" (change)='updateFrontImage($event)' type="file" id="editFront" #back />
              </label>
            </div>
          </div>
          <div *ngIf='!upload.front' class="row">
            <label for="frontFile" class="dni-upload-width">
              <div drag id="front" (filesChangeEmitter)="onFilesChange($event)" class='draggable'>
                <div class="drag-container">
                  <input class="transparent" (change)='updateFrontImage($event)' type="file" id="frontFile" #front />
                  <i class="evo subir-foto" style="font-size: 50px;"></i>
                  <p>{{uploadImageFirstOptionText}}</p>
                  <p>{{uploadImageSecondOptionText}}</p>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    <div class="btn-group_fix">
        <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="onSubmit()" [disabled]="!activeContinuar">
          Continuar
        </button>
      </div>
  </div>
</form>
