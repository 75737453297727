// export class Persona {
//   idExterno: number;
//   email: string = '';
//   telefonoMovil: string = '';
//   complete: boolean = false;

//   constructor(values: Object = {}) {
//     Object.assign(this, values);
//   }
// }

export class Persona {

  canal: string = "desktop";
  estado: string = "registro";
  codigoEntidad: string;
  complete: boolean = false;
  producto: "fullonline";
  id: number;
  consentimientoTGSS = false;
  flujoOtpPrimerPaso = false;
  activeOtpFirstStep = false;
  abandono = false;
  indConsentimientoTratamientoTipoA = false;
  indConsentimientoTipoA = false;
  indPerfiladoCliente = false;
  indPoliticaPrivacidad = false;
  indCesiEmpGrupo = false;
  origenPromocion: string;
  proveedor: string;
  retorno: string;
  datosPersonales: {
    documento: string;
    oficinaCliente: string; // 2000 para dev, 0806 para produccion
    telefonoMovil: string;
    datosCorrectos: Boolean;
    dirección: string;
    nombreCliente: string;
    apellido1Cliente: string;
    apellido2Cliente: string;
    codigoIdExterno: string;
    idExterno: string;
    fechaCaducidadDocumento: string;
    fechaNacimiento: string;
    codigoPaisNacimiento: string;
    paisNacionalidad: string;
    paisResidencia: string;
    anioResidPobla: string;
    aniosResidPais: string;
    nombreLocalidadNacimiento: string;
    codigoProvincia: string;
    nombreProvinciaNacimiento: string;
    sexo: string;
    codigoEstadoCivil: string;
    miembrosUnidadFamiliar: string;
    numeroHijos: string;
    codigoRegimenEconomico: string;
    telefono: string;
    email: string;
    id_cliente_EVO: string;
    paisResidenciaEsp: string;
    password: string;
  };
  datosDomicilio:  {
    codigoProvinciaDomicilio: string;
    nombreProvinciaDomicilio: string;
    nombreLocalidad: string;
    codigoDomicilioTipoVia: string;
    nombreVia: string;
    domicilioNumero: string;
    domicilioPortal: string;
    domicilioEscalera: string;
    domicilioPlanta: string;
    domicilioPuerta: string;
    codigoPaisResidencia: string;
    anioAdquisicion: string;
    codigoPostal: string;
    parcela: string;
    fase: string;
    bloque: string;
    fechaAltaAlquiler: string;
    cuotaAlquilerMensual: string;
  };
  datosSocioEco:  {
    regimenOcupacion: string;
    codigoSituacionLaboral: string;
    idioma: string;
    codigoRazonAltaPersona: string;
    codigoCNAE: string;
    profesion: string;
    codigoCNO: string;
    fechaAltaAutonomo: string;
    fechaAntiguedadLaboral: string;
    aniosGlobalesTrabajados: string;
    cifEmpresa: string;
    nombreEmpresa: string;
    telefonoEmpresa: string;
    cargo: string;
    fechaAntiguaCargo: string;
    nivelSalarial: string;
    codigoContrato: string;
    fechaFinContrato: string;
    duracion: string;
    jornada: string;
    continuidad: string;
    dedicacion: string;
    tipoDeclaracion: string;
    declaracionIVAUltimoEjercicio: string;
    beneficioEjercicioAnterior: string;
    beneficioEjercicioActual: string;
    obligacionesFiscales: boolean;
    IBAN: string;
    ingresosMensuales: string;
    numeroPagas: string;
    situacionVivienda: string;
    salarioMaximoAnual: string;
    productos: [{
      tipoPack: string;
      identificador: string;
      iban: string;
      acuerdo: string;
      relacionAcuerdoPersona: string;
    }];
  };
  otrosDatos: {
    indPubliPe: Boolean;
    idPeInversis: string;
    tipoIdentificacion: string;
  };
  confirma: {
    idConfirma: string;
    resultadoConfirma: string;
    image1: string;
    image2: string;
  };
  logalty: {
    logaltySignatureId: string;
    signatureId: string;
    codProductoContratado: string; // : "006"; //006 = Cuenta Inteligente 007 = Cuenta Base/Duo 008 = Cuenta Joven 998 = Alta Póliza Microseguros 999 = Segundo Titular
    isSecondSigner: boolean;
  };
  promocion = false;
  electronicID: {
    electronicId: string;
    descripcionError: string;
  };

  Microseguros: {
    emailMicroseguros: string;
  };
  datosPrimerTitular: {
    idExterno: string
    nombreCliente: string;
    apellido1Cliente: string;
    apellido2Cliente: string;
    email: string;
    telefonoMovil: string;
    sexo: string;
    codigoIdExterno: string;
    id_cliente_EVO: string;
    paisResidencia: string;
    signatureId: string;
    productos: string;
  }

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
