// modules
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';


// custom modules
import { SharedModule } from '../shared/shared.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { MicroinsurancesModule } from './microinsurances/microinsurances.module';
import { LoadingCustomComponent } from 'app/components/loading-custom/loading-custom.component';
import { CurtainComponent } from 'app/components/curtain/curtain.component';
import { ErrorAltavozComponent } from 'app/components/error-altavoz/error-altavoz.component';
import { DivShowComponent } from 'app/components/div-show/div-show.component';

// services
import { AuthGuardUser } from '../../services/auth-guard-user.service';
import { AuthUserService } from '../../services/auth-user.service';
import { ApiUserService } from '../../services/api/api.user.service';
import { ApiSecondHolderService } from '../../services/api/api.second-holder.service';
import { SecondHolderService} from '../../services/second-holder.service';
import { SecurityService } from '../../services/security-registration.service';
import { ApiSecurityService } from '../../services/api/api.security.service';

// components
import { ProfileScreenComponent } from './profile-screen/profile-screen.component';
import { LoggedProfileComponent } from './logged-profile/logged-profile.component';
import { LoggedSegundoTitularComponent } from './logged-segundoTitular/logged-segundoTitular.component';
import { LoginComponent } from './login/login.component';
import { LoginAmpliacionComponent } from './login-ampliacion/login-ampliacion.component';
import { LoginCuentaInvitacionComponent } from './login-invitacion/login-invitacion.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { ClientAdvantagesComponent } from './client-advantages/client-advantages.component';
import { InfoContentComponent } from './info-content/info-content.component';
import { MyProductsComponent } from './my-products/my-products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { UpdateDniComponent } from 'app/modules/profile/update-dni/update-dni.component';
import { UpdateDniSpecsComponent } from 'app/modules/profile/update-dni-specs/update-dni-specs.component';
import { InfoDuoAccountComponent } from './info-duo-account/info-duo-account.component';
import { InfoInvestmentAccountComponent } from './info-investment-account/info-investment-account.component';
import { ExtendLimitStep1Component } from './extend-limit-step1/extend-limit-step1.component';
import { ExtendLimitSelectCardComponent } from './extend-limit-selectcard/extend-limit-selectcard.component';
import { ConfigInvestmentAccountComponent } from './config-investment-account/config-investment-account.component';
import { ObligationsInvestmentAccountComponent } from './obligations-investment-account/obligations-investment-account.component';
import { DocumentationInvestmentAccountComponent } from './documentation-investment-account/documentation-investment-account.component';
import { ViewInvestmentAccountComponent } from './view-investment-account/view-investment-account.component';
import { FinalInvestmentAccountComponent } from './final-investment-account/final-investment-account.component';
import { StatesComponent } from './states/states.component';
import { MyProductsService } from 'app/services/my-products.service';
import { KeyboardComponent } from 'app/components/numeric-keyboard/keyboard.component';
import { MyInterestService } from 'app/services/my-interest.service';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ConfirmOtpProfileComponent } from 'app/modules/profile/confirm-otp-profile/confirm-otp-profile.component';
import { OtpDetailsProfileComponent } from 'app/modules/profile/otp-details-profile/otp-details-profile.component';
import { InfoMyProfileComponent } from 'app/modules/profile/info-my-profile/info-my-profile.component';
import { SelectCardComponent } from './select-card/select-card.component';
import { ConfirmaLimitComponent } from './confirma-limit/confirma-limit.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { AmpliacionService } from '../../services/ampliacion.service';
import { LogaltyAmpliacionComponent } from './logalty-ampliacion/logalty-ampliacion.component';
import { LogaltyLoadAmpliacionComponent} from 'app/modules/profile/logalty-load-ampliacion/logalty-load-ampliacion.component';
import { PdfViewerComponent } from 'app/components/pdf/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SegundoTitularFinishComponent } from './segundoTitular-finish/segundoTitular-finish.component';
import { SessionExpiredComponent } from 'app/components/session-expired/session-expired.component';
import { MessageScreenComponent } from 'app/components/message-screen/message-screen.component';
import { CinvHeaderComponent } from 'app/components/cinv-header/cinv-header.component';
import { ErrorBatchComponent } from 'app/components/error-batch/error-batch.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProfileRoutingModule,
    SharedModule,
    MicroinsurancesModule,
    PdfViewerModule
  ],
  declarations: [
    ProfileScreenComponent,
    LoggedProfileComponent,
    LoggedSegundoTitularComponent,
    NotificationsComponent,
    NotificationDetailsComponent,
    LoginComponent,
    LoginAmpliacionComponent,
    LoginCuentaInvitacionComponent,
    ClientAdvantagesComponent,
    MyProductsComponent,
    ProductDetailsComponent,
    UpdateDniComponent,
    UpdateDniSpecsComponent,
    InfoDuoAccountComponent,
    InfoInvestmentAccountComponent,
    ExtendLimitStep1Component,
    ExtendLimitSelectCardComponent,
    ConfigInvestmentAccountComponent,
    ObligationsInvestmentAccountComponent,
    DocumentationInvestmentAccountComponent,
    ViewInvestmentAccountComponent,
    FinalInvestmentAccountComponent,
    StatesComponent,
    MyProfileComponent,
    ConfirmOtpProfileComponent,
    OtpDetailsProfileComponent,
    InfoMyProfileComponent,
    LoadingCustomComponent,
    StatesComponent,
    CurtainComponent,
    ErrorAltavozComponent,
    DivShowComponent,
    SelectCardComponent,
    ConfirmaLimitComponent,
    DocumentationComponent,
    LogaltyAmpliacionComponent,
    LogaltyLoadAmpliacionComponent,
    PdfViewerComponent,
    SegundoTitularFinishComponent,
    MessageScreenComponent,
    SessionExpiredComponent,
    CinvHeaderComponent,
    ErrorBatchComponent],

  providers : [
    AuthGuardUser,
    AuthUserService,
    ApiUserService,
    ApiSecondHolderService,
    SecondHolderService,
    MyProductsService,
    MyInterestService,
    AmpliacionService,
    SecurityService,
    ApiSecurityService
  ],
  entryComponents: [
    KeyboardComponent
  ]

})
export class ProfileModule { }
