<div class="heading">
  <h3>Notificaciones</h3>

</div>
  <app-breadcrumb id="breadcrumb" class="mobile" [breadcrumbs]="breadcrumbNav"
  breadcrumbAdd="NOTIFICACIÓN"></app-breadcrumb>

 <div class="notifications content-wrapper has-breadcrumb">
  <div class="content-contain">
    <div class="row title desktop">
      <div class="container">
          <div class="evo sobre"></div>
          <h3>¿Qué necesito saber?</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h5 class="table-header padding-left">
        asunto
        </h5>
      </div>
      <div class="col wide">
        <h5 class="table-header">
        descripción
        </h5>
      </div>

    </div>
    <div class="calendar">
      <ul>
        <li *ngFor='let notification of _notifications'
        (click)='goToDetail(notification)'>
          <!-- <div class="row date">25 junio</div>        -->
          <div class="row timetable">
            <div>
              <div class="col">
                <p class='bold-title'>{{notification.title}}</p>
                <p class="date">{{notification.date}}</p>
              </div>
              <div class="col wide">
                <div class="no-style" [innerHTML]='notification["content"]'></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

  </div>


</div>
