<div class="info-my-profile">

        <div (click)="goToMyProfile()">
            <span class="backbutton">
              <i class="evo flecha" height="28px"></i>
            </span>
        </div>
    <p class="description title-hide-mobile">
        INFORMACIÓN
    </p>

  <p class="description title-hide-mobile">
   ¿Cómo puedo realizar este cambio? 
  </p>  

  <p class="description subtitle-hide-mobile">
    En esta seccion puedes modificar todos tus datos excepto los marcados en fondo negro 
    los cuales podrás modificar de forma muy sencilla desde la Banca Elecrónica:
  </p>
  
 
    <ul class="description hide-mobile">

      <li class="li_info">Si quieres modificar tu <strong class="textbold">email y teléfono móvil</strong> entra en tu <strong class="textbold">Banca Elecrónica</strong>, 
      selecciona en el menú superior <strong class="textbold">Mis Datos</strong> y a continuación selecciona <strong class="textbold">datos personales</strong>.</li>

      <li class="li_info">Si lo que necesitas es modificar tu<strong class="textbold"> DNI/NIE, nombre, apellidos, fecha de nacimiento o país de residencia,</strong>
      entra en tu <strong class="textbold">Banca Elecrónica</strong>, pincha en tus <strong class="textbold">mensajes</strong> (encima del menú superior), selecciona <strong class="textbold">nueva consulta</strong>,
      despliega las opciones y elige la que corresponda, completa los campos obligatorios, comprueba la información 
      y haz clic en aceptar para enviar la petición. </li>

    </ul>
 
  <div class="container">
  </div>
    <div class="btn-group">
      <button (click)='goToMyProfile()' type="submit" class="btn-common btn-common_fix_bold">ENTENDIDO</button>
    </div>
</div>
