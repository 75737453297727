
  <div class="state-path">
      <div class="item">
        <div class="state" [ngClass]="setClassSelected(states.solicitada)">
          <a><h3 class="step evo user-face" [ngClass]="setClassSelected(states.solicitada)"></h3></a>
          <div class="text" [ngClass]="setClassSelected(states.solicitada)">SOLICITADA</div>
        </div>
      </div>
      <span class="separator" [ngClass]="{'active': setActivatedColor(states.solicitada), 'post-active': stateCurrent == states.solicitada }"></span>

      <div class="item">
        <div class="state" [ngClass]="setClassSelected(states.creada)">
          <a><h3 class="step evo brujula" [ngClass]="setClassSelected(states.creada)"></h3></a>
          <div class="text" [ngClass]="setClassSelected(states.creada)">CREADA</div>
        </div>
      </div>
      <span class="separator " [ngClass]="{'active': setActivatedColor(states.creada), 'post-active': stateCurrent == states.creada }"></span>

      <div class="item">
        <div class="state" [ngClass]="setClassSelected(states.enviada)">
          <a><h3 class="step evo bag" [ngClass]="setClassSelected(states.enviada)"></h3></a>
          <div class="text" [ngClass]="setClassSelected(states.enviada)">ENVIADA</div>
        </div>
      </div>
      <span class="separator" [ngClass]="{'active': setActivatedColor(states.recibida), 'post-active': stateCurrent == states.enviada }"></span>

      <div class="item">
        <div class="state" [ngClass]="setClassSelected(states.recibida)">
          <a><h3 class="step evo phone" [ngClass]="setClassSelected(states.recibida)"></h3></a>
          <div class="text" [ngClass]="setClassSelected(states.recibida)">RECIBIDA</div>
        </div>
      </div>
  </div>
