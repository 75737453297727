import { Component, OnInit, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { Persona } from '../../../collections/persona';
import { Register } from '../../../collections/register';

import { SITUACIONESLABORALES } from '../../../resources/situacion-laboral.model';
import { PROFESIONES } from '../../../resources/profesiones.model';
import { TIPOACTIVIDADPROFESIONAL } from '../../../resources/tipo-actividad-profesional.model';
import { CNAE } from '../../../resources/cnae.model';
import { ERRORES } from '../../../resources/errors.model';
import { ANTIGUEDAD } from '../../../resources/antiguedad.model';
import { TIPOSCONTRATO } from '../../../resources/tipo-contrato.model';

import { AuthService } from '../../../services/auth.service';
import { JsonService } from '../../../services/json.service';
import { FormValidatorService } from '../../../services/form-validator.service';
import { ErrorService } from '../../../services/error.service';
import { DeviceService } from '../../../services/device.service';
import { ModalService } from '../../../services/modal.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { environment } from '../../../../environments/environment';
import { HeaderService } from '../../../services/headers.service';
import { Modal } from '../../../collections/modal';
import { ACCOUNTS } from 'app/resources/account.model';

@Component({
  selector: 'app-labor-details',
  templateUrl: './labor-details.component.html',
  styleUrls: ['./labor-details.component.scss'],
  providers: [FormValidatorService,
    DeviceService,
    ModalService,
    JsonService]
})
export class LaborDetailsComponent implements OnInit {
  loading: boolean = false;
  laborDetailsForm: FormGroup;
  situaciones = SITUACIONESLABORALES;
  profesion = PROFESIONES;
  tipoActividadProfesional = [{ name: 'Tipo de actividad profesional', value: null, profesion: null }];
  cnae = CNAE;
  years = ANTIGUEDAD;
  noSelectedProfession = true;
  errors = ERRORES;
  contracts = TIPOSCONTRATO;
  mobileErrors = [];
  modal = new Modal();
  telephoneMsg = '<a class="link-blue" href="tel:910900900">910 900 900<a/>';
  exceptionMsg = `Lo sentimos, no podemos seguir con la solicitud online. Llámanos al ` + this.telephoneMsg + ` y te explicamos los motivos.`;
  incompleteMsg = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.';
  private currentProfesion = null;
  private persona: Persona = this.authService.getSessionProspect();
  private fechaAntiguedadLaboral;
  public isYoungAccount: boolean = false;
  private flujoOtpPrimerPaso:boolean=true;


  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    this.authService.preventAbandon(this, $event);
  }
  constructor(private fb: FormBuilder,
    private router: Router,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    private authService: AuthService,
    private jsonService: JsonService,
    public deviceService: DeviceService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService,
    private cdRef: ChangeDetectorRef
  ) {
    this.tipoActividadProfesional = this.tipoActividadProfesional.concat(TIPOACTIVIDADPROFESIONAL);
    this.createForm();
  }

  ngOnInit() {
    this.errService.mobileErrors(this.laborDetailsForm);
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    this.flujoOtpPrimerPaso=  this.persona.flujoOtpPrimerPaso;
    if (this.authService.product !== ACCOUNTS.STCode) {
      const data = Object.assign({
        abandono: true,
        flujoOtpPrimerPaso: true,
        activeOtpFirstStep: true,
      });
      this.authService.setSessionProspect(data);
    }
  }

  ngAfterViewInit() {
    if (this.laborDetailsForm.get('profesion').value) {
      this.tipoActividadProfesional = this.filterTipoActividad;
      this.noSelectedProfession = false;
      setTimeout(() => this.helpers.updateFieldValue(this.laborDetailsForm, 'tipoActividad', this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.codigoCNO')), 0);
    }
    this.cdRef.detectChanges();
  }
  onSubmit() {
    const ingresos = this.laborDetailsForm.get('ingresosMensuales'),
          pagas = this.laborDetailsForm.get('numeroPagas'),
          invalid = { valid: false };

    this.validateAdditionalFields();
    this.helpers.updateFormStatus(this.laborDetailsForm);
    this.triggerErrorAnalytics();

    if (!this.isSalaryCorrect) {
      window.scrollTo(0, 0);
      ingresos.setErrors(invalid);
      pagas.setErrors(invalid);
      this.modal = Object.assign({
        title : '¡Vaya!',
        message : this.exceptionMsg,
        withImage : true,
        blocking : false
      })
      this.modService.open(this.modal.name);
      return false;
    }

    if (!this.laborDetailsForm.valid) {
      window.scrollTo(0, 0);

      this.modal = Object.assign({
        title : '¡Ups!',
        withImage : false,
        blocking : false,
        message : this.incompleteMsg
      })

      this.modService.open(this.modal.name);
      return false;
    }


    this.setFields();
    this.sendProspect();
  }

  triggerErrorAnalytics() {
    const invalidFields = this.errService.errorsArray.map(error => {
      return error = error.text
    })
    const invalidString = invalidFields.join(', ');

    if (invalidString !== '')
      return this.analytics.triggerFormData('Datos laborales', `Envio KO: ${invalidString}`);
  }
  maxAndminLengthCheck(event: any, tipo : any, min: any, max: any) {
    if (event < min ) {
      this.laborDetailsForm.get(tipo).setValue(Math.abs(event));
    }
    if(event===null){
      this.laborDetailsForm.get(tipo).setValue('');
    }
    if (event > max){
      this.laborDetailsForm.get(tipo).setValue(String(event).slice(0,String(max).length));
    }
  }
  get filterTipoActividad() {
    const selection = this.laborDetailsForm.get('profesion').value;
    let tipoActividadProfesional = [];
    tipoActividadProfesional.push({ name: 'Tipo de actividad profesional', value: null, profesion: null });
    if (selection === '' && selection === null) return tipoActividadProfesional;
    tipoActividadProfesional = tipoActividadProfesional.concat(TIPOACTIVIDADPROFESIONAL);
    tipoActividadProfesional = tipoActividadProfesional.filter(item => {
      return selection === item.profesion
    });
    const filteredActividadProfesional = tipoActividadProfesional.unshift({ name: 'Tipo de actividad profesional', value: null, profesion: null });
    return tipoActividadProfesional
  }

  get situacionLaboralValue() {
    const currentSituacion = this.laborDetailsForm.get('situacionLaboral').value,
          situacionObj = this.situaciones.filter(situacion => {
      return situacion.value === currentSituacion
    })
    return situacionObj[0]['name']
  }

  get isSalaryCorrect() {
    const salary = this.laborDetailsForm.get('ingresosMensuales').value,
          pays = this.laborDetailsForm.get('numeroPagas').value;
    if (salary > 80000) return false;
    return salary * pays < 1000000;
  }

  get isAutonomo() {
    return this.laborDetailsForm.get('situacionLaboral') &&
      this.laborDetailsForm.get('situacionLaboral').value === '02';
  }

  get isFuncionario() {
    return this.laborDetailsForm.get('situacionLaboral') &&
      this.laborDetailsForm.get('situacionLaboral').value === '12';
  }

  get isOneField() {
    return this.laborDetailsForm.controls.situacionLaboral.value != null
      && !this.isAutonomo && !this.isCuentaAjena 
      && !this.isJubiladoPensionanistaRentista && !this.isFuncionario ? "flex-btn" : "flex-btn-center"
  }

  get isOneFieldForm() {
    return this.laborDetailsForm.controls.situacionLaboral.value != null
      && !this.isAutonomo && !this.isCuentaAjena 
      && !this.isJubiladoPensionanistaRentista && !this.isFuncionario ? "flex-form" : ""
  }

  get isCuentaAjena() {
    return this.laborDetailsForm.get('situacionLaboral') &&
      this.laborDetailsForm.get('situacionLaboral').value === '01';
  }

  get isJubiladoPensionanistaRentista(){
    return this.laborDetailsForm.get('situacionLaboral') &&
    this.laborDetailsForm.get('situacionLaboral').value === '05' ||
    this.laborDetailsForm.get('situacionLaboral').value === '11';
  }

  sendProspect() {
    let nextStep = '/metodo-validacion';
    if (this.authService.product === ACCOUNTS.STCode) {
      nextStep = '/confirmacion-otp';
    }
   
    this.loading = true;
    this.authService.updateProspect(this.persona).subscribe((data) => {
      this.loading = false;
      if (data.response.codigo !== 'OK') {
        this.modal = this.modService.selectStatusModal(data.response);
        this.analytics.triggerFormData('Datos laborales', `${this.situacionLaboralValue}Envio KO: ${this.modal.message}`)
        this.modService.open(this.modal.name, this.modal.blocking);
        return
      }
      if (this.authService.isLoggedIn) {
        this.authService.pageSubmitted = 'datos-laboral';
        this.analytics.triggerFormData('Datos laborales', `${this.situacionLaboralValue} Envio OK`);
        this.loading = true;
        this.headerSrv.getRefreshToken().subscribe(data => { 
          this.loading = false; 
          this.helpers.navigateTo(nextStep); },
           error => { 
            this.loading = false; 
          });
      }
    }, error => {
      this.error();
    });
  }

  error() {
    this.analytics.triggerFormData('Datos laborales', `${this.situacionLaboralValue} Envio KO 500 Server Error`);
    this.errService.navToError();
  }

  createForm() {

    this.laborDetailsForm = this.fb.group({
      situacionLaboral: [this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.codigoSituacionLaboral') || null, [Validators.required]],
      tipoContrato: [this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.codigoContrato') || null],
      profesion: [this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.profesion') || null],
      tipoActividad: [this.initCNO(this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.codigoCNO')) || null],
      duracionTrabajo: [this.getSeniorityFromJson(this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.duracion')) || null],
      fechaAltaAutonomo: [this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.fechaAltaAutonomo') || ''],
      cnae: [this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.codigoCNAE') || null],
      ingresosMensuales: [this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.ingresosMensuales') || ''],
      numeroPagas: [this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.numeroPagas') || '']
    });
    this.formSubscribe();
  }

  formSubscribe() {
    const tipoActividad = this.laborDetailsForm.get('tipoActividad');

    this.laborDetailsForm.get('situacionLaboral').valueChanges.subscribe(control => {
      this.validateAdditionalFields();
      //this.laborDetailsForm.markAsUntouched();
    })

    this.laborDetailsForm.valueChanges.subscribe(form => {
      this.authService.saveUpdatedForm(this.laborDetailsForm, this, form);
      this.errService.mobileErrors(this.laborDetailsForm);
    });

    this.laborDetailsForm.get('profesion').valueChanges.subscribe(profesion => {
      if (!profesion) {
        this.noSelectedProfession = true;
      } else {
        this.noSelectedProfession = false;
      }

      if (profesion !== this.currentProfesion) {
        this.currentProfesion = profesion;
        this.helpers.updateFieldValue(this.laborDetailsForm, 'tipoActividad');
      }

      this.tipoActividadProfesional = this.filterTipoActividad;
    })

    if (this.laborDetailsForm.get('profesion').value) {
      this.tipoActividadProfesional = this.filterTipoActividad;
      setTimeout(() => {
        this.helpers.updateFieldValue(
          this.laborDetailsForm,
          'tipoActividad',
          this.initCNO(this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.codigoCNO'))), 0
      }
      );
    }

  }

  initCNO(value) {
    if (value) {
      const filteredTipo = TIPOACTIVIDADPROFESIONAL.filter(tipoActividad => {
        return tipoActividad.value === value;
      })
      return filteredTipo[0]['name'];
    }
    return undefined;
  }

  setCNO(value) {
    if (value) {
      const filteredTipo = TIPOACTIVIDADPROFESIONAL.filter(tipoActividad => {
        return tipoActividad.name === value;
      })
      return filteredTipo[0]['value'];
    }
    return undefined;
  }

  setFields() {

    const canal = this.deviceService.getDevice(),
          duracion = this.laborDetailsForm.get('duracionTrabajo'),
          codigoCNO = this.laborDetailsForm.get('tipoActividad');

    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina
      },
      datosSocioEco: {
        codigoSituacionLaboral: this.helpers.returnValue(this.laborDetailsForm.get('situacionLaboral')),
        codigoContrato: this.helpers.returnValue(this.laborDetailsForm.get('tipoContrato')),
        profesion: this.helpers.returnValue(this.laborDetailsForm.get('profesion')), //firs value of combo;
        codigoCNO: codigoCNO.value !== null ? this.setCNO(codigoCNO.value) : undefined,
        codigoCNAE: this.helpers.returnValue(this.laborDetailsForm.get('cnae')),
        fechaAltaAutonomo: this.helpers.returnValue(this.laborDetailsForm.get('fechaAltaAutonomo')),
        duracion: this.calcLaborSeniority(duracion.value),
        ingresosMensuales: this.helpers.returnValue(this.laborDetailsForm.get('ingresosMensuales')),
        numeroPagas: this.helpers.returnValue(this.laborDetailsForm.get('numeroPagas'))
      }
    });
  }

  calcLaborSeniority(duration) {
    const date = new Date(),
          isYear = duration && duration !== null && duration >= 1,
          isMonth = duration && duration !== null && duration < 1 && duration > 0.1,
          isDay = duration && duration !== null && duration < 0.1;

    if (!duration) {
      return undefined;
    } else if (isYear) {
      date.setFullYear(date.getFullYear() - duration);
    } else if (isMonth) {
      date.setMonth(date.getMonth() - duration * 10);
    } else if (isDay) {
      date.setDate(date.getDate() - duration * 100);
    }
    return this.formatDate(date);

  }

  getSeniorityFromJson(date) {
    if (!date) return null
    const currentDate = new Date(),
          date2 = new Date(date),
          timeDiff = Math.abs(currentDate.getTime() - date2.getTime()),
          diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)),
          valueDiff = Math.floor(diffDays / 30);

    if (valueDiff < 3) {
      return 0.01
    } else if (valueDiff >= 3 && valueDiff < 9) {
      return 0.3
    } else if (valueDiff > 9 && valueDiff <= 12) {
      return 0.9
    } else {
      const years = Math.floor(valueDiff / 12);
      return years
    }
  }

  formatDate(date: Date) {
    date = new Date(Number(date));
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  valueSelectDate(duration) {
    if (this.isEmpty(duration)) return undefined;
    duration = duration.split('/');
    duration = new Date(duration[2], duration[1], duration[0]);
    const yearNow = new Date().getFullYear();
    return yearNow - duration.getFullYear();
  }

  isEmpty(obj) {
    if (obj === null) return true;
    return Object.keys(obj).length === 0;
  }

  searchNameOfCode(values, code) {
    let name;
    for (const value in values) {
      if (value['value'] === code) {
        name = value['name'];
        break;
      }
    }
    return name;
  }

  validateAdditionalFields() {
    const autonomoFields = ['cnae', 'fechaAltaAutonomo'],
      cuentaAjenaFields = ['tipoContrato', 'duracionTrabajo', 'ingresosMensuales', 'numeroPagas'],
      jubiladoRentistaPensionistaFields = ['ingresosMensuales', 'numeroPagas'],
      funcionario = ['profesion', 'duracionTrabajo', 'ingresosMensuales', 'numeroPagas',],
      cuentaAjenaSinDatosIngresos = ['tipoContrato', 'duracionTrabajo'],
      cuentaAjenaSinDatosIngresosNiProfesion = ['tipoContrato', 'duracionTrabajo', 'profesion'];

    this.noValidators();

    if (this.isCuentaAjena) {
      this.helpers.resetFieldsState(this.laborDetailsForm, cuentaAjenaFields)
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'tipoContrato', [Validators.required]);
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'profesion', [Validators.required]);
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'tipoActividad', [Validators.required]);
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'duracionTrabajo', [Validators.required]);
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'ingresosMensuales', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(6),
        this.evoValidator.validateIncomings
      ]
      );
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'numeroPagas', [
        Validators.minLength(1),
        Validators.maxLength(2),
        this.evoValidator.validateAnnualPayments
      ]
      )
      this.cleanSpecificFields(autonomoFields);
    } else if (this.isAutonomo) {
      this.helpers.resetFieldsState(this.laborDetailsForm, autonomoFields)
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'profesion', [Validators.required]);
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'tipoActividad', [Validators.required]);
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'fechaAltaAutonomo', [
        Validators.required,
        this.evoValidator.validateDate
      ]
      );
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'ingresosMensuales', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(6),
        this.evoValidator.validateIncomings
      ]
      );
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'cnae', [Validators.required]);
      this.cleanSpecificFields(cuentaAjenaSinDatosIngresos);
    } else if (this.isFuncionario) {
      this.helpers.resetFieldsState(this.laborDetailsForm, funcionario)
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'numeroPagas', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2),
        this.evoValidator.validateAnnualPayments
      ]
      );
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'profesion', [Validators.required]);
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'ingresosMensuales', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(6),
        this.evoValidator.validateIncomings
      ]
      );
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'duracionTrabajo', [Validators.required]);
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'tipoActividad', [Validators.required]);
      this.cleanSpecificFields(autonomoFields);
      this.cleanSpecificFields(['tipoContrato']);
    } else if (this.isJubiladoPensionanistaRentista) {
      this.helpers.resetFieldsState(this.laborDetailsForm, jubiladoRentistaPensionistaFields)
      this.helpers.updateFieldValidation(this.laborDetailsForm, 'numeroPagas', [
        Validators.minLength(1),
        Validators.maxLength(2),
        this.evoValidator.validateAnnualPayments
      ]
      );
        this.helpers.updateFieldValidation(this.laborDetailsForm, 'ingresosMensuales', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(6),
        this.evoValidator.validateIncomings
      ]
      );
      this.cleanSpecificFields(autonomoFields);
      this.cleanSpecificFields(cuentaAjenaSinDatosIngresosNiProfesion);
    } else if (!this.isAutonomo && !this.isCuentaAjena
       && !this.isJubiladoPensionanistaRentista && !this.isFuncionario) {
      this.cleanAllValues();
    }

  }

  cleanSpecificFields(arr) {
    arr.forEach(field => {
      this.helpers.updateFieldValue(this.laborDetailsForm, field);
    })
  }

  cleanAllValues() {
    Object.keys(this.laborDetailsForm.controls).forEach(key => {
      if (key !== 'situacionLaboral') {
        this.helpers.updateFieldValue(this.laborDetailsForm, key);
      }
    });
  }

  noValidators() {
    Object.keys(this.laborDetailsForm.controls).forEach(key => {
      if (key !== 'situacionLaboral') {
        this.helpers.updateFieldValidation(this.laborDetailsForm, key);
      }
    });
  }

  isCorrectDrowDown(custom){
    let isCorrect = true
    if (custom) {
      isCorrect = false
    }
    return isCorrect;
  }
}
