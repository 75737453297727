<app-header></app-header>
<modal [modalTitle]="'Recomienda'" [blocking]='false' [modalId]='recomiendaModal' class='modal recomienda'>
  <iframe #iframereco class="recomiendaiframe" width="100%" height="100%"></iframe>
</modal>
<div class="everything-nearly">
  <div class="image" [ngClass]="{ 'young' : isYoungAccount }"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>¡Casi lo tenemos!</h2>

        <p>Tic tac tic tac... estamos comprobando tu identidad con el banco. <br><strong>¿Cuándo estará activa mi cuenta?</strong></p>
      </legend>
    </div>
    <div class="path-container">
      <div class="row process">
        <i class="evo proc-lupa"></i>
        <br>
        <div class="text-container">
          <p>Comprobamos tus datos con <strong>tu banco</strong> <br>(5-8 días)</p>
        </div>
      </div>
      <div class="separator"></div>
      <div class="row process">
        <i class="evo proc-ok"></i>
        <div class="text-container">
          <p>Si todo está correcto <br><strong>¡Ya eres cliente!</strong></p>
        </div>
      </div>
      <div class="separator"></div>
      <div class="row process">
        <i class="evo proc-mail"></i>
        <div class="text-container">
          <p>Recibirás un mail con <strong>tu contrato y tu estado de validación.</strong></p>
        </div>
      </div>
      <div class="separator"></div>
      <div class="row process">
        <i class="evo proc-sms"></i>
        <div class="text-container">
          <p>Recibirá <strong>2 SMS</strong></p>
          <ul>
            <li><p>Claves de tu Banca móvil</p></li>
            <li><p>Tu PIN de tarjeta</p></li>
          </ul>
        </div>
      </div>
      <div class="separator"></div>
      <div class="row process">
        <i class="evo proc-card-5"></i>
        <div class="text-container">
          <p>Recibirás tu Tarjeta Inteligente en 7 días hábiles desde la recepción de tu PIN por SMS</p>
        </div>
      </div>
    </div>
    <div class="title-container ">
        Recuerda por seguridad, no actives tu tarjeta hasta recibirla en la dirección que nos has dado.
    </div>
    <div class="title-container ">
        Además, cuando tu cuenta ya esté abierta, podrás incluir un segundo titular desde tu app.
    </div>
    <div class="row mobile-only">
      <p>¡Espera! Estamos conectando con nuestro Consejero Delegado para darte la bienvenida, no te mereces menos.</p>
    </div>
    <div class="row">
      <div class="video-container">
        <div *ngIf='showPlay' (click)="hidePlayer(); playVid()" class="video"></div>
        <video #video controls (click)='playVid()' src={{vidService.videoUrl}}></video>
      </div>
    </div>
    <!-- <div class="row btn">
      <div class="btn-group">
        <button type="submit" class="btn-common btn-common_fix_bold" tabindex="5"
        (click)='goToProfile()'>
          COMENZAR
        </button>
      </div>
    </div> -->
    <div class="row recomienda" #recomienda *ngIf="!isYoungAccount">
      <i class='evo ko' (click)='closeRecomienda($event)'></i>
      <div class="flex-column">
        <div class="text-container">
          <i class="evo people"></i>
          <h6>recomienda evo</h6>
          <p>Si te ha gustado el proceso y te ha parecido fácil, contagia a tus amigos la forma de ahorrar.</p>
        </div>
        <div class="mobile-container flex-right">
          <h6 class="uppercase-info" (click)='goToRecomienda()'>recomendar</h6><i class="evo right-arrow"></i>
        </div>
      </div>
    </div>
  </div>
</div>
