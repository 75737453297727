import { Component, Input, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'desktop-error-bar-zone',
  templateUrl: './desktop-error-bar-zone.component.html',
  styleUrls: ['./desktop-error-bar-zone.component.scss']
})
export class DesktoperrorbarzoneComponent {

  @Input() errors = [];
  @Input('datepickerzone') datepickerzone = false;
  @Input('is-error') isError = true;
  @Input('desktop-displayable') desktopDisplayable = false;

  private scrollTop = false
  public screenWidth: number;
  private scroll: number;

  constructor(private element: ElementRef) {
    this.screenWidth = window.innerWidth;
  }

  get errorsHaveText() {
    if (this.errors.length === 0) {
      return false;
    }
    return this.errors.filter(error => {
      return !!error.text
    }).length !== 0
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }

}
