import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie';
import { SpecificAnalyticsService } from './specific-analytics.service';


@Injectable({
    providedIn: 'root'
})
export class TokenInfoService {

    private accessToken;
    private isAuthenticatedFromCookies;
    private phone;

    constructor(private keycloak: KeycloakService,
        private _cookieService: CookieService, private specificAnalyticsSrv: SpecificAnalyticsService) { }

    checkAuthenticationFromCookies(): boolean {
        this.isAuthenticatedFromCookies = this._cookieService.get("access");
        this._cookieService.remove("access");
        return !!this.isAuthenticatedFromCookies;
    }

    public getAuthenticatedFromCookies() {
        return this.isAuthenticatedFromCookies;
    }

    setKeycloakInfo() {
        if (this.keycloak.getKeycloakInstance().authenticated) {
          const accesToken = this.keycloak.getKeycloakInstance().idTokenParsed['api_access_token'];
          this.accessToken = accesToken ? accesToken : this.keycloak.getKeycloakInstance().token;
          this.specificAnalyticsSrv.emitHashedDni( this.keycloak.getKeycloakInstance().idTokenParsed['idExterno']);
          this.phone = this.keycloak.getKeycloakInstance().idTokenParsed['telefonoMovil'];
        }
    }

    getAccesToken() {
        return this.accessToken;
    }

    getPhone(){
      return this.phone;
    }
}
