import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../../services/helpers.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-confirma-limit',
  templateUrl: './confirma-limit.component.html',
  styleUrls: ['./confirma-limit.component.scss']
})
export class ConfirmaLimitComponent implements OnInit {

  public titulo;
  public subtitulo;
  public mostrarInfo: boolean = false;
  public bajadaLimite: boolean = false;
  public operacionAmpliacion;
  public myLimit;
  public lastNum;
  public nameError: string = '';
  public showError: boolean = false;
  public msgRecuerda: boolean = false; // mensaje RECUERDA cuadrado inferior
  public limiteActual;
  public showHeader: boolean = true;

  constructor( 
    private activatedRoute: ActivatedRoute,
    public helpers: HelperService,
    private analytics: AnalyticsService,) { }

  ngOnInit() {
    this.showError = false;
    this.nameError = "ocultarErrores";
    
    this.activatedRoute.queryParams.subscribe((params: Params) => { // PREPARADO PARA CASO DE 
      //this.isParam=true;
      if(params.origen) {
        if (params.origen.toLowerCase() === "bmi") {
          // ocultar cabecera
          this.showHeader = false;
        }
      }
    });

    // Si se accede mediante una redirección desde logalty, se comprueba si es bmi desde los datos de sesión.
    if (sessionStorage.getItem('isBMI') === 'true') {
      this.showHeader = false;
    }

    // PUT casi-lo-tenemos
    if (!sessionStorage.getItem('operacion-ampliacion') && !sessionStorage.getItem('logaltySigned')){
      this.helpers.navigateTo("/perfil");
    }

    this.myLimit = sessionStorage.getItem('limite-solicitado');
    let creditLimitValue = '';
    if (this.myLimit < 3000) {
      creditLimitValue = 'Menor de 3K€'
    } else {
      creditLimitValue = 'Mayor de 3K€'
    }

    this.operacionAmpliacion = sessionStorage.getItem('operacion-ampliacion');
    if(this.operacionAmpliacion === "ampliacion") {
      this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/Paso 05 - Confirmación', 'creditLimit': 'Menor de 3K€'});
    }
    else if(this.operacionAmpliacion === "ampliacion-firma") {
      this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/Paso 05 - Confirmación', 'creditLimit': 'Mayor de 3K€'});
    }
    else if(this.operacionAmpliacion === "cancelar") {
      this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/Confirmación cancelación', 'creditLimit': ''});
    }
    else if(this.operacionAmpliacion === "disminuir0") {
      this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/Confirmación límite 0€', 'creditLimit': ''});
    }
    else if(this.operacionAmpliacion === "disminuir") {
      this.analytics.triggerCreditLimitDataNavData({'pageName': 'Modificación Límite/Paso 05 - Confirmación', 'creditLimit': creditLimitValue});
    }
   
    this.limiteActual = sessionStorage.getItem('limite-actual');
    if (this.limiteActual === "1") {
      this.limiteActual = "0";
    }
    let card = JSON.parse(sessionStorage.getItem('tarjetaSeleccionada')) || this.searchFirstCard();
    let numCard = card && card.numTarjeta.trim();//Se quitan los espacios que hay en el string al final 
    this.lastNum = numCard && numCard.substr(-4, 4);
    console.log("Operacion ampliacion:", this.operacionAmpliacion);
    this.changeText();
    this.borrarSession();
  }


  /**Se busca la primera tarjeta operativa(no bloqueada) del cliente */
  searchFirstCard() {
    let tarjetaSeleccionada = '';
    let tarjetasActivas = [];
    let tarjetas = JSON.parse(sessionStorage.getItem("userData")).listaTarjetas;
    if (tarjetas.length > 0) {

      for (var i = 0; i < tarjetas.length; i++) {
        if (tarjetas[i].codigoLinea === "01") {
          if (tarjetas[i].codBloqueo === "10" || tarjetas[i].codBloqueo === "20" ||
              tarjetas[i].codBloqueo === "21" || tarjetas[i].codBloqueo === "32" || 
              tarjetas[i].codBloqueo === "33" || tarjetas[i].codBloqueo === "71" ||
              tarjetas[i].codBloqueo === "72" || tarjetas[i].codBloqueo === "80") {
            //tarjeta bloqueada
          }
          else {
            tarjetasActivas.push(tarjetas[i]);
          }
        }
      }
    }

    if (tarjetasActivas.length > 0) {
      tarjetaSeleccionada = tarjetasActivas[0];
      sessionStorage.setItem('tarjetaSeleccionada',  JSON.stringify(tarjetaSeleccionada));
    }

    return tarjetaSeleccionada;
  }

  changeText(){
    //Si viene por cancelar solicitud
    if(this.operacionAmpliacion === 'cancelar'){
      this.titulo = "MODIFICACIÓN CANCELADA";
      this.subtitulo = "Tu límite de crédito ha vuelto a su saldo anterior";
      this.mostrarInfo = false;
      this.bajadaLimite = false;
    }
    else if(this.operacionAmpliacion === 'modificar'){
      //Si viene por modificar solicitud
      this.titulo = "MODIFICACIÓN EN PROCESO";
      this.subtitulo = "Tu petición ya está siendo revisada";
      this.mostrarInfo = true;
      this.msgRecuerda = true;
      this.bajadaLimite = false;
    }
    else if(this.operacionAmpliacion === 'disminuir'){
      //Si viene por disminuir solicitud
      this.titulo = "PETICIÓN RECIBIDA";
      this.subtitulo = "Ya hemos recibido tu petición, cuando esté finalizada recibirás un email";
      this.mostrarInfo = true;
      this.msgRecuerda = false;
      this.bajadaLimite = true;
    }
    else if(this.operacionAmpliacion === 'disminuir0'){
      this.titulo = "PETICIÓN RECIBIDA";
      this.subtitulo = "Ya hemos recibido tu petición, cuando esté finalizada recibirás un email";
      this.mostrarInfo = true;
      this.msgRecuerda = false;
      this.bajadaLimite = true;
    }
    else if(this.operacionAmpliacion === 'ampliacion'){
      this.titulo = "MODIFICACIÓN EN PROCESO";
      this.subtitulo = "Tu petición ya está siendo revisada";
      this.mostrarInfo = true;
      this.msgRecuerda = true;
      this.bajadaLimite = false;
    }
    else if(this.operacionAmpliacion === 'ampliacion-firma'){
      this.titulo = "MODIFICACIÓN EN PROCESO";
      this.subtitulo = "Tu petición ya está siendo revisada";
      this.mostrarInfo = true;
      this.msgRecuerda = true;
      this.bajadaLimite = false;
    }
    else if(!this.operacionAmpliacion){
      this.titulo = "MODIFICACIÓN EN PROCESO";
      this.subtitulo = "Tu petición ya está siendo revisada";
      this.mostrarInfo = true;
      this.msgRecuerda = true;
      this.bajadaLimite = false;
    }
  }

  bttVolver(){
    // borrar datos de sesion 
    // sessionStorage.removeItem("");
    // navigate to perfil
  }

  borrarSession() {
    sessionStorage.removeItem("casoSeleccionado");
    sessionStorage.removeItem("codProdAmpliacion");
    sessionStorage.removeItem("operacion-ampliacion");
    sessionStorage.removeItem("tarjetaSeleccionada");
    sessionStorage.removeItem("operacion-inversora");
    sessionStorage.removeItem("productoContratando");
    sessionStorage.removeItem("isSecondSigner");
    sessionStorage.removeItem("ibanCuentaSeleccionada");
  }

  close(){
    // borrar datos de sesion 
    // sessionStorage.removeItem("");
    window.location.href='https://bancaelectronica.evobanco.com/';
    //this.helpers.navigateTo("/login");
  }

  botonVolver(){}

}
