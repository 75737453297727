<loading [showMe]="loading"></loading>

<!-- <modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal not-adult'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal> -->

<modal [modalTitle]="'modalOld'" [modalId]='modalOld' class='modalOld'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
    <button class="btn-common letter-white btn-common_fix_bold" (click)="onClose()">
      {{txtButtonModal}}
    </button>
  </div>
</modal>

<modal [modalTitle]="'apellidos'" [blocking]='true' [modalId]='modalSurname' class='surname-modal'>
  <div class="container">
    <form [formGroup]='surnameOptions' (ngSubmit)="updateSurnames()">
    <h2>¿Nos echas un cable?</h2>
    <p>Nuestro sistema no consigue reconocer tus apellidos. Elige entre las siguientes opciones</p>
    <div class="group-radio">
      <div class="options">
        <div class="row" *ngFor='let surname of surnames'>
          <input type="radio" class="option" value="{{surname}}"
          formControlName="surnameOption" id="{{surname}}">
          <label class="button-label" for="{{surname}}"><p>{{surname}}</p>
          </label>
        </div>
      </div>
    </div>
    <div class="equal-buttons flex-column">
      <div class="row">
        <button class='btn-common' type="submit" name="button">
          Listo, este es el mío
        </button>
      </div>
      <div class="row">
        <div (click)='hasIncorrectSurnames()' class="access">
          <span>No es ninguno de estos</span>
        </div>
      </div>
    </div>
  </form>
  </div>
</modal>

<div class="confirm-personal">
  <div class="image"></div>
  <div class="container">
    <h2 *ngIf='!isDataLoaded' class='loading'>Estamos cargando tus datos...</h2>

    <div *ngIf='isDataLoaded' class="title-container">
      <legend>
        <h2>{{title}}</h2>
       <p>Asegúrate de que son correctos, si no es así, selecciona el que esté mal y modifícalo. Ya casi has terminado, solo te queda firmar.</p>
      </legend>
    </div>

  <div class="form" *ngIf='!isLoggedUser && isDataLoaded'>
    <div class="row">
      <div class="mobile-container">
        <label for="nombre">Nombre</label>
        <input class="edit" type="text" id="nombre"
          name="nombre"
          placeholder="Nombre"
          (change)="change(($event))"
          [value]="confirmPersonal.name"
           autocomplete="off"
         >
       
      </div>
      <div class="mobile-container">
        <label for="primerApellido">Primer apellido</label>
        <input type="text" class="edit" id="primerApellido"
          name="primerApellido" 
          placeholder="Primer apellido"
          (change)="change(($event))"
          [value]="confirmPersonal.firstName"
          autocomplete="off">
      </div>
      <div class="mobile-container" *ngIf="confirmPersonal.secondName !== ''">
        <label for="segundoApellido">Segundo apellido</label>
        <input type="text" class="edit" id="segundoApellido"
        name="segundoApellido"
        placeholder="Segundo apellido"
        (change)="change(($event))"
        [value]="confirmPersonal.secondName"
        autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="fechaCaducidad">Fecha de caducidad</label>
        <input date-input type="text"
          class="datepicker"
          name="fechaCaducidad"
          id="fechaCaducidad"
          placeholder="dd/mm/yyyy"
          (change)="change(($event))"
          [value]="formatDate(confirmPersonal.expiryDate)"
          disabled
          autocomplete="off"/>
      </div>
      <div class="mobile-container">
        <label for="fechaNacimiento">Fecha de nacimiento</label>
        <input date-input type="text"
          class="datepicker"
          name="fechaNacimiento"
          id="fechaNacimiento"
          placeholder="dd/mm/yyyy"
          (change)="change(($event))"
          [value]="formatDate(confirmPersonal.birthDate)"
          disabled
          autocomplete="off"/>
      </div>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="genero">Género</label>
        <input type="text" id="genero"
        name="genero" 
        id="genero"
        placeholder="Género"
        (change)="change(($event))"
        [value]="confirmPersonal.sex"
        disabled
        autocomplete="off">
      </div>
      <div class="mobile-container">
        <label for="nacionalidad">Nacionalidad</label>
        <input type="text" id="nacionalidad"
        name="nacionalidad" 
        id="nacionalidad"
        placeholder="Nacionalidad"
        (change)="change(($event))"
        [value]="confirmPersonal.nationality"
        disabled
        autocomplete="off">
      </div>
      <div class="mobile-container">

      </div>
    </div>
    <div class="btn-group">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="onSubmit()">CONFIRMO QUE ESTÁN CORRECTOS</button>
    </div>
  </div>
  <form class="form" *ngIf='isLoggedUser && isDataLoaded'>
    <div class="row">
      <h4>DATOS PERSONALES</h4>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="nombre">Nombre</label>
        <input type="text" id="nombre"
          name="nombre"
          placeholder="Nombre"
          disabled
          [value]="confirmPersonal.name"
          autocomplete="off">
        </div>
        <div class="mobile-container">
          <label for="primerApellido">Primer Apellido</label>
          <input type="text"
            name="primerApellido"  
            id="primerApellido"                      
            placeholder="Primer Apellido"
            disabled
            [value]="confirmPersonal.firstName"
            autocomplete="off">
          </div>
          <div class="mobile-container" *ngIf='confirmPersonal.secondName !== ""'>
            <label for="segundoApellido">Segundo Apellido</label>
            <input type="text"
              id="segundoApellido"
              name="segundoApellido"
              placeholder="Segundo Apellido"
              disabled
              [value]="confirmPersonal.secondName"
              autocomplete="off">
            </div>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="documentoIdentidad">Documento de Identidad</label>
        <input type="text"
          name="documentoIdentidad"
          disabled
          placeholder="Documento de identidad"
          [value]="confirmPersonal.documentNumber">
        </div>
        <div class="mobile-container" *ngIf='confirmPersonal.expiryDate'>
          <label for="fechaCaducidad">Fecha de caducidad</label>
          <input date-input type="text"
          class="datepicker" name="date" value=""
          placeholder="dd/mm/yyyy" disabled
          [value]="formatDate(confirmPersonal.expiryDate)"
          autocomplete="off"/>
        </div>
        <div class="mobile-container">
          <label for="nacionalidad">Nacionalidad</label>
          <input type="text" id="nacionalidad"
          name="nacionalidad" disabled
          placeholder="Nacionalidad"
          [value]="confirmPersonal.nationality"
          autocomplete="off">
        </div>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="paisNacimiento">País de Nacimiento</label>
        <input type="text" id="paisNacimiento"
          name="paisNacimiento"
          disabled
          placeholder="País de nacimiento"
          [value]="confirmPersonal.birthCountry"
          autocomplete="off">
      </div>
      <div class="mobile-container" *ngIf='confirmPersonal.birthProvince !== ""'>
        <label for="provinciaNacimiento">Provincia de Nacimiento</label>
        <input type="text"
          name="provinciaNacimiento"
          disabled
          placeholder="Provincia de Nacimiento"
          [value]="confirmPersonal.birthProvince"
          autocomplete="off">
      </div>
      <div class="mobile-container">
        <label for="localidadNacimiento">Localidad de Nacimiento</label>
        <input type="text"
          name="localidadNacimiento"
          disabled
          placeholder="Localidad de Nacimiento"
          [value]="confirmPersonal.birthLocation"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="fechaNacimiento">Fecha de nacimiento</label>
        <input date-input type="text"
        class="datepicker" name="date"
        value=""
        placeholder="dd/mm/yyyy" disabled
        [value]="formatDate(confirmPersonal.birthDate)"
        autocomplete="off"/>
      </div>
      <div class="mobile-container">
        <label for="genero">Género</label>
        <input type="text"
          name="genero"
          disabled
          placeholder="genero"
          [value]="confirmPersonal.sex"
          autocomplete="off">
      </div>
      <div class="mobile-container">
        <label for="estadoCivil">Estado Civil</label>
        <input type="text"
          name="estadoCivil"
          disabled
          placeholder="Estado Civil"
          [value]="confirmPersonal.civilStatus"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="mobile-container" *ngIf='confirmPersonal.economicSystem !== ""'>
        <label for="regimenEconomico">Régimen Económico</label>
        <input type="text"
          name="regimenEconomico"
          disabled
          placeholder="Regimen Economico"
          [value]="confirmPersonal.economicSystem"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <h4>DATOS DE CONTACTO</h4>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="email">Correo Electrónico</label>
        <input type="text"
          name="email"
          disabled
          placeholder="Email"
          [value]="confirmPersonal.contactDetails.email"
          autocomplete="off">
      </div>
      <div class="mobile-container">
        <label for="telephone">Teléfono móvil</label>
        <input type="text"
          name="telephone"
          disabled
          placeholder="XXXXXXXXX"
          [value]="confirmPersonal.contactDetails.telephone"
          autocomplete="off">
      </div>
      <div class="mobile-container">
        <label for="paisResidencia">País de Residencia</label>
        <input type="text"
          name="paisResidencia"
          disabled
          placeholder="País de Residencia"
          [value]="confirmPersonal.residenceCountry"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="provinciaResidencia">Provincia de Residencia</label>
        <input type="text"
          name="provinciaResidencia"
          disabled
          placeholder="Provincia de Residencia"
          [value]="confirmPersonal.residenceProvince"
          autocomplete="off">
      </div>
      <div class="mobile-container">
        <label for="ciudadResidencia">Ciudad de Residencia</label>
        <input type="text"
          name="ciudadResidencia"
          disabled
          placeholder="Ciudad de Residencia"
          [value]="confirmPersonal.residenceLocation"
          autocomplete="off">
      </div>
      <div class="mobile-container">
        <label for="postalCode">Código Postal</label>
        <input type="text"
          name="postalCode"
          disabled
          placeholder="Código Postal"
          [value]="confirmPersonal.contactDetails.postalCode"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="tipoVia">Tipo de vía</label>
        <input type="text"
          name="tipoVia"
          disabled
          placeholder="Tipo de vía"
          [value]="confirmPersonal.contactDetails.streetType"
          autocomplete="off">
      </div>
      <div class="mobile-container">
        <label for="nombreVia">Nombre vía</label>
        <input type="text"
          name="nombreVia"
          disabled
          placeholder="Nombre vía"
          [value]="confirmPersonal.contactDetails.streetName"
          autocomplete="off">
      </div>
      <div class="mobile-container small" *ngIf='confirmPersonal.contactDetails.streetNumber !== ""'>
        <label for="numero">Nº</label>
        <input type="text"
          name="numero"
          disabled
          placeholder=""
          [value]="confirmPersonal.contactDetails.streetNumber"
          autocomplete="off">
      </div>
      <div class="mobile-container small" *ngIf='confirmPersonal.contactDetails.piso !== ""'>
        <label for="piso">Piso</label>
        <input type="text"
          name="piso"
          disabled
          placeholder=""
          [value]="confirmPersonal.contactDetails.piso"
          autocomplete="off">
      </div>
      <div class="mobile-container small" *ngIf='confirmPersonal.contactDetails.puerta !== ""'>
        <label for="puerta">Pta</label>
        <input type="text"
          name="puerta"
          disabled
          placeholder=""
          [value]="confirmPersonal.contactDetails.puerta"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <h5>Datos adicionales</h5>
    </div>
    <div class="row">
      <div class="mobile-container small" *ngIf='confirmPersonal.contactDetails.portal !== ""'>
        <label for="portal">Portal</label>
        <input type="text"
          name="portal"
          disabled
          [value]="confirmPersonal.contactDetails.portal"
          autocomplete="off">
      </div>
      <div class="mobile-container small" *ngIf='confirmPersonal.contactDetails.bloque !== ""'>
        <label for="Bloque">Bloque</label>
        <input type="text"
          name="Bloque"
          disabled
          [value]="confirmPersonal.contactDetails.bloque"
          autocomplete="off">
      </div>
      <div class="mobile-container small" *ngIf='confirmPersonal.contactDetails.escalera !== ""'>
        <label for="escalera">Esc.</label>
        <input type="text"
          name="escalera"
          disabled
          [value]="confirmPersonal.contactDetails.escalera"
          autocomplete="off">
      </div>
      <div class="mobile-container small" *ngIf='confirmPersonal.contactDetails.fase !== ""'>
        <label for="Fase">Fase</label>
        <input type="text"
          name="Fase"
          disabled
          [value]="confirmPersonal.contactDetails.fase"
          autocomplete="off">
      </div>
      <div class="mobile-container small" *ngIf='confirmPersonal.contactDetails.parcela !== ""'>
        <label for="parcela">Parcela</label>
        <input type="text"
          name="parcela"
          disabled
          [value]="confirmPersonal.contactDetails.parcela"
          autocomplete="off">
      </div>
      <div class="mobile-container">
        <label for="situacionVivienda">Situación de la vivienda</label>
        <input type="text"
          name="situacionVivienda"
          disabled
          [value]="confirmPersonal.contactDetails.situacionVivienda"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <h4>DATOS LABORALES</h4>
    </div>
    <div class="row">
      <div class="mobile-container">
        <label for="situacionLaboral">Situación Laboral</label>
        <input type="text"
          name="situacionLaboral"
          disabled
          placeholder="Situación Laboral"
          [value]="confirmPersonal.laborDetails.situacionLaboral"
          autocomplete="off">
      </div>
      <div class="mobile-container" *ngIf='confirmPersonal.laborDetails.tipoContrato !== ""'>
        <label for="tipoContrato">Tipo de contrato</label>
        <input type="text"
          name="tipoContrato"
          disabled
          placeholder="Tipo de Contrato"
          [value]="confirmPersonal.laborDetails.tipoContrato"
          autocomplete="off">
      </div>
      <div class="mobile-container" *ngIf='confirmPersonal.laborDetails.tipoActividad !== ""'>
        <label for="tipoActividad">Tipo de actividad profesional</label>
        <input type="text"
          name="tipoActividad"
          disabled
          placeholder="Tipo de actividad profesional"
          [value]="confirmPersonal.laborDetails.tipoActividad"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="mobile-container" *ngIf='confirmPersonal.laborDetails.fechaAltaAutonomo !== ""'>
        <label for="fechaAltaAutonomo">Fecha de alta como autónomo</label>
        <input type="text"
          name="fechaAltaAutonomo"
          disabled
          placeholder="Fecha de alta como autónomo"
          [value]="confirmPersonal.laborDetails.fechaAltaAutonomo"
          autocomplete="off">
      </div>
      <div class="mobile-container" *ngIf='confirmPersonal.laborDetails.nombreEmpresa !== ""'>
        <label for="empresaActual">Empresa actual</label>
        <input type="text"
          name="empresaActual"
          disabled
          placeholder="Empresa actual"
          [value]="confirmPersonal.laborDetails.nombreEmpresa"
          autocomplete="off">
      </div>
      <div class="mobile-container" *ngIf='confirmPersonal.laborDetails.antiguedadLaboral !== ""'>
        <label for="antiguedadLaboral">Antiguedad Laboral</label>
        <input type="text"
          name="antiguedadLaboral"
          disabled
          placeholder="Antiguedad Laboral"
          [value]="confirmPersonal.laborDetails.antiguedadLaboral"
          autocomplete="off">
      </div>
    </div>
    <div class="btn-group">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="onSubmit()">CONFIRMO QUE ESTÁN CORRECTOS</button>
    </div>
  </form>
  </div>
</div>
