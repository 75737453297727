import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { Persona } from '../../../collections/persona';

import { FormValidatorService } from '../../../services/form-validator.service';
import { ModalService } from '../../../services/modal.service';
import { AuthService } from '../../../services/auth.service';
import { ErrorService } from '../../../services/error.service';
import { DeviceService } from '../../../services/device.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { SpecificAnalyticsService } from '../../../services/specific-analytics.service';
import { environment } from '../../../../environments/environment';
import { HeaderService } from '../../../services/headers.service';
import { ACCOUNTS } from 'app/resources/account.model';

@Component({
  selector: 'identify-details',
  templateUrl: './identify-details.component.html',
  styleUrls: ['./identify-details.component.scss'],
  providers: [FormValidatorService, ModalService, DeviceService]
})
export class IdentifyDetailsComponent implements OnInit {

  @Input() username: string = '';
  identifyDetailsForm: FormGroup;
  mobileErrors = [];
  modalIdVideo = 'video-modal';
  modalSpecVideo = 'spec-dni-modal';
  modalSpecIban = 'spec-iban-modal';
  modalIphone = 'modalIphone';
  modalInfoEid = 'modalInfoEid';
  videoUrl: String = '';
  toggle: Boolean = false;
  persona: Persona;
  loading: boolean = false;
  eidDisabled: boolean = false;
  private click: any;
  msgSrvError: string = '';
  modalSrvError: string = 'modal-srv-error';
  public isYoungAccount: boolean = false;
  public noIexplorer: boolean = true;
  cameraAvailable: boolean = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private evoValidator: FormValidatorService,
    public modService: ModalService,
    public devService: DeviceService,
    public errService: ErrorService,
    private specificAnalytics: SpecificAnalyticsService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService
  ) {
    // this.createForm();
  }

  ngOnInit() {
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    //https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    if (!!window['MSInputMethodContext'] && !!document['documentMode']) this.noIexplorer = !this.noIexplorer;
    //Comprobamos si el dispositivo tiene camara disponible
    this.checkCameraAvaible();
  }
  setVideoClass() {
    if (this.devService.isIphone()) {
      let iPhoneVersion = this.devService.getIphoneVersion();
      if (iPhoneVersion != undefined && iPhoneVersion < 11) {
        return 'disabled';
      } else {
        return '';
      }
    }
  }
  onSubmit() {
    this.setTipoIdentificacionProspec();
    this.sendToIdentification();
  }
  redirect() {
    //if (!this.devService.isMobile())
      this.onSubmit();
  }
  showModalEid() {
    this.modService.open(this.modalInfoEid);
  }
  sendToIdentification() {
    this.loading = true;
    this.authService.updateProspect(this.persona).subscribe((data) => {
      // this.loading = false;
      if (data.response.codigo === 'KO') {
        this.msgSrvError = !!data.response.message ? data.response.message : 'Error genérico';
        this.modService.open(this.modalSrvError);
        return
      }
      let nextstep = 'identificacion-video';

      if (this.authService.isLoggedIn) {
        if (this.click.id === 'video') {
          if (this.devService.isIphone()) {
            // this.modService.open(this.modalIphone);
            return;
          }
          nextstep = 'identificacion-video';
        } else {
          nextstep = 'identificacion-iban';
        }
        //this.helpers.navigateTo(nextstep);
        // this.loading = true;
        this.headerSrv.getRefreshToken().subscribe(
          data => {
            this.loading = false;
            this.helpers.navigateTo(nextstep);
          },
          error => {
            this.loading = false;
            console.log(error);
          });
      }
    }, error => {
      this.error();
    });
  }

  error() {
    this.errService.navToError();
  }

  setTipoIdentificacionProspec() {
    const canal = this.devService.getDevice();
    this.specificAnalytics.emitIdentificationType(this.click.id);
    //const checkPubli =
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina
      },
      otrosDatos: {
        tipoIdentificacion: (this.click.id === 'video') ? 'identificacion-video' : 'identificacion-iban'
      }
    })
  }
  getSpecify(s) {
    if (s === 'video') {
      this.analytics.triggerInteractionData('Acceso a información sobre identificación', 'video: especificaciones');
      this.modService.open(this.modalSpecVideo);
    } else {
      this.analytics.triggerInteractionData('Acceso a información sobre identificación', 'iban: especificaciones');
      this.modService.open(this.modalSpecIban);
    }
  }

  getVideo(id) {
    this.analytics.triggerInteractionData('Acceso a información sobre identificación', 'video: videotutorial');
    this.videoUrl = `https://www.youtube.com/embed/${id}?controls=1&vq=large&autoplay=0&rel=0`;
    this.modService.open(this.modalIdVideo);
  }

  toggleIdentify(el, e)  {
    this.click = undefined;
    for (let i = 0; i < el.children.length; i++) {
      const item = el.children[i];
      //item.classList.add('not-selected');
    }
    //e.currentTarget.parentElement.classList.remove('not-selected');
    this.click = e.currentTarget;
  }

  redirectToIban() {
    this.helpers.navigateTo('identificacion-iban');
  }

  isDisabled(el) {
    if (this.click) return false;
    return true;
  }

  checkCameraAvaible(){

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      return false;
    }
    navigator.mediaDevices.enumerateDevices().then((devices)=> {
    devices.forEach((device)=>{
    if((device.kind=="videoinput")){
       this.cameraAvailable=true;
    }
   });
      if(!this.cameraAvailable){
        console.error("Camara no detectada");

      }
  }).catch(function(err) {
    console.log(err.name + ": " + err.message);
  });
  }

  clickLink(identify, event){
    //Si el dispositivo es un móvil al pinchar en el cuadro, te muestra las instrucciones necesarias
    if(this.devService.isMobile()){
      this.toggleIdentify(identify, event);
      this.redirect();
    }else{
      return;
    }
  }
}
