<mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray">
</mobile-error-zone>

<loading [showMe]="loading"></loading>

<modal [modalTitle]="'NoEsCliente'" [modalId]='modalNoCliente' class="modal modal-noCliente">
  <p>
    Vaya, parece que aún no eres cliente de EVO Tienes que abrir una cuenta para poder continuar la contratación de
    inversiones como segundo titular. ¡Coge tu DNI!
  </p>
  <div class="button-center">
    <div class="equal-buttons">
      <div class="access" name="button1">
        <a href="https://cuentajoven.evobanco.com/#/cuenta-joven/bienvenido" target="_blank">
          <span>Abrir Cuenta Joven</span>
        </a>
      </div>
      <div class="access" name="button2">
        <a href="https://v2.cuentainteligente.evobanco.com/#/bienvenido" target="_blank">
          <span>Abrir Cuenta Inteligente</span>
        </a>
      </div>
    </div>
  </div>
</modal>


<modal [modalTitle]="'login'" [modalId]='login' class='modal'>
  <div class="image-modal"></div>
  <div class="false-container">
    <h2>ALGO NO NOS CUADRA</h2>
    <p>Revisa tus datos.</p>
  </div>
</modal>


<div class="heading">
  <h3>CUENTA INTELIGENTE: LA CUENTA QUE AHORRA POR TI.</h3>
  <p>Accede para añadir un segundo titular a tu Cuenta Inteligente.</p>
</div>

<div class="login content-wrapper">
  <div class="image"></div>
  <div class="content-contain">
    <p *ngIf="errMsg" class="err-login">
      {{errMsg}}
    </p>
    <p>
      <label class="intro">INTRODUCE TUS DATOS</label>
    </p>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginUser" (ngSubmit)="sendClient()" novalidate>
      <div class="row">
        <div class="mobile-container">
          <label for="dni">NIF/NIE</label>
          <input autofocus maxlength="9" type="text" name="user" id="dni" formControlName="user" 
            autocomplete="off" (blur)="validUser()"
            (focus)='scrollToField()'>
          <div class="error-text" *ngIf="errService.desktopErrors(loginUser.controls.user, 'user')">
            {{errService.desktopErrors(loginUser.controls.user, 'user')}}
          </div>
        </div>
        <div class="mobile-container">
          <label for="claveSMS">Contraseña</label>
          <input type="password" #password name="pass" onfocus="blur(); click()" (click)="setClassFocus()"
            formControlName="pass" [modal]='false' [horizontal]='true' keyboard
            (numberEmmited)="numberEmmited($event)" autocomplete="off" (deleteLast)="deleteLast($event)">
          <div class="error-text" *ngIf="errService.desktopErrors(loginUser.controls.pass,'pass')">
            {{errService.desktopErrors(loginUser.controls.pass,'pass')}}
          </div>
        </div>
        <div class="mobile-container button-top-index">
          <button type="submit" class="btn-common btn-common_fix_bold" name="button"
            (click)="closeKeyboard()">Enviar</button>
        </div>
      </div>
    </form>
    <div class="row">
      <p class='label'>
        <b>¿No tienes claves para entrar?</b> Llámanos al
        <a class="link-blue" href="tel:910900900">910 900 900</a> y te ayudaremos.
      </p>
    </div>
  </div>
</div>