import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { HelperService } from '../../services/helpers.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements AfterViewInit {

  private _breadcrumbs: Array<any>;

  @Input()
  public set breadcrumbs(value: Array<any>) {
    this._breadcrumbs = value.slice(0);
  }

  public get breadcrumbs(): Array<any> {
    return this._breadcrumbs;
  }

  @Input('breadcrumbAdd') breadcrumbAdd: string;

  constructor(
    private helpers: HelperService,
    private cdRef: ChangeDetectorRef
  ) {

  }

  ngAfterViewInit() {
    const index = this.breadcrumbs.findIndex((element) => {
      return element['name'] === this.breadcrumbAdd;
    });

    if (index !== -1) {
      this.breadcrumbs.splice(index + 1, this.breadcrumbs.length - index);
    } else {
      this.breadcrumbs.push({name: this.breadcrumbAdd, link: ''});
    }
    this.cdRef.detectChanges();
  }

  fnClick(step) {
    if(step !== '')
      this.helpers.navigateTo(step);
  }

}
