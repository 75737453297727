import { AnalyticsService } from 'app/services/analytics.service';
import { TokenInfoService } from './../../../services/token-info.service';
import { Component, OnInit, Input, Output, OnChanges, EventEmitter  } from '@angular/core';
import { environment } from 'environments/environment';
import { ConstantsService } from 'app/services/constants.service';

declare var callC2CJSWS: any;
declare var callC2CJSWSUAT: any;
@Component({
  selector: 'app-footer-center',
  templateUrl: './footer-center.component.html',
  styleUrls: ['./footer-center.component.scss']
})

export class FooterCenterComponent implements OnInit {

  constructor(
    private tokenInfoService: TokenInfoService,
    private analytics: AnalyticsService
  ) { }

  @Input('showCookies') showCookies: boolean;

  @Input('showGlance') showGlance: boolean = false;

  ngOnInit() {

  }

  callMeModal() {
    const phone = this.tokenInfoService.getPhone();
    const isCinv = sessionStorage.getItem("productoContratando") == ConstantsService.AccountTypes.CuentaInversora.code;
    this.analytics.triggerC2C(isCinv);
    if (environment.production) {
      callC2CJSWS(phone);
    } else {
      callC2CJSWSUAT(phone);
    }
  }

}
