<footer class="footer">
  <div class="logo-container">
    <h3 class="logo"></h3>
  </div>
  <div class="contact-links">
    <div class="question">
      <span class="call-text">¿Tienes dudas?:</span>
    </div>
    <div class="call-button" (click)="callMeModal()">
      <img class="icon-info" src="assets/images/ic_operadora_rosa.svg">
      <span class="call-text">Te llamamos</span>
    </div>
    <div class="glance-button" glance_button="startSession">
      <img class="icon-info" src="assets/images/ic_pantallas_flechas_rosa_s.svg">
      <span class="glance-text">Compartir pantalla</span>
      <div class="frame"></div>
    </div>
  </div>
  <div class="logo-container mobile">
    <h3 class="logo"></h3>
  </div>
  <!--
  <p *ngIf="!showCookies"></p> -->
</footer>
