
export class ValidateSecurizedOtp {

  code : string = "";
  otpId : string = "";
  canal: string = "";
  estado : string = "";
  indPubliPe : boolean = false;
  codProductoContratado: string = "";
  origenPromocion: string = "";
  oficina: string = "";
  promocion: boolean = true;
  origen: string = "";
  nombre: string = "";
  apellido1: string = "";
  apellido2: string = "";
  idExterno: string = "";
  email: string = "";
  password: string = "";
  indConsentimientoTratamientoTipoA: boolean = false;
  proveedor: string = ""

  constructor(values : Object = {}){
    Object.assign(this, values);
  }

}

export class ValidateSecurizedOtpTypeA {

  code : string = "";
  otpId : string = "";
  canal: string = "";
  estado : string = "";
  indPubliPe : boolean = false;
  codProductoContratado: string = "";
  origenPromocion: string = "";
  oficina: string = "";
  promocion: boolean = true;
  origen: string = "";
  nombre: string = "";
  apellido1: string = "";
  apellido2: string = "";
  idExterno: string = "";
  email: string = "";
  password: string = "";
  indConsentimientoTratamientoTipoA: boolean = false;
  indConsentimientoTipoA: boolean = false;
  indPerfiladoCliente: boolean = false;
  proveedor: string = ""

  constructor(values : Object = {}){
    Object.assign(this, values);
  }

}

export class ValidateSecurizedOtpResponse {

  validated: boolean;
  response: [{
      codigo: string;
      message: string;
  }];
  idPersona: string;
  fullonlineToken: string;
  refreshToken: string;
  operationErrorCode: string;
  operationErrorMessage: string;

  constructor(values : Object = {}){
    Object.assign(this, values);
  }

}
