<section class="c-header--full"> 
  <div class="c-header--bginv ">
    <div class="responsive-header">
      <div class="backbutton" (click)="botonVolver()">
        <i class="evo flecha"></i>
      </div>
      <div *ngIf="!esFondoInteligente" class="page-title">
        <span>FONDOS Y VALORES</span>
      </div>
      <div *ngIf="esFondoInteligente" class="page-title">
        <span>FONDO INTELIGENTE</span>
      </div>
    </div>
    <div class="c-header__wizard">
      <p class="c-header__wizard__title">DOCUMENTACIÓN</p>
    </div>
  </div>
</section>
<form class="form" action="">
    <div class="main-content">

      <section *ngIf="currentDocument === 'infoMifid' && (showNoCatDocuments === true || showNoCatDocuments === 'true')" class="info--container--doc">
        <pdf-viewer-evo 
          title="Pack de Bienvenida MIFID" 
          button='HE LEÍDO Y ACEPTO' 
          pdfSrc="assets/pdf/InformacionMIFID.pdf">
        </pdf-viewer-evo>
      </section>

      <section *ngIf="currentDocument === 'infoMifid' && (showNoCatDocuments === false || showNoCatDocuments === 'false')" class="info--container--doc">
        <pdf-viewer-evo 
          title="Información MIFID" 
          button='HE LEÍDO Y ACEPTO' 
          pdfSrc="assets/pdf/InformacionMIFID.pdf">
        </pdf-viewer-evo>
      </section>

      <section *ngIf="currentDocument === 'poliGestion' && !esFondoInteligente" class="info--container--doc">
        <pdf-viewer-evo 
          title="Política de Gestión de Orden" 
          button='HE LEÍDO Y ACEPTO' 
          pdfSrc="assets/pdf/Politica-Gestion-Ordenes-EVO.PDF">
        </pdf-viewer-evo>
      </section>

      <section *ngIf="currentDocument === 'poliConflic' && !esFondoInteligente" class="info--container--doc">
        <pdf-viewer-evo 
          title="Política Conflicto de Interés Grupo EVO" 
          button='HE LEÍDO Y ACEPTO' 
          pdfSrc="assets/pdf/NORMA_GENERAL_SOBRE_POLITICA_CONFLICTOS_INTERES_EVO.PDF">
        </pdf-viewer-evo>
      </section>

      <section *ngIf="currentDocument === 'poliOpti' && !esFondoInteligente" class="info--container--doc">
        <pdf-viewer-evo 
          title="Política Óptima Ejecución EVO" 
          button='HE LEÍDO Y ACEPTO' 
          pdfSrc="assets/pdf/Politica-Optima-Ejecucion-EVO.PDF">
        </pdf-viewer-evo>
      </section>

      <section *ngIf="currentDocument === 'poliSalva' && !esFondoInteligente" class="info--container--doc">
          <pdf-viewer-evo 
            title="Política Salvaguarda de Activos EVO" 
            button='HE LEÍDO Y ACEPTO' 
            pdfSrc="assets/pdf/Politica-de-Salvaguarda-Activos-EVO.PDF">
          </pdf-viewer-evo>
      </section>

      <section *ngIf="currentDocument === 'poliIncen' && !esFondoInteligente" class="info--container--doc">
          <pdf-viewer-evo 
            title="Política Incentivos" 
            button='HE LEÍDO Y ACEPTO' 
            pdfSrc="assets/pdf/POLITICA-INCENTIVOS-EN-PRESTACION-SERVICIOS-DE-INVERSION.PDF">
          </pdf-viewer-evo>
      </section>

      <section *ngIf="currentDocument === 'folleto' && !esFondoInteligente" class="info--container--doc">
          <pdf-viewer-evo 
            title="Folleto de Tarifas Valores" 
            button='HE LEÍDO Y ACEPTO' 
            pdfSrc="assets/pdf/Tarifas_valores_EVO.pdf">
          </pdf-viewer-evo>
      </section>

    <section *ngIf="currentDocument === 'condicionesSegtit' && !esFondoInteligente" class="info--container--doc">
      <div class="inner__content info">
        <p class="info-title">TERMINOS & CONDICIONES</p>
        <p class="info-description">Confirmo haber obtenido el consentimiento para que EVO Banco pueda contactar y
          tratar lo
          datos personales de la persona que facilito en este apartado, con la finalidad de solicitar la contratación
          de la
          Cuenta Inversora como segundo titular.</p>
      </div>
      <div class="btn-group_fix">
        <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="acceptTerms()">
          HE LEÍDO Y ACEPTO
        </button>
      </div>
    </section>
    
    <section *ngIf="currentDocument === 'condiciones'" class="info--container--doc-datos">
      <div class="inner__content info">
        <p class="info-title">Información básica de protección de datos de carácter personal</p>
          <p class="info-title">1.    RESPONSABLE DEL TRATAMIENTO</p>
          <p class="info-description">En  cumplimiento  del  Reglamento  General  de  Protección  de  Datos,  así  como  el  resto  de normativa aplicable, le informamos que el responsable del tratamiento de sus datos personales es  EVO  BANCO,  S.A.  (en  adelante,  “EVO”)  con  C.I.F.  A70386024,  domicilio  social  en  Calle  Don Ramón  de  la  Cruz,  número  84,  (28006,  Madrid)  y  teléfono  910900900.  EVO  ha  nombrado  un Delegado de Protección de Datos con el que podrá contactar a través de la siguiente dirección de correo electrónico: dpo.evo@evobanco.com.</p>
          <p class="info-title">2.    TRATAMIENTO DE DATOS EFECTUADO POR EVO: FINALIDADES Y SU LEGITIMACIÓN</p>
          <p class="info-description">Como consecuencia de la solicitud y contratación del Fondo Inteligente, EVO tratará sus datos con la finalidad de formalizar, mantener y ejecutar tal relación precontractual y, en el caso de que  ésta  se  complete,  con  el  fin  de  gestionar  la  relación  contractual.  La  base  de  legitimación para  ello  es  la  propia  relación  contractual.  Adicionalmente,  en  cumplimiento  de  la  normativa aplicable, EVO tratará sus datos para atender sus obligaciones legales, en concreto, entre otras, sus obligaciones de naturaleza tributaria y en materia de mercados de instrumentos financieros. La base de legitimación para ello es el cumplimiento de la obligación legal.</p>
          <p class="info-description">Así  mismo,  EVO,  como  responsable  del  tratamiento,  puede  tratar  sus  datos  con  base  en  el interés  legítimo,  de  acuerdo  con  el  artículo  6.1  f)  del  Reglamento  General  de  Protección  de Datos. Derivado de la contratación del Fondo Inteligente, EVO podrá tratar sus datos con base en su interés legítimo para las siguientes finalidades:</p>
          <ul class="info-list">
            <li class="info-list-item">Consultar  sus  datos  en  los  sistemas  comunes  de  información  crediticia  para evaluar su solvencia. </li>
            <li class="info-list-item">Prevenir situaciones de fraude relacionadas con los productos y/o servicios que tenga contratados con el EVO.</li>
            <li class="info-list-item">Informarle   sobre   productos   y/o   servicios   similares   a   los   que   usted   tiene contratados con nosotros.</li>
            <li class="info-list-item">Elaborar un perfil comercial sobre usted a partir de los datos que nos ha facilitado y  los  derivados  de  los  productos  y/o  servicios  que  tenga  contratados  con  EVO para personalizar las acciones comerciales sobre los mismos.</li>
          </ul>
      
          <p class="info-title">3.    DATOS TRATADOS POR EVO</p>
          <p class="info-description">Los datos tratados por EVO como consecuencia de la contratación del Fondo Inteligente son los siguientes:</p>
          <ul class="info-list">
            <li class="info-list-item">Datos facilitados por usted cuando se ha dado de alta como cliente en el Banco y los que ha proporcionado para la contratación de distintos productos y/o servicios, incluyendo los necesarios que debe proporcionar en los mencionados cuestionarios previamente a la contratación de cualquier producto y/o servicio de inversión.</li>
            <li class="info-list-item">Datos  obtenidos  y  generados  como  consecuencia  del  desarrollo  de  los  distintos productos y/o servicios que tenga contratados con EVO.</li>
          </ul>
          <p class="info-title">4.    DESTINATARIOS</p>
          <p class="info-description">No  se  cederán  sus  datos  a  terceros,  salvo  que  tengamos  su  consentimiento  previo  o  sea necesario por obligación legal o para la ejecución del contrato. </p>
          <p class="info-title">5.    DURACIÓN DEL TRATAMIENTO</p>
          <p class="info-description">Los datos serán tratados para esta finalidad mientras usted mantenga una relación contractual con  EVO  y,  en  el  caso  de  que  dejase  de  ser  cliente,  hasta  que  prescriban  las  acciones  que pudieran ejercitarse para la defensa o intereses legítimos del Banco o sus clientes o para atender a posibles reclamaciones.</p>
          <p class="info-title">6.    DERECHOS</p>
          <p class="info-description">Puedes   ejercer   sus   derechos   de   acceso,   rectificación,   supresión,   oposición,   limitación   y portabilidad a través de la dirección de correo electrónico protecciondedatos@evobanco.com. Se le informa, asimismo, de su derecho a presentar una reclamación ante la Agencia Española de Protección de Datos.</p>
          <p class="info-description">Puedes obtener información ampliada sobre cómo tratamos sus datos personales a través del siguiente  enlace  a  nuestra  Política  de  Privacidad: <a class="info__link" target="_blank" href="https://www.evobanco.com/politica-de-privacidad/">https://www.evobanco.com/politica-de-privacidad/</a></p>
        </div>
      <div class="btn-group_fix">
        <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="acceptTerms()">
          HE LEÍDO Y ACEPTO
        </button>
      </div>
    </section>

    <section *ngIf="currentDocument === 'consentimiento'" class="info--container--doc">
      <div class="inner__content info">
        <p class="info-title">CONSENTIMIENTO DE COMUNICACIONES</p>
        <p class="info-description">¿Consientes recibir comunicaciones comerciales sobre productos o servicios de
          terceros, adaptados a tu perfil, que Evo comercialice por cualquier medio y la cesión de datos a terceros con
          esa finalidad, así como recibir o acceder a ofertas sobre productos no similares a los contratados con
          nosotros?</p>
      </div>
      <div class="btn-group_fix">
        <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="acceptTerms()">
          HE LEÍDO Y ACEPTO
        </button>
      </div>
    </section>

  </div>
</form>