import { Component, Input } from '@angular/core';

@Component({
  selector: 'desktop-error-zone',
  templateUrl: './desktop-error-zone.component.html',
  styleUrls: ['./desktop-error-zone.component.scss']
})
export class DesktopErrorZoneComponent{

  public screenWidth: number;
  @Input() errors: any ;

  constructor() {
  }
}
