import { Component, HostListener,  OnInit, OnDestroy,  AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute, Params  } from '@angular/router';
// import { Ng2PicaService } from 'ng2-pica';

import { Persona } from '../../../collections/persona';
import { AuthService } from '../../../services/auth.service';
import { PersonaService } from '../../../services/persona.service';
import { FormValidatorService } from '../../../services/form-validator.service';
import { ErrorService } from '../../../services/error.service';
import { ModalService } from '../../../services/modal.service';
import { DeviceService } from '../../../services/device.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { HeaderService } from '../../../services/headers.service';
import { JsonService } from '../../../services/json.service';
import { ACCOUNTS } from 'app/resources/account.model';


@Component({
  selector: 'update-dni-specs',
  templateUrl: './update-dni-specs.component.html',
  styleUrls: ['./update-dni-specs.component.scss'],
  providers: [ModalService, FormValidatorService, JsonService, DeviceService]
})
export class UpdateDniSpecsComponent implements OnInit, OnDestroy, AfterViewInit {

  public param;
  public showHeader = true;
  public showFooter = true;

  constructor(public errService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private helpers: HelperService,
    private changeDetector: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.origen) {
        this.param = params.origen.toLowerCase();
        if (this.param === "bmi") {
          this.showHeader = false;
          this.showFooter = false;
        }
      }
    });
   
  }
  ngOnDestroy(): void {
    
  }

  ngAfterViewInit() {
   
  }

  close() {
    this.helpers.navigateTo("/subir-documentacion");
  }

}
