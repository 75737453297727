import { Injectable } from '@angular/core';
import { Logalty, setCheckLogalty, CheckLogalty, signLogalty, LogaltyAmpliacion, LogaltyMicroseg, LogaltyInversora, LogaltyInversoraTwice} from '../collections/logalty';
import { ApiLogaltyService } from './api/api.logalty.service';
import { Observable } from 'rxjs';

@Injectable()
export class LogaltyService {

  constructor(private api: ApiLogaltyService) { }

  signLogalty(logalty:Logalty):Observable<signLogalty>{
    return this.api.setSign(logalty);
  }

  signLogaltyAmpliacion(logaltyAmpliacion:LogaltyAmpliacion):Observable<signLogalty>{
    return this.api.setSignAmpliacion(logaltyAmpliacion);
  }

  signLogaltyCuentaInversora(logaltyInversora:LogaltyInversora):Observable<signLogalty>{
    return this.api.setSignCuentaInversora(logaltyInversora);
  }

  signLogaltyCuentaInversoraTwice(logaltyInversoraTwice:LogaltyInversoraTwice):Observable<signLogalty>{
    return this.api.setSignCuentaInversoraTwice(logaltyInversoraTwice);
  }

  signTwiceLogalty(logalty: Logalty): Observable<signLogalty> {
    return this.api.setSignTwice(logalty);
  }

  signLogaltyMicroseg(logalty: LogaltyMicroseg): Observable<signLogalty> {
    return this.api.setSignMicroseg(logalty);
  }

  setUpdateSign(logalty:Logalty):Observable<signLogalty>{
    return this.api.setUpdateSign(logalty);
  }

  checkState(logalty:setCheckLogalty):Observable<CheckLogalty>{
    return this.api.checkState(logalty);
  }

  logaltyMicroseg(logalty){
    return this.api.logaltyMicroseg(logalty);
  }
}
