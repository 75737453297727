import { AnalyticsService } from 'app/services/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from './../../services/session-storage.service';
import { environment } from './../../../environments/environment.uat.fint';
import { KeycloakService } from 'keycloak-angular';
import { Component, Input, OnInit } from '@angular/core';
import { ConstantsService } from 'app/services/constants.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-message-screen',
  templateUrl: './message-screen.component.html',
  styleUrls: ['./message-screen.component.scss']
})
export class MessageScreenComponent implements OnInit {

  @Input()
  public message = ConstantsService.FinalMessages.SesionFinalizada;
  @Input()
  public isProductoInv = false;

  constructor(
    private keycloak: KeycloakService,
    private sessionStorageSrv: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private analytics: AnalyticsService) { }

  ngOnInit(): void {

  }

  redirect(destino:string) {

    const codProducto = this.sessionStorageSrv.getItem('productoContratando');
    let redirectTo = `${location.protocol}//${location.host}/#`;

    // No se deben enviar los params token y refresh al orquestador
    let snapshot = this.activatedRoute.snapshot;
    const params = { ...snapshot.queryParams };
    delete params.token;
    delete params.refresh;
    const queryParamsString = new HttpParams({ fromObject: params }).toString();

    switch (codProducto) {
      case ConstantsService.AccountTypes.AmpliacionLimite.code:
        redirectTo += ConstantsService.firstPageAmpliacion;
        break;
      case ConstantsService.AccountTypes.CuentaInversora.code:
      case ConstantsService.AccountTypes.CuentaInversoraCorto.code:
      case ConstantsService.AccountTypes.FondoInteligente.code:
      default:
        redirectTo += ConstantsService.firstPageProductoInversion;
        break;
    }

    redirectTo += `?${queryParamsString}`;

    switch (destino) {
      case 'GOTOEVO':
        this.analytics.triggerInteractionDataNew(codProducto, 'Acceso a distributiva', 'Error - 97');
        redirectTo = 'https://www.evobanco.com/inversion';
        sessionStorage.clear();
        this.keycloak.logout(redirectTo);
        break;
      case 'LOGOUT':
        this.analytics.triggerInteractionDataNew(codProducto, 'Acceso a Login', 'Volver Página Error - 401');
      default:
        sessionStorage.clear();
        this.keycloak.logout(redirectTo);
        return;
        break;
    }

  }

}
