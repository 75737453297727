export class InversisInvestmentsResponse {
  Accounts: InversisAccount[] | InversisAccount;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  };
}

export class InversisAccount {
  saldoLimite: string;
  saldoDeudaImpg: string;
  acuerdo: string;
  saldoNoVencido: string;
  iban: string;
  nombrePdv: string;
  saldoNoDispuesto: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  };
}

export class InversisSessionRequest {
  datosDesconexion: DatosDesconexion;
  datosOrden: DatosOrden;
  datosCuenta: DatosCuenta;
  resultMessage: string;
  operationErrorMessage: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  };
}

export class DatosDesconexion {
  urlLogout: string;
  indicadorLogout: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  };
}

export class DatosOrden {
  tipoFuncionalidad: string;
  isinOrigen: string;
  isinDestino: string;
  codigoMercadoOrigen: string;
  codigoMercadoDestino: string;
  codigoTipoOperacion: string;
  numeroCuentaOrigen: string;
  soloOperativa: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  };
}

export class DatosCuenta {
  numeroCuenta: string;
  numeroCuentaExterna: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  };
}

export class InversisSessionResponse {
  urlAccion: string;
  respuestaBackoffice: RespuestaBackoffice;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  };
}

export class RespuestaBackoffice {
  codigoError: number;
  codigoErrorInterno: number;
  descripcionError: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  };
}
