export const CNAE = [
  {name: 'Tipo de actividad económica', value: null, nameSort:null},
  { value: '0111', nameSort: 'CULT. CEREALES', name: 'CULTIVO DE CEREALES (EXCEPTO ARROZ), LEGUMINOSAS Y'},
  { value: '0112', nameSort: 'CULTIVO DE ARROZ', name: 'CULTIVO DE ARROZ'},
  { value: '0113', nameSort: 'CULTIVO HORTALIZAS', name: 'CULTIVO DE HORTALIZAS, RAICES Y TUBERCULOS'},
  { value: '0114', nameSort: 'CULT. CAÑA AZUCAR', name: 'CULTIVO DE CAÑA DE AZUCAR'},
  { value: '0115', nameSort: 'CULTIVO DE TABACO', name: 'CULTIVO DE TABACO'},
  { value: '0116', nameSort: 'CULT. PLANT. FIBRAS', name: 'CULTIVO DE PLANTAS PARA FIBRAS TEXTILES'},
  { value: '0119', nameSort: 'CULTIVO NO PERENNES', name: 'OTROS CULTIVOS NO PERENNES'},
  { value: '0121', nameSort: 'CULTIVO VID', name: 'CULTIVO DE LA VID'},
  { value: '0122', nameSort: 'CULT. FRUTOS TROPIC.', name: 'CULTIVO DE FRUTOS TROPICALES Y SUBTROPICALES'},
  { value: '0123', nameSort: 'CULTIVO CÍTRICOS', name: 'CULTIVO DE CITRICOS'},
  { value: '0124', nameSort: 'CULT. FRUT. HUESO', name: 'CULTIVO DE FRUTOS CON HUESO Y PEPITAS'},
  { value: '0125', nameSort: 'CULT. OTROS ARBOLES', name: 'CULTIVO DE OTROS ARBOLES Y ARBUSTOS FRUTALES Y FRU'},
  { value: '0126', nameSort: 'CULT FRUT. OLEAGINOS', name: 'CULTIVO DE FRUTOS OLEAGINOSOS'},
  { value: '0127', nameSort: 'CULT. PLANT. BEBIDAS', name: 'CULTIVO DE PLANTAS PARA BEBIDAS'},
  { value: '0128', nameSort: 'CULTIVO ESPECIAS', name: 'CULTIVO DE ESPECIAS, PLANTAS AROMATICAS, MEDICINAL'},
  { value: '0129', nameSort: 'OTRS CULT PERENNES', name: 'OTROS CULTIVOS PERENNES'},
  { value: '0130', nameSort: 'PROPAGACIÓN PLANTAS', name: 'PROPAGACION DE PLANTAS'},
  { value: '0141', nameSort: 'EXP. GANADO BOVINO', name: 'EXPLOTACION DE GANADO BOVINO PARA LA PRODUCCION DE'},
  { value: '0142', nameSort: 'OTRS GANAD BOVINO', name: 'EXPLOTACION DE OTRO GANADO BOVINO Y BUFALOS'},
  { value: '0143', nameSort: 'OTRS GANAD BOVINO', name: 'EXPLOTACION DE CABALLOS Y OTROS EQUINOS'},
  { value: '0144', nameSort: 'CAMELLO OTR CAMÉLIDO', name: 'EXPLOTACION DE CAMELLOS Y OTROS CAMELIDOS'},
  { value: '0145', nameSort: 'GANADO OVINO', name: 'EXPLOTACION DE GANADO OVINO Y CAPRINO'},
  { value: '0146', nameSort: 'GANADO PORCINO', name: 'EXPLOTACION DE GANADO PORCINO'},
  { value: '0147', nameSort: 'AVICULTURA', name: 'AVICULTURA'},
  { value: '0149', nameSort: 'OTROS GANADOS', name: 'OTRAS EXPLOTACIONES DE GANADO'},
  { value: '0150', nameSort: 'AGRÍCOLA Y GANADERA', name: 'PRODUCCION AGRICOLA COMBINADA CON LA PRODUCCION GA'},
  { value: '0161', nameSort: 'ACTIV. AGRICULTURA', name: 'ACTIVIDADES DE APOYO A LA AGRICULTURA'},
  { value: '0162', nameSort: 'ACTIV. GANADERÍA', name: 'ACTIVIDADES DE APOYO A LA GANADERIA'},
  { value: '0163', nameSort: 'ACTIV. POST. COSECHA', name: 'ACTIVIDADES DE PREPARACION POSTERIOR A LA COSECHA'},
  { value: '0164', nameSort: 'TRATAMIENTO SEMILLAS', name: 'TRATAMIENTO DE SEMILLAS PARA REPRODUCCION'},
  { value: '0170', nameSort: 'CAZA', name: 'CAZA, CAPTURA DE ANIMALES Y SERVICIOS RELACIONADOS'},
  { value: '0210', nameSort: 'SILVICULTURA', name: 'SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES'},
  { value: '0220', nameSort: 'EXPLOTACIÓN MADERA', name: 'EXPLOTACION DE LA MADERA'},
  { value: '0230', nameSort: 'EXPLOTACIÓN MADERA', name: 'RECOLECCION DE PRODUCTOS SILVESTRES, EXCEPTO MADER'},
  { value: '0240', nameSort: 'APOYO SELVICULTURA', name: 'SERVICIOS DE APOYO A LA SILVICULTURA'},
  { value: '0311', nameSort: 'PESCA MARINA', name: 'PESCA MARINA'},
  { value: '0312', nameSort: 'PESCA AGUA DULCE ', name: 'PESCA EN AGUA DULCE'},
  { value: '0321', nameSort: 'ACUICULTURA MARINA', name: 'ACUICULTURA MARINA'},
  { value: '0322', nameSort: 'ACUICULTURA DULCE', name: 'ACUICULTURA EN AGUA DULCE'},
  { value: '0510', nameSort: 'EXT. ANTRACITA, HULL', name: 'EXTRACCION DE ANTRACITA Y HULLA'},
  { value: '0520', nameSort: 'EXTRACCIÓN LIGNITO', name: 'EXTRACCION DE LIGNITO'},
  { value: '0610', nameSort: 'EXTRACCIÓN CRUDO', name: 'EXTRACCION DE CRUDO DE PETROLEO'},
  { value: '0620', nameSort: 'EXT. GAS NATURAL', name: 'EXTRACCION DE GAS NATURAL'},
  { value: '0710', nameSort: 'EXT. MINERALES HIERR', name: 'EXTRACCION DE MINERALES DE HIERRO'},
  { value: '0721', nameSort: 'EXT. URANIO Y TORIO', name: 'EXTRACCION DE MINERALES DE URANIO Y TORIO'},
  { value: '0729', nameSort: 'EXT. OTRS. MINERALES', name: 'EXTRACCION DE OTROS MINERALES METALICOS NO FERREOS'},
  { value: '0811', nameSort: 'EXTRACCIÓN PIEDRA', name: 'EXTRACCION DE PIEDRA ORNAMENTAL Y PARA LA CONSTRUC'},
  { value: '0812', nameSort: 'EXTRACCIÓN DE GRAVAS', name: 'EXTRACCION DE GRAVAS Y ARENAS, EXTRACCION DE ARCIL'},
  { value: '0891', nameSort: 'EXTRACCIÓN MINERALES', name: 'EXTRACCION DE MINERALES PARA PRODUCTOS QUIMICOS Y'},
  { value: '0892', nameSort: 'EXTRACCIÓN DE TURBA', name: 'EXTRACCION DE TURBA'},
  { value: '0893', nameSort: 'EXTRACCIÓN DE TURBA', name: 'EXTRACCION DE SAL'},
  { value: '0899', nameSort: 'OTRS. EXTRACCIONES', name: 'OTRAS INDUSTRIAS EXTRACTIVAS N'},
  { value: '0910', nameSort: 'APOYO EXT PETRÓLEO', name: 'ACTIVIDADES DE APOYO A LA EXTRACCION DE PETROLEO Y'},
  { value: '0990', nameSort: 'APOYO OTRS INDUSTRIA', name: 'ACTIVIDADES DE APOYO A OTRAS INDUSTRIAS EXTRACTIVA'},
  { value: '1011', nameSort: 'CONSERVACIÓN CARNE', name: 'PROCESADO Y CONSERVACION DE CARNE'},
  { value: '1012', nameSort: 'CONSERV. VOLATERÍA', name: 'PROCESADO Y CONSERVACION DE VOLATERIA'},
  { value: '1013', nameSort: 'ELABOR PROD CÁRNICOS', name: 'ELABORACION DE PRODUCTOS CARNICOS Y DE VOLATERIA'},
  { value: '1021', nameSort: 'PESCADOS, MOLUSCOS', name: 'PROCESADO DE PESCADOS, CRUSTACEOS Y MOLUSCOS'},
  { value: '1022', nameSort: 'CONSERVAS PESCADO', name: 'FABRICACION DE CONSERVAS DE PESCADO'},
  { value: '1031', nameSort: 'CONSERVACIÓN PATATAS', name: 'PROCESADO Y CONSERVACION DE PATATAS'},
  { value: '1032', nameSort: 'ZUMOS DE FRUTAS', name: 'ELABORACION DE ZUMOS DE FRUTAS Y HORTALIZAS'},
  { value: '1039', nameSort: 'CONSERVACIÓN FRUTAS', name: 'OTRO PROCESADO Y CONSERVACION DE FRUTAS Y HORTALIZ'},
  { value: '1042', nameSort: 'FABRIC. MARGARINA', name: 'FABRICACION DE MARGARINA Y GRASAS COMESTIBLES SIMI'},
  { value: '1043', nameSort: 'FABRIC. ACEITE OLIVA', name: 'FABRICACION DE ACEITE DE OLIVA'},
  { value: '1044', nameSort: 'FABRIC. OTROS ACEITE', name: 'FABRICACION DE OTROS ACEITES Y GRASAS'},
  { value: '1052', nameSort: 'ELABORACIÓN HELADOS', name: 'ELABORACION DE HELADOS'},
  { value: '1053', nameSort: 'FABRICACIÓN QUESOS', name: 'FABRICACION DE QUESOS'},
  { value: '1054', nameSort: 'LECHE Y OTROS LÁCTEO', name: 'PREPARACION DE LECHE Y OTROS PRODUCTOS LACTEOS'},
  { value: '1061', nameSort: 'PRODUCTOS MOLINERA', name: 'FABRICACION DE PRODUCTOS DE MOLINERIA'},
  { value: '1062', nameSort: 'PRODUCTOS AMILÁCEOS', name: 'FABRICACION DE ALMIDONES Y PRODUCTOS AMILACEOS'},
  { value: '1071', nameSort: 'PRODUCT FRESC PANAD', name: 'FABRICACION DE PAN Y DE PRODUCTOS FRESCOS DE PANAD'},
  { value: '1072', nameSort: 'PASTEL LARGA DURACIÓ', name: 'FABRICACION DE GALLETAS Y PRODUCTOS DE PANADERIA Y'},
  { value: '1073', nameSort: 'PASTAS ALIMENTICIAS', name: 'FABRICACION DE PASTAS ALIMENTICIAS, CUSCUS Y PRODU'},
  { value: '1081', nameSort: 'AZÚCAR', name: 'FABRICACION DE AZUCAR'},
  { value: '1082', nameSort: 'CACAO, CHOCOLATES', name: 'FABRICACION DE CACAO, CHOCOLATE Y PRODUCTOS DE CON'},
  { value: '1083', nameSort: 'CAFÉ, TE', name: 'ELABORACION DE CAFE, TE E INFUSIONES'},
  { value: '1084', nameSort: 'ESPECIAS, SALSAS', name: 'ELABORACION DE ESPECIAS, SALSAS Y CONDIMENTOS'},
  { value: '1085', nameSort: 'COMIDA PREPARADA', name: 'ELABORACION DE PLATOS Y COMIDAS PREPARADOS'},
  { value: '1086', nameSort: 'ALIMENTOS DIETÉTICOS', name: 'ELABORACION DE PREPARADOS ALIMENTICIOS HOMOGENEIZA'},
  { value: '1089', nameSort: 'OTRS. PRODUC. ALIMEN', name: 'ELABORACION DE OTROS PRODUCTOS ALIMENTICIOS N'},
  { value: '1091', nameSort: 'PROD. ANIMALES GRANJ', name: 'FABRICACION DE PRODUCTOS PARA LA ALIMENTACION DE A'},
  { value: '1092', nameSort: 'PROD ANIMAL COMPAÑÍA', name: 'FABRICACIÓN DE PRODUCTOS PARA LA ALIMENTACIÓN DE A'},
  { value: '1101', nameSort: 'BEBIDAS ALCOHÓLICAS', name: 'DESTILACION, RECTIFICACION Y MEZCLA DE BEBIDAS ALC'},
  { value: '1102', nameSort: 'VINO', name: 'ELABORACION DE VINOS'},
  { value: '1103', nameSort: 'SIDRA', name: 'ELABORACION DE SIDRA Y OTRAS BEBIDAS FERMENTADAS A'},
  { value: '1104', nameSort: 'BEBIDAS NO DESTILADA', name: 'ELABORACION DE OTRAS BEBIDAS NO DESTILADAS, PROCED'},
  { value: '1105', nameSort: 'CERVEZA', name: 'FABRICACION DE CERVEZA'},
  { value: '1106', nameSort: 'MALTA', name: 'FABRICACION DE MALTA'},
  { value: '1107', nameSort: 'BEBIDAS NO DESTILADA', name: 'FABRICACION DE BEBIDAS NO ALCOHOLICAS, PRODUCCION'},
  { value: '1200', nameSort: 'INDUSTRIA DEL TABACO', name: 'INDUSTRIA DEL TABACO'},
  { value: '1310', nameSort: 'FIBRAS TEXTILES', name: 'PREPARACION E HILADO DE FIBRAS TEXTILES'},
  { value: '1320', nameSort: 'TEJIDOS TEXTILES', name: 'FABRICACION DE TEJIDOS TEXTILES'},
  { value: '1330', nameSort: 'ACABADO DE TEXTILES ', name: 'ACABADO DE TEXTILES'},
  { value: '1391', nameSort: 'TEJIDOS DE PUNTO', name: 'FABRICACION DE TEJIDOS DE PUNTO'},
  { value: '1392', nameSort: 'ARTÍCULOS CON TEXTIL', name: 'FABRICACION DE ARTICULOS CONFECCIONADOS CON TEXTIL'},
  { value: '1393', nameSort: 'ALFOMBRAS Y MOQUETAS', name: 'FABRICACION DE ALFOMBRAS Y MOQUETAS'},
  { value: '1394', nameSort: 'CUERDAS, CORDELES', name: 'FABRICACION DE CUERDAS, CORDELES, BRAMANTES Y REDE'},
  { value: '1395', nameSort: 'TELAS NO TEJIDAS', name: 'FABRICACION DE TELAS NO TEJIDAS Y ARTICULOS CONFEC'},
  { value: '1396', nameSort: 'TEXTILES USO TÉCNICO', name: 'FABRICACION DE OTROS PRODUCTOS TEXTILES DE USO TEC'},
  { value: '1399', nameSort: 'OTROS TEXTILES', name: 'FABRICACION DE OTROS PRODUCTOS TEXTILES N'},
  { value: '1411', nameSort: 'PRENDAS DE VESTIR', name: 'CONFECCION DE PRENDAS DE VESTIR DE CUERO'},
  { value: '1412', nameSort: 'ROPA DE TRABAJO', name: 'CONFECCION DE ROPA DE TRABAJO'},
  { value: '1413', nameSort: 'OTRS PRENDAS VESTIR', name: 'CONFECCION DE OTRAS PRENDAS DE VESTIR EXTERIORES'},
  { value: '1414', nameSort: 'ROPA INTERIOR', name: 'CONFECCION DE ROPA INTERIOR'},
  { value: '1419', nameSort: 'ACCESORIOS', name: 'CONFECCION DE OTRAS PRENDAS DE VESTIR Y ACCESORIOS'},
  { value: '1420', nameSort: 'ARTÍCULOS PELETERÍA', name: 'FABRICACION DE ARTICULOS DE PELETERIA'},
  { value: '1431', nameSort: 'CALCETERÍA', name: 'CONFECCION DE CALCETERIA'},
  { value: '1439', nameSort: 'OTR PRENDAS DE PUNTO', name: 'CONFECCION DE OTRAS PRENDAS DE VESTIR DE PUNTO'},
  { value: '1511', nameSort: 'PREPARACIÓN DEL CUER', name: 'PREPARACION, CURTIDO Y ACABADO DEL CUERO, PREPARAC'},
  { value: '1512', nameSort: 'MARROQUINERÍA', name: 'FABRICACION DE ARTICULOS DE MARROQUINERIA, VIAJE Y'},
  { value: '1520', nameSort: 'FABRICACIÓN CALZADO', name: 'FABRICACION DE CALZADO'},
  { value: '1610', nameSort: 'ASERRADO DE MADERA', name: 'ASERRADO Y CEPILLADO DE LA MADERA'},
  { value: '1621', nameSort: 'TABLEROS DE MADERA', name: 'FABRICACION DE CHAPAS Y TABLEROS DE MADERA'},
  { value: '1622', nameSort: 'SUELOS DE MADERA', name: 'FABRICACION DE SUELOS DE MADERA ENSAMBLADOS'},
  { value: '1623', nameSort: 'OTRS ESTRUCT MADERA', name: 'FABRICACION DE OTRAS ESTRUCTURAS DE MADERA Y PIEZA'},
  { value: '1624', nameSort: 'EMBALAJES DE MADERA', name: 'FABRICACION DE ENVASES Y EMBALAJES DE MADERA'},
  { value: '1629', nameSort: 'OTRS PRODUCT MADERA', name: 'FABRICACION DE OTROS PRODUCTOS DE MADERA, ARTICULO'},
  { value: '1711', nameSort: 'PASTA PAPELERA', name: 'FABRICACION DE PASTA PAPELERA'},
  { value: '1712', nameSort: 'PAPEL Y CARTÓN', name: 'FABRICACION DE PAPEL Y CARTON'},
  { value: '1721', nameSort: 'PAPEL CONDULADO', name: 'FABRICACION DE PAPEL Y CARTON ONDULADOS, FABRICACI'},
  { value: '1722', nameSort: 'PAPEL USO DOMÉSTICO', name: 'FABRICACION DE ARTICULOS DE PAPEL Y CARTON PARA US'},
  { value: '1723', nameSort: 'ARTÍCULOS DE PAPELER', name: 'FABRICACION DE ARTICULOS DE PAPELERIA'},
  { value: '1724', nameSort: 'PAPELES PINTADOS', name: 'FABRICACION DE PAPELES PINTADOS'},
  { value: '1729', nameSort: 'OTRS ARTÍCULOS PAPEL', name: 'FABRICACION DE OTROS ARTICULOS DE PAPEL Y CARTON'},
  { value: '1811', nameSort: 'IMPRESIÓN PERIÓDICOS', name: 'IMPRESION DE PERIODICOS'},
  { value: '1812', nameSort: 'OTRAS IMPRESIONES', name: 'OTRAS ACTIVIDADES DE IMPRESION Y ARTES GRAFICAS'},
  { value: '1813', nameSort: 'OTRAS IMPRESIONES', name: 'SERVICIOS DE PREIMPRESION Y PREPARACION DE SOPORTE'},
  { value: '1814', nameSort: 'ENCUADERNACIÓN', name: 'ENCUADERNACION Y SERVICIOS RELACIONADOS CON LA MIS'},
  { value: '1820', nameSort: 'REPRODUC. GRABADOS', name: 'REPRODUCCION DE SOPORTES GRABADOS'},
  { value: '1910', nameSort: 'COQUERIAS', name: 'COQUERIAS'},
  { value: '1920', nameSort: 'REFINO DE PETROLEO', name: 'REFINO DE PETROLEO'},
  { value: '2011', nameSort: 'GASES INDUSTRIALES', name: 'FABRICACION DE GASES INDUSTRIALES'},
  { value: '2012', nameSort: 'COLORANTE, PIGMENTOS', name: 'FABRICACION DE COLORANTES Y PIGMENTOS'},
  { value: '2013', nameSort: 'QUÍMICA INORGÁNICA', name: 'FABRICACION DE OTROS PRODUCTOS BASICOS DE QUIMICA INORGAMICA'},
  { value: '2014', nameSort: 'QUÍMICA ORGÁNICA', name: 'FABRICACION DE OTROS PRODUCTOS BASICOS DE QUIMICA ORGANICA'},
  { value: '2015', nameSort: 'FERTILIZANTES', name: 'FABRICACION DE FERTILIZANTES Y COMPUESTOS NITROGEN'},
  { value: '2016', nameSort: 'PLÁSTICOS', name: 'FABRICACION DE PLASTICOS EN FORMAS PRIMARIAS'},
  { value: '2017', nameSort: 'CAUCHO', name: 'FABRICACION DE CAUCHO SINTETICO EN FORMAS PRIMARIA'},
  { value: '2020', nameSort: 'PESTICIDAS', name: 'FABRICACION DE PESTICIDAS Y OTROS PRODUCTOS AGROQU'},
  { value: '2030', nameSort: 'PINTURAS', name: 'FABRICACION DE PINTURAS, BARNICES Y REVESTIMIENTOS'},
  { value: '2041', nameSort: 'JABONES', name: 'FABRICACION DE JABONES, DETERGENTES Y OTROS ARTICU'},
  { value: '2042', nameSort: 'PERFUMES, COSMÉTICOS', name: 'FABRICACION DE PERFUMES Y COSMETICOS'},
  { value: '2051', nameSort: 'EXPLOSIVOS', name: 'FABRICACION DE EXPLOSIVOS'},
  { value: '2052', nameSort: 'COLAS', name: 'FABRICACION DE COLAS'},
  { value: '2053', nameSort: 'ACEITES ESENCIALES', name: 'FABRICACION DE ACEITES ESENCIALES'},
  { value: '2059', nameSort: 'OTRS PRODUCT QUÍMICO', name: 'FABRICACION DE OTROS PRODUCTOS QUIMICOS'},
  { value: '2060', nameSort: 'FIBRAS ARTIFICIALES', name: 'FABRICACION DE FIBRAS ARTIFICIALES Y SINTETICAS'},
  { value: '2110', nameSort: 'PRODUCTO FARMACEUTIC', name: 'FABRICACION DE PRODUCTOS FARMACEUTICOS DE BASE'},
  { value: '2120', nameSort: 'ESPECIALID FARMACEUT', name: 'FABRICACION DE ESPECIALIDADES FARMACEUTICAS'},
  { value: '2211', nameSort: 'NEUMÁTICOS', name: 'FABRICACION DE NEUMATICOS Y CAMARAS DE CAUCHO, REC'},
  { value: '2219', nameSort: 'OTRS PRODUCT. CAUCHO', name: 'FABRICACION DE OTROS PRODUCTOS DE CAUCHO'},
  { value: '2221', nameSort: 'PLACAS, TUBOS PLASTI', name: 'FABRICACION DE PLACAS, HOJAS, TUBOS Y PERFILES DE'},
  { value: '2222', nameSort: 'ENVASES DE PLÁSTICO', name: 'FABRICACION DE ENVASES Y EMBALAJES DE PLASTICO'},
  { value: '2223', nameSort: 'PLÁSTICO PARA CONSTR', name: 'FABRICACION DE PRODUCTOS DE PLASTICO PARA LA CONST'},
  { value: '2229', nameSort: 'OTRS PRODUC PLÁSTICO', name: 'FABRICACION DE OTROS PRODUCTOS DE PLASTICO'},
  { value: '2311', nameSort: 'VIDRIO PLANO', name: 'FABRICACION DE VIDRIO PLANO'},
  { value: '2312', nameSort: 'VIDRIO PLANO', name: 'MANIPULADO Y TRANSFORMACION DE VIDRIO PLANO'},
  { value: '2313', nameSort: 'VIDRIO HUECO', name: 'FABRICACION DE VIDRIO HUECO'},
  { value: '2314', nameSort: 'FICBRA DE VIDRIO', name: 'FABRICACION DE FIBRA DE VIDRIO'},
  { value: '2319', nameSort: 'TRANSFOR OTR VIDRIO', name: 'FABRICACION Y MANIPULADO DE OTRO VIDRIO, INCLUIDO'},
  { value: '2320', nameSort: 'PRODUCTOS CERÁMICOS', name: 'FABRICACION DE PRODUCTOS CERAMICOS REFRACTARIOS'},
  { value: '2331', nameSort: 'AZULEJOS Y BALDOSAS', name: 'FABRICACION DE AZULEJOS Y BALDOSAS DE CERAMICA'},
  { value: '2332', nameSort: 'LADRILLOS, TEJAS', name: 'FABRICACION DE LADRILLOS, TEJAS Y PRODUCTOS DE TIE'},
  { value: '2341', nameSort: 'ARTÍCULOS CERÁMICOS', name: 'FABRICACION DE ARTICULOS CERAMICOS DE USO DOMESTIC'},
  { value: '2342', nameSort: 'APARATOS CERÁMICOS', name: 'FABRICACION DE APARATOS SANITARIOS CERAMICOS'},
  { value: '2343', nameSort: 'PIEZAS AISLANTES', name: 'FABRICACION DE AISLADORES Y PIEZAS AISLANTES DE MA'},
  { value: '2344', nameSort: 'CERÁMICOS USO TECNIC', name: 'FABRICACION DE OTROS PRODUCTOS CERAMICOS DE USO TE'},
  { value: '2349', nameSort: 'OTRS PRODUC CERÁMICO', name: 'FABRICACION DE OTROS PRODUCTOS CERAMICOS'},
  { value: '2351', nameSort: 'CEMENTO', name: 'FABRICACION DE CEMENTO'},
  { value: '2352', nameSort: 'CAL Y YESO', name: 'FABRICACION DE CAL Y YESO'},
  { value: '2361', nameSort: 'HORMIGÓN PARA CONTRU', name: 'FABRICACION DE ELEMENTOS DE HORMIGON PARA LA CONST'},
  { value: '2362', nameSort: 'HORMIGÓN PARA CONTRU', name: 'FABRICACION DE ELEMENTOS DE YESO PARA LA CONSTRUCC'},
  { value: '2363', nameSort: 'HORMIGÓN FRESCO', name: 'FABRICACION DE HORMIGON FRESCO'},
  { value: '2364', nameSort: 'MORTERO', name: 'FABRICACION DE MORTERO'},
  { value: '2365', nameSort: 'FIBROCEMENTO', name: 'FABRICACION DE FIBROCEMENTO'},
  { value: '2369', nameSort: 'OTRS PRODUCT. HORMIG', name: 'FABRICACION DE OTROS PRODUCTOS DE HORMIGON, YESO Y'},
  { value: '2370', nameSort: 'ACABADO DE LA PIEDRA', name: 'CORTE, TALLADO Y ACABADO DE LA PIEDRA'},
  { value: '2391', nameSort: 'PRODUCTOS ABRASIVOS', name: 'FABRICACION DE PRODUCTOS ABRASIVOS'},
  { value: '2399', nameSort: 'OTR MINERALES NO MET', name: 'FABRICACION DE OTROS PRODUCTOS MINERALES NO METALI'},
  { value: '2410', nameSort: 'HIERRO, ACERO', name: 'FABRICACION DE PRODUCTOS BASICOS DE HIERRO, ACERO'},
  { value: '2420', nameSort: 'TUBOS, TUBERÍAS ACER', name: 'FABRICACION DE TUBOS, TUBERIAS, PERFILES HUECOS Y'},
  { value: '2431', nameSort: 'ESTIRADO EN FRÍO', name: 'ESTIRADO EN FRIO'},
  { value: '2432', nameSort: 'LAMINACIÓN EN FRÍO', name: 'LAMINACION EN FRIO'},
  { value: '2433', nameSort: 'PERFILES EN FRÍO', name: 'PRODUCCION DE PERFILES EN FRIO POR CONFORMACION CO'},
  { value: '2434', nameSort: 'TREFILADO EN FRÍO', name: 'TREFILADO EN FRIO'},
  { value: '2441', nameSort: 'METALES PRECIOSOS', name: 'PRODUCCION DE METALES PRECIOSOS'},
  { value: '2442', nameSort: 'ALUMINIO', name: 'PRODUCCION DE ALUMINIO'},
  { value: '2443', nameSort: 'PLOMO, ZINC Y ESTAÑO', name: 'PRODUCCION DE PLOMO, ZINC Y ESTAÑO'},
  { value: '2444', nameSort: 'COBRE', name: 'PRODUCCION DE COBRE'},
  { value: '2445', nameSort: 'OTROS METALES', name: 'PRODUCCION DE OTROS METALES NO FERREOS'},
  { value: '2446', nameSort: 'COMBUSTIBLES NUCLEAR', name: 'PROCESAMIENTO DE COMBUSTIBLES NUCLEARES'},
  { value: '2451', nameSort: 'FUNDICION HIERRO ', name: 'FUNDICION DE HIERRO'},
  { value: '2452', nameSort: 'FUNDICIÓN ACERO', name: 'FUNDICION DE ACERO'},
  { value: '2453', nameSort: 'FUNDICIÓN METALE LIG', name: 'FUNDICION DE METALES LIGEROS'},
  { value: '2454', nameSort: 'FUNDIC OTR METALES', name: 'FUNDICION DE OTROS METALES NO FERREOS'},
  { value: '2511', nameSort: 'ESTRUCTURAS METÁLICA', name: 'FABRICACION DE ESTRUCTURAS METALICAS Y SUS COMPONE'},
  { value: '2512', nameSort: 'CARPINTERÍA METÁLICA', name: 'FABRICACION DE CARPINTERIA METALICA'},
  { value: '2521', nameSort: 'RADIADORES Y CALDERA', name: 'FABRICACION DE RADIADORES Y CALDERAS PARA CALEFACC'},
  { value: '2529', nameSort: 'GRANDES DEPÓSITOS', name: 'FABRICACION DE OTRAS CISTERNAS, GRANDES DEPOSITOS'},
  { value: '2530', nameSort: 'GENERADORES DE VAPOR', name: 'FABRICACION DE GENERADORES DE VAPOR, EXCEPTO CALDE'},
  { value: '2540', nameSort: 'ARMAS Y MUNICIONES', name: 'FABRICACION DE ARMAS Y MUNICIONES'},
  { value: '2550', nameSort: 'FORJA DE METALES', name: 'FORJA, ESTAMPACION Y EMBUTICION DE METALES, METALU'},
  { value: '2561', nameSort: 'TRATAMIENTO DE METAL', name: 'TRATAMIENTO Y REVESTIMIENTO DE METALES'},
  { value: '2562', nameSort: 'MECÁNICA CUENTA TERC', name: 'INGENIERIA MECANICA POR CUENTA DE TERCEROS'},
  { value: '2571', nameSort: 'MECÁNICA CUENTA TERC', name: 'FABRICACION DE ARTICULOS DE CUCHILLERIA Y CUBERTER'},
  { value: '2572', nameSort: 'CERRADURAS Y HERRAJE', name: 'FABRICACION DE CERRADURAS Y HERRAJES'},
  { value: '2573', nameSort: 'HERRAMIENTAS', name: 'FABRICACION DE HERRAMIENTAS'},
  { value: '2591', nameSort: 'TONELES DE HIERRO', name: 'FABRICACION DE BIDONES Y TONELES DE HIERRO O ACERO'},
  { value: '2592', nameSort: 'EMBALAJES METÁLICOS', name: 'FABRICACION DE ENVASES Y EMBALAJES METALICOS LIGER'},
  { value: '2593', nameSort: 'ALAMBRE, CADENAS', name: 'FABRICACION DE PRODUCTOS DE ALAMBRE, CADENAS Y MUE'},
  { value: '2594', nameSort: 'PRODUCT DE TORNILLER', name: 'FABRICACION DE PERNOS Y PRODUCTOS DE TORNILLERIA'},
  { value: '2599', nameSort: 'OTRS PRODUC. METALIC', name: 'FABRICACION DE OTROS PRODUCTOS METALICOS N'},
  { value: '2611', nameSort: 'COMPOS.ELECTRÓNICOS', name: 'FABRICACION DE COMPONENTES ELECTRONICOS'},
  { value: '2612', nameSort: 'CIRCUIT IMPRE ENSAMB', name: 'FABRICACION DE CIRCUITOS IMPRESOS ENSAMBLADOS'},
  { value: '2620', nameSort: 'ORDENADORES', name: 'FABRICACION DE ORDENADORES Y EQUIPOS PERIFERICOS'},
  { value: '2630', nameSort: 'EQUIP DE TELECOMUNIC', name: 'FABRICACION DE EQUIPOS DE TELECOMUNICACIONES'},
  { value: '2640', nameSort: 'ELECTRONIC DE CONSUM', name: 'FABRICACION DE PRODUCTOS ELECTRONICOS DE CONSUMO'},
  { value: '2651', nameSort: 'APARATOS DE MEDIDA', name: 'FABRICACION DE INSTRUMENTOS Y APARATOS DE MEDIDA,'},
  { value: '2652', nameSort: 'RELOJES', name: 'FABRICACION DE RELOJES'},
  { value: '2660', nameSort: 'RADIACIÓN, ELECTROME', name: 'FABRICACION DE EQUIPOS DE RADIACION, ELECTROMEDICO'},
  { value: '2670', nameSort: 'ÓPTICA Y FOTOGRÁFICO', name: 'FABRICACION DE INSTRUMENTOS DE OPTICA Y EQUIPO FOT'},
  { value: '2680', nameSort: 'MAGNÉTICOS Y ÓPTICOS', name: 'FABRICACION DE SOPORTES MAGNETICOS Y OPTICOS'},
  { value: '2711', nameSort: 'MOTORES, GENERADORES', name: 'FABRICACION DE MOTORES, GENERADORES Y TRANSFORMADO'},
  { value: '2712', nameSort: 'APARAT DE DISTRIBUCI', name: 'FABRICACION DE APARATOS DE DISTRIBUCION Y CONTROL'},
  { value: '2720', nameSort: 'ACUMULAD ELECTRICOS', name: 'FABRICACION DE PILAS Y ACUMULADORES ELECTRICOS'},
  { value: '2731', nameSort: 'CABLES FIBRA ÓPTICA', name: 'FABRICACION DE CABLES DE FIBRA OPTICA'},
  { value: '2732', nameSort: 'CABLES ELÉCTRICOS', name: 'FABRICACION DE OTROS HILOS Y CABLES ELECTRONICOS Y'},
  { value: '2733', nameSort: 'DISPOSITIVOS CABLEAD', name: 'FABRICACION DE DISPOSITIVOS DE CABLEADO'},
  { value: '2740', nameSort: 'APARATOS DE ILUMINAC', name: 'FABRICACION DE LAMPARAS Y APARATOS ELECTRICOS DE I'},
  { value: '2751', nameSort: 'ELECTRODOMÉSTICOS', name: 'FABRICACION DE ELECTRODOMESTICOS'},
  { value: '2752', nameSort: 'APARAT NO ELÉCTRICOS', name: 'FABRICACION DE APARATOS DOMESTICOS NO ELECTRICOS'},
  { value: '2790', nameSort: 'OTRO EQUIPO ELÉCTRIC', name: 'FABRICACION DE OTRO MATERIAL Y EQUIPO ELECTRICO'},
  { value: '2811', nameSort: 'MOTORES Y TURBINAS', name: 'FABRICACION DE MOTORES Y TURBINAS, EXCEPTO LOS DES'},
  { value: '2812', nameSort: 'TRANSMISIÓN HIDRAULI', name: 'FABRICACION DE EQUIPOS DE TRANSMISION HIDRAULICA Y'},
  { value: '2813', nameSort: 'OTROS COMPRESORES', name: 'FABRICACION DE OTRAS BOMBAS Y COMPRESORES'},
  { value: '2814', nameSort: 'OTRA GRITERÍA Y VAL.', name: 'FABRICACION DE OTRA GRIFERIA Y VALVULAS'},
  { value: '2815', nameSort: 'COJINES Y ENGRANAJES', name: 'FABRICACION DE COJINETES, ENGRANAJES Y ORGANOS MEC'},
  { value: '2821', nameSort: 'HORNOS Y QUEMADORES', name: 'FABRICACION DE HORNOS Y QUEMADORES'},
  { value: '2822', nameSort: 'MAQUINARIA DE ELEVAC', name: 'FABRICACION DE MAQUINARIA DE ELEVACION Y MANIPULAC'},
  { value: '2823', nameSort: 'MÁQUINAS DE OFICINA', name: 'FABRICACION DE MAQUINAS Y EQUIPOS DE OFICINA, EXCE'},
  { value: '2824', nameSort: 'HERRAM. ELECT. MANUA', name: 'FABRICACION DE HERRAMIENTAS ELECTRICAS MANUALES'},
  { value: '2825', nameSort: 'VENTILACIÓN Y REFRIG', name: 'FABRICACION DE MAQUINARIA DE VENTILACION Y REFRIGE'},
  { value: '2829', nameSort: 'MAQUINA. USO GENERAL', name: 'FABRICACION DE OTRA MAQUINARIA DE USO GENERAL N'},
  { value: '2830', nameSort: 'MAQUINARIA AGRARIA', name: 'FABRICACION DE MAQUINARIA AGRARIA Y FORESTAL'},
  { value: '2841', nameSort: 'MÁQUINAS PARA EL MET', name: 'FABRICACION DE MAQUINAS HERRAMIENTA PARA TRABAJAR'},
  { value: '2849', nameSort: 'OTRAS MAQUINAS', name: 'FABRICACION DE OTRAS MAQUINAS HERRAMIENTA'},
  { value: '2891', nameSort: 'MAQUINA.INDUST METAL', name: 'FABRICACION DE MAQUINARIA PARA LA INDUSTRIA METALU'},
  { value: '2892', nameSort: 'MAQUINA.CONSTRUCCIÓN', name: 'FABRICACION DE MAQUINARIA PARA LAS INDUSTRIAS EXTR'},
  { value: '2893', nameSort: 'MÁQUINAS ALIMENTACIO', name: 'FABRICACION DE MAQUINARIA PARA LA INDUSTRIA DE LA'},
  { value: '2894', nameSort: 'MAQUINA.INDUST TEXTI', name: 'FABRICACION DE MAQUINARIA PARA LAS INDUSTRIAS TEXT'},
  { value: '2895', nameSort: 'MAQUINA.INDUST PAPEL', name: 'FABRICACION DE MAQUINARIA PARA LA INDUSTRIA DEL PA'},
  { value: '2896', nameSort: 'MAQUINA.INDUST PLAST', name: 'FABRICACION DE MAQUINARIA PARA LAS INDUSTRIAS DEL'},
  { value: '2899', nameSort: 'MAQUINA.USO ESPECIFI', name: 'FABRICACION DE OTRA MAQUINARIA PARA USOS ESPECIFIC'},
  { value: '2910', nameSort: 'VEHÍCULOS DE MOTOR', name: 'FABRICACION DE VEHICULOS DE MOTOR'},
  { value: '2920', nameSort: 'CARROCERÍA VEHÍCULOS', name: 'FABRICACION DE CARROCERIAS PARA VEHICULOS DE MOTOR'},
  { value: '2931', nameSort: 'EQUIPOS ELE.VEHÍCULO', name: 'FABRICACION DE EQUIPOS ELECTRICOS Y ELECTRONICOS P'},
  { value: '2932', nameSort: 'PIEZAS VEHÍCULOS', name: 'FABRICACION DE OTROS COMPONENTES, PIEZAS Y ACCESOR'},
  { value: '3011', nameSort: 'BARCOS', name: 'CONSTRUCCION DE BARCOS Y ESTRUCTURAS FLOTANTES'},
  { value: '3012', nameSort: 'EMBARCACIÓN DE RECRE', name: 'CONSTRUCCION DE EMBARCACIONES DE RECREO Y DEPORTE'},
  { value: '3020', nameSort: 'MATERIAL FERROVIARI', name: 'FABRICACION DE LOCOMOTORAS Y MATERIAL FERROVIARIO'},
  { value: '3030', nameSort: 'AERONÁUTICA Y ESPACI', name: 'CONSTRUCCION AERONAUTICA Y ESPACIAL Y SU MAQUINARI'},
  { value: '3040', nameSort: 'VEHÍCULOS DE COMBATE', name: 'FABRICACION DE VEHICULOS MILITARES DE COMBATE'},
  { value: '3091', nameSort: 'MOTOCICLETAS', name: 'FABRICACION DE MOTOCICLETAS'},
  { value: '3092', nameSort: 'BICICLETAS', name: 'FABRICACION DE BICICLETAS Y DE VEHICULOS PARA PERS'},
  { value: '3099', nameSort: 'OTRO MATERIAL', name: 'FABRICACION DE OTRO MATERIAL DE TRANSPORTE N'},
  { value: '3101', nameSort: 'MUEBLES DE OFICINA', name: 'FABRICACION DE MUEBLES DE OFICINA Y DE ESTABLECIMI'},
  { value: '3102', nameSort: 'MUEBLES DE COCINA', name: 'FABRICACION DE MUEBLES DE COCINA'},
  { value: '3103', nameSort: 'COLCHONES', name: 'FABRICACION DE COLCHONES'},
  { value: '3109', nameSort: 'OTROS MUEBLES', name: 'FABRICACION DE OTROS MUEBLES'},
  { value: '3211', nameSort: 'MONEDAS', name: 'FABRICACION DE MONEDAS'},
  { value: '3212', nameSort: 'ARTÍCULOS DE JOYERÍA', name: 'FABRICACION DE ARTICULOS DE JOYERIA Y ARTICULOS SI'},
  { value: '3213', nameSort: 'ARTÍCULOS DE BISUTER', name: 'FABRICACION DE ARTICULOS DE BISUTERIA Y ARTICULOS'},
  { value: '3220', nameSort: 'INSTRUMENTOS MUSICAL', name: 'FABRICACION DE INSTRUMENTOS MUSICALES'},
  { value: '3230', nameSort: 'ARTÍCULOS DE DEPORT', name: 'FABRICACION DE ARTICULOS DE DEPORTE'},
  { value: '3240', nameSort: 'JUEGOS Y JUGUETES', name: 'FABRICACION DE JUEGOS Y JUGUETES'},
  { value: '3250', nameSort: 'SUMINISTROS MÉDICOS', name: 'FABRICACION DE INSTRUMENTOS Y SUMINISTROS MEDICOS'},
  { value: '3291', nameSort: 'BROCHAS Y CEPILLOS', name: 'FABRICACION DE ESCOBAS, BROCHAS Y CEPILLOS'},
  { value: '3299', nameSort: 'INDUST MANUFACTURERA', name: 'OTRAS INDUSTRIAS MANUFACTURERAS'},
  { value: '3311', nameSort: 'R. PRODUCTOS METALIC', name: 'REPARACION DE PRODUCTOS METALICOS'},
  { value: '3312', nameSort: 'R. MAQUINARIA', name: 'REPARACION DE MAQUINARIA'},
  { value: '3313', nameSort: 'R. EQUIPO ELECTRONIC', name: 'REPARACION DE EQUIPOS ELECTRONICOS Y OPTICOS'},
  { value: '3314', nameSort: 'R. EQUIPO ELÉCTRICO', name: 'REPARACION DE EQUIPOS ELECTRICOS'},
  { value: '3315', nameSort: 'R. MANTENIMIENTO NAV', name: 'REPARACION Y MANTENIMIENTO NAVAL'},
  { value: '3316', nameSort: 'R. MANTENIM. ESPACIA', name: 'REPARACION Y MANTENIMIENTO AERONAUTICO Y ESPACIAL'},
  { value: '3317', nameSort: 'R. OTRO TRASPORTE', name: 'REPARACION Y MANTENIMIENTO DE OTRO MATERIAL DE TRA'},
  { value: '3319', nameSort: 'R. OTROS EQUIPOS', name: 'REPARACION DE OTROS EQUIPOS'},
  { value: '3320', nameSort: 'INSTALACIÓN MÁQUINAS', name: 'INSTALACION DE MAQUINAS Y EQUIPOS INDUSTRIALES'},
  { value: '3512', nameSort: 'TRANSP.ENERGÍA ELEC.', name: 'TRANSPORTE DE ENERGIA ELECTRICA'},
  { value: '3513', nameSort: 'DISTRIB. ENERGÍA ELE', name: 'DISTRIBUCION DE ENERGIA ELECTRICA'},
  { value: '3514', nameSort: 'COMERC. ENERGÍA ELEC', name: 'COMERCIO DE ENERGIA ELECTRICA'},
  { value: '3515', nameSort: 'P. ENERGÍA HIDROELÉC', name: 'PRODUCCION DE ENERGIA HIDROELECTRICA'},
  { value: '3516', nameSort: 'ENERG ELÉCTRIC. TERM', name: 'PRODUCCION DE ENERGIA ELECTRICA DE ORIGEN TERMICO'},
  { value: '3517', nameSort: 'ENERG ELÉCTRIC. NUCL', name: 'PRODUCCION DE ENERGIA ELECTRICA DE ORIGEN NUCLEAR'},
  { value: '3518', nameSort: 'ENERG ELÉCTRIC. EOLI', name: 'PRODUCCION DE ENERGIA ELECTRICA DE ORIGEN EOLICO'},
  { value: '3519', nameSort: 'ENERG ELÉCTRIC. OTR', name: 'PRODUCCION DE ENERGIA ELECTRICA DE OTROS TIPOS'},
  { value: '3521', nameSort: 'PRODUCCION DE GAS', name: 'PRODUCCION DE GAS'},
  { value: '3522', nameSort: 'DISTRIBUCIÓN DE GAS', name: 'DISTRIBUCION POR TUBERIA DE COMBUSTIBLES GASEOSOS'},
  { value: '3523', nameSort: 'COMERCIO DE GAS', name: 'COMERCIO DE GAS POR TUBERIA'},
  { value: '3530', nameSort: 'SUMISTRO AIRE ACONDI', name: 'SUMINISTRO DE VAPOR Y AIRE ACONDICIONADO'},
  { value: '3600', nameSort: 'DISTRIBUCIÓN DE AGUA', name: 'CAPTACION, DEPURACION Y DISTRIBUCION DE AGUA'},
  { value: '3700', nameSort: 'TRATAM AGUA RESIDUAL', name: 'RECOGIDA Y TRATAMIENTO DE AGUAS RESIDUALES'},
  { value: '3811', nameSort: 'RESIDUOS NO PELIGROS', name: 'RECOGIDA DE RESIDUOS NO PELIGROSOS'},
  { value: '3812', nameSort: 'RESIDUOS PELIGROSOS', name: 'RECOGIDA DE RESIDUOS PELIGROSOS'},
  { value: '3821', nameSort: 'RESIDUOS PELIGROSOS', name: 'TRATAMIENTO Y ELIMINACION DE RESIDUOS NO PELIGROSO'},
  { value: '3822', nameSort: 'TRTATM RESIDUO PELIG', name: 'TRATAMIENTO Y ELIMINACION DE RESIDUOS PELIGROSOS'},
  { value: '3831', nameSort: 'SEPARARC DE MATERIAL', name: 'SEPARACION Y CLASIFICACION DE MATERIALES'},
  { value: '3832', nameSort: 'VALORACIÓN MATERIAL', name: 'VALORIZACION DE MATERIALES YA CLASIFICADOS'},
  { value: '3900', nameSort: 'DESCONTAMINACIÓN', name: 'ACTIVIDADES DE DESCONTAMINACION Y OTROS SERVICIOS'},
  { value: '4110', nameSort: 'PROMOCIÓN INMOBILIAR', name: 'PROMOCION INMOBILIARIA'},
  { value: '4121', nameSort: 'CONSTRC EDIFIC RESID', name: 'CONSTRUCCION DE EDIFICIOS RESIDENCIALES'},
  { value: '4122', nameSort: 'CONSTRC EDIFIC NO RE', name: 'CONSTRUCCION DE EDIFICIOS NO RESIDENCIALES'},
  { value: '4211', nameSort: 'CONSTRC CARRETERAS', name: 'CONSTRUCCION DE CARRETERAS Y AUTOPISTAS'},
  { value: '4212', nameSort: 'CONSTRC VÍAS FÉRREAS', name: 'CONSTRUCCION DE VIAS FERREAS DE SUPERFICIE Y SUBTE'},
  { value: '4213', nameSort: 'CONSTRC PUENTES, TUN', name: 'CONSTRUCCION DE PUENTES Y TUNELES'},
  { value: '4221', nameSort: 'CONSTRC RED FLUIDOS', name: 'CONSTRUCCION DE REDES PARA FLUIDOS'},
  { value: '4222', nameSort: 'CONSTRC RED ELECTRIC', name: 'CONSTRUCCION DE REDES ELECTRICAS Y DE TELECOMUNICA'},
  { value: '4291', nameSort: 'OBRA HIDRÁULICA', name: 'OBRAS HIDRAULICAS'},
  { value: '4299', nameSort: 'CONSTRC INGEN CIVIL', name: 'CONSTRUCCION DE OTROS PROYECTOS DE INGENIERIA CIVI'},
  { value: '4311', nameSort: 'DEMOLICIÓN', name: 'DEMOLICION'},
  { value: '4312', nameSort: 'PREPARACIÓN TERRENO', name: 'PREPARACION DE TERRENOS'},
  { value: '4313', nameSort: 'PERFORACIÓN', name: 'PERFORACIONES Y SONDEOS'},
  { value: '4321', nameSort: 'INSTALAC ELÉCTRICAS', name: 'INSTALACIONES ELECTRICAS'},
  { value: '4322', nameSort: 'FONTANERÍA, CALEFACC', name: 'FONTANERIA, INSTALACIONES DE SISTEMAS DE CALEFACCI'},
  { value: '4329', nameSort: 'INSTAL OBRA CONSTRC', name: 'OTRAS INSTALACIONES EN OBRAS DE CONSTRUCCION'},
  { value: '4331', nameSort: 'REVOCAMIENTO', name: 'REVOCAMIENTO'},
  { value: '4332', nameSort: 'INSTAL CARPINTERÍA', name: 'INSTALACION DE CARPINTERIA'},
  { value: '4333', nameSort: 'REVESTIMIENTO SUELOS', name: 'REVESTIMIENTO DE SUELOS Y PAREDES'},
  { value: '4334', nameSort: 'PINTURA, ACRISTALAMI', name: 'PINTURA Y ACRISTALAMIENTO'},
  { value: '4339', nameSort: 'ACABADO EDIFICIOS', name: 'OTRO ACABADO DE EDIFICIOS'},
  { value: '4391', nameSort: 'CONSTRC CUBIERTAS', name: 'CONSTRUCCION DE CUBIERTAS'},
  { value: '4399', nameSort: 'OTRS CONSTRUCCIÓN', name: 'OTRAS ACTIVIDADES DE CONSTRUCCION ESPECIALIZADA'},
  { value: '4511', nameSort: 'VENTA AUTOMÓVILES', name: 'VENTA DE AUTOMOVILES Y VEHICULOS DE MOTOR LIGEROS'},
  { value: '4519', nameSort: 'VENTA ORTS VEHÍCULOS', name: 'VENTA DE OTROS VEHICULOS DE MOTOR'},
  { value: '4520', nameSort: 'MANTENIM VEHÍCULOS', name: 'MANTENIMIENTO Y REPARACION DE VEHICULOS DE MOTOR'},
  { value: '4531', nameSort: 'POR MAY ACCESORIO VE', name: 'COMERCIO AL POR MAYOR DE REPUESTOS Y ACCESORIOS DE'},
  { value: '4532', nameSort: 'POR MEN ACCESORIO VE', name: 'COMERCIO AL POR MENOR DE REPUESTOS Y ACCESORIOS DE'},
  { value: '4540', nameSort: 'MOTOCICLETA, REPUES', name: 'VENTA, MANTENIMIENTO Y REPARACION DE MOTOCICLETAS'},
  { value: '4611', nameSort: 'INTERM MATERIAS PRIM', name: 'INTERMEDIARIOS DEL COMERCIO DE MATERIAS PRIMAS AGR'},
  { value: '4612', nameSort: 'INTERM COMBUSTIBLE', name: 'INTERMEDIARIOS DEL COMERCIO DE COMBUSTIBLES, MINER'},
  { value: '4613', nameSort: 'INTERM MADERA', name: 'INTERMEDIARIOS DEL COMERCIO DE LA MADERA Y MATERIA'},
  { value: '4614', nameSort: 'INTERM MAQUINARIA', name: 'INTERMEDIARIOS DEL COMERCIO DE MAQUINARIA, EQUIPO'},
  { value: '4615', nameSort: 'INTERM ARTIC DE HOGA', name: 'INTERMEDIARIOS DEL COMERCIO DE MUEBLES, ARTICULOS'},
  { value: '4616', nameSort: 'INTERM TEXTILES', name: 'INTERMEDIARIOS DEL COMERCIO DE TEXTILES, PRENDAS D'},
  { value: '4617', nameSort: 'INTERM ALIMIENTOS', name: 'INTERMEDIARIOS DEL COMERCIO DE PRODUCTOS ALIMENTIC'},
  { value: '4618', nameSort: 'INTERM OTROS PRODUCT', name: 'INTERMEDIARIOS DEL COMERCIO ESPECIALIZADOS EN LA V'},
  { value: '4619', nameSort: 'INTERM PRODUCT DIVER', name: 'INTERMEDIARIOS DEL COMERCIO DE PRODUCTOS DIVERSOS'},
  { value: '4621', nameSort: 'MAYOR CEREALES', name: 'COMERCIO AL POR MAYOR DE CEREALES, TABACO EN RAMA,'},
  { value: '4622', nameSort: 'MAYOR FLORES, PLANTA', name: 'COMERCIO AL POR MAYOR DE FLORES Y PLANTAS'},
  { value: '4623', nameSort: 'MAYOR ANIMAL VIVO', name: 'COMERCIO AL POR MAYOR DE ANIMALES VIVOS'},
  { value: '4624', nameSort: 'MAYOR CUERO Y PIEL', name: 'COMERCIO AL POR MAYOR DE CUEROS Y PIELES'},
  { value: '4631', nameSort: 'MAYOR FRUTA', name: 'COMERCIO AL POR MAYOR DE FRUTAS Y HORTALIZAS'},
  { value: '4632', nameSort: 'MAYOR PROD CARNICOS', name: 'COMERCIO AL POR MAYOR DE CARNE Y PRODUCTOS CARNICO'},
  { value: '4633', nameSort: 'MAYOR PROD LACTEOS', name: 'COMERCIO AL POR MAYOR DE PRODUCTOS LACTEOS, HUEVOS'},
  { value: '4634', nameSort: 'MAYOR BEBIDAS', name: 'COMERCIO AL POR MAYOR DE BEBIDAS'},
  { value: '4635', nameSort: 'MAYOR TABACO', name: 'COMERCIO AL POR MAYOR DE PRODUCTOS DEL TABACO'},
  { value: '4636', nameSort: 'MAYOR AZÚCAR', name: 'COMERCIO AL POR MAYOR DE AZUCAR, CHOCOLATE Y CONFI'},
  { value: '4637', nameSort: 'MAYOR CAFÉ', name: 'COMERCIO AL POR MAYOR DE CAFE, TE, CACAO Y ESPECIA'},
  { value: '4638', nameSort: 'MAYOR PESCADOS', name: 'COMERCIO AL POR MAYOR DE PESCADOS, MARISCOS Y OTRO'},
  { value: '4639', nameSort: 'MAYOR NO ESPECIALIZA', name: 'COMERCIO AL POR MAYOR, NO ESPECIALIZADO, DE PRODUC'},
  { value: '4641', nameSort: 'MAYOR TEXTILES', name: 'COMERCIO AL POR MAYOR DE TEXTILES'},
  { value: '4642', nameSort: 'MAYOR PRENDAS VESTIR', name: 'COMERCIO AL POR MAYOR DE PRENDAS DE VESTIR Y CALZA'},
  { value: '4643', nameSort: 'MAYOR ELECTRODOMES', name: 'COMERCIO AL POR MAYOR DE APARATOS ELECTRODOMESTICO'},
  { value: '4644', nameSort: 'MAYOR CRISTALERÍA', name: 'COMERCIO AL POR MAYOR DE PORCELANA, CRISTALERIA Y'},
  { value: '4645', nameSort: 'MAYOR PERFUMERÍA', name: 'COMERCIO AL POR MAYOR DE PRODUCTOS DE PERFUMERIA Y'},
  { value: '4646', nameSort: 'MAYOR FARMACÉUTICOS', name: 'COMERCIO AL POR MAYOR DE PRODUCTOS FARMACEUTICOS'},
  { value: '4647', nameSort: 'MAYOR MUEBLES', name: 'COMERCIO AL POR MAYOR DE MUEBLES, ALFOMBRAS Y APAR'},
  { value: '4648', nameSort: 'MAYOR JOYERÍA', name: 'COMERCIO AL POR MAYOR DE ARTICULOS DE RELOJERIA Y'},
  { value: '4649', nameSort: 'MAYOR OTR USO DOMEST', name: 'COMERCIO AL POR MAYOR DE OTROS ARTICULOS DE USO DO'},
  { value: '4651', nameSort: 'MAYOR ORDENADORES', name: 'COMERCIO AL POR MAYOR DE ORDENADORES, EQUIPOS PERI'},
  { value: '4652', nameSort: 'MAYOR EQUIP ELECTRÓN', name: 'COMERCIO AL POR MAYOR DE EQUIPOS ELECTRONICOS Y DE'},
  { value: '4661', nameSort: 'MAYOR SUMIN AGRÍCOLA', name: 'COMERCIO AL POR MAYOR DE MAQUINARIA, EQUIPOS Y SUM'},
  { value: '4662', nameSort: 'MAYOR HERRAMIENTA', name: 'COMERCIO AL POR MAYOR DE MAQUINAS HERRAMIENTA'},
  { value: '4663', nameSort: 'MAYOR MAQUIN MINERA', name: 'COMERCIO AL POR MAYOR DE MAQUINARIA PARA LA MINERI'},
  { value: '4664', nameSort: 'MAYOR MAQUIN TEXTIL', name: 'COMERCIO AL POR MAYOR DE MAQUINARIA PARA LA INDUST'},
  { value: '4665', nameSort: 'MAYOR MUEBLE OFICINA', name: 'COMERCIO AL POR MAYOR DE MUEBLES DE OFICINA'},
  { value: '4666', nameSort: 'MAYOR MAQUIN OFICINA', name: 'COMERCIO AL POR MAYOR DE OTRA MAQUINARIA Y EQUIPO.'},
  { value: '4669', nameSort: 'MAYOR MAQUIN OFICINA', name: 'COMERCIO AL POR MAYOR DE OTRA MAQUINARIA Y EQUIPO'},
  { value: '4671', nameSort: 'MAYOR COMBUSTIBLE', name: 'COMERCIO AL POR MAYOR DE COMBUSTIBLES SOLIDOS, LIQ'},
  { value: '4672', nameSort: 'MAYOR METALES', name: 'COMERCIO AL POR MAYOR DE METALES Y MINERALES METAL'},
  { value: '4673', nameSort: 'MAYOR MADERA', name: 'COMERCIO AL POR MAYOR DE MADERA, MATERIALES DE CON'},
  { value: '4674', nameSort: 'MAYOR CALEFACCIÓN', name: 'COMERCIO AL POR MAYOR DE FERRETERIA, FONTANERIA Y'},
  { value: '4675', nameSort: 'MAYOR PROD. QUÍMICOS', name: 'COMERCIO AL POR MAYOR DE PRODUCTOS QUIMICOS'},
  { value: '4676', nameSort: 'MAYOR PROD SEMIELABO', name: 'COMERCIO AL POR MAYOR DE OTROS PRODUCTOS SEMIELABO'},
  { value: '4677', nameSort: 'MAYOR CHATARRA', name: 'COMERCIO AL POR MAYOR DE CHATARRA Y PRODUCTOS DE D'},
  { value: '4690', nameSort: 'MAYOR NO ESPECIALIZA', name: 'COMERCIO AL POR MAYOR NO ESPECIALIZADO'},
  { value: '4711', nameSort: 'MENOR PROD ALIMENTIC', name: 'COMERCIO AL POR MENOR EN ESTABLECIMIENTOS NO ESPEC'},
  { value: '4719', nameSort: 'MENOR NO ESPECIALIZAD', name: 'OTRO COMERCIO AL POR MENOR EN ESTABLECIMIENTOS NO'},
  { value: '4721', nameSort: 'MENOR FRUTA Y HORTAL', name: 'COMERCIO AL POR MENOR DE FRUTAS Y HORTALIZAS EN ES'},
  { value: '4722', nameSort: 'MENOR CARNE', name: 'COMERCIO AL POR MENOR DE CARNE Y PRODUCTOS CARNICO'},
  { value: '4723', nameSort: 'MENOR PESCADO Y MARI', name: 'COMERCIO AL POR MENOR DE PESCADOS Y MARISCOS EN ES'},
  { value: '4724', nameSort: 'MENOR PROD PANADERÍA', name: 'COMERCIO AL POR MENOR DE PAN Y PRODUCTOS DE PANADE'},
  { value: '4725', nameSort: 'MENOR BEBIDAS', name: 'COMERCIO AL POR MENOR DE BEBIDAS EN ESTABLECIMIENT'},
  { value: '4726', nameSort: 'MENOR TABACO', name: 'COMERCIO AL POR MENOR DE PRODUCTOS DE TABACO EN ES'},
  { value: '4729', nameSort: 'MENOR OTR PRODCUTOS', name: 'OTRO COMERCIO AL POR MENOR DE PRODUCTOS ALIMENTICI'},
  { value: '4730', nameSort: 'MENOR COMBUSTIBLE', name: 'COMERCIO AL POR MENOR DE COMBUSTIBLE PARA LA AUTOM'},
  { value: '4741', nameSort: 'MENOR ORDENADORES', name: 'COMERCIO AL POR MENOR DE ORDENADORES, EQUIPOS PERI'},
  { value: '4742', nameSort: 'MENOR TELECOMUNICACI', name: 'COMERCIO AL POR MENOR DE EQUIPOS DE TELECOMUNICACI'},
  { value: '4743', nameSort: 'MENOR AUDIO Y VIDEO', name: 'COMERCIO AL POR MENOR DE EQUIPOS DE AUDIO Y VIDEO'},
  { value: '4751', nameSort: 'MENOR TEXTILES', name: 'COMERCIO AL POR MENOR DE TEXTILES EN ESTABLECIMIEN'},
  { value: '4752', nameSort: 'MENOR FERRETERÍA', name: 'COMERCIO AL POR MENOR DE FERRETERIA, PINTURA Y VID'},
  { value: '4753', nameSort: 'MENOR ALFOMBRAS', name: 'COMERCIO AL POR MENOR DE ALFOMBRAS, MOQUETAS Y REV'},
  { value: '4754', nameSort: 'MENOR ELECTRODOMÉSTI', name: 'COMERCIO AL POR MENOR DE APARATOS ELECTRODOMESTICO'},
  { value: '4759', nameSort: 'MENOR MUEBLES', name: 'COMERCIO AL POR MENOR DE MUEBLES, APARATOS DE ILUM'},
  { value: '4761', nameSort: 'MENOR LIBROS', name: 'COMERCIO AL POR MENOR DE LIBROS EN ESTABLECIMIENTO'},
  { value: '4762', nameSort: 'MENOR ARTIC PAPELERI', name: 'COMERCIO AL POR MENOR DE PERIODICOS Y ARTICULOS DE'},
  { value: '4763', nameSort: 'MENOR MÚSICA Y VIDEO', name: 'COMERCIO AL POR MENOR DE GRABACIONES DE MUSICA Y V'},
  { value: '4764', nameSort: 'MENOR MÚSICA Y VIDEO', name: 'COMERCIO AL POR MENOR DE ARTICULOS DEPORTIVOS EN E'},
  { value: '4765', nameSort: 'MENOR JUGIETES', name: 'COMERCIO AL POR MENOR DE JUEGOS Y JUGUETES EN ESTA'},
  { value: '4771', nameSort: 'MENOR VESTIR', name: 'COMERCIO AL POR MENOR DE PRENDAS DE VESTIR EN ESTA'},
  { value: '4772', nameSort: 'MENOR CALZADO', name: 'COMERCIO AL POR MENOR DE CALZADO Y ARTICULOS DE CU'},
  { value: '4773', nameSort: 'MENOR PROD FARMACÉUT', name: 'COMERCIO AL POR MENOR DE PRODUCTOS FARMACEUTICOS E'},
  { value: '4774', nameSort: 'MENOR ARTC MÉDICOS', name: 'COMERCIO AL POR MENOR DE ARTICULOS MEDICOS Y ORTOP'},
  { value: '4775', nameSort: 'MENOR COSMÉTICOS', name: 'COMERCIO AL POR MENOR DE PRODUCTOS COSMETICOS E HI'},
  { value: '4776', nameSort: 'MENOR FLORES, PLANTA', name: 'COMERCIO AL POR MENOR DE FLORES, PLANTAS, SEMILLAS'},
  { value: '4777', nameSort: 'MENOR JOYERÍA', name: 'COMERCIO AL POR MENOR DE ARTICULOS DE RELOJERIA Y'},
  { value: '4778', nameSort: 'MENOR ARTC NUEVOS', name: 'OTRO COMERCIO AL POR MENOR DE ARTICULOS NUEVOS EN'},
  { value: '4779', nameSort: 'MENOR ARTIC SEGUNDA', name: 'COMERCIO AL POR MENOR DE ARTICULOS DE SEGUNDA MANO'},
  { value: '4781', nameSort: 'MENOR ALIMT MERCADIL', name: 'COMERCIO AL POR MENOR DE PRODUCTOS ALIMENTICIOS, B'},
  { value: '4782', nameSort: 'MENOR TEXTIL MERCADI', name: 'COMERCIO AL POR MENOR DE PRODUCTOS TEXTILES, PREND'},
  { value: '4789', nameSort: 'MENOR OTR MERCADILLO', name: 'COMERCIO AL POR MENOR DE OTROS PRODUCTOS EN PUESTO'},
  { value: '4791', nameSort: 'MENOR INTERNET', name: 'COMERCIO AL POR MENOR POR CORRESPONDENCIA O INTERN'},
  { value: '4799', nameSort: 'MENOR NO ESTBLEC, ME', name: 'OTRO COMERCIO AL POR MENOR NO REALIZADO NI EN ESTA'},
  { value: '4910', nameSort: 'TRASP INTERURB', name: 'TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRI'},
  { value: '4920', nameSort: 'TRASPMERCANCIAS FERR', name: 'TRANSPORTE DE MERCANCIAS POR FERROCARRIL'},
  { value: '4931', nameSort: 'TRASP URBANO', name: 'TRANSPORTE TERRESTRE URBANO Y SUBURBANO DE PASAJER'},
  { value: '4932', nameSort: 'TRASP TAXI', name: 'TRANSPORTE POR TAXI'},
  { value: '4939', nameSort: 'TRASP TAXI', name: 'OTROS TIPOS DE TRANSPORTE TERRESTRE DE PASAJEROS N'},
  { value: '4941', nameSort: 'TRASP MERC CARRETERA', name: 'TRANSPORTE DE MERCANCIAS POR CARRETERA'},
  { value: '4942', nameSort: 'MUDANZAS', name: 'SERVICIOS DE MUDANZA'},
  { value: '4950', nameSort: 'TRASP POR TUBERÍAS', name: 'TRANSPORTE POR TUBERIA'},
  { value: '5010', nameSort: 'TRASP MARIT PASAJERO', name: 'TRANSPORTE MARITIMO DE PASAJEROS'},
  { value: '5020', nameSort: 'TRASP MARIT MERCANCI', name: 'TRANSPORTE MARITIMO DE MERCANCIAS'},
  { value: '5030', nameSort: 'TRAS PASAJ NAV INTER', name: 'TRANSPORTE DE PASAJEROS POR VIAS NAVEGABLES INTERI'},
  { value: '5040', nameSort: 'TRAS MERC NAV INTERI', name: 'TRANSPORTE DE MERCANCIAS POR VIAS NAVEGABLES INTER'},
  { value: '5110', nameSort: 'TRASP AÉREO PASAJ', name: 'TRANSPORTE AEREO DE PASAJEROS'},
  { value: '5121', nameSort: 'TRASP AÉREO MERCAN', name: 'TRANSPORTE AEREO DE MERCANCIAS'},
  { value: '5122', nameSort: 'TRASP ESPACIAL', name: 'TRANSPORTE ESPACIAL'},
  { value: '5210', nameSort: 'DEPÓSITO, ALMACENAM', name: 'DEPOSITO Y ALMACENAMIENTO'},
  { value: '5221', nameSort: 'ACTIV TRASP TERRESTR', name: 'ACTIVIDADES ANEXAS AL TRANSPORTE TERRESTRE'},
  { value: '5222', nameSort: 'ACTIV TRASP MARÍTIMO', name: 'ACTIVIDADES ANEXAS AL TRANSPORTE MARITIMO Y POR VI'},
  { value: '5223', nameSort: 'ACTIV TRASP AEREO', name: 'ACTIVIDADES ANEXAS AL TRANSPORTE AEREO'},
  { value: '5224', nameSort: 'MANIPULAC MERCANCÍAS', name: 'MANIPULACION DE MERCANCIAS'},
  { value: '5229', nameSort: 'ORT ACTIV TRASPORTE', name: 'OTRAS ACTIVIDADES ANEXAS AL TRANSPORTE'},
  { value: '5310', nameSort: 'ACTIV POSTAL SERV UN', name: 'ACTIVIDADES POSTALES SOMETIDAS A LA OBLIGACION DEL'},
  { value: '5320', nameSort: 'ORT ACTIV POSTAL', name: 'OTRAS ACTIVIDADES POSTALES Y DE CORREOS'},
  { value: '5510', nameSort: 'HOTELES', name: 'HOTELES Y ALOJAMIENTOS SIMILARES'},
  { value: '5520', nameSort: 'ALOJAM TURÍSTICOS', name: 'ALOJAMIENTOS TURISTICOS Y OTROS ALOJAMIENTOS DE CO'},
  { value: '5530', nameSort: 'CAMPINGS', name: 'CAMPINGS'},
  { value: '5590', nameSort: 'OTROS ALOJAMIENTOS', name: 'OTROS ALOJAMIENTOS'},
  { value: '5610', nameSort: 'RESTAURANTES', name: 'RESTAURANTES Y PUESTOS DE COMIDAS'},
  { value: '5621', nameSort: 'COMIDAS PREPARADAS', name: 'PROVISION DE COMIDAS PREPARADAS PARA EVENTOS'},
  { value: '5629', nameSort: 'OTR SERV COMIDAS', name: 'OTROS SERVICIOS DE COMIDAS'},
  { value: '5630', nameSort: 'ESTABLEC BEBIDAS', name: 'ESTABLECIMIENTOS DE BEBIDAS'},
  { value: '5811', nameSort: 'EDICIÓN LIBROS', name: 'EDICION DE LIBROS'},
  { value: '5812', nameSort: 'EDICIÓN GUÍAS DIREC', name: 'EDICION DE DIRECTORIOS Y GUIAS DE DIRECCIONES POST'},
  { value: '5813', nameSort: 'EDICIÓN PERIÓDICOS', name: 'EDICION DE PERIODICOS'},
  { value: '5814', nameSort: 'EDICIÓN REVISTAS', name: 'EDICION DE REVISTAS'},
  { value: '5819', nameSort: 'ORT ACTIV EDITORIALE', name: 'OTRAS ACTIVIDADES EDITORIALES'},
  { value: '5821', nameSort: 'EDICIÓN VIDEOJUEGOS', name: 'EDICION DE VIDEOJUEGOS'},
  { value: '5829', nameSort: 'EDIC.OTROS PGM INFOR', name: 'EDICION DE OTROS PROGRAMAS INFORMATICOS'},
  { value: '5912', nameSort: 'ACTIV POSTPRODUCCIÓN', name: 'ACTIVIDADES DE POSTPRODUCCION CINEMATOGRAFICA, DE'},
  { value: '5914', nameSort: 'ACTIV EXHIB CIMENATO', name: 'ACTIVIDADES DE EXHIBICION CINEMATOGRAFICA'},
  { value: '5915', nameSort: 'ACTIV PRODUCCIÓN CIN', name: 'ACTIVIDADES DE PRODUCCION CINEMATOGRAFICA Y DE VID'},
  { value: '5916', nameSort: 'ACTIV PRODUCCIÓN TV', name: 'ACTIVIDADES DE PRODUCCION DE PROGRAMAS DE TELEVISI'},
  { value: '5917', nameSort: 'ACTIV DISTRIB CINE', name: 'ACTIVIDADES DE DISTRIBUCION CINEMATOGRAFICA Y DE V'},
  { value: '5918', nameSort: 'ACTIV DISTRIB CINE', name: 'ACTIVIDADES DE DISTRIBUCION DE PROGRAMAS DE TELEVI'},
  { value: '5920', nameSort: 'ACTIV GRABAR SONIDO', name: 'ACTIVIDADES DE GRABACION DE SONIDO Y EDICION MUSIC'},
  { value: '6010', nameSort: 'RADIODIFUSIÓN', name: 'ACTIVIDADES DE RADIODIFUSION'},
  { value: '6020', nameSort: 'PROGRAMC, EMISIÓN TV', name: 'ACTIVIDADES DE PROGRAMACION Y EMISION DE TELEVISIO'},
  { value: '6110', nameSort: 'TELECOMUNIC POR CABL', name: 'TELECOMUNICACIONES POR CABLE'},
  { value: '6120', nameSort: 'TELECOMUNIC INALAMBR', name: 'TELECOMUNICACIONES INALAMBRICAS'},
  { value: '6130', nameSort: 'TELECOMUNIC SATÉLITE', name: 'TELECOMUNICACIONES POR SATELITE'},
  { value: '6190', nameSort: 'OTR ACTIV TELECOMUNI', name: 'OTRAS ACTIVIDADES DE TELECOMUNICACIONES'},
  { value: '6201', nameSort: 'PROGRAMACIÓN INFORMA', name: 'ACTIVIDADES DE PROGRAMACION INFORMATICA'},
  { value: '6202', nameSort: 'CONSULTORÍA INFORMAT', name: 'ACTIVIDADES DE CONSULTORIA INFORMATICA'},
  { value: '6203', nameSort: 'RECURSOS INFORMÁTICO', name: 'GESTION DE RECURSOS INFORMATICOS'},
  { value: '6209', nameSort: 'OTRS TECNOL INFORMAT', name: 'OTROS SERVICIOS RELACIONADOS CON LAS TECNOLOGIAS D'},
  { value: '6311', nameSort: 'PROCESO DATOS', name: 'PROCESO DE DATOS, HOSTING Y ACTIVIDADES RELACIONAD'},
  { value: '6312', nameSort: 'PORTALES WEB', name: 'PORTALES WEB'},
  { value: '6391', nameSort: 'AGENCIAS DE NOTICIAS', name: 'ACTIVIDADES DE LAS AGENCIAS DE NOTICIAS'},
  { value: '6399', nameSort: 'OTRS SERV INFORMACIO', name: 'OTROS SERVICIOS DE INFORMACION'},
  { value: '6411', nameSort: 'BANCO CENTRAL', name: 'BANCO CENTRAL'},
  { value: '6419', nameSort: 'OTR INTERM MONETARIA', name: 'OTRA INTERMEDIACION MONETARIA'},
  { value: '6420', nameSort: 'ACTIV SOCIEDAD HOLDI', name: 'ACTIVIDADES DE LAS SOCIEDADES HOLDING'},
  { value: '6430', nameSort: 'FONDOS, ENTIDAD FINA', name: 'INVERSION COLECTIVA, FONDOS Y ENTIDADES FINANCIERA'},
  { value: '6491', nameSort: 'ARRENDAMIENTO FINANC', name: 'ARRENDAMIENTO FINANCIERO'},
  { value: '6492', nameSort: 'OTR ACTIV CREDITICIA', name: 'OTRAS ACTIVIDADES CREDITICIAS'},
  { value: '6499', nameSort: 'OTR SERV FINANCIEROS', name: 'OTROS SERVICIOS FINANCIEROS, EXCEPTO SEGUROS Y FON'},
  { value: '6511', nameSort: 'SEGUROS DE VIDA ', name: 'SEGUROS DE VIDA'},
  { value: '6512', nameSort: 'OTROS SEGUROS', name: 'SEGUROS DISTINTOS DE LOS SEGUROS DE VIDA'},
  { value: '6520', nameSort: 'REASEGUROS', name: 'REASEGUROS'},
  { value: '6530', nameSort: 'FONDOS DE PENSIONES ', name: 'FONDOS DE PENSIONES'},
  { value: '6611', nameSort: 'ADMINIST MERC FINANC', name: 'ADMINISTRACION DE MERCADOS FINANCIEROS'},
  { value: '6612', nameSort: 'ACTIV OPERC VALORES', name: 'ACTIVIDADES DE INTERMEDIACION EN OPERACIONES CON V'},
  { value: '6619', nameSort: 'OTR ACTIV FINANCIERO', name: 'OTRAS ACTIVIDADES AUXILIARES A LOS SERVICIOS FINAN'},
  { value: '6621', nameSort: 'EVALUACIÓN RIESGOS', name: 'EVALUACION DE RIESGOS Y DAÑOS'},
  { value: '6622', nameSort: 'AGENTES, CORRED SEGU', name: 'ACTIVIDADES DE AGENTES Y CORREDORES DE SEGUROS'},
  { value: '6629', nameSort: 'OTR ACTIV SEGUROS', name: 'OTRAS ACTIVIDADES AUXILIARES A SEGUROS Y FONDOS DE'},
  { value: '6630', nameSort: 'GESTIÓN FONDOS', name: 'ACTIVIDADES DE GESTION DE FONDOS'},
  { value: '6810', nameSort: 'COMPORAVENTA INMOBIL', name: 'COMPRAVENTA DE BIENES INMOBILIARIOS POR CUENTA PRO'},
  { value: '6820', nameSort: 'ALQUILER INMOBILIARI', name: 'ALQUILER DE BIENES INMOBILIARIOS POR CUENTA PROPIA'},
  { value: '6831', nameSort: 'AGENTES INMOBILIARIA', name: 'AGENTES DE LA PROPIEDAD INMOBILIARIA'},
  { value: '6832', nameSort: 'GESTIÓN PROPD INMOBI', name: 'GESTION Y ADMINISTRACION DE LA PROPIEDAD INMOBILIA'},
  { value: '6910', nameSort: 'ACTIV JURÍDICAS', name: 'ACTIVIDADES JURIDICAS'},
  { value: '6920', nameSort: 'CONTABILID, ASESORÍA', name: 'ACTIVIDADES DE CONTABILIDAD, TENEDURIA DE LIBROS,'},
  { value: '7010', nameSort: 'SEDES CENTRALES', name: 'ACTIVIDADES DE LAS SEDES CENTRALES'},
  { value: '7021', nameSort: 'RELACIONES PÚBLICAS', name: 'RELACIONES PUBLICAS Y COMUNICACION'},
  { value: '7022', nameSort: 'OTR GESTIÓN EMPRESAR', name: 'OTRAS ACTIVIDADES DE CONSULTORIA DE GESTION EMPRES'},
  { value: '7111', nameSort: 'TÉCNICOS ARQUITECTUR', name: 'SERVICIOS TECNICOS DE ARQUITECTURA'},
  { value: '7112', nameSort: 'TÉCNICOS INGENIERÍA', name: 'SERVICIOS TECNICOS DE INGENIERIA Y OTRAS ACTIVIDAD'},
  { value: '7120', nameSort: 'ANÁLISIS TÉCNICOS', name: 'ENSAYOS Y ANALISIS TECNICOS'},
  { value: '7211', nameSort: 'DESARRLL BIOTECNOLOG', name: 'INVESTIGACION Y DESARROLLO EXPERIMENTAL EN BIOTECN'},
  { value: '7219', nameSort: 'OTR INVESTIGACIÓN', name: 'OTRA INVESTIGACION Y DESARROLLO EXPERIMENTAL EN CI'},
  { value: '7220', nameSort: 'DESARRLL SOCIALES', name: 'INVESTIGACION Y DESARROLLO EXPERIMENTAL EN CIENCIA'},
  { value: '7311', nameSort: 'AGENCIAS PUBLICIDAD', name: 'AGENCIAS DE PUBLICIDAD'},
  { value: '7312', nameSort: 'REPRESENT MEDIOS', name: 'SERVICIOS DE REPRESENTACION DE MEDIOS DE COMUNICAC'},
  { value: '7320', nameSort: 'ESTUDIOS DE MERCADO', name: 'ESTUDIOS DE MERCADO Y REALIZACION DE ENCUESTAS DE'},
  { value: '7410', nameSort: 'DISEÑO ESPECIALIZADO', name: 'ACTIVIDADES DE DISEÑO ESPECIALIZADO'},
  { value: '7420', nameSort: 'FOTOGRAFÍA', name: 'ACTIVIDADES DE FOTOGRAFIA'},
  { value: '7430', nameSort: 'TRADUCCIÓN, INTERPRE', name: 'ACTIVIDADES DE TRADUCCION E INTERPRETACION'},
  { value: '7490', nameSort: 'OTR ACTIV PROFESIONA', name: 'OTRAS ACTIVIDADES PROFESIONALES, CIENTIFICAS Y TEC'},
  { value: '7500', nameSort: 'VETERINARIO', name: 'ACTIVIDADES VETERINARIAS'},
  { value: '7711', nameSort: 'ALQUILER AUTOMÓVILES', name: 'ALQUILER DE AUTOMOVILES Y VEHICULOS DE MOTOR LIGER'},
  { value: '7712', nameSort: 'ALQUILER CAMIONES', name: 'ALQUILER DE CAMIONES'},
  { value: '7721', nameSort: 'ALQUIL ARTÍC. OCIO', name: 'ALQUILER DE ARTICULOS DE OCIO Y DEPORTIVOS'},
  { value: '7722', nameSort: 'ALQUIL VIDEO, DISCOS', name: 'ALQUILER DE CINTAS DE VIDEO Y DISCOS'},
  { value: '7729', nameSort: 'ALQUIL OTR EFECT PER', name: 'ALQUILER DE OTROS EFECTOS PERSONALES Y ARTICULOS D'},
  { value: '7731', nameSort: 'ALQUIL MAQUIN AGRICO', name: 'ALQUILER DE MAQUINARIA Y EQUIPO DE USO AGRICOLA'},
  { value: '7732', nameSort: 'ALQUIL MAQUIN CONSTR', name: 'ALQUILER DE MAQUINARIA Y EQUIPO PARA LA CONSTRUCCI'},
  { value: '7733', nameSort: 'ALQUIL MAQUIN OFICIN', name: 'ALQUILER DE MAQUINARIA Y EQUIPO DE OFICINA, INCLUI'},
  { value: '7734', nameSort: 'ALQUIL NAVEGACIÓN', name: 'ALQUILER DE MEDIOS DE NAVEGACION'},
  { value: '7735', nameSort: 'ALQUIL TRASP AÉREO', name: 'ALQUILER DE MEDIOS DE TRANSPORTE AEREO'},
  { value: '7739', nameSort: 'ALQUIL OTR MAQUINA', name: 'ALQUILER DE OTRA MAQUINARIA, EQUIPOS Y BIENES TANG'},
  { value: '7740', nameSort: 'ARRENDA PROPIAD INTE', name: 'ARRENDAMIENTO DE LA PROPIEDAD INTELECTUAL Y PRODUC'},
  { value: '7810', nameSort: 'AGENCIAS COLOCACIÓN', name: 'ACTIVIDADES DE LAS AGENCIAS DE COLOCACION'},
  { value: '7820', nameSort: 'EMPR TRABAJ TEMPORAL', name: 'ACTIVIDADES DE LAS EMPRESAS DE TRABAJO TEMPORAL'},
  { value: '7830', nameSort: 'OTR RECURS HUMANOS', name: 'OTRA PROVISION DE RECURSOS HUMANOS'},
  { value: '7911', nameSort: 'AGENCIAS DE VIAJES', name: 'ACTIVIDADES DE LAS AGENCIAS DE VIAJES'},
  { value: '7912', nameSort: 'OPERADORES TURISTICO', name: 'ACTIVIDADES DE LOS OPERADORES TURISTICOS'},
  { value: '7990', nameSort: 'OTR SERVICIOS RESERV', name: 'OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES RELACION'},
  { value: '8010', nameSort: 'SEGURIDAD PRIVADA', name: 'ACTIVIDADES DE SEGURIDAD PRIVADA'},
  { value: '8020', nameSort: 'SISTEMAS SEGURIDAD', name: 'SERVICIOS DE SISTEMAS DE SEGURIDAD'},
  { value: '8030', nameSort: 'INVESTIGACIÓN', name: 'ACTIVIDADES DE INVESTIGACION'},
  { value: '8110', nameSort: 'SERV A INSTALACIONES', name: 'SERVICIOS INTEGRALES A EDIFICIOS E INSTALACIONES'},
  { value: '8121', nameSort: 'SERV A INSTALACIONES', name: 'LIMPIEZA GENERAL DE EDIFICIOS'},
  { value: '8122', nameSort: 'OTR LIMP INDUSTRIAL', name: 'OTRAS ACTIVIDADES DE LIMPIEZA INDUSTRIAL Y DE EDIF'},
  { value: '8129', nameSort: 'OTR ACTIV LIMPIEZA', name: 'OTRAS ACTIVIDADES DE LIMPIEZA'},
  { value: '8130', nameSort: 'JARDINERÍA', name: 'ACTIVIDADES DE JARDINERIA'},
  { value: '8211', nameSort: 'SERV ADMINIST COMBIN', name: 'SERVICIOS ADMINISTRATIVOS COMBINADOS'},
  { value: '8219', nameSort: 'ACTIV ESPECIAL OFICI', name: 'ACTIVIDADES DE FOTOCOPIADO, PREPARACION DE DOCUMEN'},
  { value: '8220', nameSort: 'CENTROS DE LLAMADA', name: 'ACTIVIDADES DE LOS CENTROS DE LLAMADAS'},
  { value: '8230', nameSort: 'CONVENCIONES Y FERIA', name: 'ORGANIZACION DE CONVENCIONES Y FERIAS DE MUESTRAS'},
  { value: '8291', nameSort: 'AGENCIAS DE COBROS', name: 'ACTIVIDADES DE LAS AGENCIAS DE COBROS Y DE INFORMA'},
  { value: '8292', nameSort: 'ACTIV EMPAQUETADO', name: 'ACTIVIDADES DE ENVASADO Y EMPAQUETADO'},
  { value: '8299', nameSort: 'OTR ACTIV EMPRESARIA', name: 'OTRAS ACTIVIDADES DE APOYO A LAS EMPRESAS'},
  { value: '8411', nameSort: 'AMINISTRAC PÚBLICA', name: 'ACTIVIDADES GENERALES DE LA ADMINISTRACION PUBLICA'},
  { value: '8412', nameSort: 'ACTIV SANITARIA, EDU', name: 'REGULACION DE LAS ACTIVIDADES SANITARIAS, EDUCATIV'},
  { value: '8413', nameSort: 'ACTIVIDAD ECONÓMICA', name: 'REGULACION DE LA ACTIVIDAD ECONOMICA Y CONTRIBUCIO'},
  { value: '8421', nameSort: 'ASUNTOS EXTERIORES', name: 'ASUNTOS EXTERIORES'},
  { value: '8422', nameSort: 'DEFENSA', name: 'DEFENSA'},
  { value: '8423', nameSort: 'JUSTICIA', name: 'JUSTICIA'},
  { value: '8424', nameSort: 'ORDEN PÚBLICO', name: 'ORDEN PUBLICO Y SEGURIDAD'},
  { value: '8425', nameSort: 'PROTECCIÓN CIVIL', name: 'PROTECCION CIVIL'},
  { value: '8430', nameSort: 'SEGURIDAD SOCIAL', name: 'SEGURIDAD SOCIAL OBLIGATORIA'},
  { value: '8510', nameSort: 'EDUCACIÓN PREPRIMARI', name: 'EDUCACION PREPRIMARIA'},
  { value: '8520', nameSort: 'EDUCACION PRIMARIA', name: 'EDUCACION PRIMARIA'},
  { value: '8531', nameSort: 'EDUCAC SECUND GENERA', name: 'EDUCACION SECUNDARIA GENERAL'},
  { value: '8532', nameSort: 'EDUCAC SECUND TECNIC', name: 'EDUCACION SECUNDARIA TECNICA Y PROFESIONAL'},
  { value: '8541', nameSort: 'EDUCAC POSTSECUNDARI', name: 'EDUCACION POSTSECUNDARIA NO TERCIARIA'},
  { value: '8543', nameSort: 'EDUCACIÓN UNIVERSITA', name: 'EDUCACION UNIVERSITARIA'},
  { value: '8544', nameSort: 'EDUCACIÓN TERCIARIA', name: 'EDUCACION TERCIARIA NO UNIVERSITARIA'},
  { value: '8551', nameSort: 'EDUCACIÓN DEPORTIVA', name: 'EDUCACION DEPORTIVA Y RECREATIVA'},
  { value: '8552', nameSort: 'EDUCACION CULTURAL', name: 'EDUCACION CULTURAL'},
  { value: '8553', nameSort: 'ESCUELA CONDUCCIÓN', name: 'ACTIVIDADES DE LAS ESCUELAS DE CONDUCCION Y PILOTA'},
  { value: '8559', nameSort: 'OTRA EDUCACIÓN', name: 'OTRA EDUCACION N'},
  { value: '8560', nameSort: 'ACTIV DE EDUCACIÓN', name: 'ACTIVIDADES AUXILIARES A LA EDUCACION'},
  { value: '8610', nameSort: 'ACTIV HOSPITALARIA', name: 'ACTIVIDADES HOSPITALARIAS'},
  { value: '8621', nameSort: 'MEDICINA GENERAL', name: 'ACTIVIDADES DE MEDICINA GENERAL'},
  { value: '8622', nameSort: 'MEDICINA ESPECIAL', name: 'ACTIVIDADES DE MEDICINA ESPECIALIZADA'},
  { value: '8623', nameSort: 'ODONTOLÓGICAS', name: 'ACTIVIDADES ODONTOLOGICAS'},
  { value: '8690', nameSort: 'OTR ACTIV SANITARIA', name: 'OTRAS ACTIVIDADES SANITARIAS'},
  { value: '8710', nameSort: 'ASISTENC RESIDENCIAL', name: 'ASISTENCIA EN ESTABLECIMIENTOS RESIDENCIALES CON C'},
  { value: '8720', nameSort: 'RESIDENCIAL DISCAPAC', name: 'ASISTENCIA EN ESTABLECIMIENTOS RESIDENCIALES PARA DISCAPACITADOS'},
  { value: '8731', nameSort: 'RESIDENC MAYORES', name: 'ASISTENCIA EN ESTABLECIMIENTOS RESIDENCIALES PARA MAYORES'},
  { value: '8732', nameSort: 'RESIDENC DISCAP FISI', name: 'ASISTENCIA EN ESTABLECIMIENTOS RESIDENCIALES PARA FISI'},
  { value: '8790', nameSort: 'OTR ESTABLEC RESIDEN', name: 'OTRAS ACTIVIDADES DE ASISTENCIA EN ESTABLECIMIENTO'},
  { value: '8811', nameSort: 'SERVC SOCIAL MAYORES', name: 'ACTIVIDADES DE SERVICIOS SOCIALES MAYORES SIN ALOJAMIENTO'},
  { value: '8812', nameSort: 'SERVC SOCIAL DISCAPA', name: 'ACTIVIDADES DE SERVICIOS SOCIALES DISCAPACITADOS SIN ALOJAMIENTO'},
  { value: '8891', nameSort: 'CUIDADO DE NIÑOS', name: 'ACTIVIDADES DE CUIDADO DIURNO DE NIÑOS'},
  { value: '8899', nameSort: 'OTR SERVC SOCIAL', name: 'OTRAS ACTIVIDADES DE SERVICIOS SOCIALES SIN ALOJAM'},
  { value: '9001', nameSort: 'ARTES ESCENICAS ', name: 'ARTES ESCENICAS'},
  { value: '9002', nameSort: 'ACTIV AUXIL ARTE ESC', name: 'ACTIVIDADES AUXILIARES A LAS ARTES ESCENICAS'},
  { value: '9003', nameSort: 'CREACIÓN ARTÍSTICA', name: 'CREACION ARTISTICA Y LITERARIA'},
  { value: '9004', nameSort: 'SALAS ESPECTÁCULO', name: 'GESTION DE SALAS DE ESPECTACULOS'},
  { value: '9102', nameSort: 'MUSEOS', name: 'ACTIVIDADES DE MUSEOS'},
  { value: '9103', nameSort: 'EDIFICIOS HISTÓRICOS', name: 'GESTION DE LUGARES Y EDIFICIOS HISTORICOS'},
  { value: '9104', nameSort: 'RESERVAS NATURALES', name: 'ACTIVIDADES DE LOS JARDINES BOTANICOS, PARQUES ZOO'},
  { value: '9105', nameSort: 'BIBLIOTECAS', name: 'ACTIVIDADES DE BIBLIOTECAS'},
  { value: '9106', nameSort: 'ARCHIVOS', name: 'ACTIVIDADES ARCHIVOS'},
  { value: '9200', nameSort: 'JUEGOS DE AZAR', name: 'ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS'},
  { value: '9311', nameSort: 'INSTALACIÓN DEPORTIV', name: 'GESTION DE INSTALACIONES DEPORTIVAS'},
  { value: '9312', nameSort: 'CLUBES DEPORTIVOS', name: 'ACTIVIDADES DE LOS CLUBES DEPORTIVOS'},
  { value: '9313', nameSort: 'GIMNASIOS', name: 'ACTIVIDADES DE LOS GIMNASIOS'},
  { value: '9319', nameSort: 'OTR ACTIV DEPORTIVAS', name: 'OTRAS ACTIVIDADES DEPORTIVAS'},
  { value: '9321', nameSort: 'PARQUES TEMÁTICOS', name: 'ACTIVIDADES DE LOS PARQUES DE ATRACCIONES Y LOS PA'},
  { value: '9329', nameSort: 'OTR ACTIV RECREATIVA', name: 'OTRAS ACTIVIDADES RECREATIVAS Y DE ENTRETENIMIENTO'},
  { value: '9411', nameSort: 'ACTIV ORGANIZ EMPRES', name: 'ACTIVIDADES DE ORGANIZACIONES EMPRESARIALES Y PATR'},
  { value: '9412', nameSort: 'ACTIV ORGANIZ PROFES', name: 'ACTIVIDADES DE ORGANIZACIONES PROFESIONALES'},
  { value: '9420', nameSort: 'ACTIV SINDICALES', name: 'ACTIVIDADES SINDICALES'},
  { value: '9491', nameSort: 'ORGANIZAC RELIGIOSAS', name: 'ACTIVIDADES DE ORGANIZACIONES RELIGIOSAS'},
  { value: '9492', nameSort: 'ORGANIZAC POLÍTICAS', name: 'ACTIVIDADES DE ORGANIZACIONES POLITICAS'},
  { value: '9499', nameSort: 'OTR ASOCIACIONES', name: 'OTRAS ACTIVIDADES ASOCIATIVAS N'},
  { value: '9511', nameSort: 'REP.ORDENADORES', name: 'REPARACION DE ORDENADORES Y EQUIPOS PERIFERICOS'},
  { value: '9512', nameSort: 'REP.EQ.COMUNICACION', name: 'REPARACION DE EQUIPOS DE COMUNICACION'},
  { value: '9521', nameSort: 'REPARC ELECTROD VIDE', name: 'REPARACION DE APARATOS ELECTRONICOS DE AUDIO Y VID'},
  { value: '9522', nameSort: 'REPARC ELECTRODOMEST', name: 'REPARACION DE APARATOS ELECTRODOMESTICOS Y DE EQUI'},
  { value: '9523', nameSort: 'REPARC CALZADO', name: 'REPARACION DE CALZADO Y ARTICULOS DE CUERO'},
  { value: '9524', nameSort: 'REPARC MUEBLES', name: 'REPARACION DE MUEBLES Y ARTICULOS DE MENAJE'},
  { value: '9525', nameSort: 'REPARC JOYERÍA', name: 'REPARACION DE RELOJES Y JOYERIA'},
  { value: '9529', nameSort: 'OTR REPARACIONES', name: 'REPARACION DE OTROS EFECTOS PERSONALES Y ARTICULOS'},
  { value: '9601', nameSort: 'LAVADO TEXTIL', name: 'LAVADO Y LIMPIEZA DE PRENDAS TEXTILES Y DE PIEL'},
  { value: '9602', nameSort: 'LAVADO TEXTIL', name: 'PELUQUERIA Y OTROS TRATAMIENTOS DE BELLEZA'},
  { value: '9603', nameSort: 'ACTIV FUNEBRES', name: 'POMPAS FUNEBRES Y ACTIVIDADES RELACIONADAS'},
  { value: '9604', nameSort: 'ACTIV MANTEN.FÍSICO', name: 'ACTIVIDADES DE MANTENIMIENTO FISICO'},
  { value: '9609', nameSort: 'ACTIV MANTEN.FÍSICO', name: 'OTROS SERVICIOS PERSONALES N'},
  { value: '9700', nameSort: 'ACTIV HOGAR EMPLEADO', name: 'ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PER'},
  { value: '9810', nameSort: 'ACTIV HOGARES PRODUC', name: 'ACTIVIDADES DE LOS HOGARES COMO PRODUCTORES DE BIE'},
  { value: '9820', nameSort: 'ACTIV HOGARES SERVIC', name: 'ACTIVIDADES DE LOS HOGARES COMO PRODUCTORES DE SER'},
  { value: '9900', nameSort: 'ORGANISM EXTRTERRIT', name: 'ACTIVIDADES DE ORGANIZACIONES Y ORGANISMOS EXTRATE'},
]
