import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Headers, RequestOptions } from '@angular/http';
import { Otp } from 'app/collections/otp';
import { ValidateOtp } from 'app/collections/validate.otp';
import { ValidateSecurizedOtp, ValidateSecurizedOtpResponse } from 'app/collections/validate.securized.otp';
import { Observable } from 'rxjs';
import { ResponseOTP } from 'app/collections/otp.token';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { OtpTypeA } from 'app/collections/otp-type-a';

const API_URL = environment.apiUrl;
const CLIENT_ID = environment.otpClientId;
const CLIENT_SECRET = environment.otpClientSecret;

@Injectable()
export class ApiOtpService {
  private options = {};
  constructor(private http: HttpClient) {
    this.options = { withCredentials: true, observe: 'response' };
  }

  // API: POST /generateSecurizedOtp
  public generateOtp(otp: Otp): Observable<Otp> {
    return this.http
      .post(API_URL + '/fullonline/integracion/otp/generateOtp',
            otp,
            this.options)
      .pipe(map(response => {
        return new Otp(response['body']);
      }));
  }

  // API: POST /generateSecurizedOtp
  public generateSecurizedOtp(otp: Otp): Observable<Otp> {
    return this.http
      .post(API_URL + '/fullonline/integracion/otp/generateSecurizedOtp/' + otp.userId,
            otp,
            this.options)
      .pipe(map(response => {
        return new Otp(response['body']);
      }));
  }

  // API: POST /generateSecurizedOtp
  public generateSecurizedOtpTypeA(otp: OtpTypeA): Observable<Otp> {
    return this.http
      .post(API_URL + '/fullonline/integracion/otp/generateSecurizedOtp/' + otp.userId,
            otp,
            this.options)
      .pipe(map(response => {
        return new Otp(response['body']);
      }));
  }

  // API: POST /validateOTP
  public validateOTP(otp: ValidateOtp): Observable<ValidateOtp> {
    return this.http
      .post(API_URL + '/fullonline/integracion/otp/validateOtp', otp, this.options)
      .pipe(map(response => {
        return new ValidateOtp(response['body']);
      }));
  };

   // API: POST /validateSecurizedOtp
   public validateSecurizedOtp(otp: ValidateSecurizedOtp): Observable<ValidateSecurizedOtpResponse> {
    return this.http
      .post(API_URL + '/fullonline/integracion/otp/validateSecurizedOtp', otp, this.options)
      .pipe(map(response => {
        return new ValidateSecurizedOtpResponse(response['body']);
      }));
  };

  // API: POST /generateTokenOTP
  public generateTokenOTP(mobile: string): Observable<ResponseOTP> {
    const params = `grant_type=onboarding&scope=otp&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&username=${mobile}`;
    return this.http
      .post(`${API_URL}/auth/oauth/v2/token?${params}`, this.options)
      .pipe(map(response => {
        return new ResponseOTP(response['body']);
      }));
  };

}
