export const TIPOACTIVIDADPROFESIONAL = [
    {
      profesion: "ACTIVIDADES MILITARES",
      name: "OFICIALES Y SUBOFICIALES DE LAS FUERZAS ARMADAS",
      value: "0011",
      options: [
        "OFICIALES DE LAS FUERZAS ARMADAS",
        "CAPITAN GENERAL",
        "GENERAL DEL AIRE",
        "GENERAL DEL EJERCITO",
        "ALMIRANTE GENERAL",
        "TENIENTE GENERAL",
        "ALMIRANTE",
        "OFICIAL DE LAS FUERZAS ARMADAS",
        "GENERAL DE DIVISIÓN",
        "VICEALMIRANTE",
        "GENERAL DE BRIGADA",
        "CONTRALMIRANTE",
        "CORONEL",
        "CAPITAN DE NAVIO",
        "TENIENTE CORONEL",
        "CAPITAN DE FRAGATA",
        "COMANDANTE",
        "CAPITÁN DE CORBETA",
        "CAPITAN",
        "TENIENTE DE NAVIO",
        "TENIENTE",
        "ALFEREZ DE NAVIO",
        "ALFEREZ",
        "ALFEREZ DE FRAGATA",
        "SUBOFICIAL MAYOR",
        "SUBTENIENTE",
        "BRIGADA",
        "SARGENTO PRIMERO",
        "SARGENTO",
      ],
      descripcion_cno__c: [
        {
          value: "0011",
          name: "OFICIALES DE LAS FUERZAS ARMADAS",
        },
        {
          value: "0012",
          name: "SUBOFICIALES DE LAS FUERZAS ARMADAS",
        },
      ],
    },
    {
      profesion: "ACTIVIDADES MILITARES",
      name: "TROPA Y MARINERÍA DE LAS FUERZAS ARMADAS",
      value: "0020",
      options: [
        "TROPA Y MARINERÍA DE LAS FUERZAS ARMADAS",
        "TROPA Y MARINERÍA DE LAS FUERZAS ARMADAS",
        "CABO MAYOR",
        "CABO PRIMERO",
        "CABO",
        "SOLDADO DE PRIMERA",
        "GUARDIA DE PRIMERA",
        "MARINERO DE PRIMERA",
        "SOLDADO",
        "MARINERO",
        "GUARDIA CIVIL",
        "SOLDADO PROFESIONAL",
      ],
      descripcion_cno__c: [
        {
          value: "0020",
          name: "TROPA Y MARINERÍA DE LAS FUERZAS ARMADAS",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "MIEMBROS DEL PODER EJECUTIVO(NACIONAL, AUTONOMICO,",
      value: "1111",
      descripcion_cno__c: [
        {
          value: "1111",
          name: "MIEMBROS DEL PODER EJECUTIVO(NACIONAL, AUTONOMICO)",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "PERSONAL DIRECTIVO DE LA ADMINISTRACION PUBLICA",
      value: "1112",
      descripcion_cno__c: [
        {
          value: "1112",
          name: "PERSONAL DIRECTIVO DE LA ADMINISTRACION PUBLICA",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "DIRECTORES DE ORGANIZACIONES DE INTERES SOCIAL",
      value: "1113",
      descripcion_cno__c: [
        {
          value: "1113",
          name: "DIRECTORES DE ORGANIZACIONES DE INTERES SOCIAL",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "DIRECTORES GENERALES Y PRESIDENTES EJECUTIVOS",
      value: "1120",
      options: [
        "DTORES. GENERALES Y PRESIDENTES EJECUTIVOS",
        "DIRECTORES GENERALES Y PRESIDENTES EJECUTIVOS",
        "DIRECTOR GENERAL",
        "DIRECTORA GENERAL",
        "DIRECTORES GENERALES",
        "DIRECTORAS GENERALES",
        "PRESIDENTE EJECUTIVO",
        "PRESIDENTA EJECUTIVA",
        "PRESIDENTES EJECUTIVOS",
        "PRESIDENTAS EJECUTIVAS",
        "CEO",
        "DIRECTOR EJECUTIVO",
        "DIRECTORA EJECUTIVA",
        "DIRECTORES EJECUTIVOS",
        "DIRECTORAS EJECUTIVAS",
        "CONSEJERO DELEGADO",
        "CONSEJEROS DELEGADOS",
        "CONSEJERA DELEGADA",
        "CONSEJERAS DELEGADAS",
        "PRINCIPAL OFICIAL EJECUTIVO",
        "PRINCIPALES OFICIALES EJECUTIVOS",
      ],
      descripcion_cno__c: [
        {
          value: "1120",
          name: "DIRECTORES GENERALES Y PRESIDENTES EJECUTIVOS",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "DIRECTORES DE DEPARTAMENTOS ADMINISTRATIVOS",
      value: "1211",
      options: [
        "DTORES. FINANCIEROS",
        "DIRECTORES FINANCIEROS",
        "DIRECTORAS FINANCIERAS",
        "DIRECTOR FINANCIERO",
        "DIRECTORA FINANCIERA",
      ],
      descripcion_cno__c: [
        {
          value: "1211",
          name: "DIRECTORES FINANCIEROS",
        },
        {
          value: "1212",
          name: "DIRECTORES DE RECURSOS HUMANOS",
        },
        {
          value: "1219",
          name: "DIRECTORES DE POLITICAS Y PLANIFICACION Y DE OTROS",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "DIRECTORES COMERCIALES, DE PUBLICIDAD, RELACIONES PÚBLICAS Y DE INVESTIGACIÓN Y DESARROLLO",
      value: "1221",
      options: [
        "DTORES. COMERCIALES Y DE VENTAS",
        "DTORES. COMERCIALES Y DE VENTAS",
        "DIRECTOR COMERCIAL Y DE VENTAS",
        "DIRECTORA COMERCIAL Y DE VENTAS",
        "DIR. COMERCIAL Y DE VENTAS",
        "DIRECTOR COMERCIAL",
        "DIRECTORES COMERCIALES",
        "DIRECTORA COMERCIAL",
        "DIRECTORAS COMERCIAL",
        "DIRECTORES DE DEPARTAMENTO DE COMERCIALIZACIÓN Y VENTA",
        "DIRECTORAS DE DEPARTAMENTO DE COMERCIALIZACIÓN Y VENTA",
        "DIRECTOR DE DEPARTAMENTO DE COMERCIALIZACIÓN Y VENTA",
        "DIRECTORA DE DEPARTAMENTO DE COMERCIALIZACIÓN Y VENTA",
        "DIRECTOR DE MARKETING",
        "DIRECTORA DE MARKETING",
        "DIRECTORES DE MARKETING",
        "DIRECTORAS DE MARKETING",
        "DIRECTOR DEL DEPARTAMENTO DE MARKETING",
        "DIRECTORA DEL DEPARTAMENTO DE MARKETING",
        "DIRECTORES DEL DEPARTAMENTO DE MARKETING",
        "DIRECTORAS DEL DEPARTAMENTO DE MARKETING",
        "DIRECTORES DEL DEPARTAMENTO DE TELEMARKETING",
        "DIRECTORAS DEL DEPARTAMENTO DE TELEMARKETING",
        "DIRECTOR DEL DEPARTAMENTO DE TELEMARKETING",
        "DIRECTORA DEL DEPARTAMENTO DE TELEMARKETING",
        "DIRECTOR DE TELEMARKETING",
        "DIRECTORA DE TELEMARKETING",
        "DIRECTORES DE TELEMARKETING",
        "DIRECTORAS DE TELEMARKETING",
        "DIRECTOR DE CALL CENTER",
        "DIRECTORA DE CALL CENTER",
        "DIRECTORES DE CALL CENTER",
        "DIRECTORAS DE CALL CENTER",
        "DIRECTOR DE CONTACT CENTER",
        "DIRECTORA DE CONTACT CENTER",
        "DIRECTORES DE CONTACT CENTER",
        "DIRECTORAS DE CONTACT CENTER",
        "DIRECTOR DE COMERCIALIZACIÓN",
        "DIRECTORA DE COMERCIALIZACIÓN",
      ],
      descripcion_cno__c: [
        {
          value: "1221",
          name: "DIRECTORES COMERCIALES Y DE VENTAS",
        },
        {
          value: "1222",
          name: "DIRECTORES DE PUBLICIDAD Y RELACIONES PÚBLICAS",
        },
        {
          value: "1223",
          name: "DIRECTORES DE INVESTIGACION Y DESARROLLO",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "DIRECTORES DE PRODUCCIÓN DE EXPLOTACIONES AGROPECUARIAS, FORESTALES Y PESQUERAS, Y DE INDUSTRIAS MANUFACTURERAS, DE MINERÍA, CONSTRUCCIÓN Y DISTRIBUCIÓN",
      value: "1311",
      descripcion_cno__c: [
        {
          value: "1311",
          name: "DIRECTORES DE PRODUCCION DE EXPLOTACIONES AGROPECUARIAS",
        },
        {
          value: "1312",
          name: "DIRECTORES DE EXPLOTACIONES PESQUERAS Y ACUICOLAS",
        },
        {
          value: "1313",
          name: "DIRECTORES DE INDUSTRIAS MANUFACTURERAS",
        },
        {
          value: "1314",
          name: "DIRECTORES DE EXPLOTACIONES MINERAS",
        },
        {
          value: "1315",
          name: "DIRECTORES DE EMPRESAS DE ABASTECIMIENTO, TRANSPORTES",
        },
        {
          value: "1316",
          name: "DIRECTORES DE EMPRESAS DE CONSTRUCCION",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "DIRECTORES DE SERVICIOS DE TECNOLOGÍAS DE LA INFORMACIÓN Y LAS COMUNICACIONES (TIC) Y DE EMPRESAS DE SERVICIOS PROFESIONALES",
      value: "1321",
      descripcion_cno__c: [
        {
          value: "1321",
          name: "DIRECTORES DE SERVICIOS DE TECNOLOGIAS DE LA INFORMACION",
        },
        {
          value: "1322",
          name: "DIRECTORES DE SERVICIOS SOCIALES PARA NIÑOS",
        },
        {
          value: "1323",
          name: "DIRECTORES-GERENTES DE CENTROS SANITARIOS",
        },
        {
          value: "1324",
          name: "DIRECTORES DE SERVICIOS SOCIALES PARA PERSONAS MAYORES",
        },
        {
          value: "1325",
          name: "DIRECTORES DE OTROS SERVICIOS SOCIALES",
        },
        {
          value: "1326",
          name: "DIRECTORES DE SERVICIOS DE EDUCACION",
        },
        {
          value: "1327",
          name: "DIRECTORES DE SUCURSALES DE BANCOS, DE SERVICIOS FINANCIEROS",
        },
        {
          value: "1329",
          name: "DIRECTORES DE OTRAS EMPRESAS DE SERVICIOS PROFESIONALES",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "DIRECTORES Y GERENTES DE EMPRESAS DE ALOJAMIENTO Y RESTAURACIÓN",
      value: "1411",
      descripcion_cno__c: [
        {
          value: "1411",
          name: "DIRECTORES Y GERENTES DE HOTELES",
        },
        {
          value: "1419",
          name: "DIRECTORES Y GERENTES DE OTRAS EMPRESAS DE SERVICIOS",
        },
        {
          value: "1421",
          name: "DIRECTORES Y GERENTES DE RESTAURANTES",
        },
        {
          value: "1422",
          name: "DIRECTORES Y GERENTES DE BARES,CAFETERIAS Y SIMILARES",
        },
        {
          value: "1429",
          name: "DIRECTORES Y GERENTES DE EMPRESAS DE CATERING Y OTRAS EMPRESAS DE RESTAURACION",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "DIRECTORES Y GERENTES DE EMPRESAS DE COMERCIO AL POR MAYOR Y AL POR MENOR",
      value: "1431",
      descripcion_cno__c: [
        {
          value: "1431",
          name: "DIRECTORES Y GERENTES DE EMPRESAS DE COMERCIO AL POR MAYOR",
        },
        {
          value: "1432",
          name: "DIRECTORES Y GERENTES DE EMPRESAS DE COMERCIO AL POR MENOR",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "DIRECTORES Y GERENTES DE OTRAS EMPRESAS DE SERVICIOS NO CLASIFICADOS BAJO OTROS EPÍGRAFES",
      value: "1501",
      descripcion_cno__c: [
        {
          value: "1501",
          name: "DIRECTORES Y GERENTES DE EMPRESAS DE ACTIVIDADES RECREATIVAS,CULTURALES Y DEPORTIVAS",
        },
        {
          value: "1509",
          name: "DIRECTORES Y GERENTES DE EMPRESAS DE GESTION DE RESIDUOS Y DE OTRAS EMPRESAS DE SERVICIOS NO CLASIFICADOS EN OTROS EPIGRAFES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "MÉDICOS",
      value: "2111",
      descripcion_cno__c: [
        {
          value: "2111",
          name: "MEDICOS DE FAMILIA",
        },
        {
          value: "2112",
          name: "OTROS MEDICOS ESPECIALISTAS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "PROFESIONALES DE ENFERMERÍA Y PARTERÍA",
      value: "2121",
      options: [
        "ENFERMEROS NO ESPECIALIZADOS",
        "ENFERMEROS",
        "ENFERMERAS",
        "ENFERMERA",
        "ENFERMERO NO ESPECIALIZADO",
        "ENFERMERA NO ESPECIALIZADA",
        "ENFERMERÍA Y ATENCIÓN A ENFERMOS",
      ],
      descripcion_cno__c: [
        {
          value: "2121",
          name: "ENFERMEROS NO ESPECIALIZADOS",
        },
        {
          value: "2122",
          name: "ENFERMEROS ESPECIALIZADOS(EXCEPTO MATRONOS)",
        },
        {
          value: "2123",
          name: "MATRONOS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "VETERINARIOS",
      value: "2130",
      descripcion_cno__c: [
        {
          value: "2130",
          name: "VETERINARIOS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "FARMACEUTICOS",
      value: "2140",
      options: [
        "FARMACEUTICOS",
        "FARMACIA",
        "FARMACÉUTICAS",
        "FARMACÉUTICO",
        "FARMACÉUTICA",
      ],
      descripcion_cno__c: [
        {
          value: "2140",
          name: "FARMACEUTICOS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "OTROS PROFESIONALES DE LA SALUD",
      value: "2159",
      descripcion_cno__c: [
        {
          value: "2151",
          name: "ODONTOLOGOS Y ESTOMATOLOGOS",
        },
        {
          value: "2152",
          name: "FISIOTERAPEUTAS",
        },
        {
          value: "2153",
          name: "DIETISTAS Y NUTRICIONISTAS",
        },
        {
          value: "2154",
          name: "LOGOPEDAS",
        },
        {
          value: "2155",
          name: "OPTICOS-OPTOMETRISTAS",
        },
        {
          value: "2156",
          name: "TERAPEUTAS OCUPACIONALES",
        },
        {
          value: "2157",
          name: "PODOLOGOS",
        },
        {
          value: "2158",
          name: "PROFESIONALES DE LA SALUD Y LA HIGIENE LABORAL Y A",
        },
        {
          value: "2159",
          name: "PROFESIONALES DE LA SALUD NO CLASIFICADOS BAJO OTR",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "PROFESORES DE UNIVERSIDADES Y OTRA ENSEÑANZA SUPER",
      value: "2210",
      options: [
        "PROFESORES UNIVERSIDADES Y ENSEÑANZA SUP., EXC. FP",
        "PROFESORES DE UNIVERSIDADES Y OTRA ENSEÑANZA SUPERIOR (EXCEPTO FORMACIÓN PROFESIONAL)",
        "PROFESORAS UNIVERSIDADES Y ENSEÑANZA SUP., EXC. FP",
        "PROFESOR UNIVERSIDADES Y ENSEÑANZA SUP., EXC. FP",
        "PROFESORA UNIVERSIDADES Y ENSEÑANZA SUP., EXC. FP",
        "PROFESORAS DE UNIVERSIDADES Y OTRA ENSEÑANZA SUPERIOR (EXCEPTO FORMACIÓN PROFESIONAL)",
        "PROFESOR DE UNIVERSIDAD Y OTRA ENSEÑANZA SUPERIOR (EXCEPTO FORMACIÓN PROFESIONAL)",
        "PROFESORA DE UNIVERSIDAD Y OTRA ENSEÑANZA SUPERIOR (EXCEPTO FORMACIÓN PROFESIONAL)",
        "PROFESOR DE UNIVERSIDAD",
        "PROFESORA DE UNIVERSIDAD",
        "PROFESORADO DE UNIVERSIDAD",
        "PROFESOR DE ENSEÑANZA SUPERIOR EXCEPTO FORMACIÓN PROFESIONAL",
        "PROFESORES DE ENSEÑANZA SUPERIOR EXCEPTO FORMACIÓN PROFESIONAL",
        "PROFESORA DE ENSEÑANZA SUPERIOR EXCEPTO FORMACIÓN PROFESIONAL",
        "PROFESOR DE ENSEÑANZA SUPERIOR EXCEPTO F.P.",
        "PROFESOR DE ENSEÑANZA SUPERIOR EXCEPTO F.P.",
        "PROFESORA DE ENSEÑANZA SUPERIOR EXCEPTO F.P.",
        "PROFESORA DE ENSEÑANZA SUPERIOR EXCEPTO FP",
      ],
      descripcion_cno__c: [
        {
          value: "2210",
          name: "PROFESORES DE UNIVERSIDADES Y OTRA ENSEÑANZA SUPER",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "OTROS PROFESORES Y PROFESIONALES DE LA ENSEÑANZA",
      value: "2329",
      options: [
        "PROFESORES Y PROFESIONALES DE LA ENSEÑANZA (OTROS)",
        "PROFESORAS Y PROFESIONALES DE LA ENSEÑANZA (OTROS)",
        "PROFESORES Y PROFESIONALES DE LA ENSEÑANZA NO CLASIFICADOS BAJO OTROS EPÍGRAFES",
        "FORMADOR DE FORMACIÓN NO REGLADA",
        "FORMADORES DE FORMACIÓN NO REGLADA",
        "FORMADORAS DE FORMACIÓN NO REGLADA",
        "FORMADORA DE FORMACIÓN NO REGLADA",
        "FORMADORES DE FORMACIÓN OCUPACIONAL NO REGLADA",
        "FORMADORAS DE FORMACIÓN OCUPACIONAL NO REGLADA",
        "FORMADOR DE FORMACIÓN OCUPACIONAL NO REGLADA",
        "FORMADORA DE FORMACIÓN OCUPACIONAL NO REGLADA",
        "FORMADOR OCUPACIONAL",
        "FORMADORA OCUPACIONAL",
        "FORMADORAS OCUPACIONAL",
        "FORMADORES OCUPACIONAL",
        "FORMADORES-TUTORES DE EMPRESA",
        "FORMADOR-TUTOR DE EMPRESA",
        "FORMADORA-TUTORA DE EMPRESA",
        "FORMADORAS-TUTORAS DE EMPRESA",
        "ORIENTADORES ESCOLARES",
        "ORIENTADOR ESCOLAR",
        "ORIENTADORA ESCOLAR",
        "ORIENTADORAS ESCOLARES",
        "TUTOR DE FORMACIÓN ABIERTA Y A DISTANCIA",
        "TUTORA DE FORMACIÓN ABIERTA Y A DISTANCIA",
        "TUTORAS DE FORMACIÓN ABIERTA Y A DISTANCIA",
        "TUTORES DE FORMACIÓN ABIERTA Y A DISTANCIA",
        "TUTORES DE FORMACIÓN A DISTANCIA",
        "TUTOR DE FORMACIÓN A DISTANCIA",
        "TUTORA DE FORMACIÓN A DISTANCIA",
        "TUTORAS DE FORMACIÓN A DISTANCIA",
      ],
      descripcion_cno__c: [
        {
          value: "2220",
          name: "PROFESORES DE FORMACION PROFESIONAL(MATERIAS ESPEC",
        },
        {
          value: "2251",
          name: "MAESTROS DE EDUCACION INFANTIL",
        },
        {
          value: "2252",
          name: "TECNICOS EN EDUCACION INFANTIL",
        },
        {
          value: "2311",
          name: "PROFESORES DE EDUCACION ESPECIAL",
        },
        {
          value: "2312",
          name: "TECNICOS EDUCADORES DE EDUCACION ESPECIAL",
        },
        {
          value: "2321",
          name: "ESPECIALISTAS EN METODOS DIDACTICOS Y PEDAGOGICOS",
        },
        {
          value: "2322",
          name: "PROFESORES DE ENSEﾑANZA NO REGLADA DE IDIOMAS",
        },
        {
          value: "2323",
          name: "PROFESORES DE ENSEﾑANZA NO REGLADA DE MUSICA Y DAN",
        },
        {
          value: "2324",
          name: "PROFESORES DE ENSEﾑANZA NO REGLADA DE ARTES",
        },
        {
          value: "2325",
          name: "INSTRUCTORES DE TECNOLOGIAS DE LA INFORMACION EN ENSEÑANZA NO REGLADA",
        },
        {
          value: "2326",
          name: "PROFESIONALES DE LA EDUCACION AMBIENTAL",
        },
        {
          value: "2329",
          name: "PROFESORES Y PROFESIONALES DE LA ENSEÑANZA NO CLASIFICADO BAJO OTROS EPIGRAFES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "PROFESORES DE ENSEÑANZA PRIMARIA Y SECUNDARIA",
      value: "2230",
      options: [
        "PROFESORES DE ENSEÑANZA SEGUNDARIA",
        "PROFESORA DE SECUNDARIA",
        "PROFESOR DE SECUNDARIA",
        "PROFESOR DE ENSEÑANZA SECUNDARIA",
        "PROFESORA DE ENSEÑANZA SECUNDARIA",
        "PROFE DE SECUNDARIA",
        "MAESTRO",
        "MAESTRA",
      ],
      descripcion_cno__c: [
        {
          value: "2230",
          name: "PROFESORES DE ENSEÑANZA SECUNDARIA",
        },
        {
          value: "2240",
          name: "PROFESORES DE ENSEﾑANZA PRIMARIA",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "FÍSICOS, QUÍMICOS, MATEMÁTICOS Y AFINES",
      value: "2411",
      descripcion_cno__c: [
        {
          value: "2411",
          name: "FISICOS Y ASTRONOMOS",
        },
        {
          value: "2412",
          name: "METEOROLOGOS",
        },
        {
          value: "2413",
          name: "QUIMICOS",
        },
        {
          value: "2414",
          name: "GEOLOGOS Y GEOFISICOS",
        },
        {
          value: "2415",
          name: "MATEMATICOS Y ACTUARIOS",
        },
        {
          value: "2416",
          name: "ESTADISTICOS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "PROFESIONALES EN CIENCIAS NATURALES",
      value: "2421",
      descripcion_cno__c: [
        {
          value: "2421",
          name: "BIOLOGOS, BOTANICOS, ZOOLOGOS Y AFINES",
        },
        {
          value: "2422",
          name: "INGENIEROS AGRONOMOS",
        },
        {
          value: "2423",
          name: "INGENIEROS DE MONTES",
        },
        {
          value: "2424",
          name: "INGENIEROS TECNICOS AGRICOLAS",
        },
        {
          value: "2425",
          name: "INGENIEROS TECNICOS FORESTALES Y DEL MEDIO NATURAL",
        },
        {
          value: "2426",
          name: "PROFESIONALES DE LA PROTECCION AMBIENTAL",
        },
        {
          value: "2427",
          name: "ENOLOGOS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "INGENIEROS (EXCEPTO INGENIEROS AGRÓNOMOS Y DE MONTES)",
      value: "2439",
      options: [
        "INGENIEROS (OTROS)",
        "INGENIERO (OTRO)",
        "INGENIERO NO CLASIFICADOS BAJO OTROS EPÍGRAFES",
        "INGENIEROS NO CLASIFICADOS BAJO OTROS EPÍGRAFES",
      ],
      descripcion_cno__c: [
        {
          value: "2431",
          name: "INGENIEROS INDUSTRIALES Y DE PRODUCCION",
        },
        {
          value: "2432",
          name: "INGENIEROS EN CONSTRUCCION Y OBRA CIVIL",
        },
        {
          value: "2433",
          name: "INGENIEROS MECANICOS",
        },
        {
          value: "2434",
          name: "INGENIEROS AERONAUTICOS",
        },
        {
          value: "2435",
          name: "INGENIEROS QUIMICOS",
        },
        {
          value: "2436",
          name: "INGENIEROS DE MINAS, METALURGICOS Y AFINES",
        },
        {
          value: "2437",
          name: "INGENIEROS AMBIENTALES",
        },
        {
          value: "2439",
          name: "INGENIEROS NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
        {
          value: "2441",
          name: "INGENIEROS EN ELECTRICIDAD",
        },
        {
          value: "2442",
          name: "INGENIEROS ELECTRONICOS",
        },
        {
          value: "2443",
          name: "INGENIEROS EN TELECOMUNICACIONES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "ARQUITECTOS, URBANISTAS E INGENIEROS GEÓGRAFOS",
      value: "2451",
      options: [
        "ARQUITECTOS (EXCEPTO PAISAJISTAS Y URBANISTAS)",
        "ARQUITECTOS",
        "ARQUITECTAS",
        "ARQUITECTO",
        "ARQUITECTA",
        "ARQUITECTO (EXCEPTO PAISAJISTA Y URBANISTA)",
        "ARQUITECTA (EXCEPTO PAISAJISTA Y URBANISTA)",
        "ARQUITECTAS (EXCEPTO PAISAJISTAS Y URBANISTAS)",
      ],
      descripcion_cno__c: [
        {
          value: "2451",
          name: "ARQUITECTOS(EXCEPTO ARQUITECTOS PAISAJISTAS Y URBANISTAS)",
        },
        {
          value: "2452",
          name: "ARQUITECTOS PAISAJISTAS",
        },
        {
          value: "2453",
          name: "URBANISTAS E INGENIEROS DE TRAFICO",
        },
        {
          value: "2454",
          name: "INGENIEROS GEOGRAFOS Y CARTOGRAFOS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "INGENIEROS Y AQRUITECTOS TÉCNICOS",
      value: "2469",
      descripcion_cno__c: [
        {
          value: "2461",
          name: "INGENIEROS TECNICOS INDUSTRIALES Y DE PRODUCCION",
        },
        {
          value: "2462",
          name: "INGENIEROS TECNICOS DE OBRAS PÚBLICAS",
        },
        {
          value: "2463",
          name: "INGENIEROS TECNICOS MECANICOS",
        },
        {
          value: "2464",
          name: "INGENIEROS TECNICOS AERONAUTICOS",
        },
        {
          value: "2465",
          name: "INGENIEROS TECNICOS QUIMICOS",
        },
        {
          value: "2466",
          name: "INGENIEROS TECNICOS DE MINAS, METALURGICOS Y AFINES",
        },
        {
          value: "2469",
          name: "INGENIEROS TECNICOS NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
        {
          value: "2471",
          name: "INGENIEROS TECNICOS EN ELECTRICIDAD",
        },
        {
          value: "2472",
          name: "INGENIEROS TECNICOS EN ELECTRONICA",
        },
        {
          value: "2473",
          name: "INGENIEROS TECNICOS EN TELECOMUNICACIONES",
        },
        {
          value: "2481",
          name: "ARQUITECTOS TECNICOS Y TECNICOS URBANISTAS",
        },
        {
          value: "2482",
          name: "DISEﾑADORES DE PRODUCTOS Y DE PRENDAS",
        },
        {
          value: "2483",
          name: "INGENIEROS TECNICOS EN TOPOGRAFIA",
        },
        {
          value: "2484",
          name: "DISEﾑADORES GRAFICOS Y MULTIMEDIA",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DEL DERECHO",
      name: "ABOGADOS",
      value: "2511",
      options: [
        "ABOGADOS",
        "ABOGACÍA",
        "ABOGADO",
        "ABOGADA",
        "ABOGADAS",
        "ABOGADOS DEL ESTADO",
        "ABOGADAS DEL ESTADO",
        "PROFESIONALES EN DERECHO",
        "MAGISTRADA",
        "MAGISTRADOS",
        "MAGISTRADAS",
      ],
      descripcion_cno__c: [
        {
          value: "2511",
          name: "ABOGADOS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DEL DERECHO",
      name: "FISCALES",
      value: "2512",
      descripcion_cno__c: [
        {
          value: "2512",
          name: "FISCALES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DEL DERECHO",
      name: "JUECES Y MAGRISTRADOS",
      value: "2513",
      descripcion_cno__c: [
        {
          value: "2513",
          name: "JUECES Y MAGRISTRADOS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DEL DERECHO",
      name: "NOTARIOS Y REGISTRADORES",
      value: "2591",
      descripcion_cno__c: [
        {
          value: "2591",
          name: "NOTARIOS Y REGISTRADORES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DEL DERECHO",
      name: "PROCURADORES",
      value: "2592",
      descripcion_cno__c: [
        {
          value: "2592",
          name: "PROCURADORES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DEL DERECHO",
      name: "PROFESIONALES DEL DERECHO NO CLASIFICADOS BAJO OTR",
      value: "2599",
      descripcion_cno__c: [
        {
          value: "2599",
          name: "PROFESIONALES DEL DERECHO NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "ESPECIALISTAS EN FINANZAS, ORGANIZACIÓN Y ADMINISTRACIÓN",
      value: "2613",
      descripcion_cno__c: [
        {
          value: "2611",
          name: "ESPECIALISTAS EN CONTABILIDAD",
        },
        {
          value: "2612",
          name: "ASESORES FINANCIEROS Y EN INVERSIONES",
        },
        {
          value: "2613",
          name: "ANALISTAS FINANCIEROS",
        },
        {
          value: "2621",
          name: "ANALISTAS DE GESTION Y ORGANIZACION",
        },
        {
          value: "2622",
          name: "ESPECIALISTAS EN ADMINISTRACION DE POLITICA DE EMP",
        },
        {
          value: "2623",
          name: "ESPECIALISTAS DE LA ADMINISTRACION PÚBLICA",
        },
        {
          value: "2624",
          name: "ESPECIALISTAS EN POLITICAS Y SERVICIOS DE PERSONAL",
        },
        {
          value: "2625",
          name: "ESPECIALISTAS EN FORMACION DE PERSONAL",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "OTROS PROFESIONALES DE LAS VENTAS, LA COMERCIALIZACIÓN, LA PUBLICIDAD, EL TURISMO Y LAS RELACIONES PÚBLICAS",
      value: "2651",
      options: [
        "PROFESIONALES DE PUBLICIDAD Y COMERCIALIZACION",
        "PROFESIONAL DE PUBLICIDAD Y COMERCIALIZACIÓN",
        "TÉCNICO EN ESTUDIOS DE MERCADO",
        "TÉCNICO EN ESTUDIOS DE MERCADO Y OPINIÓN PÚBLICA ",
        "TÉCNICA EN ESTUDIOS DE MERCADO",
        "TÉCNICA EN ESTUDIOS DE MERCADO Y OPINIÓN PÚBLICA",
        "TÉCNICOS EN ESTUDIOS DE OPINIÓN PÚBLICA",
        "TÉCNICO EN ESTUDIOS DE OPINIÓN PÚBLICA",
        "TÉCNICA EN ESTUDIOS DE OPINIÓN PÚBLICA",
        "TÉCNICAS EN ESTUDIOS DE OPINIÓN PÚBLICA",
        "TÉCNICSA EN ESTUDIOS DE MERCADO",
        "TÉCNICAS EN ESTUDIOS DE MERCADO Y OPINIÓN PÚBLICA",
        "TÉCNICO DE PUBLICIDAD",
        "TÉCNICOS DE PUBLICIDAD",
        "TÉCNICA DE PUBLICIDAD",
        "TÉCNICAS DE PUBLICIDAD",
        "TÉCNICOS DE PUBLICIDAD Y RR.PP",
        "TÉCNICO DE PUBLICIDAD Y RR.PP",
        "TÉCNICA DE PUBLICIDAD Y RR.PP",
        "TÉCNICAS DE PUBLICIDAD Y RR.PP",
        "TÉCNICOS DE PUBLICIDAD Y RELACIONES PÚBLICAS",
        "TÉCNICO DE PUBLICIDAD Y RELACIONES PÚBLICAS",
        "TÉCNICA DE PUBLICIDAD Y RELACIONES PÚBLICAS",
        "TÉCNICAS DE PUBLICIDAD Y RELACIONES PÚBLICAS",
        "TÉCNICO EN RELACIONES PÚBLICAS",
        "TÉCNICOS EN RELACIONES PÚBLICAS",
        "TÉCNICA EN RELACIONES PÚBLICAS",
        "TÉCNICAS EN RELACIONES PÚBLICAS",
        "TÉCNICOS MEDIOS EN PUBLICIDAD Y RELACIONES PÚBLICAS",
        "TÉCNICO MEDIOS EN PUBLICIDAD Y RELACIONES PÚBLICAS",
        "TÉCNICOS SUPERIORES EN INVESTIGACIÓN DE MERCADOS",
        "TÉCNICO SUPERIOR EN INVESTIGACIÓN DE MERCADOS",
        "TÉCNICAS SUPERIORES EN INVESTIGACIÓN DE MERCADOS",
        "TÉCNICA SUPERIORES EN INVESTIGACIÓN DE MERCADOS",
      ],
      descripcion_cno__c: [
        {
          value: "2630",
          name: "TECNICOS DE EMPRESAS Y ACTIVIDADES TURISTICAS",
        },
        {
          value: "2640",
          name: "PROFESIONALES DE VENTAS TECNICAS Y MEDICAS(EXCEPTO LAS TIC)",
        },
        {
          value: "2651",
          name: "PROFESIONALES DE LA PUBLICIDAD Y LA COMERCIALIZACION",
        },
        {
          value: "2652",
          name: "PROFESIONALES DE RELACIONES PÚBLICAS",
        },
        {
          value: "2653",
          name: "PROFESIONALES DE LA VENTA DE TECNOLOGIAS DE LA INFORMACION",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "ANALISTAS Y DISEÑADORES DE SOFTWARE Y MULTIMEDIA.ESPECIALISTAS EN BASES DE DATOS Y EN REDES INFORMÁTICAS",
      value: "2713",
      descripcion_cno__c: [
        {
          value: "2711",
          name: "ANALISTAS DE SISTEMAS",
        },
        {
          value: "2712",
          name: "ANALISTAS Y DISEﾑADORES DE SOFTWARE",
        },
        {
          value: "2713",
          name: "ANALISTAS, PROGRAMADORES Y DISEÑADORES WEB Y MULTIMEDIA",
        },
        {
          value: "2719",
          name: "ANALISTAS Y DISENADORES DE SOFTWARE Y MULTIMEDIA NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
        {
          value: "2721",
          name: "DISEﾑADORES Y ADMINISTRADORES DE BASE DE DATOS",
        },
        {
          value: "2722",
          name: "ADMINISTRADORES DE SISTEMAS Y REDES",
        },
        {
          value: "2723",
          name: "ANALISTAS DE REDES INFORMATICAS",
        },
        {
          value: "2729",
          name: "ESPECIALISTAS EN BASES DE DATOS Y EN REDES INFORMATICAS NO CLASIFICADOS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "ECONOMISTAS",
      value: "2810",
      options: [
        "ECONOMISTAS",
        "ECONOMISTA",
        "PROFESIONALES EN CIENCIAS SOCIALES",
        "PROFESIONAL EN CIENCIAS SOCIALES",
      ],
      descripcion_cno__c: [
        {
          value: "2810",
          name: "ECONOMISTAS",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "SOCIÓLOGOS, HISTORIADORES, PSICÓLOGOS Y OTROS PROFESIONALES EN CIENCIAS SOCIALES",
      value: "2821",
      descripcion_cno__c: [
        {
          value: "2821",
          name: "SOCIOLOGOS, GEOGRAFOS, ANTROPOLOGOS,ARQUEOLOGOS Y AFINES",
        },
        {
          value: "2822",
          name: "FILOSOFOS, HISTORIADORES Y PROFESIONALES EN CIENCIAS POLITICAS",
        },
        {
          value: "2823",
          name: "PSICOLOGOS",
        },
        {
          value: "2824",
          name: "PROFESIONALES DEL TRABAJO Y LA EDUCACION SOCIAL",
        },
        {
          value: "2825",
          name: "AGENTES DE IGUALDAD DE OPORTUNIDADES ENTRE MUJERES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "SACERDOTES DE LAS DISTINTAS RELIGIONES",
      value: "2830",
      descripcion_cno__c: [
        {
          value: "2830",
          name: "SACERDOTES DE LAS DISTINTAS RELIGIONES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "ARCHIVISTAS, BIBLIOTECARIOS, CONSERVADORES Y AFINES",
      value: "2911",
      options: ["DOCUMENTALISTA"],
      descripcion_cno__c: [
        {
          value: "2911",
          name: "ARCHIVISTAS Y CONSERVADORES DE MUSEOS",
        },
        {
          value: "2912",
          name: "BIBLIOTECARIOS, DOCUMENTALISTAS Y AFINES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "ESCRITORES, PERIODISTAS Y LINGÜISTAS",
      value: "2921",
      descripcion_cno__c: [
        {
          value: "2921",
          name: "ESCRITORES",
        },
        {
          value: "2922",
          name: "PERIODISTAS",
        },
        {
          value: "2923",
          name: "FILOLOGOS, INTERPRETES Y TRADUCTORES",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DE LA EDUCACIÓN, SALUD, CIENCIAS E INGENIERÍA",
      name: "ARTISTAS CREATIVOS E INTERPRETATIVOS",
      value: "2931",
      options: [
        "PINTOR ARTISTICO",
        "ESCULTOR",
        "ESCRITOR",
        "AUTOR MUSICAL",
        "CANTA AUTOR",
        "MUSICO",
        "CANTANTE",
        "CANTANTE DE ORQUESTA",
        "BAILARIN",
        "BAILAOR",
        "BAILARINA",
        "BAILAORA",
        "GUITARRISTA",
        "BAJISTA",
        "ACTRIZ",
        "ACTOR",
        "CINEASTA",
        "GUIONISTA",
        "ARTISTA DE CIRCO",
        "CANTANTE DE OPERA",
        "PINTORA ARTISTICA",
        "ESCULTORA",
        "MALABARISTA",
        "MAKEUP ARTIST",
      ],
      descripcion_cno__c: [
        {
          value: "2931",
          name: "ARTISTAS DE ARTES PLASTICAS Y VISUALES",
        },
        {
          value: "2932",
          name: "COMPOSITORES, MUSICOS Y CANTANTES",
        },
        {
          value: "2933",
          name: "COREOGRAFOS Y BAILARINES",
        },
        {
          value: "2934",
          name: "DIRECTORES DE CINE,DE TEATRO Y AFINES",
        },
        {
          value: "2935",
          name: "ACTORES",
        },
        {
          value: "2936",
          name: "LOCUTORES DE RADIO,TELEVISION Y OTROS PRESENTADORES",
        },
        {
          value: "2937",
          name: "PROFESIONALES DE ESPECTACULOS TAURINOS",
        },
        {
          value: "2939",
          name: "ARTISTAS CREATIVOS E INTERPRETATIVOS NO CLASIFICADOS BAJO OTROS",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "DELINEANTES Y DIBUJANTES TECNICOS",
      value: "3110",
      descripcion_cno__c: [
        {
          value: "3110",
          name: "DELINEANTES Y DIBUJANTES TECNICOS",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "TÉCNICOS DE LAS CIENCIAS FÍSICAS, QUÍMICAS, MEDIOAMBIENTALES Y DE LAS INGENIERÍAS",
      value: "3129",
      descripcion_cno__c: [
        {
          value: "3121",
          name: "TECNICOS DE CIENCIAS FISICAS Y QUIMICAS",
        },
        {
          value: "3122",
          name: "TECNICOS EN CONSTRUCCION",
        },
        {
          value: "3123",
          name: "TECNICOS EN ELECTRICIDAD",
        },
        {
          value: "3124",
          name: "TECNICOS EN ELECTRONICA(EXCEPTO ELECTROMEDICINA)",
        },
        {
          value: "3125",
          name: "TECNICOS EN ELECTRONICA,ESPECIALIDAD EN ELECTROMEDICINA",
        },
        {
          value: "3126",
          name: "TECNICOS EN MECANICA",
        },
        {
          value: "3127",
          name: "TECNICOS Y ANALISTAS DE LABORATORIO EN QUIMICA INDUSTRIAL",
        },
        {
          value: "3128",
          name: "TECNICOS EN METALURGIA Y MINAS",
        },
        {
          value: "3129",
          name: "OTROS TECNICOS DE LAS CIENCIAS FISICAS,QUIMICAS,MEDIOAMBIENTALES",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "TÉCNICOS EN CONTROL DE PROCESOS Y DE CALIDAD",
      value: "3139",
      descripcion_cno__c: [
        {
          value: "3131",
          name: "TECNICOS EN INSTALACIONES DE PRODUCCION DE ENERGIA",
        },
        {
          value: "3132",
          name: "TECNICOS EN INSTALACIONES DE TRATAMIENTO DE RESIDUOS,DE AGUAS Y OTROS OPERADORES EN PLANTA SIMILARES",
        },
        {
          value: "3133",
          name: "TECNICOS EN CONTROL DE INSTALACIONES DE PROCESAMIENTO DE PRODUCTOS QUIMICOS",
        },
        {
          value: "3134",
          name: "TECNICOS DE REFINERIAS DE PETROLEO Y GAS NATURAL",
        },
        {
          value: "3135",
          name: "TECNICOS EN CONTROL DE PROCESOS DE PRODUCCION DE METALES",
        },
        {
          value: "3139",
          name: "TECNICOS DE CONTROL DE PROCESOS NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "TÉCNICOS DE LAS CIENCIAS NATURALES Y PROFESIONALES AUXILIARES AFINES",
      value: "3141",
      descripcion_cno__c: [
        {
          value: "3141",
          name: "TECNICOS EN CIENCIAS BIOLOGICAS (EXCEPTO EN AREAS SANITARIAS)",
        },
        {
          value: "3142",
          name: "TECNICOS AGROPECUARIOS",
        },
        {
          value: "3143",
          name: "TECNICOS FORESTALES Y DEL MEDIO NATURAL",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "PROFESIONALES EN NAVEGACIÓN MARÍTIMA Y AERONÁUTICA",
      value: "3155",
      descripcion_cno__c: [
        {
          value: "3151",
          name: "JEFES Y OFICIALES DE MAQUINAS",
        },
        {
          value: "3152",
          name: "CAPITANES Y OFICIALES DE PUENTE",
        },
        {
          value: "3153",
          name: "PILOTOS DE AVIACION Y PROFESIONALES AFINES",
        },
        {
          value: "3154",
          name: "CONTROLADORES DE TRAFICO AEREO",
        },
        {
          value: "3155",
          name: "TECNICOS EN SEGURIDAD AERONAUTICA",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "TÉCNICOS EN CONTROL DE PROCESOS Y DE CALIDAD",
      value: "3139",
      descripcion_cno__c: [
        {
          value: "3160",
          name: "TECNICOS DE CONTROL DE CALIDAD DE LAS CIENCIAS FISICAS,QUIMICAS",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "SUPERVISORES EN INGENIERÍA DE MINAS, DE INDUSTRIAS MANUFACTURERAS Y DE LA CONSTRUCCIÓN",
      value: "3209",
      descripcion_cno__c: [
        {
          value: "3201",
          name: "SUPERVISORES EN INGENIERIA DE MINAS",
        },
        {
          value: "3202",
          name: "SUPERVISORES DE LA CONSTRUCCION",
        },
        {
          value: "3203",
          name: "SUPERVISORES DE INDUSTRIAS ALIMENTICIAS Y DEL TABACO",
        },
        {
          value: "3204",
          name: "SUPERVISORES DE INDUSTRIAS QUIMICA Y FARMACEUTICA",
        },
        {
          value: "3205",
          name: "SUPERVISORES DE INDUSTRIAS DE TRANSFORMACION DE PLASTICOS,CAUCHO Y RESINAS NATURALES",
        },
        {
          value: "3206",
          name: "SUPERVISORES DE INDUSTRIAS DE LA MADERA Y PASTERO",
        },
        {
          value: "3207",
          name: "SUPERVISORES DE LA PRODUCCION EN INDUSTRIAS DE ARTES GRAFICAS Y EN LA  FABRICAC",
        },
        {
          value: "3209",
          name: "SUPERVISORES DE OTRAS INDUSTRIAS MANUFACTURERAS",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "TÉCNICOS SANITARIOS DE LABORATORIO, PRUEBAS DIAGNÓSTICAS, PRÓTESIS Y OTROS TÉCNICOS SANITARIOS",
      value: "3329",
      options: [
        "TECNICOS DE LA SANIDAD (OTROS)",
        "TÉCNICOS DE LA SANIDAD NO CLASIFICADOS BAJO OTROS EPÍGRAFES",
        "TÉCNICO DE LA SANIDAD NO CLASIFICADOS BAJO OTROS EPÍGRAFES",
        "TÉCNICA DE LA SANIDAD NO CLASIFICADOS BAJO OTROS EPÍGRAFES",
        "TÉCNICAS DE LA SANIDAD NO CLASIFICADOS BAJO OTROS EPÍGRAFES",
        "EDUCADORES PARA LA SALUD",
        "EDUCADOR PARA LA SALUD",
        "EDUCADORAS PARA LA SALUD",
        "EDUCADORA PARA LA SALUD",
        "QUIROPRÁCTICOS",
        "QUIROPRÁCTICAS",
        "QUIROPRÁCTICA",
        "QUIROPRÁCTICO",
        "TÉCNICOS EN PREVENCIÓN COMUNITARIA DE TOXICOMANÍAS",
        "TÉCNICO EN PREVENCIÓN COMUNITARIA DE TOXICOMANÍAS",
        "TÉCNICA EN PREVENCIÓN COMUNITARIA DE TOXICOMANÍAS",
        "TÉCNICAS EN PREVENCIÓN COMUNITARIA DE TOXICOMANÍAS",
        "PERSONAL DE LABORATORIO",
        "RADIOLOGO",
      ],
      descripcion_cno__c: [
        {
          value: "3311",
          name: "TECNICOS EN RADIOTERAPIA",
        },
        {
          value: "3312",
          name: "TECNICOS EN IMAGEN PARA EL DIAGNOSTICO",
        },
        {
          value: "3313",
          name: "TECNICOS EN ANATOMIA PATOLOGICA Y CITOLOGIA",
        },
        {
          value: "3314",
          name: "TECNICOS EN LABORATORIO DE DIAGNOSTICO CLINICO",
        },
        {
          value: "3315",
          name: "TECNICOS EN ORTOPROTESIS",
        },
        {
          value: "3316",
          name: "TECNICOS EN PROTESIS DENTALES",
        },
        {
          value: "3317",
          name: "TECNICOS EN AUDIOPROTESIS",
        },
        {
          value: "3321",
          name: "TECNICOS SUPERIORES EN HIGIENE BUCODENTAL",
        },
        {
          value: "3322",
          name: "TECNICOS SUPERIORES EN DOCUMENTACION SANITARIA",
        },
        {
          value: "3323",
          name: "TECNICOS SUPERIORES EN DIETETICA",
        },
        {
          value: "3324",
          name: "TECNICOS EN OPTOMETRIA",
        },
        {
          value: "3325",
          name: "AYUDANTES FISIOTERAPEUTAS",
        },
        {
          value: "3326",
          name: "TECNICOS EN PREVENCION DE RIESGOS LABORALES Y SALU",
        },
        {
          value: "3327",
          name: "AYUDANTES DE VETERINARIA",
        },
        {
          value: "3329",
          name: "TECNICOS DE LA SANIDAD NO CLASIFICADOS BAJO OTROS",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "PROFESIONALES DE LAS TERAPIAS ALTERNATIVAS",
      value: "3339",
      descripcion_cno__c: [
        {
          value: "3331",
          name: "PROFESIONALES DE LA ACUPUNTURA,LA NATUROPATIA,LA H",
        },
        {
          value: "3339",
          name: "OTROS PROFESIONALES DE LAS TERAPIAS ALTERNATIVAS",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "PROFESIONALES DE APOYO EN FINANZAS Y MATEMÁTICAS",
      value: "3401",
      descripcion_cno__c: [
        {
          value: "3401",
          name: "PROFESIONALES DE APOYO E INTERMEDIARIOS DE CAMBIO,",
        },
        {
          value: "3402",
          name: "COMERCIALES DE PRESTAMOS Y CREDITOS",
        },
        {
          value: "3403",
          name: "TENEDORES DE LIBROS",
        },
        {
          value: "3404",
          name: "PROFESIONALES DE APOYO EN SERVICIOS ESTADISTICOS,M",
        },
      ],
    },
    {
      profesion: "PROFESIONALES DEL DERECHO",
      name: "TASADORES",
      value: "3405",
      descripcion_cno__c: [
        {
          value: "3405",
          name: "TASADORES",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "AGENTES Y REPRESENTANTES COMERCIALES",
      value: "3510",
      options: [
        "AGENTES Y REPRESENTANTES COMERCIALES",
        "COMERCIALES",
        "COMERCIAL",
        "VENDEDOR",
        "VENDEDORA",
        "AGENTES Y REPRESENTANTES COMERCIALES",
        "AGENTE Y REPRESENTANTE COMERCIAL",
        "REPRESENTANTE COMERCIAL",
        "AGENTE COMERCIAL",
        "REPRESENTANTES COMERCIALES",
        "AGENTES COMERCIALES",
        "DELEGADO COMERCIAL",
        "DELEGADOS COMERCIALES",
        "REPRESENTANTE DE COMERCIO",
        "REPRESENTANTES DE COMERCIO",
        "REPRESENTANTES DE COMERCIOS",
      ],
      descripcion_cno__c: [
        {
          value: "3510",
          name: "AGENTES Y REPRESENTANTES COMERCIALES",
        },
        {
          value: "3522",
          name: "AGENTES DE COMPRAS",
        },
        {
          value: "3523",
          name: "CONSIGNATARIOS",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "MEDIADORES Y AGENTES DE SEGUROS",
      value: "3521",
      options: [
        "MEDIADORES Y AGENTES DE SEGUROS",
        "MEDIADOR Y AGENTE DE SEGUROS",
        "AGENTE DE SEGUROS",
        "AGENTES DE SEGUROS",
        "COMERCIAL DE SEGUROS",
        "COMERCIALES DE SEGUROS",
        "TÉCNICOS EN SEGUROS",
        "TÉCNICO EN SEGUROS",
        "TÉCNICA EN SEGUROS",
        "TÉCNICAS EN SEGUROS",
      ],
      descripcion_cno__c: [
        {
          value: "3521",
          name: "MEDIADORES Y AGENTES DE SEGUROS",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "REPRESENTANTES DE ADUANAS",
      value: "3531",
      descripcion_cno__c: [
        {
          value: "3531",
          name: "REPRESENTANTES DE ADUANAS",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "AGENTES Y ADMINISTRADORES DE LA PROPIEDAD INMOBILIARIA",
      value: "3534",
      descripcion_cno__c: [
        {
          value: "3532",
          name: "ORGANIZADORES DE CONFERENCIAS Y EVENTOS",
        },
        {
          value: "3534",
          name: "AGENTES Y ADMINISTRADORES DE LA PROPIEDAD INMOBILIARIA",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "AGENTES INMOBILIARIOS Y OTROS AGENTES",
      value: "3534",
      descripcion_cno__c: [
        {
          value: "3533",
          name: "AGENTES O INTERMEDIARIOS EN LA CONTRATACION DE LA MANO DE OBRA(EXCEPTO REPRESENTANTES DE ESPECTACULOS)",
        },
  
        {
          value: "3535",
          name: "PORTAVOCES Y AGENTES DE RELACIONES PÚBLICAS",
        },
        {
          value: "3539",
          name: "REPRESENTANTES ARTISTICOS Y DEPORTIVOS YOTROS AGENTES DE SERVICIOS COMERCIALES  NO CLASIFICADOS",
        },
      ],
    },
    {
      profesion: "DIRECTORES, GERENTES Y/O PERSONAS CON RESPONSABILIDAD PÚBLICA",
      name: "AGENTES Y ADMINISTRADORES DE LA PROPIEDAD INMOBILIARIA",
      value: "3534",
      descripcion_cno__c: [{}],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "ASISTENTES ADMINISTRATIVOS Y ESPECIALIZADOS",
      value: "3611",
      descripcion_cno__c: [
        {
          value: "3611",
          name: "SECRETARIOS DE CENTROS MEDICOS O CLINICAS",
        },
        {
          value: "3612",
          name: "ASISTENTES JURIDICO-LEGALES",
        },
        {
          value: "3613",
          name: "ASISTENTES DE DIRECCION Y ADMININSTRATIVOS",
        },
        {
          value: "3614",
          name: "ASISTENTES DE DIRECCION Y ADMININSTRATIVOS",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "AGENTES DE ADUANAS, TRIBUTOS Y AFINES QUE TRABAJAN EN TAREAS PROPIAS DE LA ADMINISTRACIÓN PÚBLICA",
      value: "3621",
      descripcion_cno__c: [
        {
          value: "3621",
          name: "PROFESIONALES DE APOYO DE LA ADMINISTRACION PÚBLICA DE TRIBUTOS",
        },
        {
          value: "3622",
          name: "PROFESIONALES DE APOYO DE LA ADMINISTRACION PÚBLICA DE SERVICIOS SOCIALES",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "PROFESIONALES DE APOYO DE LA ADMINISTRACION PUBLICA DE SERVICIOS DE EXPEDICION DE LICENCIAS",
      value: "3623",
      descripcion_cno__c: [
        {
          value: "3623",
          name: "PROFESIONALES DE APOYO DE LA ADMINISTRACION PUBLICA DE SERVICIOS DE EXPEDICION DE LICENCIAS",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "OTROS PROFESIONALES DE APOYO DE LA ADMINISTRACION PUBLICA PARA TAREAS DE INSPECCION Y CONTROL Y TAREAS SIMILARES",
      value: "3629",
      options: [
        "OTROS PROFESIONALES APOYO ADMON PÚBLICA INSPECC.",
        "OTROS PROFESIONALES DE APOYO DE LA ADMINISTRACIÓN PÚBLICA DE SERVICIOS SOCIALES",
        "OTROS PROFESIONALES DE APOYO DE SERVICIOS SOCIALES",
      ],
      descripcion_cno__c: [
        {
          value: "3629",
          name: "OTROS PROFESIONALES DE APOYO DE LA ADMINISTRACION",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "TÉCNICOS DE LAS FUERZAS Y CUERPOS DE SEGURIDAD",
      value: "3631",
      descripcion_cno__c: [
        {
          value: "3631",
          name: "TECNICOS DE LA POLICIA NACIONAL,AUTONOMICA Y LOCAL",
        },
        {
          value: "3632",
          name: "SUBOFICIALES DE LA GUARDIA CIVIL",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "PROFESIONALES DE APOYO DE SERVICIOS JURÍDICOS Y SOCIALES",
      value: "3711",
      descripcion_cno__c: [
        {
          value: "3711",
          name: "PROFESIONALES DE APOYO DE SERVICIOS JURIDICOS Y SERVICIOS SIMILARES",
        },
        {
          value: "3712",
          name: "DETECTIVES PRIVADOS",
        },
        {
          value: "3713",
          name: "PROFESIONALES DE APOYO AL TRABAJO Y A LA EDUCACION",
        },
        {
          value: "3714",
          name: "PROMOTORES DE IGUALDAD DE OPORTUNIDADES ENTRE MUJERES Y HOMBRES",
        },
        {
          value: "3715",
          name: "ANIMADORES COMUNITARIOS",
        },
        {
          value: "3716",
          name: "AUXILIARES LAICOS DE LAS RELIGIONES",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "PROFESIONALES DE APOYO DE SERVICIOS JURÍDICOS Y SOCIALES",
      value: "3711",
      descripcion_cno__c: [{}],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "ATLETAS Y DEPORTISTAS",
      value: "3721",
      descripcion_cno__c: [
        {
          value: "3721",
          name: "ATLETAS Y DEPORTISTAS",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "ENTRENADORES, INSTRUCTORES DE ACTIVIDADES DEPORTIVAS; MONITORES DE ACTIVIDADES RECREATIVAS",
      value: "3722",
      descripcion_cno__c: [
        {
          value: "3722",
          name: "ENTRENADORES Y ARBITROS DE ACTIVIDADES DEPORTIVAS",
        },
        {
          value: "3723",
          name: "INSTRUCTORES DE ACTIVIDADES DEPORTIVAS",
        },
        {
          value: "3724",
          name: "MONITORES DE ACTIVIDADES RECREATIVAS Y DE ENTRETEN",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "TÉCNICOS Y PROFESIONALES DE APOYO DE ACTIVIDADES CULTURALES, ARTÍSTICAS Y CULINARIAS",
      value: "3739",
      descripcion_cno__c: [
        {
          value: "3731",
          name: "FOTOGRAFOS",
        },
        {
          value: "3732",
          name: "DISEﾑADORES Y DECORADORES DE INTERIOR",
        },
        {
          value: "3733",
          name: "TECNICOS EN GALERIAS DE ARTE,MUSEOS Y BIBLIOTECAS",
        },
        {
          value: "3734",
          name: "CHEFS",
        },
        {
          value: "3739",
          name: "OTROS TECNICOS Y PROFESIONALES DE APOYO DE ACTIVID",
        },
      ],
    },
    {
      profesion: "TÉCNICOS Y PROFESIONALES DE APOYO",
      name: "TÉCNICOS EN OPERACIONES DE TECNOLOGÍAS DE LA INFORMACIÓN, PROGRAMADORES Y ASISTENCIA AL USUARIO",
      value: "3811",
      descripcion_cno__c: [
        {
          value: "3811",
          name: "TECNICOS EN OPERACIONES DE SISTEMAS INFORMATICOS",
        },
        {
          value: "3812",
          name: "TECNICOS EN ASISTENCIA AL USUARIO DE TECNOLOGIAS DE LA INFORMACION",
        },
        {
          value: "3813",
          name: "TECNICOS EN REDES",
        },
        {
          value: "3814",
          name: "TECNICOS DE LA WEB",
        },
        {
          value: "3820",
          name: "PROGRAMADORES INFORMATICOS",
        },
        {
          value: "3831",
          name: "TECNICOS DE GRABACION AUDIOVISUAL",
        },
        {
          value: "3832",
          name: "TECNICOS DE RADIODIFUSION",
        },
        {
          value: "3833",
          name: "TECNICOS DE INGENIERIA DE LAS TELECOMUNICACIONES",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "EMPLEADOS EN SERVICIOS CONTABLES, FINANCIEROS, Y DE SERVICIOS DE APOYO A LA PRODUCCIÓN Y AL TRANSPORTE",
      value: "4113",
      options: [
        "EMPLEADOS OFICINA SERV. ESTAD., FINANC., BANCARIOS",
        "EMPLEADAS OFICINA SERV. ESTAD., FINANC., BANCARIOS",
        "EMPLEADOS DE OFICINA DE SERVICIOS ESTADÍSTICOS, FINANCIEROS Y BANCARIOS",
        "EMPLEADOS ADMINISTRATIVOS DE ENTIDADES FINANCIERAS",
        "EMPLEADOS ADMINISTRATIVOS DE ESTADÍSTICA",
        "EMPLEADOS ADMINISTRATIVOS DE SEGUROS",
        "TÉCNICOS ADMINISTRATIVOS DE SEGUROS",
        "EMPLEADOS DE GESTIÓN FINANCIERA DE EMPRESAS",
        "EMPLEADO DE OFICINA DE SERVICIOS ESTADÍSTICOS, FINANCIEROS Y BANCARIOS",
        "EMPLEADO ADMINISTRATIVO DE ENTIDADES FINANCIERAS",
        "EMPLEADO ADMINISTRATIVO DE ESTADÍSTICA",
        "EMPLEADO ADMINISTRATIVO DE SEGUROS",
        "TÉCNICO ADMINISTRATIVO DE SEGUROS",
        "EMPLEADO DE GESTIÓN FINANCIERA DE EMPRESAS",
        "EMPLEADA ADMINISTRATIVA DE ENTIDADES FINANCIERAS",
        "EMPLEADA ADMINISTRATIVA DE ESTADÍSTICA",
        "EMPLEADA ADMINISTRATIVA DE SEGUROS",
        "TÉCNICA ADMINISTRATIVA DE SEGUROS",
        "EMPLEADA DE GESTIÓN FINANCIERA DE EMPRESAS",
        "EMPLEADO DE SERVICIOS CENTRALES",
      ],
      descripcion_cno__c: [
        {
          value: "4111",
          name: "EMPLEADOS DE CONTABILIDAD",
        },
        {
          value: "4112",
          name: "EMPLEADOS DE CONTROL DE PERSONAL Y NOMINAS",
        },
        {
          value: "4113",
          name: "EMPLEADOS DE OFICINAS DE SERVICIOS ESTADISTICOS,FINANCIEROS Y BANCARIOS",
        },
        {
          value: "4121",
          name: "EMPLEADOS DE CONTROL DE ABASTECIMIENTO E INVENTARIO",
        },
        {
          value: "4122",
          name: "EMPLEADOS DE OFICINA DE SERVICIOS DE APOYO A LA PRODUCCION",
        },
        {
          value: "4123",
          name: "EMPLEADOS DE LOGISTICA Y TRANSPORTE DE PASAJEROS Y MERCANCIAS",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "EMPLEADOS DE BIBLIOTECAS, SERVICIOS DE CORREOS Y AFINES",
      value: "4210",
      descripcion_cno__c: [
        {
          value: "4210",
          name: "EMPLEADOS DE BIBLIOTECAS Y ARCHIVOS",
        },
        {
          value: "4221",
          name: "EMPLEADOS DE SERVICIOS DE CORREOS(EXCEPTO EMPLEADOS DE MOSTRADOR)",
        },
        {
          value: "4222",
          name: "CODIFICADORES Y CORRECTORES DE IMPRENTA",
        },
        {
          value: "4223",
          name: "EMPLEADOS DE SERVICIO DE PERSONAL",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "OTROS EMPLEADOS ADMINISTRATIVOS SIN TAREAS DE ATENCIÓN AL PÚBLICO",
      value: "4309",
      options: [
        "EMPLEADOS ADMINISTRATIVOS SIN TAREAS ATT.PÚBLICO (OTROS)",
        "ADMINISTRATIVOS",
        "ADMINISTRATIVA ADMINISTRATIVO",
        "GESTIÓN ADMINISTRATIVA",
        "EMPLEADO ADMINISTRATIVO SIN TAREAS ATT.PÚBLICO (OTROS)",
        "EMPLEADA ADMINISTRATIVA SIN TAREAS ATT.PÚBLICO (OTROS)",
      ],
      descripcion_cno__c: [
        {
          value: "4301",
          name: "GRABADORES DE DATOS",
        },
        {
          value: "4309",
          name: "EMPLEADOS ADMINISTRATIVOS SIN TAREAS DE ATENCION AL PÚBLICO",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "EMPLEADOS DE AGENCIAS DE VIAJES, RECEPCIONISTAS Y TELEFONISTAS; EMPLEADOS DE VENTANILLA, AFINES (EXCEPTO TAQUILLEROS) Y OTROS EMPLEADOS ADMINISTRATIVOS CON TAREAS DE ATENCIÓN AL PÚBLICO",
      value: "4500",
      options: [
        "EMPLEADOS ADMINISTRATIVOS CON TAREAS ATT.PÚBLICO (OTROS)",
        "ADMINISTRATIVO ATENCIÓN CLIENTES",
        "ADMINISTRATIVA ATENCIÓN CLIENTES",
        "ADMINISTRATIVO ATENCIÓN AL PÚBLICO",
        "ATENCIÓN AL CLIENTE",
        "ATENCIÓN AL CONSUMIDOR",
        "ATENCIÓN AL USUARIO",
        "ADMINISTRATIVO DE ATENCIÓN AL CONSUMIDOR",
        "ADMINISTRATIVO DE ATENCIÓN AL USUARIO",
        "ADMINISTRATIVO DE ATENCIÓN AL CLIENTE",
        "EMPLEADO DEL ÁREA DE ATENCIÓN AL CLIENTE",
        "EMPLEADA DEL ÁREA DE ATENCIÓN AL CLIENTE",
      ],
      descripcion_cno__c: [
        {
          value: "4411",
          name: "EMPLEADOS DE INFORMACION AL USUARIO",
        },
        {
          value: "4412",
          name: "RECEPCIONISTAS(EXCEPTO DE HOTELES)",
        },
        {
          value: "4421",
          name: "EMPLEADOS DE AGENCIAS DE VIAJES",
        },
        {
          value: "4422",
          name: "RECEPCIONISTAS DE HOTELES",
        },
        {
          value: "4423",
          name: "TELEFONISTAS",
        },
        {
          value: "4424",
          name: "TELEOPERADORES",
        },
        {
          value: "4430",
          name: "AGENTES DE ENCUESTAS",
        },
        {
          value: "4441",
          name: "CAJEROS DE BANCOS Y AFINES",
        },
        {
          value: "4442",
          name: "EMPLEADOS DE VENTA DE APUESTAS",
        },
        {
          value: "4445",
          name: "COBRADORES DE FACTURAS,DEUDAS Y EMPLEADOS AFINES",
        },
        {
          value: "4446",
          name: "EMPLEADOS DE MOSTRADOR DE CORREOS",
        },
        {
          value: "4500",
          name: "EMPLEADOS ADMINISTRATIVOS CON TAREAS DE ATENCION AL PÚBLICO",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "EMPLEADOS DE SALA DE JUEGOS Y AFINES",
      value: "4443",
      descripcion_cno__c: [
        {
          value: "4443",
          name: "EMPLEADOS DE SALA DE JUEGOS Y AFINES",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "EMPLEADOS DE CASAS DE EMPEÑO Y DE PRESTAMOS",
      value: "4444",
      descripcion_cno__c: [
        {
          value: "4444",
          name: "EMPLEADOS DE CASAS DE EMPEÑO Y DE PRESTAMOS",
        },
      ],
    },
    {
      profesion: "EMPLEADOS EN DEPARTAMENTOS CENTRALES Y/O ATENCION AL PÚBLICO",
      name: "VENDEDORES A DOMICILIO",
      value: "5491",
      descripcion_cno__c: [
        {
          value: "5491",
          name: "VENDEDORES A DOMICILIO",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE HOSTELERÍA,RESTAURACIÓN, COMERCIO",
      name: "CAMAREROS Y COCINEROS PROPIETARIOS",
      value: "5000",
      descripcion_cno__c: [
        {
          value: "5000",
          name: "CAMAREROS Y COCINEROS PROPIETARIOS",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE HOSTELERÍA,RESTAURACIÓN, COMERCIO",
      name: "COCINEROS Y CAMAREROS ASALIARIADOS",
      value: "5110",
      options: [
        "COCINEROS ASALARIADOS",
        "COCINERO ASALARIADO",
        "COCINERAS ASALARIADAS",
        "COCINEROS DE BARCO",
        "COCINERO DE BARCO",
        "COCINERA DE BARCO",
        "COCINERAS DE BARCO",
        "COCINEROS EN GENERAL",
        "COCINEROS",
        "COCINERO",
        "COCINERA",
        "COCINERAS EN GENERAL",
        "COCINERA EN GENERAL",
        "PLANCHISTAS (CAFETERÍA O RESTAURACIÓN)",
        "PLANCHISTA (CAFETERÍA O RESTAURACIÓN)",
        "PREPARADORES DE ALIMENTOS INFANTILES ",
        "PREPARADOR DE ALIMENTOS INFANTILES",
        "PREPARADORAS DE ALIMENTOS INFANTILES",
        "PREPARADORA DE ALIMENTOS INFANTILES",
        "PREPARADORES DE CATERING",
        "PREPARADOR DE CATERING",
        "PREPARADORAS DE CATERING",
        "PREPARADORA DE CATERING",
        "PREPARADORES DE PRECOCINADOS Y COCINADOS",
        "PREPARADORAS DE PRECOCINADOS Y COCINADOS",
        "PREPARADORA DE PRECOCINADOS Y COCINADOS",
        "PREPARADOR DE PRECOCINADOS Y COCINADOS",
        "REPOSTERO - PASTELERO (HOSTELERÍA)",
        "REPOSTERA - PASTELERA (HOSTELERÍA)",
        "REPOSTERO",
        "REPOSTERA",
        "PASTELERO",
        "PASTELERA",
        "SUPERVISORES DE CATERING",
        "SUPERVISORA DE CATERING",
        "SUPERVISOR DE CATERING",
      ],
      descripcion_cno__c: [
        {
          value: "5110",
          name: "COCINEROS ASALARIADOS",
        },
        {
          value: "5120",
          name: "CAMAREROS ASALARIADOS",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE HOSTELERÍA,RESTAURACIÓN, COMERCIO",
      name: "VENDEDORES EN TIENDAS Y ALMACENES",
      value: "5220",
      options: [
        "VENDEDORES EN TIENDAS Y ALMACENES",
        "ATT AL PÚBLICO",
        "DEPENDIENTE",
        "DEPENDIENTE EN TIENDA",
        "DEPENDIENTE DE ARTÍCULOS DE DEPORTE, CAZA Y PESCA",
        "DEPENDIENTE DE ARTÍCULOS DE FERRETERÍA",
        "FERRETERO",
        "FERRETERA",
        "DEPENDIENTE DE ARTÍCULOS DE FOTOGRAFÍA, CINE Y VÍDEO",
        "DEPENDIENTE DE ARTÍCULOS DE REGALO",
        "DEPENDIENTE DE CALZADO Y ARTÍCULOS DE PIEL",
        "PELETERO",
        "PELETERA",
        "DEPENDIENTE DE COMERCIO",
        "DEPENDIENTE DE COMPONENTES ELECTRÓNICOS Y/O INFORMÁTICA",
        "DEPENDIENTE DE ELECTRODOMÉSTICOS",
        "DEPENDIENTE DE ESTANCO",
        "DEPENDIENTE DE FARMACIA",
        "DEPENDIENTES DE FARMACIA",
        "DEPENDIENTE DE FLORISTERÍA",
        "FLORISTA",
        "FLORISTERO",
        "FLORISTERA",
        "FLORISTAS",
        "DEPENDIENTE DE GRANDES SUPERFICIES",
        "DEPENDIENTE DE HERBORISTERÍA",
        "DEPENDIENTE DE INSTRUMENTOS MUSICALES",
        "DEPENDIENTE DE JUGUETERÍA",
        "JUGUETERO",
        "JUGUETERA",
        "DEPENDIENTE DE LIBRERÍA Y PAPELERÍA",
        "LIBRERO",
        "LIBRERA",
        "DEPENDIENTE DE MATERIAL CLÍNICO, ORTOPÉDICO, QUIRÚRJICO",
        "DEPENDIENTE DE ORTOPEDIA",
        "DEPENDIENTE DE INSTRUMENTOS DE PRECISIÓN",
        "DEPENDIENTE DE MUEBLES",
        "DEPENDIENTE DE ARTÍCULOS DE DECORACIÓN",
        "DEPENDIENTE DE ARTÍCULOS DE COLECCIONISTA",
        "DEPENDIENTE DE NUMISMÁTICA",
        "DEPENDIENTE DE FILATELIA",
        "DEPENDIENTE DE PERFUMERÍA Y DROGUERÍA",
        "DEPENDIENTE DE RECAMBIOS DE COCHE",
        "DEPENDIENTE DE RELOJERÍA",
        "DEPENDIENTE DE JOYERÍA",
        "JOYERO",
        "JOYERA",
        "RELOJERO",
        "RELOJERA",
        "DEPENDIENTE DE REPRODUCCIÓN DE DOCUMENTOS",
        "DEPENDIENTE DE REPROGRAFÍA",
        "DEPENDIENTES DE FOTOCOPIAS",
        "REPROGRAFÍA",
        "DEPENDIENTE DE ROPA DE HOGAR",
        "DEPENDIENTE DE TEJIDOS Y ROPA DE VESTIR",
        "DEPENDIENTE DE TIENDA DE ROPA",
        "ESCAPARATISTA",
        "VISUAL MERCHANDISING",
        "CAJERO",
        "CAJERA",
        "CAJERO SUPERMERCADO",
        "CAJERA SUPERMERCADO",
        "TENDERO",
        "TENDERA",
      ],
      descripcion_cno__c: [
        {
          value: "5210",
          name: "JEFES DE SECCION DE TIENDAS Y ALMACENES",
        },
        {
          value: "5220",
          name: "VENDEDORES EN TIENDAS Y ALMACENES",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE HOSTELERÍA,RESTAURACIÓN, COMERCIO",
      name: "VENDEDORES (EXCEPTO EN TIENDAS Y ALMACENES)",
      value: "5499",
      descripcion_cno__c: [
        {
          value: "5300",
          name: "COMERCIANTES PROPIETARIOS DE TIENDAS",
        },
        {
          value: "5411",
          name: "VENDEDORES EN QUIOSCOS",
        },
        {
          value: "5412",
          name: "VENDEDORES EN MERCADOS OCASIONALES Y MERCADILLOS",
        },
        {
          value: "5420",
          name: "OPERADORES DE TELEMARKETING",
        },
        {
          value: "5430",
          name: "EXPENDEDORES DE GASOLINERAS",
        },
        {
          value: "5492",
          name: "PROMOTORES DE VENTA",
        },
        {
          value: "5493",
          name: "MODELOS DE MODA,ARTE Y PUBLICIDAD",
        },
        {
          value: "5499",
          name: "VENDEDORES NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE HOSTELERÍA,RESTAURACIÓN, COMERCIO",
      name: "CAJEROS Y TAQUILLEROS(EXCEPTO BANCOS)",
      value: "5500",
      descripcion_cno__c: [
        {
          value: "5500",
          name: "CAJEROS Y TAQUILLEROS(EXCEPTO BANCOS)",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "AUXILIARES DE ENFERMERÍA, FARMACIAS, EMERGENCIAS SANITARIAS Y OTROS",
      value: "5629",
      descripcion_cno__c: [
        {
          value: "5611",
          name: "AUXILIARES DE ENFERMERIA HOSPITALARIA",
        },
        {
          value: "5612",
          name: "AUXILIARES DE ENFERMERIA DE ATENCION PRIMARIA",
        },
        {
          value: "5621",
          name: "TECNICOS AUXILIARES DE FARMACIA",
        },
        {
          value: "5622",
          name: "TECNICOS DE EMERGENCIAS SANITARIAS",
        },
        {
          value: "5629",
          name: "TRABAJADORES DE LOS CUIDADOS A LAS PERSONAS EN SERVICIOS DE SALUD",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "TRABAJADORES DE LOS CUIDADOS PERSONALES A DOMICILIO Y CUIDADORES DE NIÑOS",
      value: "5710",
      descripcion_cno__c: [
        {
          value: "5710",
          name: "TRABAJADORES DE LOS CUIDADOS PERSONALES A DOMICILIO",
        },
        {
          value: "5721",
          name: "CUIDADORES DE NIÑOS EN GUARDERIAS Y CENTROS EDUCATIVOS",
        },
        {
          value: "5722",
          name: "CUIDADORES DE NIﾑOS EN DOMICILIOS",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "PELUQUEROS Y ESPECIALISTAS EN TRATAMIENTOS DE ESTÉTICA, BIENESTAR Y AFINES",
      value: "5812",
      options: [
        "ESTETICISTA",
        "ESTETICIEN",
        "ESTILISTA",
        "ASESOR DE BELLEZA",
        "ASESORA DE BELLEZA",
        "MASAJISTA NO TERAPEUTICO",
        "ESPECIALISTA EN TRATAMIENTOS CAPILARES",
        "ESPECIALISTA EN TRATAMIENTOS CORPORALES",
      ],
      descripcion_cno__c: [
        {
          value: "5811",
          name: "PELUQUEROS",
        },
        {
          value: "5812",
          name: "ESPECIALISTAS EN TRATAMIENTO DE ESTETICA,BIENESTAR",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "TRABAJADORES QUE ATIENDEN A VIAJEROS, GUÍAS TURÍSTICOS Y AFINES",
      value: "5821",
      descripcion_cno__c: [
        {
          value: "5821",
          name: "AUXILIARES DE VUELO Y CAMAREROS DE AVION,BARCO Y TREN",
        },
        {
          value: "5822",
          name: "REVISORES Y COBRADORES DE TRANSPORTE TERRESTRE",
        },
        {
          value: "5823",
          name: "ACOMPAﾑANTES TURISTICOS",
        },
        {
          value: "5824",
          name: "AZAFATOS DE TIERRA",
        },
        {
          value: "5825",
          name: "GUIAS DE TURISMO",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "SUPERVISORES DE MANTENIMIENTO Y LIMPIEZA DE EDIFICIOS, CONSERJES Y MAYORDOMOS DOMÉSTICOS",
      value: "5831",
      descripcion_cno__c: [
        {
          value: "5831",
          name: "SUPERVISORES DE MANTENIMIENTO Y LIMPIEZA EN OFICIN",
        },
        {
          value: "5832",
          name: "MAYORDOMOS DE SERVICIO DOMESTICO",
        },
        {
          value: "5833",
          name: "CONSERJES DE EDIFICIOS",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "TRABAJADORES PROPIETARIOS DE PEQUEÑOS ALOJAMIENTOS",
      value: "5840",
      descripcion_cno__c: [
        {
          value: "5840",
          name: "TRABAJADORES PROPIETARIOS DE PEQUEÑOS ALOJAMIENTOS",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "OTROS TRABAJADORES DE SERVICIOS PERSONALES",
      value: "5899",
      descripcion_cno__c: [
        {
          value: "5891",
          name: "ASISTENTES PERSONALES O PERSONAS DE COMPAﾑIA",
        },
        {
          value: "5892",
          name: "EMPLEADOS DE POMPAS FUNEBRES Y EMBALSAMADORES",
        },
        {
          value: "5893",
          name: "CUIDADORES DE ANIMALES Y ADIESTRADORES",
        },
        {
          value: "5894",
          name: "INSTRUCTORES DE AUTOESCUELA",
        },
        {
          value: "5895",
          name: "ASTROLOGOS,ADIVINADORES Y AFINES",
        },
        {
          value: "5899",
          name: "TRABAJADORES DE SERVICIOS PERSONALES NO CLASIFICAD",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "GUARDIAS CIVILES",
      value: "5910",
      descripcion_cno__c: [
        {
          value: "5910",
          name: "GUARDIAS CIVILES",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "POLICÍAS",
      value: "5921",
      descripcion_cno__c: [
        {
          value: "5921",
          name: "POLICIAS NACIONALES",
        },
        {
          value: "5922",
          name: "POLICIAS AUTONOMICOS",
        },
        {
          value: "5923",
          name: "POLICIAS LOCALES",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "BOMBEROS",
      value: "5931",
      descripcion_cno__c: [
        {
          value: "5931",
          name: "BOMBEROS(EXCEPTO FORESTALES)",
        },
        {
          value: "5932",
          name: "BOMBEROS FORESTALES",
        },
      ],
    },
    {
      profesion: "TRABAJADORES DE PROTECCIÓN, SEGURIDAD Y LA SALUD",
      name: "OTROS TRABAJADORES DE LOS SERVICIOS DE PROTECCION Y SEGURIDAD ",
      value: "5999",
      descripcion_cno__c: [
        {
          value: "5941",
          name: "VIGILANTES DE SEGURIDAD Y SIMILARES HABILITADOS PARA IR ARMADOS",
        },
        {
          value: "5942",
          name: "AUXILIARES DE VIGILANTE DE SEGURIDAD NO HABILITADOS PARA IR ARMADOS",
        },
        {
          value: "5991",
          name: "VIGILANTES DE PRISIONES",
        },
        {
          value: "5992",
          name: "BAﾑISTAS-SOCORRISTAS",
        },
        {
          value: "5993",
          name: "AGENTES FORESTALES Y MEDIOAMBIENTALES",
        },
        {
          value: "5999",
          name: "TRABAJADORES DE LOS SERVICIOS DE PROTECCION Y SEGU",
        },
      ],
    },
    {
      profesion: "TRABAJADORES SECTOR PRIMARIO Y SECUNDARIO",
      name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES AGRÍCOLAS Y GANADERAS (INCLUIDAS AVÍCOLAS, APÍCOLAS Y SIMILARES)",
      value: "6110",
      descripcion_cno__c: [
        {
          value: "6110",
          name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES AGRICOLAS",
        },
        {
          value: "6120",
          name: "TRABAJADORES CUALIFICADOS EN HUERTAS,INVERNADEROS,",
        },
        {
          value: "6201",
          name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES GANADERAS DE VACUNO",
        },
        {
          value: "6202",
          name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES GANADERAS DE OVINO Y CAPRINO",
        },
        {
          value: "6203",
          name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES GANADERAS DE PORCINO",
        },
        {
          value: "6204",
          name: "TRABAJADORES CUALIFICADOS EN APICULTURA Y SERICICULTURA",
        },
        {
          value: "6205",
          name: "TRABAJADORES CUALIFICADOS EN LA AVICULTURA Y CUNICULTURA",
        },
        {
          value: "6209",
          name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES GANADERAS NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
        {
          value: "6300",
          name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES AGROPECUARIAS MIXTAS",
        },
      ],
    },
    {
      profesion: "TRABAJADORES SECTOR PRIMARIO Y SECUNDARIO",
      name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES FORESTALES, PESQUERAS Y CINEGÉTICAS",
      value: "6410",
      options: [
        "CAZADOR",
        "PESCADOR",
        "MARISQUERO",
        "MARISCADORA",
        "AGENTE FORESTAL",
        "GUARDA FORESTAL",
        "ACUICULTOR",
      ],
      descripcion_cno__c: [
        {
          value: "6410",
          name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES FORESTALES Y DEL MEDIO NATURAL",
        },
        {
          value: "6421",
          name: "TRABAJADORES CUALIFICADOS EN LA ACUICULTURA",
        },
        {
          value: "6422",
          name: "PESCADORES DE AGUAS COSTERAS Y AGUAS DULCES",
        },
        {
          value: "6423",
          name: "PESCADORES DE ALTURA",
        },
        {
          value: "6430",
          name: "TRABAJADORES CUALIFICADOS EN ACTIVIDADES CINEGETICAS",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "TRABAJADORES EN OBRAS ESTRUCTURALES DE CONSTRUCCIÓN Y AFINES",
      value: "7199",
      descripcion_cno__c: [
        {
          value: "7111",
          name: "ENCOFRADORES Y OPERARIOS DE PUESTA EN OBRA DE HORMIGON",
        },
        {
          value: "7112",
          name: "MONTADORES DE PREFABRICADOS ESTRUCTURALES(SOLO HORMIGON)",
        },
        {
          value: "7121",
          name: "ALBAﾑILES",
        },
        {
          value: "7122",
          name: "CANTEROS,TRONZADORES,LABRANTES Y GRABADORES DE PIE",
        },
        {
          value: "7131",
          name: "CARPINTEROS(EXCEPTO EBANISTAS)",
        },
        {
          value: "7132",
          name: "INSTALADORES DE CERRAMIENTOS METALICOS Y CARPINTEROS",
        },
        {
          value: "7191",
          name: "MANTENEDORES DE EDIFICIOS",
        },
        {
          value: "7192",
          name: "INSTALADORES DE FACHADAS TECNICAS",
        },
        {
          value: "7193",
          name: "INSTALADORES DE SISTEMAS DE IMPERMEABILIZACION EN EDIFICIOS",
        },
        {
          value: "7199",
          name: "OTROS TRABAJADORES DE LAS OBRAS ESTRUCTURALES DE CONTRUCCION",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "TRABAJADORES DE ACABADO DE CONSTRUCCIONES E INSTALACIONES (EXCEPTO ELECTRICISTAS), PINTORES Y AFINES",
      value: "7211",
      descripcion_cno__c: [
        {
          value: "7211",
          name: "ESCAYOLISTAS",
        },
        {
          value: "7212",
          name: "APLICADORES DE REVESTIMIENTOS DE PASTA Y MORTERO",
        },
        {
          value: "7221",
          name: "FONTANEROS",
        },
        {
          value: "7222",
          name: "MONTADORES-INSTALADORES DE GAS EN EDIFICIOS",
        },
        {
          value: "7223",
          name: "INSTALADORES DE CONDUCTOS EN OBRA PÚBLICA",
        },
        {
          value: "7231",
          name: "PINTORES Y EMPAPELADORES",
        },
        {
          value: "7232",
          name: "PINTORES EN LAS INDUSTRIAS MANUFACTURERAS",
        },
        {
          value: "7240",
          name: "SOLADORES,COLOCADORES DE PARQUET Y AFINES",
        },
        {
          value: "7250",
          name: "MECANICOS-INSTALADORES DE REFRIGERACION Y CLIMATIZ",
        },
        {
          value: "7291",
          name: "MONTADORES DE CUBIERTAS",
        },
        {
          value: "7292",
          name: "INSTALADORES DE MATERIAL AISLANTE TERMICO Y DE INSONORACION",
        },
        {
          value: "7293",
          name: "CRISTALEROS",
        },
        {
          value: "7294",
          name: "MONTADORES-INSTALADORES DE PLACAS DE ENERGIA SOLAR",
        },
        {
          value: "7295",
          name: "PERSONAL DE LIMPIEZA DE FACHADAS DE EDIFICIOS Y CHIMENEAS",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "SOLDADORES, CHAPISTAS, MONTADORES DE ESTRUCTURAS METÁLICAS, HERREROS, ELABORADORES DE HERRAMIENTAS Y AFINES",
      value: "7311",
      descripcion_cno__c: [
        {
          value: "7311",
          name: "MOLDEADORES Y MACHEROS",
        },
        {
          value: "7312",
          name: "SOLDADORES Y OXICORTADORES",
        },
        {
          value: "7313",
          name: "CHAPISTAS Y CALDEREROS",
        },
        {
          value: "7314",
          name: "MONTADORES DE ESTRUCTURAS METALICAS",
        },
        {
          value: "7315",
          name: "MONTADORES DE ESTRUCTURAS CABLEADAS Y EMPALMADORES",
        },
        {
          value: "7321",
          name: "HERREROS Y FORJADORES",
        },
        {
          value: "7322",
          name: "TRABAJADORES DE LA FABRICACION DE HERRAMIENTAS,MECANICO-AJUSTADORES,MODELISTAS,MATRICEROS Y AFINES",
        },
        {
          value: "7323",
          name: "AJUSTADORES Y OPERADORES DE MAQUINAS-HERRAMIENTA",
        },
        {
          value: "7324",
          name: "PULIDORES DE METALES Y AFILADORES DE HERRAMIENTAS",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "MECÁNICOS Y AJUSTADORES DE MAQUINARIA",
      value: "7401",
      descripcion_cno__c: [
        {
          value: "7401",
          name: "MECANICOS Y AJUSTADORES DE VEHICULOS DE MOTOR",
        },
        {
          value: "7402",
          name: "MECANICOS Y AJUSTADORES DE MOTORES DE AVION",
        },
        {
          value: "7403",
          name: "MECANICOS Y AJUSTADORES DE MAQUINARIA AGRICOLA E INDUSTRIAL",
        },
        {
          value: "7404",
          name: "MECANICOS Y AJUSTADORES DE MAQUINARIA NAVAL Y FERROVIARIA",
        },
        {
          value: "7405",
          name: "REPARADORES DE BICICLETAS Y AFINES",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "TRABAJADORES ESPECIALIZADOS EN ELECTRICIDAD Y ELECTROTECNOLOGÍA",
      value: "7510",
      descripcion_cno__c: [
        {
          value: "7510",
          name: "ELECTRICISTAS DE LA CONSTRUCCION Y AFINES",
        },
        {
          value: "7521",
          name: "MECANICOS Y REPARADORES DE EQUIPOS ELECTRICOS",
        },
        {
          value: "7522",
          name: "INSTALADORES Y REPARADORES DE LINEAS ELECTRICAS",
        },
        {
          value: "7531",
          name: "MECANICOS Y REPARADORES DE EQUIPOS ELECTRONICOS",
        },
        {
          value: "7532",
          name: "INSTALADORES Y REPARADORES EN ELECTROMEDICINA",
        },
        {
          value: "7533",
          name: "INSTALADORES Y REPARADORES EN TECNOLOGIAS DE LA INFORMACION Y LAS COMUNICACIONES",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "MECÁNICOS DE PRECISIÓN EN METALES, CERAMISTAS, VIDRIEROS, ARTESANOS Y TRABAJADORES DE ARTES GRÁFICAS",
      value: "7619",
      descripcion_cno__c: [
        {
          value: "7611",
          name: "RELOJEROS Y MECANICOS DE INSTRUMENTOS DE PRECISION",
        },
        {
          value: "7612",
          name: "LUTIERES Y SIMILARES,AFINADORES DE INSTRUMENTOS MUSICALES",
        },
        {
          value: "7614",
          name: "TRABAJADORES DE LA CERAMICA,ALFAREROS Y AFINES",
        },
        {
          value: "7615",
          name: "SOPLADORES,MODELADORES,LAMINADORES,CORTADORES Y PULIDORES DE VIDRIO",
        },
        {
          value: "7616",
          name: "ROTULISTAS,GRABADORES DE VIDRIO,PINTORESD ECORATIV",
        },
        {
          value: "7617",
          name: "ARTESANOS EN MADERA Y MATERIALES SIMILARES,CESTERO",
        },
        {
          value: "7618",
          name: "ARTESANOS EN TEJIDOS,CUEROS Y MATERIALES SIMILARES",
        },
        {
          value: "7619",
          name: "ARTESANOS NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
        {
          value: "7621",
          name: "TRABAJADORES DE PROCESOS DE PREIMPRESION",
        },
        {
          value: "7622",
          name: "TRABAJADORES DE PROCESOS DE IMPRESION",
        },
        {
          value: "7623",
          name: "TRABAJADORES DE PROCESOS DE ENCUADERNACION",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "JOYEROS,ORFEBRES Y PLATEROS",
      value: "7613",
      descripcion_cno__c: [
        {
          value: "7613",
          name: "JOYEROS,ORFEBRES Y PLATEROS",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "TRABAJADORES DE LA INDUSTRIA DE LA ALIMENTACIÓN, BEBIDAS Y TABACO",
      value: "7701",
      descripcion_cno__c: [
        {
          value: "7701",
          name: "MATARIFES Y TRABAJADORES DE LAS INDUSTRIAS CARNICA",
        },
        {
          value: "7702",
          name: "TRABAJADORES DE LAS INDUSTRIAS DEL PESCADO",
        },
        {
          value: "7703",
          name: "PANADEROS,PASTELEROS Y CONFITEROS",
        },
        {
          value: "7704",
          name: "TRABAJADORES DEL TRATAMIENTO DE LA LECHE Y ELABORACION DE PRODUCTOS LACTEOS(INCLUIDOS",
        },
        {
          value: "7705",
          name: "TRABAJADORES CONSERVEROS DE FRUTAS Y HORTALIZAS Y TRABAJADORES DE LA ELABORACION DE BEBIDAS NO ALCOHOLICAS",
        },
        {
          value: "7706",
          name: "TRABAJADORES DE LA ELABORACION DE BEBIDAS ALCOHOLICAS DISTINTAS DEL VINO",
        },
        {
          value: "7707",
          name: "TRABAJADORES DE LA ELABORACION DEL VINO",
        },
        {
          value: "7708",
          name: "PREPARADORES Y ELABORADORES DEL TABACO Y SUS PRODUCTOS",
        },
        {
          value: "7709",
          name: "CATADORES Y CLASIFICADORES DE ALIMENTOS Y BEBIDAS",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "TRABAJADORES DE LA MADERA, TEXTIL, CONFECCIÓN, PIEL, CUERO, CALZADO Y OTROS OPERARIOS EN OFICIOS",
      value: "7899",
      descripcion_cno__c: [
        {
          value: "7811",
          name: "TRABAJADORES DEL TRATAMIENTO DE LA MADERA",
        },
        {
          value: "7812",
          name: "AJUSTADORES Y OPERADORES DE MAQUINAS PARA TRABAJAR",
        },
        {
          value: "7820",
          name: "EBANISTAS Y TRABAJADORES AFINES",
        },
        {
          value: "7831",
          name: "SASTRES,MODISTOS,PELETEROS Y SOMBREREROS",
        },
        {
          value: "7832",
          name: "PATRONISTAS PARA PRODUCTOS EN TEXTIL Y PIEL",
        },
        {
          value: "7833",
          name: "CORTADORES DE TEJIDOS,CUERO,PIEL Y OTROS MATERIALES",
        },
        {
          value: "7834",
          name: "COSTUREROS A MANO,BORDADORES Y AFINES",
        },
        {
          value: "7835",
          name: "TAPICEROS,COLCHONEROS Y AFINES",
        },
        {
          value: "7836",
          name: "CURTIDORES Y PREPARADORES DE PIELES",
        },
        {
          value: "7837",
          name: "ZAPATEROS Y AFINES",
        },
        {
          value: "7891",
          name: "BUCEADORES",
        },
        {
          value: "7892",
          name: "PEGADORES",
        },
        {
          value: "7893",
          name: "CLASIFICADORES Y PROBADORES DE PRODUCTOS(EXCEPTO ALIMENTOS,BEBIDAS Y TABACO)",
        },
        {
          value: "7894",
          name: "FUMIGADORES Y OTROS CONTROLADORES DE PLAGAS Y MALA",
        },
        {
          value: "7899",
          name: "OFICIALES,OPERARIOS Y ARTESANOS DE OTROS OFICIOS NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "OPERADORES DE INSTALACIONES Y MAQUINARIA FIJAS, Y MONTADORES",
      value: "8112",
      descripcion_cno__c: [
        {
          value: "8111",
          name: "MINEROS Y OTROS OPERADORES EN INSTALACIONES MINERAS",
        },
        {
          value: "8112",
          name: "OPERADORES EN INSTALACIONES PARA LA PREPARACION DE MINERALES Y ROCAS",
        },
        {
          value: "8113",
          name: "SONDISTAS Y TRABAJADORES AFINES",
        },
        {
          value: "8114",
          name: "OPERADORES DE MAQUINARIA PARA FABRICAR PRODUCTOS DERIVADOS DE MINERALES",
        },
        {
          value: "8121",
          name: "OPERADORES EN INSTALACIONES PARA LA OBTENCION Y TRANSFORMACION DE METALES",
        },
        {
          value: "8122",
          name: "OPERADORES DE MAQUINAS INDUSTRIALES, MAQUINAS PULIDORAS,GALVANIZADORAS Y RECUBRIDORA DE METALES",
        },
        {
          value: "8131",
          name: "OPERADORES DE PLANTAS INDUSTRIALES QUIMICAS",
        },
        {
          value: "8132",
          name: "OPERADORES DE MAQUINAS PARA FABRICAR PRODUCTOS FARMACEUTICOS,COSMETICOS Y",
        },
        {
          value: "8133",
          name: "OPERADORES DE LABORATORIOS FOTOGRAFICOS Y AFINES",
        },
        {
          value: "8141",
          name: "OPERADORES DE MAQUINAS PARA FABRICAR PRODUCTOS DE CAUCHO Y DERIVADOS DE RESINAS",
        },
        {
          value: "8142",
          name: "OPERADORES DE MAQUINAS PARA FABRICAR PRODUCTOS DE MATERIAL PLASTICO",
        },
        {
          value: "8143",
          name: "OPERADORES DE MAQUINAS PARA FABRICAR PRODUCTOS DE PAPEL Y CARTON",
        },
        {
          value: "8144",
          name: "OPERADORES DE SERRERIAS,DE MAQUINAS DE FABRICACION",
        },
        {
          value: "8145",
          name: "OPERADORES EN INSTALACIONES PARA LA PREPARACION DE PASTA DE PAPEL",
        },
        {
          value: "8151",
          name: "OPERADORES DE MAQUINAS PARA PREPARAR FIBRAS,HILAR",
        },
        {
          value: "8152",
          name: "OPERADORES DE TELARES Y OTRAS MAQUINAS TEJEDORAS",
        },
        {
          value: "8153",
          name: "OPERADORES DE MAQUINAS DE COSER Y BORDAR",
        },
        {
          value: "8154",
          name: "OPERADORES DE MAQUINAS DE BLANQUEAR,TEÑIR,ESTAMPAR Y ACABAR TEXTILES",
        },
        {
          value: "8155",
          name: "OPERADORES DE MAQUINAS PARA TRATAR PIELES Y CUERO",
        },
        {
          value: "8156",
          name: "OPERADORES DE MAQUINAS PARA LA FABRICACION DEL CALZADO,MARROQUINERIA Y GUANTERIA",
        },
        {
          value: "8159",
          name: "OPERADORES DE MAQUINAS PARA FABRICAR PRODUCTOS TEXTILES NO CLASIFICADOS BAJO OTROS EPIGRA",
        },
        {
          value: "8160",
          name: "OPERADORES DE MAQUINAS PARA ELABORAR PRODUCTOS ALIMENTICIOS,BEBIDAS Y TABACO",
        },
        {
          value: "8170",
          name: "OPERADORES DE MAQUINAS DE LAVANDERIA Y TINTORERIA",
        },
        {
          value: "8191",
          name: "OPERADORES DE HORNOS E INSTALACIONES DE VIDRIERIA",
        },
        {
          value: "8192",
          name: "OPERADORES DE CALDERAS Y MAQUINAS DE VAPOR",
        },
        {
          value: "8193",
          name: "OPERADORES DE MAQUINAS DE EMBALAJE,EMBOTELLAMIENTO",
        },
        {
          value: "8199",
          name: "OPERADORES DE INSTALACIONES Y MAQUINARIA FIJAS NO CLASIFICADOS BAJO OTROS EPIGRAFES",
        },
        {
          value: "8201",
          name: "ENSAMBLADORES DE MAQUINARIA MECANICA",
        },
        {
          value: "8202",
          name: "ENSAMBLADORES DE EQUIPOS ELECTRICOS Y ELECTRONICOS",
        },
        {
          value: "8209",
          name: "MONTADORES Y ENSAMBLADORES NO CLASIFICADOS EN OTROS EPIGRAFES",
        },
      ],
    },
    {
      profesion: "OFICIALES,OPERADORES Y ARTESANOS DE OTROS OFICIOS",
      name: "MAQUINISTAS DE LOCOMOTORAS, OPERADORES DE MAQUINARIA AGRÍCOLA Y DE EQUIPOS PESADOS MÓVILES, Y MARINEROS",
      value: "8311",
      descripcion_cno__c: [
        {
          value: "8311",
          name: "MAQUINISTAS DE LOCOMOTORAS",
        },
        {
          value: "8312",
          name: "AGENTES DE MANIOBRAS FERROVIARIAS",
        },
        {
          value: "8321",
          name: "OPERADORES DE MAQUINARIA AGRICOLA MOVIL",
        },
        {
          value: "8322",
          name: "OPERADORES DE MAQUINARIA FORESTAL MOVIL",
        },
        {
          value: "8331",
          name: "OPERADORES DE MAQUINARIA DE MOVIMIENTOS DE TIERRAS",
        },
        {
          value: "8332",
          name: "OPERADORES DE GRUAS,MONTACARGAS Y DE MAQUINARIA SIMILAR DE MOVIMIENTO DE MATERIALES",
        },
        {
          value: "8333",
          name: "OPERADORES DE CARRETILLAS ELEVADORAS",
        },
        {
          value: "8340",
          name: "MARINEROS DE PUENTE,MARINEROS DE MAQUINAS Y AFINES",
        },
        {
          value: "8411",
          name: "CONDUCTORES PROPIETARIOS DE AUTOMOVILES,TAXIS Y FURGONETAS",
        },
        {
          value: "8412",
          name: "CONDUCTORES ASALARIADOS DE AUTOMOVILES,TAXIS Y FURGONETAS",
        },
        {
          value: "8420",
          name: "CONDUCTORES DE AUTOBUSES Y TRANVIAS",
        },
        {
          value: "8431",
          name: "CONDUCTORES PROPIETARIOS DE CAMIONES",
        },
        {
          value: "8432",
          name: "CONDUCTORES ASALARIADOS DE CAMIONES",
        },
        {
          value: "8440",
          name: "CONDUCTORES DE MOTOCICLETAS Y CICLOMOTORES",
        },
      ],
    },
    {
      profesion: "PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA",
      name: "EMPLEADOS DOMESTICOS",
      value: "9100",
      options: [
        "EMPLEADOS DOMESTICOS",
        "EMPLEADO DOMÉSTICO",
        "EMPLEADA DOMÉSTICA",
        "EMPLEADAS DOMÉSTICAS",
        "EMPLEADO DEL HOGAR",
        "EMPLEADA DEL HOGAR",
        "PERSONAL DE LIMPIEZA",
        "PERSONAL DE MANTENIMIENTO",
      ],
      descripcion_cno__c: [
        {
          value: "9100",
          name: "EMPLEADOS DOMESTICOS",
        },
      ],
    },
    {
      profesion: "PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA",
      name: "OTRO PERSONAL DE LIMPIEZA",
      value: "9229",
      descripcion_cno__c: [
        {
          value: "9210",
          name: "PERSONAL DE LIMPIEZA DE OFICINAS,HOTELES Y OTROS ESTABLECIMIENTOS SIMILARES",
        },
        {
          value: "9221",
          name: "LIMPIADORES EN SECO A MANO Y AFINES",
        },
        {
          value: "9222",
          name: "LIMPIADORES DE VEHICULOS",
        },
        {
          value: "9223",
          name: "LIMPIADORES DE VENTANAS",
        },
        {
          value: "9229",
          name: "OTRO PERSONAL DE LIMPIEZA",
        },
      ],
    },
    {
      profesion: "PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA",
      name: "AYUDANTES DE PREPARACIÓN DE ALIMENTOS",
      value: "9310",
      descripcion_cno__c: [
        {
          value: "9310",
          name: "AYUDANTES DE COCINA",
        },
        {
          value: "9320",
          name: "PREPARADORES DE COMIDAS RAPIDAS",
        },
      ],
    },
    {
      profesion: "EMPLEADOS DE MANTENIMIENTO, MENSAJERÍA, ALMACEN",
      name: "REPARTIDORES DE PUBLICIDAD,LIMPIABOTAS Y OTROS TRABAJADORES DE OFICIOS CALLEJEROS",
      value: "9410",
      descripcion_cno__c: [
        {
          value: "9410",
          name: "VENDEDORES CALLEJEROS",
        },
        {
          value: "9420",
          name: "REPARTIDORES DE PUBLICIDAD,LIMPIABOTAS Y OTROS TRABAJADORES DE OFICIOS CALLEJEROS",
        },
      ],
    },
    {
      profesion: "EMPLEADOS DE MANTENIMIENTO, MENSAJERÍA, ALMACEN",
      name: "ORDENANZAS, MOZOS DE EQUIPAJE, REPARTIDORES A PIE Y AFINES",
      value: "9433",
      descripcion_cno__c: [
        {
          value: "9431",
          name: "ORDENANZAS",
        },
        {
          value: "9432",
          name: "MOZOS DE EQUIPAJE Y AFINES",
        },
        {
          value: "9433",
          name: "REPARTIDORES,RECADISTAS Y MENSAJEROS A PIE",
        },
        {
          value: "9434",
          name: "LECTORES DE CONTADORES Y RECAUDADORES DE MAQUINAS",
        },
      ],
    },
    {
      profesion: "EMPLEADOS DE MANTENIMIENTO, MENSAJERÍA, ALMACEN",
      name: "PEONES DEL TRANSPORTE, DESCARGADORES Y REPONEDORES",
      value: "9811",
      descripcion_cno__c: [
        {
          value: "9811",
          name: "PEONES DEL TRANSPORTE DE MERCANCIAS Y DESCARGADORES",
        },
      ],
    },
    {
      profesion: "PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA",
      name: "RECOGEDORES DE RESIDUOS, CLASIFICADORES DE DESECHOS, BARRENDEROS Y AFINES",
      value: "9441",
      descripcion_cno__c: [
        {
        value: "9442",
        name: "CLASIFICADORES DE DESECHOS,OPERARIOS DE PUNTO LIMPIO Y RECOGEDORES DE CHATARRA",
        },
        {
        value: "9443",
        name: "BARRENDEROS Y AFINES",
        },
      ],
    },
    {
      profesion: "PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA",
      name: "OTRAS OCUPACIONES ELEMENTALES",
      value: "9490",
      descripcion_cno__c: [
        {
          value: "9490",
          name: "OTRAS OCUPACIONES ELEMENTALES",
        },
      ],
    },
    {
      profesion: "PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA",
      name: "PEONES AGRARIOS, FORESTALES Y DE LA PESCA",
      value: "9512",
      descripcion_cno__c: [
        {
          value: "9511",
          name: "PEONES AGRICOLAS(EXCEPTO EN HUERTAS,INVERNADEROS,VIVEROS Y JARDINES)",
        },
        {
          value: "9512",
          name: "PEONES AGRICOLAS EN HUERTAS,INVERNADEROS,VIVEROS Y JARDINES",
        },
        {
          value: "9520",
          name: "PEONES GANADEROS",
        },
        {
          value: "9530",
          name: "PEONES AGROPECUARIOS",
        },
        {
          value: "9541",
          name: "PEONES DE LA PESCA",
        },
        {
          value: "9542",
          name: "PEONES DE LA ACUICULTURA",
        },
        {
          value: "9543",
          name: "PEONES FORESTALES Y DE LA CAZA",
        },
      ],
    },
    {
      profesion: "PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA",
      name: "PEONES DE LA CONSTRUCCIÓN, DE LA MINERÍA Y DE LAS INDUSTRIAS MANUFACTURERAS",
      value: "9602",
      descripcion_cno__c: [
        {
          value: "9601",
          name: "PEONES DE OBRAS PÚBLICAS",
        },
        {
          value: "9602",
          name: "PEONES DE LA CONSTRUCCION DE EDIFICIOS",
        },
        {
          value: "9603",
          name: "PEONES DE LA MINERIA,CANTERA Y OTRAS INDUSTRIAS EXTRACTIVAS",
        },
        {
          value: "9700",
          name: "PEONES DE LAS INDUSTRIAS MANUFACTURERAS",
        },
      ],
    },
    {
      profesion: "PEONES, EMPLEADOS DE SERVICIOS DE MANTENIMIENTO Y LIMPIEZA",
      name: "PEONES DEL TRANSPORTE, DESCARGADORES Y REPONEDORES",
      value: "9811",
      descripcion_cno__c: [
        {
          value: "9811",
          name: "PEONES DEL TRANSPORTE DE MERCANCIAS Y DESCARGADORES",
        },
        {
          value: "9812",
          name: "CONDUCTORES DE VEHICULOS DE TRACCION ANIMAL PARA EL TRANSPORTE DE PERSONAS Y SIMILARES",
        },
        {
          value: "9820",
          name: "REPONEDORES",
        },
      ],
    },
    {
      profesion: "DESCONOCIDO",
      name: "DESCONOCIDO",
      value: "999999",
      descripcion_cno__c: [
        {
          value: "99999",
          name: "DESCONOCIDO",
        },
      ],
    },
  ];
  