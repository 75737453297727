import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorService } from '../error.service';
import { HeaderService } from '../headers.service';
import { ResponseLogin } from '../../collections/login';
import { NestedObject } from 'app/services/nestedObject.service';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiLoginService {
  options = {};

  private HEADERS: Headers;
  constructor(private http: HttpClient,
    public errService: ErrorService,
    private headerSrv: HeaderService,
    public nestedObject: NestedObject) {

    this.options = { withCredentials: true, observe: 'response' };
  }

  // public setSign(login): Observable<signLogalty> {
  //   return this.http
  //     .post(`${API_URL}/login_be`, login, { withCredentials: true, observe: 'response' })
  //     .pipe(map(response =>  response['body']));
  // }

  public login(login): Observable<ResponseLogin> {
    return this.http
      .post(`${API_URL}/login_be`, login, this.options)
      .pipe(map(response => {
        const _sessionLogin = new ResponseLogin(response['body']);
        this.sessionLogin = _sessionLogin;
        const bearer = _sessionLogin.access_token;
        if (bearer) {
          sessionStorage.setItem('auth_token', bearer);
          sessionStorage.setItem('refresh_token', _sessionLogin.refresh_token);
        };
        return response['body'];
      }));
    /*.catch(this.errService.handleError);*/
  }

  get sessionLogin(): ResponseLogin {
    let previusSession = JSON.parse(sessionStorage.getItem('session-log')) || {};
    return previusSession;
  }

  set sessionLogin(session: ResponseLogin) {
    let previusSession = JSON.parse(sessionStorage.getItem('session-log')) || {};
    let currentSession = this.nestedObject.nestedObjectAssign({}, previusSession, session);
    sessionStorage.setItem('session-log', JSON.stringify(currentSession) || null);
  }
}
