import { Component, HostListener, OnInit, ChangeDetectorRef} from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalService } from './../../../../services/modal.service';
import { AuthService } from './../../../../services/auth.service';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { DeviceService } from '../../../../services/device.service';
import { ErrorService } from '../../../../services/error.service';
import { AnalyticsService } from '../../../../services/analytics.service';
import { Persona } from '../../../../collections/persona';
import { Product } from '../../../../collections/product';
import { HelperService } from '../../../../services/helpers.service';
import { environment } from 'environments/environment';
import { HeaderService } from '../../../../services/headers.service';
import { JsonService } from '../../../../services/json.service';
import { FormValidatorService } from 'app/services/form-validator.service';
import { LoginService } from 'app/services/login.service';
import { User } from 'app/collections/user';
import { Observable } from 'rxjs';
import { AuthUserService } from '../../../../services/auth-user.service';
import { ApiLoginService } from '../../../../services/api/api.login.service';
import { ResponseLogin } from '../../../../collections/login';
import { ACCOUNTS } from 'app/resources/account.model';
import { HelpersMicroinsurancesService } from '../../../../services/helpers-microinsurances.service';
import { AuthGuardMicro } from '../../../../services/auth-guard-micro.service';
import { SecurityService } from '../../../../services/security-registration.service';

@Component({
  selector: 'app-prev-sign',
  templateUrl: './prev-sign.component.html',
  styleUrls: ['./prev-sign.component.scss'],
  providers: [ModalService, DeviceService, JsonService]
})
export class PrevSignComponent implements OnInit {
  loading:boolean = false;
  prevSignForm : FormGroup;
  persona: Persona;
  msgSrvError = '';
  modalSrvError = 'modal-srv-error';
  modalEmail = 'modal-no-email-complete';
  modalRequire = 'modal-require';
  modalAdhesion = 'modal-adeshion';
  modalNota = 'modal-nota';
  recoveryToken = false;
  bmi = false;
  slides: Array<any> = [];
  photoInterval:number = 5000;
  noLoopSlides:boolean = false;
  idCliente:string;
  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    return $event.returnValue = '¿Estás seguro?';
  }
  constructor(private fb: FormBuilder,
              private router: Router,
              public modService: ModalService,
              private authService:AuthService,
              private authMicroService:AuthMicroService,
              private authUserService: AuthUserService,
              private deviceService:DeviceService,
              public errService:ErrorService,
              private analytics: AnalyticsService,
              private helpers: HelperService,
              private helpersMicroinsurance: HelpersMicroinsurancesService,
              private headerSrv: HeaderService,
              private jsonService: JsonService,
              private activatedRoute: ActivatedRoute,
              private loginService: LoginService,
              private evoValidator: FormValidatorService,
              private apiLoginService: ApiLoginService,
              private authguardMicro: AuthGuardMicro,
              private securitySrv: SecurityService
) {
  this.addNewSlides();
}

  ngOnInit() {
    this.authguardMicro.empty();
    this.authService.product = ACCOUNTS.MSCode;
    this.getQueryParams();
    this.createForm();
    this.formSubscribe();

    if (document.getElementById('user-path')){//STEPS
      // Elimina la clase hide para mostrar los steps
      document.getElementById('user-path').classList.remove('hide');
      // Se eliminan estas clases si el usurario vuelve hacia atrás
      document.getElementById('user-path').classList.remove('paddingHeader');
      document.getElementById('user-path').classList.remove('stepMarginTop');
      // document.getElementById('user-path').classList.add('paddingHeader');
    }

   
    this.errService.bmiError = true;
    if (this.isTokenizedURL || this.loginService.isLoggedIn) {
      // Venimos de BMI
      if(this.isTokenizedURL) {
        this.getPersonaAndGetEmail();
      // Venimos del simulador del portal
      } else {
        const IBAN = this.authService.getIbanProduct();
        const params = {
          bmi: this.bmi,
          IBAN: IBAN
        };
        this.authMicroService.setMicroSession(params);
        this.getPersonaAndGetEmail();
        this.isMobile();
      }
    }
  }

  getPersonaAndGetEmail() {
    this.loading = true;
    this.authService.getProspect('bWljcm9zZWd1cm9z').subscribe(titular => {
      this.loading = false;
      if (titular === undefined || titular === null) {
        this.msgSrvError = 'Error genérico';
        this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.msgSrvError);
        this.modService.open(this.modalSrvError);
        return
      }
      this.idCliente = titular.datosPersonales.id_cliente_EVO;
      this.authService.setSessionProspect(titular);
      this.helpers.updateFieldValue(this.prevSignForm, 'email', titular.datosPersonales.email);
      this.persona = titular;
    }, error => {
      this.error();
    });
  }

  private addNewSlides() {
    this.slides.push(
      {image: './assets/images/background/microinsurances/mountain.jpg',text:'Microservicios'}
    );
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.token) {
        sessionStorage.setItem('auth_token', params.token);
        this.recoveryToken = true;
      }
      if (params.IBAN) this.authMicroService.setMicroSession(params);
      if (params.bmi=== ''){this.bmi = true;};
    });
  }

  openModal(modal) {
    switch (modal) {
      case 'requisitos':
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación',modal);
        this.modService.open(this.modalRequire);
        break;
      case 'nota':
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación',modal);
        this.modService.open(this.modalNota);
        break;
      case 'boletin':
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación',modal);
        this.modService.open(this.modalAdhesion);
        break;
    }
  }

  get isTokenizedURL() {
    return (this.recoveryToken);
  }

  createForm() {
    this.prevSignForm = this.fb.group({
      email: ['', [Validators.minLength(1),
      Validators.maxLength(50),
      this.evoValidator.validateEmail
      ]],
    });
  }

  formSubscribe() {
    this.prevSignForm.valueChanges.subscribe(contactForm => {
      this.errService.mobileErrors(this.prevSignForm);
    });
  }

  onSubmit() {

    if (!this.prevSignForm.get('email').valid){
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.modalEmail);
      this.modService.open(this.modalEmail);
      return;
    }
    const persona = this.setEmailMicro();
    this.sendMicro(persona);
  }

  sendMicro(persona) {
    this.loading = true;
    this.authService.updateProspect(persona).subscribe((data) => {
      this.loading = false;
      if (data.response.codigo === 'KO') {
        // this.analytics.triggerFormData('Previa firma microseguros', `Envio KO: ${this.msgSrvError}`);
        this.msgSrvError = !!data.response.message ? data.response.message : 'Error genérico';
        this.modService.open(this.modalSrvError);
        return
      }
      if (this.authService.isLoggedIn) {
        const nextStep = this.helpersMicroinsurance.getParentPathMicro('pre-logalty');
        // this.analytics.triggerFormData('Previa firma microseguros', 'Envio OK');
        this.helpers.navigateTo(nextStep, undefined, true);
        // this.headerSrv.getRefreshToken().subscribe(data => { this.helpers.navigateTo(nextStep); }, error => { this.loading = false;console.log(error); });
      }
    }, error => {
      this.error();
    });
  }

  error() {
    this.errService.navToError();
  }

  setEmailMicro() {
    const canal = this.deviceService.getDevice();

    return {
      canal: canal,
      codigoEntidad: '0239',
      Microseguros: {
        emailMicroseguros: this.helpers.returnValue(this.prevSignForm.get('email'))
      }
    };
  }

  isMobile(){
    let device = this.deviceService.getDevice();
    if(device === 'Móvil'){
      document.getElementById('user-path').classList.add('hide');
      this.bmi = true;
      let bmiSession: string = String(this.bmi);
      sessionStorage.setItem("bmiSession",bmiSession );
    }
  }

}
