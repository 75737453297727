<loading [showMe]="loading"></loading>

<div-show [showMe]="showError" [nombreSegundoTitular]="nombreSegundoTitular"
 [nameError]="nameError" [mailCliente]="mailCliente"></div-show>

 <!-- Datos modificados correctamente -->
<div class="banner banner-todo-ok deviceBanner" *ngIf="modificacionOK && !hideBannerNotificacionOk">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBannerNotifyOk()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo icon-todo-ok"></i>
      </span>
      <span class="banner-message">
        ¡Todo en orden! Tus datos han sido modificados con éxito. No tienes que hacer nada más, pronto podrás verlos
        actualizados
      </span>
    </div>
  </div>
</div>
 
<div class="banner banner-narrow banner-narrow_red" *ngIf="!hiddeNotification">
    <div class="banner-content">
      <div class="message-container">
        <i class="evo ico-warning-white"></i>
        <span class="banner-message">
            ¡UPS! Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.
        </span>
      </div>
      <i class="evo close" (click)="hideNotification()"></i>
    </div>
  </div>
<section class="c-header--full bg-pos">
  <div class="c-header--bginv ">
    <div class="responsive-header" *ngIf="!isBmi">
      <div class="backbutton" (click)="botonVolver()">
        <i class="evo flecha"></i>
      </div>
      <div *ngIf="!esFondoInteligente && !isBmi" class="page-title">
        <span>FONDOS Y VALORES</span>
      </div>
      <div *ngIf="esFondoInteligente && !isBmi" class="page-title">
        <span>FONDO INTELIGENTE</span>
      </div>
    </div>
    <div class="c-header__wizard">
      <p class="c-header__wizard__title">DOCUMENTACIÓN</p>
      <ul class="c-header__wizard__steps">
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 1}" id="wizard-1">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 2}" id="wizard-2">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 3}" id="wizard-3">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 4}" id="wizard-4">
          </li>
      </ul>
    </div>
  </div>
</section>
<form class="form" action="" [formGroup]="viewForm">
  <div class="main-content">
    <section class="info--container" *ngIf="showSecondStep">
      <div class="info__banner">
        <header class="info__banner__header">
          <p *ngIf="esFondoInteligente" class="info__banner__title">DOS FONDOS CONECTADOS ENTRE SI</p>
          <p *ngIf="!esFondoInteligente" class="info__banner__title">ENCUENTRA LOS FONDOS Y VALORES QUE BUSCAS</p>
          <img *ngIf="esFondoInteligente" class="info__banner__img" src="./assets/images/banner/banner_2.jpg" alt="Banner">
          <img *ngIf="!esFondoInteligente" class="info__banner__img" src="./assets/images/banner/banner_4.jpg" alt="Banner">
        </header>
        <footer class="info__banner__footer">
          <ul>
            <li *ngIf="!esFondoInteligente">Conoce nuestros fondos con nombres y apellidos</li>
            <li *ngIf="!esFondoInteligente">Fácil de contratar</li> 
            <li *ngIf="esFondoInteligente">Un fondo que invierte en renta fija a corto plazo</li>
            <li *ngIf="esFondoInteligente">Un fondo adicional en IBEX 35</li> 
          </ul>
        </footer>
      </div>
      <div class="info">
        <p *ngIf="showNoCatDocuments" class="info-title align-left">CATEGORIZACIÓN</p>
        <p *ngIf="showNoCatDocuments" class="info-description--borderbtm">Te hemos otorgado el máximo nivel de protección clasificándote como Minorista, lo que te permitirá tener toda la información y protección que establece la normativa aplicable. Podrás cambiar esta categorización según se recoge en el Pack de Bienvenida MIFID adjunto.</p>
        <p class="info-description align-left">Antes de continuar tu camino tienes que <strong class="bold">leer y aceptar la
            siguiente documentación. </strong></p>
        <!-- <div class="info__row">
        </div> -->
        <div class="info__row row-border-top" (click)="openDocument('infoMifid')">
          <div class="info__row__item">
            <div class="form-checkbox">
              <input id="check-doc1" class="i-checkbox" name="check-doc1" type="checkbox" value="Acepto"
               [checked]="infoMifid" formControlName="infoMifid">
              <label *ngIf="!showNoCatDocuments && !infoMifid" class="l-checkbox" for="check-doc1">Información MIFID</label>
              <label *ngIf="showNoCatDocuments && !infoMifid" class="l-checkbox" for="check-doc1">Pack de Bienvenida MIFID</label>
              <label *ngIf="!showNoCatDocuments && infoMifid" class="l-checkbox" for="check-doc1">
                <a (click)="reviewDocument('infoMifid')">Información MIFID</a>
              </label>
              <label *ngIf="showNoCatDocuments && infoMifid" class="l-checkbox" for="check-doc1">
                <a (click)="reviewDocument('infoMifid')">Pack de Bienvenida MIFID</a>
              </label>
              <div *ngIf="!infoMifid && validateSubmit" class="error-text">Debes leer y aceptar la documentación obligatoria para continuar</div>
            </div>
          </div>
          <div class="info__row__item">
              <i class="evo icon-check-ok" *ngIf="infoMifid"></i>
              <i class="evo right-arrow"  *ngIf="!infoMifid"></i>
          </div>
        </div>
        <!-- <div *ngIf="showNoCatDocuments" class="info__row">
          <div class="info__row__item">
            <div class="form-checkbox">
              <input id="check-doc2a" class="i-checkbox" name="check-doc2a" type="checkbox" value="Acepto"
               [checked]="poliGestion" formControlName="poliGestion" (change)="openDocument('poliGestion')">
              <label *ngIf="!poliGestion" class="l-checkbox" for="check-doc2a">Política de Gestión de Orden</label>
              <label *ngIf="poliGestion" class="l-checkbox" for="check-doc2a">
                <a (click)="reviewDocument('poliGestion')">Política de Gestión de Orden</a>
              </label>
            </div>
          </div>
          <div class="info__row__item">
              <i class="evo icon-check-ok" *ngIf="poliGestion"></i>
              <i class="evo right-arrow" *ngIf="!poliGestion" (click)="openDocument('poliGestion')"></i>
          </div>
        </div> -->
        <div *ngIf="currentDocument === 'poliConflic' && !esFondoInteligente" class="info__row" (click)="openDocument('poliConflic')">
          <div class="info__row__item">
            <div class="form-checkbox">
              <input id="check-doc2" class="i-checkbox" name="check-doc2" type="checkbox" value="Acepto"
               [checked]="poliConflic" formControlName="poliConflic">
              <label *ngIf="!poliConflic" class="l-checkbox" for="check-doc2">Política Conflicto de Interés Grupo EVO</label>
              <label *ngIf="poliConflic" class="l-checkbox" for="check-doc2">
                <a (click)="reviewDocument('poliConflic')">Política Conflicto de Interés Grupo EVO</a>
              </label>
              <div *ngIf="!poliConflic && validateSubmit" class="error-text">Debes leer y aceptar la documentación obligatoria para continuar</div>
            </div>
          </div>
          <div class="info__row__item">
              <i class="evo icon-check-ok" *ngIf="poliConflic"></i>
              <i class="evo right-arrow" *ngIf="!poliConflic"></i>
          </div>
        </div>
        <div *ngIf="currentDocument === 'poliOpti' && !esFondoInteligente" class="info__row" (click)="openDocument('poliOpti')">
          <div class="info__row__item">
            <div class="form-checkbox">
              <input id="check-doc3" class="i-checkbox" name="check-doc3" type="checkbox" value="Acepto"
               [checked]="poliOpti" formControlName="poliOpti">
              <label *ngIf="!poliOpti" class="l-checkbox" for="check-doc3">Política Óptima Ejecución EVO</label>
              <label *ngIf="poliOpti" class="l-checkbox" for="check-doc3">
                <a (click)="reviewDocument('poliOpti')">Política Óptima Ejecución EVO</a>
              </label>
              <div *ngIf="!poliOpti && validateSubmit" class="error-text">Debes leer y aceptar la documentación obligatoria para continuar</div>
            </div>
          </div>
          <div class="info__row__item">
            <i class="evo icon-check-ok" *ngIf="poliOpti"></i>
            <i class="evo right-arrow" *ngIf="!poliOpti"></i>
          </div>
        </div>
        <div *ngIf="currentDocument === 'poliSalva' && !esFondoInteligente" class="info__row" (click)="openDocument('poliSalva')">
          <div class="info__row__item">
            <div class="form-checkbox">
              <input id="check-doc4" class="i-checkbox" name="check-doc4" type="checkbox" value="Acepto"
               [checked]="poliSalva" formControlName="poliSalva">
              <label *ngIf="!poliSalva" class="l-checkbox" for="check-doc4">Política Salvaguarda de Activos EVO</label>
              <label *ngIf="poliSalva" class="l-checkbox" for="check-doc4">
                <a (click)="reviewDocument('poliSalva')">Política Salvaguarda de Activos EVO</a>
              </label>
              <div *ngIf="!poliSalva && validateSubmit" class="error-text">Debes leer y aceptar la documentación obligatoria para continuar</div>
            </div>
          </div>
          <div class="info__row__item">
            <i class="evo icon-check-ok" *ngIf="poliSalva"></i>
            <i class="evo right-arrow" *ngIf="!poliSalva"></i>
          </div>
        </div>
        <div *ngIf="currentDocument === 'poliIncen' && !esFondoInteligente" class="info__row" (click)="openDocument('poliIncen')">
          <div class="info__row__item">
            <div class="form-checkbox">
              <input id="check-doc5" class="i-checkbox" name="check-doc5" type="checkbox" value="Acepto"
               [checked]="poliIncen" formControlName="poliIncen">
              <label *ngIf="!poliIncen" class="l-checkbox" for="check-doc5">Política Incentivos</label>
              <label *ngIf="poliIncen" class="l-checkbox" for="check-doc5">
                <a (click)="reviewDocument('poliIncen')">Política Incentivos</a>
              </label>
              <div *ngIf="!poliIncen && validateSubmit" class="error-text">Debes leer y aceptar la documentación obligatoria para continuar</div>
            </div>
          </div>
          <div class="info__row__item">
            <i class="evo icon-check-ok" *ngIf="poliIncen"></i>
            <i class="evo right-arrow" *ngIf="!poliIncen"></i>
          </div>
        </div>
        <div *ngIf="currentDocument === 'folleto' && !esFondoInteligente" class="info__row" (click)="openDocument('folleto')">
          <div class="info__row__item">
            <div class="form-checkbox">
              <input id="check-doc6" class="i-checkbox" name="check-doc6" type="checkbox" value="Acepto"
               [checked]="folleto" formControlName="folleto">
              <label *ngIf="!folleto" class="l-checkbox" for="check-doc6">Folleto de Tarifas Valores</label>
              <label *ngIf="folleto" class="l-checkbox" for="check-doc6"><a (click)="reviewDocument('folleto')">Folleto de Tarifas Valores</a></label>
              <div *ngIf="!folleto && validateSubmit" class="error-text">Debes leer y aceptar la documentación obligatoria para continuar</div>
            </div>
          </div>
          <div class="info__row__item">
              <i class="evo icon-check-ok" *ngIf="folleto"></i>
              <i class="evo right-arrow" *ngIf="!folleto"></i>
          </div>
        </div>
        <div class="info__row--noborder">
          <div class="form-checkbox">
            <input id="check-conditions1" class="i-checkbox" name="check-accept-conditions" type="checkbox" value="Acepto"
              [checked]="condiciones" formControlName="condiciones" (change)="openDocument('condiciones')">
              <label class="l-checkbox" for="check-conditions1">
                He leído y acepto la <a (click)="openDocument('condiciones')" class="info__link">política de privacidad (obligatoria)</a>
              </label>
            <div *ngIf="!condiciones && validateSubmit" class="error-text">Debes leer y aceptar las condiciones legales para continuar con la
              contratación</div>
          </div>
        </div>
        <!-- <div class="info__row--noborder">
          <div class="form-checkbox">
            <input id="check-consent" class="i-checkbox" name="check-accept-consent" type="checkbox" value="Acepto"
              [checked]="consentimiento" formControlName="consentimiento" (change)="openDocument('consentimiento')" >
            <label class="l-checkbox" for="check-consent">Acepto el <a (click)="openDocument('consentimiento')" class="info__link">consentimiento de
                comunicaciones</a></label>
          </div>
        </div> -->
      </div>
      <div class="btn-group_fix">
        <button class="btn-common btn-common_fix btn-common_fix_bold" (click)="submit()">
          Continuar
        </button>
      </div>
    </section>

    <section>
      <div *ngIf="esFondoInteligente || !isMobileOrTablet" [ngClass]='esFondoInteligente ? "info-final":"hidden-info-final"'>
        El folleto informativo y el documento de datos fundamentales para el inversor están disponibles en www.cnmv.es</div>
    </section>
    
    <section *ngIf="esFondoInteligente" class="info--bggrey">
      <div class="info--bggrey__item">
        <p class="info--bggrey__title">PERFIL DE RIESGO</p>
      </div>
      <div class="info--bggrey__item">
        <div class="numericlevel">
          <div class="numericlevel__info">
            <p class="numericlevel__info__title">MENOR RIESGO</p>
            <p class="numericlevel__info__description">Rendimiento</p>
            <p class="numericlevel__info__description">potencialmente menor</p>
          </div>
          <div class="numericlevel__info">
            <p class="numericlevel__info__title">MAYOR RIESGO</p>
            <p class="numericlevel__info__description">Rendimiento</p>
            <p class="numericlevel__info__description">potencialmente mayor</p>
          </div>
          <ul class="numericlevel__list">
            <li class="numericlevel__list__item" *ngFor="let num of numRisks">
              <span>{{ num }}</span>
            </li>
          </ul>
          <p class="numericlevel__info__description">La categoría "1" no significa que la inversión esté libre de
            riesgo.
          </p>
        </div>
        <p class="info--bggrey__description">El perfil de riesgo señalado es un dato meramente indicativo del riesgo
          del
          fondo y está calculado en base a datos históricos de volatilidad que, no obstante pueden no constituir una
          indicación fiable del futuro perfil de desigo del fondo, por lo que podría variar a lo largo del tiempo.</p>
      </div>
      <div class="info--bggrey__item">
        <p class="info--bggrey__description">Puedes disponer de tu dinero en cualquier momento, en función del valor 
          liquidativo del fondo el día del reembolso. Ten en cuenta que, la retirada del dinero con carácter previo 
          al cumplimiento del horizonte temporal recomendado aumenta el riesgo de pérdida de capital. Los Fondos de Inversión
          EVO tienen indicadores de riesgo que van de 1 a 7, siendo 1 menor riesgo y 7 mayor riesgo. 
          Los folletos explicativos de los productos y los documentos de datos fundamentales para el inversor están disponibles
          en <a>www.cnmv.es</a>. EVO Fondo Inteligente, F.I. (Fondo Inteligente EVO) está registrado en la CNMV con el número 4978. No hay garantía
          de devolución del capital invertido. El indicador de riesgos del Fondo Inteligente está compuesto por la combinación de los
          riesgos de sus compartimentos, uno de Renta Fija con riesgo 1/7 con otro compartimento de Renta Variable riesgo 7/7. 
          El folleto explicativo del producto y el documento de datos fundamentales para el inversor están disponibles en www.cnmv.es 
          Gestora: GESCONSULT, S.A., S.G.I.I.C. Grupo Gestora: GESCONSULT. Depositario: BANCO INVERSIS, S.A., Grupo 
          Depositario: BANCA MARCH. Auditor: Deloitte, S.L. Comercializador.</p>
      </div>
    </section>
    <section class="info--bggrey" *ngIf="false">
      <p class="info--bggrey__description">La inversión está sujeta a posibles fluctuaciones en el precio de mercado,
        por lo que no hay garantías de devolución del capital invertido. Los folletos explicativos de los productos y
        los documentos de datos fundamentales para el inversor de los Fondos de Inversión donde figura su Indicador de
        Riesgo que puede ser 1 a 7 de menor a mayor riesgos, disponibles en www.cnmv.es</p>
    </section>
  </div>
</form>