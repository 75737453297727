import { CivilStatus } from '../collections/civil-status';

export var ESTADO_CIVIL: CivilStatus[] = [

  { name : 'Estado Civil', value : null},
  { name : 'Casado/a', value :  '05'},
  { name : 'Divorciado/a', value :  '03'},
  { name : 'Separado/a', value :  '02'},
  { name : 'Soltero/a', value :  '01'},
  { name : 'Unión de hecho', value : '06'},
  { name : 'Viudo/a', value :  '04'}
];
