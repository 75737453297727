<loading [showMe]="loading && logaltyLoaded"></loading>
<header-profile *ngIf="!logaltyLoaded && microSession.bmi !== '' || logaltySignature"></header-profile>
<preload-logalty *ngIf="!logaltyLoaded && !logaltySignature"></preload-logalty>
<load-logalty *ngIf="logaltySignature"></load-logalty>            

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<app-header-protect *ngIf="!bmi" id="logalty-ready-header"></app-header-protect>


<div class="logalty" [hidden]="!logaltyLoaded || ocultarIframe">
  <div class="image"></div>
  <form action="{{logaltySamlAction}}" method="POST" target="_self">
    <input type="hidden" name="saml_assertion" />
  </form>
</div>
