import { Injectable } from '@angular/core';
import { ErrorService } from '../error.service';
import { Http, Response, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';


@Injectable()
export class ApiBySideCarService {

  constructor(private http : Http,
              public errService: ErrorService) { }

  // // API: POST
  public getCalled(phoneParams: Object) {
    const options = new RequestOptions({headers:  new Headers({"Content-type":"application/x-www-form-urlencoded"})});
    return this.http
      .post(`https://ws.bysidecar.es/Campaigns/EvoBanco/incomingC2C.php`,
        phoneParams,
        options
      ).pipe(map(response =>  response['body']));
  }

}
