import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Http, Response, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Logalty,setCheckLogalty, CheckLogalty, signLogalty, LogaltyAmpliacion, LogaltyMicroseg, LogaltyInversora, LogaltyInversoraTwice } from '../../collections/logalty';
import { ErrorService } from '../error.service';
import { HeaderService } from '../headers.service';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiLogaltyService {
  options = {};
  constructor(private http: HttpClient) {
    this.options = { withCredentials: true, observe: 'response' };
  }

  public setSign(logalty: Logalty): Observable<signLogalty> {
    return this.http
      .post(`${API_URL}/fullonline/integracion/firma/sign`,
        logalty,
        this.options
      )
      .pipe(map(response =>  response['body']));
  }

  public setSignAmpliacion(logaltyAmpliacion: LogaltyAmpliacion): Observable<signLogalty> {
    return this.http
      .post(`${API_URL}/fullonline/integracion/firma/signCreditLimit`,
        logaltyAmpliacion,
        this.options
      )
      .pipe(map(response =>  response['body']));
  }

  public setSignCuentaInversora(logaltyInversora: LogaltyInversora): Observable<signLogalty> {
    return this.http
      .post(`${API_URL}/fullonline/integracion/firma/signCinvFint`,
        logaltyInversora,
        this.options
      )
      .pipe(map(response =>  response['body']));
  }

  public setSignCuentaInversoraTwice(logaltyInversoraTwice: LogaltyInversoraTwice): Observable<signLogalty> {
    return this.http
      .post(`${API_URL}/fullonline/integracion/firma/signCinvFintTwice`,
      logaltyInversoraTwice,
        this.options
      )
      .pipe(map(response =>  response['body']));
  }

  public setSignTwice(logalty: Logalty): Observable<signLogalty> {
    return this.http
      .post(`${API_URL}/fullonline/integracion/firma/signTwice`,
        logalty,
        this.options
      )
      .pipe(map(response =>  response['body']));
  }

  public setSignMicroseg(logalty: LogaltyMicroseg): Observable<signLogalty> {
    return this.http
      .post(`${API_URL}/fullonline/integracion/firma/signMicroseg`,
        logalty,
        this.options
      )
      .pipe(map(response =>  response['body']));
  }

  public setUpdateSign(logalty: Logalty): Observable<signLogalty> {
    return this.http
      .get(`${API_URL}/fullonline/integracion/firma/sign/${logalty.logaltySignatureId}`,
      this.options
    )
    .pipe(map(response =>  response['body']));
  }

  checkState(logalty:setCheckLogalty):Observable<CheckLogalty>{
    return this.http
      .post(`${API_URL}/fullonline/integracion/firma/checkState`,
        logalty,
        this.options
      )
      .pipe(map(response =>  response['body']));
  }

  logaltyMicroseg(logalty){
    return this.http
      .post(`${API_URL}/fullonline/salesforce/personainfo/v1/logalty`,
        logalty,
        this.options
      )
      .pipe(map(response =>  response['body']));

      /*.catch(res => {        
        if(res.status == 200) {
          return new Observable(observer => {
            observer.next({});
            observer.complete();
          });
        }
      });*/
  };

}
