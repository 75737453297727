import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'load-logalty',
  templateUrl: './load-logalty.component.html',
  styleUrls: ['./load-logalty.component.scss']
})
export class LoadLogaltyComponent  {


 
   mensaje = "Te estamos dirigiendo a una pantalla para confirmar los últimos datos"

  
  constructor() { }

 

}

