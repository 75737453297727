import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'micro-coverage',
  templateUrl: './coverage.component.html',
  styleUrls: ['./coverage.component.scss']
})
export class CoverageComponent implements OnInit {

  @Output("selectCoverage") selectCoverage = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  addCoverage() {
    this.selectCoverage.emit(true);
  }

}
