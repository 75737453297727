import { Injectable } from '@angular/core';
import { CancelPolicy, CancelPolicyResponse } from '../collections/microseg-adhesion';
import { MicrosegConfigResponse,MicrosegurosConfigCapitales } from "../collections/microseg-config"
import { ApiMicroinsurances } from "./api/api.microinsurances.service";
import { Observable } from 'rxjs';
import { TarificationOptResponse, TarificationOpt } from 'app/collections/tarification-opt';
import { Tarification, TarificationResponse } from 'app/collections/tarification';
import { CheckBalance, CheckBalanceResponse } from 'app/collections/check-balance';
import { MicroinsRegistry, MicroinsRegistryResponse } from 'app/collections/microins-registry';
import { GetCertificateResponse } from 'app/collections/get-certificate';
import { ListPolicies, ListPoliciesResponse } from 'app/collections/list-policies';
import { GetContractResponse } from 'app/collections/get-contract';

@Injectable()
export class MicrosegConfigService {

  constructor(private api: ApiMicroinsurances) { };

  getConfigLists(element: string):Observable<MicrosegConfigResponse> {
    return this.api.getConfigLists(element);
  };

  getConfigCapitales():Observable<MicrosegurosConfigCapitales> {
    return this.api.getConfigCapitales();
  }

  getTarificationOpt(tarificationOpt: TarificationOpt): Observable<TarificationOptResponse> {
    return this.api.getTarificationOpt(tarificationOpt);
  }

  getTarification(tarification: Tarification): Observable<TarificationResponse> {
    return this.api.getTarification(tarification);
  }

  getCheckBalance(checkBalance: CheckBalance): Observable<CheckBalanceResponse> {
    return this.api.getCheckBalance(checkBalance);
  }

  microinsRegistryFirstCall(microRegistry: MicroinsRegistry): Observable<MicroinsRegistryResponse> {
    return this.api.microinsRegistryFirstCall(microRegistry);
  }

  microinsRegistrySecondCall(microRegistry: MicroinsRegistry, mfaId: string, mfaValidationData:string): Observable<MicroinsRegistryResponse> {
    return this.api.microinsRegistrySecondCall(microRegistry, mfaId,  mfaValidationData);
  }

  getCertificate(requestId: string): Observable<GetCertificateResponse>{
    return this.api.getCertificate(requestId);
  }

  cancelPolicy(adhesion:CancelPolicy):Observable<CancelPolicyResponse>{
    return this.api.cancelPolicy(adhesion);
  }

  listPolicies(listPolicies: ListPolicies): Observable<ListPoliciesResponse> {
    return this.api.listPolicies(listPolicies);
  }

  getContract(): Observable<GetContractResponse> {
    return this.api.getContract();
  }

};
