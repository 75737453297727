
<div-show [showMe]="showError" [nameError]="nameError"></div-show>

<loading [showMe]="loading"></loading>

<modal [modalTitle]="'NoEsCliente'" modalId='modal_noCliente' class="modal modal-noCliente">
  <p>
      Vaya, parece que aún no eres cliente de EVO Tienes que abrir una cuenta para poder continuar la contratación de
       inversiones como segundo titular. ¡Coge tu DNI!
  </p>
  <div class="button-center">
    <div class="equal-buttons">
      <div  class="access" name="button1">
        <a href="https://cuentajoven.evobanco.com/#/cuenta-joven/bienvenido" target="_blank">
          <span>Abrir Cuenta Joven</span>
        </a>
      </div>
      <div  class="access" name="button2">
        <a href="https://v2.cuentainteligente.evobanco.com/#/bienvenido" target="_blank">
          <span>Abrir Cuenta Inteligente</span>
        </a>
      </div>
    </div>
  </div>
</modal>

<div class="banner banner-narrow banner-narrow_red" [ngClass]="{'top0Bmi': isBmi}" *ngIf="bannerRevisa && !hiddeNotification">
  <div class="banner-content">
    <div class="message-container">
      <i class="evo ico-warning-white"></i>
      <span class="banner-message">
          ¡UPS! Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.
      </span>
    </div>
    <!-- <i class="evo close" (click)="hideNotification()"></i> -->
  </div>
</div>

<!-- Datos modificados correctamente -->
<div class="banner banner-todo-ok deviceBanner" *ngIf="!hideBannerNewConsoleClientDataOk">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBannerNewConsoleClientData()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo icon-todo-ok"></i>
      </span>
      <span class="banner-message">
        ¡Todo en orden! Tus datos han sido modificados con éxito.
      </span>
    </div>
  </div>
</div>

<!-- Datos modificados correctamente consola 2.0-->
<div class="banner banner-todo-ok deviceBanner" *ngIf="!hideBannerNewConsoleDocumentation">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBannerNotifyOk()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo icon-todo-ok"></i>
      </span>
      <span class="banner-message">
        ¡Listo! Tu documentación está actualizada.
      </span>
    </div>
  </div>
</div>

<section class="c-header--full">
  <div class="c-header--bginv ">
    <div class="responsive-header">
      <div *ngIf="!isBmi && showSecondStep" class="backbutton" (click)="botonVolver()">
        <i class="evo flecha"></i>
      </div>
      <div *ngIf="!esFondoInteligente && !isBmi" class="page-title">
        <span>FONDOS Y VALORES</span>
      </div>
      <div *ngIf="esFondoInteligente && !isBmi" class="page-title">
        <span>FONDO INTELIGENTE</span>
      </div>
      <div *ngIf="isBmi">
        <span></span>
      </div>
    </div>
    <div class="c-header__wizard">
      <p class="c-header__wizard__title">PERSONALIZACIÓN</p>
      <ul class="c-header__wizard__steps">
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 1}" id="wizard-1">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 2}" id="wizard-2">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 3}" id="wizard-3">
          </li>
          <li [ngClass]="{'wizard__steps__item': true, 'is-completed': step >= 4}" id="wizard-4">
          </li>
      </ul>
    </div>
  </div>
</section>
<form *ngIf="!showError" class="form" [formGroup]="datosForm" action="">
  <div class="main-content">
    <section class="info--container" *ngIf="!showSecondStep">
      <div class="info__banner">
        <header class="info__banner__header">
          <p class="info__banner__title">FORMA PARTE DE UNA GRAN COMUNIDAD</p>
          <img class="info__banner__img" src="./assets/images/banner/banner_1.jpg" alt="Banner">
        </header>
        <footer class="info__banner__footer">
          <ul>
            <li>Toda la actualidad en Invertips</li>
            <li *ngIf="!esFondoInteligente">Encuentra y conoce toda la información sobre fondos y valores.</li>
            <li *ngIf="esFondoInteligente">Consulta el ranking de inversores</li>
          </ul>
        </footer>
        <div class="subBanner">La inversión está sujeta a posibles fluctuaciones en el precio de mercado, por lo que no hay garantía de
            devolución del capital invertido.
        </div>
      </div>

      <div class="info">
        <p class="info-title">CREA UN NICKNAME</p>
        <div class="form__item">
          <input  type="text" 
                  [ngClass]="{'input--secondary': true, 'invalid-nickname': invalidNickname}" 
                  name="nickname" 
                  id="nickname" 
                  placeholder="ej. John Smith"
                  autocomplete="off" 
                  value="" 
                  formControlName="nickname" 
                  required 
                  pattern="([a-z]|[A-Z]|[0-9]){6,12}" 
                  [maxlength]="getMaxLengtNick" 
                  minlength="6" 
                  (focus)="inFocusNickname()">
          <div class="error-text" *ngIf="nicknameExist">
            ¡Ups! Alguien ya ha escogido este nickname. Vuelve a intentarlo con otro.
          </div>
          <div class="error-text" *ngIf="nicknameError">
            ¡Ups! El Nickname debe tener entre 6 y 12 caracteres y no contener espacios.
          </div>
          <div class="error-text" *ngIf="nicknameErrorCaracteres">
            ¡Ups! El Nickname no puede contener caracteres especiales.
          </div>
          <div class="error-text" *ngIf="nickNoValido">
            ¡Ups! El Nickname contiene palabras malsonantes.
          </div>
          <div class="error-text" *ngIf="nickNameIncludes">
            ¡Ups! El Nickname contiene tu nombre o apellido.
          </div>
        </div>
        <div class="info-description_img">
          <i class="evo icon-nickname info-icon"></i>
          <p class="info-subtitle">¿Por qué necesitas un Nickname o alias?</p>
        </div>

        <p *ngIf="esFondoInteligente" class="info-description">El nickname es un alias que utilizaremos para visualizar tu posición en el ranking dentro de la Comunidad del Fondo Inteligente.</p>
        <p *ngIf="!esFondoInteligente" class="info-description">El nickname es un alias que utilizaremos para visualizar tu posición en el ranking dentro de la Comunidad de Fondos y Valores.</p>

        <p class="info-description">Elige sabiamente, no podrás cambiarlo después.</p>
      </div>
      <div class="btn-group_fix">
        <button [disabled]="disableContinueButton || nicknameError || nicknameErrorCaracteres || nickNameIncludes" class="btn-common btn-common_fix btn-common_fix_bold" (click)="toContinue()">
          Continuar
        </button>
      </div>
    </section>
    <section class="info--container" *ngIf="showSecondStep">
      <div class="info__banner">
        <header class="info__banner__header">
          <p class="info__banner__title">LA FORMA MÁS SENCILLA DE INVERTIR</p>
          <img class="info__banner__img" src="./assets/images/banner/banner_3.jpg" alt="Banner">
        </header>
        <footer class="info__banner__footer">
          <ul>
            <li *ngIf="!esFondoInteligente" >Traspasos entre tus fondos sin impuestos</li>
            <li *ngIf="esFondoInteligente" >Sin comisiones de subscripción ni reembolso</li>
            <li *ngIf="esFondoInteligente" >Sin compromiso de permanencia</li>
            <li>Tu dinero siempre disponible</li>
          </ul>
        </footer>
        <div class="subBanner">La inversión está sujeta a posibles fluctuaciones en el precio de mercado, por lo que no hay garantía de
            devolución del capital invertido.
        </div>
      </div>
      <div class="info">
        <p class="info-title">ESCOGE TU CUENTA ASOCIADA</p>
        <p class="info-description">La cuenta asociada es la cuenta donde cargaremos las suscripciones y abonaremos los reembolsos que hagas en tu Cuenta Inversora de Fondos y Valores.
        </p>

        <dropdown-custom  *ngIf="cuentas.length > 1" class="dropdown" id="cuentas" name="cuentas" [limitNames]="false" [options]="cuentas" formControlName="cuentas" autofocus></dropdown-custom>
        <input *ngIf="cuentas.length === 1"
              type="text"
              formControlName="cuentas"
              [ngClass]="{'input--iban': true}" name="cuentas" id="cuentas"
              autofocus
              readonly
        >

        <div class="new-account">
          <div class="form-checkbox">
            <input id="check-new-account" class="i-checkbox" name="checkAddAccount" type="checkbox" value="Acepto"
              (click)="activarST()" formControlName="checkAddAccount">
            <label class="l-checkbox" for="check-new-account">¿Quieres añadir un segundo
              titular?</label>
          </div>
          <div class="group-radio--circle" *ngIf="activarSegundoTitular">
            <div class="group-radio-item" *ngIf="segundoTitular">
              <input type="radio" id="account1" value="1" name="seleccionarCuenta" formControlName="seleccionarCuenta" (click)="setEmail(1)">
              <label id="account1-label" class="button-label" for="account1">
                <span>{{mailSegTitular}}</span>
              </label>
              <br>
              <p class="radio-item-subdescription">Autorizado cuenta asociada seleccionada</p>
              <!-- <p class="radio-item-subdescription">belen@gmail.com </p> -->
            </div>
            <div class="group-radio-item">
              <input type="radio" id="account2" value="2" name="seleccionarCuenta" formControlName="seleccionarCuenta" (click)="setEmail(2)" *ngIf="segundoTitular">
              <label id="account2-label" class="button-label" for="account2" *ngIf="segundoTitular"><span>Otro/a</span></label>
              <div class="group-radio-item__options" *ngIf="showInputEmail">
                <input type="password" name="password_fake" id="password_fake" value="" style="display:none;" />
                <p class="title">E-MAIL DEL SEGUNDO TITULAR</p>
                <div class="form__item">
                  <input type="email" #emailSecond name="email" [ngClass]="{'input--secondary': true}" name="email" id="email" placeholder="Introduce tu email"
                    autocomplete="foo" value="" formControlName="email" required pattern=".+@.+\..+" maxlength="45" (focus)="inFocusEmail()"
                    (blur)="outFocus(emailSecond)">
                    <div class="error-text"
                    *ngIf="datosForm.get('confirmEmail').hasError('notEquals') && datosForm.get('confirmEmail').touched">
                    {{errors.correosNoCoinciden}}
                  </div>
                  <div  class="error-text"
                        *ngIf="isEqualMail() && activarSegundoTitular">
                    {{errors.email2Titulars}}
                  </div>
                  <div class="error-text"
                    *ngIf="datosForm.get('email').hasError('invalid') && datosForm.get('email').touched ||
                    datosForm.get('confirmEmail').hasError('invalid') && datosForm.get('confirmEmail').touched">
                    {{errors.emailValido}}
                  </div>
                </div>
                <p class="title">CONFIRMACIÓN E-MAIL DEL SEGUNDO TITULAR</p>
                <div class="form__item">
                  <input type="email" #confirmSecond onCopy='return false' onPaste='return false' [attr.disabled]="datosForm.get('email').value === '' ? true : null" [ngClass]="{'input--secondary': true}" name="confirmEmail" id="confirmEmail" placeholder="Confirma tu email"
                    autocomplete="foo" value="" formControlName="confirmEmail" required pattern=".+@.+\..+" maxlength="45" (focus)="inFocusEmail()"
                    (blur)="outFocus(confirmSecond)">
                    <div class="error-text"
                    *ngIf="datosForm.get('confirmEmail').hasError('notEquals') && datosForm.get('confirmEmail').touched">
                    {{errors.correosNoCoinciden}}
                  </div>
                  <div class="error-text"
                    *ngIf="datosForm.get('email').hasError('invalid') && datosForm.get('email').touched ||
                    datosForm.get('confirmEmail').hasError('invalid') && datosForm.get('confirmEmail').touched">
                    {{errors.emailValido}}
                  </div>
                  <div  class="error-text"
                        *ngIf="isEqualMail() && activarSegundoTitular">
                    {{errors.email2Titulars}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-description_img">
          <i class="evo icon-eye info-icon"></i>
          <p class="info-description">Por motivos fiscales, si no añades ahora un segundo titular no lo podrás hacer en un futuro.</p>
        </div>
        <div *ngIf="activarSegundoTitular" class="form-checkbox checkbox-conditions">
          <input id="check-conditions" class="i-checkbox" name="checkAcceptConditions" type="checkbox" value="Acepto"
             formControlName="checkAcceptConditions" (click)="modalOpenCondiciones()">
          <label  class="l-checkbox" for="check-conditions">He leído y acepto las <a (click)="modalOpenCondiciones()" class="info__link">condiciones
              legales</a> </label>
          <div *ngIf="checkLegales" class="error-text">Debes leer y aceptar las condiciones legales para continuar con la contratación</div>
        </div>
      </div>

      <modal [modalTitle]="'Condiciones Legales'" [blocking]='true' [modalId]='modalLegal' class='modal'>
        <div class="container">
          <h2>Aceptación de las condiciones legales</h2>
          <p *ngIf="!esFondoInteligente">
            Confirmo haber obtenido el consentimiento para que EVO Banco pueda contactar y tratar lo datos personales de la persona que facilito en este apartado,
             con la finalidad de solicitar la contratación de tu Cuenta Inversora de Fondos y Valores como segundo titular.
          </p>
           <p *ngIf="esFondoInteligente">
            Confirmo haber obtenido el consentimiento para que EVO Banco pueda contactar y tratar lo datos personales de la persona que facilito en este apartado,
             con la finalidad de solicitar la contratación de tu Fondo Inteligente como segundo titular.
          </p>
          <div class="btn-group">
            <button type="button" class="btn-common btn-common_fix_bold legal" (click)="checkCondiciones()">ACEPTAR</button>
          </div>
        </div>
      </modal>
      <div class="btn-group_fix">
        <button class="btn-common btn-common_fix btn-common_fix_bold"
                [disabled] = "isEqualMail()"
                (click)="submit()">
          Continuar
        </button>
      </div>
    </section>

    <section>
      <div *ngIf="esFondoInteligente || !isMobileOrTablet" [ngClass]='esFondoInteligente ? "info-final":"hidden-info-final"'>
        El folleto informativo y el documento de datos fundamentales para el inversor están disponibles en www.cnmv.es</div>
    </section>
    <section class="info--bggrey">
      <div class="info--bggrey__item" *ngIf="esFondoInteligente">
        <p class="info--bggrey__title">PERFIL DE RIESGO</p>
      </div>
      <div class="info--bggrey__item" *ngIf="esFondoInteligente">
        <div class="numericlevel">
          <div class="numericlevel__info">
            <p class="numericlevel__info__title">MENOR RIESGO</p>
            <p class="numericlevel__info__description">Rendimiento</p>
            <p class="numericlevel__info__description">potencialmente menor</p>
          </div>
          <div class="numericlevel__info">
            <p class="numericlevel__info__title">MAYOR RIESGO</p>
            <p class="numericlevel__info__description">Rendimiento</p>
            <p class="numericlevel__info__description">potencialmente mayor</p>
          </div>
          <ul class="numericlevel__list">
            <li class="numericlevel__list__item" *ngFor="let num of numRisks">
              {{num}}
            </li>
          </ul>
           <p class="info--bggrey__description">La categoría "1" no significa que la inversión esté libre de
            riesgo.
          </p>
        </div>
        <p class="numericlevel__info__description">La categoría "1" no significa que la inversión esté libre de
          riesgo.
        </p>
        <p class="info--bggrey__description">El perfil de riesgo señalado es un dato meramente indicativo del riesgo
          del
          fondo y está calculado en base a datos históricos de volatilidad que, no obstante pueden no constituir una
          indicación fiable del futuro perfil de desigo del fondo, por lo que podría variar a lo largo del tiempo.</p>
      </div>
      <div class="info--bggrey__item" *ngIf="esFondoInteligente">
        <p class="info--bggrey__description">Puedes disponer de tu dinero en cualquier momento, en función del valor 
          liquidativo del fondo el día del reembolso. Ten en cuenta que, la retirada del dinero con carácter previo 
          al cumplimiento del horizonte temporal recomendado aumenta el riesgo de pérdida de capital. Los Fondos de Inversión
          EVO tienen indicadores de riesgo que van de 1 a 7, siendo 1 menor riesgo y 7 mayor riesgo. 
          Los folletos explicativos de los productos y los documentos de datos fundamentales para el inversor están disponibles
          en <a>www.cnmv.es</a>. EVO Fondo Inteligente, F.I. (Fondo Inteligente EVO) está registrado en la CNMV con el número 4978. No hay garantía
          de devolución del capital invertido. El indicador de riesgos del Fondo Inteligente está compuesto por la combinación de los
          riesgos de sus compartimentos, uno de Renta Fija con riesgo 1/7 con otro compartimento de Renta Variable riesgo 7/7. 
          El folleto explicativo del producto y el documento de datos fundamentales para el inversor están disponibles en www.cnmv.es 
          Gestora: GESCONSULT, S.A., S.G.I.I.C. Grupo Gestora: GESCONSULT. Depositario: BANCO INVERSIS, S.A., Grupo 
          Depositario: BANCA MARCH. Auditor: Deloitte, S.L. Comercializador.</p>
      </div>
      <div class="info--bggrey__description" *ngIf="!esFondoInteligente">La inversión está sujeta a posibles fluctuaciones en el precio de mercado, por lo que no hay garantía de
        devolución del capital invertido.Los folletos explicativos de los productos y los documentos de datos fundamentales
        para el inversor de ls fondos de inversión donde figura su indicador de Riesgo que puede ser 1 a 7 de menor a mayor
        riesgos, disponibles en www.cnmv.es</div>
    </section>
  </div>
</form>
