import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ErrorService } from './error.service';
import { Modal } from '../collections/modal';
import { MONTHS } from 'app/resources/months.model';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthUserService } from './auth-user.service';
import { SessionStorageService } from './session-storage.service';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class HelperService {

  emailRexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  phoneRexp = /^[6-7][0-9]{8}|8{9}/i;
  phoneRexpWithoutException = /^[6-7][0-9]{8}/i;
  alphanumericRexp = /^[a-z0-9]+$/i;
  dateDDMMYYYSlashRexp = /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i;
  keyboardClose = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public errService: ErrorService,
    private authUserService: AuthUserService,
    private sessionStorageSrv: SessionStorageService,
    private keycloak: KeycloakService
  ) {}

  returnValue(control) {
    if (control.value && control.valid) {
      return control.value
    }
    return undefined
   }

  cleanObjValues(key) {
    if(!key || key =="Desconocido") return '';
    return key;
  }

  navigateTo(nextStep, params?, skipLocation?) {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };
    if(skipLocation){
      navigationExtras.skipLocationChange = true;
      this.router.navigateByUrl(nextStep);
    }else{
      if (!params) {
        this.router.navigate([nextStep], navigationExtras)
      } else {
        this.router.navigate([nextStep, params], navigationExtras);
      }
    }
  }

  updateFieldValidation(form, field, validators = []) {
    form.get(field).setValidators(validators);
    form.get(field).updateValueAndValidity();
  }

  updateFieldValue(form, field, value?) {
    if (value === undefined) { value = ''}
    form.get(field).setValue(value);
    form.get(field).updateValueAndValidity();
  }

  hasEqualsChars(firstControl, secondControl) {
    let valid = true;
    for (let i = 0; secondControl.length > i; i++) {
      if (firstControl[i] !== secondControl[i]) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  isEmpty(obj) {
    if (obj === null || obj === undefined) { return true; }
    return Object.keys(obj).length === 0;
  }

  updateFormStatus(form) {
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsTouched();
      form.get(key).markAsDirty();
      this.errService.mobileErrors(form);
    });
  }

  updateFieldStatus(form, field) {
      form.get(field).markAsTouched();
      form.get(field).markAsDirty();
      this.errService.mobileErrors(form);
  }

  updateFieldsState(form, fields= []) {
    fields.forEach(field => {
      form.get(field).markAsTouched();
      form.get(field).markAsDirty();
    })
  }

  resetFieldsState(form, fields= []) {
    fields.forEach(field => {
      form.get(field).markAsPristine();
      form.get(field).markAsUntouched();
    })
  }

  cleanMultipleValidations(form, fields = []) {
    fields.forEach(field => {
      this.updateFieldValidation(form,field);
    })
  }

  cleanMultipleValues(form,fields = []) {
    fields.forEach(field => {
      this.updateFieldValue(form,field);
    })
  }

  encode(plaintext: string) {
    // The Javascript escape and unescape functions do not correspond
    // with what browsers actually do...
    if (!plaintext) return
    const SAFECHARS = "0123456789" +    // Numeric
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +    // Alphabetic
      "abcdefghijklmnopqrstuvwxyz" +
      "-_.!~*'()";                      // RFC2396 Mark characters
    const HEX = "0123456789ABCDEF";
    let encoded = "";
    for (var i = 0; i < plaintext.length; i++) {
      var ch = plaintext.charAt(i);
      if (ch == " ") {
        encoded += "+";    // x-www-urlencoded, rather than %20
      } else if (SAFECHARS.indexOf(ch) != -1) {
        encoded += ch;
      } else {
        var charCode = ch.charCodeAt(0);
        if (charCode > 255) {
          encoded += "+";
        } else {
          encoded += "%";
          encoded += HEX.charAt((charCode >> 4) & 0xF);
          encoded += HEX.charAt(charCode & 0xF);
        }
      }
    } // for
    return encoded;
  }
  formatDate(date: Date) {
    let month = date.getMonth();
    let monthText = this.formatMonth(date);
    return date.getDate() + " de " + monthText + " de " + date.getFullYear();
  }

   toDate(dateStr) {
    const [day, month, year] = dateStr.split("/");
    return new Date(year, month - 1, day);
  }

  formatDateDayMonthYear(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if(day < 10) {
      day = "0" + day;
    }

    if(month < 10) {
      month = "0" + month;
    }

    return day + "/" + month + "/" + year;
  }

  formatMonth(date: Date) {
    let month = date.getMonth();
    let monthText = MONTHS[month];
    return monthText;
  }

  formatHour(date: Date) {
    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
  }

  convertToUpperCase(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] != null && typeof obj[key] !== 'undefined' &&
      (key !== 'canal' &&
      key !== 'estado' &&
      key !== 'logalty' &&
      key !== 'confirma' &&
      key !== 'electronicID' &&
      key !== 'producto')) {
        if (typeof obj[key] === 'string') {
          obj[key] = obj[key].toUpperCase();
        }
        if (typeof obj[key] === 'object') {
          this.convertToUpperCase(obj[key]);
        }
      }
    });
    return obj;
  }

  cloneAndConvertToUpperCase(obj) {
    let cloneObj = Object.assign({}, obj);
    return this.convertToUpperCase(cloneObj);
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }

  openPDF(pdfBase64) {
    let blob = this.converBase64toBlob(pdfBase64, 'application/pdf');
    let blobURL = URL.createObjectURL(blob);
    let win = window.open(blobURL);
  }

  closeLoginKeyboard(){
    this.keyboardClose.next();
  }

  isAdultNot65Years(fechaNacimiento) {
    const currentDate = new Date();

    const currentDateMilliseconds = currentDate.getTime();
    const birthDate = new Date(fechaNacimiento);
    const birthDateMilliseconds = birthDate.getTime();

    const diffDate = currentDateMilliseconds - birthDateMilliseconds;

    const eighteenYears = 568024200000;
    const sixtyFiveYears = 2051198500000;

    if(diffDate >= eighteenYears && diffDate < sixtyFiveYears) {
      return true;
    }
    return false;
  }

  isAdult(fechaNacimiento) {
    const currentDate = new Date();

    const currentDateMilliseconds = currentDate.getTime();
    const birthDate = new Date(fechaNacimiento);
    const birthDateMilliseconds = birthDate.getTime();

    const diffDate = currentDateMilliseconds - birthDateMilliseconds;
    const eighteenYears = 568024200000;

    if(diffDate >= eighteenYears) {
      return true;
    }
    return false;
  }

  isOld(fechaNacimiento) {
    const currentDate = new Date();

    const currentDateMilliseconds = currentDate.getTime();
    const birthDate = new Date(fechaNacimiento);
    const birthDateMilliseconds = birthDate.getTime();

    const diffDate = currentDateMilliseconds - birthDateMilliseconds;
    const eightyYears = 2366820000000;

    if(diffDate >= eightyYears) {
      return true;
    }
    return false;
  }

  //dd/mm/yyyy
  isValidDate(sDate:string){
    return this.dateDDMMYYYSlashRexp.test(sDate);
  }

  isFutureDate(date){
    let today = new Date();
    return today <= date;
  }

  stringToDate(sDate:string){
    if (sDate.indexOf("/")  === -1){
      return new Date(sDate);
    } else {
      return new Date(this.toDate(sDate));
    }
  }

   //Formatea fecha de dd/mm/yyyy a yyyy-mm-dd
   toYYYYMMDD(fecha){
    let format = "";
    if(fecha && fecha !== undefined){
     format =  fecha.split("/").reverse().join("-");
    }
    return format;
  }

  toDDMMYYYY(fecha){
    let format = "";
    if(fecha && fecha !== undefined){
     format =  fecha.split("-").reverse().join("/");
    }
    return format;
  }



  formatFecha(date : Date){

    if(date){
     date = new Date(date);
     const day = date.getDate().toString().length === 1 ? '0' + date.getDate().toString() : date.getDate().toString();
     let month = (date.getMonth() + 1).toString();
     month = month.toString().length === 1 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
    return `${day}/${month}/${date.getFullYear()}`;
    }
  }


  padWith(number, minLength = 4, symbol = '0') {
    number = number + '';
    return number.length >= minLength ? number : new Array(minLength - number.length + 1).join(symbol) + number;
  }

  goToConsola(codProductoContratado, idCaso, redirectUrl) {
    const redirectUrlEncoded = encodeURIComponent(redirectUrl);
    // tslint:disable-next-line: max-line-length
    let params = `codProductoContratado=${codProductoContratado}&numCaso=${idCaso}&redirectUrl=${redirectUrlEncoded}`
    if (this.sessionStorageSrv.getItem('isBMI') === 'true') {
      params += '&origen=bmi';
    }
    if (this.sessionStorageSrv.getItem('isSecondSigner') === 'true') {
      params += '&isSecondSigner=true';
    }

    // Si el login se ha hecho a través del Keycloak, directamente se realiza la redirección a la url de Consola.
    if (this.keycloak.getKeycloakInstance().authenticated) {
      window.location.href = `${environment.consoleUrl}?${params}`;
    }
    else {
      const ssoUrl = this.sessionStorageSrv.getItem('ssoUrl') === 'true';
      // Parámetros SSO
      if (sessionStorage.getItem('refresh_token') && ssoUrl) {
        params += '&token=' + sessionStorage.getItem('auth_token') +
          '&refresh=' + sessionStorage.getItem('refresh_token');
      }
      // Si no, se llama al servicio que obtiene la url de redirección y genera las cookies necesarias.
      let isBMI = this.sessionStorageSrv.getItem('isBMI') === 'true';
      this.authUserService.getConsoleRedirection(params)
        .subscribe(data => {
          window.location.href = data;
        },
          _error => {
            this.errService.modDataError = true;
            this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
          });
    }
  }
  clearQueryParams(){
      // Remove query params
    this.router.navigate([], {
      queryParams: {
        'uuid': null,
        'usuarioBE': null,
        'token': null,
        'refresh': null,
        'codProductoContratado': null
      },
      queryParamsHandling: 'merge'
    });
  }
}
