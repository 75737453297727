<div class="clickKb" (click)="preventClose($event)">
  <div class="numb" (click)="addThis($event)">{{matrix[0]}}</div>
  <div class="numb" (click)="addThis($event)">{{matrix[1]}}</div>
  <div class="numb" (click)="addThis($event)">{{matrix[2]}}</div>
  <div class="numb" (click)="addThis($event)">{{matrix[3]}}</div>
  <div class="numb" (click)="addThis($event)">{{matrix[4]}}</div>
  <div class="numb" (click)="addThis($event)">{{matrix[5]}}</div>
  <div class="numb" (click)="addThis($event)">{{matrix[6]}}</div>
  <div class="numb" (click)="addThis($event)">{{matrix[7]}}</div>
  <div class="numb" (click)="addThis($event)">{{matrix[8]}}</div>
  <div class="numb clear" (click)="removeLast($event)">CORREGIR</div>
  <div class="numb" (click)="addThis($event)">{{matrix[9]}}</div>
  <div class="row text-left">
    <span (click)="close($event)">CERRAR</span>
  </div>
</div>
