import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preload-logalty',
  templateUrl: './preload-logalty.component.html',
  styleUrls: ['./preload-logalty.component.scss']
})
export class PreloadLogaltyComponent implements OnInit {

  selected = 0;

  slides = [
    "Te vamos a dirigir a la página de nuestro tercero de confianza para que puedas contratar tu seguro.",
    "A partir de este momento podrás realizar todas tus contrataciones y sólo pagarás por los días y horas que selecciones."    
  ];

  constructor() { }

  ngOnInit() {
    if(document.getElementById('user-path')){
      document.getElementById('user-path').classList.remove('paddingHeader');
      document.getElementById('user-path').classList.add('paddingLogaltyHeader');
    }  
    setTimeout(() => {
      if(this.selected == 0) {
        this.selected = 1;
      } else {
        this.selected = 0;
      }
    }, 7000);
  }

  setSlideClass(index) {
    //Primera slide
    if(index == 0) {
      if(this.selected != 0) {
        return 'translate';
      }
    } else {
      if(this.selected != 0) {
        return 'translate0';
      } else {
        return 'translate2';
      }
    }

    return '';
  }

}
