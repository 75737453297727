import { Injectable } from '@angular/core';

@Injectable()
export class DeviceService {


  private mobile: boolean = false;
  private iPhone: boolean = false;
  private iDevices = [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
              ];
  private testExpMobile = new RegExp('Android|webOS|iPhone|' +
    		       'BlackBerry|Windows Phone|'  +
    		       'Opera Mini|IEMobile|Mobile' ,
              'i');

  private testExpMobileOrTablet = new RegExp('Android|webOS|' +
              'iPhone|iPad|iPod' +
              'BlackBerry|Windows Phone|'  +
              'Opera Mini|IEMobile|Mobile' ,
              'i');   

  private testExpIPad = new RegExp('iPad','i');
  
  private testExpAndroid = new RegExp('Android','i'); 

  isMobile () {
    this.mobile = this.testExpMobile.test(navigator.userAgent);
    return this.testExpMobile.test(navigator.userAgent) ? true: false;
  }

  isMobileOrTablet () {
    this.mobile = this.testExpMobile.test(navigator.userAgent);
    return this.testExpMobileOrTablet.test(navigator.userAgent) ? true: false;
  }

  getDevice() {
    this.mobile = this.testExpMobile.test(navigator.userAgent);
    return this.testExpMobile.test(navigator.userAgent) ? 'Móvil' : 'Desktop';
  }

  getDeviceExtended(){

    if (this.isTablet()){
      return 'Tablet';
    } else if (this.testExpMobile.test(navigator.userAgent)) {
      this.mobile = true;
      return 'Móvil';
    } else {
      return 'Desktop';
    }
  }

  isIphone () {
    if (!!navigator.platform) {
      while (this.iDevices.length) {
        if (navigator.platform === this.iDevices.pop()){ return true; }
      }
    }
    return false;
  }

  isTablet () {
    let isIPad = this.testExpIPad.test(navigator.userAgent);
    let isAndroidAndTabletWidth = this.testExpAndroid.test(navigator.userAgent) && screen.width >= 425 ;
    return isIPad || isAndroidAndTabletWidth;
  }

  // Return undefined if it's not iphone/ipad/ipod or doesn't have userAgent version
  getIphoneVersion (){
      let userAgent = navigator.userAgent;
      if(userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('iPod')){
        let firstDivision = userAgent.split(' OS ');
        if(firstDivision.length > 1){
          let secondDivision = firstDivision[1].split(' ');
          if(secondDivision.length > 0){
            let version = secondDivision[0].split('_');
            if(version.length > 0) {
              let v = Number(version[0]);
              return v;
            }
          }
        }
      }
      return undefined;

  }
}
