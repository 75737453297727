<!-- <loading [showMe]="loading"></loading> -->

<loading-custom [showMe]="loading" 
                [titulo]="titulo" 
                [imagen]="imagen"
                [subtitulo]="subtitulo"
                [boton]="boton">
</loading-custom>
 <div-show [showMe]="showError" [nameError]="nameError"></div-show>
 <!-- Error mo se puede seguir con la solicitud -->
<div class="banner banner-narrow banner-narrow_red" [hidden]="!this.otpDetails.otpDetailsForm.controls.smsphone.touched || otpDetails.otpDetailsForm.controls.smsphone.valid">
    <div class="banner-content">
      <div class="message-container">
        <i class="evo ico-warning-white"></i>
        <span class="banner-message">
            El código no es válido. Debes introducir los 6 dígitos recibidos por SMS.
        </span>
      </div>
    </div>
  </div>
 <otp-details-profile [paramsSMS]="paramsSMS" 
             [isLogged]="true" 
             [allowEditPhone]="false"
             [ofuscatePhone]="true"
             (eventSendCode)="onSendCode($event)" 
             (eventResendSMS)="onResendSMS($event)" 
             c="6"
             (eventSendSMS)="onSendSMS($event)"><otp-details-profile> 



                          