import { Component, Input,ViewChild, OnDestroy ,OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute, Params  } from '@angular/router';
import { UpdateDniComponent} from '../../modules/profile/update-dni/update-dni.component';

@Component({
  selector: 'loading-custom',
  templateUrl: './loading-custom.component.html',
  styleUrls: ['./loading-custom.component.scss']
})
export class LoadingCustomComponent implements OnDestroy {
  //public showMe: boolean;

  @ViewChild(UpdateDniComponent) updateDni: UpdateDniComponent;
  public EdgeClass: boolean = this.checkNavigator();
  public mostrarBoton: boolean = false;
  public param;
  public showHeader = true;
  public showFooter = true;

  constructor(private activatedRoute: ActivatedRoute) { }

  @Input('showMe') _showMe: boolean;
  
  @Input('imagen') imagen;
  @Input('titulo') titulo;
  @Input('subtitulo') subtitulo;
  @Input ('boton') boton;

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      //console.log("parametros "+JSON.stringify(params));
      if (params.origen) {
        this.param = params.origen.toLowerCase();
        //sessionStorage.setItem("subidaDocumentacion", "true");
        if (this.param === "bmi") {
          this.showHeader = false;
        }
      }
    });



    if(this.boton !== ""){
      this.mostrarBoton = true;
    }
  }

  @Input()
  public set showMe(value: boolean) {
    console.log("LoadingCustomComponent",value);
    if (value) {
      //document.body.classList.add('pointer-events');
      document.documentElement.classList.add('noscroll');
    } else {
     // document.body.classList.remove('pointer-events');
      document.documentElement.classList.remove('noscroll');
    }
    this._showMe = value;
    console.log(this.titulo);
  }

  
  
 public get showMe(): boolean {
  return this._showMe;
}

  ngOnDestroy() {
    document.body.classList.remove('pointer-events');
  }

  checkNavigator() {
    const UA = ["Edge", "MSIE"];
    const UA_IE11 = ["Mozilla/5.0", "Windows NT", "Trident/7.0", "rv:11.0", "like Gecko"];
    let count = 0;
    //Edge y Explorer 10
    for (var i = 0; i < UA.length; i++) {
      if (navigator.userAgent.indexOf(UA[i]) > -1) return true;
    }
    //Explorer 11
    for (var i = 0; i < UA_IE11.length; i++) {
      if (navigator.userAgent.indexOf(UA_IE11[i]) == -1) return false;
      count++;
    }
    if (count == UA_IE11.length) return true;
    return false;
  };

  onSubmit(){

    if(this.boton == "Reintentar"){
      this.updateDni.tryAgain();
    }
  }


}
