import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cinv-header',
  templateUrl: './cinv-header.component.html',
  styleUrls: ['./cinv-header.component.scss']
})
export class CinvHeaderComponent implements OnInit {

  @Input() showProductHeader: boolean | undefined;

  constructor() { }

  ngOnInit() {
  }

}
