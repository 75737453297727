import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { MyProductsService } from 'app/services/my-products.service';
import { HelperService } from 'app/services/helpers.service';
import { ModalService } from 'app/services/modal.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { SessionStorageService } from 'app/services/session-storage.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'documentation-investment-account',
  templateUrl: './documentation-investment-account.component.html',
  styleUrls: ['./documentation-investment-account.component.scss'],
  providers: [ModalService]
})

export class DocumentationInvestmentAccountComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() button: string;
  @Input() document: string;

  public clientID;
  public loading;
  public showSecondStep = true;
  public cuentas: Array<any> = [];
  public cuentasCliente: any;
  public numRisks: number[] = [1, 2, 3, 4, 5, 6, 7];
  public esFondoInteligente = sessionStorage.getItem('productoContratando') === '010' ? true : false;

  stateCurrent = States.enviada;
  currentProduct: any;
  fullName: string;
  address: string;
  ACCOUNTS = ACCOUNTS;
  currentDocument: any;
  showNoCatDocuments: any;

  constructor(private fb: FormBuilder,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService,
    public sessionStorageSrv: SessionStorageService) {
  }

  ngOnInit() {
    this.currentDocument = sessionStorage.getItem('currentDocument');
    this.showNoCatDocuments = sessionStorage.getItem('showNoCatDocuments') === 'true';
  }

  ngAfterViewInit() {
    this.currentDocument = sessionStorage.getItem('currentDocument');
    this.showNoCatDocuments = sessionStorage.getItem('showNoCatDocuments') === 'true';
    this.triggerInvesmentNavData();
  }

  acceptTerms() {
    const currentDocument = sessionStorage.getItem('currentDocument');
    sessionStorage.setItem(currentDocument, 'true');
    this.helpers.navigateTo('visor-cuenta-inversora');
  }

  botonVolver() {
    this.helpers.navigateTo('visor-cuenta-inversora');
  }

  triggerInvesmentNavData() {
    let pageName = '';
    const isSecondSigner = sessionStorage.getItem('isSecondSigner') === 'true';
    const hasidClienteEvo = sessionStorage.getItem('idClienteEvo') ? sessionStorage.getItem('idClienteEvo') : '';
    const clientId = JSON.parse(sessionStorage.getItem('userInfo')).id_salesforce ? JSON.parse(sessionStorage.getItem('userInfo')).id_salesforce : hasidClienteEvo;
    switch (this.currentDocument) {
      case 'infoMifid':
        pageName += 'Documentación/Información Mifid';
        break;
      case 'poliConflic':
        pageName += 'Documentación/Política Conflicto de Interés ';
        break;
      case 'poliOpti':
        pageName += 'Documentación/Política Óptima Ejecución';
        break;
      case 'poliSalva':
        pageName += 'Documentación/Política Salvaguarda de Activos';
        break;
      case 'poliIncen':
        pageName += 'Documentación/Política Incentivos';
        break;
      case 'folleto':
        pageName += 'Documentación/Folleto Tarifa Valores';
        break;
      case 'condiciones':
        pageName += 'Documentación/Condiciones legales';
        break;
      case 'consentimiento':
        pageName += 'Documentación/Consentimiento comunicaciones';
        break;

      default:
        break;
    }

    this.analytics.triggerInvestmentDataNavDataNew(pageName, clientId);
  }
}
