import { Component } from '@angular/core';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-error-less-25years',
  templateUrl: './error-less-25years.component.html',
  styleUrls: ['./error-less-25years.component.scss']
})
export class ErrorLess25yearsComponent {
  name:string;
  constructor(
    public analytics: AnalyticsService,
    public helpers: HelperService
  ) { }

public goToCI() {
    this.analytics.triggerInteractionData('Error - Mayor de 25', 'Ir a la Cuenta Inteligente');
    window.location.href = 'https://www.evobanco.com/cuenta-inteligente/';
  }

public goToLanding() {
  this.analytics.triggerInteractionData('Error - Mayor de 25', 'Salir')
    this.helpers.navigateTo('/cuenta-joven/bienvenido');
  }
}
