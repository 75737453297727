export const ANTIGUEDAD: Array<object> = [
  { name : 'Antigüedad en la empresa actual', value :  null},
  { name : 'Menos de 3 meses', value :  0.01},
  { name : 'Entre 3 y 6 meses', value :  0.3},
  { name : 'Entre 9 meses y 1 año', value :  0.9},
  { name : '1 año', value :  1},
  { name : '2 años', value :  2},
  { name : '3 años', value :  3},
  { name : '4 años', value :  4},
  { name : '5 años', value :  5},
  { name : '6 años', value :  6},
  { name : '7 años', value :  7},
  { name : '8 años', value :  8},
  { name : '9 años', value :  9},
  { name : '10 años', value :  10},
  { name : '11 años', value :  11},
  { name : '12 años', value :  12},
  { name : '13 años', value :  13},
  { name : '14 años', value :  14},
  { name : '15 años', value :  15},
  { name : '16 años', value :  16},
  { name : '17 años', value :  17},
  { name : '18 años', value :  18},
  { name : '19 años', value :  19},
  { name : 'Más de 20 años', value :  20}
];
