import { Simulador } from "app/collections/simulador";

export var SIMULADO: Simulador = {
  id: 0,
  clientId: '',
  canal: "desktop",
  estado: "simulador",
  producto: "microseguros",
  emailMicroseguros: '',
  IBAN1: '',
  configure: {
    actividad: '',
    duration: '',
    region: '',
    capital: '',
    cfAmount: '',
    cipAmount: '',
    crcAmount: '',
    timezone: '',
    cobertura: '',
    date: { value: undefined, date: null, time: 0 },
    totalPrice: 0 
  },
  beneficiary: {
    tipo: '',
    beneficiario: {
      nombre: '',
      apellidos: '',
      documento: ''
    }
  },
  methodpayout: {
    id: '',
    tipo: '',
    iban: '',
    disponible: 0,
    condicion: '',
    mfaId: ''
  },
  requestId: ''
};
