import { Notification } from '../collections/notification';

export const NOTIFICATIONS: Notification[] = [
    {
        id: 1,
        title:"Descárgate nuestra Banca Móvil",
        content: `<b>Nuestra app de Banca Móvil lo tiene todo para hacerte la vida más fácil</b>
                  <p>¿Aún no la tienes? Pues no sabes lo que te estás perdiendo. Es una de la apps más valoradas e innovadoras del mercado y con ella:</p>
                  <ul>
                    <li class='no-list'>Podrás hacer transferencias nacionales, tener siempre a mano tus operaciones favoritas, sacar dinero del cajero solo con tu móvil (Hal Cash) y muchas cosas más.</li>
                    <li class='no-list'>Tendrás todos tus movimientos registrados, podrás consultar tu número PIN, bloquear tus tarjetas...</li>
                  </ul>
                  <p>Además, es tan fácil de usar que podrás hacerlo con los ojos cerrados aunque, claro, te perderías lo bonita que es.
                  <br><br>Descárgatela aquí:</p>`,
        date: ""
    },
    // {
    //     id: 2,
    //     title:"Actualiza tus datos",
    //     content: `<b>Actualiza tus datos</b>
    //     <p>Es muy importante que mantengas todos tus datos actualizados correctamente. Si bien, hay datos más sensibles que otros a los que es recomendable prestar atención especial. Por ejemplo:</p>
    //     <b>Correo electrónico de contacto</b>
    //     <p>El correo electrónico es nuestro canal principal de comunicación contigo, si está erróneo no podremos avisarte y podría suponer un perjuicio para ti.</p>
    //     <b>Dirección postal</b>
    //     <p>Ya sabes que no somos de escribir cartas, pero necesitamos una dirección postal válida para poder enviarte tu tarjeta a casa.</p>
    //     <b>Teléfono</b>
    //     <p>En él recibirás avisos para estar al día de lo que pasa en tus cuentas, nos sirve como refuerzo de seguridad. Además, si necesitáramos contactar contigo sería lo más rápido para localizarte.</p>
    //     <b>Para comprobar que tus datos son correctos, solo tienes que ir a evobanco.com > Acceso clientes > Mi Perfil > Datos personales</b>
    //   `,
    //     date: ""
    // },
    {
        id:3,
        title:"Comprueba que tienes los 10 puntos",
        content: `<b>Tu Cuenta Inteligente es más inteligente que nunca</b>
          <p>
            Ya sabes que tan solo <b>10 puntos son suficiente para tener todas las ventajas de tu Cuenta Inteligente que sin que te cueste un solo euro.</b><br><br>
            Si quieres saber cuántos puntos tienes actualmente, solo tienes que entrar en tu <b>Banca Móvil y comprobarlo en la sección “Mi perfil”.</b>           
          </p>`,
        date: ""
    }
];
