<message-iban-load *ngIf="messageLoading"></message-iban-load>

<mobile-error-zone
*ngIf="errService.errorsArray.length > 0"
[errors]="errService.errorsArray">
</mobile-error-zone>

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<modal [modalTitle]="'documentDetail'" [blocking]='false' [modalId]='detailModal'>
  <div class="container">
    <div class="center-image">
      <img *ngIf='image[documentSide]' src="{{image[documentSide]}}" alt="">
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]='friendlyModal' class='modal'>
  <div class="image-modal"></div>
  <div class="false-container">
    <h2>{{friendlyTitle}}</h2>
    <p>{{friendlyMessage}}</p>
  </div>
</modal>

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='incorrectFormatModal' class='modal'>
  <div class="container">
    <h2>{{incorrectTitle}}</h2>
    <p class="invalid-text">{{incorrectText}}</p>
  </div>
</modal>

<modal [modalTitle]="'Guías para sacar las fotos de forma correcta:'" [blocking]='false' [modalId]='modalIbanSpecs'>
  <div class="list-container">
      <strong>1. ENCUADRE Y FONDO</strong><p style='color: #999; line-height: 25px; margin-bottom:10px'>Toma la foto sobre un fondo liso (sin objetos ni fondo) y evita cortar información del documento.</p>
      <strong>2. ILUMINACIÓN</strong><p style='color: #999; line-height: 25px; margin-bottom:10px'>Asegúrate de hacer la foto con buena iluminación, evitar brillos y desactivar el flash de tu cámara.</p>
      <strong>3. NITIDEZ Y CALIDAD DE LA IMAGEN</strong><p style='color: #999; line-height: 25px; margin-bottom:10px'>Asegúrate de que la foto no este borrosa y que la resolución de la misma no sea inferior a 2 megapixeles ni supere 15MB de tamaño.</p>
      <strong>4. DOCUMENTO ORIGINAL</strong><p style='color: #999; line-height: 25px; margin-bottom:10px'>La foto debe ser de tu DNI o Tarjeta de residencia original, evita fotocopias o fotos a pantallas.</p>
  </div>
</modal>

<modal [modalTitle]="'Excepcion caducado'" [blocking]='true' [modalId]='exceptionModal' class='modal not-adult exception-modal' >
  <h2>{{exceptionTitle}}</h2>
  <p>
    {{exceptionText}}
  </p>
</modal>

<modal [modalTitle]="'Excepcion Blanco Negro'" [blocking]='true' [modalId]='blackWhiteModal' >
  <h2>{{exceptionTitle}}</h2>
  <p>
    {{exceptionText}}
  </p>
  <div class="equal-buttons">
    <div  class="access center" name="entendido" (click)="removeImages()">
      <span>Entendido</span>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Documento'" [blocking]='false' [modalId]='incompleteModal'>
  <div class="container">
    <h2>Ups!</h2>
    <p class='invalid-text'>
       Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.
    </p>
  </div>
</modal>


<!-- <modal [modalId]='modal_problems' class='modal'>
  <div class="image-modal"></div>
  <div class="false-container">
    <h2>Ups!</h2>
    <p>
    Ahora mismo no podemos identificarte.Inténtalo con la videoidentificación o facilitándonos tu IBAN. O si lo que prefieres es continuar más tarde, te enviaremos un email para que puedas continuar donde lo dejaste.
    </p>
    <span class="secondary-text">Llámanos al 910 900 900</span>
    <div class="button-center">
      <div class="equal-buttons">
        <div  class="access" name="button1">
          <a href="http://www.evobanco.com/donde-estamos/" target="_blank">
            <span>PROBAR CON VIDEOIDENTIFACIÓN</span>
          </a>
        </div>
        <div  class="access" name="button1">
          <a href="http://www.evobanco.com/donde-estamos/" target="_blank">
            <span>PROBAR CON IBAN</span>
          </a>
        </div>
      </div>
      <p>Continuar más tarde</p>
    </div>
  </div>
</modal> -->

<modal [modalTitle]="''" [blocking]='modalBlock' [modalId]='modal_ko' class='modal'>
  <div class="container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <div class="equal-buttons">
      <div *ngIf='link != ""' class="access center" name="continuar" (click)="tryAgain()">
        <span>{{link | uppercase}}</span>
      </div>
    </div>
  </div>
</modal>

<modal [modalTitle]="'¿Qué es el IBAN?'" [blocking]='false' [modalId]='modalIbanInfo'>
    <div class="container">
      <div class="left-container">
          <h2><span class="icon-span m"><i class="evo ico-info-blue"></i></span></h2>
      </div>
      <div class="right-container">
        <div class="modal-info-iban">
          <h2>¿Qué es el IBAN?</h2>
          <p>
            Por lo general el número IBAN aparece en la parte frontal de tu libreta bancaria, en el contrato que firmaste y en la información
            de tu cuenta en la banca online / app móvil de tu otro banco.
          </p>
          <p>Tiene una estructura:</p>
          <p><strong>IBAN ESXX XXXX XXXX XXXX XXXX XXXX</strong></p>
        </div>
      </div>
    </div>
</modal>

<div class="identifyIban" drag (filesChangeEmitter)="onFileParent($event)">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>IDENTIFICACIÓN IBAN <span class="icon-span m" (click)='showInfo()'><sup><i class="evo ico-info-blue"></i></sup></span></h2>
        <div class="row">
          <p class="flex-text">
            <span class="icon-span m"><i class="evo calendar"></i></span> 
            1. Introduce el número de cuenta de otro banco del que seas titular.</p>
        </div>
      </legend>
    </div>
    <form [formGroup]='identifyIbanForm' class="flex-form" (ngSubmit)='onSubmit()'>
      <div class="row input" #identify>
          <div class="mobile-container relative">
            <span class="false-placeholder">ES</span>
            <input type="tel"
            placeholder="00 0000 0000 0000 0000 0000"
            class='iban'
            iban-input
            formControlName="iban" name="iban"
            maxlength="27"
            autocomplete="off">
            <div class="error-text" *ngIf="identifyIbanForm.get('iban').invalid">
              {{errService.desktopErrors(identifyIbanForm.controls.iban, 'iban')}}
            </div>
          </div>
          <span
          class="check-container">
          <i [ngClass]="ibanValidClass" class="evo"></i>
        </span>
      </div>
    </form>
    <div class="row" *ngIf='ibanValid'>
      <p class="uppercase-info no-top flex-text" (click)='resetIban()'>
        Cambiar Iban
      </p>
    </div>
    <div  *ngIf='ibanValid'>
      <div class="row">
        <p class="flex-text">
          <span class="icon-span m"><i class="evo dni"></i></span>
          2. Sube las fotos de tu DNI o Tarjeta de residencia en vigor por ambas caras</p>
      </div>
      <div class="row info">
        <p class="uppercase-info flex-text" (click)='showSpecs()'>
          <span class="icon-span s"><i class="evo info"></i></span>
          Ver especificaciones de las imágenes</p>
      </div>
      <div *ngIf='upload.front' class="row upload">
        <div class="image-container" (click)="openDetail(); documentSide = 'front'">
          <i class="evo lupa"></i>
          <img class="preview" *ngIf='image.front' src="{{image.front}}" alt="">
        </div>
        <p>Parte delantera</p>
        <span *ngIf="identifyIbanForm.controls.iban.valid && !identifyIbanForm.controls.iban.untouched"
        class="icon-container">
          <i class="evo ok"></i>
        </span>
        <div class="flex-edit">
          <p class="uppercase-info" (click)="openDetail(); documentSide = 'front'">
            Ver
          </p>
          <label for="editFront">
            <p (click)='resetFrontImage()' class="uppercase-info">
              editar
            </p>
            <input class="transparent" (change)='updateFrontImage($event)' type="file" id="editFront" #back/>
          </label>
        </div>
      </div>
      <div *ngIf='!upload.front' class="row">
        <label for="frontFile">
          <div drag id="front" (filesChangeEmitter)="onFilesChange($event)" class='draggable'>
            <div class="drag-container">
              <input class="transparent" (change)='updateFrontImage($event)' type="file" id="frontFile" #front/>
              <i class="evo arrow"></i>
              <p>{{frontDNI}}</p>
              <a> o cárgala desde tu galería</a>
            </div>
          </div>
        </label>
      </div>
      <div *ngIf='upload.back' class="row upload">
        <div class="image-container" (click)="openDetail(); documentSide = 'back'">
          <i class="evo lupa"></i>
          <img class="preview" *ngIf='image.back' src="{{image.back}}" alt="">
        </div>
        <p>Parte trasera</p>
        <span *ngIf="identifyIbanForm.controls.iban.valid  && !identifyIbanForm.controls.iban.untouched"
        class="icon-container">
          <i class="evo ok"></i>
        </span>
        <div class="flex-edit">
          <p class="uppercase-info" (click)="openDetail(); documentSide = 'back'">
            Ver
          </p>
          <label for="editBack">
            <p (click)='resetBackImage()' class="uppercase-info">
              editar
            </p>
            <input class="transparent" (change)='updateBackImage($event)' type="file" id="editBack" #back/>
          </label>
        </div>
      </div>
      <div *ngIf='!upload.back' class="row">
        <label for="backFile">
          <div drag id='back' (filesChangeEmitter)="onFilesChange($event)"
          class='draggable'>
            <div class="drag-container">
              <input class="transparent" (change)='updateBackImage($event)' type="file" id="backFile" #back/>
              <i class="evo arrow"></i>
              <p>{{backDNI}}</p>
              <a> o cárgala desde tu galería</a>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div class="btn-group">
        <button (click)='onSubmit()' type="submit" class="btn-common btn-common_fix_bold">CONTINUAR</button>
    </div>

   <!-- formulario valido : {{identifyDetailsForm.valid}}
  {{identifyDetailsForm.value | json}} -->
  </div>
</div>
