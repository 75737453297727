<div class="slider-container" [ngClass]='microseguros? "full-width" : ""'>
  <div (mouseenter)="pause()" (mouseleave)="play()" class="carousel slide">
        <h3>{{title}}</h3>
        <h4>{{text}}</h4>
        <ol class="carousel-indicators" [hidden]="slides.length <= 1">
           <li *ngFor="let slidez of slides" [class.active]="slidez.active === true" (click)="select(slidez)"></li>
        </ol>
        <div class="carousel-inner"><ng-content></ng-content></div>

      </div>
</div>
