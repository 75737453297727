import { CamposConsola } from '../collections/campos-consola';

export var CAMPOSCONSOLA: CamposConsola[] = [


  { name : 'codigoEstadoCivil', value : 'estadoCivil'},
  { name : 'codigoDomicilioTipoVia', value : 'tipoVia'},
  { name : 'nombreVia', value : 'direccion'},
  { name : 'domicilioNumero', value : 'numero'},
  { name : 'codigoPostal', value : 'codPostal'},
  { name : 'nombreProvinciaDomicilio', value : 'residenceProvince'},
  { name : 'nombreLocalidad', value : 'residenceLocality'},
  { name : 'situacionVivienda', value : 'situacionVivienda'},
  { name : 'codigoSituacionLaboral', value : 'situacionLaboral'},
  { name : 'codigoCNO', value : 'tipoActividad'},
  { name : 'codigoCNAE', value : 'cnae'},
  { name : 'fechaAltaAutonomo', value : 'fechaAltaAutonomo'},
  { name : 'profesion', value : 'profesion'},
  { name : 'numeroPagas', value : 'numeroPagas'},
  { name : 'nombreEmpresa', value : 'nombreEmpresa'},
  { name : 'codigoContrato', value : 'tipoContrato'},
  { name : 'ingresosMensuales', value : 'ingresosMensuales'},
  { name : 'fechaAntiguedadLaboral', value : 'antiguedadLaboral'},
  { name : 'anioAdquisicion', value : 'adquisicion'},
  { name : 'cuotaAlquilerMensual', value : 'alquilerVivienda'},
  { name : 'codigoRegimenEconomico', value : 'regEconomico'},
  
];
