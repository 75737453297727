export class Otp {
  id : string = "";
  message : string;
  operationErrorCode : number;
  operationErrorMessage : string;
  operationErrorTrace : string;
  product : string = "";
  sent : boolean;
  telephone : string = "";
  userId : string = "";
  validated : boolean;

  constructor(values : Object = {}){
    Object.assign(this, values);
  }
}
