<div *ngIf="!isBmi">
  <header-profile></header-profile>
</div>
<div-show [showMe]="showError" [nameError]="nameError"></div-show>
<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>
<div class="logalty">
  <div class="image"></div>
  <form action="{{logaltySamlAction}}" method="POST" target="_self">
    <input type="hidden" name="saml_assertion" />
  </form>
</div>
