import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { ActivatedRoute, Router } from '@angular/router';
import { MyProductsService } from 'app/services/my-products.service';
import { LoginService } from 'app/services/login.service';
import { AuthService } from 'app/services/auth.service';
import { AuthUserService } from '../../../services/auth-user.service';
import { SecondHolderService } from '../../../services/second-holder.service';
import { GetContractResponse } from '../../../collections/get-contract';
import { HelperService } from '../../../services/helpers.service';
import { ModalService } from '../../../services/modal.service';
import { Modal } from 'app/collections/modal';
import { AnalyticsService } from '../../../services/analytics.service';
import { ACCOUNTS } from '../../../resources/account.model';
import { User } from '../../../collections/user';

@Component({
  selector: 'obligations-investment-account',
  templateUrl: './obligations-investment-account.component.html',
  styleUrls: ['./obligations-investment-account.component.scss'],
  providers: [ModalService]
})
export class ObligationsInvestmentAccountComponent implements OnInit {

  public clientID;
  private userData: User;
  private user;
  private ibanCC;
  private ibanCD;
  public loading;
  public showSecondStep: boolean = false;
  public cuentas: Array<any> = [];
  public cuentasCliente: any;
  modal = new Modal();
  exceptionMsg = 'No es posible que sigamos con el proceso de contratación si tributas fuera de España. Para más información, llámanos:';
  telf = '9109000090';
  txtButtonModal = 'CERRAR Y VOLVER';
 
  stateCurrent = States.enviada;
  currentProduct:any;
  fullName:string;
  address:string;
  ACCOUNTS = ACCOUNTS;
  
  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private secondHolderSrv: SecondHolderService,
    private loginSrv: LoginService,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    private authUserService: AuthUserService,
    private helpersService: HelperService,
    private authService: AuthUserService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService) {
    //this.createForm();
  }

    ngOnInit() {
      /*
      this.cuentasCliente = JSON.parse(sessionStorage.getItem("userData")).listaContratados;
      console.log(this.cuentas);
      for(let i = 0; i < this.cuentasCliente.length; i++){
        this.cuentas.push({
          'value':  this.cuentasCliente[i].ibanCC,
          'name':  this.cuentasCliente[i].nombreProducto.concat(" - " + this.cuentasCliente[i].ibanCC)
        });
      }*/
      this.initModal();
    }

    initModal() {
      this.modal = Object.assign({
        title : '¡Vaya!',
        withImage : false,
        message : this.exceptionMsg,
      })
    }

    submit() {
      this.user = JSON.parse(sessionStorage.getItem("userData"));
      console.log(this.user);
    }


    toContinue() {
      //this.showSecondStep = true;
      this.helpers.navigateTo("/configuracion-cuenta-inversora");
    }

    toCancel() {

      this.modService.open(this.modal.name);
    }

    toProfile() {
      this.helpers.navigateTo("/perfil");
    }
  }