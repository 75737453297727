import { Component, OnInit, Input, Inject, forwardRef } from '@angular/core';
import { InputModalComponent } from 'app/modules/profile/microinsurances/components/input-modal/input-modal.component';
import { ModalService } from 'app/services/modal.service';

@Component({
  selector: 'selector-option',
  templateUrl: './selector-option.component.html',
  styleUrls: ['./selector-option.component.scss']
})
export class SelectorOptionComponent implements OnInit {

  items: any[] = [];
  itemSelected: any;
  itemBefore: any;
  submit = false;

  @Input() title: string;
  @Input() warm: string;
  @Input() tipoOption: string;

  @Input()
  set selectedItem(item) {
    this.itemSelected = item;
    this.itemBefore = this.itemSelected;
  }

  @Input()
  set setItems(value: any[]) {
    this.items = value;
  }

  constructor(
    @Inject(forwardRef(() => InputModalComponent)) private _parent: InputModalComponent,
    private modService: ModalService
  ) {  }

  ngOnInit() {
    this.modService.onclose.subscribe(() => {
      if (this.submit) {
        this.submit = false;
        this.itemBefore = this.itemSelected;
      } else {
        this.itemSelected = this.itemBefore;
      }
    });
  }

  handleChange($event) {
    const id = $event.target.id;
    this.itemSelected = this.items.find(x => x.id === id);
  }

  onSubmit(event) {
    this.submit = true;
    this._parent.eventCloseModal.next(this.itemSelected);
  }
}
