<div class="selector-options">
    <div class="header">
      <div class="title-container">
        <span class="title">{{title}}</span>
      </div>
    </div>
    <div class="warm" *ngIf="warm && warm.length > 0">
      <span>{{warm}}</span>
    </div>
  
    <div class="list-items">
      <div class="group-radio-vertical margin-left">
        <div class="options ">
          <div *ngFor="let item of items">
              <input type="radio" [id]="item.id" [value]="item.id" [name]="tipoOption" [checked]="itemSelected ? itemSelected.id == item.id : false"  (change)="handleChange($event)">
              <label class="button-label round" [for]="item.id"><div class="text-label">{{item.value}}</div></label>
               <div class="sub-label" *ngIf="item.description">{{ item.description }}</div>
              <br><br>
          </div>
        </div>
      </div>
    </div>
  
    <div class="menu-bottom">
        <div class="btn-group">
          <button class="btn-common btn-common_fix_bold" (click)="onSubmit($event)" [disabled]="!itemSelected">SELECCIONAR</button>
        </div>
    </div>
  </div>
  