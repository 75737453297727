<logalty-load *ngIf="logaltyLoaded"></logalty-load>

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<modal [modalTitle]="''" [blocking]='false' [modalId]='modal_id' class='modal' (reload)="reloadModal($event)">
  <div class="container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <!--ERROR TIMEOUT-->
    <div *ngIf='timeout' class="equal-buttons" [ngClass]='!link?"": "no-margin"'>
      <div *ngIf='video != ""' class="access" name="video" (click)="click('video')">
        <span>{{video | uppercase}}</span>
      </div>
      <div *ngIf='iban != ""' class="access accessIban" name="iban" (click)="click('iban')">
        <span>{{iban | uppercase}}</span>
      </div>
    </div>

    <div  *ngIf='!timeout' class="equal-buttons" [ngClass]='!link?"": "no-margin"'>
      <div *ngIf='video != ""' class="access" name="video" (click)="click('video')">
        <span>{{video | uppercase}}</span>
      </div>
      <div *ngIf='iban != ""' class="access accessIban" name="iban" (click)="click('iban')">
        <span>{{iban | uppercase}}</span>
      </div>
    </div>
    <!--<div *ngIf='link != ""'
    [ngClass]='!link?"": "no-margin"'
    class="equal-buttons"
    (click)="click('welcome')">
      <a class="link" href="#">{{link | uppercase}}</a>
    </div>-->
  </div>
</modal>

<modal [modalTitle]="''" [blocking]='true' [modalId]='modal_notAdult' class='modal not-adult '>
  <div class="container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <!-- <div class="equal-buttons">
      <div  class="access" name="continuar" (click)="click('continuar')">
        <span>{{link | uppercase}}</span>
      </div>
    </div> -->
  </div>
</modal>

<modal [modalTitle]="''" [blocking]='false' [modalId]='modal_ko' class='modal'>
  <div class="container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <div class="equal-buttons">
      <div  class="access" name="continuar" (click)="tryAgain()">
        <span>{{link | uppercase}}</span>
      </div>
    </div>
    <p *ngIf='caption != ""'>{{caption}}</p>
  </div>
</modal>
<div helperSteps [msghelper]="msghelper" class="msgHelper"></div>
<div [id]="videoElementId" class="identifyVideo"></div>