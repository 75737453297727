export class ResponseLogin{
  grant_type: string; //rsi,
  access_token: string; //82017f34-d3ea-4dc8-a3a3-eadb97651c4e,
  token_type: string; //Bearer,
  expires_in: string; //3600,
  refresh_token: string; //a1342b78-63a5-4594-a1f6-08ed974176af,
  scope: string; //rsi
  userinfo: {
    telefonoMovil:string; //646633634,
    codigoTarifa:string; //001,
    idInternoPe:string; //00009554,
    usuarioHCE:string; //,
    codigoEntidad:string; //0239,
    numTarjeta:string; //239004199,
    numIntentos:string; //0,
    nombreCliente:string; //PRUEBAS,
    estadoUsuario:string; //A,
    oficina:string; //2000,
    usuarioBE:string; //EV004199,
    acuerdoBE:string; //3000929590,
    apellido1Cliente:string; //PRECONCEDIDO,
    tipoUsuario:string; //3,
    usuario:string; //EV004199,
    ecvPersonaAcuerdo:string; //2,
    codigoIdExterno:string; //51,
    id:string; //70824563L,
    fechaUltimaConexion:string; //2017-06-27T11:39:36,
    apellido2Cliente:string; //EVO UNO,
    mail:string; //dfernandez-golfin@evobanco.com
    numeroTarjetaPrincipal:string; //239004199,
    codigoOficina:string; //2000,
    tipoAcuerdoBE:string; //P,       ,
    fechaAltaAcuerdoBE:string; //2017-06-21T14:48:35.000+02:00,
    idExterno:string; //70824563L,
    numeroTarjeta:string; //239004199,
    perfil:string; //001
  };
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
