import { ErrorService } from './../error.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { AuthTokenService } from '../token/auth.token.service';
import { InterceptorErrorSrv } from './interceptor.errors.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { TokenInfoService } from '../token-info.service';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenInfoService: TokenInfoService,
              public auth: AuthTokenService,
              private keycloak: KeycloakService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(!request.url.endsWith('/evobanco/v1/sso')) {
      if ((request['url'].indexOf('/login_be') !== -1)) {
        request = request.clone({
          setHeaders: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          }
        });
      }
      else if ((request['url'].indexOf('/auth/oauth/v2/token') !== -1)) {
        request = request.clone({
          setHeaders: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          }
        });
      } else if ((request['url'].indexOf('/mfaServices') !== -1)) {
        const token = this.auth.getToken();
        if (token) {
          if (sessionStorage.getItem('microRegistryCall1')) {
            const param = JSON.parse(sessionStorage.getItem('microRegistryCall1'))['mfaAmount'];
            request = request.clone({
              setHeaders: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `${token}`,
                'mfaAmount': param
              }
            });
          } else if (sessionStorage.getItem('microRegistryCall2')) {

            const mfaId = JSON.parse(sessionStorage.getItem('microRegistryCall2'))['mfaId'];
            const mfaValidationData = JSON.parse(sessionStorage.getItem('microRegistryCall2'))['mfaValidationData'];
            const mfaAmount = JSON.parse(sessionStorage.getItem('microRegistryCall2'))['mfaAmount'];

            request = request.clone({
              setHeaders: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'mfaId': mfaId,
                'mfaValidationData': mfaValidationData,
                'mfaAmount': mfaAmount
              }
            });
          }
        }
      } else if ((request['url'].indexOf('api.ipify.org') !== -1)) {
        request = request.clone({
          setHeaders: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          }
        });
      } else {
        if (request['url'].indexOf('/assets') !== -1) {
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          });
        }

        // Si la información se obtuvo de las cookies, no se envía la cabecera 'Authorization'.
        else if (this.tokenInfoService.getAuthenticatedFromCookies()) {
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          });
        }
        // Si no, se obtiene la información a partir del Keycloak y se envía la cabecera.
        else if (this.tokenInfoService.getAccesToken()) { 
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: request.method.includes('GET') ? `${this.auth.getToken()}` : `Bearer ${this.tokenInfoService.getAccesToken()}`,
              // 'x-id-token': `${this.keycloak.getKeycloakInstance().idToken}`, //TODO activar si es necesario y evitarlo para las llamadas a cinv funnel corto
            }
          });
        }
        else {
          request = request.clone({
            setHeaders: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `${this.auth.getToken()}`
            }
          });
        }
      }
    }
    return next.handle(request);
  }
}

@Injectable()
export class RegisterInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthTokenService,
    public interceptErr: InterceptorErrorSrv,
    public errService: ErrorService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request['url'];
    return next.handle(request).do((res: HttpEvent<any>) => {
      if (res instanceof HttpResponse) {
        let bearer, refreshToken;
        if (res.body) {
          if (res.body['auth_token']) {
            bearer = res.body['auth_token']
          }
          else if (res.body['access_token']) { //GENERATE OTP
            bearer = res.body['access_token']
          }
          else if (res.body['fullonlineToken']) { //VALIDATE OTP
            bearer = res.body['fullonlineToken']
            refreshToken = res.body['refreshToken'];
          }
          else {
            bearer = (res.headers.getAll('fullonline_token')) ? res.headers.getAll('fullonline_token')[0] : false;
          }
        }
        if (bearer) {
          this.auth.setToken(bearer);
          const refresh_token = refreshToken ? refreshToken : (res.body['refresh_token']) ? res.body['refresh_token'] : res.headers.getAll('refresh_token')[0];
          this.auth.setRefreshToken(refresh_token);
          return next.handle(request);
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.auth.isUnauth = true;
          this.errService.isStatus401 = true;

        }
        if (url.indexOf('mpm') >= 0) {
          this.interceptErr.mpmError = true;
        }
      }
    });
  }
}
