import { Component, HostListener, ViewRef, ElementRef, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
// import { Ng2PicaService } from 'ng2-pica';

import { Persona } from '../../../collections/persona';
import { AuthService } from '../../../services/auth.service';
import { PersonaService } from '../../../services/persona.service';
import { FormValidatorService } from '../../../services/form-validator.service';
import { ErrorService } from '../../../services/error.service';
import { ModalService } from '../../../services/modal.service';
import { DeviceService } from '../../../services/device.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { HeaderService } from '../../../services/headers.service';
import { environment } from '../../../../environments/environment';
import { JsonService } from '../../../services/json.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { LoadingCustomComponent } from '../../../components/loading-custom/loading-custom.component';
import { ExtendLimitStep1Component } from 'app/modules/profile/extend-limit-step1/extend-limit-step1.component';
import { KeycloakService } from 'keycloak-angular';
import { AuthTokenService } from 'app/services/token/auth.token.service';
import { ConstantsService } from 'app/services/constants.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
  providers: [ModalService, FormValidatorService, JsonService, DeviceService]
})
export class DocumentationComponent implements OnInit, OnDestroy, AfterViewInit {

  public friendlyMessage = '';
  public friendlyTitle = '';
  public friendlyModal = 'friendly';
  public loading = false;
  uploadImageFirstOptionText = 'Escoge una imagen o';
  uploadImageSecondOptionText = 'arrastra un archivo';
  dniData: FormData = new FormData();
  detailModal = 'detail';
  incompleteModal = 'incomplete';
  incorrectFormatModal = 'incorrectFormat';
  incorrectTitle = 'Archivo no válido';
  incorrectText = '¡Vaya! La imagen debe de tener uno de los siguientes formatos: bmp, dib, jpeg, jpg, jpe, png, pbm, pgm, ppm, tiff, tif, jp2';
  modalDniSpecs = 'specs';
  showHeader = true;
  showFooter = true;
  private extensions: Array<string> = [
    'bmp', 'dib', 'jpeg', 'jpg', 'jpe', 'png', 'pbm', 'pgm', 'ppm', 'tiff', 'tif', 'jp2',
    'BMP', 'DIB', 'JPEG', 'JPG', 'JPE', 'PNG', 'PBM', 'PGM', 'PPM', 'TIFF', 'TIF', 'JP2'
  ];
  public msgSrvError = '';
  public modalSrvError = 'modal-srv-error';
  public hiddeBanner: boolean = false;
  public incorrectFormatBanner: boolean = false;
  image: any = {};
  upload: any = {};
  documentSide;
  //private persona: Persona = this.authService.getSessionProspect();
  imageFront: any = {
    side: undefined,
    file: undefined,
    resize: false
  };
  imageBack: any = {
    side: undefined,
    file: undefined,
    resize: false
  }
  title = '¡Vaya!';
  public nameError: string = '';
  public showError: boolean = false;
  public validationExperianAsnef: boolean = false;

  public puntoAbandono: string;
  public activeContinuar: boolean = false;
  public showAllOk: boolean = false;
  public messageAllOk: string = "";

  @ViewChild('extendLimit') extendLimit: ExtendLimitStep1Component;

  constructor(public errService: ErrorService,
    private fb: FormBuilder,
    private router: Router,
    public modService: ModalService,
    private evoValidator: FormValidatorService,
    private authService: AuthService,
    private personaSrv: PersonaService,
    private deviceService: DeviceService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService,
    private jsonService: JsonService,
    private changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    // private picaService: Ng2PicaService,
    private keycloak: KeycloakService,
    private authTokenSrv: AuthTokenService
  ) {

    this.textChange();
    if (this.authService.getSessionProspect().confirma && this.authService.getSessionProspect().confirma.imageFront) {
      this.imageFront = {
        side: 'front',
        file: this.authService.getSessionProspect().confirma.imageFront.file,
        resize: true
      };
      this.upload['front'] = true;
      this.image['front'] = 'data:image/png;base64,' + this.authService.getSessionProspect().confirma.image1;
    }
    /*
    if (this.authService.getSessionProspect().confirma && this.authService.getSessionProspect().confirma.imageBack) {
      this.imageBack = {
        side: 'back',
        file: this.authService.getSessionProspect().confirma.imageBack.file,
        resize: true
      }
      this.upload['back'] = true;
      this.image['back'] = 'data:image/png;base64,' + this.authService.getSessionProspect().confirma.image2;
    }
    */
    this.loadForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.textChange();
  }

  @HostListener('window:dragover', ['$event'])
  onDrag(e) {
    this.highlightDragModule(e);
    e.preventDefault();
  }

  @HostListener('window:drop', ['$event'])
  onDrop(e) {
    e.preventDefault();
  }


  ngOnInit(): void {
    // Se recupera la información de sesión desde el keycloak.
    this.authTokenSrv.getTokenFromKeycloak();

    this.activatedRoute.queryParams.subscribe((params: Params) => {

      // Check origen
      if (params.origen) {
        if (params.origen.toLowerCase() === 'bmi') {
          sessionStorage.setItem('isBMI', 'true');
        }
      }

      this.showHeader =  sessionStorage.getItem('isBMI') === 'true';

      // Se comprueba la respuesta desde Consola 2.0
      if (params.statusConsole && sessionStorage.getItem("casoSeleccionado")) {
        this.checkConsoleStatus(params.statusConsole);
      }
      else {
        // Si no existe el parámetro con la respuesta de Consola o no hay un caso almacenado en sesión, se lleva a la pantalla de error.
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
      }

    });
  }

  continuarFlujo(): void {
    this.errService.modDataError = true;

    // Se muestra el banner.
    this.messageAllOk = "¡Listo! Tu documentación está actualizada.";
    this.showAllOk = true;

    if (!sessionStorage.getItem('operacion-ampliacion')) {
      this.helpers.navigateTo("/perfil");
    } else if (sessionStorage.getItem('operacion-ampliacion') === "imprevistoRiesgos") {
      //this.showError = true; // mostramos el error de div-show
      //o hago asi this.nameError = "imprevistoRiesgos";
      this.showError = true;
      this.nameError = "imprevistoRiesgos";
    }
    else if (sessionStorage.getItem('operacion-ampliacion')) {
      if (parseInt(sessionStorage.getItem('limite-solicitado')) >= 3000) {
        this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/Paso 03-Documentación', 'creditLimit': 'Mayor de 3K€' });
      }
      else {
        this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/Paso 03-Documentación', 'creditLimit': 'Menor de 3K€' });
      }
    }
    document.documentElement.classList.remove('noscroll');

    this.errService.modDataError = true;
    document.getElementsByTagName('body')[0].style.overflow = '';

    this.consultaExperianAsnef();
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges()
  }

  // Comprueba el estado con el que se ha retornado desde el flujo de Consola 2.0
  checkConsoleStatus(statusConsole) {
    let DRF: boolean = false;
    let obligacionesFiscales: boolean = false;

    switch (statusConsole) {
      case ConstantsService.RespuestaConsola.TodoOk: // CMD_1
      case ConstantsService.RespuestaConsola.ModificacionDatosCliente: // CMD_6
      case ConstantsService.RespuestaConsola.ModificacionDocumentacion: // CMD_7
        break;
      case ConstantsService.RespuestaConsola.ResidenciaFueraDeEspania: // CMD_2
      case ConstantsService.RespuestaConsola.ObligacionesFiscalesFueraDeEspania: // CMD_4
      case ConstantsService.RespuestaConsola.RelacionEEUU: // CMD_5
        obligacionesFiscales = true;
        break;
      case ConstantsService.RespuestaConsola.SinDRF: // CMD_3
        DRF = true;
        break;
      default: // CMD_99 - Error genérico
        return;
    }

    let obj: Object = {
      "estado": "aportar-documentacion",
      "idCaso": sessionStorage.getItem("casoSeleccionado"),
      "DRF": DRF,
      "obligacionesFiscales": obligacionesFiscales,
      "logaltySignatureId": "",
      "signatureId": ""
    };

    // Se realiza el PUT a partir de la respuesta de Consola 2.0
    this.modificarLimite(obj, statusConsole);
  }

  modificarLimite(obj: Object, statusConsole: string) {
    this.loading = true;

    this.personaSrv.modificarLimite(obj).subscribe(data => {

      this.showError = false;
      this.nameError = "ocultarErrores";

      switch (data.codigo) {
        case 'KO':
          this.triggerCreditLimitDataErrorNavData('KO Genérico');
          this.triggerExtendLimitFormData('Envío KO: KO Genérico');
          this.loading = false;
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
          break;
        case '06': //DRF - mostramos el error de div-show
          this.triggerExtendLimitFormData('Envío KO: KO DRF - Falta informacion');
          this.loading = false;
          this.showError = true;
          this.nameError = "faltaInformacion";
          break;
        case '01': case '02': case '03': case '04':
          this.triggerExtendLimitFormData('Envío KO: KO imprevistoRiesgos');
          this.loading = false;
          this.showError = true;
          this.nameError = "imprevistoRiesgos";
          break;
        case '05':
          this.loading = false;

          // Se muestra la ventana de error en función de la respuesta de Consola.
          if (statusConsole == ConstantsService.RespuestaConsola.ResidenciaFueraDeEspania) { // CMD_2
            this.errService.navToErrorModData('RESIDENCIA-FUERA-ESPANIA', 'ENTENDIDO', 'bancaelectronica');
          }
          else if (statusConsole == ConstantsService.RespuestaConsola.ObligacionesFiscalesFueraDeEspania) { // CMD_4
            this.errService.navToErrorModData('OBLIGACIONES-FUERA-ESPANIA', 'ENTENDIDO', 'bancaelectronica');
          }
          else if (statusConsole == ConstantsService.RespuestaConsola.RelacionEEUU) { // CMD_5
            this.errService.navToErrorModData('RELACION-EEUU', 'ENTENDIDO', 'bancaelectronica');
          }
          break;
        case 'OK':
          this.triggerExtendLimitFormData('Envío OK');
          this.continuarFlujo();
          break;
        default:
          this.triggerExtendLimitFormData('Envío KO: KO Genérico');
          this.triggerCreditLimitDataErrorNavData('KO Genérico');
          this.loading = false;
          break;
      }
    }, _error => {
      console.log("Error en el put");
      this.triggerExtendLimitFormData('Envío KO: KO Error servicio');
      this.triggerCreditLimitDataErrorNavData('KO Error servicio');
      //Redirige al 404 de Modificación de datos
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }

  highlightDragModule(e) {
    const yPos = e.clientY,
      frontModule = document.getElementById('front'),
      backModule = document.getElementById('back'),
      defaultStyle = '6px dashed #000',
      highlight = '6px dashed #F4137B',
      divId = e.toElement.id

    if (frontModule) {

      if (divId === 'back') {
        backModule.style.border = highlight;
        frontModule.style.border = defaultStyle;
      } else {
        frontModule.style.border = highlight;
        if (backModule) {
          backModule.style.border = defaultStyle;
        }
      }
    } else if (backModule) {

      if (divId === 'front') {
        frontModule.style.border = highlight;
        backModule.style.border = defaultStyle;
      } else {
        backModule.style.border = highlight;
        if (frontModule) {
          frontModule.style.border = defaultStyle;
        }
      }
    }

    document.body.onmouseleave = () => {
      if (frontModule)
        frontModule.style.border = defaultStyle;
      if (backModule)
        backModule.style.border = defaultStyle;
    }
  }

  placeFrontImage(side, file) {
    this.activeContinuar = true;
    if (!this.isValid(file)) { return };
    this.resizeImage(file, side);
  }

  updateFrontImage(files: any) {
    this.activeContinuar = true;
    const file = files.target.files[0];
    if (!this.isValid(file)) { return };
    this.resizeImage(file, 'front');
  }

  resizeImage(file, side) {
    const reader = new FileReader();
    if (side === 'front') {
      this.imageFront.file = file;
      this.imageFront.side = side;
      this.imageFront.resize = true;
    } else {
      this.imageBack.file = file;
      this.imageBack.side = side;
      this.imageBack.resize = true;
    }
    this.loadForm();
    this.upload[side] = true;
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      this.image[side] = reader.result;
    });

    let image1, image2;

    if (this.image['front']) {
      image1 = this.image['front'].split(',')[1];

    }

    if (this.image['back']) {
      image2 = this.image['back'].split(',')[1];

    }

    // }
  }

  onFileParent(event) {
    if (this.helpers.isEmpty(event)) {
      //return this.modService.open(this.incorrectFormatModal);
      this.incorrectFormatBanner = true;
      this.hiddeBanner = false;
    }

    if (typeof this.image['front'] === 'undefined') {
      event['side'] = 'front';
    } else if (typeof this.image['back'] === 'undefined') {
      event['side'] = 'back';
    }

    this.onFilesChange(event);
  }

  onFilesChange(fileObj: Object) {
    const side = fileObj['side'],
      file = fileObj['file'];

    if (this.helpers.isEmpty(fileObj)) {
      //return this.modService.open(this.incorrectFormatModal);
      this.incorrectFormatBanner = true;
      this.hiddeBanner = false;
    }

    this.loadForm();

    if (fileObj['valid'] === true) {
      if (fileObj['side'] === 'front') {
        this.placeFrontImage(side, file)
      } else {
        this.placeBackImage(side, file)
      }
    }
  }

  placeBackImage(side, file) {
    this.activeContinuar = true;
    if (!this.isValid(file)) return;
    this.resizeImage(file, side);
  }


  updateBackImage(files: any) {
    const file = files.target.files[0];
    if (!this.isValid(file)) return;
    this.resizeImage(file, 'back');
  }

  isValid(file) {
    const maxSizeMb = 15;
    const ext = file.name.split('.')[file.name.split('.').length - 1];
    if (this.extensions.lastIndexOf(ext) == -1) {
      //this.modService.open(this.incorrectFormatModal);
      this.hiddeBanner = false;
      this.incorrectFormatBanner = true;
      return false;
    };
    if (file.size > (maxSizeMb * 1048576)) {
      this.incorrectTitle = 'Tamaño máximo';
      this.incorrectText = 'El tamaño máximo de la imagen es de ' + maxSizeMb + 'Mb.';
      this.hiddeBanner = false;
      this.incorrectFormatBanner = true;
      //this.modService.open(this.incorrectFormatModal);
      return false;
    }
    return true;
  }

  loadForm() {
    this.dniData = new FormData();
    if (!!this.imageFront.side) {
      this.dniData.append(this.imageFront.side, this.imageFront.file);
    }
    if (!!this.imageBack.side) {
      this.dniData.append(this.imageBack.side, this.imageBack.file);
    }
  }

  openDetail() {
    this.modService.open(this.detailModal);
  }

  showSpecs() {

    this.modService.open(this.modalDniSpecs);
  }

  textChange() {
    if (this.deviceService.isMobileOrTablet()) {
      this.uploadImageSecondOptionText = 'haz una foto';
    } else {
      this.uploadImageSecondOptionText = 'arrastra un archivo';
    }
  }

  onSubmit() {
    //this.loading = true;
    if (sessionStorage.getItem("operacion-ampliacion") === "ampliacion-firma") { //sessionStorage.getItem("limite-solicitado")
      this.showError = true;
      this.nameError = "preparadoFirma";
      this.puntoAbandono = "previa-firma";
    }
    else {
      this.showError = true;
      this.nameError = "loadingCustom";
      this.puntoAbandono = "casi-lo-tenemos";
    }

    if (!this.imageFront.resize) {
      this.modService.open(this.incompleteModal);
    } else {

      console.log(this.imageFront);

      let imagen = Object.assign({
        //idCaso: "00027374",
        idCaso: sessionStorage.getItem("casoSeleccionado"),
        fileContent: this.image['front'].split(',')[1],
        fileName: this.imageFront.file.name,
      });

      this.personaSrv.subirDocumentacionAmpliacion(imagen).subscribe(data => {
        console.log("peticion realizada", data);
        //sessionStorage.setItem('origen', 'ampliacion');
        //this.extendLimit.putLimite("casi-lo-tenemos","casi");

        // mirar si es mas de 3000 para cambiar el estado - previa firma

        let idCaso = sessionStorage.getItem("casoSeleccionado");
        let obj: Object = {
          "estado": this.puntoAbandono,
          "idCaso": idCaso,
          "DRF": false,
          "obligacionesFiscales": false,
          "logaltySignatureId": "",
          "signatureId": ""
        };
        this.personaSrv.modificarLimite(obj).subscribe(data => {
          this.loading = false;
          if (data.codigo === 'OK') {
            if (parseInt(sessionStorage.getItem('limite-solicitado')) >= 3000) {
              //if (sessionStorage.getItem("operacion-ampliacion") === "ampliacion-firma") { //sessionStorage.getItem("limite-solicitado")
              this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/Paso 04.1 -Redirigiendo a Logalty', 'creditLimit': 'Mayor de 3K€' });
              this.helpers.navigateTo('/logalty-ampliacion');
            }
            else {
              this.helpers.navigateTo('/confirma-limite'); // si sube la documentacion, mandar a confirmar limite
            }
          }
        }, error => {
          console.log("Error en la cancelarLimite");
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        });

      }, error => {
        console.log("error en la peticion");
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
      });

    }
  }

  consultaExperianAsnef() {
    let userData = sessionStorage.getItem("userData");
    let personaData = JSON.parse(sessionStorage.getItem("userData"));
    const idUser = personaData.persona.id_cliente_EVO;

    let objeto: Object = {
      "codProducto": "201",
      "idCaso": sessionStorage.getItem("casoSeleccionado")
    };
    this.personaSrv.getConsultaExperianAmpliacion(idUser, objeto).subscribe(data => {

      if (data.codigo === "01" || data.codigo === "03") {
        if (data.codigo === "01") {
          this.validationExperianAsnef = true;
        }
        // codigo: 01 OK
        // codigo: 03 Error Experian,
        console.log("Consulta Experian OK");
        console.log(data.message);
        this.getConsultaAsnef();
      } else if (data.codigo === "02" || data.codigo === "KO") {
        if (sessionStorage.getItem("operacion-ampliacion") === "ampliacion") {
          this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/KO Bureau', 'creditLimit': 'Menor de 3K€' });
        }
        else if (sessionStorage.getItem("operacion-ampliacion") === "ampliacion-firma") {
          this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/KO Bureau', 'creditLimit': 'Mayor de 3K€' });
        }
        // codigo: 03 Desestimar
        console.log("Consulta Experian KO");
        console.log(data.message);

        //MOSTRAR ERROR - ha surgido un imprevisto
        this.showError = true; // mostramos el error de div-show
        this.nameError = "imprevistoMorosidad";


      }
      else { //En cualquier otro caso se deja continuar
        this.getConsultaAsnef();
      }
    }, error => {
      if (sessionStorage.getItem('operacion-ampliacion') === "ampliacion") {
        this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/KO Error servicio', 'creditLimit': 'Menor de 3K€' });
      }
      else if (sessionStorage.getItem('operacion-ampliacion') === "ampliacion-firma") {
        this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/KO Error servicio', 'creditLimit': 'Mayor de 3K€' });
      }
      this.getConsultaAsnef();
    });
  }

  getConsultaAsnef() {

    let userData = sessionStorage.getItem("userData");
    let personaData = JSON.parse(sessionStorage.getItem("userData"));
    const idUser = personaData.persona.id_cliente_EVO;

    let objeto: Object = {
      "codProducto": "201",
      "idCaso": sessionStorage.getItem("casoSeleccionado")
    };
    this.personaSrv.getConsultaAsnefAmpliacion(idUser, objeto).subscribe(data => {

      if (data.codigo === "01") {
        // codigo: 01 OK
        // codigo: 03 Error Experian,
        console.log("Consulta Asnef OK. Continua", data.message);
        this.loading = false;
        //this.continuarSubmit();
        //this.putLimite("aportar-documentacion", "documentacion");
        //this.putLimite("modificacion-datos", "mi-perfil");
        //this.helpers.navigateTo("mi-perfil");
      } else if (data.codigo === "03") {
        if (this.validationExperianAsnef === true) {
          this.loading = false;
        }
        else {
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        }
      } else if (data.codigo === "02" || data.codigo === "KO") {
        if (sessionStorage.getItem("operacion-ampliacion") === "ampliacion") {
          this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/KO Bureau', 'creditLimit': 'Menor de 3K€' });
        }
        else if (sessionStorage.getItem("operacion-ampliacion") === "ampliacion-firma") {
          this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/KO Bureau', 'creditLimit': 'Mayor de 3K€' });
        }
        // codigo: 03 Desestimar
        console.log("Consulta Asnef KO", data.message);
        //MOSTRAR ERROR - ha surgido un imprevisto
        this.showError = true; // mostramos el error de div-show
        this.nameError = "imprevistoMorosidad";

      } else { //En cualquier otro caso se deja continuar
        //this.continuarSubmit();
      }
    }, error => {
      if (sessionStorage.getItem('operacion-ampliacion') === "ampliacion") {
        this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/KO Error servicio', 'creditLimit': 'Menor de 3K€' });
      }
      else if (sessionStorage.getItem('operacion-ampliacion') === "ampliacion-firma") {
        this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Modificación Límite/KO Error servicio', 'creditLimit': 'Mayor de 3K€' });
      }
      //ERROR
      //this.continuarSubmit();
      //Redirige al 404 de Modificación de datos
      this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
    });
  }

  goToProfile() {
    this.helpers.navigateTo("/perfil");
  }

  resetFrontImage() {
    this.activeContinuar = false;
    this.imageFront.side = undefined;
    this.imageFront.file = undefined;
    this.imageFront.resize = false;
    this.upload['front'] = false;
    this.image['front'] = undefined;

  }

  botonVolver() {
    this.helpers.navigateTo("/mi-perfil");
  }

  hideBanner() {
    this.hiddeBanner = true;
  }
  closeModal() {
    this.modService.close(this.modalDniSpecs);
  }


  hideBannerNotifyOk() {
    this.showAllOk = false;
    //metemos en sesion si ha cerrado el banner.
    sessionStorage.setItem("notificacionOk", "true");
  }

  isMobile(): boolean {
    return !this.showHeader || (this.deviceService.isMobile() && !this.deviceService.isTablet());
  }

  triggerCreditLimitDataErrorNavData(errorDescription) {

    let creditLimitValue = '';
    let firmaNecesaria = sessionStorage.getItem('operacion-ampliacion') == 'ampliacion-firma' ? true : false;
    if (firmaNecesaria) {
      creditLimitValue = 'Menor de 3K€'
    } else {
      creditLimitValue = 'Mayor de 3K€'
    }
    this.analytics.triggerCreditLimitDataNavData({
      'pageName': 'Modificación Límite/' + errorDescription,
      'creditLimit': creditLimitValue
    });
  }

  triggerExtendLimitFormData(submitStatus) {
    this.analytics.triggerExtendLimitFormData(submitStatus, sessionStorage.getItem('limite-solicitado'));
  }
}
