<modal [modalTitle]="'Boletin adhesion'" [blocking]='false' [modalId]='modalAdhesion' class='modal'>
  <div class="container">
    <h3 class="txt-center">Boletín de Adhesión</h3>
    <p>
      <strong>Características</strong>
    </p>
    <p class="line-height-40">Seguro colectivo innominado</p>
    <ul class="square-bullet">
      <li>
        <span>Tomador: EVO.</span>
      </li>
      <li>
        <span>Adhesión de asegurados con duración</span>
      </li>
      <li>
        <span>Personas no asegurables:</span>
      </li>
    </ul>

    <strong>Coberturas (obligatorias):</strong>
    <ul class="listRequire square-bullet">
      <li>
        <span>Muerte por Accidente</span>
      </li>
      <li>
        <span>Invalidez Permanente Parcial por accidente</span>
      </li>
      <li>
        <span>Invalidez Absoluta y Permanente por accidente</span>
      </li>
      <li>
        <span>Responsabilidad Civil. Cobertura de las indemnizaciones exigidas al Asegurado por un tercero, por daños personales
          y/o materiales, así como por los perjuicios económicos derivados de dichos daños, ocasionados involuntariamente,
          que deriven directamente del ejercicio como aficionado de la actividad deportiva asegurada.</span>
      </li>
      <li>
        <span>No contratable según ámbito geográfico y actividad deportiva mencionado en normas.</span>
      </li>
    </ul>
  </div>
</modal>

<header-profile></header-profile>
<div class="simulador">
  <div class="image thin-border"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>EL NUEVO SEGURO POR HORAS PARA DISFRUTAR DE TU DEPORTE FAVORITO SIN NINGUNA PREOCUPACIÓN</h2>
        <p>Asegura hasta 75 actividades deportivas desde 0,42€</p>
      </legend>
    </div>
    <div class="main-content">
      <div class="col-6">
        <div class="row info">
          <div class="item">
            <div class="text-info">
              <h6>ASEGURA HASTA 75 ACTIVIDADES DEPORTIVAS</h6>
              <div>HASTA 5.000m DE ALTURA</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info">
              <h6>DESDE 3 HORAS</h6>
              <div>6h, 9h, 12h... HASTA 5 DÍAS</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info">
              <h6>HASTA 75.000€ DE CAPITAL ASEGURADO</h6>
              <div>EN CASO DE ACCIDENTE</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="video-container">
            <div *ngIf='showPlay' 
              (click)="hidePlayer();playVid()" 
              class="video" [ngStyle]="{'background':'url('+poster+')'}">
            </div>
            <div *ngIf='!showPlay' class="player-container">
              <!-- <video id="EVOProtect" (click)='playVid()'>
                <source src="https://www.youtube.com/watch?v=Ge6vFzSZ1WU&feature=youtu.be" type="video/mp4">
                <source src="https://www.youtube.com/watch?v=Ge6vFzSZ1WU&feature=youtu.be" type="video/ogg">
              </video> -->
              <iframe height="330"
              src="https://www.youtube.com/embed/Ge6vFzSZ1WU?autoplay=1&controls=0&rel=0">
              </iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <tarificador *ngIf="tarificador" [navigateTo]="navigate"></tarificador>
        <slider-without-feeder *ngIf="!tarificador"></slider-without-feeder>
      </div>
    </div>
    <div class="req">
      <h6>NO TE OLVIDES QUE PARA CONTRATAR EVO PROTECT DEPORTES NECESITAS:</h6>
      <div>
        <ul>
          <li>Ser cliente de EVO. </li>
          <li>Ser mayor de 18 y menor de 64 años.</li>
          <li>Firmar el Boletín de Adhesión. Sin coste alguno. Con la firma de este Boletín de Adhesión estarás cubierto en hasta
            75 actividades deportivas y podrás activar cualquiera de las coberturas de tu seguro EVO PROTECT cuando lo necesites
            y de manera rápida.</li>
          <li>Para actividades deportivas amateur, sin federar, que no sean competiciones y hasta 5.000m de altura.</li>
          <li>EVO Protect Deportes está respaldado por la póliza de Santalucía “Seguro de Accidentes Tu actividad”</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
