import { Component, HostListener, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { NestedObject } from 'app/services/nestedObject.service';
import { AuthService } from 'app/services/auth.service';
import { Logalty, signLogalty, setCheckLogalty, LogaltyAmpliacion , LogaltyInversora, LogaltyInversoraTwice } from 'app/collections/logalty';
import { LogaltyService } from 'app/services/logalty.service';
import { ApiLogaltyService } from 'app/services/api/api.logalty.service';
import { DeviceService } from 'app/services/device.service';
import { ErrorService } from 'app/services/error.service';
import { ModalService } from 'app/services/modal.service';
import { ApiVideoeidService } from 'app/services/api/api.video-eid.service';
import { VideoEidService } from 'app/services/video-eid.service';
import { ApiIbanService } from 'app/services/api/api.iban.service';
import { HelperService } from 'app/services/helpers.service';
import { IbanService } from 'app/services/iban.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ProductSelection } from 'app/services/product-selection.service'
import { Persona } from 'app/collections/persona';
import { PersonaService } from 'app/services/persona.service';
import { environment } from 'app/../environments/environment';
import { PAISES } from 'app/resources/countries.model';
import { ESTADO_CIVIL } from 'app/resources/civil-status.model';
import { VIAS } from 'app/resources/tipo-via.model';
import { SITUACIONES } from 'app/resources/situacion-vivienda.model';
import { SITUACIONESLABORALES } from 'app/resources/situacion-laboral.model';
import { TIPOACTIVIDADPROFESIONAL } from 'app/resources/tipo-actividad-profesional.model';
import { REGIMENES } from 'app/resources/regimen-economico.model';
import { ACCOUNTS } from 'app/resources/account.model';
import { PROVINCES } from 'app/resources/provincias.model';
import { CNAE } from '../../../resources/cnae.model';
import { Params, ActivatedRoute } from '@angular/router';
declare var $;

@Component({
  selector: 'logalty-ampliacion',
  templateUrl: './logalty-ampliacion.component.html',
  styleUrls: ['./logalty-ampliacion.component.scss'],
  providers: [LogaltyService, ApiLogaltyService, ApiVideoeidService, VideoEidService, ApiIbanService, IbanService, DeviceService, ModalService]
})
export class LogaltyAmpliacionComponent implements OnInit {

  public logaltySamlAction: string = environment.urlLogalty;
  private samlLoaded: Boolean = false;
  private persona: Persona;
  public loading: boolean = false;
  public msgSrvError: string = '';
  public modalSrvError: string = 'modal-srv-error';
  public urlCallback: string = environment.urlBackFragment;
  public logaltyData;
  private secondSignerId;
  public logaltyLoaded = false;
  public dataCaso: any;
  public codProducto: string = "";
  public logaltySigId;
  public sigId;
  public isAmpliacion: boolean = false;
  public nameError: string = '';
  public showError: boolean = false;
  public estado: string;
  public obj: Object;
  public logaltySigned = false;

  public isOperacionAmpliacion: boolean = false;

  public idCliente: string;
  public idCaso: string;
  public productoContratando: string;
  public codProductContratado;
  public ibanCuentaSeleccionada: string;
  public categorizacion: boolean = false;
  public isBmi: boolean = false;


  //cuenta inversora
  public logalty: any;
  public logaltyInversora: any;
  public secondHolder: any;
  public isPrimerTitular: boolean = false;


 // @ViewChild('iframe') iframe: ElementRef;

  // @HostListener('window:beforeunload', ['$event'])
  // preventAbandon($event) {
  //   return $event.returnValue = '¿Estás seguro?';
  // }

  constructor(
    private authService: AuthService,
    private logaltySrv: LogaltyService,
    public deviceService: DeviceService,
    public errService: ErrorService,
    public modService: ModalService,
    private helpers: HelperService,
    private personaService: PersonaService,
    private activatedRoute: ActivatedRoute,
    private productSelect: ProductSelection,
    public nestedObject: NestedObject,
    private analytics: AnalyticsService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params.origen) {
        if (params.origen.toLowerCase() === "bmi") {
          sessionStorage.setItem('isBMI', "true");
        }
      }
      if (params.logaltySigned)
        this.logaltySigned = true;
      else
        this.logaltySigned = false;
    });

    this.checkSessionStorage();
    if (!this.logaltySigned && sessionStorage.getItem('logaltySigned') !== "true") {
      sessionStorage.removeItem('logaltySigned');
      this.showError = true;
      this.scrollToTop();
      this.loadSaml();
    }
  }

  ngAfterViewInit() {
    if (this.logaltySigned || sessionStorage.getItem('logaltySigned') === "true") {

    //Se elimina de la session el elemento que nos indica que ya se ha firmado
    sessionStorage.removeItem('logaltySigned');
    //Quitamos el query params de la url para no trasmitirlo a otros componentes
    if(location.href.indexOf("?logaltySigned"))
      location.href = location.href.split("?logaltySigned")[0];
    
    if(location.hash.indexOf("?logaltySigned"))
      location.hash = location.hash.split("?logaltySigned")[0];

      this.loading = true;
      this.nameError = 'backFromLogalty';
      this.showError = true;
      this.checkLogaltyResponse();
    }

  }

  checkLogaltyResponse(){
      this.logaltySigId = sessionStorage.getItem('logaltySignatureId');
      this.productoContratando = sessionStorage.getItem("productoContratando");
      if (this.productoContratando === "009") {
        this.codProductContratado = "cinv";
      }
      else {
        this.codProductContratado = "fint";
      }
      this.isOperacionAmpliacion = sessionStorage.getItem('operacion-ampliacion') === "ampliacion-firma" ||
      sessionStorage.getItem('operacion-ampliacion') === 'modificar';
      if (this.isOperacionAmpliacion){
        this.codProducto = '201';
      }
      this.sigId = sessionStorage.getItem('sigId');
      sessionStorage.removeItem('logaltySigned');
      this.triggerNavData('Logalty');
      this.watchForIframeClosing( this.logaltySigId);

  }

  checkSessionStorage() {
    this.idCliente = JSON.parse(sessionStorage.getItem('userData')).persona.id_cliente_EVO;
    this.idCaso = sessionStorage.getItem("casoSeleccionado");
    this.productoContratando = sessionStorage.getItem("productoContratando");
    this.isOperacionAmpliacion = sessionStorage.getItem('operacion-ampliacion') === "ampliacion-firma" ||
    sessionStorage.getItem('operacion-ampliacion') === 'modificar';
    this.secondHolder = sessionStorage.getItem('isSecondSigner');
    this.ibanCuentaSeleccionada = sessionStorage.getItem('ibanCuentaSeleccionada');
    this.categorizacion = false;
    if (sessionStorage.getItem('categorizacionAnterior') === "true") {
      this.categorizacion = true;
    }
    if (this.productoContratando === "009") {
      this.codProductContratado = "cinv";
    }
    else {
      this.codProductContratado = "fint";
    }
    if(sessionStorage.getItem("isBMI") === "true"){
      this.isBmi = true;
    }
  }

  loadSaml() {
    if (this.isOperacionAmpliacion) { //Ampliación
      this.codProducto = "201"; //Object.assign({ codProductoContratado: sessionStorage.getItem("codProdAmpliacion") });
      sessionStorage.setItem('product-selected', JSON.stringify(this.codProducto));
      this.nameError = "preparadoFirma";
    }
    else { // Inversora
      this.codProducto = Object.assign({ codProductoContratado: this.productoContratando });
      sessionStorage.setItem('product-selected', JSON.stringify(this.codProducto));
      if (this.productoContratando === "009") {
        this.nameError = "preparadoFirmaCI";
      } else {
        this.nameError = "preparadoFirmaFI";
      }
    }

    this.loading = true;

    this.personaService.getPersonaById(this.idCliente, this.codProducto).subscribe((data) => {
      this.loading = false;
      sessionStorage.setItem("persona", JSON.stringify(data));

      let product = this.productSelect.getProductSelected();
      (!product) ? product = this.authService.product : product = product.codProductoContratado;

      this.productSelect.product = this.nestedObject.nestedObjectAssign({ codProductoContratado: product }, this.productSelect.product);

      this.loadSpecificLogalty();

    }, error => {
      this.checkErrorStatusAndGoToError(error);
    });
  }

  loadSpecificLogalty() {
    let isTwoHolders = JSON.parse(sessionStorage.getItem("isTwoHolders"));
    let mostrarTexto1Titular = false;
    if(sessionStorage.getItem('mostrarTexto1Titular') === 'true') {
      mostrarTexto1Titular = true;
    }

    if (this.isOperacionAmpliacion) { //Ampliación
      const LogaltyAmpliacion: signLogalty = new signLogalty();
      this.loadLogalty(LogaltyAmpliacion, false);
    } else {
      if (this.secondHolder !== "true") {
        if (mostrarTexto1Titular || isTwoHolders){
          this.isPrimerTitular= true;
          this.estado = "firma-tit1";
          this.triggerNavData('Prelogalty');
          this.loadLogaltyInversoraSecondHolder(false);
        } else {
          this.triggerNavData('Prelogalty');
          this.loadLogaltyInversora(false);
        }
      }
      else {
        //secondHolder
        this.estado = "firma-tit2";
        this.triggerNavData('Prelogalty');
        this.loadLogaltyInversoraSecondHolder(true);
        //this.helpers.navigateTo("final-cuenta-inversora");
      }
    }
  }

  // consoleFn(iframe) {
  //   if (window.parent['iframe']) {
  //     window.parent['iframe'] = undefined;
  //     this.watchForIframeClosing(this.logaltyData);
  //   }
  // }

  setLogaltyProspec(logaltySignatureId, signatureId, office?) {
    const canal = this.deviceService.getDevice();
    let oficina = office ? office : environment.oficina;
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      producto: { codProductoContratado: this.productSelect },
      datosPersonales: {
        oficinaCliente: oficina
      },
      logalty: {
        logaltySignatureId,
        signatureId,
        codProductoContratado: this.productSelect,
      }
    })
  }

  // ampliacionLimiteGet para obtener get de caso
  loadLogalty(LogaltyAmpliacion, isSecond) {
    this.loading = true;
    this.personaService.consultarCasoAmpliacion(this.idCliente, this.idCaso).subscribe(data => {
      this.dataCaso = data;
      sessionStorage.setItem("getCaso", JSON.stringify(data));
      this.logaltySrv.signLogaltyAmpliacion(this.callLogaltyAmpliacion()).subscribe((dataLogalty) => {
        // put caso con logalty
        this.logaltySigId = dataLogalty.logaltySignatureId;
        sessionStorage.setItem('logaltySignatureId', dataLogalty.logaltySignatureId);
        this.sigId = dataLogalty.signatureId;
        sessionStorage.setItem('sigId', dataLogalty.signatureId);
          let obj: Object = {
            "estado": "firma",
            "idCaso": this.idCaso,
            "DRF": false,
            "obligacionesFiscales": false,
            "logalty": {
              "logaltySignatureId": dataLogalty.logaltySignatureId,
              "signatureId": dataLogalty.signatureId,
            }
          };
          this.personaService.modificarLimite(obj).subscribe(data => {
            this.loading = false;
            if( data.codigo === 'OK'){
              this.responseLogalty(dataLogalty, data, LogaltyAmpliacion, isSecond);
              }
              else {
                this.goToError();
              }
            }, error => {
            console.log("Error en la modificacion del caso");
            this.goToError();
          });
        // get de caso  - mirar si es necesario

      }, error => {
        this.checkErrorStatusAndGoToError(error);
      });
    });
  }
    // inversora para obtener get de caso
    loadLogaltyInversora(isSecond) {
      this.loading = true;
      this.personaService.consultarDatosCasoInversora(this.idCliente, this.idCaso).subscribe(data => {
        this.dataCaso = data;
        sessionStorage.setItem("getCaso", JSON.stringify(data));
        this.logaltySrv.signLogaltyCuentaInversora(this.callLogaltyInversora(data.infoCaso)).subscribe((dataLogalty) => {
          // put caso con logalty
          this.logaltySigId = dataLogalty.logaltySignatureId;
          sessionStorage.setItem('logaltySignatureId', dataLogalty.logaltySignatureId);
          this.sigId = dataLogalty.signatureId;
          sessionStorage.setItem('sigId', dataLogalty.signatureId);
            let obj: Object = {
              "estado": "firma-tit1",
              "idCaso": this.idCaso,
              "logalty": {
                "logaltySignatureId": dataLogalty.logaltySignatureId,
                "signatureId": dataLogalty.signatureId,
              }
            };
            this.personaService.modificarCuenta(obj).subscribe(data => {
              this.loading = false;
              if( data.codigo === 'OK'){
                this.responseLogalty(dataLogalty, data, LogaltyInversora, isSecond);
                }
                else {
                  this.goToError();
                }
              }, error => {
              console.log("Error en la modificacion del caso");
              this.triggerNavData('KO Error servicio');
              this.goToError();
            });
          // get de caso  - mirar si es necesario

        }, error => {
          this.checkErrorStatusAndGoToError(error, true)

        });
      }, error => {
        this.checkErrorStatusAndGoToError(error);
      });
    }

    // Segundo titular
    loadLogaltyInversoraSecondHolder(isSecond) {
      this.loading = true;
      this.personaService.consultarDatosCasoInversora(this.idCliente, this.idCaso).subscribe(data => {
        sessionStorage.setItem("getCaso", JSON.stringify(data));
        this.logaltySrv.signLogaltyCuentaInversoraTwice(this.callLogaltyInversoraHolder(data.infoCaso, isSecond)).subscribe((dataLogalty) => {
          // put caso con logalty
          this.logaltySigId = dataLogalty.logaltySignatureId;
         sessionStorage.setItem('logaltySignatureId', dataLogalty.logaltySignatureId);
          this.sigId = dataLogalty.signatureId;
          sessionStorage.setItem('sigId', dataLogalty.signatureId);
          if(this.estado === "firma-tit1"){
             this.obj= {
              "estado": this.estado,
              "idCaso": this.idCaso,
              "logalty": {
                "logaltySignatureId": dataLogalty.logaltySignatureId,
                "signatureId": dataLogalty.signatureId,
              }
            };
          }else{
             this.obj = {
              "estado": this.estado,
              "idCaso": this.idCaso,
              "logalty": {
                "logaltySignatureIdSegundoTitular": dataLogalty.logaltySignatureId,
                "signatureIdSegundoTitular": dataLogalty.signatureId,
              }
            };
          }

            this.personaService.modificarCuenta(this.obj).subscribe(data => {
              this.loading = false;
              if( data.codigo === 'OK'){
                this.responseLogalty(dataLogalty, data, LogaltyInversora, isSecond);
                }
                else {
                  this.goToError();
                }
              }, error => {
                this.checkErrorStatusAndGoToError(error);
                console.log("Error en la modificacion del caso");
            });
          // get de caso  - mirar si es necesario

        }, error => {
          this.checkErrorStatusAndGoToError(error, true);
        });
      }, error => {
        this.checkErrorStatusAndGoToError(error);
      });
    }

  responseLogalty(dataLogalty, data, logalty, isSecond) {
    let office;
    if (dataLogalty.logaltySignatureId === null) {
      if (dataLogalty.operationErrorCode === 800 && dataLogalty.operationErrorMessage === 'Duplicated Key') {
        const num = Math.floor((Math.random() * 100) + 100);
        const modifyPersonData = this.nestedObject.nestedObjectAssign({}, data);
        if (typeof modifyPersonData.datosPersonales !== 'undefined') {
          office = modifyPersonData.datosPersonales.oficinaCliente;
        }
        if (typeof modifyPersonData.logalty === 'undefined') {
          modifyPersonData.logalty = Object.assign({});
        }
        modifyPersonData.logalty = Object.assign({
          signatureId: `${data.datosPersonales.id_cliente_EVO}_${num}`
        });
        this.loadLogalty(logalty, isSecond);

        return;
      } else {
        this.triggerNavData('KO Error servicio');
        this.goToError(); return;
      }
    }
      this.setLogaltyProspec(dataLogalty.logaltySignatureId, dataLogalty.signatureId, office);

    logalty = dataLogalty;
    this.logaltyData = dataLogalty;
    this.samlLoaded = !!logalty;
    this.submitForm(this.helpers.encode(logalty.saml));
    if(this.isOperacionAmpliacion){
      this.triggerNavData('Paso 04.2-Logalty','Mayor de 3K€');
    } else {
      if (this.secondHolder !== 'true') {
        let mostrarTexto1Titular = false;
        if(sessionStorage.getItem('mostrarTexto1Titular') === 'true'){
          mostrarTexto1Titular = true;
        }
        if (mostrarTexto1Titular){
          this.triggerNavData('Logalty');
        } else {
          this.triggerNavData('Logalty');
        }
      }
      else {
        this.triggerNavData('Logalty')
      }
    }
    // this.loading = false;
    // this.logaltyLoaded = true;
    // this.showError = false;
    // this.nameError = "ocultarErrores";
    // this.loading = false;
  }

  updateCaseInversora(errorDescription) {
    this.obj = {
      'estado': this.estado,
      'idCaso': this.idCaso,
      'description': errorDescription
    };
    this.personaService.modificarCuenta(this.obj).subscribe();
  }

  goToError() {
    this.errService.modDataError = true;
    this.errService.navToErrorModData('SATELITE-C' , 'ENTENDIDO', 'bancaelectronica');
  }

  submitForm(saml) {
    $('form:first input[name=saml_assertion]').val(saml);
    $('form:first').submit();
  }

  watchForIframeClosing(logaltySignatureId) {
    if (logaltySignatureId) {

      if (this.codProducto === "201") {//ampliacion.
        this.isAmpliacion = true;
        this.logalty = new setCheckLogalty({
          product: 'limitecred',
          guids: [logaltySignatureId]
        });
      }
      else {
        if (this.productoContratando === "009") {
          this.logalty = new setCheckLogalty({
            product: 'cinv',
            guids: [logaltySignatureId]
          });
        }
        else if (this.productoContratando === "010") {
          this.logalty = new setCheckLogalty({
            product: 'fint',
            guids: [logaltySignatureId]
          });
        }
      }
      this.checkState();
    }
    else {
      this.goToError();
    }
  }

  checkState() {
    this.loading = true;
    this.logaltySrv.checkState(this.logalty).subscribe(data => {
      if (data.operationErrorMessage === null && data.states[0].stateCode == 12 && data.states[0].resultCode == 7) {
        this.persona = this.authService.getSessionProspect();
        this.persona.complete = true;
        this.changeContratacion(true);
      } else {
        if (data.states[0].resultCode === 8) {
          this.errService.logaltyCancel = true;
        } else {
          this.errService.logaltyWrong = true;
        }
        this.goToError();
      }
      this.loading = false;
    }, _error => {
      this.triggerNavData('KO Error servicio');

      if (this.logalty.product === 'cinv'){
        this.obj = {'descripcion' : 'Error servicio checkstate Logalty ' };
        this.personaService.modificarCuenta(this.obj).subscribe(
            data => {
              this.goToError();
            },
            error => {
              this.goToError();
            });
      } else {
        this.goToError();
      }

    });
  }

  changeContratacion(isOk: Boolean) {
    this.persona = this.authService.getSessionProspect();
    if (isOk) {
      if (this.isAmpliacion) {
        this.modificarLimite();
      } else {
        this.modificarCuenta();
      }
    }
    else {
      this.goToError();
    }
  }

  /**
   * Put caso con logalty para ampliación de límite
   */
  modificarLimite() {

    let obj: Object = {
      "estado": "casi-lo-tenemos",
      "idCaso": this.idCaso,
      "DRF": false,
      "obligacionesFiscales": false,
      "logaltySignatureId": "",
      "signatureId": ""
    };

    sessionStorage.setItem('operacion-ampliacion', 'ampliacion');

    this.personaService.modificarLimite(obj).subscribe(data => {
      this.loading = false;
      if (data.codigo === 'OK') {
        let nextstep = '/404';
        if (this.codProducto && (this.codProducto === "201")) {
          nextstep = '/confirma-limite'
        }
        this.helpers.navigateTo(nextstep);
      }
      else {
        this.goToError();
      }
    }, _error => {
      this.triggerNavData('KO Error servicio');
      console.log("Error en la modificacion del caso");
    });
  }

   /**
   * Put caso con logalty para inversora
   */
  modificarCuenta() {
    this.secondHolder = sessionStorage.getItem('isSecondSigner');
    if (this.secondHolder === 'true') {
      this.estado = 'previa-firma-tit1';
    } else {
      this.estado = 'casi-lo-tenemos';
    }
    let obj: Object = {
      "estado": this.estado,
      "idCaso": this.idCaso,
      "DRF": false,
      "obligacionesFiscales": false,
      "logaltySignatureId": "",
      "signatureId": ""
    };

    this.personaService.modificarCuenta(obj).subscribe(data => {
      this.loading = false;
      if (data.codigo === 'OK') {
        let nextstep = '/404';

        let product = this.productSelect.getProductSelected();
        (!product) ? product = this.authService.product : product = product.codProductoContratado;

        this.productSelect.product = this.nestedObject.nestedObjectAssign({ codProductoContratado: product }, this.productSelect.product);

        if (this.productSelect.product && (this.productSelect.product.codProductoContratado === "009") ||
          (this.productSelect.product.codProductoContratado === "010")) {
          nextstep = '/final-cuenta-inversora';
          this.helpers.navigateTo(nextstep);
        } else {
          this.goToError();
        }
      } else {
        this.goToError();
      }
    }, error => {
      this.checkErrorStatusAndGoToError(error);
    });
  }

  setEstadoLogalty(){
    this.secondHolder = sessionStorage.getItem('isSecondSigner');
    if (this.secondHolder === 'true') {
      this.estado = 'previa-firma-tit1';
    } else {
      this.estado = 'casi-lo-tenemos';
    }
  }

  isEmpty(obj) {
    if (obj === null) return true;
    return Object.keys(obj).length === 0;
  }

  searchValue(values, value) {
    let name;
    values.forEach((item) => {
      if(item.descripcion_cno__c){
        item.descripcion_cno__c.forEach(cno => {
          if (cno['value'] && value && cno['value'].toUpperCase() === value.toUpperCase()) {
            name = cno;
          }
        });
      }else if (item['value'] && value && item['value'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    return (name) ? name.name : name;
  }

  searchCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item['code'] && value && item['code'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    return (name) ? name.name : name;
  }

  getFieldUndefinedEscaped(field) {
    if (field === undefined || field === '' || field === null)
      return ' ';
    if (typeof field === 'string')
      return field.toUpperCase();
    else
      return field;
  }

  findProduct(products, identificador) {
    let product;
    // var productAccount = ACCOUNTS[this.productSelect.product.codProductoContratado];
    for (let i = 0; i < products.length; i++) {
      if (identificador === 'cc' && products[i].cc) {
        product = products[i].iban;
        break;
      }
      if (identificador === 'cd' && products[i].cd) {
        product = products[i].iban;
        break;
      }
    }
    return product;
  }

  callLogaltyAmpliacion(): LogaltyAmpliacion {
    const timespan = new Date().getTime();
    const data = new Date(),
          date = data.getDate(),
          month = data.getMonth() + 1,
          year = data.getFullYear();
    let logaltyAmp = <LogaltyAmpliacion>{};
    //si hay modificacion obtenemos datos de persona.
    // para no traer los datos del caso, ya que no tendremos los datos actualizados.

    //let user = JSON.parse(sessionStorage.getItem('userInfo'));
    let user = JSON.parse(sessionStorage.getItem('persona'));



    // PONER PRODUCT
    logaltyAmp.product = "limitecred";
    logaltyAmp.signatureUserData = this.nestedObject.nestedObjectAssign({});
    logaltyAmp.signatureUserData.countryResidence = this.nestedObject.nestedObjectAssign({});
    logaltyAmp.contractData = this.nestedObject.nestedObjectAssign({});
    logaltyAmp.contractData.personalData = this.nestedObject.nestedObjectAssign({});
    logaltyAmp.contractData.personalData.address = this.nestedObject.nestedObjectAssign({});
    logaltyAmp.contractData.economicData = this.nestedObject.nestedObjectAssign({});


    logaltyAmp.mobileNumber = this.getFieldUndefinedEscaped(user.datosPersonales.telefonoMovil);
    logaltyAmp.urlBackFragment = this.urlCallback;

    logaltyAmp.signatureUserData.firstName = this.getFieldUndefinedEscaped(user.datosPersonales.nombreCliente);
    logaltyAmp.signatureUserData.lastName1 = this.getFieldUndefinedEscaped(user.datosPersonales.apellido1Cliente);
    logaltyAmp.signatureUserData.lastName2 = this.getFieldUndefinedEscaped(user.datosPersonales.apellido2Cliente);
    logaltyAmp.signatureUserData.email = this.getFieldUndefinedEscaped(user.datosPersonales.email);
    logaltyAmp.signatureUserData.treatment = user.datosPersonales.sexo === 'H' ? 'M' : 'F';
    logaltyAmp.signatureUserData.mobileNumber = this.getFieldUndefinedEscaped(user.datosPersonales.telefonoMovil);
    //logaltyAmp.signatureUserData.signatureDate = `${date}/${month}/${year}`;
    logaltyAmp.signatureUserData.signatureDate = this.helpers.formatDateDayMonthYear(data);
    logaltyAmp.signatureUserData.identityDocumentType = user.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
    logaltyAmp.signatureUserData.signatureId = `${user.datosPersonales.id_cliente_EVO}_${timespan}`;
    logaltyAmp.signatureUserData.nif = user.datosPersonales.idExterno;
    logaltyAmp.signatureUserData.userId = user.datosPersonales.id_cliente_EVO;
    logaltyAmp.signatureUserData.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(user.datosPersonales.paisResidencia);
    logaltyAmp.signatureUserData.countryResidence.alpha2Code = "Alpha2";
    logaltyAmp.signatureUserData.countryResidence.englishDescription = "N/A";

    logaltyAmp.contractData.personalData.address.typeOfRoad = this.searchValue(VIAS, user.datosDomicilio.codigoDomicilioTipoVia);
    logaltyAmp.contractData.personalData.address.street = this.getFieldUndefinedEscaped(user.datosDomicilio.nombreVia);;
    logaltyAmp.contractData.personalData.address.buildingNumber = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioNumero);
    logaltyAmp.contractData.personalData.address.floorLevel = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioPlanta);
    logaltyAmp.contractData.personalData.address.door = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioPuerta);
    logaltyAmp.contractData.personalData.address.portal = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioPortal);
    logaltyAmp.contractData.personalData.address.block = this.getFieldUndefinedEscaped(user.datosDomicilio.bloque);
    logaltyAmp.contractData.personalData.address.phase = this.getFieldUndefinedEscaped(user.datosDomicilio.fase);
    logaltyAmp.contractData.personalData.address.plot = this.getFieldUndefinedEscaped(user.datosDomicilio.parcela);
    logaltyAmp.contractData.personalData.address.postalCode = this.getFieldUndefinedEscaped(user.datosDomicilio.codigoPostal);
    logaltyAmp.contractData.personalData.address.stairway = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioEscalera);
    logaltyAmp.contractData.personalData.address.locality = this.getFieldUndefinedEscaped(user.datosDomicilio.nombreLocalidad);
    logaltyAmp.contractData.personalData.address.province = this.getFieldUndefinedEscaped(user.datosDomicilio.nombreProvinciaDomicilio);
    logaltyAmp.contractData.personalData.address.country = this.getFieldUndefinedEscaped(this.searchValue(PAISES, user.datosPersonales.paisResidencia));

    logaltyAmp.contractData.personalData.documentExpiryDate = this.getFieldUndefinedEscaped(user.datosPersonales.fechaCaducidadDocumento);
    logaltyAmp.contractData.personalData.birthdate = this.getFieldUndefinedEscaped(user.datosPersonales.fechaNacimiento);
    logaltyAmp.contractData.personalData.birthCountry =  this.getFieldUndefinedEscaped(this.searchValue(PAISES, user.datosPersonales.codigoPaisNacimiento));
    logaltyAmp.contractData.personalData.birthLocality = this.getFieldUndefinedEscaped(user.datosPersonales.nombreLocalidadNacimiento);
    logaltyAmp.contractData.personalData.residenceCountry = this.getFieldUndefinedEscaped(this.searchValue(PAISES, user.datosDomicilio.codigoPaisResidencia));
    logaltyAmp.contractData.personalData.nationalityCountry = this.getFieldUndefinedEscaped(this.searchValue(PAISES, user.datosPersonales.paisNacionalidad));
    logaltyAmp.contractData.personalData.maritalStatus = this.searchValue(REGIMENES, user.datosPersonales.regimenEconomico);
    logaltyAmp.contractData.personalData.civilStatus = this.searchValue(ESTADO_CIVIL, user.datosPersonales.codigoEstadoCivil);

    logaltyAmp.contractData.economicData.yearSinceActualPoblation =this.dataCaso.datosCaso.datosEconomicos.anioResidenciaPoblacion;
    logaltyAmp.contractData.economicData.yearsInCountry = this.dataCaso.datosCaso.datosEconomicos.aniosResidenciaPais;
    logaltyAmp.contractData.economicData.homeSituation = this.searchValue(SITUACIONES, user.datosSocioEco.situacionVivienda);
    logaltyAmp.contractData.economicData.numberOfFamilyComponents = this.dataCaso.datosCaso.datosEconomicos.miembrosUnidadFamiliar;
    logaltyAmp.contractData.economicData.labourStatus = this.searchValue(SITUACIONESLABORALES, user.datosSocioEco.codigoSituacionLaboral);
    logaltyAmp.contractData.economicData.contractEndDate = this.dataCaso.datosCaso.datosEconomicos.fechaFinContrato;
    logaltyAmp.contractData.economicData.profesion = this.getFieldUndefinedEscaped(user.datosSocioEco.profesion);
    logaltyAmp.contractData.economicData.companyName = this.getFieldUndefinedEscaped(user.datosSocioEco.nombreEmpresa);
    //seteamos la actividad dependiendo si es autonomo o trabajador por cuenta ajena.
    if(user.datosSocioEco.codigoSituacionLaboral === "02"){
      logaltyAmp.contractData.economicData.activity =  this.getFieldUndefinedEscaped(this.searchValue(CNAE, user.datosSocioEco.codigoCNAE));
    }else{
      logaltyAmp.contractData.economicData.activity = this.getFieldUndefinedEscaped(this.searchValue(TIPOACTIVIDADPROFESIONAL,user.datosSocioEco.codigoCNO));
    }
    logaltyAmp.contractData.economicData.startingDateOfTheCurrentJob = this.getFieldUndefinedEscaped(user.datosSocioEco.fechaAltaEmpresa);
    logaltyAmp.contractData.economicData.yearsWorked = this.dataCaso.datosCaso.datosEconomicos.aniosGlobalesTrabajados;
    logaltyAmp.contractData.economicData.numberOfPayments = this.getFieldUndefinedEscaped(user.datosSocioEco.numeroPagas);
    logaltyAmp.contractData.economicData.monthlyNetFixedIncome = this.dataCaso.datosCaso.datosEconomicos.ingresosMensuales;
    logaltyAmp.contractData.economicData.otherNetFixedIncome = null;
    logaltyAmp.contractData.economicData.monthlyNetVariableIncome = user.datosSocioEco.ingresosMensualesVariablesNetos;
    logaltyAmp.contractData.economicData.otherLoansPayments = null;
    logaltyAmp.contractData.economicData.monthlyRentImport = this.getFieldUndefinedEscaped(user.datosDomicilio.cuotaAlquilerMensual);
    logaltyAmp.contractData.economicData.compensatoryPensionsExpenses = null;

    return logaltyAmp;
  }


  callLogaltyInversora(infoCaso): LogaltyInversora {
    const timespan = new Date().getTime();
    const data = new Date();
    let logaltyInv = <LogaltyInversora>{};
    let user = JSON.parse(sessionStorage.getItem('persona'));
    if(this.ibanCuentaSeleccionada === null){
      this.ibanCuentaSeleccionada = infoCaso.IBAN;
    }
    // PONER PRODUCT
    logaltyInv.product = this.codProductContratado;
    logaltyInv.signatureUserData = this.nestedObject.nestedObjectAssign({});
    logaltyInv.contractData = this.nestedObject.nestedObjectAssign({});
    logaltyInv.signatureUserData.countryResidence = this.nestedObject.nestedObjectAssign({});
    logaltyInv.contractData.personalData = this.nestedObject.nestedObjectAssign({});
    logaltyInv.contractData.personalData.address = this.nestedObject.nestedObjectAssign({});
    logaltyInv.contractData.economicData = this.nestedObject.nestedObjectAssign({});


    logaltyInv.mobileNumber = this.getFieldUndefinedEscaped(user.datosPersonales.telefonoMovil);
    logaltyInv.urlBackFragment = this.urlCallback;

    logaltyInv.signatureUserData.firstName = this.getFieldUndefinedEscaped(user.datosPersonales.nombreCliente);
    logaltyInv.signatureUserData.lastName1 = this.getFieldUndefinedEscaped(user.datosPersonales.apellido1Cliente);
    logaltyInv.signatureUserData.lastName2 = this.getFieldUndefinedEscaped(user.datosPersonales.apellido2Cliente);
    logaltyInv.signatureUserData.email = this.getFieldUndefinedEscaped(user.datosPersonales.email);
    logaltyInv.signatureUserData.treatment = user.datosPersonales.sexo === 'H' ? 'M' : 'F';
    logaltyInv.signatureUserData.mobileNumber = this.getFieldUndefinedEscaped(user.datosPersonales.telefonoMovil);
    logaltyInv.signatureUserData.signatureDate = this.helpers.formatDateDayMonthYear(data);

    // logaltyInv.signatureUserData.signatureDate = `${date}/${month}/${year}`;
    logaltyInv.signatureUserData.identityDocumentType = user.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
    logaltyInv.signatureUserData.signatureId = `${user.datosPersonales.id_cliente_EVO}_${timespan}`;
    logaltyInv.signatureUserData.nif = user.datosPersonales.idExterno;
    logaltyInv.signatureUserData.userId = user.datosPersonales.id_cliente_EVO;
    logaltyInv.signatureUserData.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(user.datosPersonales.paisResidencia);
    logaltyInv.signatureUserData.countryResidence.alpha2Code = "Alpha2";
    logaltyInv.signatureUserData.countryResidence.englishDescription = "N/A";

    logaltyInv.contractData.personalData.address.typeOfRoad = this.searchValue(VIAS, user.datosDomicilio.codigoDomicilioTipoVia);
    logaltyInv.contractData.personalData.address.street = this.getFieldUndefinedEscaped(user.datosDomicilio.nombreVia);;
    logaltyInv.contractData.personalData.address.buildingNumber = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioNumero);
    logaltyInv.contractData.personalData.address.floorLevel = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioPlanta);
    logaltyInv.contractData.personalData.address.door = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioPuerta);
    logaltyInv.contractData.personalData.address.portal = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioPortal);
    logaltyInv.contractData.personalData.address.block = this.getFieldUndefinedEscaped(user.datosDomicilio.bloque);
    logaltyInv.contractData.personalData.address.phase = this.getFieldUndefinedEscaped(user.datosDomicilio.fase);
    logaltyInv.contractData.personalData.address.plot = this.getFieldUndefinedEscaped(user.datosDomicilio.parcela);
    logaltyInv.contractData.personalData.address.postalCode = this.getFieldUndefinedEscaped(user.datosDomicilio.codigoPostal);
    logaltyInv.contractData.personalData.address.stairway = this.getFieldUndefinedEscaped(user.datosDomicilio.domicilioEscalera);
    logaltyInv.contractData.personalData.address.locality = this.getFieldUndefinedEscaped(user.datosDomicilio.nombreLocalidad);
    logaltyInv.contractData.personalData.address.province = this.getFieldUndefinedEscaped(user.datosDomicilio.nombreProvinciaDomicilio);
    logaltyInv.contractData.personalData.address.country = this.getFieldUndefinedEscaped(this.searchValue(PAISES, user.datosPersonales.paisResidencia));

    logaltyInv.contractData.personalData.documentExpiryDate = this.getFieldUndefinedEscaped(user.datosPersonales.fechaCaducidadDocumento);
    logaltyInv.contractData.personalData.birthdate = this.getFieldUndefinedEscaped(user.datosPersonales.fechaNacimiento);
    logaltyInv.contractData.personalData.birthCountry = this.getFieldUndefinedEscaped(this.searchValue(PAISES, user.datosPersonales.codigoPaisNacimiento));
    logaltyInv.contractData.personalData.birthLocality = this.getFieldUndefinedEscaped(user.datosPersonales.nombreLocalidadNacimiento);
    logaltyInv.contractData.personalData.residenceCountry = this.getFieldUndefinedEscaped(this.searchValue(PAISES, user.datosDomicilio.codigoPaisResidencia));
    logaltyInv.contractData.personalData.nationalityCountry = this.getFieldUndefinedEscaped(this.searchValue(PAISES, user.datosPersonales.paisNacionalidad));
    logaltyInv.contractData.personalData.maritalStatus = this.searchValue(REGIMENES, user.datosPersonales.regimenEconomico);
    logaltyInv.contractData.personalData.civilStatus = this.searchValue(ESTADO_CIVIL, user.datosPersonales.codigoEstadoCivil);

    logaltyInv.contractData.economicData.yearSinceActualPoblation = this.dataCaso.infoCaso.datosPrimerTitular.datosEconomicos.anioResidenciaPoblacion;
    logaltyInv.contractData.economicData.yearsInCountry = this.dataCaso.infoCaso.datosPrimerTitular.datosEconomicos.aniosResidenciaPais;
    logaltyInv.contractData.economicData.homeSituation = this.searchValue(SITUACIONES, user.datosSocioEco.situacionVivienda);
    logaltyInv.contractData.economicData.numberOfFamilyComponents = this.dataCaso.infoCaso.datosPrimerTitular.datosEconomicos.miembrosUnidadFamiliar;
    logaltyInv.contractData.economicData.labourStatus = this.searchValue(SITUACIONESLABORALES, user.datosSocioEco.codigoSituacionLaboral);
    logaltyInv.contractData.economicData.contractEndDate = this.dataCaso.infoCaso.datosPrimerTitular.datosEconomicos.fechaFinContrato;
    logaltyInv.contractData.economicData.profesion = this.getFieldUndefinedEscaped(user.datosSocioEco.profesion);
    logaltyInv.contractData.economicData.companyName = this.getFieldUndefinedEscaped(user.datosSocioEco.nombreEmpresa);
    //seteamos la actividad dependiendo si es autonomo o trabajador por cuenta ajena.
    if(user.datosSocioEco.codigoSituacionLaboral === "02"){
      logaltyInv.contractData.economicData.activity =  this.getFieldUndefinedEscaped(this.searchValue(CNAE, user.datosSocioEco.codigoCNAE));
    }else{
      logaltyInv.contractData.economicData.activity = this.getFieldUndefinedEscaped(this.searchValue(TIPOACTIVIDADPROFESIONAL,user.datosSocioEco.codigoCNO));
    }
    logaltyInv.contractData.economicData.startingDateOfTheCurrentJob = this.getFieldUndefinedEscaped(user.datosSocioEco.fechaAltaEmpresa);
    logaltyInv.contractData.economicData.yearsWorked = this.dataCaso.infoCaso.datosPrimerTitular.datosEconomicos.aniosGlobalesTrabajados;
    logaltyInv.contractData.economicData.numberOfPayments = this.getFieldUndefinedEscaped(user.datosSocioEco.numeroPagas);
    logaltyInv.contractData.economicData.monthlyNetFixedIncome = this.dataCaso.infoCaso.datosPrimerTitular.datosEconomicos.ingresosMensuales;
    logaltyInv.contractData.economicData.otherNetFixedIncome = null;
    logaltyInv.contractData.economicData.monthlyNetVariableIncome = user.datosSocioEco.ingresosMensualesVariablesNetos;
    logaltyInv.contractData.economicData.otherLoansPayments = null;
    logaltyInv.contractData.economicData.monthlyRentImport = this.dataCaso.infoCaso.datosPrimerTitular.datosEconomicos.cuotaAlquilerMensual;
    logaltyInv.contractData.economicData.compensatoryPensionsExpenses = null;
    logaltyInv.contractData.economicData.associateAcount = this.ibanCuentaSeleccionada;
    logaltyInv.contractData.economicData.categorized = this.categorizacion;

    return logaltyInv;
  }


  callLogaltyInversoraHolder(infoCaso, isSecond): LogaltyInversoraTwice {
    const timespan = new Date().getTime();
    const data = new Date(),
          date = data.getDate(),
          month = data.getMonth() + 1,
          year = data.getFullYear();
    let logaltyInvTwice = <LogaltyInversoraTwice>{};
    let userinfo = JSON.parse(sessionStorage.getItem('userData')).userinfo;
    let user = JSON.parse(sessionStorage.getItem('persona'));
    if(this.ibanCuentaSeleccionada === null){
      this.ibanCuentaSeleccionada = infoCaso.IBAN;
    }
    // PONER PRODUCT
    logaltyInvTwice.product = this.codProductContratado;
    logaltyInvTwice.secondHolder = isSecond;
    logaltyInvTwice.signatureUserData = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.contractData = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.signatureUserData.countryResidence = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.contractData.personalData = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.contractData.personalData.address = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.contractData.economicData = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.contractData2Holder = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.contractData2Holder.personalData = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.contractData2Holder.personalData.address = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.contractData2Holder.economicData = this.nestedObject.nestedObjectAssign({});
    logaltyInvTwice.mobileNumber = this.getFieldUndefinedEscaped(user.datosPersonales.telefonoMovil);
    logaltyInvTwice.urlBackFragment = this.urlCallback;

    //Datos del firmante.
    if(this.isPrimerTitular){
      //Flujo primer titular.
      logaltyInvTwice.signatureUserData.firstName = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.nombreCliente);
      logaltyInvTwice.signatureUserData.lastName1 = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.apellido1Cliente);
      logaltyInvTwice.signatureUserData.lastName2 = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.apellido2Cliente);
      logaltyInvTwice.signatureUserData.email = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.email);
      logaltyInvTwice.signatureUserData.treatment = infoCaso.datosPrimerTitular.datosPersonales.sexo === 'H' ? 'M' : 'F';
      logaltyInvTwice.signatureUserData.mobileNumber = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.telefonoMovil);
      logaltyInvTwice.signatureUserData.signatureDate = this.helpers.formatDateDayMonthYear(data);
      logaltyInvTwice.signatureUserData.identityDocumentType = infoCaso.datosPrimerTitular.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
      logaltyInvTwice.signatureUserData.signatureId = `${infoCaso.datosPrimerTitular.datosPersonales.idClienteEVO}_${timespan}`;
      logaltyInvTwice.signatureUserData.nif = infoCaso.datosPrimerTitular.datosPersonales.idExterno;
      logaltyInvTwice.signatureUserData.userId = infoCaso.datosPrimerTitular.datosPersonales.idClienteEVO;
      logaltyInvTwice.signatureUserData.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.paisResidencia);
      logaltyInvTwice.signatureUserData.countryResidence.alpha2Code = "Alpha2";
      logaltyInvTwice.signatureUserData.countryResidence.englishDescription = "N/A";
    }else{
      //Flujo segundo titular.
      logaltyInvTwice.signatureUserData.firstName = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.nombreCliente);
      logaltyInvTwice.signatureUserData.lastName1 = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.apellido1Cliente);
      logaltyInvTwice.signatureUserData.lastName2 = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.apellido2Cliente);
      logaltyInvTwice.signatureUserData.email = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.email);
      logaltyInvTwice.signatureUserData.treatment = infoCaso.datosSegundoTitular.datosPersonales.sexo === 'H' ? 'M' : 'F';
      logaltyInvTwice.signatureUserData.mobileNumber = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.telefonoMovil);
      logaltyInvTwice.signatureUserData.signatureDate = this.helpers.formatDateDayMonthYear(data);
      logaltyInvTwice.signatureUserData.identityDocumentType = infoCaso.datosSegundoTitular.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
      logaltyInvTwice.signatureUserData.signatureId = `${infoCaso.datosSegundoTitular.datosPersonales.idClienteEVO}_${timespan}`;
      logaltyInvTwice.signatureUserData.nif = infoCaso.datosSegundoTitular.datosPersonales.idExterno;
      logaltyInvTwice.signatureUserData.userId = infoCaso.datosSegundoTitular.datosPersonales.idClienteEVO;
      logaltyInvTwice.signatureUserData.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.paisResidencia);
      logaltyInvTwice.signatureUserData.countryResidence.alpha2Code = "Alpha2";
      logaltyInvTwice.signatureUserData.countryResidence.englishDescription = "N/A";
    }
    //primer titular.
    //Primer titular.Direccion.
    logaltyInvTwice.contractData.personalData.address.typeOfRoad = this.searchValue(VIAS, infoCaso.datosPrimerTitular.datosPersonales.codigoDomicilioTipoVia);
    logaltyInvTwice.contractData.personalData.address.street = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.nombreVia);;
    logaltyInvTwice.contractData.personalData.address.buildingNumber = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.domicilioNumero);
    logaltyInvTwice.contractData.personalData.address.floorLevel = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.domicilioPlanta);
    logaltyInvTwice.contractData.personalData.address.door = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.domicilioPuerta);
    logaltyInvTwice.contractData.personalData.address.portal = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.domicilioPortal);
    logaltyInvTwice.contractData.personalData.address.block = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.bloque);
    logaltyInvTwice.contractData.personalData.address.phase = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.fase);
    logaltyInvTwice.contractData.personalData.address.plot = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.parcela);
    logaltyInvTwice.contractData.personalData.address.postalCode = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.codigoPostal);
    logaltyInvTwice.contractData.personalData.address.stairway = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.domicilioEscalera);
    logaltyInvTwice.contractData.personalData.address.locality = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.nombreLocalidad);
    logaltyInvTwice.contractData.personalData.address.province = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.nombreProvinciaDomicilio);
    logaltyInvTwice.contractData.personalData.address.country = this.getFieldUndefinedEscaped(this.searchValue(PAISES, infoCaso.datosPrimerTitular.datosPersonales.paisResidencia));
    //Primer titular.Datos personales.
    logaltyInvTwice.contractData.personalData.documentExpiryDate = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.caducidadDocumento);
    logaltyInvTwice.contractData.personalData.birthdate = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.birthdate);
    logaltyInvTwice.contractData.personalData.birthCountry =  this.getFieldUndefinedEscaped(this.searchValue(PAISES, infoCaso.datosPrimerTitular.datosPersonales.paisResidencia));
    logaltyInvTwice.contractData.personalData.birthLocality = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.nombreLocalidad);
    logaltyInvTwice.contractData.personalData.residenceCountry = this.getFieldUndefinedEscaped(this.searchValue(PAISES, infoCaso.datosPrimerTitular.datosPersonales.codigoPaisResidencia));
    logaltyInvTwice.contractData.personalData.nationalityCountry = this.getFieldUndefinedEscaped(this.searchValue(PAISES, infoCaso.datosPrimerTitular.datosPersonales.paisNacionalidad));
    logaltyInvTwice.contractData.personalData.maritalStatus = this.searchValue(REGIMENES, infoCaso.datosPrimerTitular.datosPersonales.regimenEconomico);
    logaltyInvTwice.contractData.personalData.civilStatus = this.searchValue(ESTADO_CIVIL, (infoCaso.datosPrimerTitular.datosPersonales.estadoCivil));
    logaltyInvTwice.contractData.personalData.firstName = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.nombreCliente);
    logaltyInvTwice.contractData.personalData.lastName1 = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.apellido1Cliente);
    logaltyInvTwice.contractData.personalData.lastName2 = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.apellido2Cliente);
    logaltyInvTwice.contractData.personalData.email = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.email);
    logaltyInvTwice.contractData.personalData.mobileNumber = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.telefonoMovil);
    logaltyInvTwice.contractData.personalData.treatment = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosPersonales.sexo) === 'H' ? 'M' : 'F';
    logaltyInvTwice.contractData.personalData.nif = infoCaso.datosPrimerTitular.datosPersonales.idExterno;
    //Primer titular.Datos economicos.
    logaltyInvTwice.contractData.economicData.yearSinceActualPoblation = infoCaso.datosPrimerTitular.datosEconomicos.anioResidenciaPoblacion;
    logaltyInvTwice.contractData.economicData.yearsInCountry = infoCaso.datosPrimerTitular.datosEconomicos.aniosResidenciaPais;
    logaltyInvTwice.contractData.economicData.homeSituation = this.searchValue(SITUACIONES, infoCaso.datosPrimerTitular.datosEconomicos.situacionVivienda);
    logaltyInvTwice.contractData.economicData.numberOfFamilyComponents = infoCaso.datosPrimerTitular.datosEconomicos.miembrosUnidadFamiliar;
    logaltyInvTwice.contractData.economicData.labourStatus = this.searchValue(SITUACIONESLABORALES, infoCaso.datosPrimerTitular.datosEconomicos.situacionLaboral);
    logaltyInvTwice.contractData.economicData.contractEndDate = infoCaso.datosPrimerTitular.datosEconomicos.fechaFinContrato;
    logaltyInvTwice.contractData.economicData.profesion = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosEconomicos.profesion);
    logaltyInvTwice.contractData.economicData.companyName = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosEconomicos.empresa);
    //informamos la actividad dependiendo si es autonomo o trabajador por cuenta ajena.
    if(user.datosSocioEco.codigoSituacionLaboral === "02"){
      logaltyInvTwice.contractData.economicData.activity =  this.getFieldUndefinedEscaped(this.searchValue(CNAE, infoCaso.datosPrimerTitular.datosEconomicos.codigoCNAE));
    }else{
      logaltyInvTwice.contractData.economicData.activity = this.getFieldUndefinedEscaped(this.searchValue(TIPOACTIVIDADPROFESIONAL, infoCaso.datosPrimerTitular.datosEconomicos.codigoCNO));
    }
    logaltyInvTwice.contractData.economicData.startingDateOfTheCurrentJob = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosEconomicos.fechaAltaEmpresa);
    logaltyInvTwice.contractData.economicData.yearsWorked = infoCaso.datosPrimerTitular.datosEconomicos.aniosGlobalesTrabajados;
    logaltyInvTwice.contractData.economicData.numberOfPayments = this.getFieldUndefinedEscaped(infoCaso.datosPrimerTitular.datosEconomicos.numeroPagas);
    logaltyInvTwice.contractData.economicData.monthlyNetFixedIncome = infoCaso.datosPrimerTitular.datosEconomicos.ingresosMensuales;
    logaltyInvTwice.contractData.economicData.otherNetFixedIncome = null;
    logaltyInvTwice.contractData.economicData.monthlyNetVariableIncome = infoCaso.datosPrimerTitular.datosEconomicos.ingresosMensualesVariablesNetos;
    logaltyInvTwice.contractData.economicData.otherLoansPayments = null;
    logaltyInvTwice.contractData.economicData.monthlyRentImport = infoCaso.datosPrimerTitular.datosEconomicos.cuotaAlquilerMensual;
    logaltyInvTwice.contractData.economicData.compensatoryPensionsExpenses = null;
    logaltyInvTwice.contractData.economicData.associateAcount= this.ibanCuentaSeleccionada;
    logaltyInvTwice.contractData.economicData.categorized = this.categorizacion;


    //segundo titular.
    //segundo titular.Direccion.
    logaltyInvTwice.contractData2Holder.personalData.address.typeOfRoad = this.searchValue(VIAS, infoCaso.datosSegundoTitular.datosPersonales.codigoDomicilioTipoVia);
    logaltyInvTwice.contractData2Holder.personalData.address.street = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.nombreVia);
    logaltyInvTwice.contractData2Holder.personalData.address.buildingNumber = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.numeroDomicilio);
    logaltyInvTwice.contractData2Holder.personalData.address.floorLevel = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.plantaDominiclio);
    logaltyInvTwice.contractData2Holder.personalData.address.door = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.domicilioPuerta);
    logaltyInvTwice.contractData2Holder.personalData.address.portal = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.domicilioPortal);
    logaltyInvTwice.contractData2Holder.personalData.address.block = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.bloque);
    logaltyInvTwice.contractData2Holder.personalData.address.phase = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.fase);
    logaltyInvTwice.contractData2Holder.personalData.address.plot = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.parcela);
    logaltyInvTwice.contractData2Holder.personalData.address.postalCode = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.codigoPostal);
    logaltyInvTwice.contractData2Holder.personalData.address.stairway = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.domicilioEscalera);
    logaltyInvTwice.contractData2Holder.personalData.address.locality = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.nombreLocalidad);
    logaltyInvTwice.contractData2Holder.personalData.address.province = this.getFieldUndefinedEscaped(this.searchValue(PROVINCES ,infoCaso.datosSegundoTitular.datosPersonales.provinciaDomicilio));
    logaltyInvTwice.contractData2Holder.personalData.address.country = this.getFieldUndefinedEscaped(this.searchValue(PAISES, infoCaso.datosSegundoTitular.datosPersonales.paisResidencia));
    //segundo titular.Datos personales.
    logaltyInvTwice.contractData2Holder.personalData.documentExpiryDate = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.caducidadDocumento);
    logaltyInvTwice.contractData2Holder.personalData.birthdate = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.birthdate);
    logaltyInvTwice.contractData2Holder.personalData.birthCountry =  this.getFieldUndefinedEscaped(this.searchValue(PAISES, infoCaso.datosSegundoTitular.datosPersonales.codigoPaisNacimiento));
    logaltyInvTwice.contractData2Holder.personalData.birthLocality = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.nombreLocalidadNacimiento);
    logaltyInvTwice.contractData2Holder.personalData.residenceCountry = this.getFieldUndefinedEscaped(this.searchValue(PAISES, infoCaso.datosSegundoTitular.datosPersonales.codigoPaisResidencia));
    logaltyInvTwice.contractData2Holder.personalData.nationalityCountry = this.getFieldUndefinedEscaped(this.searchValue(PAISES, infoCaso.datosSegundoTitular.datosPersonales.paisNacionalidad));
    logaltyInvTwice.contractData2Holder.personalData.maritalStatus = this.searchValue(REGIMENES, infoCaso.datosSegundoTitular.datosPersonales.regimenEconomico);
    logaltyInvTwice.contractData2Holder.personalData.civilStatus = this.searchValue(ESTADO_CIVIL, infoCaso.datosSegundoTitular.datosPersonales.estadoCivil);
    logaltyInvTwice.contractData2Holder.personalData.firstName = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.nombreCliente);
    logaltyInvTwice.contractData2Holder.personalData.lastName1 = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.apellido1Cliente);
    logaltyInvTwice.contractData2Holder.personalData.lastName2 = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.apellido2Cliente);
    logaltyInvTwice.contractData2Holder.personalData.email = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.email);
    logaltyInvTwice.contractData2Holder.personalData.treatment = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.sexo) === 'H' ? 'M' : 'F';
    logaltyInvTwice.contractData2Holder.personalData.mobileNumber = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.telefonoMovil);
    logaltyInvTwice.contractData2Holder.personalData.nif = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosPersonales.idExterno);
    //segundo titular.Datos economicos.
    logaltyInvTwice.contractData2Holder.economicData.yearSinceActualPoblation =infoCaso.datosSegundoTitular.datosPersonales.anioResidenciaPoblacion;
    logaltyInvTwice.contractData2Holder.economicData.yearsInCountry = infoCaso.datosSegundoTitular.datosPersonales.aniosResidenciaPais;
    logaltyInvTwice.contractData2Holder.economicData.homeSituation = this.searchValue(SITUACIONES, infoCaso.datosSegundoTitular.datosEconomicos.situacionVivienda);
    logaltyInvTwice.contractData2Holder.economicData.numberOfFamilyComponents = infoCaso.datosSegundoTitular.datosEconomicos.miembrosUnidadFamiliar;
    logaltyInvTwice.contractData2Holder.economicData.labourStatus = this.searchValue(SITUACIONESLABORALES, infoCaso.datosSegundoTitular.datosEconomicos.situacionLaboral);
    logaltyInvTwice.contractData2Holder.economicData.contractEndDate = infoCaso.datosSegundoTitular.datosEconomicos.fechaFinContrato;
    logaltyInvTwice.contractData2Holder.economicData.profesion = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosEconomicos.profesion);
    logaltyInvTwice.contractData2Holder.economicData.companyName = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosEconomicos.nombreEmpresa);
    logaltyInvTwice.contractData2Holder.economicData.activity = this.getFieldUndefinedEscaped(this.searchValue(TIPOACTIVIDADPROFESIONAL, infoCaso.datosSegundoTitular.datosEconomicos.codigoCNO));
    logaltyInvTwice.contractData2Holder.economicData.startingDateOfTheCurrentJob = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosEconomicos.fechaAltaEmpresa);
    logaltyInvTwice.contractData2Holder.economicData.yearsWorked = infoCaso.datosSegundoTitular.datosEconomicos.aniosGlobalesTrabajados;
    logaltyInvTwice.contractData2Holder.economicData.numberOfPayments = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosEconomicos.numeroPagas);
    logaltyInvTwice.contractData2Holder.economicData.monthlyNetFixedIncome = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosEconomicos.ingresosMensuales);
    logaltyInvTwice.contractData2Holder.economicData.otherNetFixedIncome = null;
    logaltyInvTwice.contractData2Holder.economicData.monthlyNetVariableIncome = infoCaso.datosSegundoTitular.datosEconomicos.ingresosMensualesVariablesNetos;;
    logaltyInvTwice.contractData2Holder.economicData.otherLoansPayments = null;
    logaltyInvTwice.contractData2Holder.economicData.monthlyRentImport = this.getFieldUndefinedEscaped(infoCaso.datosSegundoTitular.datosEconomicos.cuotaAlquilerMensual);
    logaltyInvTwice.contractData2Holder.economicData.compensatoryPensionsExpenses = null;
    logaltyInvTwice.contractData2Holder.economicData.associateAcount= this.ibanCuentaSeleccionada;
    logaltyInvTwice.contractData2Holder.economicData.categorized = this.categorizacion;

    return logaltyInvTwice;
  }

  firstCallLogalty(persona: Persona): Logalty {
    const data = new Date(),
          date = data.getDate(),
          month = data.getMonth() + 1,
          year = data.getFullYear();
    let logalty = <Logalty>{};
    /******** BREAKPOINT NACHO **********/
    logalty = this.nestedObject.nestedObjectAssign({}, logalty);
    logalty.signatureUserData = this.nestedObject.nestedObjectAssign({});
    logalty.signatureUserData.countryResidence = this.nestedObject.nestedObjectAssign({});
    logalty.bankInformation = this.nestedObject.nestedObjectAssign({});
    logalty.bankInformation2Signer = this.nestedObject.nestedObjectAssign({});
    logalty.jobInformation = this.nestedObject.nestedObjectAssign({});
    logalty.ownershipConfirmation = this.nestedObject.nestedObjectAssign({});
    logalty.personalInformation = this.nestedObject.nestedObjectAssign({});
    logalty.personalInformation.address = this.nestedObject.nestedObjectAssign({});

    logalty.mobileNumber = this.getFieldUndefinedEscaped(persona.datosPersonales.telefonoMovil);
    logalty.product = 'fullonline';
    if (this.productSelect.product && this.productSelect.product.codProductoContratado === ACCOUNTS.CJCode) {
      logalty.product = 'cuentajov';
    }
    logalty.urlBackFragment = this.urlCallback;
    logalty.signatureUserData.firstName = this.getFieldUndefinedEscaped(persona.datosPersonales.nombreCliente);
    logalty.signatureUserData.lastName1 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido1Cliente);
    logalty.signatureUserData.lastName2 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido2Cliente);
    logalty.signatureUserData.email = this.getFieldUndefinedEscaped(persona.datosPersonales.email);
    logalty.signatureUserData.treatment = persona.datosPersonales.sexo === 'H' ? 'M' : 'F';
    logalty.signatureUserData.mobileNumber = this.getFieldUndefinedEscaped(persona.datosPersonales.telefonoMovil);
    logalty.signatureUserData.signatureDate = `${date}/${month}/${year}`;
    logalty.signatureUserData.identityDocumentType = persona.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
    let signerId = `${persona.datosPersonales.id_cliente_EVO}_0`;
    if (persona.datosPrimerTitular && persona.datosPrimerTitular.id_cliente_EVO ||
      (this.productSelect.product && (this.productSelect.product.isSecondSigner || this.productSelect.product.codProductoContratado === ACCOUNTS.STCode))) {
      signerId = `${persona.datosPersonales.id_cliente_EVO}_second_signer_0`;
      if(persona.logalty) persona.logalty.signatureId = `${persona.logalty.signatureId}_second_signer_0`;
    }
    let signatureiD = persona.logalty ? persona.logalty.signatureId || signerId : signerId;
    let number = 0;
    const regex = RegExp('(_[0-9]*)$', 'g');
    let resultreg = regex.exec(signatureiD)[0];
    signatureiD = signatureiD.split(resultreg)[0];
    resultreg = resultreg.split('_')[1];
    number = Number(resultreg) + 1;
    if (number && number !== NaN) signatureiD = `${signatureiD}_${number}`;
    logalty.signatureUserData.signatureId = signatureiD;
    logalty.signatureUserData.nif = persona.datosPersonales.idExterno;
    logalty.signatureUserData.userId = persona.datosPersonales.id_cliente_EVO;
    logalty.signatureUserData.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(persona.datosPersonales.paisResidencia);
    logalty.signatureUserData.countryResidence.alpha2Code = "Alpha2";
    logalty.signatureUserData.countryResidence.englishDescription = "N/A";
    logalty.jobInformation.labourStatus = this.searchValue(SITUACIONESLABORALES, persona.datosSocioEco.codigoSituacionLaboral);
    logalty.jobInformation.salaryRank = this.getFieldUndefinedEscaped(persona.datosSocioEco.nivelSalarial);
    logalty.jobInformation.salaryMonth = this.getFieldUndefinedEscaped(persona.datosSocioEco.ingresosMensuales);
    logalty.jobInformation.cno = this.getFieldUndefinedEscaped(this.searchValue(TIPOACTIVIDADPROFESIONAL, persona.datosSocioEco.codigoCNO));
    logalty.jobInformation.currentJobSeniority = this.getFieldUndefinedEscaped(persona.datosSocioEco.fechaAntiguedadLaboral);
    logalty.jobInformation.startingDateOfTheCurrentJob = this.getFieldUndefinedEscaped(persona.datosSocioEco.fechaAntiguaCargo);
    logalty.jobInformation.companyName = this.getFieldUndefinedEscaped(persona.datosSocioEco.nombreEmpresa);
    logalty.jobInformation.jobTitle = this.getFieldUndefinedEscaped(persona.datosSocioEco.profesion);
    logalty.jobInformation.monthlySalary = this.getFieldUndefinedEscaped(persona.datosSocioEco.ingresosMensuales);
    logalty.jobInformation.globalLaborSeniority = this.getFieldUndefinedEscaped(persona.datosSocioEco.aniosGlobalesTrabajados);
    logalty.jobInformation.salaryNumber = this.getFieldUndefinedEscaped(persona.datosSocioEco.numeroPagas);
    logalty.ownershipConfirmation.ibanThirdParty = this.getFieldUndefinedEscaped(persona.datosSocioEco.IBAN);
    logalty.ownershipConfirmation.officeName = '0806 - Oficina Virtual';
    logalty.bankInformation.ibanAccount = 'IBAN proporcionado una vez formalizada la contratación';
    logalty.bankInformation.ibanInvestment = 'Dato proporcionado una vez formalizada la contratación';
    logalty.bankInformation.cardNumber = 'Dato proporcionado una vez formalizada la contratación';
    logalty.bankInformation.userElectronicBank = 'Dato proporcionado una vez formalizada la contratación';
    logalty.bankInformation.userAlerts = 'Dato proporcionado una vez formalizada la contratación';
    logalty.personalInformation.treatment = persona.datosPersonales.sexo === 'H' ? 'M' : 'F';
    logalty.personalInformation.firstName = this.getFieldUndefinedEscaped(persona.datosPersonales.nombreCliente);
    logalty.personalInformation.firstLastName = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido1Cliente);
    logalty.personalInformation.secondLastName = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido2Cliente);
    logalty.personalInformation.nationality = this.searchCodeFromValue(PAISES, persona.datosPersonales.paisNacionalidad);
    logalty.personalInformation.countryResidence = this.getFieldUndefinedEscaped(this.searchValue(PAISES, persona.datosPersonales.paisResidencia));
    logalty.personalInformation.identityDocumentType = persona.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
    logalty.personalInformation.identityNumber = persona.datosPersonales.idExterno;
    logalty.personalInformation.identityDocumentExpirationDate = this.getFieldUndefinedEscaped(persona.datosPersonales.fechaCaducidadDocumento);
    logalty.personalInformation.maritalStatus = this.searchValue(ESTADO_CIVIL, persona.datosPersonales.codigoEstadoCivil);
    logalty.personalInformation.birthdate = this.getFieldUndefinedEscaped(persona.datosPersonales.fechaNacimiento);
    logalty.personalInformation.birthCountry = this.searchValue(PAISES, persona.datosPersonales.codigoPaisNacimiento);
    logalty.personalInformation.province = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreProvinciaDomicilio);
    logalty.personalInformation.localization = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreLocalidad);
    logalty.personalInformation.roadType = this.searchValue(VIAS, persona.datosDomicilio.codigoDomicilioTipoVia);
    logalty.personalInformation.homeSituation = this.searchValue(SITUACIONES, persona.datosSocioEco.situacionVivienda);
    logalty.personalInformation.monthlyRentImport = this.getFieldUndefinedEscaped(persona.datosDomicilio.cuotaAlquilerMensual);
    logalty.personalInformation.address.street = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreVia);
    logalty.personalInformation.address.buildingNumber = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioNumero);
    logalty.personalInformation.address.floorLevel = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioPlanta);
    logalty.personalInformation.address.door = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioPuerta);
    logalty.personalInformation.address.portal = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioPortal);
    logalty.personalInformation.address.block = this.getFieldUndefinedEscaped(persona.datosDomicilio.bloque);
    logalty.personalInformation.address.phase = this.getFieldUndefinedEscaped(persona.datosDomicilio.fase);
    logalty.personalInformation.address.plot = this.getFieldUndefinedEscaped(persona.datosDomicilio.parcela);
    logalty.personalInformation.address.postalCode = this.getFieldUndefinedEscaped(persona.datosDomicilio.codigoPostal);
    logalty.personalInformation.address.stairway = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioEscalera);
    logalty.personalInformation.address.locality = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreLocalidad);
    const signatureId = !!(this.productSelect.product && this.productSelect.product.signatureId);
    if (persona.datosPrimerTitular && persona.datosPrimerTitular.id_cliente_EVO && !signatureId) {
      logalty.isSecondSigner = false;
      logalty.signatureUserData2Signer = this.nestedObject.nestedObjectAssign({});
      logalty.signatureUserData2Signer.countryResidence = this.nestedObject.nestedObjectAssign({});

      logalty.signatureUserData2Signer.firstName = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.nombreCliente);
      logalty.signatureUserData2Signer.lastName1 = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.apellido1Cliente);
      logalty.signatureUserData2Signer.lastName2 = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.apellido2Cliente);
      logalty.signatureUserData2Signer.email = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.email);
      logalty.signatureUserData2Signer.mobileNumber = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.telefonoMovil);
      logalty.signatureUserData2Signer.treatment = persona.datosPrimerTitular.sexo === 'H' ? 'M' : 'F';
      logalty.signatureUserData2Signer.signatureDate = `${date}/${month}/${year}`;
      logalty.signatureUserData2Signer.identityDocumentType = persona.datosPrimerTitular.codigoIdExterno === '51' ? 'DNI' : 'NIE';
      logalty.signatureUserData2Signer.signatureId = `${persona.datosPrimerTitular.id_cliente_EVO}_second_signer_1`;
      logalty.signatureUserData2Signer.nif = persona.datosPrimerTitular.idExterno;
      logalty.signatureUserData2Signer.userId = persona.datosPrimerTitular.id_cliente_EVO;
      logalty.signatureUserData2Signer.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(persona.datosPrimerTitular.paisResidencia);
      logalty.signatureUserData2Signer.countryResidence.alpha2Code = "Alpha2";
      logalty.signatureUserData2Signer.countryResidence.englishDescription = "N/A";
      if (persona.datosPrimerTitular && !this.helpers.isEmpty(persona.datosPrimerTitular.productos)){
        logalty.bankInformation2Signer.ibanAccount = this.findProduct(persona.datosPrimerTitular.productos, 'cc');
        logalty.bankInformation2Signer.ibanInvestment = this.findProduct(persona.datosPrimerTitular.productos,'cd');
        logalty.bankInformation2Signer.ibanAccount = 'IBAN proporcionado una vez formalizada la contratación';
        logalty.bankInformation2Signer.ibanInvestment = 'Dato proporcionado una vez formalizada la contratación';
        logalty.bankInformation2Signer.cardNumber = 'Dato proporcionado una vez formalizada la contratación';
        logalty.bankInformation2Signer.userElectronicBank = 'Dato proporcionado una vez formalizada la contratación';
        logalty.bankInformation2Signer.userAlerts = 'Dato proporcionado una vez formalizada la contratación';
      }
    } else {
      if (this.productSelect.product && this.productSelect.product.isSecondSigner) {
        logalty.isSecondSigner = true;
        logalty.signatureUserData2Signer = this.nestedObject.nestedObjectAssign({});
        logalty.signatureUserData = this.nestedObject.nestedObjectAssign({});
        logalty.signatureUserData.signatureId = this.productSelect.product.signatureId;
        this.secondSignerId = signatureiD;
        logalty.signatureUserData2Signer.signatureId = signatureiD;
        logalty.signatureUserData2Signer.firstName = this.getFieldUndefinedEscaped(persona.datosPersonales.nombreCliente);
        logalty.signatureUserData2Signer.lastName1 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido1Cliente);
        logalty.signatureUserData2Signer.lastName2 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido2Cliente);
        logalty.signatureUserData2Signer.email = this.getFieldUndefinedEscaped(persona.datosPersonales.email);
        logalty.signatureUserData2Signer.treatment = persona.datosPersonales.sexo === 'H' ? 'M' : 'F';
        logalty.signatureUserData2Signer.mobileNumber = this.getFieldUndefinedEscaped(persona.datosPersonales.telefonoMovil);
        logalty.signatureUserData2Signer.signatureDate = `${date}/${month}/${year}`;
        logalty.signatureUserData2Signer.identityDocumentType = persona.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
        logalty.signatureUserData2Signer.nif = persona.datosPersonales.idExterno;
        logalty.signatureUserData2Signer.userId = persona.datosPersonales.id_cliente_EVO;
        logalty.signatureUserData2Signer.countryResidence = this.nestedObject.nestedObjectAssign({});
        logalty.signatureUserData2Signer.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(persona.datosPersonales.paisResidencia);
        logalty.signatureUserData2Signer.countryResidence.alpha2Code = "Alpha2";
        logalty.signatureUserData2Signer.countryResidence.englishDescription = "N/A";
        logalty.signatureUserData2Signer.nif = persona.datosPersonales.idExterno;
        logalty.signatureUserData2Signer.userId = persona.datosPersonales.id_cliente_EVO;
        logalty.bankInformation.ibanAccount = this.findProduct(persona.datosSocioEco.productos, 'cc');
        logalty.bankInformation.ibanInvestment = this.findProduct(persona.datosSocioEco.productos, 'cd');
      }
    }
    return logalty;
  }

  setLogalty(state:String, logaltySignatureId: String){

    this.logalty = Object.assign({

    product: state,
    guids: [logaltySignatureId],

     })
     //return objectLogalty;
  }

  scrollToTop(){
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  triggerNavData(pageName, creditLimit = '') {
    if (this.isOperacionAmpliacion){
      this.analytics.triggerCreditLimitDataNavData(
        {'pageName': 'Modificación Límite/'+ pageName, 'creditLimit': creditLimit});
    }
    else {
      this.analytics.triggerInvestmentDataNavDataNew(pageName);
    }
  }

  checkErrorStatusAndGoToError(error, updateCase = false){
    let errorPageEvent = 'KO Error servicio';
    if(error && error.status && error.status.toString() === '401'){
      this.errService.isStatus401 = true;
    }
    if (updateCase){
      this.updateCaseInversora('Error servicio generación Logalty Segundo Titular');
    }

    if (!this.errService.isStatus401) {
      this.triggerNavData('KO Error servicio');
    }

    this.goToError();
  }

}
