<app-header></app-header>
<modal [modalTitle]="'Recomienda'" [blocking]='false' [modalId]='recomiendaModal' class='modal recomienda'>
  <iframe #iframereco class="recomiendaiframe" width="100%" height="100%"></iframe>
</modal>
<div class="everything-done">
  <div class="image"></div>
  <div class="container">

    <div class="title-container">
      <legend>
        <h2>¡Todo listo! Ya tienes tu cuenta <span>{{(isYoungAccount)?'Joven':'Inteligente'}}</span></h2>
      </legend>
    </div>

    <div class="path-container">
        <div class="row process">
          <i class="evo proc-step1secondholder"></i>
          <div class="text-container">
            <p>Recibirás un mail con <strong>tu contrato y tu estado de validación.</strong></p>
          </div>
        </div>
        <div class="separator"></div>
        <div class="row process">
          <i class="evo proc-sms-2"></i>
          <div class="text-container">
            <p>Recibirá <strong>2 SMS</strong></p>
            <ul>
              <li><p>Claves de tu Banca móvil</p></li>
              <li><p>Tu PIN de tarjeta</p></li>
            </ul>
          </div>
        </div>
         <div class="separator"></div>
        <div class="row process">
          <i class="evo proc-card-3"></i>
          <div class="text-container">
            <p>Recibirás tu Tarjeta {{(isYoungAccount)?'de Débito':'Inteligente'}} en 7 días hábiles desde la recepción de tu PIN por SMS</p>
            <p class="info-done">
              Recuerda por seguridad, no actives tu tarjeta hasta recibirla en la dirección que nos has dado.
            </p>
          </div>
        </div>
        
      <!-- -->
        <div class="separator"></div>
        <div class="row process">
          <i class="newIconEvo proc-card-4"></i>
          <div class="text-container">
            <p>Empieza a pagar ya con tu móvil aunque todavía no hayas recibido tu tarjeta. Solo tienes que descargarte la app. No te preocupes tu tarjeta física no se activará hasta que tú no quieras</p>
            <p  class='uppercase-info'
                (click)='goToknowMore()'>SABER MÁS</p>
          </div> 
        </div> 
      </div>
      <div class="title-container ">
        <p> Además, cuando tu cuenta ya esté abierta, podrás incluir un segundo titular desde tu app.</p>
      </div>
      <div class="row">
        <p>Disponible en Android. Próximamente en iOs</p>
        </div>
        <div class="row">
          <div class="badge-container">
            <div id="apple" (click)='goToAppStore()' class="placeholder-badge"></div>
            <div id="google" (click)='goToPlayStore()' class="placeholder-badge"></div>
          </div>
      </div>

    <div class="title-container">
        <legend>
          <p class="constrain">¡Espera! Estamos conectando con nuestro Consejero Delegado para darte la bienvenida, no te mereces menos.</p>
        </legend>
   </div>
    <div class="row">
      <div class="video-container">
        <div *ngIf='showPlay' (click)="hidePlayer(); playVid()" class="video"></div>
        <video #video controls (click)='playVid()' src={{vidService.videoUrl}}></video>
      </div>
    </div>
    <div class="row">
      <p>En unos días recibirás tu Tarjeta <span>{{(isYoungAccount)?'de Débito':'Inteligente'}}</span> en la dirección que nos has proporcionado</p>
    </div>
    <div class="row" *ngIf="!isYoungAccount">
      <div class="mobile-container center">
        <button class='btn-common tall' (click)='goToInfo()'>
          Todo lo que necesitas saber sobre tu cuenta
        </button>
      </div>
    </div>
    

    <!-- <div class="row btn">
      <div class="btn-group">
        <button type="submit" class="btn-common btn-common_fix_bold" tabindex="1"
        (click)='goToProfile()'>
          COMENZAR
        </button>
      </div>
    </div> -->

    <div class="row recomienda" #recomienda *ngIf="!isYoungAccount">
      <i class='evo ko' (click)='closeRecomienda($event)'></i>
      <div class="flex-column">
        <div class="text-container">
          <i class="evo people"></i>
          <h6>recomienda evo</h6>
          <p>Si te ha gustado el proceso y te ha parecido fácil, contagia a tus amigos la forma de ahorrar.</p>
        </div>
        <div class="mobile-container flex-right">
          <h6 class="uppercase-info" (click)='goToRecomienda()'>recomendar</h6><i class="evo right-arrow"></i>
        </div>
      </div>
    </div>

  </div>
</div>
