<app-header></app-header>
<div class="abandonment">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>¡Hasta luego!</h2>
        <h3>¿Podrías decirnos la razón de tu abandono?</h3>
      </legend>
    </div>
    <form [formGroup]='abandonmentForm' (ngSubmit)='onSubmit()'>
      <div class="abandon-checkbox">

          <div class="row">
            <input  type="radio" id="firstOption" value="already-client"
            formControlName="reason"  name="reason">
            <label class="" for="firstOption"><p>Ya soy cliente</p>
            </label>
          </div>
          <div class="row">
            <input  type="radio" id="secondOption" value="busy-user"
            formControlName="reason" name="reason">
            <label class="" for="secondOption">
              <p>
                Ahora no puedo finalizar la contratación, continuaré en otro momento.
              </p>
            </label>
          </div>
          <div class="row">
            <input  type="radio" id="thirdOption" value="not-interested"
            formControlName="reason" name="reason">
            <label class="" for="thirdOption">
              <p>
                No estoy interesado en contratar
              </p>
            </label>
          </div>
          <div class="row">
            <input  type="radio" id="fourthOption" value="help-needed"
            formControlName="reason" name="reason">
            <label class="" for="fourthOption">
              <p>Necesito ayuda</p>
            </label>
          </div>

      </div>
      <div class="row text-contain">
        <textarea name="name" rows="8" cols="80"></textarea>
      </div>
      <div class="row">
        <button class='btn-common' type="submit" name="button">
          Enviar respuesta
        </button>
      </div>
    </form>

  </div>
</div>
