import { Component, OnInit, OnDestroy, AfterContentInit, NgZone } from '@angular/core';
import { Router, NavigationEnd, Route } from '@angular/router';
import { AnalyticsService } from './services/analytics.service';
import 'rxjs/add/operator/filter';
import { environment } from '../environments/environment';
import { ErrorService } from './services/error.service';
import { InjectScriptService } from './services/inject-script.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'app/services/auth.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { Subscription } from 'rxjs';

const firstPageAmpliacion = '/ampliacion-step1';
const firstPageCuentaIversora = '/configuracion-cuenta-inversora?codProductoContratado=009&origen=BE';
const firstPageFondoInteligente = '/configuracion-cuenta-inversora?codProductoContratado=010&origen=BE';

const redirectTo =
  environment.type === 'CINV'
    ? firstPageCuentaIversora
    : environment.type === 'FINT'
    ? firstPageFondoInteligente 
    : firstPageAmpliacion; // AMPLI


@Component({
  selector: 'evo-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  enviroment = environment;
  routerSubscription: Subscription;
  constructor(
    private titleService: Title,
    private router: Router,
    private analytics: AnalyticsService,
    public errService: ErrorService,
    public inject: InjectScriptService,
    private authservice: AuthService
  ) {
  }

  ngOnInit() {
    const r: Route = {
      path: '',
      redirectTo: redirectTo,
      pathMatch: 'full'
    }
    this.router.resetConfig([r, ...this.router.config]);

    const prospect = JSON.parse(sessionStorage.getItem('currentProspect')),
      url = environment.videoUrl,
      urlAdrum = environment.urlAdrum,
      appKey = environment.appKey;
    this.setTitle();
    window.parent['iframe'] = undefined;
    if (prospect && prospect.datosPersonales && !prospect.datosPersonales.id_cliente_EVO) {
      sessionStorage.clear();
    }
    this.routerSubscription = this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe(event => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        this.errService.errorsArray = [];
      });
    this.googleTagManager();

    this.inject.createScript(url);
    this.addrum(urlAdrum, appKey);
    if (prospect && prospect.datosPersonales) {
      this.addHotjarSalesforce(prospect.datosPersonales.id_cliente_EVO);
    }
    this.inject.addScriptGlance(environment.urlGlance, environment.production.toString());
    window['version'] = environment.version;

    
  }

  addHotjarSalesforce(idSalesforce) {
    if (location.hash.indexOf('datos-personal') !== -1) {
      this.inject.createScriptHotjar(idSalesforce);
    }
  }

  googleTagManager() {

    const routeCookieScript = environment.urlCookies;
    this.inject.createScriptCookies(routeCookieScript);
    // google analytics
    // CI
    let tag = 'GTM-TFMGJVG';
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authservice.product === ACCOUNTS.CJCode);
    if (isYoungAccount) {
      // CJ 'cuenta-joven'
      tag = 'GTM-NL3N2GF';
    }
    //Acceso de ecommerce y login
    const ecommerce = (location.hash.indexOf('login') !== -1 && !location.hash.endsWith('login-invitacion'))
      || location.hash.indexOf('perfil') !== -1
      || location.hostname === 'ecommerce.evobanco.com'
      || location.hostname === 'cuentasuattest.evobanco.com'
      || location.hostname === 'modificacionlimitecredito.evobanco.com'
      || location.hostname === 'fondointeligente.evobanco.com'
      || location.hostname === 'cuentainversora.evobanco.com';
    if (ecommerce) {
      // CEF
      tag = 'GTM-TFMGJVG';
    }

    const microseg = location.hash.indexOf('evo-protect') !== -1;
    const altaClienteA = location.hash.indexOf('alta-cliente') !== -1;
    if (microseg  || altaClienteA) {
      tag = 'GTM-P5QQPP5';
    }
    this.inject.addScriptGoogle('script', tag);
    //this.inject.addScriptGoogle('noscript', tag);
  }

  ngAfterContentInit() {
    this.analytics.subscribeToNav(this.router);
  }

  addrum(url, appKey) {
    window['adrum-start-time'] = new Date().getTime();
    (function (config) {
      config.appKey = appKey;
      config.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
      config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
      config.beaconUrlHttp = 'http://' + url;
      config.beaconUrlHttps = 'https://' + url;
      config.xd = { enable: true };
    })(window['adrum-config'] || (window['adrum-config'] = {}));
    this.inject.createScript('https://cdn.appdynamics.com/adrum/adrum-4.3.5.0.js');
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  setTitle() {
    //CI
    let newTitle = "EVO Banco | Contratación Cuenta Inteligente";
    //MICSEG
    const isMicSeg = location.href.indexOf('sin-tarificador') !== -1;
    if (isMicSeg) {
      newTitle = "EVO Banco | Contratación Evo Protect";
    }
    const isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authservice.product === ACCOUNTS.CJCode);
    const isCinv = this.isCinvOrFint() === 'cinv' ? true : false;
    const isFinv = this.isCinvOrFint() === 'fint' ? true : false;
    if (isYoungAccount) {
      // CJ
      newTitle = "EVO Banco | Contratación Cuenta Joven";
    }
    if (isCinv) {
      // Cuenta Inversora
      newTitle = "EVO Banco | Contratación de Cuenta Inversora";
    }
    if (isFinv) {
      // Fondo Inteligente
      newTitle = "EVO Banco | Contratación de Fondo Inteligente";
    }
    const ecommerce = location.hash.indexOf('login') !== -1 || location.hash.indexOf('perfil') !== -1;
    if (ecommerce && !isCinv && !isFinv) {
      // CEF
      newTitle = "EVO Banco | Área Personal eCommerce";
    }

    const isAltaClienteA = sessionStorage.getItem("altaClienteA") === "true";
    if (isAltaClienteA) {
      // Alta Clientes A
      newTitle = "Hazte EVO Lover | EVO Banco";
    }

    this.titleService.setTitle(newTitle);
  }

  isCinvOrFint() {
    const result = this.getParameterByName('codProductoContratado');
    let product = null;
    if (result === "009") {
      product = 'cinv';
    };
    if (result === "010") {
      product = 'fint';
    };
    return product;
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
