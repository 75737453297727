import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResponseSecurity } from '../../collections/security';
import { Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';

const API_URL = environment.apiUrl;


@Injectable()
export class ApiSecurityService {

    private options = {};

    constructor(private httpClient: HttpClient) {
        this.options = { withCredentials: true, observe: 'response' };
    }

    getPublicIP(): Observable<any> {
        return this.httpClient.get(`https://api.ipify.org/?format=json`)
        .pipe(map(res => {
            return res['ip'];
        }));
    }

}
