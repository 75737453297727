<div #pdfContainer class="inner__content info">
    <p class="info-title">{{title}}</p>
    
    <pdf-viewer 
        [src]="pdfSrc" 
        [render-text]="false" 
        [original-size]="false"
        [autoresize]="true"
        [show-all]="true" 
        [zoom]="zoom"
        style="display:inline-block; overflow: scroll; width:75vw;height: 60vh; border: 1px solid grey;" 
        (after-load-complete)="afterLoadComplete($event)"
        >
    </pdf-viewer>
    <div class="zoom-control">
        <button class="btn-zoom-out" (click)="zoomOut()">-</button>
        <button class="btn-zoom-in" (click)="zoomIn()">+</button>
    </div>
    <div class="btn-group_fix">
        <button class="btn-common btn-common_fix btn-common_fix_bold"  [disabled]="closeButtonDisabled" (click)="close()">
            {{button}}
        </button>
    </div>
</div>


