class base {
  element: any;
  totalPages: number;
  page: number;
  pageSize: number;
  totalRecords: number;
  records: number;
  bytesArray: any;
  contentType: any;
  error: any;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  };
}

export class MicrosegConfigResponse extends base{
  list: [{
    config: string;
    elementValue: string;
    elementDesc: string;
    elementOrder: string;
  }];
  constructor(values: Object = {}) {
    super();
    Object.assign(this, values);
  };
};

export class MicrosegurosConfigCapitales extends base{
  listGlobalCapital: [
    {
      config: "PDF_CAPITALGLOBAL";
      elementOrder: string;
      amount: string;
      entity: string;
      cfAmount: string;
      cfMandatory: boolean;
      cipAmount: string;
      cipMandatory: boolean;
      ciaAmount: string;
      ciaMandatory: boolean;
      ccmAmount: string;
      ccmMandatory: boolean;
      crcAmount: string;
      crcMandatory: boolean;
      scope: string;
    }
  ];
  constructor(values: Object = {}) {
    super();
    Object.assign(this, values);
  };
}
