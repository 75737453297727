import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'microinsurances-screen',
  templateUrl: './microinsurances-screen.component.html',
  styleUrls: ['./microinsurances-screen.component.scss']
})
export class MicroinsurancesScreenComponent implements OnInit {

  constructor(private router:Router) {
    
  }

  ngOnInit() {

  }


}