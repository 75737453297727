import { Injectable } from '@angular/core';
import { ApiLocalidadesService } from './api/api.localidades.service';
import { Observable } from 'rxjs';
import { Localidades } from '../collections/localidades';

@Injectable()
export class LocalidadesService {

  constructor(private api:ApiLocalidadesService) { }

  getLocalidades(data:Localidades):Observable<any>{
    return this.api.getLocalidades(data);
  }

  getLocalidadesData():Observable<any>{
    return this.api.getLocalidadesData();
  }
}
