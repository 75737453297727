import { Step } from "app/collections/step";

export var STEPS_MICROBREADCRUMBS: Step[] = [
    {
        id: 0,
        selected: false,
        icon: "micro-configure",
        URL: 'evo-protect',
        subURLs: [],
        tooltip: "",
        text: "Antes de.."
      },
    {
        id: 1,
        selected: false,
        icon: "sign",
        URL: 'evo-protect/logalty',
        subURLs: [],
        tooltip: "",
        text: "Boletín de Adhesión"
    },
    {
        id: 2,
        selected: false,
        icon: "micro-tarjetas",
        URL: 'evo-protect/configura-microseguro',
        subURLs: [],
        tooltip: "",
        text: "Pago"
    },

    {
        id: 3,
        selected: false,
        icon: "proc-ok",
        URL: 'evo-protect/todo-listo',
        subURLs: [],
        tooltip: "",
        text: "Confirmación"
    }
];
  