<div class="footer" [ngClass]='microseguros? "force-top": ""'>
  <div class="logo-container">
    <h3 class='logo'></h3>
  </div>
  <div class="frame"></div>
  <p> 2018 EVO Banco, S.A. Todos los derechos reservados 
      |<a href="https://www.evobanco.com/politica-de-privacidad/"> Privacidad</a>
      |<a href="https://www.evobanco.com/cookies/"> Cookies </a>
      |<a href="https://www.evobanco.com/aviso-legal/"> Aviso legal </a>
    </p>
</div>
