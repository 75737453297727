import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

@Component({
  selector: 'segundoTitular-finish',
  templateUrl: './segundoTitular-finish.component.html',
  styleUrls: ['./segundoTitular-finish.component.scss']
})
export class SegundoTitularFinishComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
}
