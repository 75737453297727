
export const REGISTERLITERALS = {
  young: {
    online: '',
    title: '',
    subtitle: '' +
              '',
    bienvenido: {
      title: 'CONTRATACIÓN DE LA CUENTA JOVEN',
      subtitle:'Primero, déjanos tu móvil. Antes de empezar, ten en cuenta lo siguiente:',
      title_p: [
        'Ten tu DNI o NIE (con foto) a mano.',
        'Debes tributar en España.',
        'Solo puedes ser titular de una Cuenta Joven.']
    },
  },
  default: {
    online: '100% ONLINE',
    title: 'ABRE TU CUENTA INTELIGENTE EN MENOS DE 10 MINUTOS.',
    subtitle: 'Una cuenta donde todo está en tu móvil. Porque desde la APP podrás gestionar tus gastos, ' +
              'ingresos y pagar con tu móvil de una manera fácil.',
    bienvenido: {
      title: 'ABRE TU CUENTA INTELIGENTE EN MENOS DE 10 MINUTOS',
      subtitle:'Primero, déjanos tu móvil. Antes de empezar, ten en cuenta lo siguiente:',
      title_p: [
        'Ten tu DNI o NIE con foto',
        'Tributar en España.',
        'No tener ya la Cuenta Inteligente']
    }
  },
  modal: {
    literal:'De conformidad con lo establecido en el Reglamento 679/2016 General de Protección de Datos y normativa complementaria,' +
    'te informamos de que tus datos serán incorporados a los ficheros de EVO BANCO, S.A. en tanto entidad responsable del tratamiento' +
    'automatizado o no, de los mismos.  Los datos facilitados a lo largo de la presente solicitud, entre ellos aquellos utilizados para ' +
    'la identificación no presencial mediante videoconferencia así como la grabación y conservación del presente proceso de contratación,' +
    'incluida la IP desde donde se realiza, serán tratados con la finalidad de estudiar tu solicitud y posterior gestión de tu cuenta. Asimismo,' + 
    'tus datos serán tratados con finalidad estadística conforme a técnicas de agregación y seudonimización para analizar patrones globales sobre ingresos'+ 
    'y gastos de usuarios, pero en ningún momento como consecuencia de este tratamiento se generarán patrones de carácter longitudinal respecto del uso de '+
    'determinados servicios o la singularización de usuarios, no siendo posible la vinculación directa o indirecta de la información con un determinado usuario.'+
    'Para el estudio de tu solicitud, Evo Banco, S.A. podrá consultar ficheros de las empresas del grupo, datos que constan en redes sociales, así como en ficheros '+
    'de solvencia económica y podrán ponerse en contacto contigo tanto telefónicamente como por otros medios, incluidos los telemáticos, con la misma finalidad. '+
    'Al marcar la casilla anterior, consientes expresamente que estos datos sean almacenados y tratados en un fichero automatizado y manual del que Evo Banco, S.A.'+ 
    'es responsable. No aceptar estas condiciones implicaría no poder seguir adelante con el proceso de contratación.'+
    'Los datos objeto de dicho tratamiento se conservarán en la medida en que sean necesarios para las finalidades descritas y en cumplimiento de la normativa aplicable.'+ 
    'Conservaremos la información personal con las medidas de seguridad adecuadas en función de los datos y tratamientos por 10 años. No obstante, en todo momento podrás '+
    'ejercer tus derechos de acceso, rectificación, cancelación, portabilidad, limitación y oposición al tratamiento de datos a través del correo electrónico protecciondedatos@evobanco.com '+
    'o bien por escrito dirigido a Evo Banco, S.A. en la Calle Don Ramón de la Cruz 84, CP28006 de Madrid, indicando en el asunto “Protección de Datos” y adjuntando copia de tu DNI o documento identificativo '+
    'válido, a cualquiera de ellos. Asimismo, te informamos de que tienes derecho a recabar la tutela de la Agencia Española de Protección de Datos a través de su página Web www.aepd.es.'+
    'Por otro lado, quedas informado de la comunicación de los datos de la presente solicitud al Fichero CONFIRMA, para la finalidad de prevención de fraude.'+
    'Los responsables de dicho fichero son las Entidades Adheridas al Reglamento del Fichero CONFIRMA, siendo el encargado del tratamiento Confirma Sistemas de Información,'+ 
     'S.L., con domicilio en la Avda, de la Industria, 18, TRES CANTOS (28760) MADRID. Podrás consultar el listado de Entidades que actualmente están adheridas al Reglamento del'+ 
     'Fichero CONFIRMA en www.confirmasistemas.es. Los datos comunicados al Fichero CONFIRMA podrán ser cedidos a las Entidades Adheridas al Reglamento del Fichero CONFIRMA para '+
     'la finalidad previamente indicada. Podrás ejercer tus derechos de acceso, rectificación, cancelación, limitación, portabilidad y oposición, dirigiéndote al domicilio de '+
     'CONFIRMA SISTEMAS DE INFORMACIÓN, S.L. en la dirección arriba indicada.',
     comunicaciones_comerciales:'¿Consientes recibir comunicaciones comerciales sobre productos o servicios de terceros, adaptados a tu' +
     'perfil, que Evo comercialice por cualquier medio y la cesión de datos a terceros con esa finalidad, así como' +
     'recibir o acceder a ofertas sobre productos no similares a los contratados con nosotros?'
  }
};
