import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthUserService } from '../../../services/auth-user.service';
import { SpecificAnalyticsService } from '../../../services/specific-analytics.service';
import { LoginService } from '../../../services/login.service';
import { SecondHolderService } from '../../../services/second-holder.service';
import { HelperService } from '../../../services/helpers.service';
import { ErrorService } from '../../../services/error.service';
import { ModalService } from '../../../services/modal.service';
import { RecomiendaService } from '../../../services/recomienda.service';
import { User } from '../../../collections/user';

import { NOTIFICATIONS } from '../../../resources/notifications.model';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { Observable } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { AuthGuardMicro } from 'app/services/auth-guard-micro.service';
import { MyInterestService } from 'app/services/my-interest.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { MyProductsService } from '../../../services/my-products.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { PersonaService } from '../../../services/persona.service';
import { Persona } from 'app/collections/persona';
import { SecurityService } from '../../../services/security-registration.service';
import { ERRORSFORM } from 'app/resources/errors-form';

@Component({
  selector: 'app-logged-segtit',
  templateUrl: './logged-segundoTitular.component.html',
  styleUrls: ['./logged-segundoTitular.component.scss'],
  providers: [ModalService, RecomiendaService]
})
export class LoggedSegundoTitularComponent implements OnInit {
  @ViewChild('iframereco') public iframereco: ElementRef;
  public slidesEmulated: Array<any> = [
    { image: './assets/images/sliders/slider_1.jpg', title: '', text: '', goTo: '' }
  ];

  public photoInterval = 5000;
  public noLoopSlides = false;
  public loading = false;
  private secondHolderForm: FormGroup;
  private userData: User;
  public isSendSecondUser: boolean = false;
  public canHaveSecondHolder: boolean = true;
  public addSecondHolder: string = "AÑADIR SEGUNDO TITULAR";
  private accToken: string;
  public clientID;
  public notifications = NOTIFICATIONS;
  public modalLegal = 'legalModal';
  public errorSendModal = 'errorSendModal';
  public fieldError = { field: 'genericError', text: '¡UPS! Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.', code: 100 };
  public tieneSolicitudes: boolean = false;
  public solicitudesEnCurso = [];
  public reload: boolean = false;
  private sessionUserData;
  public errors = ERRORSFORM;

  //BMI
  public accessToken: any;
  public idExterno: any;
  public refreshToken: any;
  public origen: any;
  public isBmi: boolean = false;
  public emailErrorClear: boolean = true;

  constructor(
    private authService: AuthUserService,
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private helpers: HelperService,
    public errService: ErrorService,
    public modService: ModalService,
    private loginSrv: LoginService,
    private recomienda: RecomiendaService,
    private secondHolderSrv: SecondHolderService,
    private microConfigSrv: MicrosegConfigService,
    private analytics: AnalyticsService,
    public specificAnalytics: SpecificAnalyticsService,
    public authguardmicro: AuthGuardMicro,
    public myInteresetSrv: MyInterestService,
    public myProductsService: MyProductsService,
    private personaSrv: PersonaService,
    private activatedRoute: ActivatedRoute,
    private securitySrv: SecurityService
  ) {
    this.createForm();
  }

  ngOnInit() {
    sessionStorage.setItem("segundo-titular", "datosSegundoTitular");

    this.getQueryParams();
    this.checkSessionStorage();
    this.auth.product = ACCOUNTS.STCode;
    sessionStorage.removeItem("casoSeleccionado");
    sessionStorage.removeItem("codProdAmpliacion");
    sessionStorage.removeItem("operacion-ampliacion");
    sessionStorage.removeItem("limite-solicitado");
    sessionStorage.removeItem("tarjetaSeleccionada");
    sessionStorage.removeItem("submit");
    sessionStorage.removeItem("operacion-inversora");
    sessionStorage.removeItem("productoContratando");
    sessionStorage.removeItem("isSecondSigner");
    sessionStorage.removeItem("ibanCuentaSeleccionada");

    this.loadUserData();
    this.authguardmicro.empty();
  }

  private createForm() {
    const emailRegex = /.+@.+\..+/i;
    this.secondHolderForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex), Validators.maxLength(45)]],
      confirmEmail: ['', [Validators.required, Validators.pattern(emailRegex), Validators.maxLength(45)]],
      checkLegal: ['', [Validators.required]]
    })

    this.formSubscribe(this.secondHolderForm);
  }

  loadUserData() {
    if (this.loginSrv.isLoggedIn) {
      this.loading = true;
      if (!this.helpers.isEmpty(this.loginSrv.userData) && !sessionStorage.getItem("reloadUserData")) {
        this.loading = false;
        this.userData = this.loginSrv.userData;

        this.clientID = this.userData.persona.id_cliente_EVO;
        this.reload = true;
        return
      }
      sessionStorage.removeItem("reloadUserData");

      if (!this.idExterno && this.sessionUserData && this.sessionUserData.persona) {
        this.idExterno = this.sessionUserData.persona.idExterno;
      }

      this.personaSrv.getPersonaById("IDE-99999999", ACCOUNTS.CICode).subscribe(data => {
        this.loading = false;
        this.userData = this.loginSrv.userData;

        this.clientID = data.datosPersonales.id_cliente_EVO;
        const dni = data.datosPersonales.documento;
        this.specificAnalytics.emitHashedDni(dni);
        this.loginSrv.userInfo = data;
        this.loginSrv.comprobacionDatos();

      }, error => {
        this.errService.rsi = true;
        let redirectPage: string = this.isBmi ? 'https://www.evobanco.com/segundo-titular-fin/' : 'login-invitacion';
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', redirectPage);
      });
    } else {
      this.helpers.navigateTo('/login-invitacion');
    }
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.token && params.refresh && params.origen && params.dni) {
        this.accessToken = params.token;
        this.refreshToken = params.refresh;
        this.idExterno = params.dni;
        this.origen = params.origen.toLowerCase();
        sessionStorage.setItem('auth_token', this.accessToken);
        sessionStorage.setItem('refresh_token', this.refreshToken);
        sessionStorage.setItem('idInternoPe', params['idInterno']);
        if (this.origen === "bmi") {
          this.isBmi = true;
          this.loginSF(this.idExterno)
        }
      }
    });
  }
  /**
   * @description Función para realizar el login de SF.
   * El objetivo de ejecutar esta función es actualizar el SF los datos del usuario logado , dado que ha día de hoy (25/09/2019)
   * desde BMI no se esta realizando esta llamada.
   * En caso de que esta condición cambie en el futuro , debería borrarse este logín en 2º titular.
   * @param dni
   */
  private loginSF(dni) {
    this.authService.getUserData(dni).subscribe(
      data  => {console.log(data)},
      error => {console.log(error)}
    )
  }

  /**
 * Recoge de session los datos de las variables de control de flujo y de los datos de usuario
 */
  private checkSessionStorage() {
    this.sessionUserData = JSON.parse(sessionStorage.getItem('userData'));
  }

  checkCondiciones() {
    this.modService.close(this.modalLegal);
  }

  private formSubscribe(form) {

    this.secondHolderForm.get('checkLegal').valueChanges.subscribe(legal => {
      const conditionsCheck = this.secondHolderForm.get('checkLegal');
      if (conditionsCheck.value === false) {
        this.modService.close(this.modalLegal);
      }
      else if (conditionsCheck.value === true) {
        this.helpers.resetFieldsState(this.secondHolderForm, ['checkLegal']);
        this.modService.open(this.modalLegal);

        if (this.emailErrorClear) {
          this.errService.errorsArray = [];
        }
      }
    })
  }

  private openLegal() {
    this.helpers.resetFieldsState(this.secondHolderForm, ['checkLegal']);
    const conditionsCheck = this.secondHolderForm.get('checkLegal');
    if (conditionsCheck.value === "" || conditionsCheck.value === false) {
    }
    else if (conditionsCheck.value === true) {
      this.modService.open(this.modalLegal);
    }
  }

  inFocus() {
    this.emailErrorClear = true;
    this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
    this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);

    if (!this.secondHolderForm.get('checkLegal').hasError('notChecked')) {
      this.errService.errorsArray = [];
    }
  }

  outFocus(input) {
    this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
    this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
  }

  private onSubmit() {
    this.errService.errorsArray = [];
    this.loading = true;

    this.secondHolderForm.controls["email"].updateValueAndValidity();
    this.secondHolderForm.controls["confirmEmail"].updateValueAndValidity();

    const checkbox = this.secondHolderForm.get('checkLegal'),
      form = this.secondHolderForm;

    if (!checkbox.value) {
      this.loading = false;
      this.secondHolderForm.get('checkLegal').setErrors({ notChecked: true });
      this.analytics.triggerSegundoTitularFormData('Formulario datos segundo titular', 'Envio KO: No se han aceptado los términos y condiciones legales');

      this.helpers.updateFieldStatus(this.secondHolderForm, 'checkLegal');
    }
    else {
      this.helpers.resetFieldsState(this.secondHolderForm, ['checkLegal']);
    }

    if (form.get('email').valid || form.get('confirmEmail').valid) {

      if (form.get('email').value !== form.get('confirmEmail').value) {
        this.loading = false;

        this.analytics.triggerSegundoTitularFormData('Formulario datos segundo titular', 'Envio KO: Emails no coinciden');
        form.get('email').setErrors({ notEquals: true });
        form.get('confirmEmail').setErrors({ notEquals: true });
        this.helpers.updateFieldStatus(this.secondHolderForm, 'email');
        this.helpers.updateFieldStatus(this.secondHolderForm, 'confirmEmail');
      }
    }

    else {
      this.loading = false;
      this.emailErrorClear = false;
      this.analytics.triggerSegundoTitularFormData('Formulario datos segundo titular', 'Envio KO: Email inválido');

      if (!form.get('email').valid) {
        if (form.get('email').value !== "") {
          this.secondHolderForm.get('email').setErrors({ invalid: true });
        }

        this.helpers.updateFieldStatus(this.secondHolderForm, 'email');
      }
      if (!form.get('confirmEmail').valid) {
        if (form.get('confirmEmail').value !== "") {
          this.secondHolderForm.get('confirmEmail').setErrors({ invalid: true });
        }
        else {
        }
        this.helpers.updateFieldStatus(this.secondHolderForm, 'confirmEmail');
      }
    }

    // Si no hay error, se llama al servicio de invitación de segundo titular
    if (this.errService.errorsArray.length == 0) {
      this.secondHolderSrv.createSecondHolder(form.get('email').value, ACCOUNTS.CICode, this.clientID, sessionStorage.getItem('idInternoPe')).subscribe(
        data => {
          this.loading = false;
          sessionStorage.setItem("reloadUserData", "true");
          this.isSendSecondUser = true;
          this.analytics.triggerSegundoTitularFormData('Formulario datos segundo titular', 'Envio OK');
          this.analytics.triggerSegundoTitularNavData(
            { 'pageName': 'Cuenta Inteligente/Datos segundo titular OK' }
          );
        },
        error => {
          this.loading = false;
          this.isSendSecondUser = false;
          this.analytics.triggerSegundoTitularFormData('Formulario datos segundo titular', 'Envio KO: 500 Server error');
          this.errService.modDataError = true;
          let redirectPage: string = this.isBmi ? 'segundo-titular-fin' : 'login-invitacion';
          let mesageModal = 'Ya existe un cliente con el email introducido y con el producto a contratar';
          if (error.error.resultCode == 35 || error.error.resultCode == 39) {
            this.errService.navToErrorModData('ASTRONAUTA-S', 'ENTENDIDO', redirectPage);
          }else if(error.error.resultCode == 40){
            this.errService.navToErrorModData('ALIEN-C', 'ENTENDIDO', redirectPage);
          //Mostrar mensaje si ya existe un cliente con el mail introducido
          }else if (error.error.resultCode == 0 && mesageModal === error.error.resultMessage){
            this.modService.open(this.errorSendModal);
          } else {
            this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', redirectPage);
          }
        }
      );
    }
  }

  finish() {
    if (this.isBmi) {
      window.location.href = 'https://www.evobanco.com/segundo-titular-fin/';
    }
    else {
      window.location.href = 'https://www.evobanco.com/clientes/';
    }
  }

  resetForm() {
    this.secondHolderForm.reset({ email: '', confirmEmail: '', checkConditions: false });
  }
  closeErrorSend() {
    this.resetForm();
    this.modService.close(this.errorSendModal);
  }

}
