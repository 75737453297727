import { Component, Input, OnDestroy } from '@angular/core';
import { SmallSlide } from './small-slide/small-slide.component';

export enum Direction {UNKNOWN, NEXT, PREV}


@Component({
  selector: 'small-slider',
  templateUrl: './small-slider.component.html',
  styleUrls: ['./small-slider.component.scss'],
})

export class SmallSliderComponent implements OnDestroy {
      @Input() public noWrap:boolean;
      @Input() public noPause:boolean = true;
      @Input() public noTransition:boolean;
      @Input() public text:string;
      @Input() public arrowStyle:string;

      @Input() public get interval():number {
          return this._interval;
      }

      public set interval(value:number) {
          this._interval = value;
          this.restartTimer();
      }

      public slides: Array<SmallSlide> = [];
      private currentInterval:any;
      private isPlaying:boolean;
      private destroyed:boolean = false;
      private currentSlide:SmallSlide;
      private _interval:number;

      public ngOnDestroy() {
          this.destroyed = true;
      }

      public select(nextSlide:SmallSlide, direction:Direction = Direction.UNKNOWN) {
          const nextIndex = nextSlide.index;
          if (direction === Direction.UNKNOWN) {
              direction = nextIndex > this.getCurrentIndex() ? Direction.NEXT : Direction.PREV;
          }

          // Prevent this user-triggered transition from occurring if there is already one in progress
          if (nextSlide && nextSlide !== this.currentSlide) {
              this.goNext(nextSlide, direction);
          }
      }

      private goNext(slide: SmallSlide, direction: Direction) {
          if (this.destroyed) {
              return;
          }

          slide.direction = direction;
          slide.active = true;

          if (this.currentSlide) {
              this.currentSlide.direction = direction;
              this.currentSlide.active = false;
          }

          this.currentSlide = slide;

          // every time you change slides, reset the timer
          this.restartTimer();
      }

      private getSlideByIndex(index:number) {
          const len = this.slides.length;
          for (let i = 0; i < len; ++i) {
              if (this.slides[i].index === index) {
                  return this.slides[i];
              }
          }
      }

      private getCurrentIndex() {
          return !this.currentSlide ? 0 : this.currentSlide.index;
      }

      public next() {
          if (this.isDesktop) return this.scrollTo('right');

          const newIndex = (this.getCurrentIndex() + 1) % this.slides.length;

          if (newIndex === 0 && this.noWrap) {
              this.pause();
              return;
          }

          return this.select(this.getSlideByIndex(newIndex), Direction.NEXT);
      }

      public prev() {
          if (this.isDesktop) return this.scrollTo('left');

          const newIndex = this.getCurrentIndex() - 1 < 0 ? this.slides.length - 1 : this.getCurrentIndex() - 1;

          if (this.noWrap && newIndex === this.slides.length - 1) {
              this.pause();
              return;
          }

          return this.select(this.getSlideByIndex(newIndex), Direction.PREV);
      }

      private restartTimer() {
          this.resetTimer();
          const interval = +this.interval;
          if (!isNaN(interval) && interval > 0) {
              this.currentInterval = setInterval(() => {
                  const nInterval = +this.interval;
                  if (this.isPlaying && !isNaN(this.interval) && nInterval > 0 && this.slides.length) {
                      if (!this.isDesktop) this.next();
                  } else {
                      this.pause();
                  }
              }, interval);
          }
      }

      private resetTimer() {
          if (this.currentInterval) {
              clearInterval(this.currentInterval);
              this.currentInterval = null;
          }
      }

      public play() {
          if (!this.isPlaying) {
              this.isPlaying = true;
              this.restartTimer();
          }
      }

      public scrollTo(direction) {
        const scrollableDiv = document.getElementById('carousel'),
              scrollPixels = scrollableDiv.offsetWidth;
        if (direction === 'left') {
          for (let i = 0; i < scrollPixels; i++) {
            setTimeout(function(){scrollableDiv.scrollLeft -= 1},100);
          }
        } else {
          for (let i = 0; i < scrollPixels; i++) {
            setTimeout(function(){scrollableDiv.scrollLeft += 1},100);
          }
        }
      }

      public pause() {
          if (!this.noPause) {
              this.isPlaying = false;
              this.resetTimer();
          }
      }

      public addSlide(slide: SmallSlide) {
          slide.index = this.slides.length;
          this.slides.push(slide);
          if (this.slides.length === 1 || slide.active) {
              this.select(this.slides[this.slides.length - 1]);
              if (this.slides.length === 1) {
                  this.play();
              }
          } else {
              slide.active = false;
          }
      }

      public removeSlide(slide: SmallSlide) {
          this.slides.splice(slide.index, 1);

          if (this.slides.length === 0) {
              this.currentSlide = null;
              return;
          }

          for (let i = 0; i < this.slides.length; i++) {
              this.slides[i].index = i;
          }
      }

      private get isDesktop() {
        return window.innerWidth > 996
      }


}
