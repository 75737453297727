import { Component, HostListener, ViewRef, ElementRef, OnInit, OnDestroy, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
// import { Ng2PicaService } from 'ng2-pica';

import { Persona } from '../../../collections/persona';
import { AuthService } from '../../../services/auth.service';
import { PersonaService } from '../../../services/persona.service';
import { FormValidatorService } from '../../../services/form-validator.service';
import { ErrorService } from '../../../services/error.service';
import { ModalService } from '../../../services/modal.service';
import { DeviceService } from '../../../services/device.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { HeaderService } from '../../../services/headers.service';
import { AmpliacionService } from '../../../services/ampliacion.service';
import { LoginService } from '../../../services/login.service';
import { environment } from '../../../../environments/environment';
import { JsonService } from '../../../services/json.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { LoadingCustomComponent } from '../../../components/loading-custom/loading-custom.component';


@Component({
  selector: 'update-dni',
  templateUrl: './update-dni.component.html',
  styleUrls: ['./update-dni.component.scss'],
  providers: [ModalService, FormValidatorService, JsonService, DeviceService]
})
export class UpdateDniComponent implements OnInit, OnDestroy, AfterViewInit {

  public friendlyMessage = '';
  public friendlyTitle = '';
  public friendlyModal = 'friendly';
  loading = false;
  loadCustom = false;
  titulo = "";
  imagen = "";
  subtitulo = "";
  boton = "";
  messageLoading = false;
  dniForm: FormGroup;
  uploadImageFirstOptionText = 'Escoge una imagen o';
  uploadImageSecondOptionText = 'arrastra un archivo';
  frontDNI = 'Escoge una imagen o arrastra un archivo';
  backDNI = 'Escoge una imagen o arrastra un archivo';
  dniData: FormData = new FormData();
  detailModal = 'detail';
  incompleteModal = 'incomplete';
  exceptionModal = 'exception';
  incorrectFormatModal = 'incorrectFormat';
  incorrectTitle = 'Archivo no válido';
  incorrectText = '¡Vaya! La imagen debe de tener uno de los siguientes formatos: bmp, dib, jpeg, jpg, jpe, png, pbm, pgm, ppm, tiff, tif, jp2';
  modalDniSpecs = 'specs';
  private extensions: Array<string> = [
    'bmp', 'dib', 'jpeg', 'jpg', 'jpe', 'png', 'pbm', 'pgm', 'ppm', 'tiff', 'tif', 'jp2',
    'BMP', 'DIB', 'JPEG', 'JPG', 'JPE', 'PNG', 'PBM', 'PGM', 'PPM', 'TIFF', 'TIF', 'JP2'
  ];
  public msgSrvError = '';
  public modalSrvError = 'modal-srv-error';
  public modalBlock = false;
  public hiddeBanner: boolean = false;
  public incorrectFormatBanner: boolean = false;
  public showWarningValidating: boolean = false;
  image: any = {};
  upload: any = {};
  documentSide;
  private persona: Persona = this.authService.getSessionProspect();
  public pers;
  imageFront: any = {
    side: undefined,
    file: undefined,
    resize: false
  };
  imageBack: any = {
    side: undefined,
    file: undefined,
    resize: false
  }
  title = '¡Vaya!';
  msg = 'La imagen del documento que nos has facilitado no es correcta. Inténtalo de nuevo.';
  link = 'Volver a intentarlo';
  modal_ko = 'modal-ko';
  modal_ok = 'modal-ok';
  modal_ok_dni = 'modal_ok_dni';
  firstTry = true;
  showSpecText = true;
  clientId: string;
  exceptionTitle: string;
  exceptionText: string;
  cuentaEvo = '0239';
  public name: string;
  private solicitudDni;
  public dniActualizado: boolean = false;
  public btnShow: boolean = true;
  public isYoungAccount = false;
  public estado: string;
  public screenWidth: number;
  public isBmi = false;

  public subDocumentacion: boolean = false;

  public showAllOk: boolean = false;
  public messageAllOk: string = "";
  public isContratacion: boolean = false;
   showError = false;
   nameError = "";

  constructor(public errService: ErrorService,
    private fb: FormBuilder,
    private router: Router,
    public modService: ModalService,
    private evoValidator: FormValidatorService,
    private authService: AuthService,
    private personaSrv: PersonaService,
    private deviceService: DeviceService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService,
    public ampliacionService: AmpliacionService,
    private jsonService: JsonService,
    private changeDetector: ChangeDetectorRef,
    private loginSrv: LoginService,
    private activatedRoute: ActivatedRoute
    // private picaService: Ng2PicaService
  ) {


    this.screenWidth = window.innerWidth;
    this.textChange();
    //this.errService.mobileErrors(this.dniForm);
    const subidaDoc = JSON.parse(sessionStorage.getItem("subidaDocumentacion"));
    if (subidaDoc == true) {
      this.clientId = sessionStorage.getItem("idClienteEvo");
      let idProductoContratado =  sessionStorage.getItem('idProductoContratado');
      this.personaSrv.getPersonaById(this.clientId, idProductoContratado).subscribe((data) => {
        this.loading = false;
        sessionStorage.setItem("userInfo",JSON.stringify(data));
        this.subDocumentacion = true;
        this.loginSrv.comprobacionDatos();
      }, error => {
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
      });
    }
    else {
      this.clientId = JSON.parse(sessionStorage.getItem('userData')).persona.id_cliente_EVO;
      this.loginSrv.comprobacionDatos();
    }

    if (this.authService.getSessionProspect().confirma && this.authService.getSessionProspect().confirma.imageFront) {
      this.imageFront = {
        side: 'front',
        file: this.authService.getSessionProspect().confirma.imageFront.file,
        resize: true
      };
      this.upload['front'] = true;
      this.image['front'] = 'data:image/png;base64,' + this.authService.getSessionProspect().confirma.image1;
    }

    if (this.authService.getSessionProspect().confirma && this.authService.getSessionProspect().confirma.imageBack) {
      this.imageBack = {
        side: 'back',
        file: this.authService.getSessionProspect().confirma.imageBack.file,
        resize: true
      }
      this.upload['back'] = true;
      this.image['back'] = 'data:image/png;base64,' + this.authService.getSessionProspect().confirma.image2;
    }

    this.loadForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
    this.textChange();
  }

  @HostListener('window:dragover', ['$event'])
  onDrag(e) {
    this.highlightDragModule(e);
    e.preventDefault();
  }

  @HostListener('window:drop', ['$event'])
  onDrop(e) {
    e.preventDefault();
  }


  ngOnInit(): void {
    this.getQueryParams();
    sessionStorage.removeItem('vueltaSubDoc');
    const subidaDoc = JSON.parse(sessionStorage.getItem("subidaDocumentacion"));
    if (subidaDoc == true) {
      this.btnShow = false;
      this.loginSrv.comprobacionDatos();
    }

    if(sessionStorage.getItem('showTodoOK') === 'true'){
      this.messageAllOk = "Tus datos han sido modificados correctamente";
      this.showAllOk = true;
    }

    if (sessionStorage.getItem("updateData")) {
      if (sessionStorage.getItem("updateData") === "modDatos") {
        this.messageAllOk = "Tus datos han sido modificados correctamente";
        this.showAllOk = true;
      }
    }
    else if (this.loginSrv.camposError.length > 0) {
      sessionStorage.setItem("updateData", "updateDni");
      this.helpers.navigateTo("mi-perfil");
    }
    this.errService.modDataError = true;
    document.getElementsByTagName('body')[0].style.overflow = '';
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  ngAfterViewInit() {
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    this.changeDetector.detectChanges()
  }

  highlightDragModule(e) {
    const yPos = e.clientY,
      frontModule = document.getElementById('front'),
      backModule = document.getElementById('back'),
      defaultStyle = '6px dashed #000',
      highlight = '6px dashed #F4137B',
      divId = e.toElement.id

    if (frontModule) {

      if (divId === 'back') {
        backModule.style.border = highlight;
        frontModule.style.border = defaultStyle;
      } else {
        frontModule.style.border = highlight;
        if (backModule) {
          backModule.style.border = defaultStyle;
        }
      }
    } else if (backModule) {

      if (divId === 'front') {
        frontModule.style.border = highlight;
        backModule.style.border = defaultStyle;
      } else {
        backModule.style.border = highlight;
        if (frontModule) {
          frontModule.style.border = defaultStyle;
        }
      }
    }

    document.body.onmouseleave = () => {
      if (frontModule)
        frontModule.style.border = defaultStyle;
      if (backModule)
        backModule.style.border = defaultStyle;
    }
  }

  updateFrontImage(files: any) {
    const file = files.target.files[0];
    files.srcElement.value = null;
    this.checkValidFile(file,'front');
  }

  placeImage(file, side){
    this.checkValidFile(file, side);
  }

  resizeImage(file, side) {
    const reader = new FileReader();
    if (side === 'front') {
      this.imageFront.file = file;
      this.imageFront.side = side;
      this.imageFront.resize = true;
    } else {
      this.imageBack.file = file;
      this.imageBack.side = side;
      this.imageBack.resize = true;
    }
    this.loadForm();
    this.upload[side] = true;
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      this.image[side] = reader.result;
    });

    let image1, image2;

    if (this.image['front']) {
      image1 = this.image['front'].split(',')[1];
      this.persona = Object.assign({
        confirma: {
          image1: image1,
          imageFront: this.imageFront,
        }
      });
    }
    this.authService.setSessionProspect(this.persona);

    if (this.image['back']) {
      image2 = this.image['back'].split(',')[1];
      this.persona = Object.assign({
        confirma: {
          image2: image2,
          imageBack: this.imageBack,
        }
      });
    }
    this.authService.setSessionProspect(this.persona);

    // }
  }

  onFileParent(event) {
    if (this.helpers.isEmpty(event)) {
      //return this.modService.open(this.incorrectFormatModal);
      this.incorrectFormatBanner = true;
      this.hiddeBanner = false;
    }

    if (typeof this.image['front'] === 'undefined') {
      event['side'] = 'front';
    } else if (typeof this.image['back'] === 'undefined') {
      event['side'] = 'back';
    }

    this.onFilesChange(event);
  }

  onFilesChange(fileObj: Object) {
    const side = fileObj['side'],
          file = fileObj['file'];

    if (this.helpers.isEmpty(fileObj)) {
      //return this.modService.open(this.incorrectFormatModal);
      this.incorrectFormatBanner = true;
      this.hiddeBanner = false;
    }

    this.loadForm();

    if (fileObj['valid'] === true) {
      this.placeImage(file, fileObj['side'])
    }
  }

  updateBackImage(files: any) {
    const file = files.target.files[0];
    files.srcElement.value = null;
    this.checkValidFile(file,'back');
  }

  checkValidFile(file: any, side) {
    if (!this.isValid(file)) {
      this.resetImageSide(side);
      return;
    }
    this.resizeImage(file, side);
  }

  resetImageSide(side: string) {
    if (side === 'front') {
      this.resetFrontImage();
      return;
    }
    this.resetBackImage();
  }

  isValid(file) {
    const maxSizeMb = 15;
    const ext = file.name.split('.')[file.name.split('.').length - 1];
    if (this.extensions.lastIndexOf(ext) === -1) {
      //this.modService.open(this.incorrectFormatModal);
      this.hiddeBanner = false;
      this.incorrectFormatBanner = true;
      return false;
    };
    if (file.size > (maxSizeMb * 1048576)) {
      this.incorrectTitle = 'Tamaño máximo';
      this.incorrectText = 'El tamaño máximo de la imagen es de ' + maxSizeMb + 'Mb.';
      this.hiddeBanner = false;
      this.incorrectFormatBanner = true;
      //this.modService.open(this.incorrectFormatModal);
      return false;
    }
    return true;
  }
  loadForm() {
    this.dniData = new FormData();
    if (!!this.imageFront.side) {
      this.dniData.append(this.imageFront.side, this.imageFront.file);
    }
    if (!!this.imageBack.side) {
      this.dniData.append(this.imageBack.side, this.imageBack.file);
    }
  }
  openDetail() {
    this.modService.open(this.detailModal);
  }

  showSpecs() {
    this.analytics.triggerProfileModDataNavData({ 'pageName': 'Perfil/Información DNI' });
    this.helpers.navigateTo('/subir-documentacion/especificaciones');
  }
  textChange() {
    if (this.deviceService.isMobileOrTablet()) {
      this.uploadImageSecondOptionText = 'haz una foto';
    } else {
      this.uploadImageSecondOptionText = 'arrastra un archivo';
    }
    if (this.screenWidth < 768) {
      this.showSpecText = false;
    } else {
      this.showSpecText = true;
    }
  }

  onSubmit() {
    //Quitamos el faldón rojo para cada nueva petición de subida.
    this.incorrectFormatBanner=false;
    if (!this.imageFront.resize || !this.imageBack.resize) {
      this.modService.open(this.incompleteModal);
    } else {
      /*// añadir codigo de producto contratado cuando veamos la procedencia

      let codProductoContratado;
      if (this.authService.getSessionProspect().logalty && this.authService.getSessionProspect().logalty.codProductoContratado) {
        codProductoContratado = this.authService.getSessionProspect().logalty.codProductoContratado
      }
      */
      //this.loading = true;

      //Para que al mostrar la pantalla de loading-custom se valla al principio de la vista
      window.scrollTo(0, 0);
      this.showLoading();
      const timespan = new Date().getTime();
      const tipoSolicitud = sessionStorage.getItem('tipoSolicitud');

      if (tipoSolicitud === '01') {
        this.solicitudDni = '02';
      }
      else if (tipoSolicitud === '02') {
        this.solicitudDni = '01';
      }
      else if (tipoSolicitud === '03' || tipoSolicitud === '04') {
        this.solicitudDni = '02';
      }

      if ((sessionStorage.getItem('operacion-ampliacion') &&
        (sessionStorage.getItem('operacion-ampliacion') === "ampliacion" ||
          sessionStorage.getItem('operacion-ampliacion') === "ampliacion-firma"))
        || sessionStorage.getItem('operacion-inversora')) {
        this.persona = Object.assign({
          //idConfirma: `${this.authService.getSessionProspect().datosPersonales.id_cliente_EVO}${Math.floor(new Date().getTime() / 1000)}`,
          idConfirma: `${this.clientId}${timespan}`,
          tipoSolicitud: `${this.solicitudDni}`,
          frontImg: this.image['front'].split(',')[1],
          backImg: this.image['back'].split(',')[1],
          idCaso: sessionStorage.getItem("casoSeleccionado")
        });
      }
      else {
        this.persona = Object.assign({
          //idConfirma: `${this.authService.getSessionProspect().datosPersonales.id_cliente_EVO}${Math.floor(new Date().getTime() / 1000)}`,
          idConfirma: `${this.clientId}${timespan}`,
          tipoSolicitud: `${this.solicitudDni}`,
          frontImg: this.image['front'].split(',')[1],
          backImg: this.image['back'].split(',')[1]
        });
      }


      // this.messageLoading = true;
      //document.getElementById('user-path').classList.add('hide');


      //const id = this.persona.confirma.idConfirma;

      //Scroll to top
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;

      this.showLoading();
      this.titulo = "ESTAMOS VALIDANDO TU PETICIÓN";
      this.imagen = "./assets/images/6_Rocket.gif";
      this.subtitulo = "¡No toques nada que ya casi estamos!";
      if(sessionStorage.getItem('operacion-ampliacion')){
        this.analytics.triggerCreditLimitDataNavData({ 'pageName': 'Perfil/Validando DNI' });
      }
      else {
        this.analytics.triggerProfileModDataNavData({ 'pageName': 'Perfil/Validando DNI' });
      }

      //const resp = this.getRespSentDni ();

      this.personaSrv.sendDni(this.persona).subscribe((data) => {

        if(!sessionStorage.getItem('operacion-inversora')){
          this.hideLoading();
        }

        if (this.subDocumentacion){
          this.getCampaignErrorMessage(data.result);
        }
        else{
          this.getErrorMessage(data.result);
        }

      }, error => {
        this.analytics.triggerProfileModDataFormData('Envío KO', 'Perfil/DNI Error conexión');
        //Redirige al 404 de Modificación de datos
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
      });

    }
  }

  getCampaignErrorMessage(result){

    switch (result) {

      case '01': //TELÓN TODO-OK

        // si viene de ampliacion PUT MODIFICACION DATOS derivar a consola de modificacion

        if (sessionStorage.getItem("updateData") === 'modDatos') {
          sessionStorage.setItem("updateData", "updateDni");
        }

        if (sessionStorage.getItem('operacion-ampliacion') === "ampliacion") {

          this.ampliacionService.putLimite("modificacion-datos","modificacion");

        }else if(sessionStorage.getItem('operacion-inversora')){

          let idCaso = sessionStorage.getItem("casoSeleccionado");
          let obj = {
            "estado": "modificacion-datos-tit1",
            "idCaso": idCaso
          };
          this.personaSrv.modificarCuenta(obj).subscribe(data =>{
            this.hideLoading();
            if(data.codigoRetorno === 'OK'){
              this.helpers.navigateTo("mi-perfil");
            }else{
              console.log("error update-dni put modificar cuenta");
              this.errService.modDataError = true;
              this.errService.navToErrorModData('SATELITE-C', 'REINTENTAR', 'mi-perfil');
            }
          })
        }
        this.analytics.triggerProfileModDataFormData('Envío OK');
        sessionStorage.setItem("updateDni", "true"); // campaña - mis datos
        sessionStorage.removeItem("notificacionOk")
        this.goToMyProfile();
        /* menos 2 digitos
          astronauta (vamos a revisar tu peticion)
          mas de dos digitos
          pantalla 404 nueva
         */
        break;

      case '02':
      case '03':
        this.analytics.triggerProfileModDataFormData('Envío KO: Posible fraude', 'Perfil/DNI en Trámite');
        this.errService.navToErrorModData('ASTRONAUTA', 'ENTENDIDO', 'login');
        break;

      case '04': //REINTENTAR

        this.analytics.triggerProfileModDataFormData('Envío KO: Datos no recuperados', 'Perfil/DNI KO');
        this.errService.navToErrorModData('ALIEN', 'REINTENTAR', 'subir-documentacion');
        break;

      case '25': //DNI CADUCADO - Faldón rojo
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI caducado');
        this.errService.hasExpiredDocument = true;
        this.incorrectText = '¡Vaya! El DNI/NIE que has subido está caducado.';
        this.resetImages();
        this.hiddeBanner = false;
        this.incorrectFormatBanner = true;
        break;

      case '36': // Demasiados reintentos (segundo KO de confirma) - Enviar directamente al 404
        this.analytics.triggerProfileModDataFormData('Envío KO: Demasiados intentos', 'Perfil/DNI en Trámite');
        this.errService.navToErrorModData('ASTRONAUTA', 'ENTENDIDO', 'login');
        break;

      case '55': // Timeout - Reintento
        this.analytics.triggerProfileModDataFormData('Envío KO: Time Out', 'Perfil/DNI Error conexión');
        this.errService.navToErrorModData('SATELITE', 'REINTENTAR','subir-documentacion');
        break;

      case '56': // Segundo Timeout - Enviar directamente al 404
        this.analytics.triggerProfileModDataFormData('Envío KO: Time Out', 'Perfil/DNI en Trámite');
        this.errService.navToErrorModData('ASTRONAUTA', 'ENTENDIDO', 'login');
        break;

      case '70': // Cliente con solicitud abierta
        this.analytics.triggerProfileModDataFormData('Envío KO: El cliente ya tiene una solicitud abierta');
        this.errService.navToErrorModData('ASTRONAUTA-C', 'ENTENDIDO', 'login');
        break;
      case '77': // Documentacion Blanco y negro
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI/NIE no original');
        this.title = '¡UPS!';
        this.msg = 'Lo sentimos, pero tu foto de DNI/NIE tiene que ser original, no valen fotocopias.'
        this.modService.open(this.modal_ko);
        break;
      case '88': //DNI difiere en dos o menos caracteres
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI no corresponde', 'Perfil/DNI en Trámite');
        this.errService.navToErrorModData('ASTRONAUTA', 'ENTENDIDO', 'login');
        break;

      case '99': // DNI difiere en más de dos caracteres
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI no corresponde', 'Perfil/DNI KO');
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        break;

      case 'KO':
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI no corresponde', 'Perfil/DNI Error conexión');
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        break;

      default:
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        break;
    }

  }

  getErrorMessage(result){
    switch (result) {

      case '01':

        if (sessionStorage.getItem('operacion-ampliacion') === "ampliacion" || sessionStorage.getItem('operacion-ampliacion') === "ampliacion-firma") {
          // si viene de ampliacion PUT MODIFICACION DATOS
          // derivar a consola de modificacion

          if (sessionStorage.getItem("updateData") === 'modDatos') {
            sessionStorage.setItem("updateData", "updateDni");
            //this.ampliacionService.putLimite("warning-documentacion","subir");
            this.ampliacionService.putLimite("aportar-documentacion", "aportar-documentacion");
          }
          else {
            this.ampliacionService.putLimite("modificacion-datos","modificacion");
          }

         this.analytics.triggerProfileModDataFormData('Envío OK');
         sessionStorage.setItem("updateDni", "true"); // campaña - mis datos
         this.goToMyProfile();
         /* menos 2 digitos
           astronauta (vamos a revisar tu peticion)
           mas de dos digitos
           pantalla 404 nueva
          */
        }else if(sessionStorage.getItem('operacion-inversora')){

            let idCaso = sessionStorage.getItem("casoSeleccionado");
            let isSecondSigner = sessionStorage.getItem('isSecondSigner');
            if(isSecondSigner !== "false"){
              this.estado = 'modificacion-datos-tit2';
            }else{
              this.estado = 'modificacion-datos-tit1';
            }
            let obj: Object = {
              "estado": this.estado,
              "idCaso": idCaso,
            }
            this.personaSrv.modificarCuenta(obj).subscribe(data => {
              this.hideLoading();
              if(data.codigo === 'OK'){
                let redirectPage: string = 'mi-perfil';
                if (sessionStorage.getItem("updateData") === "modDatos") {
                  redirectPage = 'configuracion-cuenta-inversora';
                  sessionStorage.setItem('operacion-inversora', 'operacion-inversora-nickname');
                  sessionStorage.removeItem("notificacionOk");
                }
                sessionStorage.setItem("updateData", "updateDni");
                sessionStorage.setItem("updateDni", "true");
                sessionStorage.setItem('peticion-modificacion','true');

                this.helpers.navigateTo(redirectPage);
              }else{
                this.errService.modDataError = true;
                this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
              }
            });
        } else { //Acceso anterior a través de mi perfil
          sessionStorage.setItem("updateDni", "true");
          this.goToMyProfile();
        }

        break;

      case '02':
      case '03':
        this.analytics.triggerProfileModDataFormData('Envío KO: Posible fraude', 'Perfil/DNI en Trámite');
        this.errService.navToErrorModData('ASTRONAUTA', 'ENTENDIDO', 'login');
        break;

      case '04': //KO-Confirma -- Reintentar

        this.analytics.triggerProfileModDataFormData('Envío KO: Datos no recuperados', 'Perfil/DNI KO');
        this.errService.navToErrorModData('ALIEN', 'REINTENTAR', 'subir-documentacion');
        break;

      case '25':
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI caducado');
        this.errService.hasExpiredDocument = true;
        this.incorrectText = '¡Vaya! El DNI/NIE que has subido está caducado.'; // campaña - reintentar
        this.resetImages();
        this.hiddeBanner = false;
        this.incorrectFormatBanner = true;
        // faldon arriba rojo
        break;

      case '36': // Demasiados reintentos - Enviar directamente al 404
        this.analytics.triggerProfileModDataFormData('Envío KO: Demasiados intentos', 'Perfil/DNI en Trámite');
        this.errService.navToErrorModData('ASTRONAUTA', 'ENTENDIDO', 'login');
        break;

      case '55':
        this.analytics.triggerProfileModDataFormData('Envío KO: Time Out', 'Perfil/DNI Error conexión');
        this.errService.navToErrorModData('SATELITE', 'REINTENTAR', 'subir-documentacion');
        break;

      case '56': // TIMEOUT Enviar directamente al 404
        this.analytics.triggerProfileModDataFormData('Envío KO: Time Out', 'Perfil/TimeOutConfirma');
        this.errService.navToErrorModData('ASTRONAUTA', 'ENTENDIDO', 'login');
        break;

      case '70': // Faldon estamos revisando
        this.analytics.triggerProfileModDataFormData('Envío KO: El cliente ya tiene una solicitud abierta');
        this.errService.navToErrorModData('ASTRONAUTA-C', 'ENTENDIDO');
        break;
      case '77': // Documentacion Blanco y negro
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI/NIE no original');
        this.title = '¡UPS!';
        this.msg = 'Lo sentimos, pero tu foto de DNI/NIE tiene que ser original, no valen fotocopias.'
        this.modService.open(this.modal_ko);
        break;
      case '88': //DNI difiere en dos o menos caracteres
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI no corresponde', 'Perfil/DNI en Trámite');
        this.errService.navToErrorModData('ASTRONAUTA', 'ENTENDIDO', 'login'); // campaña - login
        break;

      case '99': // DNI difiere en más de dos caracteres
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI no corresponde', 'Perfil/DNI KO');
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        break;

      case 'KO':
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI no corresponde', 'Perfil/DNI Error conexión');
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        break;

      default:
        this.analytics.triggerProfileModDataFormData('Envío KO: DNI no corresponde', 'Perfil/DNI Error conexión');
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        break;
    }

  }

  getRespSentDni() {
    let promise = new Promise((resolve, reject) => {
      //let apiURL = `${this.apiRoot}?term=${term}&media=music&limit=20`;
      this.personaSrv.sendDni(this.persona)
        .toPromise()
        .then(
          res => { // Success
            const results = res.json().results;
            resolve();
          },
          msg => { // Error
            reject(msg);
            this.analytics.triggerProfileModDataFormData('Modificación de datos DNI', 'Envío KO: DNI no corresponde');
            //Redirige al 404 de Modificación de datos
            this.errService.navToError();
          }
        );
    });
    return promise;
  }

  goToMyProfile() {
    const redirect = 'mi-perfil';
    sessionStorage.setItem("updateDniCorrect", "true");
    this.helpers.navigateTo(redirect);
  }

  changeStep() {

    // definir siguiente step segun procedencia
    const redirect = 'confirmacion-datos';
    this.helpers.navigateTo(redirect);
  }

  tryAgain() {
    this.resetImages();
    this.modService.close(this.modal_ko);
  }

  nextStepDni() {
    this.resetImages();
    this.modService.close(this.modal_ko);
  }

  goToProfile() {
    this.helpers.navigateTo("/perfil");
  }

  resetImages() {
    this.resetFrontImage();
    this.resetBackImage();
    return this.dniData = new FormData();
  }

  resetFrontImage() {
    this.imageFront.side = undefined;
    this.imageFront.file = undefined;
    this.imageFront.resize = false;
    this.upload['front'] = false;
    this.image['front'] = undefined;
    this.persona = Object.assign({
      confirma: {
        image1: undefined,
        imageFront: undefined
      }
    });
    this.authService.setSessionProspect(this.persona);
    this.persona = this.authService.getSessionProspect();


  }

  resetBackImage() {
    this.imageBack.side = undefined;
    this.imageBack.file = undefined;
    this.imageBack.resize = false;
    this.upload['back'] = false;
    this.image['back'] = undefined;
    this.persona = Object.assign({
      confirma: {
        image2: undefined,
        imageBack: undefined
      }
    });
    this.authService.setSessionProspect(this.persona);
    this.persona = this.authService.getSessionProspect();

  }

  botonVolver() {
    sessionStorage.setItem('vueltaSubDoc', 'true');
    this.helpers.navigateTo("/perfil");
  }

  hideBanner() {
    this.hiddeBanner = true;
  }
  closeModal() {
    this.modService.close(this.modalDniSpecs);
  }

  hideBannerNotifyOk() {
    this.showAllOk = false;
    //metemos en sesion si ha cerrado el banner.
    sessionStorage.setItem("notificacionOk", "true");
  }

  submit(){}


  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (!!params.origen) {
        this.isBmi = params.origen.toLowerCase() === "bmi";
      }
      if (!!params.codProductoContratado) {
        this.isContratacion = true;
      }
    });
  }

  showLoading(){
    this.loadCustom = true;
  }

  hideLoading(){
    this.loadCustom = false;
  }

}
