import { Component, HostListener, ViewContainerRef, ElementRef, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
// import { Ng2PicaService } from 'ng2-pica';

import { Persona } from '../../../collections/persona';
import { IbanImage, getIban } from '../../../collections/iban';

import { AuthService } from '../../../services/auth.service';
import { IbanService } from '../../../services/iban.service';
import { ApiIbanService } from '../../../services/api/api.iban.service';
import { FormValidatorService } from '../../../services/form-validator.service';
import { ErrorService } from '../../../services/error.service';
import { ModalService } from '../../../services/modal.service';
import { DeviceService } from '../../../services/device.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { HeaderService } from '../../../services/headers.service';
import { environment } from '../../../../environments/environment';
import { JsonService } from '../../../services/json.service';
import { IbanInputDirective } from '../../../directives/iban-input.directive';
import { ACCOUNTS } from 'app/resources/account.model';

@Component({
  selector: 'identify-iban',
  templateUrl: './identify-iban.component.html',
  styleUrls: ['./identify-iban.component.scss'],
  providers: [ModalService, FormValidatorService, IbanService, ApiIbanService, JsonService, DeviceService]
})
export class IdentifyIbanComponent implements OnInit, OnDestroy, AfterViewInit {



  prevIban;
  public friendlyMessage = '';
  public friendlyTitle = '';
  public friendlyModal = 'friendly';
  loading = false;
  messageLoading = false;
  identifyIbanForm: FormGroup;
  frontDNI = 'Arrastra la parte delantera de tu documento';
  backDNI = 'Arrastra la parte trasera de tu documento';
  ibanData: FormData = new FormData();
  detailModal = 'detail';
  incompleteModal = 'incomplete';
  exceptionModal = 'exception';
  blackWhiteModal = 'blackWhiteModal';
  incorrectFormatModal = 'incorrectFormat';
  incorrectTitle = 'Archivo no válido';
  incorrectText = 'La imagen debe de tener uno de los siguientes formatos: bmp, dib, jpeg, jpg, jpe, png, pbm, pgm, ppm, tiff, tif, jp2';
  modalIbanSpecs = 'specs';
  modalIbanInfo = 'info';
  private extensions: Array<string> = [
    'bmp', 'dib', 'jpeg', 'jpg', 'jpe', 'png', 'pbm', 'pgm', 'ppm', 'tiff', 'tif', 'jp2',
    'BMP', 'DIB', 'JPEG', 'JPG', 'JPE', 'PNG', 'PBM', 'PGM', 'PPM', 'TIFF', 'TIF', 'JP2'
  ];
  private koCounter = 0;
  public msgSrvError = '';
  public modalSrvError = 'modal-srv-error';
  public modalBlock = false;

  image: Object = {};
  upload: Object = {};
  documentSide;
  private persona: Persona = this.authService.getSessionProspect();
  ibanImage: IbanImage;
  ibanValid = false;
  ibanFormVal: any = undefined;
  imageFront: any = {
    side: undefined,
    file: undefined,
    resize: false
  };
  imageBack: any = {
    side: undefined,
    file: undefined,
    resize: false
  }
  title = '¡Vaya!';
  msg = 'La imagen del documento que nos has facilitado no es correcta. Inténtalo de nuevo.';
  link = 'Volver a intentarlo';
  modal_ko = 'modal-ko';
  ibanValidClass = '';
  firstTry = true;

  clientId: string;
  exceptionTitle: string;
  exceptionText: string;
  cuentaEvo = '0239';

  public isYoungAccount = false;

  @ViewChild(IbanInputDirective) ibanDirective: IbanInputDirective;


  public screenWidth: number;

  constructor(public errService: ErrorService,
    private fb: FormBuilder,
    private router: Router,
    public modService: ModalService,
    private evoValidator: FormValidatorService,
    private authService: AuthService,
    private ibanSrv: IbanService,
    private deviceService: DeviceService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private headerSrv: HeaderService,
    private jsonService: JsonService,
    private changeDetector: ChangeDetectorRef
    // private picaService: Ng2PicaService
  ) {
    this.createForm();
    this.screenWidth = window.innerWidth;
    this.textChange();
    this.errService.mobileErrors(this.identifyIbanForm);
    this.clientId = this.authService.getSessionProspect().datosPersonales.id_cliente_EVO;

    if (this.authService.getSessionProspect().confirma && this.authService.getSessionProspect().confirma.imageFront) {
      this.imageFront = {
        side: 'front',
        file: this.authService.getSessionProspect().confirma.imageFront.file,
        resize: true
      };
      this.upload['front'] = true;
      this.image['front'] = 'data:image/png;base64,' + this.authService.getSessionProspect().confirma.image1;
    }

    if (this.authService.getSessionProspect().confirma && this.authService.getSessionProspect().confirma.imageBack) {
      this.imageBack = {
        side: 'back',
        file: this.authService.getSessionProspect().confirma.imageBack.file,
        resize: true
      }
      this.upload['back'] = true;
      this.image['back'] = 'data:image/png;base64,' + this.authService.getSessionProspect().confirma.image2;
    }

    this.loadForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
    this.textChange();
  }

  @HostListener('window:dragover', ['$event'])
  onDrag(e) {
    this.highlightDragModule(e);
    e.preventDefault();
  }

  @HostListener('window:drop', ['$event'])
  onDrop(e) {
    e.preventDefault();
  }


  ngOnInit(): void {
    document.getElementsByTagName('body')[0].style.overflow = '';
  }
  ngOnDestroy(): void {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  ngAfterViewInit() {
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    this.checkForIban();
    this.changeDetector.detectChanges()
  }

  highlightDragModule(e) {
    const yPos = e.clientY,
      frontModule = document.getElementById('front'),
      backModule = document.getElementById('back'),
      defaultStyle = '6px dashed #000',
      highlight = '6px dashed #F4137B',
      divId = e.toElement.id

    if (frontModule) {

      if (divId === 'back') {
        backModule.style.border = highlight;
        frontModule.style.border = defaultStyle;
      } else {
        frontModule.style.border = highlight;
        if (backModule) {
          backModule.style.border = defaultStyle;
        }
      }
    } else if (backModule) {

      if (divId === 'front') {
        frontModule.style.border = highlight;
        backModule.style.border = defaultStyle;
      } else {
        backModule.style.border = highlight;
        if (frontModule) {
          frontModule.style.border = defaultStyle;
        }
      }
    }

    document.body.onmouseleave = () => {
      if (frontModule)
        frontModule.style.border = defaultStyle;
      if (backModule)
        backModule.style.border = defaultStyle;
    }
  }

  placeFrontImage(side, file) {
    if (!this.isValid(file)) { return };
    this.analytics.triggerInteractionData('Identificación por IBAN', 'Captura DNI cara', this.clientId);
    this.resizeImage(file, side);
  }

  resetIban() {
    const field = this.identifyIbanForm.get('iban');
    this.helpers.updateFieldValue(this.identifyIbanForm, 'iban');
    this.ibanValidClass = '';
    this.ibanValid = false;
    field.enable();
    field.markAsPristine();
    field.markAsUntouched();
    this.errService.errorsArray = [];
    this.prevIban = '';
    this.resetImages();
  }


  updateFrontImage(files: any) {
    const file = files.target.files[0];
    if (!this.isValid(file)) { return };
    this.analytics.triggerInteractionData('Identificación por IBAN', 'Captura DNI cara', this.clientId);
    this.resizeImage(file, 'front');
  }

  resizeImage(file, side) {
    const reader = new FileReader();
    if (side === 'front') {
      this.imageFront.file = file;
      this.imageFront.side = side;
      this.imageFront.resize = true;
    } else {
      this.imageBack.file = file;
      this.imageBack.side = side;
      this.imageBack.resize = true;
    }
    this.loadForm();
    this.upload[side] = true;
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      this.image[side] = reader.result;
    });

    let image1, image2;

    if (this.image['front']) {
      image1 = this.image['front'].split(',')[1];
      this.persona = Object.assign({
        confirma: {
          image1: image1,
          imageFront: this.imageFront,
        }
      });
    }
    this.authService.setSessionProspect(this.persona);

    if (this.image['back']) {
      image2 = this.image['back'].split(',')[1];
      this.persona = Object.assign({
        confirma: {
          image2: image2,
          imageBack: this.imageBack,
        }
      });
    }
    this.authService.setSessionProspect(this.persona);

  }

  onFileParent(event) {
    if (this.helpers.isEmpty(event)) {
      return this.modService.open(this.incorrectFormatModal);
    }

    if (typeof this.image['front'] === 'undefined') {
      event['side'] = 'front';
    } else if (typeof this.image['back'] === 'undefined') {
      event['side'] = 'back';
    }

    this.onFilesChange(event);
  }

  onFilesChange(fileObj: Object) {
    const side = fileObj['side'],
      file = fileObj['file'];

    if (this.helpers.isEmpty(fileObj)) {
      return this.modService.open(this.incorrectFormatModal);
    }

    this.ibanFormVal = this.identifyIbanForm.get('iban').value;
    this.loadForm();

    if (fileObj['valid'] === true) {
      if (fileObj['side'] === 'front') {
        this.placeFrontImage(side, file)
      } else {
        this.placeBackImage(side, file)
      }
    }
  }
  removeImages(){
    this.resetImages();
    this.modService.close(this.blackWhiteModal);
  }
  placeBackImage(side, file) {
    if (!this.isValid(file)) return;
    this.analytics.triggerInteractionData('Identificación por IBAN', 'Captura DNI dorso', this.clientId);
    this.resizeImage(file, side);
  }


  updateBackImage(files: any) {
    const file = files.target.files[0];
    if (!this.isValid(file)) return;
    this.analytics.triggerInteractionData('Identificación por IBAN', 'Captura DNI dorso', this.clientId);
    this.resizeImage(file, 'back');
  }

  isValid(file) {
    const maxSizeMb = 15;
    const ext = file.name.split('.')[file.name.split('.').length - 1];
    if (this.extensions.lastIndexOf(ext) == -1) {
      this.modService.open(this.incorrectFormatModal);
      return false;
    };
    if (file.size > (maxSizeMb * 1048576)) {
      this.incorrectTitle = 'Tamaño máximo';
      this.incorrectText = 'El tamaño máximo de la imagen es de ' + maxSizeMb + 'Mb.';
      this.modService.open(this.incorrectFormatModal);
      return false;
    }
    return true;
  }
  loadForm() {
    this.ibanData = new FormData();
    if (!!this.ibanFormVal) {
      this.ibanData.append('iban', this.ibanFormVal)
    }
    if (!!this.imageFront.side) {
      this.ibanData.append(this.imageFront.side, this.imageFront.file);
    }
    if (!!this.imageBack.side) {
      this.ibanData.append(this.imageBack.side, this.imageBack.file);
    }
  }
  openDetail() {
    this.modService.open(this.detailModal);
  }

  checkForIban() {
    let IBAN = this.jsonService.getKeyFromJSON(this.persona, 'datosSocioEco.IBAN') || '';
    if (IBAN) {
      IBAN = this.ibanDirective.formatString(this.ibanDirective.validateString(IBAN));
    }
    this.helpers.updateFieldValue(this.identifyIbanForm, 'iban', IBAN)
  }

  createForm() {
    this.identifyIbanForm = this.fb.group({
      iban: ['']
    });

    this.identifyIbanForm.valueChanges.subscribe(ibanForm => {
      if (ibanForm.iban.length === 27 && ibanForm.iban !== this.prevIban) {
        this.prevIban = ibanForm.iban;
        this.validateIban()
      }
      this.errService.mobileErrors(this.identifyIbanForm);
    });
  }

  setIbanFields() {
    const canal = this.deviceService.getDevice();
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      datosPersonales: {
        oficinaCliente: environment.oficina
      },
      datosSocioEco: {
        IBAN: "ES" + this.identifyIbanForm.get('iban').value.replace(/ /g, '')
      }

    });
  }

  showSpecs() {
    this.modService.open(this.modalIbanSpecs)
  }

  showInfo() {
    this.modService.open(this.modalIbanInfo)
  }

  validateIban() {
    const iban = (this.identifyIbanForm.get('iban').value).replace(/ /g, '');
    const bancoIban= iban.substring(2,6);
    
    if (iban.length === 22) {
      if (this.cuentaEvo===(bancoIban)) {
          this.friendlyTitle = '¡Ups!';
          this.friendlyMessage = 'Parece que te has equivocado. El iban que has introducido pertenece a una cuenta de EVO. Prueba a introducir uno nuevo.';
          this.modService.open(this.friendlyModal);
          this.ibanValidClass = 'ko';
        return;
      }
      this.helpers.updateFieldValidation(this.identifyIbanForm, 'iban', [Validators.required, this.evoValidator.validateIban])
      if (this.identifyIbanForm.get('iban').valid) {
        this.setIbanFields();
        this.loading = true;
        this.ibanValid = true;
        this.ibanValidClass = 'ok';
        this.authService.updateProspect(this.persona).subscribe(data => {
          this.loading = false;
          if (data.response.codigo === 'KO') {
            this.ibanValidClass = 'ko';
            this.analytics.triggerInteractionData('Identificación por IBAN', `IBAN ko`, this.clientId)
            this.msgSrvError = !!data.response.message ? data.response.message : 'Error genérico';
            this.modService.open(this.modalSrvError);
            return
          }
          this.analytics.triggerInteractionData('Identificación por IBAN', 'IBAN ok', this.clientId)
          this.identifyIbanForm.get('iban').disable();
        }, error => {
          this.analytics.triggerInteractionData('Identificación por IBAN', `IBAN ko`, this.clientId)
          this.errService.navToError();
        });
      } else if (this.identifyIbanForm.get('iban').invalid) {
        this.analytics.triggerInteractionData('Identificación por IBAN', `IBAN ko`, this.clientId)
        this.ibanValidClass = 'ko';
      }
    } else {
      this.ibanValid = false;
      this.ibanValidClass = '';
    }
  }

  textChange() {
    if (this.screenWidth < 996) {
      this.frontDNI = 'Parte delantera';
      this.backDNI = 'Parte trasera';
    } else {
      this.frontDNI = 'Arrastra la parte delantera';
      this.backDNI = 'Arrastra la parte trasera';
    }
  }

  onSubmit() {

    Object.keys(this.identifyIbanForm.controls).forEach(key => {
      this.identifyIbanForm.get(key).markAsTouched();
    });
    if (!this.ibanValid || !this.imageFront.resize || !this.imageBack.resize) {
      this.modService.open(this.incompleteModal);
    } else {
      let codProductoContratado;
      if (this.authService.getSessionProspect().logalty && this.authService.getSessionProspect().logalty.codProductoContratado) {
        codProductoContratado = this.authService.getSessionProspect().logalty.codProductoContratado
      }
      this.persona = Object.assign({
        confirma: {
          idConfirma: `${this.authService.getSessionProspect().datosPersonales.id_cliente_EVO}${Math.floor(new Date().getTime() / 1000)}`,
          image1: this.image['front'].split(',')[1],
          image2: this.image['back'].split(',')[1]
        },
        logalty: {
          codProductoContratado
        }
      });

      this.loading = true;
      this.messageLoading = true;
      document.getElementById('user-path').classList.add('hide');
      const id = this.persona.confirma.idConfirma;
      this.ibanSrv.sendImages(this.persona).subscribe((datos) => {
        this.persona = Object.assign({
          confirma: {
            idConfirma: id
          },
          logalty: {
            codProductoContratado
          }
        });
        this.authService.updatePersonConfirma(this.persona).subscribe((data) => {
          this.loading = false;
          if (this.helpers.isEmpty(data.confirma) && data.response.codigo !== 'OK') {
            this.messageLoading = false;
            switch (data.response.codigo) {
              case '99':
                this.analytics.triggerFormData('Identificación IBAN', `Envio KO: ${data.response.message}`);
                this.errService.navToErrorEid();
                break;
              //Error documento en blanco y negro
              case '77':
                this.messageLoading = false;
                this.analytics.triggerFormData('Identificación IBAN', `Envio KO: ${data.response.message}`);
                this.exceptionTitle = '¡UPS!';
                this.exceptionText = 'Lo sentimos, pero tu foto de DNI/NIE tiene que ser original, no valen fotocopias.'
                document.getElementById('user-path').classList.remove('hide');
                this.modService.open(this.blackWhiteModal);
                break;
              case '55':
                this.analytics.triggerFormData('Identificación IBAN', 'Envio KO: Time Out');
                this.isServerKo();
                break;
              case '44':
                this.analytics.triggerFormData('Identificación IBAN', 'Envio KO: DNI caducado');
                this.errService.hasExpiredDocument = true;
                this.exceptionTitle = '¡VAYA!';
                this.exceptionText = 'Parece que tu documento está caducado, comprueba que la fecha de caducidad es correcta. Chequea tu email para que puedas finalizar el proceso cuando lo hayas renovado.'
                this.modService.open(this.exceptionModal);
                break;
              case '33':
                this.analytics.triggerFormData('Identificación IBAN', 'Envio KO: DNI de un menor');
                this.errService.isMinor = true;
                this.exceptionTitle = '¡UPS!';
                if (codProductoContratado === ACCOUNTS.CJCode) {
                  this.exceptionText = 'Por política de admisión no puedes contratar tu Cuenta Joven ya que el titular debe ser mayor de edad.';
                } else {
                  this.exceptionText = 'Por política de admisión no puedes contratar ya que el titular debe ser mayor de edad.';
                }
                this.modService.open(this.exceptionModal);
                break;
              default:
                const modalToShow = this.modService.selectStatusModal(data.response);
                this.friendlyMessage = modalToShow['message'];
                this.friendlyTitle = modalToShow['title'];
                this.analytics.triggerFormData('Identificación IBAN', `Envio KO: ${this.friendlyMessage}`);
                this.modService.open(this.friendlyModal);
                break;
            }
          } else {
            if (data.response && data.confirma.result === '01') {
              this.ibanSrv.setSessionImagesIban(data);
              this.changeStep();
            } else if (data.response && data.confirma.result == '02') {
              this.analytics.triggerFormData('Identificación IBAN', 'Envio KO: Posible fraude');
              this.errService.logaltyWrong = true;
              this.errService.navToError();
            }
            else if (data.response && data.confirma.resultMessage && data.confirma.result == '04') {
              this.analytics.triggerFormData('Identificación IBAN', 'Envio KO: Imágenes incorrectas');
              this.isServerKo();
            }else if (data.response && data.confirma.result === '66') {
                this.ibanSrv.setSessionImagesIban(data);
                this.changeStep();
            }else if (data.response && data.confirma.result === '77') {
            //Error documento en blanco y negro
              this.messageLoading = false;
              this.analytics.triggerFormData('Identificación IBAN', `Envio KO: ${data.response.message}`);
              this.exceptionTitle = '¡UPS!';
              this.exceptionText = 'Lo sentimos, pero tu foto de DNI/NIE tiene que ser original, no valen fotocopias.'
              document.getElementById('user-path').classList.remove('hide');
              this.modService.open(this.blackWhiteModal);
            } else {
              this.analytics.triggerFormData('Identificación IBAN', 'Envio KO: 500 Server error');
              this.errService.navToError();
            }
          }
        }, error => {
          this.analytics.triggerFormData('Identificación IBAN', 'Envio KO: 500 Server error'); this.errService.navToError();
        })
      },
        (error) => {
          this.analytics.triggerFormData('Identificación IBAN', 'Envio KO: 500 Server error'); this.errService.navToError();
        });
    }
  }

  changeStep() {
    const redirect = 'confirmacion-datos';
    this.helpers.navigateTo(redirect);
  }


  isServerKo() {
    this.resetImages();
    if (this.koCounter < 2) {
      this.title = '¡Vaya!';
      this.msg = 'La imagen del documento que nos has facilitado no es correcta. Inténtalo de nuevo.';
      this.link = 'Volver a intentarlo';
      if (!this.firstTry) {
        this.msg = "La imagen del documento que nos has facilitado sigue sin ser correcta. No te preocupes, para continuar con el proceso puedes:";
        this.link = "Probar con Video";
        this.modService.open(this.modal_ko, true);
        this.koCounter++;
        this.messageLoading = false;
        return;
      }
    } else {
      this.title = '¡Ups!';
      this.msg = !this.isYoungAccount ? 'Lo sentimos pero por política de admisión no puedes contratar tu Cuenta Inteligente.' : 'Por política de admisión no puedes contratar tu Cuenta Joven ya que el titular debe ser mayor de edad.';
      this.link = '';
      this.modalBlock = true;
    }
    this.firstTry = false;
    this.modService.open(this.modal_ko);
    this.koCounter++;
    this.messageLoading = false;

  }

  tryAgain() {
    if (this.koCounter <= 1) {
      this.resetImages();
      this.modService.close(this.modal_ko);
    } else {
      this.sendToVideoIdentify();
    }
  }

  sendToVideoIdentify() {
    this.resetImages();
    this.loading = true;
    const canal = this.deviceService.getDevice(),
      redirect = 'identificacion-video';
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      estado: redirect,
      otrosDatos: {
        tipoIdentificacion: redirect
      }
    });
    this.helpers.navigateTo(redirect);
    this.authService.updateProspect(this.persona).subscribe((data) => {
    }, error => {
      this.errService.navToError();
    });
  }

  resetImages() {
    this.resetFrontImage();
    this.resetBackImage();

    return this.ibanData = new FormData();
  }

  resetFrontImage() {
    this.ibanFormVal = undefined;
    this.imageFront.side = undefined;
    this.imageFront.file = undefined;
    this.imageFront.resize = false;
    this.upload['front'] = false;
    this.image['front'] = undefined;
    this.persona = Object.assign({
      confirma: {
        image1: undefined,
        imageFront: undefined
      }
    });
    this.authService.setSessionProspect(this.persona);
    this.persona = this.authService.getSessionProspect();
   

  }

  resetBackImage() {
    this.ibanFormVal = undefined;
    this.imageBack.side = undefined;
    this.imageBack.file = undefined;
    this.imageBack.resize = false;
    this.upload['back'] = false;
    this.image['back'] = undefined;
    this.persona = Object.assign({
      confirma: {
        image2: undefined,
        imageBack: undefined
      }
    });
    this.authService.setSessionProspect(this.persona);
    this.persona = this.authService.getSessionProspect();

  }
}
