import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

import { AuthService } from '../../../services/auth.service';
import { VideoEidService } from '../../../services/video-eid.service';
import { IbanService } from '../../../services/iban.service';
import { ApiVideoeidService } from '../../../services/api/api.video-eid.service';
import { ApiIbanService } from '../../../services/api/api.iban.service';
import { ModalService } from '../../../services/modal.service';
import { DeviceService } from '../../../services/device.service';
import { ErrorService } from '../../../services/error.service';
import { HelperService } from '../../../services/helpers.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { VideoId } from '../../../collections/video';
import { getIban } from '../../../collections/iban';
import { ConfirmPersonal } from '../../../collections/confirmPersonal';
import { Persona } from '../../../collections/persona';

import { environment } from 'environments/environment';
import { LoginService } from '../../../services/login.service';
import { RecoveryService } from '../../../services/recovery.service';
import { ApiRecoveryService } from '../../../services/api/api.recovery.service';
import { HeaderService } from '../../../services/headers.service';
import { Modal } from '../../../collections/modal';

import { PAISES } from '../../../resources/countries.model';
import { GENEROS } from '../../../resources/genders.model';
import { SITUACIONESLABORALES } from '../../../resources/situacion-laboral.model';
import { SITUACIONES } from '../../../resources/situacion-vivienda.model';
import { VIAS } from '../../../resources/tipo-via.model';
import { TIPOSCONTRATO } from '../../../resources/tipo-contrato.model';
import { ESTADO_CIVIL } from '../../../resources/civil-status.model';
import { TIPOACTIVIDADPROFESIONAL } from '../../../resources/tipo-actividad-profesional.model';
import { ACCOUNTS } from 'app/resources/account.model';

@Component({
  selector: 'app-confirm-personal',
  templateUrl: './confirm-personal.component.html',
  styleUrls: ['./confirm-personal.component.scss'],
  providers: [
    VideoEidService,
    ModalService,
    IbanService,
    DeviceService,
    ApiIbanService,
    ApiVideoeidService
  ]
})
export class ConfirmPersonalComponent implements OnInit, AfterViewInit {

  private changesAllowed = 2;
  totalCharactersChanged: number = 0;
  public isDataLoaded = false;
  loading: boolean = false;
  username: string = '';
  confirmPersonal: ConfirmPersonal;
  nameInit: string = '';
  firstNameInit: string = '';
  secondNameInit: string = '';
  expiryDateInit: string ='';
  birthDateInit: string ='';
  sexInit: string ='';
  nacionality: string ='';
  msgErrorName = "El valor del campo Nombre debe tener la misma longitud y solo puede diferir en dos caracteres del valor actual";
  msgErrorFirstName = "El valor del campo Primer apellido debe tener la misma longitud y solo puede diferir en dos caracteres del valor actual";
  msgErrorSecondName = "El valor del campo Segundo apellido debe tener la misma longitud y  solo puede diferir en dos caracteres del valor actual";
  persona: Persona;
  surnames: string[];
  surnameOptions = new FormGroup({
    surnameOption: new FormControl()
  })
  public isLoggedUser: boolean = false;
  public modalSurname = 'surname';
  public modalOld ='modalOld';
  public modal = new Modal();
  public isYoungAccount: boolean = false;
  // public extraData: Object = {};
  public title: string = 'REVISA LOS DATOS DE TU DNI';
  public txtButtonModal = 'CERRAR';
  constructor(
    private router: Router,
    private authService: AuthService,
    private videoSrv: VideoEidService,
    private ibanSrv: IbanService,
    public modService: ModalService,
    private deviceService: DeviceService,
    public errService: ErrorService,
    private analytics: AnalyticsService,
    private helpers: HelperService,
    private loginSrv: LoginService,
    private recoveryService: RecoveryService,
    private headerSrv: HeaderService
  ) {

    this.surnames = [];
    this.confirmPersonal = new ConfirmPersonal();
    const iban = this.ibanSrv.getLocalIban();
    const video = this.videoSrv.getLocalVideo();
    const userExist: Persona = this.authService.getSessionProspect();

    if (!this.helpers.isEmpty(video) && video !== null) {
      this.separateSurname(video.document.subject.secondaryName);
      this.confirmPersonal.name = video.document.subject.primaryName;
      this.confirmPersonal.firstName = video.document.subject.secondaryName.split(' ')[0];
      this.confirmPersonal.secondName = video.document.subject.secondaryName.split(' ')[1];
      this.confirmPersonal.nationality = video.document.subject.nationality;
      this.confirmPersonal.sex = video.document.subject.sex;
      this.confirmPersonal.personalNumber = video.document.subject.personalNumber;
      if (this.confirmPersonal) {
        const expire = Number(video.document.expiryDate);
        this.confirmPersonal.expiryDate = new Date(expire);
        const birth = Number(video.document.subject.birthDate);
        this.confirmPersonal.birthDate = new Date(birth);
      }
      this.isDataLoaded = true;
    } else if (!this.helpers.isEmpty(iban) && iban !== null && iban.confirma.result && iban.confirma.result.length <= 2) {
      this.confirmPersonal.name = this.searchField(iban.confirma.OCRData.field, 'NAME_NORMALIZED').content;

      if (this.searchField(iban.confirma.OCRData.field, 'SURNAME_NORMALIZED').content) {
        this.confirmPersonal.firstName = this.searchField(iban.confirma.OCRData.field, 'SURNAME_NORMALIZED').content;
      }
      if (this.searchField(iban.confirma.OCRData.field, 'SECOND_SURNAME_NORMALIZED').content) {
        this.confirmPersonal.secondName = this.searchField(iban.confirma.OCRData.field, 'SECOND_SURNAME_NORMALIZED').content;
      }
 
      let expiry = this.searchField(iban.confirma.OCRData.field, 'EXPIRY').content.toString()
      if (expiry === 'PERMANENT') {
        expiry = '9999-01-01';
      }
      this.confirmPersonal.birthDate = new Date(this.searchField(iban.confirma.OCRData.field, 'BIRTHDATE').content.toString());
      this.confirmPersonal.expiryDate = new Date(expiry);
      this.confirmPersonal.nationality = this.searchField(iban.confirma.OCRData.field, 'NATIONALITY_MRZ').content;
      this.confirmPersonal.sex = this.searchField(iban.confirma.OCRData.field, 'SEX').content;
      this.confirmPersonal.personalNumber = this.searchField(iban.confirma.OCRData.field, 'ID_NUMBER').content;
      if (JSON.parse(sessionStorage.getItem('currentProspect')).datosPersonales.idExterno !== '00000000T' && JSON.parse(sessionStorage.getItem('currentProspect')).datosPersonales.telefonoMovil !== '888888888')
      {
        this.confirmPersonal.personalNumber = this.searchField(iban.confirma.OCRData.field, 'ID_NUMBER').content;
      } 
      else {
        this.confirmPersonal.personalNumber = JSON.parse(sessionStorage.getItem('currentProspect')).datosPersonales.idExterno;
      }

 

      this.isDataLoaded = true;
      this.validateConfirmData();
    } else if (
        !this.helpers.isEmpty(userExist) &&
        userExist.datosPersonales &&
        userExist.datosPersonales.nombreCliente &&
        this.helpers.isEmpty(this.loginSrv.loginData)) {

      this.setDataPersonal(userExist);
    } else if (!this.helpers.isEmpty(this.loginSrv.loginData)) {
      this.title = 'NECESITAMOS QUE COMPRUEBES TUS DATOS';
      document.getElementById('user-path').classList.add('hide');
      this.loading = true;
      this.recoveryService.recoverSignUser('Y2xpZW50ZWV4aXN0ZW50ZQ==').subscribe(data => {
        this.setDataPersonal(data, true);
        this.loading = false;
        this.initEditableFields();
      }, error => {
        this.errService.navToError();
      });
    }
    this.initEditableFields();   
  }

  initEditableFields() {
    this.nameInit = this.confirmPersonal.name;
    this.firstNameInit = this.confirmPersonal.firstName;
    this.secondNameInit = this.confirmPersonal.secondName;
  }

  setDataPersonal(data, logged = false) {
    this.confirmPersonal.name = data.datosPersonales.nombreCliente || '';
    this.confirmPersonal.firstName = data.datosPersonales.apellido1Cliente || '';
    this.confirmPersonal.secondName = data.datosPersonales.apellido2Cliente || '';
    this.confirmPersonal.nationality = this.searchValueFromCode(PAISES, data.datosPersonales.paisNacionalidad);
    this.confirmPersonal.sex = data.datosPersonales.sexo === 'H' ? 'M' : 'F';
    this.confirmPersonal.personalNumber = data.datosPersonales.idExterno || '';

    if (this.confirmPersonal) {
      if (data.datosPersonales.fechaCaducidadDocumento) {
        let expire = data.datosPersonales.fechaCaducidadDocumento;
        if (expire === '4444-01-01') {
          expire = '9999-01-01';
        }
        this.confirmPersonal.expiryDate = new Date(expire);
      } else {
        this.confirmPersonal.expiryDate = '';
      }
      if (data.datosPersonales.fechaNacimiento) {
        const birth = data.datosPersonales.fechaNacimiento;
        this.confirmPersonal.birthDate = new Date(birth);
      } else {
        this.confirmPersonal.birthDate = '';
      }
    }

    this.validateConfirmData();

    if (logged) {
      this.setLoggedData(data);
    } else {
      this.isDataLoaded = true;
    }
  }

  setLoggedData(data) {
    const datosPersonales = data.datosPersonales,
      datosDomicilio = data.datosDomicilio,
      datosSocioEco = data.datosSocioEco;

    this.confirmPersonal.documentNumber = datosPersonales.idExterno;

    this.confirmPersonal.birthCountry = this.getNamefromValue(this.helpers.cleanObjValues(datosPersonales.codigoPaisNacimiento), PAISES);
    this.confirmPersonal.birthProvince = this.helpers.cleanObjValues(datosPersonales.nombreProvinciaNacimiento);
    this.confirmPersonal.birthLocation = this.helpers.cleanObjValues(datosPersonales.nombreLocalidadNacimiento);

    this.confirmPersonal.residenceCountry = this.getNamefromValue(this.helpers.cleanObjValues(datosPersonales.paisResidencia), PAISES);
    this.confirmPersonal.residenceProvince = this.helpers.cleanObjValues(datosDomicilio.nombreProvinciaDomicilio);
    this.confirmPersonal.residenceLocation = this.helpers.cleanObjValues(datosDomicilio.nombreLocalidad);

    this.confirmPersonal.economicSystem = this.helpers.cleanObjValues(datosPersonales.codigoRegimenEconomico);
    this.confirmPersonal.civilStatus = this.getNamefromValue(this.helpers.cleanObjValues(datosPersonales.codigoEstadoCivil), ESTADO_CIVIL);

    this.confirmPersonal.contactDetails = Object.assign({
      postalCode: this.helpers.cleanObjValues(datosDomicilio.codigoPostal),
      telephone: this.helpers.cleanObjValues(datosPersonales.telefonoMovil),
      email: this.helpers.cleanObjValues(datosPersonales.email),
      streetType: this.getNamefromValue(this.helpers.cleanObjValues(datosDomicilio.codigoDomicilioTipoVia), VIAS),
      streetName: this.helpers.cleanObjValues(datosDomicilio.nombreVia),
      streetNumber: this.helpers.cleanObjValues(datosDomicilio.domicilioNumero),
      piso: this.helpers.cleanObjValues(datosDomicilio.domicilioPlanta),
      puerta: this.helpers.cleanObjValues(datosDomicilio.domicilioPuerta),
      portal: this.helpers.cleanObjValues(datosDomicilio.domicilioPortal),
      bloque: this.helpers.cleanObjValues(datosDomicilio.bloque),
      escalera: this.helpers.cleanObjValues(datosDomicilio.escalera),
      fase: this.helpers.cleanObjValues(datosDomicilio.fase),
      parcela: this.helpers.cleanObjValues(datosDomicilio.parcela),
      situacionVivienda: this.getNamefromValue(this.helpers.cleanObjValues(datosSocioEco.situacionVivienda), SITUACIONES)
    });

    this.confirmPersonal.laborDetails = Object.assign({
      situacionLaboral: this.getNamefromValue(this.helpers.cleanObjValues(datosSocioEco.codigoSituacionLaboral), SITUACIONESLABORALES),
      tipoContrato: this.getNamefromValue(this.helpers.cleanObjValues(datosSocioEco.codigoContrato), TIPOSCONTRATO),
      tipoActividad: this.getNamefromValue(this.helpers.cleanObjValues(datosSocioEco.codigoCNO), TIPOACTIVIDADPROFESIONAL),
      fechaAltaAutonomo: this.helpers.cleanObjValues(datosSocioEco.fechaAltaAutonomo),
      nombreEmpresa: this.helpers.cleanObjValues(datosSocioEco.nombreEmpresa),
      antiguedadLaboral: this.helpers.cleanObjValues(datosSocioEco.fechaAntiguedadLaboral)
    })
    this.isDataLoaded = true;
    this.isLoggedUser = true;
  }

  getYearsOld(birth) {
    const today = new Date();
    let old = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      old--;
    }
    return old;
  }

  ngAfterViewInit() {
    this.validateConfirmData();
    const video = this.videoSrv.getLocalVideo(),
          iban = this.ibanSrv.getLocalIban();
          this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    // if(this.authService.product === 'ACCOUNTS.CJCode'){
    //   const old = this.getYearsOld(this.confirmPersonal.birthDate);
    //   if (old > 24 && old <= 18){
    //     this.errService.navToLess25();
    //   }
    // }


    if (!this.helpers.isEmpty(video)) {
      if (this.surnames.length > 1) {
        this.modService.open(this.modalSurname);
        return;
      }
    }
    if (!this.helpers.isEmpty(iban)) {
      if (this.surnames.length > 1) {
        this.modService.open(this.modalSurname);
        return;
      }
    }
  }

  validateConfirmData() {
    if (!this.isAdult) {

      this.modal = Object.assign({
        title : '¡Ups!',
        message : !this.isYoungAccount ? 'Por política de admisión no puedes contratar tu Cuenta Inteligente ya que el titular debe ser mayor de edad.'
          : 'Por política de admisión no puedes contratar tu Cuenta Joven ya que el titular debe ser mayor de edad',
        withImage : false
      })

      this.modService.open(this.modal.name, true);
      return;
    }
    if (this.isOld) {

      this.modal = Object.assign({
        name : "old",
        title : '¡Ups!',

        message : !this.isYoungAccount ? 'Por política de admisión no puedes contratar tu Cuenta Inteligente.'
          : 'Por política de admisión no puedes contratar tu Cuenta Joven.',
        withImage : false
      })

      this.modService.open(this.modalOld, true);
      return;
    }
    if (this.isExpired) {
      this.modal = Object.assign({
        title : '¡Vaya!',
        message : 'Parece que tu DNI está caducado, comprueba que la fecha de caducidad es correcta. Chequea tu email para que puedas finalizar el proceso cuando lo hayas renovado.',
        withImage : false
      })
      this.modService.open(this.modal.name, true);
      return;
    }
  }

  updateSurnames() {
    const selectedOption = this.surnameOptions.get('surnameOption').value

    if (!selectedOption) return

    this.modService.close(this.modalSurname);
    const splitSurnames = selectedOption.split('/');
    if (splitSurnames.length > 1) {
      this.confirmPersonal.firstName = splitSurnames[0];
      this.confirmPersonal.secondName = splitSurnames[1];
    } else {
      this.confirmPersonal.firstName = splitSurnames[0];
      this.confirmPersonal.secondName = '';
    }
    if (!this.confirmPersonal.secondName) this.confirmPersonal.secondName = '';

  }

  setFields(isOk) {
    const canal = this.deviceService.getDevice();
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      estado: 'confirmacion-datos',
      datosPersonales: {
        oficinaCliente: environment.oficina,
        datosCorrectos: isOk,
        nombreCliente: this.confirmPersonal.name,
        apellido1Cliente: this.confirmPersonal.firstName,
        apellido2Cliente: this.confirmPersonal.secondName,
        fechaNacimiento: this.formatDate(this.confirmPersonal.birthDate),
        fechaCaducidadDocumento: this.formatDate(this.confirmPersonal.expiryDate),
        paisNacionalidad: this.searchCodeFromValue(PAISES, this.confirmPersonal.nationality), //TODO tener en cuenta por que habria que poner un buscador por nacionalidad
        sexo: this.confirmPersonal.sex,
        idExterno: this.confirmPersonal.personalNumber
      }
    });
    /******** BREAKPOINT NACHO **********/
  }

  getNamefromValue(value, collection) {
    if (value === '') return value
    return collection.filter(obj => {
      return obj.value === value
    })[0]['name']
  }

  searchValueFromCode(values, value) {
    let name = '';
    values.forEach((item) => {
      if (item['value'] &&
        value &&
        item['value'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    if (name)
      return name['code'];
    return name;
  }

  searchCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item['code'] &&
        value &&
        item['code'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    if (name)
      return name.value;
    return name;
  }

  searchField(fields, prop) {
    const field = fields.filter(item => {
      if (!item.name) return false
      return item.name.toUpperCase() === prop.toUpperCase();
    });
    if (field && field.length === 1) {
      return field[0];
    } else {
      return field;
    }
  }

  formatDate(date: Date) {
    if (!date) return undefined;
    date = new Date(Number(date));
    const day = date.getDate().toString().length === 1 ? '0' + date.getDate().toString() : date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    month = month.toString().length === 1 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
    return `${day}/${month}/${date.getFullYear()}`;
  }

  get isExpired() {
    if (this.errService.hasExpiredDocument) {
      return true;
    }
    const expire = new Date(Number(this.confirmPersonal.expiryDate)),
          today = new Date();

    if (expire.getTime() < today.getTime()) {
      return true;
    }
    return false;
  }

  get isAdult() {
    if (this.errService.isMinor) {
      return false;
    }
    const birthDate = Number(this.confirmPersonal.birthDate);
    return this.helpers.isAdult(birthDate);
  }

  get isOld() {
    const birthDate = Number(this.confirmPersonal.birthDate);
    return this.helpers.isOld(birthDate);
  }

  isSameDocument() {
    return this.authService.getSessionProspect().datosPersonales.idExterno === this.confirmPersonal.personalNumber;
  }

  ngOnInit() {
    //Eliminamos la clase que quita el scroll
    document.documentElement.classList.remove('noscroll');

  }

  checkFieldDistinctLength(field1, field2) {
    let countDistinct = 0;     
   field1 = field1.split('');
   for(let i =0; i < field2.length; i++) {     
     const character = field2.charAt(i);
     const indexFound = field1.findIndex(item => {
       return item == character;
     });
             
     if(indexFound != -1) {
       field1.splice(indexFound, 1);      
     } else {
       countDistinct++;
     }        
   }
   
   return countDistinct;
}

 areFieldDiff2Characters(field, fieldInit) {
    field = field.toUpperCase();
    let countDistinct = 0;
      countDistinct = this.checkFieldDistinctLength(field, fieldInit);           
    return countDistinct <=2;
}

change(event){
let name=  event.target.name;
  //  console.log("NAME ",event.target.name); 
  //  console.log("VALUE ",event.target.value);
  
   switch (name) {
     
    case 'nombre' :
    
    console.log(this.nameInit)
    if(event.target.value!=this.nameInit){
      event.target.classList.add("editRosa");
    }else{    
      event.target.classList.remove("editRosa");
    }
    break;

    case 'primerApellido' :
    console.log(this.firstNameInit)
    if(event.target.value!=this.firstNameInit){
      event.target.classList.add("editRosa");
    }else{    
      event.target.classList.remove("editRosa");
    }
    break;

    case 'segundoApellido' :
    console.log(this.secondNameInit)
    if(event.target.value!=this.secondNameInit){
      event.target.classList.add("editRosa");
    }else{    
      event.target.classList.remove("editRosa");
    }
    break;
    
}
}

  checkMaxCharactersChanged(){
    if (this.totalCharactersChanged > this.changesAllowed){
      this.onClose();
      return false;
    }
  }


  onSubmit() {

    const nombre: any = document.getElementById('nombre');
    this.totalCharactersChanged += this.checkFieldDistinctLength(nombre.value, this.nameInit);
    this.checkMaxCharactersChanged();

    const primerApellido: any = document.getElementById('primerApellido');
    this.totalCharactersChanged += this.checkFieldDistinctLength(primerApellido.value, this.firstNameInit);
    this.checkMaxCharactersChanged();

    const segundoApellido: any = document.getElementById('segundoApellido');
    if (segundoApellido) {
      this.totalCharactersChanged += this.checkFieldDistinctLength(segundoApellido.value, this.secondNameInit);
      this.checkMaxCharactersChanged();
    }
    this.confirmPersonal.name = nombre.value;
    this.confirmPersonal.firstName = primerApellido.value;
    this.confirmPersonal.secondName = segundoApellido ? segundoApellido.value : '';

    this.analytics.triggerInteractionData('Confirmacion de datos DNI', 'Es correcto');
    this.totalCharactersChanged = 0;
    this.loading = true;

    this.setFields(true);
    this.authService.updateProspect(this.persona).subscribe((data) => {
      this.loading = false;
      //Se añade nueva condicion para que no devuelva error al detectar cuenta joven obligatoria.
      if (data.response.codigo !== 'OK' && data.response.codigo !=='24' && data.response.codigo !=='26') {
        this.modal = this.modService.selectStatusModal(data.response);
        this.analytics.triggerFormData('Confirmacion datos DNI', `Envio KO: ${this.modal.message}`);
        this.modService.open(this.modal.name, this.modal.blocking);
      } else {
        if (this.authService.isLoggedIn) {
          let redirect = '/previa-firma';
          this.analytics.triggerFormData('Confirmación datos DNI', 'Envio OK');
          // this.helpers.navigateTo(redirect);
          if (data.response.codigo === '24') {
             //Cambiamos el tipo de producto a cuentaJoven(008)
             this.persona.logalty.codProductoContratado = ACCOUNTS.CJCode;
             this.authService.product = ACCOUNTS.CJCode;
             this.isYoungAccount=true;
             //guardamos objeto persona en sesion
             sessionStorage.setItem("persona", JSON.stringify(this.persona));
             redirect= '/cuenta-joven-redirect';
             
          } else if (data.response.codigo ==='26'){
              //Cambiamos el tipo de producto a cuentaInteligente(006)
              this.persona.logalty.codProductoContratado = ACCOUNTS.CICode;
              this.authService.product = ACCOUNTS.CICode;
              this.isYoungAccount=false;
              //guardamos objeto persona en sesion
              sessionStorage.setItem("persona", JSON.stringify(this.persona));
              redirect= '/cuenta-inteligente-redirect';
          }
          this.loading = true;
          this.headerSrv.getRefreshToken().subscribe( data => {
          this.loading = false;
          this.helpers.navigateTo(redirect);
          }, error => {
            this.loading = false; console.log(error);
          });
        }
      }
    }, error => {
      this.errService.navToError();
    });
  }


  onClose() {
    this.analytics.triggerInteractionData('Confirmacion de datos DNI', 'No es correcto')
    this.loading = true;
    this.setFields(false);
    this.totalCharactersChanged = 0;
    this.authService.updateProspect(this.persona).subscribe(() => {
      this.errService.errNoCorrectCEF = this.loginSrv.isLoggedIn;
      this.errService.navToErrorEid();
    }, error => {
      this.errService.navToError();
    });
  }

  closeModal(e) {
    this.errService.navToError();
  }

  hasIncorrectSurnames() {
    this.errService.navToErrorEid();
  }

  separateSurname(surname) {
    let firstConnector = '';
    const options = [],
      solution = [],
      surnameArray = surname.split(" "),
      sortSolutions = (arr) => {
        const originalArr = arr.slice();
        const possibleSolutions = [];
        for (let i = 1; i <= originalArr.length; i++) {
          arr = originalArr.slice()
          const firstSurname = arr.splice(0, i).join(' ');
          const secondSurname = arr.join(' ');

          if (secondSurname) {
            possibleSolutions.push(firstSurname.concat("/ " + secondSurname))
          } else {
            possibleSolutions.push(firstSurname)
          }
        }
        return possibleSolutions
      }

    if (surnameArray.length <= 2) return surnameArray;

    surnameArray.forEach((surname) => {
      let option = '';
      if (surname.length > 3) {
        if (firstConnector !== "") {
          option = firstConnector.concat(' ' + surname);
          options.push(option);
          firstConnector = '';
          return
        }
        options.push(surname);
        return
      }
      if (surname.length <= 3) {
        if (firstConnector !== '') {
          firstConnector = firstConnector.concat(' ' + surname);
          return
        }
        firstConnector = surname;
      }
    });

    const filteredSolutions = sortSolutions(options).filter((surname, index, self) => {
      return index === self.indexOf(surname);
    })

    return this.surnames = filteredSolutions;
  }
}
