import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { STEPS_MICRO } from 'app/resources/steps-micro.model';

@Component({
  selector: 'micro-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  stepsInfo = STEPS_MICRO;

  constructor() {
    
  }

  ngOnInit() {

  }
}