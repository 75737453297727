import { Component, OnInit, AfterViewInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthUserService } from 'app/services/auth-user.service';
import { ErrorService } from 'app/services/error.service';
import { HelperService } from 'app/services/helpers.service';
import { LoginService } from 'app/services/login.service';
import { ModalService } from 'app/services/modal.service';
import { FormValidatorService } from 'app/services/form-validator.service';
import { DeviceService } from 'app/services/device.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { PersonaService } from 'app/services/persona.service';
import { SecurityService } from 'app/services/security-registration.service';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'app-login-profile',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ModalService]

})
export class LoginComponent implements OnInit, AfterViewInit {
  public loginUser: FormGroup;
  loading = false;
  login = 'modal-login';
  private passStr = '';
  public errMsg;
  public param = null;
  public idCliente;
  public obsCookies = new Subject<string>();
  public idProductoContratado;
  @ViewChild('password') password: ElementRef;

  constructor(
    private authService: AuthUserService,
    private helpers: HelperService,
    private fb: FormBuilder,
    private evoValidator: FormValidatorService,
    public errService: ErrorService,
    public deviceSrv: DeviceService,
    private loginSrv: LoginService,
    private analytics: AnalyticsService,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private personaSrv: PersonaService,
    private securitySrv: SecurityService
  ) { }

  ngOnInit() {
    this.loading = false;
    this.loginSrv.logOut();
    sessionStorage.setItem("isLogin", "true");
    this.createFormLogin();
    if (this.deviceSrv.isMobile()) {
      this.scrollToField();
    }

    this.helpers.keyboardClose.subscribe(val => {
      this.renderer.removeClass(this.password.nativeElement, 'focusKeyboard');
    });
  }


  ngAfterViewInit() {
    const timeout = setTimeout(() => {
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
      clearTimeout(timeout);
    });
  }

  sendClient() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);

    this.errMsg = undefined;

    // Enviamos dato para ocultar frase del footer.
    this.obsCookies.next("SendClient");
    this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    this.helpers.updateFieldValidation(this.loginUser, 'pass', [Validators.required, Validators.minLength(6)]);
    this.helpers.updateFormStatus(this.loginUser);

    this.getLogin();
  }

  private getLogin(){
    const passInput = this.loginUser.get('pass');
    const userInput = this.loginUser.get('user');

    if (userInput.invalid || passInput.invalid) {
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: Usuario/Contraseña inválido');
      return
    };
    const login: string = `username=${userInput.value.toUpperCase()}&password=${passInput.value}`;
    this.loading = true;
    this.loginSrv.getLogin(login).subscribe(data => {
      this.analytics.triggerProfileFormData('Log in', 'Envio OK');
      const idExterno = data.userinfo.idExterno;
      if(this.loginSrv.campanyaSubDoc){
        this.authService.getUserData().subscribe(data => {
          console.log("userdata:"+  JSON.stringify(data));
          this.idCliente = data.persona.id_cliente_EVO;
          this.idProductoContratado =  data.listaContratados[0].idProducto;
          sessionStorage.setItem("idClienteEvo", this.idCliente);
          sessionStorage.setItem("userData", JSON.stringify(data));
          sessionStorage.setItem('idProductoContratado',  this.idProductoContratado);
          this.getPersona();
          
        },
        _error => {
          this.loading = false;
          this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        }
        );
      }
      else{
        //JSON.stringify(data) !=='{}'
      //redirigir aqui en campaña subida documentacion
      this.helpers.navigateTo('perfil');
      }
    }, (_error) => {
      this.loading = false;
      this.analytics.triggerProfileFormData('Log in', 'Envio KO: 500 Server error');
      this.errMsg = `Parece que hay algún error, comprueba que has introducido
                     correctamente los datos. Recuerda que por seguridad,
                     tres errores consecutivos en la contraseña bloquearán el acceso`;

      if (passInput.value.length === 6) {
        this.helpers.updateFieldValue(this.loginUser, 'pass', "");
      }
    });
  }

  /**
   * Recupera los datos de persona de SF
   */
  getPersona() {
    this.personaSrv.getPersonaById(this.idCliente, this.idProductoContratado).subscribe(data => {
      this.loginSrv.userInfo = data;
      this.loginSrv.comprobacionDatos();
      this.getInfoSolicitud();
    }, _error => {
      this.loading = false;
      this.errService.navToError();
    });
  }
  
  /**
   * Comprueba las solicitudes en curso del cliente
   */
  getInfoSolicitud() {
    this.personaSrv.getInfoSolicitud(this.idCliente).subscribe(response => {
      console.log("info solicitud " + response)
      const tieneSolicitudes = !!response.solicitudes || !!(response.result && response.result !== 'KO');
      this.getWarnings(tieneSolicitudes);
    }, _error => {
      this.loading = false;
      this.errService.navToErrorModData('SATELITE', 'ENTENDIDO', 'login');
    });
  }

  /**
   * Comprobación cliente
   * @param tieneSolicitudes  Indica si tiene solicutudes en curso
   */
  getWarnings(tieneSolicitudes) {
    this.personaSrv.getWarnings(this.idCliente).subscribe(response => {
      console.log("info warnings "+response)
      const tipoSolicitud = response.message;
      sessionStorage.setItem('tipoSolicitud',tipoSolicitud);
      this.loading = false;
      switch (tipoSolicitud) {
        case '00': // SIN WARNINGS - Todo correcto - Tenga o no solicitudes se manda a modificacion
          this.helpers.navigateTo('mi-perfil');
          break;
        case '01': //DNI está caducado o no tiene doc en RSI o ambas
        case '02':
        case '03':
        case '04':
          this.loginSrv.dniCaducado = true;
          let tieneCamposErroneos = this.loginSrv.camposError.length > 0;
          if (tieneCamposErroneos || (this.loginSrv.campanyaSubDoc && tieneSolicitudes)){
            this.helpers.navigateTo('mi-perfil');
          } else {
            sessionStorage.setItem('tipoSolicitud', tipoSolicitud);
            sessionStorage.setItem('idProductoContratado',  this.idProductoContratado);
            sessionStorage.setItem('allOKAnalyticsPageName', 'Perfil/DNI OK');
            this.helpers.navigateTo('subir-documentacion');
          }
          break;
        default: //Error al obtener los datos
          this.errService.modDataError = true; 
          this.errService.navToError();
          break;
      }
    }, _error => {
      this.loading = false;
      this.errService.modDataError = true; 
      this.errService.navToError();
    });
  }

  validUser() {
    const userInput = this.loginUser.get('user');
    if (userInput.value.length < 9) {
      this.helpers.updateFieldValidation(this.loginUser, 'user', [Validators.required, Validators.minLength(9)]);
    }
    userInput.setErrors(this.evoValidator.validateID(userInput));
  }

  createFormLogin() {
    this.loginUser = this.fb.group({
      user: [''],
      pass: ['']
    });

    this.loginUser.controls.user.valueChanges.subscribe(user => {
      if (user.length === 9) {
        this.validUser();
      } else if (user.length === 0) {
        this.helpers.resetFieldsState(this.loginUser, ['user']);
      }
    });

    this.loginUser.valueChanges.subscribe(loginUser => {
      this.errService.mobileErrors(this.loginUser);
    });
    this.getVariableCampain();
  }

  getVariableCampain() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      //console.log("parametros "+JSON.stringify(params));
      if (params.subidaDocumentacion) {
        this.loginSrv.campanyaSubDoc = true;
      }
    });
  }

  numberEmmited(e) {
    this.resetErrorsMsg();
    const inputMaxLength = 6;
    if (this.loginUser.get('pass').value.length < inputMaxLength) {
      this.passStr = this.loginUser.get('pass').value + e.currentTarget.innerText;
      this.helpers.updateFieldValidation(this.loginUser, 'pass', []);
      this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
    } else {
      this.helpers.updateFieldValidation(this.loginUser, 'pass',[Validators.required, Validators.minLength(6)]);
    }
  }

  closeKeyboard() {
    this.helpers.updateFieldValidation(this.loginUser, 'user', []);
    this.helpers.closeLoginKeyboard();
    this.renderer.removeClass(this.password.nativeElement, 'focusKeyboard');
  }

  resetErrorsMsg() {
    this.errMsg = undefined;
  }

  deleteLast(e) {
    this.passStr = '';
    this.resetErrorsMsg();
    this.helpers.updateFieldValue(this.loginUser, 'pass', this.passStr);
  }

  scrollToField() {
    const target = document.getElementById('dni'),
      headerHeight = 92,
      position = target.offsetTop - target.clientHeight - headerHeight;

    document.body.scrollTop = position;
    window.scrollTo(0, position);
  }

  setClassFocus() {
    this.renderer.addClass(this.password.nativeElement, 'focusKeyboard');

  }


}
