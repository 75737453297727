import { Step } from "app/collections/step";

export var STEPS_MICRO: Step[] = [
    {
        id: 0,
        selected: false,
        icon: "micro-configure",
        URL: 'evo-protect/configura-microseguro',
        subURLs: [],
        tooltip: "Tarificador"
      },
    {
        id: 1,
        selected: false,
        icon: "micro-beneficiary",
        URL: 'evo-protect/configura-beneficiarios',
        subURLs: [],
        tooltip: "Beneficiario"
    },
    {
        id: 2,
        selected: false,
        icon: "micro-tarjetas",
        URL: 'evo-protect/configura-cuenta',
        subURLs: [],
        tooltip: "Método de pago"
    },

    {
        id: 3,
        selected: false,
        icon: "phone",
        URL: 'evo-protect/confirmacion',
        subURLs: [],
        tooltip: "Confirmación teléfono"
    }
];
  