import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'app/services/constants.service';
import { SessionStorageService } from 'app/services/session-storage.service';

@Component({
  selector: 'app-error-batch',
  templateUrl: './error-batch.component.html',
  styleUrls: ['./error-batch.component.scss']
})
export class ErrorBatchComponent implements OnInit {

  message = ConstantsService.FinalMessages.Batch;
  codProducto = this.sessionStorageSrv.getItem('productoContratando');
  isProductoInv = false;

  constructor(private location: LocationStrategy,
    private sessionStorageSrv: SessionStorageService) {
    const currentState = window.history.state;
    history.pushState(currentState, '', window.location.href);
    this.location.onPopState(
      () => {
        history.pushState(currentState, '', window.location.href);
      });
   }

  ngOnInit(): void {
    try {
      console.error('Init pantalla Batch RSI', true, true);
    }
    catch (error){ }
    this.isProductoInv = this.codProducto === ConstantsService.AccountTypes.CuentaInversora.code
                        || this.codProducto === ConstantsService.AccountTypes.CuentaInversoraCorto.code
                        || this.codProducto === ConstantsService.AccountTypes.FondoInteligente.code;
  }
}
