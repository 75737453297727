import { Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[no-whitespace]'
})

export class NoWhitespaceDirective  {

  constructor(
    private elementRef: ElementRef,
  ) {
    this.elementRef.nativeElement.addEventListener('keyup', this.onKeyUp.bind(this));
  }

  onKeyUp(e) {
    this.removeWhitespace(e);
  }

  removeWhitespace(e) {
    let spacedValue = this.elementRef.nativeElement.value;
    return this.elementRef.nativeElement.value = spacedValue.replace(/ /g, '');
  }
}
