import { Component, OnInit } from '@angular/core';
import { ACTIVIDADES_DISPONIBLES } from 'app/resources/activities-available.model';
import { HelperService } from 'app/services/helpers.service';
import { AnalyticsService } from '../../../../../services/analytics.service';



@Component({
  selector: 'slider-without-feeder',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  items = ACTIVIDADES_DISPONIBLES;
  selected = this.items[0];

  constructor(
    private helpersService: HelperService,
    public analytics: AnalyticsService
  ) { }

  ngOnInit() {
  }

  goToItem(i) {
    this.selected = this.items[i];
  }

  goToCalculatePrice() {
    this.analytics.addTarificationEvent(1);
    this.helpersService.navigateTo("/evo-protect/tarificador");
  }

}
