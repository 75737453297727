
export const ACTIVEFIELDS = {
  nombre: true, 
  apellido1: false,
  dni: false, 
  fechaCad: false,
  nacionalidad: false, 
  paisNacimiento: false,
  provNacimiento: false,
  localidadNacimiento: false, 
  fechaNacimiento: false,
  genero: false, 
  estadoCivil: false,
  regEconomico: false, 
  correo: false,
  paisResidencia: false, 
  provResidencia: false,
  ciudadResidencia: false, 
  codPostal: false,
  tipoVia: false, 
  nombreVia: false,
  num: false, 
  planta: false,
  puerta: false, 
  portal: false,
  bloque: false, 
  escalera: false,
  fase: false, 
  parcela: false,
  situacionVivienda: false, 
  sitLaboral: false,
  tipoContrato: false, 
  tipoActividad: false,
  profesion: false,
  fechaAltaAutonomo: false,
  nombreEmpresa: false,
  antiguedad: false,
  ingresosMensuales: false,
  numeroPagas: false
};
