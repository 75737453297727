import { Component, OnInit, ViewChild, OnDestroy, HostListener} from '@angular/core';
import { OtpService } from 'app/services/otp.service';
import { ApiOtpService } from 'app/services/api/api.otp.service';
import { ModalService } from 'app/services/modal.service';
import { OtpDetailsComponent } from 'app/components/otp-details/otp-details.component';
import { Otp } from 'app/collections/otp';
import { LoginService } from 'app/services/login.service';
import { HelperService } from 'app/services/helpers.service';
import { ValidateOtp } from 'app/collections/validate.otp';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { NavigationMicroService } from 'app/services/navigation-micro.service';
import { AuthGuardMicro } from 'app/services/auth-guard-micro.service';
import { Simulador } from 'app/collections/simulador';
import { DeviceService } from 'app/services/device.service';
import { environment } from 'environments/environment';
import { HelpersMicroinsurancesService } from 'app/services/helpers-microinsurances.service';
import { MicroinsRegistry, MicroinsRegistryResponse } from 'app/collections/microins-registry';
import { ResponseLogin } from 'app/collections/login';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { ErrorService } from 'app/services/error.service';
import { AuthService } from 'app/services/auth.service';
import { AnalyticsService } from 'app/services/analytics.service';

@Component({
  selector: 'app-otp-active',
  templateUrl: './otp-active.component.html',
  styleUrls: ['./otp-active.component.scss'],
  providers : [OtpService,
    ApiOtpService,
    ModalService]
})
export class OtpActiveComponent implements OnInit, OnDestroy {

  public paramsSMS: Otp;
  private loginData: ResponseLogin = this.loginService.loginData;
  private userData = this.loginService.userData;
  private listpolicies = this.loginService.listPolicies;

  private settingsMicroSession: Simulador = this.authMicroService.getMicroSettingsSession();
  private mfaId;

  @ViewChild(OtpDetailsComponent) otpDetails: OtpDetailsComponent;

  public msgeErrorEVOProtectTemp = 'No puedes  activar dos  o más EVO Protect en el mismo período de tiempo';
  public msgErrorEdad = 'La edad para contratar EVO Protect es de 18 a 65 años';
  public msgError30Minutes = 'Se puede activar tu cobertura EVO Protect  hasta 30 minutos antes de su inicio de tu activad. ¡Deja 5 min. más que es el tiempo que tardarás en completar el proceso!';
  public msgErrorChargeAccount = '¡Vaya! No hemos podido realizar el cargo en tu cuenta para activar la cobertura, por favor revisa tu saldo.';
  public msgErrorNoPolicy = '¡Vaya! Actualmente no estás adherido al Boletín del seguro EVO Protect. Para activar una cobertura es necesario que te adhieras.';
  public messageErrorGeneric = 'Error genérico, vuelva a intentarlo o contacte con el responsable.';
  public messageErrorScopeNotValid = 'Ambito no válido, valores correctos: 1 o 2.';
  public messageErrorCalculation = 'Error en el cálculo';
  public messageErrorReadResponse = 'Póliza: ' + this.listpolicies.policyNumber + '. Existe un error al leer la respuesta del servicio web  WSCargoEnCuentaSoapClient';
  public messageErrorOTP = 'Código OTP incorrecto.';
  public messageErrorExceededOTP = 'Código OTP incorrecto. Has superado el número total de intentos.';
  public messageErrorAlreadyValidated = 'El código ha sido previamente validado';



  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
   this.authMicroService.deleteMicroSettingsSession();
  }

  constructor(private loginService: LoginService,
              public modService: ModalService,
              private helpers: HelperService,
              private helpersMicro: HelpersMicroinsurancesService,
              private authMicroService: AuthMicroService,
              private authService: AuthService,
              private authguard: AuthGuardMicro,
              private deviceService: DeviceService,
              private confService: MicrosegConfigService,
              private errService: ErrorService,
              private otpService: OtpService,
            private analytics: AnalyticsService
          ) { }

  ngOnInit() {
    const loginData = this.loginService.loginData,
          userData = this.loginService.userData;
    this.mfaId = this.settingsMicroSession.methodpayout.mfaId;

    this.paramsSMS = new Otp({
      telephone : loginData.userinfo.telefonoMovil,
      product : "CuentaInteligente",
      userId: userData.persona.id_cliente_EVO
    });
  }

  ngOnDestroy() {
    if (this.settingsMicroSession.requestId == '') {
      this.authMicroService.deleteMicroSettingsSession();
      this.authguard.empty();
    }
  }

  sendSmsOTP() {
     // Calculamos los parámetros de entrada
     const params = this.getMicroRegistryParams();
     this.otpDetails.loading = true;
     this.confService.microinsRegistryFirstCall(params).subscribe(res => {
      this.otpDetails.loading = false;
      if (res.error) {
        this.otpDetails.modal.message = res.errorMessages[0].messageText;
        this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message)
        this.otpService.openModal(false);
        return;
       }
       // Guardamos el ID para la segunda llamada
       if (res.mfaType == "OTP") {
        this.mfaId = res.mfaId;
       }
     },
     error => {
      this.otpDetails.loading = false;
       this.errService.navToError();
     });
  }


  onSendCode(event) {
    const paramsOTP: ValidateOtp = event;
    if (typeof paramsOTP.code === 'undefined') {
      this.otpDetails.modal.title = '¡Ups!';
      this.otpDetails.modal.blocking = false;
      this.otpDetails.modal.withImage = false;
      this.otpDetails.modal.message = 'Error genérico';
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message)
      this.otpService.openModal(false);
      return;
    }

    this.otpDetails.loading = true;
    // Preparamos los parámetros de entrada
    const params = this.getMicroRegistryParams();
    this.confService.microinsRegistrySecondCall(params, this.mfaId, paramsOTP.code).subscribe(res => {
      this.otpDetails.loading = false;
      const response: MicroinsRegistryResponse = res,
            infoSalesforceError = response.infoSalesforceError;

      // Errores MPM
      if (response.error) {
        const code = response.errorMessages[0].code;
        switch(code) {
          case 'ERG001':
            this.otpDetails.modal.message = this.messageErrorGeneric;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
            break;
            case 'ERR000':
            this.otpDetails.modal.message = this.messageErrorScopeNotValid;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
            break;
          case 'NPOI001':
            this.otpDetails.modal.message = this.messageErrorCalculation;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
            break;
          case 'ERR0099':
            this.otpDetails.modal.message = this.messageErrorReadResponse;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
            break;
          default:
            this.otpDetails.modal.message = response.errorMessages[0].messageText;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
        }
        return;
      }

      // Errores controlados
      if (typeof res.operationErrorCode !== 'undefined') {
        let messageError = response.operationErrorMessage;
        if (res.operationErrorCode == -7) {
          messageError = this.messageErrorOTP;
        }
        if (res.operationErrorCode == -4) {
          messageError = this.messageErrorExceededOTP;
        }
        if (res.operationErrorCode == -5) {
          messageError = this.messageErrorAlreadyValidated;
        }

        this.otpDetails.modal.message = messageError;
        this.otpService.openModal(false);
        return ;
      }

      // Errores de Salesforce
      if (infoSalesforceError != null) {
        const status = infoSalesforceError.status;
        switch(status) {
          case '001':
            this.otpDetails.modal.message = this.msgeErrorEVOProtectTemp;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
            break;
          case '002':
            this.otpDetails.modal.message = this.msgErrorEdad;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
            break;
          case '003':
            this.otpDetails.modal.message = this.msgError30Minutes;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
            break;
          case '004':
            this.otpDetails.modal.message = this.msgErrorChargeAccount;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
            break;
          case '005':
            this.otpDetails.modal.message = this.msgErrorChargeAccount;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
            break;

            default:
            this.otpDetails.modal.message = infoSalesforceError.messageText;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
            this.otpService.openModal(false);
        }
        return;
      }
      // Guardamos el requestId del alta para mostrar el pdf del boletín
      this.settingsMicroSession = Object.assign({
        requestId: response.requestId
      });
      this.authMicroService.setMicroSettingsSession(this.settingsMicroSession);
      this.helpers.navigateTo('/evo-protect/todo-listo');
    },
    error => {
     this.otpDetails.loading = false;
      this.errService.navToError();
    });
  }

  onResendSMS(event) {
    this.sendSmsOTP();
  }


  onSendSMS(event) {
    this.otpDetails.analytics.triggerInteractionData('Enviar SMS');
    this.otpDetails.loading = true;

    this.authService.updateProspect(this.getNewMobilePhone()).subscribe(data => {
      this.otpDetails.loading = false;
      if (data.response.codigo !== 'OK') {
        const modalToShow = this.modService.selectStatusModal(data.response);
        this.otpDetails.modal = modalToShow;
        this.otpDetails.analytics.triggerFormData('Datos Confirmación otp', `Envio KO: ${this.otpDetails.modal.message}`);
        this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
        this.otpService.openModal(true);
      }else {
        this.loginData.userinfo.telefonoMovil = this.otpDetails.temporalMobilePhone;
        this.sendSmsOTP();
      }
    }, error => {
      this.otpDetails.analytics.triggerFormData('Datos Confirmación otp', `Envio KO: Fallo en Sms`);
      this.otpDetails.modal.title = '¡Ups!';
      this.otpDetails.modal.withImage = false;
      this.otpDetails.modal.blocking = false;
      this.otpDetails.modal.message = 'Error genérico en servicio';
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.otpDetails.modal.message);
      this.otpService.openModal(false);
      this.otpDetails.loading = false;
    });
  }

  getNewMobilePhone() {
    const canal = this.deviceService.getDevice();
    return {
      canal: canal,
      codigoEntidad : '0239',
      datosPersonales: {
        oficinaCliente: environment.oficina,
        telefonoMovil : this.otpDetails.temporalMobilePhone
      }
    };
  }

  getMicroRegistryParams() {
    const configure = this.settingsMicroSession.configure,
          date = new Date(configure.date.time),
          beneficiary = this.settingsMicroSession.beneficiary,
          iban = this.settingsMicroSession.methodpayout.iban,
          // Formateo iban (número de iban, oficina, entidad,...)
          fieldsIban = this.helpersMicro.getFieldsIban(iban),
          microinsRegistry: MicroinsRegistry = Object.assign({
              policyId: this.listpolicies.policyId,
              clientId: this.listpolicies.clientId,
              clientNif: this.userData.persona.idExterno,
              policyNumber: this.listpolicies.policyNumber,
              sport1: configure.actividad,
              sport2: null,
              sport3: null,
              sport4: null,
              sport5: null,
              discount: '1',
              startDate: this.helpersMicro.formatDateWithTimeZone(date, configure.timezone),
              scope: configure.region,
              duration: configure.duration,
              refund: configure.cobertura,
              deseaseImport: configure.cfAmount,
              disabilityImport: configure.cipAmount,
              capitalLiability: configure.crcAmount,
              effectDate: this.helpersMicro.formatStartEffectDate(date),
              accountNumber: fieldsIban.accountNumber,
              ibanNumber: fieldsIban.ibanNumber,
              controlDigit: fieldsIban.controlDigit,
              beneficiary: beneficiary.tipo,
              otherBeneficiary: this.helpersMicro.prepareFormatOtherBeneficiary(beneficiary.beneficiario),
              entity: fieldsIban.entity,
              office: fieldsIban.office,
              expense: configure.totalPrice.toString()
          });

    return microinsRegistry;
  }

}
