import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { WINDOW, WINDOW_PROVIDERS } from 'app/services/window.service';
import { DOCUMENT } from '@angular/platform-browser';
import { ModalService } from 'app/services/modal.service';
import { Modal } from 'app/collections/modal';
import { HelperService } from 'app/services/helpers.service';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { AuthGuardMicro } from 'app/services/auth-guard-micro.service';
import { CancelPolicy, CancelPolicyResponse } from 'app/collections/microseg-adhesion';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { ErrorService } from 'app/services/error.service';
import { LoginService } from 'app/services/login.service';
import { GetContractResponse } from 'app/collections/get-contract';
import { AnalyticsService } from 'app/services/analytics.service';


@Component({
  selector: 'app-my-evo-protect',
  templateUrl: './my-evo-protect.component.html',
  styleUrls: ['./my-evo-protect.component.scss'],
  providers: [ModalService]
})
export class MyEvoProtectComponent implements OnInit {

  public loading;

  public modalCancel = "modal-cancel";
  public modalSeguros = "modal-seguros";
  public modalSrvError = 'modal-srv-error';
  public msgSrvError = '';
  public msgErrorNoPolicy = "No existe la póliza";
  public messageErrorGeneric = 'Error genérico, vuelva a intentarlo o contacte con el responsable.';
  public messageErrorCalculation = 'Error en el cálculo';

  public breadcrumbNav = [{ name: 'INICIO', link: '/perfil' }, { name: 'MIS PRODUCTOS', link: '/mis-productos' }];
  private userData = this.loginService.userData;
  private listPolicies = this.loginService.listPolicies;
  public dateRegistryPolicty;

  constructor(private modService: ModalService,
    private authguard: AuthGuardMicro,
    private authService: AuthMicroService,
    private microConfigService: MicrosegConfigService,
    private errService: ErrorService,
    private loginService: LoginService,
    private helpersService: HelperService,
    public analytics: AnalyticsService) {
    }

  ngOnInit() {
    this.authguard.empty();
    if (this.listPolicies != null && this.listPolicies.policyFirstEffect != null) {
      const policyFirstEffect = new Date(this.listPolicies.policyFirstEffect);
      this.dateRegistryPolicty = this.helpersService.formatDateDayMonthYear(policyFirstEffect);
    }
    //Se ocultan los steps (protect) para no mostrarlos en esta pantalla
    document.getElementById('user-path').classList.add('hide');
  }

  onClickCancel() {
    this.analytics.cancelAdhesionbulletin(1);
    this.modService.open(this.modalCancel);
  }

  abortCancelAdhesion() {
    this.modService.close(this.modalCancel);
  }

  acceptCancelAdhesion() {
    this.abortCancelAdhesion();
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    this.loading = true;
    const cancelPolicy = new CancelPolicy({
      policyId: this.listPolicies.policyId,
      policyNumber: this.listPolicies.policyNumber,
      clientId: this.listPolicies.clientId,
      policyHolderNif: this.userData.persona.idExterno
    });
    this.microConfigService.cancelPolicy(cancelPolicy).subscribe(data => {
      this.loading = false;
      const response: CancelPolicyResponse = data;
      if (response.error) {
        const code = response.errorMessages[0].code;
        switch (code) {
          case 'ERG001':
            this.msgSrvError = this.messageErrorGeneric;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.msgSrvError);
            this.modService.open(this.modalSrvError);
            break;
          case 'NPOI001':
            this.msgSrvError = this.messageErrorCalculation;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.msgSrvError);
            this.modService.open(this.modalSrvError);
            break;
          default:
            this.msgSrvError = response.errorMessages[0].messageText;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.msgSrvError);
            this.modService.open(this.modalSrvError);
        }
        return;
      }
      const infoSalesforceError = response.infoSalesforceError;
      if (infoSalesforceError != null) {
        const status = infoSalesforceError.status;
        switch (status) {
          case '010':
            this.analytics.triggerErrorAction(this.analytics.globalTarificationError, this.modalSeguros);
            this.modService.open(this.modalSeguros);
          break;
          case '005':
            this.msgSrvError = this.msgErrorNoPolicy;
            this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.msgSrvError);
            this.modService.open(this.modalSrvError);
            break;
          default:
          this.msgSrvError = response.errorMessages[0].messageText;
          this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.msgSrvError);
          this.modService.open(this.modalSrvError);
        }
        return;
      }

      const evoProtectMicroSession = { cancel: true };
      this.authService.setMicroEvoProtectSession(evoProtectMicroSession);
      sessionStorage.removeItem("listPolicies");
      this.helpersService.navigateTo("/evo-protect/cancelacion-adhesion");
    },
    error => {
      this.loading = false;
      this.errService.errUnsubscribe = true;
      this.errService.navToError();
    });
  }

  downloadContract() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    this.loading = true;
    this.microConfigService.getContract().subscribe(res => {
      this.loading = false;
      const response: GetContractResponse = res;
      if (res.downloaded) {
        const pdfBase64 = response.content;
        this.analytics.triggerTarificationNotificationAction('Mostrar notificación',"Mostrando Contrato");
        this.helpersService.openPDF(pdfBase64);
      } else {
        this.msgSrvError = response.operationErrorMessage;
        this.analytics.triggerTarificationNotificationAction('Mostrar notificación',this.msgSrvError);
        this.modService.open(this.modalSrvError);
      }
    },
    error => {
      this.loading = false;
      this.errService.errUnsubscribe = true;
      this.errService.navToError();
    });
  }

  closeModal(modalId) {
    this.modService.close(modalId);
  }

  goToGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=es.evobanco.bancamovil&hl=es");
  }

  goToAppStore() {
    window.open("https://itunes.apple.com/app/id542752481 ");
  }

}
