export enum NavigationMicro {
  '/microseguros',
  '/microseguros/pre-logalty',
  '/microseguros/logalty',
  '/microseguros/signatureok',
  '/microseguros/signatureko',
  '/evo-protect/requisitos',
  '/evo-protect/pre-logalty',
  '/evo-protect/logalty',
  '/evo-protect/boletin-adhesion',
  '/evo-protect/cancelacion-adhesion',
  '/evo-protect/configura-microseguro',
  '/evo-protect/configura-beneficiarios',
  '/evo-protect/configura-cuenta',
  '/evo-protect/confirmacion',
  '/evo-protect/todo-listo',
  '/evo-protect/simulador',
  '/evo-protect/tarificador',
  '/evo-protect/simulador-publico',
}
