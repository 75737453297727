import { SituacionVivienda } from '../collections/situacion-vivienda';

export var SITUACIONES: SituacionVivienda[] = [

  { name : 'Situación de la vivienda', value : null},
  { name : 'Propiedad sin Hipoteca', value : '1'},
  { name : 'Alquiler', value : '2'},
  { name : 'Cedido', value : '3'},
  { name : 'Propiedad Hipotecada (Cancelada)', value : '6'},
  { name : 'Propiedad Hipotecada', value : '4'},
  { name : 'Casa de Padres', value : '5'},
  { name : 'Otros', value : '9'}
];
