import { Injectable } from '@angular/core';
import { Persona } from '../collections/persona';
import { ApiPersonService } from './api/api.persona.service';
import { Observable, Subject } from 'rxjs';
import { LoginService } from './login.service';
import { DeviceService } from './device.service';
import { DatosCuenta, DatosDesconexion, DatosOrden, InversisInvestmentsResponse, InversisSessionRequest, InversisSessionResponse } from 'app/collections/inversis';

@Injectable({
  providedIn: 'root'
})
export class PersonaService {

  constructor(
    private api: ApiPersonService,
    private loginSrv: LoginService,
    private deviceSrv: DeviceService
  ) {
  }

  //GET /persona
  getAllInfo(): Observable<Persona[]> {
    return this.api.getAllInfo();
  }

  //POST /persona
  addPersona(persona: Persona): Observable<Persona> {
    return this.api.createPersona(persona);
  }

  //DELETE /persona/:id
  deletePersonaById(personaId: string): Observable<Persona> {
    return this.api.deletePersonaById(personaId);
  }

  //PUT /persona/:id
  updatePersona(persona: Persona): Observable<Persona> {
    return this.api.updatePersona(persona);
  }

  updatePersonConfirma(persona: Persona): Observable<Persona> {
    return this.api.updatePersonConfirma(persona);
  }

  //GET /persona/:id
  getPersonaById(personaId: string, product?): Observable<Persona> {
    return this.api.getPersonaById(personaId, product);
  }

  checkDuplicateDNI(rsi_data): Observable<any>{
    return this.api.checkDuplicateDNI(rsi_data);
  }

  checkPhoneEmail(data):Observable<any>{
    return this.api.checkPhoneEmail(data);
  }

  // TODO: Delete (deprecated after Consola 2.0)
  getWarnings(idCliente: string): Observable<any>{
    return this.api.getWarnings(idCliente);
  }

  getInfoSolicitud(idCliente: string): Observable<any>{
    return this.api.getInfoSolicitud(idCliente);
  }

  sendDni(persona: Persona): Observable<any>{
    return this.api.sendDni(persona);
  }

  sendDniAmpliacion(persona: Persona): Observable<any>{
    return this.api.sendDniAmpliacion(persona);
  }

  sendModifyData(data): Observable<any>{
    return this.api.sendModifyData(data);
  }

  getConsultaExperian(idCliente: string, producto): Observable<any>{
    return this.api.consultaExperian(idCliente, producto);
  }

  getConsultaAsnef(idCliente: string, producto): Observable<any>{
    return this.api.consultaAsnef(idCliente, producto);
  }

  getConsultaExperianAmpliacion(idCliente: string, producto): Observable<any>{
    return this.api.consultaExperianAmpliacion(idCliente, producto);
  }

  getConsultaAsnefAmpliacion(idCliente: string, producto): Observable<any>{
    return this.api.consultaAsnefAmpliacion(idCliente, producto);
  }

  crearCasoLimite(data): Observable<any> {
    return this.api.crearCasoLimite(data);
  }

  disminuirLimite(data): Observable<any> {
    return this.api.disminuirLimite(data);
  }

  modificarLimite(data): Observable<any> {
    return this.api.modificarLimite(data);
  }

  consultarCasoAmpliacion(idCliente: string, idCaso: string): Observable<any> {
    return this.api.consultarCasoAmpliacion(idCliente, idCaso);
  }

  cancelarAmpliacion(data): Observable<any> {
    return this.api.cancelarAmpliacion(data);
  }

  subirDocumentacionAmpliacion(data): Observable<any> {
    return this.api.subirDocumentacionAmpliacion(data);
  }

  consultarCasosCliente() :Observable<any> {
    return this.api.consultarCasosCliente();
  }

  /**LLamadas de Cuenta Inversora */
  crearCasoCuenta(data): Observable<any> {
    return this.api.crearCasoCuenta(data);
  }

  modificarCuenta(data): Observable<any> {
    return this.api.modificarCuenta(data);
  }

  consultarDatosCasoInversora(idCliente, idCaso): Observable<any>{
    return this.api.consultarDatosCasoInversora(idCliente, idCaso);
  }

  consultarCasosClienteInversora(): Observable<any>{
    return this.api.consultarCasosClienteInversora();
  }

  consultaLogin(idExterno?): Observable<any>{
    let loginData;
    if(idExterno){
      loginData = idExterno;
    }else{
      loginData = this.loginSrv.loginData.userinfo.idExterno;
    }
    return this.api.consultaLogin({"idExterno" : loginData});
  }

  cambioEstadoLogalty(logalty): Observable<any>{
    return this.api.cambioEstadoLogalty(logalty);
  }

  getCategorizacion(): Observable<any>{
    return this.api.getCategorizacion();
  }

  getNickName(nick): Observable<any>{
    return this.api.getNickName(nick);
  }

  getInvestments(idInterno): Observable<InversisInvestmentsResponse> {
    return this.api.getInvestments(idInterno);
  }

  inversisSession(funcionalidad = 'VALORACION CARTERA', isinDestino = '', acuerdo): Observable<InversisSessionResponse> {
    const datosDesconexion = new DatosDesconexion({urlLogout: '', indicadorLogout: ''});
    const datosOrden = new DatosOrden({
      isinOrigen: isinDestino,
      tipoFuncionalidad: funcionalidad,
      isinDestino: '',
      codigoMercadoOrigen: '',
      codigoMercadoDestino: '',
      codigoTipoOperacion: '',
      numeroCuentaOrigen: '',
      soloOperativa: 'N'
    });
    const datosCuenta = new DatosCuenta(
      {
        numeroCuenta: '',
        numeroCuentaExterna: acuerdo ? `0239${acuerdo}` : ''
      }
    );
    const request = new InversisSessionRequest({datosDesconexion, datosOrden, datosCuenta});
    return this.api.inversisSession(request);
  }

  getStatusAndRedirect(params: any){
    return this.api.getStatusAndRedirect(params);
  };

}
