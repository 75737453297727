<div class="logalty-load">
  <div class="inner">
    <div class="container-center">
      <div class="logo-modal">
        <div class="row-logo">
          <div class="col-log">
              <p class="number-title">1<span>/6</span></p>
              <p >Este número es indicativo del riesgo del producto, siendo 1/6</p>
              <p>indicativo de menor riesgo y 6/6 de mayor riesgo</p>
          </div>
          <div class="col-log">
              <p>EVO Banco, S.A. está adherido al Fondo Español de Garantía</p>
              <p>de Depósitos de Entidades de Crédito. El fondo garantiza los</p>
              <p>depósitos de dinero hasta 100.000 euros por titular y por</p>
              <p>entidad.</p>
          </div>
        </div>
      </div>
      <div class="title">
        <h4>Espera , no desesperes...</h4>
        <p>En un periquete , un santiamén,unos segundos... ya tendrás todo listo y tu Cuenta
          Inteligente EVO con estas ventajas :</p>
      </div>
      <div class="slider_ci">
        <ul>
          <!--Primero-->
          <li>
            <div>
              <div class="first-img"></div>
              <h4 class="lit_1"> 0 comisiones</h4>
            </div>
          </li>
          <!--Segundo-->
          <li>
            <div>
              <div class="second-img"></div>
              <h4 class="lit_2"><span style="font-weight: bold;">Todas tus gestiones desde el móvil</span></h4>
            </div>
          </li>
           <!--Tercero-->
           <li>
            <div>
              <div class="third-img"></div>
              <h4 class="lit_2"> <span style="font-weight: bold;">EVO Assistant,</span> tu asistente</h4>
              <h4 class="lit_2"> virtual con inteligencia artificial</h4>
            </div>
          </li>
          <!--Cuarto-->
          <li>
            <div>
              <div class="fourth-img"></div>
              <h4 class="lit_2">Añade online al 2º titular <span style="font-weight: bold;"> desde tu app </span></h4>
              <div class="subindice_2">
                <p class="lit_3" style="margin-top: 20px;">Somos el primer banco que te permite hacerlo 100% online</p> 
              </div>
            </div>
          </li>
         <!--Ultimo-->
         <li>
          <div>
            <div class="fifth-img"></div>
            <h4 class="lit_1">Más de 1.600 cajeros gratis</h4>
            <h4 class="lit_2"> a débito en España</h4>
            <div class="subindice">
              <p class="lit_3">Y sino está dentro de los 16.000 , también será gratis en España si retiras 120€ o más.</p> 
              <p class="lit_3">En el extranjero no te cobraremos nada si retiras a débito en cualquier cajero.</p> 
              <p class="lit_3" style="margin-bottom: 5px;">Es posible que las entidades propietarias del cajero apliquen alguna tasa.</p>
            </div>
          </div>
        </li>
          
        </ul>
      </div>
    </div>
  </div>
</div>