<loading [showMe]="loading"></loading>

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<modal [modalTitle]="'Email no completado'" [blocking]='false' [modalId]='modalEmail' class='modal'>
  <div class="container">
    <h2>Error.</h2>
    <p>
      Por favor introduce tu Email para poder continuar con la contratación.
    </p>
  </div>
</modal>

<modal [modalTitle]="'Requisitos de contratación'" [blocking]='false' [modalId]='modalRequire' class='modal'>
  <div class="container">
    <h3 class="txt-center">REQUISITOS DE CONTRATACIÓN</h3>
    <p>
      <strong>Actividades Aseguradas</strong>
      Únicamente se podrá activar la cobertura para una sola actividad deportiva en un mismo período de tiempo.
    </p>
    <p>
      <strong>Edad de Contratación del Asegurado</strong>
      a partir de los 18 años y hasta los 65 años.
    </p>
    <br>
    <p>
      <strong>Personas no asegurables:</strong>
      Aquellas afectadas de apoplejía, epilepsia, parálisis, enfermedades mentales, delirium tremens, alcoholismo, toxicomanía,
      o de otras enfermedades graves y/o permanentes, que tengan disminuida su capacidad física o psíquica.
    </p>
    <br>
    <strong>Riesgos excluidos comunes a todas las coberturas:</strong>
    <ul class="listRequire square-bullet">
      <li>
        <span>
          Los ocasionados a consecuencia de un acto doloso o intencionado o de imprudencia temeraria del asegurado, así como los derivados
          de su participación activa en delitos, apuestas duelos, desafíos o riñas, salvo en los casos de legítima defensa
          o estado de necesidad.
        </span>
      </li>
      <li>
        <span>
          La práctica por el Asegurado de cualquier deporte con carácter profesional, o incluso sin ese carácter, cuando el Asegurado
          esté inscrito en una Federación Deportiva y además participe en competiciones deportivas.
        </span>
      </li>
      <li>
        <span>
          Cualquier deporte no incluido en el apartado de actividades cubiertas.
        </span>
      </li>
      <li>
        <span>
        Cualquier actividad practicada a más de 5.000 metros de altura.
        </span>
      </li>
      <li>
        <span>
        Participación en apuestas, competiciones o pruebas deportivas organizadas, aunque sea como aficionado, así como la práctica
        de estos deportes en pistas/zonas que se encuentren cerradas al público o en las que haya prohibición o advertencia
        de las autoridades o competencias del lugar.
        </span>
      </li>
      <li>
        <span>
          Queda excluido el uso de bicicletas de alquiler municipales.
        </span>
      </li>
    </ul>
  </div>
</modal>

<modal [modalTitle]="'Boletin adhesion'" [blocking]='false' [modalId]='modalAdhesion' class='modal'>
  <div class="container">
    <h3 class="txt-center">Boletín de Adhesión</h3>
    <p>
      <strong>Características</strong>
    </p>
    <p class="line-height-40">Seguro colectivo innominado</p>
    <ul class="square-bullet">
      <li><span>Tomador: EVO.</span></li>
      <li><span>Adhesión de asegurados con duración</span></li>
      <li><span>Personas no asegurables:</span></li>
    </ul>

    <strong>Coberturas (obligatorias):</strong>
    <ul class="listRequire square-bullet">
      <li>
        <span>Muerte por Accidente</span>
      </li>
      <li>
        <span>Invalidez Permanente Parcial por accidente</span>
      </li>
      <li>
        <span>Invalidez Absoluta y Permanente por accidente</span>
      </li>
      <li>
        <span>Responsabilidad Civil. Cobertura de las indemnizaciones exigidas al Asegurado por un tercero, por daños personales y/o materiales,
        así como por los perjuicios económicos derivados de dichos daños, ocasionados involuntariamente, que deriven directamente
        del ejercicio como aficionado de la actividad deportiva asegurada.</span>
      </li>
      <li>
        <span>No contratable según ámbito geográfico y actividad deportiva mencionado en normas.</span>
      </li>
    </ul>
  </div>
</modal>



<modal [modalTitle]="'Nota previa informativa'" [blocking]='false' [modalId]='modalNota' class='modal'>
    <div class="container">
      <note-informative></note-informative>
    </div>
  </modal> 

<app-header-protect *ngIf="!bmi" id="logalty-ready-header"></app-header-protect>
<profile-slider [interval]="photoInterval" [noWrap]="noLoopSlides" class="desktop margin-top" [title]="'Antes de activar tu cobertura'" [text]="'Estos son los pasos que debes seguir'">
  <div class="div-steps"></div>
  <slide *ngFor="let slidez of slides; let index=index" [active]="slidez.active">
    <img class="wide-carousel" [src]="slidez.image">
  </slide>
</profile-slider>
<div class="logalty-ready">
  <div class="container">
    <div class="row">
      <ul>
        <li class="flex-wrap">
          <div class="title-text">
            <i class="number one"></i>
            <p>
              Pulsa para ver la
              <a (click)='openModal("nota")'>nota informativa previa</a>.
            </p>
          </div>
        </li>
        <li class="flex-wrap">
          <div class="title-text">
            <i class="number two"></i>
            <p>
              Revisa que cumples con los
              <a (click)='openModal("requisitos")'>requisitos de contratación</a>.
            </p>
          </div>
        </li>
        <li class="flex-wrap">
          <div class="title-text">
            <i class="number three several-lines"></i>
            <strong>Firmar el Boletín de Adhesión al seguro.</strong>
          </div>
          <div class="text-title">
            <p>Con la firma del Boletín de Adhesión estarás cubierto en más de 75 actividades deportivas y podrás activar cualquiera de
            las coberturas de tu seguro EVO PROTECT cuando lo necesites y de una manera rápida.</p>
          </div>
        </li>
        <li class="flex-wrap">
          <div class="title-text">
            <i class="number four several-lines"></i>
            <strong>Confirma que tu email es correcto.</strong>
          </div>
          <div class="text-title">
            <p>Te enviaremos el Boletín de Adhesión.</p>
          </div>
        </li>
      </ul>
    </div>
    <form [formGroup]="prevSignForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="row">
        <div class="mobile-container">
          <span *ngIf="email.value">Tu correo</span>
          <input type="text" #email id="email" name="email" required maxlength="50" placeholder="Tu correo" formControlName="email"
            tabindex="1" />
        </div>
      </div>
      <div class="row btn">
        <div class="btn-group">
          <button type="submit" tabindex="2" class="btn-common btn-common_fix_bold">CONTINUAR</button>
        </div>
      </div>
    </form>
  </div>
</div>
