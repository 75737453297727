import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Localidades } from '../../collections/localidades';
import { Observable } from 'rxjs';
import { HelperService } from 'app/services/helpers.service';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

const API_URL = environment.apiUrl;

@Injectable()
export class ApiLocalidadesService {
  options = {};
  constructor(private http: HttpClient,
    private helperSrv: HelperService) {
    this.options = { withCredentials: true, observe: 'response' };
  };
  // API: POST
  public getLocalidades(data: Object): Observable<Response> {
    let cloneData = this.helperSrv.cloneAndConvertToUpperCase(data);
    return this.http
      .post(`${API_URL}/fullonline/rsi_api/localidades/v1.0.0`, JSON.stringify(cloneData), this.options)
      .pipe(map(response => response['body']));
  };

  getLocalidadesData(): Observable<any> {
    let apiUrl = './assets/localidades.json';
    return this.http
      .get(apiUrl)
      .pipe(map(response => response));
  };
}
