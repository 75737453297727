<section class="c-header">
  <div class="responsive-header">
    <div class="backbutton" (click)="botonVolver()">
      <i class="evo flecha"></i>
    </div>
    <div class="page-title">
      <span>Mis tarjetas</span>
    </div>
    <div class="closebutton">
      <i class="evo close"></i>
    </div>
  </div>
</section>
<form class="form" class="form" action="">
  <div class="main-content">
    <section class="info info_noborder">
      <p class="info-title">Seleccionar tu tarjeta de crédito</p>
    </section>
    <section class="cardselection">
      <div class="cardselection-item" *ngFor='let tarjeta of tarjetasActivas; let i = index' (click)="getRadio($event, i)" [ngClass]="i === indexSelected ? 'is-selected':''" > <!--is-selected-->
        <div class="cardselection-item-radiobtn">
          <div class="group-radio--circle">
            <div class="group-radio-item">
              <input type="radio" id="input{{i}}" value="{{tarjeta?.numTarjeta}}" name="seleccionarTarjeta" [checked]="i === indiceTarjetaSeleccionada ? true : false">
              <label id="label{{i}}" class="button-label" for="input{{i}}" (click)="getRadio($event, i)" ></label> <!--[ngClass]="i === indexSelected ? 'selected-radio':''"-->
            </div>
          </div>
        </div>
        <div class="cardselection-item-data">
          <p class="title">{{tarjeta.digitos}}</p>
          <p class="description">
            <span class="description-text">Límite de Crédito: </span>
            <span class="description-money">{{tarjeta.limite}}</span>
          </p>
        </div>
      </div>
      <div class="cardselection-item is-blocked" *ngFor='let tarjeta of tarjetasBloqueadas'>
        <div class="cardselection-item_blocked">
          <img class="blocked-icon" src="./assets/images/block.svg" alt="tarjeta bloqueada">
          <p class="blocked-info">Tarjeta bloqueada</p>
        </div>
        <div class="cardselection-item-radiobtn">
          <div class="group-radio--circle">
            <div class="group-radio-item">
              <input type="radio" id="tarjeta.numTarjeta" value="{{tarjeta.numTarjeta}}" name="seleccionarTarjeta" disabled>
              <label id="card2-label" class="button-label" for="tarjeta.numTarjeta"></label>
            </div>
          </div>
        </div>
        <div class="cardselection-item-data">
          <p class="title">{{tarjeta.digitos}}</p>
          <p class="description">
            <span class="description-text">Límite de Crédito: </span>
            <span class="description-money">{{tarjeta.limite}}</span>
          </p>
        </div>
      </div>
    </section>
  </div>
</form>
