// modules
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/primeng';
// custom modules
import { SharedModule } from '../../shared/shared.module';
import { MicroinsurancesRoutingModule } from './microinsurances-routing.module';
// services
import { AuthGuardMicro } from '../../../services/auth-guard-micro.service';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { NavigationMicroService } from 'app/services/navigation-micro.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor, RegisterInterceptor } from 'app/services/token/token.interceptor';
// components
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MicroinsurancesScreenComponent } from './microinsurances-screen/microinsurances-screen.component';
import { SettingsComponent } from './settings/settings.component';
import { HeaderMicroComponent } from './components/header-micro/header-micro.component';
import { FooterMicroComponent } from './components/footer-micro/footer-micro.component';
import { PrevSignComponent } from './prev-sign/prev-sign.component';
import { DataEcoComponent } from './data-eco/data-eco.component';
import { LogaltyMicroComponent } from './logalty/logalty-microseg.component';
import { LogaltyBMIFinishComponent } from './logaltyBMIFinish/logaltyFinish.component';
import { MyEvoProtectComponent } from 'app/modules/profile/microinsurances/my-evo-protect/my-evo-protect.component';
import { CancelAdhesionOkComponent } from './cancel-adhesion-ok/cancel-adhesion-ok.component';
import { ConfigureComponent } from './configure/configure.component';
import { SimuladorComponent } from './simulador/simulador.component';
import { CoverageComponent } from './components/coverage/coverage.component';
import { InputModalComponent } from './components/input-modal/input-modal.component';
import { CustomRadioButtonsComponent } from './components/custom-radio-buttons/custom-radio-buttons.component';
import { AllReadyComponent } from './all-ready/all-ready.component';
import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
import { PayoutMethodComponent } from './payout-method/payout-method.component';
import { NoteInformativeComponent } from './prev-sign/note-informative.component';
import { OtpActiveComponent } from './otp-active/otp-active.component';
import { TarificadorComponent } from 'app/modules/profile/microinsurances/components/tarificador/tarificador.component';
import { SliderComponent } from './components/slider/slider.component';
import { SelectorOptionComponent } from './components/selector-option/selector-option.component';
import { TarificadorView } from 'app/modules/profile/microinsurances/tarificador/tarificador.view.component';
import { LoadLogaltyComponent } from 'app/modules/profile/microinsurances/load-logalty/load-logalty.component';


/** API */
import { ApiMicroinsurances } from '../../../services/api/api.microinsurances.service';
import { MicrosegConfigService } from '../../../services/microinsurance-config.service';
import { HelpersMicroinsurancesService } from 'app/services/helpers-microinsurances.service';
import { PreloadLogaltyComponent } from './preload-logalty/preload-logalty.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MicroinsurancesRoutingModule,
    SharedModule,
    CalendarModule
  ],
  declarations: [
    MicroinsurancesScreenComponent,
    LogaltyMicroComponent,
    LogaltyBMIFinishComponent,
    PrevSignComponent,
    DataEcoComponent,
    SettingsComponent,
    HeaderMicroComponent,
    FooterMicroComponent,
    DatepickerComponent,
    MyEvoProtectComponent,
    CancelAdhesionOkComponent,
    ConfigureComponent,
    SimuladorComponent,
    CoverageComponent,
    InputModalComponent,
    CustomRadioButtonsComponent,
    AllReadyComponent,
    BeneficiariesComponent,
    PayoutMethodComponent,
    NoteInformativeComponent,
    OtpActiveComponent,
    TarificadorComponent,
    SliderComponent,
    TarificadorView,
    SelectorOptionComponent,
    PreloadLogaltyComponent,
    LoadLogaltyComponent
  ],
  providers : [
    AuthGuardMicro,
    AuthMicroService,
    NavigationMicroService,
    ApiMicroinsurances,
    MicrosegConfigService,
    HelpersMicroinsurancesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RegisterInterceptor,
      multi: true
    }
  ],
  entryComponents: [

  ]

})
export class MicroinsurancesModule { }
