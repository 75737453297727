<header-profile></header-profile>
<div class="simulador">
  <div class="image thin-border"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>CUENTA INVERSORA</h2>
        <p></p>
      </legend>
    </div>
    <div class="main-content">
      <div class="col-8">
        <div class="row info">
          <div class="item">
            <div class="text-info">
              <h6>SIN COMISIONES</h6>
              <div>CUENTA CORRIENTE</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info">
                <h6>ALERTAS GRATIS</h6>
                <div></div>
            </div>
          </div>
          <div class="item">
            <div class="text-info">
              <h6>TARJETAS DE DÉBITO GRATUITAS</h6>
              <div>UNA POR TITULAR</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info">
              <h6>TRANSFERENCIAS PERIÓDICAS GRATIS</h6>
              <div></div>
            </div>
          </div>
          <div class="right-item">
              <div class="text-info">
                 <h6>En 5 minutos puedes disfrutar de todas las ventajas de la CUENTA DUO ¿Comenzamos?</h6>
              </div>
              <div class="row">
                <div class="mobile-container center mobile">
                  <button class="btn-common btn-common_fix_bold" (click)="submit()">
                    Abrir una cuenta inversora
                  </button>
                </div>
              </div>
              
            </div>
        </div>
        <!--
        <div class="row">
          <div class="video-container">
            <div *ngIf='showPlay' 
              (click)="hidePlayer();playVid()" 
              class="video" [ngStyle]="{'background':'url('+poster+')'}">
            </div>
            <div *ngIf='!showPlay' class="player-container">
              <iframe height="330"
              src="https://www.youtube.com/embed/Ge6vFzSZ1WU?autoplay=1&controls=0&rel=0">
              </iframe>
            </div>
          </div>
        </div>
      -->
      </div>
      <!--
      <div class="col-4">
        <div class="item">
          <div class="text-info">
             <h6>En 5 minutos puedes disfrutar de todas las ventajas de la CUENTA DUO ¿Comenzamos?</h6>
          </div>
        </div>
      </div> -->
    </div>
    <div class="req">
      <div class="text-req">
        <h6>FONDO INTELIGENTE: PASA DE AHORRADOR A INVERSOR TÚ SOLITO.</h6>
        Inversiones más atrevidas o más estables con solo mover un dedo.
      </div>
      <div class="text-list">
        <ul>
          <li>UN FONDO CON DOS COMPARTIMENTOS</li>
          <li>TÚ ELIGES DÓNDE, CÓMO Y CUÁNDO INVERTIR</li>
          <li>TODO DESDE TU MÓVIL</li>
          <li>SIN INVERSIÓN MÍNIMA</li>
        </ul>
      </div>
    </div>
    <div>
      <button class="btn-common btn-common_fix_bold" (click)="submit()">
        Contrata
      </button>
    </div>
  </div>
</div>
