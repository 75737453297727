<loading [showMe]="loading"></loading>
<mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone>

<modal [modalTitle]="'ACEPTACIÓN DE POLÍTICA DE PRIVACIDAD'" [blocking]='false' [modalId]='modalLegal' [modal-wide]="true" class='modal'>
    <div class="container">
        <h2>ACEPTACIÓN DE POLÍTICA DE PRIVACIDAD</h2>
        <p>De conformidad con lo establecido en el Reglamento 679/2016 General de Protección de Datos y normativa complementaria,
        te informamos de que tus datos serán incorporados a los ficheros de EVO BANCO, S.A. en tanto entidad responsable del
        tratamiento automatizado o no, de los mismos. Los datos facilitados a lo largo de la presente solicitud, entre ellos
        aquellos utilizados para la identificación no presencial mediante videoconferencia así como la grabación y conservación
        del presente proceso de contratación, incluida la IP desde donde se realiza, serán tratados con la finalidad de estudiar
        tu solicitud y posterior gestión de tu cuenta. Asimismo, tus datos serán tratados con finalidad estadística conforme a
        técnicas de agregación y seudonimización para analizar patrones globales sobre ingresos y gastos de usuarios, pero en
        ningún momento como consecuencia de este tratamiento se generarán patrones de carácter longitudinal respecto del uso de
        determinados servicios o la singularización de usuarios, no siendo posible la vinculación directa o indirecta de la
        información con un determinado usuario. Para el estudio de tu solicitud, Evo Banco, S.A. podrá consultar ficheros de
        las empresas del grupo, datos que constan en redes sociales, así como en ficheros de solvencia económica y podrán
        ponerse en contacto contigo tanto telefónicamente como por otros medios, incluidos los telemáticos, con la misma
        finalidad. Al marcar la casilla anterior, consientes expresamente que estos datos sean almacenados y tratados en un
        fichero automatizado y manual del que Evo Banco, S.A. es responsable. No aceptar estas condiciones implicaría no poder
        seguir adelante con el proceso de contratación. Los datos objeto de dicho tratamiento se conservarán en la medida en que
        sean necesarios para las finalidades descritas y en cumplimiento de la normativa aplicable. Conservaremos la información
        personal con las medidas de seguridad adecuadas en función de los datos y tratamientos por 10 años. No obstante, en todo
        momento podrás ejercer tus derechos de acceso, rectificación, cancelación, portabilidad, limitación y oposición al
        tratamiento de datos a través del correo electrónico protecciondedatos@evobanco.com o bien por escrito dirigido a Evo
        Banco, S.A. en la Calle Don Ramón de la Cruz 84, CP28006 de Madrid, indicando en el asunto “Protección de Datos” y adjuntando copia
        de tu DNI o documento identificativo válido, a cualquiera de ellos. Asimismo, te informamos de que tienes derecho a
        recabar la tutela de la Agencia Española de Protección de Datos a través de su página Web www.aepd.es. Por otro lado,
        quedas informado de la comunicación de los datos de la presente solicitud al Fichero CONFIRMA, para la finalidad de
        prevención de fraude. Los responsables de dicho fichero son las Entidades Adheridas al Reglamento del Fichero CONFIRMA,
        siendo el encargado del tratamiento Confirma Sistemas de Información, S.L., con domicilio en la Avda, de la Industria,
        18, TRES CANTOS (28760) MADRID. Podrás consultar el listado de Entidades que actualmente están adheridas al Reglamento
        del Fichero CONFIRMA en www.confirmasistemas.es Los datos comunicados al Fichero CONFIRMA podrán ser cedidos a las
        Entidades Adheridas al Reglamento del Fichero CONFIRMA para la finalidad previamente indicada. Podrás ejercer tus
        derechos de acceso, rectificación, cancelación, limitación, portabilidad y oposición, dirigiéndote al domicilio de
        CONFIRMA SISTEMAS DE INFORMACIÓN, S.L. en la dirección arriba indicada.</p>

        <p>He sido informado por EVO Banco que la legislación vigente sobre prevención de blanqueo de capitales le obliga a obtener
        de sus clientes la información de su actividad económica y a realizar una comprobación de la misma, tanto en el momento
        del establecimiento de relaciones de negocio como periódicamente para su debida actualización. Con este exclusivo fin de
        verificación de la información facilitada, presto mi consentimiento expreso a EVO Banco, para que en mi nombre pueda
        solicitar ante la Tesorería General de la Seguridad Social dicha información. Igualmente he sido informado que en el
        supuesto de que los datos por este suministrados para la concesión de la financiación no se correspondan con los
        obrantes en la Tesorería General de la Seguridad Social, la entidad financiera trasladará a la Tesorería General de la
        Seguridad Social la información necesaria que esta le requiera para realizar las verificaciones y comprobaciones
        necesarias en orden a la protección de la seguridad de la información de la Seguridad Social y prevención del fraude.
        Los datos obtenidos de la TGSS serán utilizados exclusivamente para la gestión señalada anteriormente. En el caso de
        incumplimiento de esta obligación por parte de la entidad financiera y/o del personal que en ella presta servicios, se
        ejecutarán todas las actuaciones previstas en la Ley Orgánica 15/99, de 13 de diciembre, de Protección de Datos de
        Carácter Persona y normativa que la desarrolla.</p>

      <div class="btn-group">
        <button type="button" class="btn-common btn-common_fix_bold" (click)="checkCondiciones()">ACEPTAR</button>
      </div>
    </div>
</modal>

<modal modalId="faqModal" [modalTitle]="'Faq'" class="modal">
  <div class="modalContent">

    <h3 class="collapsible" (click)="estadoCollapse0 = !estadoCollapse0" >
      ¿QUÉ CONDICIONES DEBO CUMPLIR PARA MANTENER LA CUENTA INTELIGENTE?
    </h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse0, 'collapse-none' : !estadoCollapse0}">
      <p>Ahora, disfrutar de las ventajas de tu Cuenta Inteligente es más fácil que nunca. Lo único que tienes que hacer es mantener 10 puntos.
        No te preocupes, es muy fácil conseguirlo ¿cómo? Solo cumpliendo con una de estas opciones tendrás esos 10 puntos.
      </p>
      <ul>
        <li>Domiciliar tu nómina, pensión o desempleo</li>
        <li>Domiciliar 4 recibos</li>
        <li>Mantener 10.000 euros de saldo en cualquier producto EVO</li>
        <li>Domiciliar ingresos mensuales de al menos 600€</li>
        <li>Tener con nosotros una hipoteca, préstamo o tarjeta revolving</li>
        <li>Contratar 1 producto distintos a la Cuenta Inteligente y domiciliar 2 recibos</li>
        <li>Contratar 2 productos distintos a la Cuenta Inteligente</li>
      </ul>
      <br/>
      <h4 class="collapsible" (click)="estadoCollapse1 = !estadoCollapse1">¿Y SI A PESAR DE TODO NO TENGO LOS 10 PUNTOS?</h4>
      <div [ngClass]="{'collapse-block' : estadoCollapse1, 'collapse-none' : !estadoCollapse1}">
        <p>
          No te preocupes, puedes seguir disfrutando de todos los productos, servicios y ventajas de la cuenta inteligente por solo 8€/mes y con una
          remuneración en la cuenta a plazo del 0,01% TIN.Condiciones exclusivas Cuenta Inteligente EVO. Cuenta a plazo remunerada durante 4 meses al
          0,20% TIN  para cualquier importe y sin comisiones de ningún tipo. Liquidación mensual de intereses. Sin penalización por reintegros antici
          pados. Renovable automáticamente cada 4 meses según remuneración vigente. Para disfrutar de las ventajas de la Cuenta Inteligente tendrás que
          conseguir 10 puntos a través del esquema de vinculación  que puedes consultar en www.evobanco.com/cuenta-inteligente , en el caso de no conse
          guir los 10 puntos, pasarás a la Cuenta Sin Vínculos  con la que sigues manteniendo todos los productos, servicios y ventajas de la Cuenta In
          teligente pero con un coste   por mantenimiento  de 8€ al mes y una remuneración en la cuenta a plazo del  0,01% TIN. Ejemplos representativos
          del cálculo de la TAE bajo la hipótesis de que la remuneración se mantiene invariable durante todo el año: Cuenta inteligente: 9.000 euros en
          plazo al 0,20% TIN, TAE del 0,20% y Cuenta Sin Vínculos: 9.000 euros en plazo al 0,01% TIN y comisión mantenimiento 8€/mes, TAE del -1,056%.
          Debes saber que aunque EVO no cobre nada por retirar dinero a débito en el extranjero, algunas entidades propietarias de cajeros pueden aplicar
          una tasa de recargo por su utilización. Debes mantener todos los productos gratuitos que integran la Cuenta Inteligente EVO (Cuenta corriente,
          Cuenta a plazo, Tarjeta Inteligente, Banca a Distancia y Servicio de Alertas). Puedes disponer de un máximo de dos Tarjetas Inteligentes bonificadas.
          Puedes cancelar tu Cuenta Inteligente sin coste de cancelación.

        </p>
      </div>
    </div>
  </div>
  <div class="modalContent">

  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse2 = !estadoCollapse2">¿HAY COMISIONES DE MANTENIMIENTO?</h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse2, 'collapse-none' : !estadoCollapse2}">
      <p>
        Olvídate de las comisiones. La Cuenta Inteligente no tiene ninguna. Y por supuesto, con tu dinero siempre disponible. Tiene tarjetas de Débito y
        Crédito GRATIS, sin comisión de emisión ni renovación. Puedes sacar dinero gratis de cualquier cajero de España si retiras 120€ o más. Sea del
        color que sea. Y para retiradas gratis sin cantidad mínima y sin límite de operaciones, tienes a tu disposición una de las mayores redes de cajeros
         de España, formada por los cajeros EVO, la red EURO6000 (excepto CaixaBank). Kutxabank, Ibercaja, Grupo Liberbank, Abanca, Unicaja, Grupo Caja Rural
         y Grupo Cooperativo Cajamar, cajeros de Banco Popular y EAc, Banco Pastor y Targo Bank, Bankinter y Caja de Ingenieros. . ¡Son más de 16.000!
        Y con muchos más servicios gratis: transferencias y traspasos nacionales y comunitarios en euros, ingreso de cheques nacionales, Banca Electrónica,
        alertas a móvil...
      </p>
    </div>
  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse3 = !estadoCollapse3">VOY DE VACACIONES AL EXTRANJERO ¿PUEDO SACAR DINERO SIN COMISIONES?</h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse3, 'collapse-none' : !estadoCollapse3}">
      <p>
        Sí, las tarjetas EVO te acompañan allí donde vayas.
        Con tu Tarjeta de Débito (la negra) o con la Tarjeta Inteligente, EVO no te cobra ni un céntimo por retirar dinero a débito de cajeros en el extranjero.
        Puedes hacerlo todas las veces que quieras y sin cantidad mínima. Estés en el país que estés. Eso sí, es posible que, aunque EVO no te cobre ninguna comisión
        por sacar dinero con la Tarjeta de Débito o la Tarjeta Inteligente, fuera de la Unión Europea las entidades propietarias de los cajeros pueden aplicar una tasa
        de recargo por su utilización. En todo caso, y para tu tranquilidad (y la nuestra) es obligatorio que, para que puedan cobrarte esta comisión, el cajero te
        avise por pantalla y te especifique cuánto te va a costar. Si quieres, podrás cancelar la operación, porque no todos los cajeros cobran esta tasa e incluso
        puede no ser siempre la misma.
      </p>
    </div>
  </div>
  <div class="modalContent">
    <h3 class="collapsible" (click)="estadoCollapse4 = !estadoCollapse4">¿PUEDO AÑADIR UN SEGUNDO TITULAR?</h3>
    <div [ngClass]="{'collapse-block' : estadoCollapse4, 'collapse-none' : !estadoCollapse4}">
      <p>
        ¡No hay problema! Porque la Cuenta Inteligente admite dos titulares. Eso sí, cada cliente, solo podrá ser titular de una única Cuenta Inteligente EVO, así
        que tú decides: solo/a, o con pareja.
      </p>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal>

<div *ngIf="modalBureau" class="modal-backdrop" id="backdropId">
  <div class="modal-container" (click)="modalClick($event)">
    <div class="image-modal"></div>
    <span class="evo close ko cross" (click)='closeModal()'></span>
    <div class="content" style="">
        <h2>¡VAYA!</h2>
        <p class="invalid-text">
          Lo sentimos, no podemos seguir con la solicitud online. Llámanos al
        <a class="link-blue" href="tel:910900900">910 900 900</a> y te explicamos los motivos.</p>
    </div>
  </div>
</div>


<modal [modalId]='alreadyClientModal' [blocking]='true' class='modal modal-login'>
  <mobile-error-zone
    *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray"
    class="custom-erros">
  </mobile-error-zone>
  <div class="container login">
    <h2>¿Ya eres cliente?</h2>
    <p>
      Accede con tus datos a tu área personal.
    </p>
    <p *ngIf="errMsg" class="err-login">
      {{errMsg}}
    </p>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginUser" (ngSubmit)="sendClient()" novalidate>
      <div class="equal-buttons">
        <input placeholder="Usuario (NIF/NIE)"
        autocomplete="off"
        type="text" name="user"
        formControlName="user"
        autofocus
        maxlength="9"
        (focus)="closeKeyboard()"
        (blur)="validUser()">
        <div class="error-text" *ngIf="errService.desktopErrors(loginUser.controls.user,'user')">
          {{errService.desktopErrors(loginUser.controls.user,'user')}}
        </div>
        <input keyboard placeholder="Contraseña"
        autocomplete="off"
        type="password" name="pass"
        formControlName="pass" (numberEmmited)="numberEmmited($event)"
        (deleteLast)="deleteLast($event)" onfocus="blur()">
        <div class="error-text" *ngIf="errService.desktopErrors(loginUser.controls.pass,'pass')">
          {{errService.desktopErrors(loginUser.controls.pass,'pass')}}
        </div>
      </div>
      <div class="row">
        <button class="btn-common btn-common_fix_bold" type="submit" (click)="closeKeyboard()">
          <span>Enviar</span>
        </button>
      </div>
    </form>
    <div class="row">
      <p class = 'label'>
        <b>¿No tienes claves para entrar?</b> Llámanos al <a class="link-blue" href="tel:910900900">910 900 900</a> y te ayudaremos.
      </p>
    </div>
  </div>
</modal>

<div class="personalDetails">
  <div class="image" [ngClass]="{ 'young' : isYoungAccount }"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <div class="row">
          <h2>DATOS PERSONALES</h2>
            <span *ngIf="esCuentaInteligente()">
              <!-- (mouseleave)="onMouseOut()" -->
              <img (click)="onMouseClick()" (mouseenter)="onMouseOver()"
                   class="icon-info" src="assets/images/icon-info.svg">
            </span>
            <div class="" *ngIf="mostrarFaq">
              <ul>
                <li>¿QUÉ CONDICIONES DEBO CUMPLIR PARA MANTENER LA CUENTA INTELIGENTE?</li>
                <li>¿HAY COMISIONES DE MANTENIMIENTO?</li>
                <li>VOY DE VACACIONES AL EXTRANJERO ¿PUEDO SACAR DINERO SIN COMISIONES?</li>
                <li>¿PUEDO AÑADIR UN SEGUNDO TITULAR?</li>
              </ul>
            </div>
        </div>
        <p>Ahora que vas a formar parte de la Banca Inteligente, necesitamos conocerte un poco más.</p>
      </legend>
    </div>
    <form [formGroup]="personalDetailsForm"
    (keydown.enter)="$event.preventDefault()"
    (ngSubmit)="onSubmit()" autocomplete="off" novalidate>
      <!-- <div class="row">
        <div class="mobile-container">
          <dropdown class="drop-mobile" id="documento" name="documento" formControlName="documento"
          [options]="documents"></dropdown>
        </div>
      </div> -->
      <div class="row">
        <div class="mobile-container">
          <input type="text" id="dni" name="dni"
            autocomplete="off"
            placeholder="DNI/NIE"
            formControlName="numeroDocumento"
            maxlength="9">
            <div class="error-text" *ngIf="errService.desktopErrors(personalDetailsForm.controls.numeroDocumento, 'numeroDocumento')">
              {{errService.desktopErrors(personalDetailsForm.controls.numeroDocumento, 'numeroDocumento')}}
            </div>
        </div>
        <div class="mobile-container">
          <dropdown class="dropdown drop-mobile" id="pais" name="pais" formControlName="pais"
          [options]="countries"></dropdown>
        </div>
        <div *ngIf='isSpain' class="mobile-container">
          <dropdown  class="dropdown drop-mobile" id="provincia" name="provincia" formControlName="provincia"
          [options]="provinces"></dropdown>
        </div>
        <div *ngIf='!isSpain' class="mobile-container hollow">

        </div>
      </div>
      <div class="row">
        <div *ngIf='!isSpain' class="mobile-container">
          <input type="text" id="localidad" name="localidad" formControlName="localidadnacimiento"
          [maxlength]="!isCountrySelected ? 0 : 45"
          placeholder="Localidad de nacimiento"
          autocomplete="off">
          <div class="error-text" *ngIf="errService.desktopErrors(personalDetailsForm.controls.localidadnacimiento, !isCountrySelected ? 'paisNacimiento':'localidadnacimiento')">
            {{errService.desktopErrors(personalDetailsForm.controls.localidadnacimiento, !isCountrySelected ? 'paisNacimiento':'localidadnacimiento')}}
          </div>
        </div>
        <div class="mobile-container">
          <dropdown class="drop-mobile" #estadoCivil id="estadoCivil" name="estadoCivil" formControlName="estadoCivil"
          [options]="civilStatus"></dropdown>
        </div>
        <div *ngIf="isMarried" class="mobile-container">
          <dropdown class="dropdown drop-mobile" id="regimenEconomico" name="regimenEconomico" formControlName="regimenEconomico"
          [options]="economicSystems"></dropdown>
        </div>
          <div *ngIf="!isMarried" class='mobile-container hollow'>
          </div>
      </div>
      <div class="mobile-container">
        <div class="group-radio ">
          <p class="false-label">¿Tienes obligaciones fiscales fuera de España?</p>
          <div class="options">
            <input type="radio" id="si" value="si" name="obligacionesFiscales"
            formControlName="obligacionesFiscales">
            <label id="si-label"class="button-label" for="si"></label>
            <input type="radio" id="no" value="no" name="obligacionesFiscales"
            formControlName="obligacionesFiscales">
            <label id="no-label" class="button-label"  for="no"></label>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="codigoRetornoDni == 0 && existFirstTitular()">
        <div class="checkbox-group first">
          <div class="form-checkbox" (click)="modalOpenCondiciones($event)">
            <input type="checkbox" name="check_conditions" id="check_conditions" value="Acepto"
                    formControlName="reactiveCheckConditions">
            <label for="check_conditions" id="check_conditions_label" (keypress)="selectCheck($event)" tabindex="1">
                He leído y acepto la <a>Política de Privacidad</a>
              </label>
            <div class="error-text" *ngIf="personalDetailsForm.get('reactiveCheckConditions').hasError('notChecked')">
              {{errors.checkObligatorio}}
            </div>
          </div>
        </div>
      </div>
       <div class="row" *ngIf="codigoRetornoDni == 0 && existFirstTitular()">
        <div class="checkbox-group">
          <div class="form-checkbox">
            <input type="checkbox" name="check_publicity" id="check_publicity" value="Acepto" formControlName="publicidad">
            <label id="check_publicity_label" for="check_publicity" (keypress)="selectCheckPubli($event)">
              Consiento que mis datos personales sean utilizados para que EVO Banco pueda ponerse en contacto conmigo
              a través de cualquier canal (incluido los electrónicos) para informarme sobre otros productos o
              servicios que no tengan similitud alguna con los que tengo con ustedes, que sean ofrecidos por Evo,
              empresas del Grupo Bankinter y terceras empresas con las que haya suscrito acuerdos de colaboración.
            </label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="codigoRetornoDni == 0 && existFirstTitular()" style="margin-top: -20px">
        <div class="checkbox-group first">
          <div class="form-checkbox">
            <input type="checkbox" name="check_solvencia" id="check_solvencia" value="Acepto"
              formControlName="reactiveCheckSolvencia">
              <label id="check_publicity_label" for="check_solvencia" (keypress)="selectCheckSolvencia($event)" tabindex="2">
                  <!-- <p>He leído y acepto el <a>perfilado de solvencia</a></p> -->
                  Consiento que mis datos personales sean utilizados para que EVO Banco puedan acceder e incorporar
                    en sus ficheros datos accesibles al público o de terceras empresas para elaborar perfiles y personalizar ofertas
                    y adaptarlas a mis necesidades, gustos y preferencias.
              </label>
              </div>
        </div>
      </div>
      <br/>

      <div class="btn-group">
          <button type="submit" class="btn-common btn-common_fix_bold">CONTINUAR</button>
      </div>
  </form>
  </div>
</div>
