import { Component, OnInit, Renderer, ElementRef, ViewChild} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { VideoSelectionService } from '../../../services/video-selection.service';
import { HelperService } from '../../../services/helpers.service';
import { RecomiendaService } from '../../../services/recomienda.service';
import { ModalService } from '../../../services/modal.service';
import { AuthService } from '../../../services/auth.service';
import { ACCOUNTS } from 'app/resources/account.model';

@Component({
  selector: 'app-everything-nearly',
  templateUrl: './everything-nearly.component.html',
  styleUrls: ['./everything-nearly.component.scss'],
  providers: [
              VideoSelectionService,
              RecomiendaService,
              ModalService
              ]
})
export class EverythingNearlyComponent implements OnInit {

  recomiendaModal:string = 'recomienda';
  recomiendaURL: string;
  vidPlaying:boolean = false;
  showPlay:boolean = true;
  public isYoungAccount: boolean = false;

  @ViewChild('video') public video:ElementRef;
  @ViewChild('iframereco') public iframereco: ElementRef;
  constructor(private router: Router,
              public vidService: VideoSelectionService,
              private renderer: Renderer,
              private helpers: HelperService,
              private recomienda: RecomiendaService,
              public modService: ModalService,
              public authService: AuthService
            ) {}

  ngOnInit() {
    this.isYoungAccount = (location.href.indexOf(ACCOUNTS[ACCOUNTS.CJCode].constPath) !== -1 || this.authService.product === ACCOUNTS.CJCode);
    this.authService.isLoggedIn = false;
    this.vidService.selectVideo();
    document.body.classList.remove('no-scroll');
  }

  goToRecomienda() {
    const sessionProspect = this.authService.getSessionProspect(),
          email = sessionProspect.datosPersonales.email,
          productoContratado = sessionProspect.logalty.codProductoContratado;
    this.recomiendaURL = this.recomienda.getRecomiendaURL(email, productoContratado);
    this.modService.open(this.recomiendaModal);
    this.iframereco.nativeElement.src = this.recomiendaURL;
  }

  closeRecomienda(event:any){
    this.renderer.setElementClass(event.path[1], 'no-display', true);
  }

  goToProfile() {
    const nextStep = '/perfil/mi-perfil-iban';
    this.helpers.navigateTo(nextStep);
  }

  hidePlayer() {
    this.showPlay = false;
  }

  playVid() {
    if (!this.vidPlaying) {
      this.video.nativeElement.play();
    } else {
      this.video.nativeElement.pause();
    }
    this.vidPlaying = !this.vidPlaying
  }

}
