export class CancelPolicy {
  policyId: string;
  policyNumber: string;
  clientId: string;
  policyHolderNif: string

  constructor(values: Object = {}) {
    Object.assign(this, values); 
  }
}

export class CancelPolicyResponse {
  error: boolean;
  errorMessages: [
    {
      code: string;
      messageLevel: number;
      messageText: string;
      internalMessage: any;
      parameters: any
    }
  ];
  messages: [
    {
      code: string;
      messageLevel: number;
      messageText: string;
      internalMessage: any;
      parameters: any
    }
  ];

  infoSalesforceError: {
    status: string;
    messageText: string;
  };

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
