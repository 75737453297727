import { Component, OnInit } from '@angular/core';
import { STEPS_REGISTER_OTP, STEPS_REGISTER_OTP_ST } from 'app/resources/steps-register.model';
import { AuthService } from 'app/services/auth.service';
import { ACCOUNTS } from 'app/resources/account.model';


@Component({
  selector: 'app-register-screen',
  templateUrl: './register-screen.component.html',
  styleUrls: ['./register-screen.component.scss']
})
export class RegisterScreenComponent implements OnInit {

  hideSteps = false;
  stepsInfo;

  constructor(private authService: AuthService) { }

  ngOnInit() {

    if (this.authService.product === ACCOUNTS.TipoACode) {
      this.hideSteps = true;
    }

    if (this.authService.product === ACCOUNTS.STCode) {
      this.stepsInfo = STEPS_REGISTER_OTP_ST;
    }
    else {
      this.stepsInfo = STEPS_REGISTER_OTP;
    }

  }


}
