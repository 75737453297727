import { Injectable } from '@angular/core';
import {
  CanActivate, Router, ActivatedRouteSnapshot,
  RouterStateSnapshot, CanActivateChild, NavigationExtras,
  CanLoad, Route
} from '@angular/router';

import { NavigationMicro } from '../collections/navigation-micro';
import { NavigationMicroService } from 'app/services/navigation-micro.service';
import { AuthMicroService } from './auth-micro.service';

@Injectable()
export class AuthGuardMicro implements CanActivate, CanActivateChild, CanLoad {
  lastStep:number = 0;
  navs = NavigationMicro;
  microsession = JSON.parse(this.authMicro.getMicroSession());

  constructor(private navigationService: NavigationMicroService,
              private authMicro: AuthMicroService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    const formattedUrl = this.formatUrl(state.url);
    let result =  this.checkMicro(formattedUrl, state);

    if (result){
      if(this.navs[formattedUrl] > this.lastStep){
        this.lastStep = this.navs[formattedUrl];
      }
    }
    return result;
  }

  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    let url = `/${route.path}`;
    return true;
  }

  formatUrl(url):string{
    if (url.indexOf("?") !== -1) {
      url = url.substring(0, url.indexOf("?"));

    }
    return url;
  }

  checkMicro(url: string, state: RouterStateSnapshot): boolean {

    let defaultPath =  this.microsession && this.microsession['bmi'] != '' ? '404': 'perfil';

    if (url.toLocaleLowerCase() === '/' + defaultPath ||
        url == '/'){ return true; }

    let resultEstate = this.checkState(url, state, this.lastStep);

    this.lastStep = resultEstate.lastStep;
    if (resultEstate.isAllowed) return true;
    if (resultEstate.defaultPath) defaultPath  = resultEstate.defaultPath;

    this.router.navigate([defaultPath])
    return false;
  }

  checkState(url : string, state: RouterStateSnapshot, lastStep: number){
    return this.navigationService.isNavigationAllow(url, state, lastStep);
  }

  empty() {
    this.navigationService.emptyNavigation();
    this.lastStep = 0;
  }

}
