import { Component, HostListener, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { NestedObject } from '../../../../services/nestedObject.service';
import { AuthService } from './../../../../services/auth.service';
import { LogaltyMicroseg, CheckLogalty, signLogalty, setCheckLogalty } from './../../../../collections/logalty';
import { LogaltyService } from '../../../../services/logalty.service';
import { ApiLogaltyService } from '../../../../services/api/api.logalty.service';
import { DeviceService } from '../../../../services/device.service';
import { ErrorService } from '../../../../services/error.service';
import { ModalService } from '../../../../services/modal.service';
import { HelperService } from '../../../../services/helpers.service';
import { ProductSelection } from '../../../../services/product-selection.service';
import { Persona } from '../../../../collections/persona';
import { environment } from '../../../../../environments/environment';
import { PAISES } from '../../../../resources/countries.model';
import { ESTADO_CIVIL } from '../../../../resources/civil-status.model';
import { VIAS } from '../../../../resources/tipo-via.model';
import { SITUACIONES } from '../../../../resources/situacion-vivienda.model';
import { SITUACIONESLABORALES } from '../../../../resources/situacion-laboral.model';
import { REGIMENES } from '../../../../resources/regimen-economico.model';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { LoginService } from 'app/services/login.service';
import { ListPoliciesResponse, ListPolicies } from 'app/collections/list-policies';
import { User } from 'app/collections/user';
import { ACCOUNTS } from 'app/resources/account.model';
import { AnalyticsService } from 'app/services/analytics.service';
import { HelpersMicroinsurancesService } from 'app/services/helpers-microinsurances.service';

declare var $;

@Component({
  selector: 'logalty',
  templateUrl: './logalty-microseg.component.html',
  styleUrls: ['./logalty-microseg.component.scss'],
  providers: [LogaltyService, ApiLogaltyService, DeviceService, ModalService]
})
export class LogaltyMicroComponent implements OnInit {

  public logaltySamlAction: string = environment.urlLogalty;
  private samlLoaded: Boolean = false;
  private persona: Persona;
  public loading: boolean = false;
  msgSrvError: string = '';
  msgNoAccounts: string = "No tienes ninguna cuenta disponible para completar el proceso de contratación";
  modalSrvError: string = 'modal-srv-error';
  private firstTitular: Persona;
  private logaltyData;
  microSession = JSON.parse(this.authMicroService.getMicroSession());
  public logaltyLoaded = false;
  public logaltySignature = false;
  public ocultarIframe = false;
  urlCallback = "assets/misc/signatureMicroSegurosOk2.html";
  bmi = false;

  constructor(private elementRef: ElementRef,
    private router: Router,
    private authService: AuthService,
    private authMicroService: AuthMicroService,
    private logaltySrv: LogaltyService,
    private deviceService: DeviceService,
    public errService: ErrorService,
    public modService: ModalService,
    private helpers: HelperService,
    private helpersMicroinsurance: HelpersMicroinsurancesService,
    private microConfig: MicrosegConfigService,
    private loginService: LoginService,
    public nestedObject: NestedObject,
    private analytics: AnalyticsService
  ) { }

  ngOnInit() {
    const persona = this.authService.getSessionProspect();
    const sessionLogalty = persona.logalty;
    if (sessionStorage.getItem('logaltySigned') !== "true") {
      this.loadSaml(persona.datosPersonales.id_cliente_EVO, sessionLogalty);
    }
    //Se añade la clase stepMarginTop
    if(document.getElementById('user-path')){
      document.getElementById('user-path').classList.add('stepMarginTop');
    }
     this.bmi = JSON.parse(sessionStorage.getItem("bmiSession"));
  }

  ngAfterViewInit(){
    if (sessionStorage.getItem('logaltySigned') === "true") {
      this.checkLogaltyResponse();
    }
  }

  checkLogaltyResponse(){
    let logaltySigId = sessionStorage.getItem('logaltySignatureId');
    sessionStorage.removeItem('logaltySigned');
    this.watchForIframeClosing(logaltySigId);
  }

  setLogaltyProspec(id, signatureiD, office?) {
    sessionStorage.setItem('logaltySignatureId', id);
    const canal = this.deviceService.getDevice();
    let oficina =  office? office : environment.oficina;
    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      producto: "fullonline",
      datosPersonales: {
        oficinaCliente: oficina
      },
      logalty: {
        logaltySignatureId: id,
        signatureId: signatureiD,
        codProductoContratado: ACCOUNTS.MSCode,
        isSecondSigner: false
      }
    })
  }

  loadSaml(id_cliente_EVO, currentLogalty) {
    this.loading = true;
    const newLogalty: signLogalty = new signLogalty();
    this.authService.getProspect(id_cliente_EVO).subscribe((data) => {
      this.loading = false;
      this.loadLogalty(data, newLogalty, currentLogalty);
    }, _error => {
      this.goToError();
    });
  }

  setSignatureId(data, currentLogalty) {
    const canal = this.deviceService.getDevice();
    const logaltyReceived = data.logalty && data.logalty.signatureId;
    const useCurrentLogalty = currentLogalty && currentLogalty.signatureId && !logaltyReceived;

    let newSignatureID,
        number,
        oficina;

    if (logaltyReceived) {
      newSignatureID = data.logalty.signatureId;
    }
    else if (useCurrentLogalty){
      newSignatureID = currentLogalty.signatureId;
    }
    else {
      newSignatureID = data.datosPersonales.id_cliente_EVO + '_microseg_0';
    }

    number = newSignatureID.split('_microseg_')[1];
    number = Number(number) + 1;
    if (number && number !== NaN)
      newSignatureID = `${newSignatureID.split('_microseg_')[0]}_microseg_${number}`;

    oficina = data.datosPersonales.oficinaCliente ?  data.datosPersonales.oficinaCliente : environment.oficina;

    this.persona = Object.assign({
      canal: canal,
      codigoEntidad: "0239",
      producto: "microseg",
      datosPersonales: {
        oficinaCliente: oficina
      },
      logalty: {
        codProductoContratado:ACCOUNTS.MSCode,
        signatureId: newSignatureID
      }
    })
    return this.persona.logalty.signatureId
  }

  loadLogalty(objPerson: Persona, newLogalty, currentLogalty) {
    let office;
    this.loading = true;
    // let modifyPersonData = this.nestedObject.nestedObjectAssign({}, objPerson.logalty);
    const modifyPersonData = this.nestedObject.nestedObjectAssign({}, objPerson);

    if (typeof modifyPersonData.logalty === 'undefined') {
      if (!!currentLogalty){
        modifyPersonData.logalty = Object.assign(currentLogalty);
      }
      else {
        modifyPersonData.logalty = Object.assign({});
      }
    }

    if (typeof modifyPersonData.datosPersonales !== 'undefined') {
      office = modifyPersonData.datosPersonales.oficinaCliente;
    }

    modifyPersonData.logalty = Object.assign({
      signatureId: this.setSignatureId(objPerson, currentLogalty)
    });

    this.authService.updateProspect(this.persona).subscribe(persona => {
      if (persona.response.codigo === 'KO') {
        this.msgSrvError = !!persona.response.message ? persona.response.message : 'Error genérico';
        this.modService.open(this.modalSrvError);
      }
    }), (error) => {
      this.goToError();
    }
    this.logaltySrv.signLogaltyMicroseg(this.firstCallLogalty(modifyPersonData)).subscribe((dataLogalty) => {
      this.logaltyData = dataLogalty;
      if (dataLogalty.logaltySignatureId === null) {
        if (dataLogalty.operationErrorCode === 800 && dataLogalty.operationErrorMessage == 'Duplicated Key') {
          const num = Math.floor((Math.random() * 100) + 100);
          modifyPersonData.logalty.signatureId = `${modifyPersonData.datosPersonales.id_cliente_EVO}_microseg_${num}`;
          this.loadLogalty(modifyPersonData, newLogalty, currentLogalty);
          return;
        } else {
          this.goToError(); return;
        }
      }
      this.setLogaltyProspec(dataLogalty.logaltySignatureId, dataLogalty.signatureId, office);
      newLogalty = dataLogalty;
      this.samlLoaded = !!newLogalty;
      this.submitForm(this.helpers.encode(newLogalty.saml));
      this.authService.updateProspect(this.persona).subscribe((data) => {
        this.loading = false;
        this.logaltyLoaded = true;
        //Se elimina la clase logaltymargintop cuando termina el loading
        //document.getElementById('user-path').classList.remove('logaltymargintop');
        if (data.response.codigo === 'KO') {
          this.msgSrvError = !!data.response.message ? data.response.message : 'Error genérico';
          this.modService.open(this.modalSrvError);
        }
      }, error => {
        this.goToError();
      });
    }, (error) => {
      this.goToError();
    });
  }

  goToError() {
    this.errService.navToError();
  }

  submitForm(saml) {
    $('form:first input[name=saml_assertion]').val(saml);
    $('form:first').submit();
  }

  watchForIframeClosing(logaltySignatureId ) {
    if (logaltySignatureId){
      const logalty: setCheckLogalty = new setCheckLogalty({
        product: 'microseg',
        guids: [logaltySignatureId ]
      });
     // this.loading = true;
      this.logaltySignature = true;
      if(document.getElementById('user-path')){
        document.getElementById('user-path').classList.add('stepMarginTop');
      }
      this.ocultarIframe= true;
      this.logaltySrv.checkState(logalty).subscribe(data => {
        if (data.operationErrorMessage === null && data.states[0].stateCode == 12 && data.states[0].resultCode == 7) {
          const logaltymicroseg = {
            guid: logaltySignatureId,
            userId: data.states[0].userId,
            stateCode: data.states[0].stateCode,
            stateMessage: data.states[0].stateMessage,
            cancelCode: data.states[0].cancelCode,
            cancelMessage: data.states[0].cancelMessage,
            resultCode: data.states[0].resultCode,
            resultMessage: data.states[0].resultMessage
          }
          this.logaltySrv.logaltyMicroseg(logaltymicroseg).subscribe(microseg => {
            this.loading = false;
            this.logaltySignature = false;
            this.changeContratacion(true);
          }, _error => {
            this.loading = false;
            this.logaltySignature = false;
            this.goToError();
          });
        } else {
          this.loading = false;
          this.logaltySignature = false;
          this.changeContratacion(false);
        }
      }, _error => {
        this.loading = false;
        this.logaltySignature = false;
        this.goToError();
      });
    }else {
      this.changeContratacion(false);
    }
  }

  changeContratacion(isOk: Boolean) {
    this.microSession = JSON.parse(this.authMicroService.getMicroSession());
    //Si el cliente no dispone de ninguna cuenta
    if (this.microSession == null) {
      this.msgSrvError = this.msgNoAccounts;
      this.modService.open(this.modalSrvError, true);
      return;
    }

    //Si venimos de BMI
    if (this.microSession.bmi === '') {
      let next = (isOk) ? '/signatureok' : '/signatureko';
      let nextStep = this.helpersMicroinsurance.getParentPathMicro(next);
      this.helpers.navigateTo(nextStep);
    //Venimos de microseguros a través del portal
    } else {
      //Si logalty ha ido bien
      if (isOk) {
        this.analytics.addPageLikeEvent(1);
        let listPolicies: ListPolicies = Object.assign({
          clientNif: this.loginService.userData.persona.idExterno,
          branchAlias: "0019",
          status: ["1", "2"]
        });
        //Obtenemos la póliza
        //this.loading = true;
        this.logaltySignature=true;
        this.microConfig.listPolicies(listPolicies).subscribe(res => {
          this.loading = false;
          this.logaltySignature = false;
          const response: ListPoliciesResponse = res;
          if (response.error != null) {
            this.msgSrvError = response.error;
            this.modService.open(this.modalSrvError);
            return;
          }
          //Si nos devuelve la póliza correctamente
          if (response.list != null && response.list.length > 0) {
            const policy = response.list[0],
                  nextstep = '/evo-protect/configura-microseguro';

            this.loginService.listPolicies = policy;
            this.logaltySignature = false;
            this.helpers.navigateTo(nextstep);
          } else {
            const nextstep = '/evo-protect/404';
            this.helpers.navigateTo(nextstep);
          }
        },
        _error => {
          this.loading = false;
          this.errService.navToError();
        });
      //Fallo de logalty
      } else {
        const nextstep = '/evo-protect/simulador';
        this.helpers.navigateTo(nextstep);
      }
    }
  }

  searchValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item['value'] && value && item['value'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    if (name)
      return name.name;
    return name;
  }

  searchCodeFromValue(values, value) {
    let name;
    values.forEach((item) => {
      if (item['code'] && value && item['code'].toUpperCase() === value.toUpperCase()) {
        name = item;
      }
    })
    if (name)
      return name.name;
    return name;
  }

  getFieldUndefinedEscaped(field) {
    if (field === undefined || field === '' || field === null)
      return ' ';
    if (typeof field === 'string')
      return field.toUpperCase();
    else
      return field;
  }

  firstCallLogalty(persona: Persona): LogaltyMicroseg {
    const session = JSON.parse(this.authMicroService.getMicroSession()),
          data = new Date(),
          date = data.getDate(),
          month = data.getMonth() + 1,
          year = data.getFullYear();

    let IBAN = ' ';
    if (session && session['IBAN']) {
      IBAN = session['IBAN'];
    };
    let logalty = <LogaltyMicroseg>{};
    logalty = this.nestedObject.nestedObjectAssign({}, logalty);
// debugger;
    logalty.mobileNumber = persona.datosPersonales.telefonoMovil;
    logalty.product = 'microseg';
    logalty.urlBackFragment = this.urlCallback;
    //logalty.logaltySignatureId = persona.logalty ? persona.logalty.logaltySignatureId ? persona.logalty.logaltySignatureId : undefined : undefined;
    logalty.signatureUserData = this.nestedObject.nestedObjectAssign({});
    logalty.signatureUserData.identityDocumentType = persona.datosPersonales.codigoIdExterno === '51' ? 'DNI' : 'NIE';
    logalty.signatureUserData.treatment = persona.datosPersonales.sexo === 'H' ? 'M' : 'F';
    logalty.signatureUserData.email = this.getFieldUndefinedEscaped(persona.Microseguros.emailMicroseguros);
    // let signatureID = this.helpers.isEmpty(persona.logalty) ? `${persona.datosPersonales.id_cliente_EVO}_microseg_1` : persona.logalty.signatureId;

    // if (signatureID.indexOf('_microseg_') === -1) {
    //   signatureID = `${persona.datosPersonales.id_cliente_EVO}_microseg_1`;
    // }
    logalty.signatureUserData.signatureId = this.persona.logalty.signatureId;
    logalty.signatureUserData.userId = persona.datosPersonales.id_cliente_EVO;

    logalty.signatureUserData.signatureDate = `${date}/${month}/${year}`;
    logalty.signatureUserData.policyHolderName = this.getFieldUndefinedEscaped(persona.datosPersonales.nombreCliente);
    logalty.signatureUserData.policyHolderLastname1 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido1Cliente);
    logalty.signatureUserData.policyHolderLastname2 = this.getFieldUndefinedEscaped(persona.datosPersonales.apellido2Cliente);
    logalty.signatureUserData.policyHolderNif = persona.datosPersonales.idExterno;
    logalty.signatureUserData.signatureDay = date.toString();
    logalty.signatureUserData.signatureMonth = this.monthString(data.getMonth());
    logalty.signatureUserData.signatureYear = year.toString();
    // logalty.signatureUserData.policyHolderMandateReference = logalty.signatureUserData.signatureId;
    logalty.signatureUserData.policyHolderIban = IBAN;
    logalty.signatureUserData.policyHolderAddress = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreVia);
    logalty.signatureUserData.policyHolderFloor = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioPlanta);
    logalty.signatureUserData.policyHolderNumber = this.getFieldUndefinedEscaped(persona.datosDomicilio.domicilioNumero);
    logalty.signatureUserData.policyHolderPostalCode = this.getFieldUndefinedEscaped(persona.datosDomicilio.codigoPostal);
    logalty.signatureUserData.policyHolderLocality = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreLocalidad);
    logalty.signatureUserData.policyHolderProvince = this.getFieldUndefinedEscaped(persona.datosDomicilio.nombreProvinciaDomicilio);
    logalty.signatureUserData.countryResidence = this.nestedObject.nestedObjectAssign({});
    logalty.signatureUserData.countryResidence.alpha3Code = this.getFieldUndefinedEscaped(persona.datosPersonales.paisResidencia);
    logalty.signatureUserData.countryResidence.alpha2Code = "Alpha2";
    logalty.signatureUserData.countryResidence.englishDescription = "N/A";
    return logalty;
  }

  monthString(num: number): string {
    return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"][num];
  }
}
