<loading [showMe]="loading"></loading>
<mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone>
<modal [modalTitle]="'RÉGIMEN DE TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL'" [blocking]='false' [modalId]='modalLegal' [modal-wide]="true" class='modal'>
  <div class="container">
    <h2>RÉGIMEN DE TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL</h2>
    <p>Los datos de carácter personal que proporciona el USUARIO serán incorporados a los ficheros de titularidad de EVO
      para efectuar el tratamiento, automatizado o no, de tales datos con la finalidad de prestar los Servicios a
      Distancia objeto de los presentes T&C.
    </p>
    <p>
      Además, EVO Banco, tiene interés legítimo para enviarle comunicaciones comerciales, por cualquier canal, sobre
      productos y servicios que EVO pueda ofrecerle y sean similares a los Servicios a Distancia; y a elaborar perfiles
      con el objetivo de que las comunicaciones comerciales que le envíe se ajusten todo lo posible a sus necesidades,
      gustos y preferencias. El USUARIO podrá oponerse a estos tratamientos solicitándolo en la dirección de correo
      electrónico bajas@evobanco.com produciéndose la cancelación inmediata de los Servicios a Distancia ante la
      imposibilidad de seguir prestando los mismos por EVO. Por otro lado, el USUARIO podrá ejercitar los derechos de
      acceso, rectificación, supresión, oposición, portabilidad y limitación previstos en el Reglamento General de
      Protección de datos 679/2016 y normativa vigente aplicable sobre protección de datos en cada momento, dirigiéndose
      mediante correo electrónico a protecciondedatos@evobanco.com o por escrito a EVO BANCO, S.A., en tanto entidad
      responsable de los ficheros, en la siguiente dirección: Calle Serrano, 45,1ª Planta,. 28001 - Madrid - indicando
      “LOPD” e incluyendo una copia de su documento oficial acreditativo de su identidad. Para más información accede a
      la Política de Privacidad de EVO Banco, S.A. disponible en la web www.evobanco.com. Le informamos que tiene
      derecho a recabar la tutela de la Agencia Española de Protección de datos a través de su página web www.agpd.es.
      El USUARIO declara que los datos personales proporcionados a EVO son ciertos, exactos y veraces, y se compromete a
      comunicar de inmediato a la EVO cualquier cambio de los mismos, y a mantenerlos permanentemente actualizados.
    </p>
    <p>
      Por otro lado, quedas informado de la comunicación de los datos de la presente solicitud al Fichero CONFIRMA, para
      la finalidad de prevención de fraude. Los responsables de dicho fichero son las Entidades Adheridas al Reglamento
      del Fichero CONFIRMA, siendo el encargado del tratamiento Confirma Sistemas de Información, S.L., con domicilio en
      la Avda, de la Industria, 18, TRES CANTOS (28760) MADRID. Podrás consultar el listado de Entidades que actualmente
      están adheridas al Reglamento del Fichero CONFIRMA en www.confirmasistemas.es Los datos comunicados al Fichero
      CONFIRMA podrán ser cedidos a las Entidades Adheridas al Reglamento del Fichero CONFIRMA para la finalidad
      previamente indicada. Podrás ejercer tus derechos de acceso, rectificación, cancelación, limitación, portabilidad
      y oposición, dirigiéndote al domicilio de CONFIRMA SISTEMAS DE INFORMACIÓN, S.L. en la dirección arriba indicada.
    </p>
    <div class="btn-group" *ngIf="isYoungAccount">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="checkCondiciones()">ACEPTAR</button>
    </div>
  </div>
</modal>
<modal [modalTitle]="'Condiciones Legales CI'" [blocking]='true' [modalId]='modalLegalCI' class='modal'>
  <div class="container">
    <a  class="close"
        (click)="closeModalCi()"></a>
    <p style="font-size: 15px;font-weight: bold;">Por favor, lee y acepta los siguientes términos y condiciones.</p>
    <p style="font-size: 15px;font-weight: bold;">Los que tiene * son obligatorios para continuar con la contratación :</p>
    <div>
      <label  class="switch">
        <input  type="checkbox"
              [(ngModel)]="isCheckedReactiveCheckConditions">
        <span class="slider round"></span>
      </label>
      <p> *{{literals_mod.literal}}</p>
    </div>
    <div>
      <label  class="switch">
        <input  type="checkbox"
              [(ngModel)]="isCheckedPublicidad">
        <span class="slider round"></span>
      </label>
      <p> {{literals_mod.comunicaciones_comerciales}}</p>
    </div>
    <p class="enlaceModal" (click)="checkCondicionesCI(undefined)"> CONTINUAR CON LAS OPCIONES SELECCIONADAS</p>
    <div class="btn-group">
      <button type="button"
              class="btn-common btn-common_fix_bold"
              (click)="checkCondicionesCI(true)">SI A TODO</button>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal>

<app-header></app-header>
<div [hidden]="isYoungAccount">
  <!-- <p class="medium pink">{{literals.online}}</p> -->
  <div class="main-content">
    <div class="col-med">
      <div class="medium-header">
        <p class="big pink">{{literals.online}}</p>
      </div>
      <div class="medium-header">
        <p class="big">{{literals.title}}</p>
      </div>
    </div>
  </div>
  <div class="main-content">
    <div  class="col-6 first_div">
      <div>
        <p class="litle gris">{{literals.subtitle}}</p>
      </div>
      <div class="movil-imagen"></div>
    </div>
    <div  class="col-6 second_div">
      <p><span class="number pink">1. </span>Antes de empezar recuerda que necesitas :</p>
        <div class="row">
          <ul class="square-joven">
            <li *ngFor="let literal of literals.bienvenido.title_p">
              <span>{{ literal }}</span>
            </li>
          </ul>
        <!-- <div>
          <p  class="litle gris"
              style="font-size: 16px !important;">Además, cuando tu cuenta ya esté abierta, podrás incluir un segundo titular desde tu app.</p>
        </div> -->
        </div>
        <div class="row info-second-handler">
          Además, cuando tu cuenta ya esté abierta, podrás incluir un segundo titular desde tu app.
        </div>
      <p><span class="number pink">2. </span>Ahora dinos tu número de teléfono móvil:</p>
        <form  (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()" [formGroup]="emailPhoneForm" >
          <div class="mobile-row">
            <div class="mobile-container">
              <div [class]="confirmEmailPhone.valid? confirmEmailPhone.class: ''">
                <input  type="tel"
                        required
                        #ephone
                        no-whitespace
                        number-input
                        class="phoneCI"
                        [minLength]="confirmEmailPhone.isPhone? 8 : 1"
                        [maxLength]="confirmEmailPhone.isPhone? 9 : 9"
                        autocomplete="off"
                        tabindex="1"
                        inputmode="numeric"
                        formControlName="reactiveEmailPhone"
                        placeholder="+34">
                    <div class="error-text" *ngIf="setFirstTooltip(emailPhoneForm.controls.reactiveEmailPhone)">
                      {{setFirstTooltip(emailPhoneForm.controls.reactiveEmailPhone)}}
                    </div>
              </div>
              <div  class="form-checkbox"
                    style="margin-top: 5%"
                    (click)="changeLegalCondiciones(false)">
                    <input  type="checkbox"
                            name="check_conditions"
                            id="check_conditions"
                            value="Acepto"
                            formControlName="reactiveCheckConditions">
                    <label  class="gris"
                            tabindex="3">
                            He leído y acepto el <a (click)="changeLegalCondiciones(true)">Régimen de Tratamiento de Datos de Carácter Personal</a>
                    </label>
                    <div class="error-text" *ngIf="emailPhoneForm.get('reactiveCheckConditions').hasError('notChecked')">
                      {{errors.checkObligatorio}}
                    </div>
              </div>
              <div class="form-checkbox" style="margin-top: 5%">
                <input  type="checkbox"
                        name="check_publicity"
                        id="check_publicity"
                        value="Acepto"
                        formControlName="publicidad">
                <label  class="gris"
                        id="check_publicity_label"
                        for="check_publicity"
                        (keypress)="selectCheckPubli($event)">
                        Consiento que mis datos personales sean utilizados para que EVO Banco pueda ponerse en contacto conmigo
                        a través de cualquier canal (incluido los electrónicos) para informarme sobre otros productos o
                        servicios que no tengan similitud alguna con los que tengo con ustedes, que sean ofrecidos por Evo,
                        empresas del Grupo Bankinter y terceras empresas con las que haya suscrito acuerdos de colaboración.
                </label>
              </div>

              <div class="buttonCI">
                  <button type="submit" class="btn-common btn-common_fix_bold" tabindex="4">ABRIR CUENTA</button>
              </div>
            </div>
          </div>
        </form>
    </div>
  </div>
  <div class="pre-footer">
    <p>En EVO queremos ayudarte a ahorrar</p>
  </div>
  <app-footer-center></app-footer-center>
</div>
<div [hidden]="!isYoungAccount">
  <div class="bienvenido">
    <div class="image" [ngClass]="{ 'young' : isYoungAccount }"></div>
      <h2 *ngIf='_isTokenized' class='loading'>Estamos cargando tus datos...</h2>
    <div class="container" [hidden]='_isTokenized' >
      <div class="title-container" >
        <h2>{{literals.bienvenido.title}}</h2>
        <!-- <div class="subtitle">{{literals.bienvenido.subtitle}}</div> -->
        <div class="row">
          <ul class="square-bullet">
            <li class="not-margin" *ngFor="let literal of literals.bienvenido.title_p">
              <span>{{ literal }}</span>
            </li>
          </ul>
        </div>
      </div>
      <form  (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()" [formGroup]="emailPhoneForm" >
      <div class="equal-container row">
        <div class="mobile-container">
          <div [class]="confirmEmailPhone.valid? confirmEmailPhone.class: ''">
            <input type="tel" id="e-phone" name="e-phone" required
            #ephone
            no-whitespace
            number-input
            [class]="setFirstInputClass(ephone)"
            [minLength]="confirmEmailPhone.isPhone? 8 : 1"
            [maxLength]="confirmEmailPhone.isPhone? 9 : 9"
            autocomplete="off"
            tabindex="1"
            inputmode="numeric"
            formControlName="reactiveEmailPhone"
            placeholder="Número de móvil">
            <div class="error-text" *ngIf="setFirstTooltip(emailPhoneForm.controls.reactiveEmailPhone)">
              {{setFirstTooltip(emailPhoneForm.controls.reactiveEmailPhone)}}
            </div>
            </div>
          </div>
      </div>

      <div class="checkbox-group first">
        <div class="form-checkbox" (click)="modalOpenCondiciones()">
          <input  type="checkbox"
                  name="check_conditions_CJ"
                  id="check_conditions_CJ"
                  value="Acepto"
                  formControlName="reactiveCheckConditions">
          <label tabindex="3">
            He leído y acepto el <a>régimen de tratamiento de datos de carácter personal</a>
          </label>
          <div class="error-text" *ngIf="emailPhoneForm.get('reactiveCheckConditions').hasError('notChecked')">
            {{errors.checkObligatorio}}
          </div>
        </div>
      </div>
      <div class="checkbox-group first" style="margin-top: -20px">
        <div class="form-checkbox">
          <input  type="checkbox"
                  name="check_publicity_CJ"
                  id="check_publicity_CJ"
                  value="Acepto"
                  formControlName="publicidad">
          <label id="check_publicity_label" for="check_publicity_CJ" (keypress)="selectCheckPubli($event)">
            Consiento que mis datos personales sean utilizados para que EVO Banco pueda ponerse en contacto conmigo
            a través de cualquier canal (incluido los electrónicos) para informarme sobre otros productos o
            servicios que no tengan similitud alguna con los que tengo con ustedes, que sean ofrecidos por Evo,
            empresas del Grupo Bankinter y terceras empresas con las que haya suscrito acuerdos de colaboración.
          </label>
        </div>
      </div>
      <div class="btn-group">
          <button type="submit" class="btn-common btn-common_fix_bold" tabindex="4">COMENZAR</button>
      </div>
    </form>
  </div>
  </div>
</div>
