import { Component,
  OnInit,
  ElementRef,
  ViewChild,
  forwardRef,
  Inject,
  Output,
  Input,
  EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { FormValidatorService } from 'app/services/form-validator.service';
import { ErrorService } from 'app/services/error.service';
import { HelperService } from 'app/services/helpers.service';
import { ModalService } from 'app/services/modal.service';
import { Calendar } from 'primeng/primeng';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { ERRORES } from 'app/resources/errors.model';
import { InputModalComponent } from 'app/modules/profile/microinsurances/components/input-modal/input-modal.component';
import { HelpersMicroinsurancesService } from 'app/services/helpers-microinsurances.service';


@Component({
selector: 'micro-datepicker',
templateUrl: './datepicker.component.html',
styleUrls: ['./datepicker.component.scss'],
providers: [  FormValidatorService ]

})

export class DatepickerComponent implements OnInit {

  public es: any;
  public defaultDate = this.getDateSelected();

  private hourInit = 0;
  private minutesInit = 0;

  private hoursBefore = null;
  private minutesBefore = null;
  private calendarBefore;

  public dateForm: FormGroup;
  mindate = new Date();

  errors = ERRORES;
  errorsArray = [];
  public errorHourMin = "";

  @ViewChild('calendar') private calendar:Calendar;
  @ViewChild('hours') private hours:ElementRef;
  @ViewChild('minutes') private minutes:ElementRef;

  @Input("selectedDate")
  set selectedDate(date:Date) {
    if (date != null) {
      this.calendar.updateModel(date);
      this.calendarBefore = date;
      this.hourInit = date.getHours();
      this.minutesInit = date.getMinutes();
      const labelHours = this.helperMicro.formatField(this.hourInit),
            labelMinutes = this.helperMicro.formatField(this.minutesInit);
      this.helpers.updateFieldValue(this.dateForm, 'hours', labelHours);
      this.helpers.updateFieldValue(this.dateForm, 'minutes', labelMinutes);
    }
  }


  constructor(private fb: FormBuilder,
          private evoValidator: FormValidatorService,
          private helpers: HelperService,
          private modService: ModalService,
          public errService: ErrorService,
          public helperMicro: HelpersMicroinsurancesService,
          private authService: AuthMicroService,
          @Inject(forwardRef(() => InputModalComponent)) private _parent:InputModalComponent) {
  this.createForm();
  }

  ngOnInit() {
    this.es = {
      firstDayOfWeek: 1,
      dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
      dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
      dayNamesMin: [ "D","L","M","X","J","V","S" ],
      monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
      monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ]
    }

    this.modService.onopen.subscribe(()=> {
      this.getDateSelected();
      // Tenemos datos guardados anteriormente
      if (this.hoursBefore != null) {
        this.hourInit = this.hoursBefore;
        this.minutesInit = this.minutesBefore;
      } else {
        //Añadimos media hora más de la hora actual
        const date = this.add30MinutesToCurrentDate();
        this.hourInit = date.getHours();
        this.minutesInit = date.getMinutes();
      }
      this.loadDataCalendar();
    });
  }


  // Cargamos los datos del calendario y la hora y minutos
  loadDataCalendar() {
    const labelHours = this.helperMicro.formatField(this.hourInit),
          labelMinutes = this.helperMicro.formatField(this.minutesInit);
    this.helpers.updateFieldValue(this.dateForm, 'hours', labelHours);
    this.helpers.updateFieldValue(this.dateForm, 'minutes', labelMinutes);
    this.calendar.updateModel(this.calendarBefore);
    this.calendar.updateUI();
  }

  // Pasa la hora al componente padre
  private formatPassDate(date) {
    const formatDate = this.helpers.formatDate(date),
          formatHour = this.helpers.formatHour(date),
          dateSelected =  `${formatDate} a las ${formatHour}`;
    this._parent.eventCloseModal.next({value: dateSelected, date: date, time: date.getTime()});
  }


  // Añade 30 minutos a la hora actual
  add30MinutesToCurrentDate() : Date {
    const date: Date = new Date();
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setTime(date.getTime() + 30 * 60 * 1000);
   return date;
  }

  // Creamos el formulario
  private createForm() {
    this.dateForm = this.fb.group({
      date: [null, [Validators.required]],
      hours : [this.helperMicro.formatField(this.hourInit), [Validators.required, Validators.minLength(2), Validators.maxLength(2), this.evoValidator.validateHour]],
      minutes: [this.helperMicro.formatField(this.minutesInit), [Validators.required, Validators.minLength(2), Validators.maxLength(2),this.evoValidator.validateMinutes]]
    });

    this.dateForm.get("hours").valueChanges.subscribe((hours) => {
      this.hourInit = parseInt(hours);
    });

    this.dateForm.get("minutes").valueChanges.subscribe((minutes) => {
      this.minutesInit = parseInt(minutes);
    });

    this.dateForm.valueChanges.subscribe((dateForm) => {
      this.errorHourMin = "";
      this.registerMobileErrors();
      this.calendar.updateInputfield();
    });
  }

  onSubmit(e) {
    e.stopPropagation();
    const dateValue = this.calendar.inputFieldValue,
          fieldDate: string[] = dateValue.split("/"),
          day = parseInt(fieldDate[1]),
          month = parseInt(fieldDate[0]),
          year = parseInt(fieldDate[2]),
          hour = this.hours.nativeElement.value,
          minutes = this.minutes.nativeElement.value,
          date = new Date(year, month - 1, day, hour, minutes, 0, 0),
          // Añade media hora más a la hora actual
          dateNow = this.add30MinutesToCurrentDate();

    // Si la hora seleccionada es menor que la hora actual más 30 mimuntuos, no dejamos seleccionar la hora
    if ((date.getTime() - dateNow.getTime()) < 0) {
      this.errorHourMin = "Se puede activar tu cobertura EVO Protect hasta 30 minutos antes de su inicio de tu activad. ¡Deja 5 min. más que es el tiempo que tardarás en completar el proceso!";
      // this.errorHourMin = "La hora mínima deben ser las " + this.helpers.formatHour(dateNow);
    } else {
      this.calendar.defaultDate = date;
      this.calendarBefore = this.calendar;
      this.hoursBefore = this.hourInit;
      this.minutesBefore = this.minutesInit;
      this.formatPassDate(date);
    }
  }

  onUpHours($event) {
    if (this.hourInit == 23) {
      this.hourInit = 0;
    } else {
      this.hourInit++;
    }
    const label = this.helperMicro.formatField(this.hourInit);
    this.helpers.updateFieldValue(this.dateForm, 'hours', label);
    $event.preventDefault();
  }

  onDownHours($event) {
    if (this.hourInit == 0) {
      this.hourInit = 23;
    } else {
    this.hourInit--;
    }
    const label = this.helperMicro.formatField(this.hourInit);
    this.helpers.updateFieldValue(this.dateForm, 'hours', label);
    $event.preventDefault();
  }

  onUpMinutes($event) {
    if (this.minutesInit == 59) {
      this.minutesInit = 0;
    } else {
      this.minutesInit++;
    }
    const label = this.helperMicro.formatField(this.minutesInit);
    this.helpers.updateFieldValue(this.dateForm, 'minutes', label);
    $event.preventDefault();
  }

  onDownMinutes($event) {
    if (this.minutesInit == 0) {
      this.minutesInit = 59;
    } else {
      this.minutesInit--;
    }
    const label = this.helperMicro.formatField(this.minutesInit);
    this.helpers.updateFieldValue(this.dateForm, 'minutes', label);
    $event.preventDefault();
  }

  getDateSelected() {
    const settingsMicro =  this.authService.getMicroSettingsSession();
    if (settingsMicro != null) {
      const configure = settingsMicro.configure,
            dateObj = configure.date;
      if (typeof dateObj !== 'undefined' && dateObj.date != null) {
        const time = dateObj.time,
              date = new Date(time);

        this.hoursBefore = date.getHours();
        this.minutesBefore = date.getMinutes();
        return date;
      } else {
        return null;
      }
    }

    return null;
  }

  getYearRange() {
    const date = new Date();
    if (date != null) {
      const year = date.getFullYear();
      return '' + year + ':' + (year + 10);
    }
    return '';
  }


  registerMobileErrors() {
    this.errorsArray = [];
    Object.keys(this.dateForm.controls).forEach((control) => {
      const field = this.dateForm.get(control);
      if (field.disabled) return;
      if (!field.valid && !field.pristine) {
        const fieldError = this.errors.filter((error) => {
          return error.field === control;
        });
        this.errorsArray.push(fieldError[0]);
      }
    });
  }
}
