<loading [showMe]="loading"></loading>
<div class="less-25">
  <div class="image young"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <span class="desktop main-icon evo error-computer"></span>
        <h2>TIENES MENOS DE 25</h2>
        <h3>LA CUENTA JOVEN ES PARA TI</h3>
      </legend>
    </div>
    <div class="row">
      <p>Si tienes 25 años o menos, tu producto es la CUENTA JOVEN, con muchas ventajas y ¡tan libre como tú!</p>
    </div>
    <div class="btn-group">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="gotoCJ()">QUIERO UNA CUENTA JOVEN</button>
    </div>
  </div>
</div>
