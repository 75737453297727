export class ModifyData {
  name: string;
  firstName: string;
  secondName:string;
  documentNumber:string;
  expiryDate: any;
  nationality: string;
  birthCountry:string;
  birthProvince:string;
  birthLocation: string;
  residenceCountry:string;
  birthDate: any;
  telephone:string;
  email:string;
  
  personalData: {
    civilStatus:string;
    economicSystem: string;
  };
  postalContact: {
    streetType:string;
    streetName:string;
    streetNumber:string;
    piso:string;
    puerta:string;
    portal:string;
    bloque:string;
    escalera:string;
    fase:string;
    parcela:string;
    postalCode:string;
    residenceProvince:string;
    residenceLocation:string;
    situacionVivienda:string;
  };
  laborData: {
    situacionLaboral:string;
    tipoContrato:string;
    profesion:string;
    tipoActividad:string;
    nombreEmpresa:string;
    antiguedadLaboral:string;
    fechaAltaAutonomo:string;
    ingresosMensuales:string;
    numeroPagas:string;
    
    
  };
  personalNumber: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
