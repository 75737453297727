import { Component, Input } from '@angular/core';

@Component({
  selector: 'user-tooltip',
  templateUrl: './user-tooltip.component.html',
  styleUrls: ['./user-tooltip.component.scss']
})
export class UserTooltip{

  public screenWidth:number;
  @Input() text: string ;
  @Input() sClass: string ;

  constructor() {
  }
}
