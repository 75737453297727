import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { ErrorService } from '../../../services/error.service';
import { AuthTokenService } from 'app/services/token/auth.token.service';
import { HelperService } from 'app/services/helpers.service';
import { InterceptorErrorSrv } from 'app/services/token/interceptor.errors.service';
import { AuthService } from 'app/services/auth.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'environments/environment';
import { SessionStorageService } from 'app/services/session-storage.service';
import { ConstantsService } from 'app/services/constants.service';

@Component({
  selector: 'app-error-default',
  templateUrl: './error-default.component.html',
  styleUrls: ['./error-default.component.scss'],
  providers: []
})
export class ErrorDefaultComponent implements OnInit {

  @Input() name: string;
  defaultIni = true;
  title: string = '¡OH-OH!'
  text: string = "Inténtalo de nuevo pasados unos minutos. Si nos has dejado tu dirección de correo electrónico recibirás un email para que puedas seguir con el proceso desde el último paso que completaste."
  textPre: string = "De todas formas, si necesitas más información, llámanos al ";
  textPost: string = " y te ayudaremos.";
  telf: boolean = false;
  button = { text: "", action: "" };
  modDataError = false;
  modDataErrorRedirect = '';
  titulo = "¡MECACHIS! SE HAN CAÍDO LAS COMUNICACIONES";

  imagen = "./assets/images/satelite.gif";
  imgLapiz = "./assets/images/1_Lapiz.gif";
  imgSatelite = "./assets/images/2_Satelite.gif";
  imgAlien = "./assets/images/3_Alien_ID.gif";
  imgAstroboy = "./assets/images/4_Astroboy.gif";
  imgVayaVaya = "./assets/images/5_VayaVaya.gif";
  imgRocket = "./assets/images/6_Rocket.gif";
  imgAmigosOk = "./assets/images/7_Amigos_OK.gif";
  imgLock = "./assets/images/8_Lock.gif";
  imgMaletin = "./assets/images/9_Maletin.gif";
  imgSecuestro = "./assets/images/10_Secuestro.gif";
  imgAltavoz = "./assets/images/11_altavoz.gif";

  subtitulo = '¡Hemos perdido el contacto con Houston! Por favor, vuelve a intentarlo más tarde';
  subidaDoc = false;

  public param;
  public showHeader = true;
  public showFooter = true;
  public isBMI = false;
  public isSegundoTitular = false;
  public linkToEvo = false;

  public message = undefined;

  constructor(
    private router: Router,
    public errService: ErrorService,
    public auth: AuthTokenService,
    private helpers: HelperService,
    public interceptErr: InterceptorErrorSrv,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private keycloakService: KeycloakService,
    private sessionStorageSrv: SessionStorageService
  ) {
  }

  ngOnInit() {

    try{
      console.error('Init pantalla error full online', true, true);
    }catch(error){

    }

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.origen) {
        this.param = params.origen.toLowerCase();
        if (this.param === 'bmi') {
          this.showHeader = false;
          this.showFooter = false;
          this.isBMI = true;
          this.linkToEvo = false;
        }
      } else if (this.sessionStorageSrv.isItemTrue('isBMI')) {
        this.showHeader = false;
        this.showFooter = false;
        this.isBMI = true;
      }
    });

    if (this.authService.product === ACCOUNTS.STCode) {
      this.isSegundoTitular = true;
    }

    if(this.errService.isCInvCortoFinalMessage){
      this.message = this.errService.isErrorBatch ? this.errService.messageCinvCortoBatch : this.errService.messageCinvCortoSesionFinalizada;
    }

    /** Origen modificacion de datos */
    if (this.errService.modDataError) {
      this.modDataError = true;
      this.titulo = this.errService.titulo || this.titulo;
      this.imagen = this.errService.imagen || this.imagen;
      this.subtitulo = this.errService.subtitulo || this.subtitulo;
      this.button['text'] = this.errService.boton || 'ENTENDIDO';
      this.button['action'] = this.errService.action || 'login';

      //Ocultar botones BMI
      if (this.hiddenButtonForBMI()) {
        this.button['text'] = '';
        this.linkToEvo = false;
      }

      this.getQueryParams();
      // if (this.subidaDoc == true){
      //   this.button['action'] = "login";
      // }
      return;
    }
    if (this.errService.bmiError || this.interceptErr.mpmError) {
      this.title = "¡VAYA!";
      this.text = "No se ha podido completar el proceso. Por favor, vuelve a intentarlo.";
    } else {
      if (this.errService.logaltyWrong) {
        this.text = "Nuestro equipo esta chequeando tu solicitud y se pondrá muy pronto en contacto contigo para que puedas continuar con la contratación.";
      } else if (this.errService.logaltyCancel) {
        this.title = '¡VAYA!';
        this.text = "¡Nos entristece que hayas decidido no ser parte del universo EVO! Si cambias de opinión estaremos encantados de contar contigo.";
      }
      if (this.auth.isUnauth) {
        this.title = "¡VAYA!";
        this.text = "La sesión ha caducado. No te preocupes, tiene solución. Te hemos enviado un email para que puedas volver al punto de la contratación donde te quedaste.";
      }
      if (this.errService.isTitularInteligente) {
        this.defaultIni = false;
        this.telf = true;
        this.title = "¡VAYA!";
        if (this.errService.errUsername) {
          this.name = "YA TIENES UNA CUENTA INTELIGENTE, " + this.errService.errUsername;
        }
        else {
          this.name = "YA TIENES UNA CUENTA INTELIGENTE";
        }
        this.text = "Al ser titular de una Cuenta Inteligente, no puedes ser segundo titular de otra Cuenta Inteligente.";
      }
      if (this.errService.isTitularJoven) {
        this.defaultIni = false;
        this.telf = true;
        this.title = "¡VAYA!";
        if (this.errService.errUsername) {
          this.name = "YA TIENES UNA CUENTA JOVEN, " + this.errService.errUsername;
        }
        else {
          this.name = "YA TIENES UNA CUENTA JOVEN";
        }
        this.text = "Al ser titular de una cuenta joven, no puedes ser segundo titular de otra cuenta inteligente.";
      }
      if (this.errService.errEco) {
        this.title = "¡VAYA!";
        this.name = "si tus datos socioeconómicos han cambiado debes modificarlos en tu área personal.";
        this.text = "Accede a tu área personal y modifica los datos antes de continuar.";
        this.button['text'] = "ir a mi área personal";
        this.button['action'] = "perfil";
      }
      if (this.errService.errUnsubscribe) {
        this.defaultIni = false;
        this.title = "¡VAYA!";
        this.name = "Parece que el proceso de baja ha fallado.";
        this.text = "Por favor, vuelve a intentarlo más tarde.";
        this.button['text'] = "aceptar";
        this.button['action'] = "perfil";
      }
      if (this.errService.rsi) {
        this.title = "¡OH-OH!";
        this.text = 'Inténtalo de nuevo pasados unos minutos.';
      }
      if (this.errService.altaClienteCancel) {
        this.title = "¡Oh-Oh!";
        this.name = 'Esta página no existe';
        this.text = '';
      }
      if (this.errService.altaClienteError) {
        this.title = "¡Oh-Oh!";
        this.name = 'Parece que algo está fallando. ¡Intentalo otra vez!';
        this.text = '';

        if (sessionStorage.getItem('currentProspect') && JSON.parse(sessionStorage.getItem('currentProspect')).datosPersonales) {
          this.button['text'] = "Prueba de Nuevo";
          this.button['action'] = "alta-cliente";
        }
      }
    }
  }

  navTo(step) {
    this.errService.resetErrors();
    this.helpers.navigateTo(step);
  }

  navToModData(step) {
    let isAmpliacion = sessionStorage.getItem('operacion-ampliacion');
    let isInversora = sessionStorage.getItem('operacion-inversora');
    let isSegundoTitular = sessionStorage.getItem('segundo-titular');
    let isPeticionModificacion = sessionStorage.getItem('peticion-modificacion') !== 'false';
    // Si es un error 401 el usuario debe volver al login
    if(this.errService.isStatus401){
      let codProducto = this.sessionStorageSrv.getItem('productoContratando');
      let urlLogin =  'codProductoContratado=';
      switch (codProducto) {
        case ConstantsService.AccountTypes.CuentaInversora.code:
          urlLogin += ConstantsService.AccountTypes.CuentaInversora.code;
          break;
        case ConstantsService.AccountTypes.CuentaInversoraCorto.code:
          urlLogin += ConstantsService.AccountTypes.CuentaInversoraCorto.code;
          break;
        default:
          urlLogin += ConstantsService.AccountTypes.FondoInteligente.code;
          break;
      }
      const redirectUri = environment.endpoint + '/configuracion-cuenta-inversora?'+urlLogin+'&origen=BE' + location.search;
      this.keycloakService.logout(redirectUri);
      return;
    }
    if (step === 'login') {
      // Si se debe redirigir a la ventana de /login, se elimina la sesión del Keycloak.
      // El Keycloack redirige al login y después regresa a la ventana indicada en 'redirectUri'.

      // TODO: Obtener URL de redirección en función del flujo de contratación.
      const redirectUri = environment.endpoint + '#/ampliacion-step1' + location.search;

      this.keycloakService.logout(redirectUri);
      return;
    }

    if (step === 'cuenta-inversora-redirect') {
      window.location.href = environment.cuentaInversoraUrl;
      return;
    }

    if (step !== 'subir-documentacion' && (isAmpliacion || (isInversora && isPeticionModificacion) || step === 'bancaelectronica')) {
      this.errService.deletePreviousSession();
      window.location.href = 'https://bancaelectronica.evobanco.com/';
      return;
    }

    if (isSegundoTitular && this.isBMI) {
      this.errService.deletePreviousSession();
      window.location.href = 'https://www.evobanco.com/segundo-titular-fin/';
      return;
    }

    this.errService.resetErrors();
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };
    this.router.navigate([step], navigationExtras);
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.subidaDocumentacion) {
        sessionStorage.setItem('subidaDocumentacion', params.subidaDocumentacion);
        this.subidaDoc = true;
      }
    });
  }

  botonVolver() { }

  hiddenButtonForBMI() {
    const hiddenButtons = ['ENTENDIDO', 'CERRAR Y VOLVER'];
    return this.isBMI && !this.isSegundoTitular && (hiddenButtons.includes(this.button['text']));
  }
}
