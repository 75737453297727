<div class="modal-container modal-fullscreen">
  <span *ngIf="showHeader" [ngClass]="['evo', 'close', 'ko']" (click)='close()'></span>
  <div [ngClass]="showHeader? 'content': 'content content-bmi'">
    <div class="list-container">
      <div class="step-box">
        <div class="step-box-img">
          <span>
            <i class="evo ico-encuadre"></i>
          </span>
        </div>
        <div class="step-box-description">
          <p class="header-2">
            Encuadre y fondo
          </p>
          <p>
            Fondo liso, sin objetos ni fondo. Evita cortar información del documento.
          </p>
        </div>
      </div>
      <div class="step-box">
        <div class="step-box-img">
          <span>
            <i class="evo ico-iluminacion"></i>
          </span>
        </div>
        <div class="step-box-description">
          <p class="header-2">
            Iluminación
          </p>
          <p>
            Evita brillos y desactiva el flash de tu teléfono.
          </p>
        </div>
      </div>
      <div class="step-box">
        <div class="step-box-img">
          <span>
            <i class="evo ico-nitidez"></i>
          </span>
        </div>
        <div class="step-box-description">
          <p class="header-2">
            Nitidez y calidad
          </p>
          <p>
            Foto no borrosa, no menor a 2 megapixeles ni mayor de 15MB.
          </p>
        </div>
      </div>
      <div class="step-box">
        <div class="step-box-img">
          <span>
            <i class="evo ico-doc-original"></i>
          </span>
        </div>
        <div class="step-box-description">
          <p class="header-2">
            Documento original
          </p>
          <p>
            Foto de DNI o NIE orginal, no fotocopias o capturas de pantalla (screenshots).
          </p>
        </div>
      </div>
    </div>
    <div class="btn-group">
      <button (click)='close()' type="submit" class="btn-common btn-common_fix_bold">ENTENDIDO</button>
    </div>
  </div>
</div>

