import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MyProductsService } from 'app/services/my-products.service';
import { LoginService } from 'app/services/login.service';
import { AuthUserService } from 'app/services/auth-user.service';
import { HelperService } from 'app/services/helpers.service';
import { ModalService } from 'app/services/modal.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { PersonaService } from 'app/services/persona.service';
import { DeviceService } from 'app/services/device.service';
import { SecurityService } from 'app/services/security-registration.service';
import { HttpClient } from '@angular/common/http';
import { ERRORSFORM } from 'app/resources/errors-form';
import { environment } from 'environments/environment';
import { ConstantsService } from 'app/services/constants.service';
import { KeycloakService } from 'keycloak-angular';
import { InvestmentService } from 'app/services/investment.service';
import { SessionStorageService } from 'app/services/session-storage.service';
import { AuthTokenService } from 'app/services/token/auth.token.service';
import { SpecificAnalyticsService } from 'app/services/specific-analytics.service';
import { InjectScriptService } from 'app/services/inject-script.service';
import { ApiSsoService } from 'app/services/api/api-sso.service';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'config-investment-account',
  templateUrl: './config-investment-account.component.html',
  styleUrls: ['./config-investment-account.component.scss'],
  providers: [ModalService]
})
export class ConfigInvestmentAccountComponent implements OnInit {

  public idCliente;
  public loading;
  public showSecondStep = false;
  public cuentas: Array<any> = [];
  public cuentasProductos: Array<any>;
  public cuentasCliente: any;
  public numRisks: number[] = [1, 2, 3, 4, 5, 6, 7];
  public newAccount = true;
  public datosForm;
  public cuentaForm: FormGroup;
  public canal: string;
  public estado: string;
  public codProductoContratado: string;
  public canalOrigen: string;
  public solicitudesEnCurso;
  public idCaso: string;
  public showError = true;
  public nameError = 'loadingCustom';
  public oblig = false;
  public casoEnVuelo: boolean;
  public cuentaCargo: string;
  private acuerdoCuentaCargo: string;
  public nickname: string;
  public obj: Object;
  public DRF = false;
  public segundoTitular = false;
  public activarSegundoTitular = false;
  public producto: string;
  private numeroAcuerdo: string;
  private acuerdoCuentaCargoElectronica: string;
  private cuentaCargoElectronica: string;
  public datosSegTitular: Object;
  public mailSegTitular: string;
  public datosSegundoTitular;
  public formNickname: string;
  public hiddeNotification = false;
  public bannerRevisa = false;
  public formValido = false;
  public checkLegales: boolean = null;
  public invalidNickname = false;
  public invalidEmail: boolean = null;
  public ngValid: boolean = null;
  public isSecondSigner;
  public isTwoHolders: boolean;
  public defaultAcount: string;
  public currentPath: string;
  public step: number;
  public nicknameExist = false;
  public nicknameError = false;
  public nicknameErrorCaracteres = false;
  public idExterno: string;
  public contieneEspacio = false;
  modalLegal = 'legal-modal2';
  public showInputEmail = false;
  public emailSegundoTitular: string;
  public action = 'onboarding';
  public esFondoInteligente = false;
  public nickNoValido = false;
  public nickNameIncludes = false;
  public finalText1Titular;
  public isBmi = false;
  public isMobileOrTablet: boolean = this.deviceService.isMobileOrTablet();
  public origen: string;
  public oferta: string;
  public emailErrorClear = true;
  public recuperarCaso = false;
  public casoPendiente: any;
  public modificacionOK = false;
  public hideBannerNewConsoleClientDataOk = true;
  public hideBannerNewConsoleDocumentation = true;
  public esCliente = false;
  public obligacionesFiscales = false;
  public drfSegundoTitular = false;
  public obligacionesFiscalesSegundoTitular = false;
  public statusCode;
  public disableContinueButton = false;
  getMaxLengtNick: String;
  stateCurrent = States.enviada;
  currentProduct: any;
  fullName: string;
  address: string;
  ACCOUNTS = ACCOUNTS;
  public errors = ERRORSFORM;
  seccion: string;

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginSrv: LoginService,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    private authUserService: AuthUserService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService,
    public personaSrv: PersonaService,
    private securitySrv: SecurityService,
    private deviceService: DeviceService,
    private http: HttpClient,
    private keycloak: KeycloakService,
    public investmentSrv: InvestmentService,
    public sessionStorageSrv: SessionStorageService,
    private authSrv: AuthTokenService,
    private specificAnalyticsSrv: SpecificAnalyticsService,
    public inject: InjectScriptService,
    private apiSsoService: ApiSsoService) {

    this.datosForm = new FormGroup({
      cuentas: new FormControl(),
      nickname: new FormControl(),
      seleccionarCuenta: new FormControl(),
      email: new FormControl(),
      confirmEmail: new FormControl(),
      checkAccept: new FormControl(),
      checkAddAccount: new FormControl(),
      checkAcceptConditions: new FormControl(),
    });
    this.router.events.subscribe((url: any) => {
      if (url instanceof NavigationEnd) {
        this.currentPath = url.url;
        // Si el acceso es a través de la ruta '/login-inversora', se eliminan todos los datos de sesión.
        if (this.currentPath === '/login-inversora') {
          sessionStorage.clear();
        }
        this.setSteps();
      }
    });

  }

  ngOnInit() {
    this.showLoading();
    this.callOrquestador();

  }

  getStatusAndRedirect(token, refresh){
    this.showLoading();

    //TODO: Ver si realmente esto es necesario

    const regex1 = /uuid=[^&]*&?/g;
    const regex2 = /usuarioBE=[^&]*&?/g;
    //Temportalmente quitamos estos regex para que no se eliminen los parametros de la URL
    //const regex3 = /token=[^&]*&?/g;
    //const regex4 = /refesh=[^&]*&?/g;
    //Temportalmente quitamos estos regex para que no se eliminen los parametros de la URL
    //Eliminar las queryParams de la URL

    let href_url = window.location.href.replace(regex1, "").replace(regex2, "")/*.replace(regex3, "").replace(regex4, "")*/.replace("?&", "?");
    if(href_url.endsWith('?') || href_url.endsWith('&')){
      href_url = href_url.slice(0, -1)
    }
    let urlParams = href_url.split('?')[1];
    //Para el SSO contra el flujo corto de cuenta inversora necesitamos agregar el token y el refresh
    if(token && refresh && window.location.href.indexOf("uuid") !== -1 && window.location.href.indexOf("usuarioBE") !== -1){
      if(!urlParams.endsWith('&')){
        urlParams += '&';
      }
      urlParams += 'token=' + token + '&refresh=' + refresh;
    }
    //Para el SSO contra el flujo corto de cuenta inversora necesitamos agregar el token y el refresh
    let params = urlParams ? urlParams : '';
    this.personaSrv.getStatusAndRedirect(params)
    .subscribe(
      data => {
        let destino: string = data.urlRedirect;
        // Si el servicio responde que nos quedemos en este punto del flujo continuar con el proceso
        this.triggerInvestmentAnalysisData(data.urlRedirect)

        if(destino && destino.includes('configuracion-cuenta-inversora')){
          this.sessionStorageSrv.setItem('alreadyCheckedStatus', true);
        } else if(destino && destino.includes('buscador')){
          destino = window.location.hash.includes('#/login-inversora') ? destino+window.location.hash.split('#/login-inversora').pop():destino+window.location.hash;
        }else {
          //Si vamos a redirigir almacenamos en la session los datos recibidos desde el orquestador para que sean accesibles para los otros portales

          this.sessionStorageSrv.setItem("numCaso", data.caso);
          this.sessionStorageSrv.setItem("idCaso", data.caso);
          this.sessionStorageSrv.setItem("idSalesforce", data.idSalesforce);
          this.sessionStorageSrv.setItem("telefonoMovil", data.telefonoMovil);
          this.sessionStorageSrv.setItem("idExterno", data.idExterno);
          this.sessionStorageSrv.setItem("nombreCliente", data.nombreCliente);
          this.sessionStorageSrv.removeItem('alreadyCheckedStatus');
        }
        window.location.href = destino;
        this.triggerInvesmentNavData('Configuración nickname');
      },
      _error => {
        this.sessionStorageSrv.removeItem('alreadyCheckedStatus');
        const errorType = _error.error && _error.error.codigo && _error.error.codigo === "CINV_04" ? 'BATCH':'SATELITE-C';
        this.goToError(errorType);
        this.triggerInvesmentNavData(errorType);

      });
  }

  triggerInvestmentAnalysisData(url: string) {
    const urlObj = new URL(url)
    const urlParams = new URLSearchParams(urlObj.search)

    const dataLayer = {
      'event': 'cta',
      'process': 'Contratación eCommerce',
      'product': 'Cuenta Inversora',
      'category': 'inversion',
      'eventCategory': 'Contratación Cuenta Inversora',
      'eventAction': 'Formulario de Login',
      'content_type': 'proceso_contratación',
      'step': 'Login',
      'clientType': 'Cliente Tipo B'
    }
    //comprobamos y añadimos propiedades que devuelvan valor
      const eventLabel = url.includes('inversis.com') ? 'Inversis' : 'abrir_cinv'
      const idCasoUrl = urlParams.get("idCaso");
      const clientId = sessionStorage.getItem('idClienteEvo');

      const flujo = url.includes('funnel-corto') ? 'Masivo' : 'Largo';
      const origenWeb = urlParams.get('origen')

      if(eventLabel) {dataLayer['eventLabel'] = `Envío OK:  ${eventLabel}`}
      if (clientId) { dataLayer["clientID"] = clientId}
      if (idCasoUrl) { dataLayer['product_id'] = `Cuenta Inversora - ${idCasoUrl.toUpperCase()}` }
      if (flujo) { dataLayer["flojo"] = flujo }

      if (url.includes('funds')) { dataLayer['type'] = 'Fondos' }
      if (url.includes('etf')) { dataLayer['type'] = 'ETFs' }
      if (url.includes('share')) { dataLayer['type'] = 'Valores' }

      if (origenWeb) { dataLayer['origen'] = origenWeb }
      if (origenWeb) { dataLayer['seccion'] = origenWeb }

    this.analytics.pushNavData(dataLayer)

  }

  continuarProceso(){
    this.showLoading();

    this.analytics.isInvestmentNav = true; // Activa analítica para inversora

    this.getUserInfoFromKeycloak();

    const backFromStep3 = this.getOperacionInv() === 'operacion-inversora-cuenta-cargo';

    if (backFromStep3) {
      this.showSecondStep = true;
    }

    this.errService.modDataError = true;
    this.getQueryParams();
    this.setAnalyticData();

    this.getMaxLengtNick = this.codProductoContratado === ACCOUNTS.CINVCode ? '9' : '12';
    if (this.statusCode) {
      this.checkStatusCode();
    } else {
      this.checkCondicionesLegales();
      // Matiene los tooltip del nickname actualizados
      this.updateTooltip();
    }
  }

  checkStatusCode() {
    this.showLoading();
    switch (this.statusCode) {
      case ConstantsService.RespuestaConsola.TodoOk:
        this.cargarCuentas();
        this.updateInversoraTodoOk();
        this.updateTooltip();
        return;
      case ConstantsService.RespuestaConsola.ModificacionDatosCliente:
        this.hideBannerNewConsoleClientDataOk = false;
        this.cargarCuentas();
        this.updateInversoraTodoOk();
        this.updateTooltip();
        return;
      case ConstantsService.RespuestaConsola.ModificacionDocumentacion:
        this.hideBannerNewConsoleDocumentation = false;
        this.cargarCuentas();
        this.updateInversoraTodoOk();
        this.updateTooltip();
        return;
      case ConstantsService.RespuestaConsola.ObligacionesFiscalesFueraDeEspania:
        this.DRF = true;
        this.drfSegundoTitular = true;
        break;
      case ConstantsService.RespuestaConsola.RelacionEEUU:
        this.obligacionesFiscales = true;
        this.obligacionesFiscalesSegundoTitular = true;
        break;
      case ConstantsService.RespuestaConsola.ResidenciaFueraDeEspania:
        this.DRF = true;
        this.drfSegundoTitular = true;
        break;
      case ConstantsService.RespuestaConsola.SinDRF:
        this.DRF = true;
        this.drfSegundoTitular = true;
        break;
    }

    this.updateInversoraAndGoToErrorBE();
  }

  initCuentaInversora() {
    if (!this.showSecondStep && this.getOperacionInv() !== 'operacion-inversora-nickname') {
      this.sessionStorageSrv.setItem('operacion-inversora', 'operacion-inversora');
    } else {
      // this.hideErrors();
    }

    if (!this.showSecondStep && this.getOperacionInv() === 'operacion-inversora') {
      this.canal = this.deviceService.getDevice();
      if (this.isSecondSigner) {
        this.updateInversora('modificacion-datos-tit2', true);
      } else {
        this.consultarCasosClienteInversora();
      }
    }
  }

  initFondoInteligente() {

    if (this.esFondoInteligente && this.existeFondoInteligente()) {
      this.errService.modDataError = true;
      this.errService.navToErrorModData('SATELITE-FONDO-INT', 'ABRIR CUENTA INVERSORA', 'cuenta-inversora-redirect');
      return;
    }

    if (!this.showSecondStep && this.getOperacionInv() !== 'fondo-inteligente-nickname') {
      this.sessionStorageSrv.setItem('operacion-inversora', 'fondo-inteligente');
      this.showLoading();
    } else {
      // this.hideErrors();
    }

    if (!this.showSecondStep && this.getOperacionInv() === 'fondo-inteligente') {
      // Coge los datos de la sesion
      this.canal = this.deviceService.getDevice();
      this.consultarCasosClienteInversora();
    }
  }

  /**
   * Duplicamos la llamada al queryParams para el flujo corto de CINV con la BMI
   * para evitar conflictos y errores en los flujos existentes
   */
  callOrquestador(){
    this.activatedRoute.queryParams.subscribe(async (params) => {
      this.codProductoContratado = params['codProductoContratado'];
      this.isTwoHolders = params['isTwoHolders'] === 'true';
      this.isSecondSigner = params['isSecondSigner'] === 'true';
      const isFondoInteligente = this.codProductoContratado === ConstantsService.AccountTypes.FondoInteligente.code;
      this.sessionStorageSrv.setItem('productoContratando', this.codProductoContratado);

      // Check seccion
      if (params['seccion']) {
        this.seccion = params['seccion'];
        this.sessionStorageSrv.setItem('seccion', this.seccion);
      }
      // Check origen
      if (params['origen']) {
        this.origen = params['origen'];
        this.sessionStorageSrv.setItem('origen', this.origen);
        if (this.origen.toLowerCase() === 'bmi') {
          this.sessionStorageSrv.setItem('isBMI', 'true');
        }
      }

      let token = null;
      let refresh = null;
      const ssoUrlv1 = params['token'] && params['refresh'];
      const ssoUrlv2 = params['uuid'] && params['usuarioBE'];
      // SSO
      if(ssoUrlv1 || ssoUrlv2){

        token = params['token'];
        refresh = params['refresh'];

        if(ssoUrlv2){
          let cred;
          try {
            cred = await this.apiSsoService.executeSso(params);
          }
          catch(e){
            console.error(e);
            this.errService.modDataError = true;
            this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
            return;
          }
          token = cred.token;
          refresh = cred.refresh;
        }

        this.authSrv.setToken(token);
        this.authSrv.setRefreshToken(refresh);
        if (params['idInterno']) {
          this.sessionStorageSrv.setItem('idInternoPe', params['idInterno']);
        }
      } else {
        this.getUserInfoFromKeycloak(false); // Sin guardar el refresh token para evitar SSO consola
      }

      if(!this.authSrv.isAuthenticated()){
        this.errService.modDataError = true;
        this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'login');
        return;
      }

      // Comprueba si estamos en FINT o flujo de dos titulares para no llamar a funnel-corto
      if (this.isTwoHolders || isFondoInteligente || this.isSecondSigner){
        this.continuarProceso();
        return;
      }

      // Si ya hemos pasado por el orquestador continuar proceso
      if (this.sessionStorageSrv.isItemTrue('alreadyCheckedStatus')){
        this.continuarProceso();
        return;
      }

      this.getStatusAndRedirect(token, refresh);

    });
  }

  getQueryParams() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.codProductoContratado = params['codProductoContratado'];
      this.isFondoInteligente(this.codProductoContratado);
      this.sessionStorageSrv.setItem('productoContratando', this.codProductoContratado);
      this.isSecondSigner = params['isSecondSigner'] === 'true';
      this.isTwoHolders = params['isTwoHolders'] === 'true';
      this.sessionStorageSrv.setItem('isTwoHolders', this.isTwoHolders);
      if(!(!!this.sessionStorageSrv.getItem("numCaso")))
        this.sessionStorageSrv.setItem("numCaso", params["idCaso"] ? params["idCaso"] : params["numCaso"]);
      // Check seccion
      if (params['seccion']) {
        this.seccion = params['seccion'];
        this.sessionStorageSrv.setItem('seccion', this.seccion);
      }
      // Check origen
      if (params['origen']) {
        this.origen = params['origen'];
        this.sessionStorageSrv.setItem('origen', this.origen);
        if (this.origen.toLowerCase() === 'bmi') {
          this.sessionStorageSrv.setItem('isBMI', 'true');
        }
      }

      // Check oferta
      if (params['oferta']) {
        this.oferta = params['oferta'];
        this.sessionStorageSrv.setItem('oferta', this.oferta);
      }

      this.isBmi = this.sessionStorageSrv.isItemTrue('isBMI');

      // SSO - Sin datos de cliente, hay que recuperarlos para continuar
      if (!this.loginSrv.userData) {
        const casoSeleccionado = this.sessionStorageSrv.getItem('casoSeleccionado');
        if (!params['idCaso'] && casoSeleccionado == null || casoSeleccionado == 'undefined') {
          if (this.loginSrv.userData) {
            this.getPersonaByIdAndCodProducto();
          } else {
            this.showLoading();
            this.authUserService.getClientData().subscribe(data => {
              this.loginSrv.userData = data;
              this.getPersonaByIdAndCodProducto();
            }, _error => {
              this.checkErrorStatusAndGoToError(_error, 'SATELITE-C');
            });
            return;
          }

        }
      }

      // BACK FROM CONSOLA 2.0
      if (params["statusConsole"]) {
        this.statusCode = params["statusConsole"];
        this.idCaso = params["numCaso"] ? params["numCaso"] : params["idCaso"];
        this.sessionStorageSrv.setItem("casoSeleccionado", this.idCaso);
      }

      // Si login segundo titular
      if (this.isSecondSigner && params['clientId'] && params['idCaso'] && params['codProductoContratado']) {
        this.loading = true;
        this.idCliente = params['clientId'];

        if (params['statusConsole']) { // Si vuelve desde consola ya se verificó del DNI antes
          this.sessionStorageSrv.setItem('abandono', 'true');
          this.sessionStorageSrv.setItem('isSecondSigner', this.isSecondSigner);
          this.idCaso = params['idCaso'];
          this.sessionStorageSrv.setItem('casoSeleccionado', this.idCaso);
          this.gestionAbandono(params['estado'], params['clientId'], params['idCaso']);
          return;
        }

        const consulta = {
          EE_I_Consulta: {
            codigoEntidad: '0239',
            idExterno: this.idExterno,
            idCliente: this.idCliente
          }
        };
        this.personaSrv.checkDuplicateDNI(consulta).subscribe(
          dataDni => {
            if (dataDni.codigoRetorno === '1') {
              this.esCliente = true;
            } else {
              // this.errService.modDataError = true;
              // this.errService.navToErrorModData('SATELITE-C', 'ENTENDIDO', 'bancaelectronica');
            }
            this.loading = false;

            // FLUJO SEGUNDO TITULAR
            this.sessionStorageSrv.setItem('abandono', 'true');
            this.sessionStorageSrv.setItem('isSecondSigner', this.isSecondSigner);
            this.idCaso = params['idCaso'];
            this.sessionStorageSrv.setItem('casoSeleccionado', this.idCaso);
            this.gestionAbandono(params['estado'], params['clientId'], params['idCaso']);

          },
          error => {
            this.errService.modDataError = true;
            this.checkErrorStatusAndGoToError(error, 'SATELITE-C');
          }
        );
        return;
      }

      if (params['codProductoContratado'] && !(this.getOperacionInv() === 'fondo-inteligente-nickname')
        && !(this.getOperacionInv() === 'operacion-inversora-nickname')
        && !(this.sessionStorageSrv.getItem('updateData'))) {
        if (params['idCaso']) {
          this.idCaso = params['idCaso'];
        }
        if (this.codProductoContratado === ConstantsService.AccountTypes.CuentaInversora.code) {
          this.sessionStorageSrv.setItem('operacion-inversora', 'operacion-inversora');
        } else {
          this.sessionStorageSrv.setItem('operacion-inversora', 'fondo-inteligente');
          this.codProductoContratado = ConstantsService.AccountTypes.FondoInteligente.code;
        }
      } else if (params['idCaso'] && !(this.sessionStorageSrv.isItemTrue('isBMI'))) {
        this.idCaso = params['idCaso'];
        this.sessionStorageSrv.setItem('casoSeleccionado', this.idCaso);
        this.codProductoContratado = ConstantsService.AccountTypes.CuentaInversora.code;
        this.sessionStorageSrv.setItem('productoContratando', this.codProductoContratado);
        this.sessionStorageSrv.setItem('operacion-inversora', 'operacion-inversora');
      } else if (!this.sessionStorageSrv.getItem('productoContratando')) {
        this.codProductoContratado = ConstantsService.AccountTypes.FondoInteligente.code;
        this.sessionStorageSrv.setItem('productoContratando', this.codProductoContratado);
        this.sessionStorageSrv.setItem('operacion-inversora', 'fondo-inteligente');
      }


      // FLUJO PRIMER TITULAR
      if (!this.isSecondSigner && params['clientId'] && params['idCaso']) {
        // this.idExterno = params['clientId'];
        this.isSecondSigner = false;
        this.sessionStorageSrv.setItem('isSecondSigner', this.isSecondSigner);
        this.sessionStorageSrv.setItem('abandono', 'true');
        this.idCaso = params['idCaso'];
        this.sessionStorageSrv.setItem('casoSeleccionado', this.idCaso);
        // Booleano para mostrar en la pantalla final el titulo correcto.
        this.finalText1Titular = true;
        this.sessionStorageSrv.setItem('mostrarTexto1Titular', this.finalText1Titular);
        this.gestionAbandono('abandono', params['clientId'], params['idCaso']);
        return;
      }

      // ABANDONO
      if (params['idCaso']) {
        this.codProductoContratado = params['codProductoContratado'];
        if (params['codProductoContratado'] === ConstantsService.AccountTypes.CuentaInversora.code) {
          this.sessionStorageSrv.setItem('operacion-inversora', 'operacion-inversora');
        } else if (params['codProductoContratado'] === ConstantsService.AccountTypes.FondoInteligente.code &&
          !(this.getOperacionInv() === 'fondo-inteligente-nickname')) {
          this.sessionStorageSrv.setItem('operacion-inversora', 'fondo-inteligente');
        }
        // Modificar si viniese el parámerto desde la url
        this.sessionStorageSrv.setItem('isSecondSigner', 'false');

        this.idExterno = params['dni'];

        const casoSeleccionado = this.sessionStorageSrv.getItem('casoSeleccionado');
        if (casoSeleccionado) {
          this.gestionAbandonoSSO(casoSeleccionado);
        } else {
          this.sessionStorageSrv.setItem('casoSeleccionado', params['idCaso']);
          this.gestionAbandonoSSO(params['idCaso']);
        }
        return;
      }

      this.sessionStorageSrv.setItem('isSecondSigner', 'false');
      this.codProductoContratado = params['codProductoContratado'];
      // No viene por SSO consultar datos, casos y continar caso o abrir uno nuevo
      if (!this.loginSrv.userData) {
        this.getUserDataAndContinue();
      } else {
        this.cargarCuentas();
        this.hideErrors();
      }
    });
  }

  checkSessionStorage() {
    if (this.loginSrv.userData) {
      this.idCliente = this.loginSrv.userData.persona.id_cliente_EVO;
    } else {
      this.idCliente = this.loginSrv.userInfo && this.loginSrv.userInfo.id_salesforce;
    }
  }


  /**
   * Método que recoge de sesión el nombre y apellidos del segundo titular y lo guarda en una variable
   * que se llama nombreSegTitularCompleto
   */
  activarST(actualizarST?) {

    if (!actualizarST) {
      this.activarSegundoTitular = !this.activarSegundoTitular;
    }

    if (this.activarSegundoTitular) {
      this.sessionStorageSrv.setItem('activarSegundoTitular', 'true');
      this.triggerInvesmentNavData('Invitar cliente a EVO');
    } else {
      sessionStorage.removeItem('activarSegundoTitular');
    }

    const datos = this.sessionStorageSrv.getItemParsed('userData').listaContratados;

    for (let i = 0; i < datos.length; i++) {
      if (datos[i].ibanCC === this.datosForm.get('cuentas').value ||
        (datos[i].nombreProducto + ' - ' + datos[i].ibanCC === this.datosForm.get('cuentas').value)) {
        if (datos[i].nombreSegTitular) {
          this.segundoTitular = true;
          this.datosSegTitular = {
            'nombreSegTitular': datos[i].nombreSegTitular,
            'apellido1SegTitular': datos[i].apellido1SegTitular,
            'apellido2SegTitular': datos[i].apellido2SegTitular,
            'idExternoSegTitular': datos[i].idExternoSegTitular,
            'idInternoSegTitular': datos[i].idInternoSegTitular,
            'mailSegTitular': datos[i].mailSegTitular,
            'movilSegTitular': datos[i].movilSegTitular
          }
          this.mailSegTitular = datos[i].mailSegTitular;
          this.sessionStorageSrv.setItem('emailSegundoTitular', this.datosSegTitular['mailSegTitular']);
        } else {
          this.segundoTitular = false;
        }
      }
    }

    // Si no existe el segundo titular, solo se muestran las casillas de introducir el email si el usuario
    // ha seleccionado añadir segundo titular
    if (this.segundoTitular) {
      this.setEmail(1);
    } else {
      this.setEmail(2);
    }

    this.datosForm.get('checkAcceptConditions').valueChanges.subscribe(data => {
      if (this.datosForm.get('checkAcceptConditions').value === true) {
        this.checkLegales = false;
      }

      if (this.emailErrorClear) {
        this.hideNotification();
      }
    });
  }

  inFocusNickname() {
    //  Se ocultan los tooltip de error.
    this.nicknameExist = false;
    this.nickNoValido = false;
    this.quitarCampoError();
  }

  inFocusEmail() {
    this.emailErrorClear = true;
    this.resetEmailForm();
    if (!this.datosForm.get('checkAcceptConditions').value !== true) {
      this.hideNotification();
    }
  }

  outFocus(input) {
    this.resetEmailForm();
  }

  resetEmailForm() {
    this.helpers.resetFieldsState(this.datosForm, ['email']);
    this.helpers.resetFieldsState(this.datosForm, ['confirmEmail']);
  }

  submit() {
    if (this.activarSegundoTitular) {
      if (this.datosForm.get('seleccionarCuenta').value === '1') {// Está seleccionado el segundo titular de la cuenta
        // El segundo titular ya existe y solo valida que se ha seleccionado la cuenta y ha aceptado las condiciones
        this.validarFormularioCuenta2();
      } else if (this.datosForm.get('seleccionarCuenta').value === '2' ||
        this.datosForm.get('seleccionarCuenta').value === null) {// El usuario debe introducir y confirmar el email
        // Se validan que los email sean iguales, la cuenta y haya aceptado las condiciones
        this.validarFormularioCuenta();
      } else {
        this.validarFormulario();
      }
    } else {
      // comprobamos validez del nickName
      this.validarFormulario();
    }
    if (this.cuentaForm.valid) {
      this.bannerRevisa = false;
      this.updateInversora('categorizacion-tit1');
    } else {
      this.bannerRevisa = true;
      this.hiddeNotification = false;
    }
    this.setIbanAcountSelected();
  }

  /**
   * Se mete en sesión el IBAN de la cuenta que ha seleccionado el cliente
   */
  setIbanAcountSelected() {
    const cuenta = this.datosForm.get('cuentas');
    const cuentaSeleccionada = this.cuentas.find(elem => elem.name === cuenta.value)
      || this.cuentas.find(elem => elem.value === cuenta.value);
    if (cuentaSeleccionada) {
      this.sessionStorageSrv.setItem('ibanCuentaSeleccionada', cuentaSeleccionada.value);
    }
  }

  validarFormulario() {
    this.cuentaForm = this.fb.group({
      cuentas: [this.datosForm.get('cuentas').value, [Validators.required]]
    });
  }

  validarFormularioCuenta() {
    this.errService.errorsArray = [];

    const emailRegex = /.+@.+\..+/i;
    if (this.datosForm.get('email').value === '' &&
      this.datosForm.get('confirmEmail').value === '') {
      document.getElementById('email').classList.remove('ng-untouched');
      document.getElementById('confirmEmail').classList.remove('ng-untouched');
    }
    this.cuentaForm = this.fb.group({
      cuentas: [this.datosForm.get('cuentas').value, [Validators.required]],
      email: [this.datosForm.get('email').value, [Validators.required, Validators.pattern(emailRegex)]],
      confirmEmail: [this.datosForm.get('confirmEmail').value, [Validators.required, Validators.pattern(emailRegex)]],
      checkAcceptConditions: [this.datosForm.get('checkAcceptConditions').value, [Validators.requiredTrue]]
    }, { validator: this.checkEmails });

    this.cuentaForm.controls['email'].updateValueAndValidity();
    this.cuentaForm.controls['confirmEmail'].updateValueAndValidity();

    this.helpers.updateFormStatus(this.cuentaForm);
    this.checkEmails(this.cuentaForm);

    if (this.datosForm.get('checkAcceptConditions').value === true) {
      this.checkLegales = false;
    } else {
      this.checkLegales = true;
    }
    if (this.datosForm.get('email').valid || this.datosForm.get('confirmEmail').valid) {

      if (this.datosForm.get('email').value !== this.datosForm.get('confirmEmail').value) {
        this.loading = false;

        //  this.analytics.triggerSegundoTitularFormData('Formulario datos segundo titular', 'Envio KO: Emails no coinciden');
        this.datosForm.get('email').setErrors({ notEquals: true });
        this.datosForm.get('confirmEmail').setErrors({ notEquals: true });
        this.helpers.updateFieldStatus(this.datosForm, 'email');
        this.helpers.updateFieldStatus(this.datosForm, 'confirmEmail');
      }
    } else {
      this.loading = false;
      this.emailErrorClear = false;
      //  this.analytics.triggerSegundoTitularFormData('Formulario datos segundo titular', 'Envio KO: Email inválido');

      this.checkValidField('email');
      this.checkValidField('confirmEmail');
    }

    this.ngValid = this.cuentaForm.valid ? true : false;

  }

  isEqualMail() {
    //Compruebo si el mail de invitación es igual al mail del usuario
    const userData = this.sessionStorageSrv.getItemParsed('userData');
    let isEqualMail = false;
    if (userData.persona.email === this.datosForm.get('email').value) {
      this.datosForm.get('email').setErrors({ equalTit1: true });
      this.datosForm.get('confirmEmail').setErrors({ equalTit1: true });
      this.helpers.updateFieldStatus(this.datosForm, 'email');
      this.helpers.updateFieldStatus(this.datosForm, 'confirmEmail');
      isEqualMail = true;
    }
    return isEqualMail;
  }
  checkValidField(fieldName) {
    const field = this.datosForm.get(fieldName);
    if (!field.valid) {
      if (field.value !== '') {
        field.setErrors({ invalid: true });
      }
      this.helpers.updateFieldStatus(this.datosForm, fieldName);
    }
  }

  validarFormularioCuenta2() {
    this.cuentaForm = this.fb.group({
      cuentas: [this.datosForm.get('cuentas').value, [Validators.required]],
      checkAcceptConditions: [this.datosForm.get('checkAcceptConditions').value, [Validators.requiredTrue]]
    });
    this.checkLegales = this.datosForm.get('checkAcceptConditions').value === true ? false : true;
  }

  /**
   * Validación que comprueba que los email que ha introducido el usuario son iguales,
   * Devuelve null si son iguales y true si son diferentes
   */
  checkEmails(group: FormGroup) {
    const email = group.controls.email.value;
    const confirmEmail = group.controls.confirmEmail.value;
    this.invalidEmail = email !== confirmEmail || (email === null && confirmEmail === null);
    return email === confirmEmail ? null : { notSame: true }
  }

  continuarSinRedireccion() {

    this.checkSessionStorage();

    if (!this.showSecondStep) { // No volvemos de visor-cuenta-inversora (Botón atrás)

      if (this.codProductoContratado === ConstantsService.AccountTypes.CuentaInversora.code) {// Cuenta inversora
        this.initCuentaInversora();
      } else if (this.codProductoContratado === ConstantsService.AccountTypes.FondoInteligente.code) {// Fondo inteligente
        this.initFondoInteligente();
      }
      else {
        this.hideErrors();
      }
    } else {
      this.hideErrors();
    }

    this.datosForm.get('checkAcceptConditions').valueChanges.subscribe(data => {
      if (this.datosForm.get('checkAcceptConditions').value === true) {
        this.checkLegales = false;
        if (this.emailErrorClear) {
          this.hideNotification();
        }
      }
    });

    this.cargarCuentas();
    this.checkCondicionesLegales();
  }

  /**
   * Se valida antes de continuar que el cliente ha introducido un nickname valido
   * el cuál tiene que cumplir que sea mayor de 6 y menor de 12 carácteres y que previamente
   * no esté en uso por otro usuario
   */
  toContinue() {

    const nick = this.datosForm.get('nickname').value;
    // Comprobar que la palabra no está en el fichero json
    this.http.get('assets/palabrasMalsonantes.json').subscribe(data => {
      this.disableContinueButton = true;
      for (let i = 0; i < data['palabras'].length && !this.nickNoValido; i++) {
        const palabra = data['palabras'][i];
        if (nick && nick.toUpperCase() === palabra.toUpperCase()) {
          this.nickNoValido = true;
          document.getElementById('nickname').classList.add('custom-bg-noerror');
          this.mostrarCampoError();
        } else {
          this.nickNoValido = false;
          this.quitarCampoError();
        }
      }

      if (nick !== null && nick !== '' && !this.nickNoValido) {
        if (nick.indexOf(' ') !== -1) {
          this.contieneEspacio = true;
        } else {
          this.contieneEspacio = false;
        }
        this.nicknameExist = false;
        this.nicknameError = false;
        this.nicknameErrorCaracteres = false;
        this.nickNoValido = false;
        if (nick.length >= 6 && nick.length <= this.getMaxLengtNick && !this.contieneEspacio) {
          this.getNickname();
        } else {
          this.disableContinueButton = false;
          this.nicknameError = true;
          this.invalidNickname = true;
          this.formValido = false;
          this.bannerRevisa = true;
          this.hiddeNotification = false;
        }
      } else {
        this.disableContinueButton = false;
        this.invalidNickname = true;
        this.formValido = false;
        this.bannerRevisa = true;
        this.hiddeNotification = false;
      }
    });

  }

  private getNickname() {
    let nickname = this.datosForm.get('nickname').value;
    this.cuentaForm = this.fb.group({
      nickname: [nickname, [Validators.required, Validators.pattern('([a-z]|[A-Z]|[0-9]){6,12}')]]
    });

    if (this.cuentaForm.valid) {
      this.loading = true;
      this.personaSrv.getNickName(nickname).subscribe(data => {
        //Si es cinv se tiene que validar si el nick existe con cin delante y sin el
        if (this.codProductoContratado === ACCOUNTS.CINVCode) {
          //Para solucionar el problema de validacion de nickname de salesforce , donde se añade "cin" delante del nickname
          let cinNickname = 'cin' + nickname;
          this.personaSrv.getNickName(cinNickname).subscribe(
            data => {
              this.nickOK(data)
            },
            error => {
              this.checkErrorStatusAndGoToError(error, 'SATELITE-C', true);
            });
        } else {
          this.nickOK(data);
        }
      }, error => {
        this.checkErrorStatusAndGoToError(error, 'SATELITE-C', true);
      });
    }
  }
  private nickOK(data) {
    const res = data.EE_O_Consulta;
    if (res.codigoRetorno === '1' && res.Respuesta.detalleNickname === '1') {// Retorno OK.
      this.nicknameExist = false;
      this.loading = false;
      this.showLoading();
      this.disableContinueButton = false;
      this.invalidNickname = false;
      this.formValido = true;
      this.bannerRevisa = false
      this.updateInversora('cuenta-cargo');

    } else if (res.codigoRetorno === '1' && res.Respuesta.detalleNickname === '0') {
      // Nickname no disponible
      this.loading = false;
      this.disableContinueButton = false;
      this.nicknameExist = true;
      document.getElementById('nickname').classList.add('custom-bg-noerror');
    } else if (res.codigoRetorno === '0') { // Retorno KO.
      this.loading = false;
      this.nicknameExist = false;
      // Mostrar mensaje de error
      this.errorInversora('SATELITE-C', 'nickname', this.idCaso, 'Error servicio consulta Nickname');
    } else {
      this.loading = false;
      // Otros Errores:Códigos HTTP 500, 401…
      this.errorInversora('SATELITE-C', 'nickname', this.idCaso, 'Error servicio consulta Nickname');
    }
  }

  botonVolver() {
    if (this.showSecondStep) {
      this.updateInversora('nickname');
    } else {
      this.updateInversora('modificacion-datos-tit1', true);
    }
  }

  consultarCasosClienteInversora() {
    this.showLoading();
    this.personaSrv.consultarCasosClienteInversora().subscribe(data => {
      // ver casos.
      const listaCasos = data.listaCasos;
      if (listaCasos) {
        for (const caso of listaCasos) {
          if (this.codProductoContratado === ConstantsService.AccountTypes.CuentaInversora.code && caso.tipoCaso === ConstantsService.AccountTypes.CuentaInversora.code ||
            this.codProductoContratado === ConstantsService.AccountTypes.FondoInteligente.code && caso.tipoCaso === ConstantsService.AccountTypes.FondoInteligente.code) {
            this.recuperarCaso = true;
            this.casoPendiente = caso;
            if(this.sessionStorageSrv.getItem('numCaso') === "undefined" || this.sessionStorageSrv.getItem('numCaso') !== caso.idCaso)
            {
              this.sessionStorageSrv.setItem('numCaso', caso.idCaso);
              this.setAnalyticData();
            }
          }
        }
      }
      //  comprobar si tiene caso en vuelo.
      if (this.recuperarCaso) {
        this.continuarCaso(this.casoPendiente);
      } else {
        //  Si no tiene caso en vuelo abrimos un nuevo caso de inversora.
        this.abrirCasoInversora();
      }
    }, _error => {
      this.checkErrorStatusAndGoToError(_error, 'SATELITE-C');
    });
  }



  abrirCasoInversora() {

    /********************Meter datos correctos en el objeto **********************/
    const object: Object = {
      'canalOrigen': this.canalOrigen,
      'canal': this.canal,
      'estado': 'registro',
      'codProductoContratado': this.codProductoContratado
    }
    this.showLoading();
    this.personaSrv.crearCasoCuenta(object).subscribe(data => {
      switch (data.codigo) {
        case 'OK':
          // this.hideErrors(); (Ya lo hace updateInversora)
          this.idCaso = data.idCaso;
          this.sessionStorageSrv.setItem('casoSeleccionado', this.idCaso);
          this.updateInversora('modificacion-datos-tit1', true);
          break;
        case 'KO':
        default:
          this.showError = true;
          this.nameError = 'astronauta';
          this.triggerInvesmentNavData('Error - 404');
          break;
      }
    }, error => {
      this.checkErrorStatusAndGoToError(error, 'SATELITE-C');
    });

  }

  /**
   * Actualizar Caso y redirige a consola si es necesario
   * @param estado
   */
  updateInversora(estado, goToConsola = false) {
    const idCaso = this.sessionStorageSrv.getItem('casoSeleccionado');
    this.showLoading();
    this.obj = {
      'estado': estado,
      'idCaso': idCaso
    };

    if (this.isSecondSigner) {
      this.obj = {
        ...this.obj,
        'drfSegungoTitular': this.drfSegundoTitular,
        'obligacionesFiscalesSegundoTitular': this.obligacionesFiscalesSegundoTitular
      };

      switch (estado) {
        case 'categorizacion-tit2':
          for (let i = 0; i < this.cuentas.length; i++) {
            if (this.datosForm.get('cuentas').value === this.cuentas[i].value) {
              this.cuentaCargo = this.cuentas[i].value;
              this.acuerdoCuentaCargo = this.cuentas[i].acuerdo;
            }
          }
          break;
        case 'modificacion-datos-tit2':
          this.obj = {
            'estado': estado,
            'idCaso': idCaso,
          };
          break;
        default:
          break;
      }
    } else {
      this.obj = {
        ...this.obj,
        'drf': this.DRF,
        'obligacionesFiscales': this.obligacionesFiscales
      };
      if (estado === 'nickname') {
        this.obj = {
          ...this.obj,
          'nickname': ''
        };
      } else if (estado === 'cuenta-cargo') {
        this.obj = {
          ...this.obj,
          'nickname': this.datosForm.get('nickname').value
        };
      } else if (estado === 'categorizacion-tit1') {
        for (let i = 0; i < this.cuentas.length; i++) {
          if (this.datosForm.get('cuentas').value === this.cuentas[i].value ||
            this.datosForm.get('cuentas').value === this.cuentas[i].name) {
            this.cuentaCargo = this.cuentas[i].value;
            this.acuerdoCuentaCargo = this.cuentas[i].acuerdo;
          }
        }
        if (this.activarSegundoTitular) {
          if (this.datosForm.get('seleccionarCuenta').value === '1') {
            // Si se activa el segundo titular se envían los siguientes objetos
            // dependiendo de si selecciona el segundo titular de la cuenta existente
            // o de si el usuario indica un mail nuevo
            this.obj = {
              ...this.obj,
              'cuentaCargo': this.cuentaCargo,
              'acuerdoCuentaCargo': this.acuerdoCuentaCargo,
              'cuentaCargoElectronica': this.cuentaCargoElectronica,
              'acuerdoCuentaCargoElectronica': this.acuerdoCuentaCargoElectronica,
              'nombreSegundoTitular': this.datosSegTitular['nombreSegTitular'],
              'apellido1SegundoTitular': this.datosSegTitular['apellido1SegTitular'],
              'apellido2SegundoTitular': this.datosSegTitular['apellido2SegTitular'],
              'dniSegundoTitular': this.datosSegTitular['idExternoSegTitular'],
              'idInternoSegundoTitular': this.datosSegTitular['idInternoSegTitular']
            };
          } else if (this.datosForm.get('seleccionarCuenta').value === '2' ||
            this.datosForm.get('seleccionarCuenta').value === null) {
            this.emailSegundoTitular = this.datosForm.get('email').value;
            this.sessionStorageSrv.setItem('emailSegundoTitular', this.emailSegundoTitular);
            this.obj = {
              ...this.obj,
              'cuentaCargo': this.cuentaCargo,
              'acuerdoCuentaCargo': this.acuerdoCuentaCargo,
              'cuentaCargoElectronica': this.cuentaCargoElectronica,
              'acuerdoCuentaCargoElectronica': this.acuerdoCuentaCargoElectronica,
              'emailSegundoTitular': this.datosForm.get('email').value
            };
          }
        } else {
          this.obj = {
            ...this.obj,
            'cuentaCargo': this.cuentaCargo,
            'acuerdoCuentaCargo': this.acuerdoCuentaCargo,
            'cuentaCargoElectronica': this.cuentaCargoElectronica,
            'acuerdoCuentaCargoElectronica': this.acuerdoCuentaCargoElectronica,
          };
        }
      } else if (estado === 'modificacion-datos-tit1') {
        this.obj = {
          ...this.obj,
          'drf': this.DRF
        };
      }
    }
    this.putInversora(estado, goToConsola);

  }

  /**
   * Llamada al servicio cuentaInversoraPut de SF
   * @param estado Estado de la petición/punto abandono
   */
  putInversora(estado, goToConsola = false) {
    this.personaSrv.modificarCuenta(this.obj).subscribe(data => {
      switch (data.codigo) {
        case 'OK':
          if (goToConsola) {
            this.goToConsola();
          } else if (this.isSecondSigner) {
            this.checkStatusAndRedirectSecondHolder(estado, '')
          } else {
            this.checkStatusAndRedirect(estado, '');
          }
          break;
        case 'KO':
          this.goToError('SATELITE-C');
          break;
        case '01':
        case '02':
        case '03':
        case '04':
        default:
          // this.goToError('SATELITE-C');
          break;
      }
    }, _error => {
      this.checkErrorStatusAndGoToError(_error, 'SATELITE-C');
    });
  }

  /**
 * Llamada al servicio cuentaInversoraPut de SF
 * @param estado Estado de la petición/punto abandono
 */
  putInversoraBackFromConsola() {
    this.showLoading();
    this.personaSrv.modificarCuenta(this.obj).subscribe(data => {

      this.hideErrors();
      this.errService.modDataError = true;

      switch (this.statusCode) {
        case ConstantsService.RespuestaConsola.ResidenciaFueraDeEspania:
          this.errService.navToErrorModData('RESIDENCIA-FUERA-ESPANIA', 'ENTENDIDO', 'bancaelectronica');
          break;
        case ConstantsService.RespuestaConsola.SinDRF:
          this.showError = true;
          this.nameError = 'faltaInformacion';
          break;
        case ConstantsService.RespuestaConsola.ObligacionesFiscalesFueraDeEspania:
          this.errService.navToErrorModData('OBLIGACIONES-FUERA-ESPANIA', 'ENTENDIDO', 'bancaelectronica');
          break;
        case ConstantsService.RespuestaConsola.RelacionEEUU:
          this.errService.navToErrorModData('RESIDENCIA-EEUU', 'ENTENDIDO', 'bancaelectronica');
          break;
        default:
          this.goToError('SATELITE-C');
      }
    }, error => {
      this.checkErrorStatusAndGoToError(error, 'SATELITE-C');
    });
  }

  putInversoraAndGoToConsola(estado) {
    this.showLoading();
    this.personaSrv.modificarCuenta(this.obj).subscribe(data => {
      switch (data.codigo) {
        case 'OK':
          this.goToConsola();
          break;
        case 'KO':
        default:
          this.goToError('SATELITE-C');
          break;
      }
    }, _error => {
      this.hideErrors();
      this.checkErrorStatusAndGoToError(_error, 'SATELITE-C');
    });

  }

  /**
   * Comprueba el estado actual de la petición y redirige o muestra el mensaje correspondiente
   * @param estado
   */
  checkStatusAndRedirect(estado, redirectUrl) {
    switch (estado) {
      case 'nickname':
        this.triggerInvesmentNavData('Configuración nickname');
        this.showSecondStep = false;
        this.hideErrors();
        this.helpers.navigateTo('configuracion-cuenta-inversora');
        break;
      case 'warning-documentacion-tit1':
      case 'modificacion-datos-tit1':
        this.goToConsola();
        break;
      case 'cuenta-cargo':
        this.triggerInvesmentNavData('Configuración cuenta');
        this.hideErrors();
        this.showSecondStep = true;
        this.scrollToTop();
        break;
      case 'categorizacion-tit1':
        this.hideErrors();
        this.helpers.navigateTo('visor-cuenta-inversora');
        break;
      default:
        this.showError = false;
        break;
    }
  }

  /**
   * Comprueba el estado actual de la petición y redirige o muestra el mensaje correspondiente
   * Para segundo titular
   * @param estado
   */
  checkStatusAndRedirectSecondHolder(estado, redirectUrl) {
    switch (estado) {
      case 'warning-documentacion-tit2':
      case 'modificacion-datos-tit2':
        this.goToConsola();
        break;
      case 'categorizacion-tit2':
        this.hideErrors();
        this.helpers.navigateTo('visor-cuenta-inversora');
        break;
      default:
        this.triggerInvesmentNavData('Configuración nickname');
        this.showError = false;
        break;
    }
  }

  hideNotification() {
    this.hiddeNotification = true;
  }

  setSteps() {
    if (this.currentPath) {
      if (this.currentPath.indexOf('/mi-perfil') !== -1) {
        this.step = 1;
      } else if (this.currentPath.indexOf('/configuracion-cuenta-inversora') !== -1) {
        this.step = 2;
      } else if (this.currentPath.indexOf('/visor-cuenta-inversora') !== -1) {
        this.step = 3;
      } else if (this.currentPath.indexOf('/final-cuenta-inversora') !== -1) {
        this.step = 4;
      }
    } else if (!this.step) {
      this.step = 1;
    }
  }

  gestionAbandono(estado, idCliente, idCaso) {
    this.showLoading();
    this.personaSrv.consultarDatosCasoInversora(idCliente, idCaso).subscribe(data => {
      if (data.codigo === 'OK') {
        this.sessionStorageSrv.setItem('getCaso', JSON.stringify(data));
        this.sessionStorageSrv.setItem('abandono', 'true');
        if (this.isSecondSigner) {
          this.idExterno = data.infoCaso.datosSegundoTitular.datosPersonales.idExterno;
          this.sessionStorageSrv.setItem('ibanCuentaSeleccionada', data.infoCaso.IBAN);
        } else {
          const idExternoTitular = data.infoCaso.datosPrimerTitular.datosPersonales.idExterno;
          // Comparar idExterno usuario logado y idExterno primer titular,
          // si no conciden se trata de un segundo titular logado tras acceder a una URL de primer titular
          if (this.idExterno && this.idExterno !== idExternoTitular) {
            this.isSecondSigner = true;
            this.sessionStorageSrv.setItem('isSecondSigner', 'true');
            this.sessionStorageSrv.setItem('preparadoFirmaST', 'true');
            this.hideErrors();
            this.helpers.navigateTo('final-cuenta-inversora');
            return;
          }

          this.idExterno = idExternoTitular;

          if (data.infoCaso.datosSegundoTitular) {
            if (data.infoCaso.puntoAbandono === 'categorizacion-tit1'
              || data.infoCaso.puntoAbandono === 'gestion-info-precontractual-tit1') {
              // Es un abandono de una invitación de primerTitular a segundoTitular.
              this.completarDatosSegundoTitular(data.infoCaso.datosSegundoTitular.datosPersonales);
            } else if (data.infoCaso.puntoAbandono === 'firma-tit1') {
              this.sessionStorageSrv.setItem('ibanCuentaSeleccionada', data.infoCaso.IBAN);
            }
          }
        }
        // Seteamos el punto de abandono del infoCaso.
        estado = data.infoCaso.puntoAbandono;


        console.log('redireccion a : ', estado)
        //  this.sessionStorageSrv.setItem("userData", JSON.stringify(data));

        if (this.loginSrv.userData) {
          const userData = this.loginSrv.userData;
          this.getPersonaAndRedirect(userData, estado);
        } else {
          this.authUserService.getClientData().subscribe(dataClient => {
            this.getPersonaAndRedirect(dataClient, estado);
          }, _error => {
            this.goToError('SATELITE-C');
          });
        }
      } else {
        this.goToError('ASTRONAUTA');
      }
    }, error => {
      this.checkErrorStatusAndGoToError(error, 'ASTRONAUTA');
    });
  }

  gestionAbandonoSSO(idCaso) {
    this.showLoading();
    // Si el cliente viene de un abandono y tiene el caso creado se le redirije a su último punto
    this.personaSrv.consultarDatosCasoInversora(this.idExterno, idCaso).subscribe(data => {
      this.sessionStorageSrv.setItem('getCaso', JSON.stringify(data));
      this.sessionStorageSrv.setItem('abandono', 'true');
      const puntoAbandono = data.infoCaso.puntoAbandono;
      this.idExterno = data.infoCaso.datosPrimerTitular.datosPersonales.idExterno;
      const contratoDeDosTitulares = !!data.infoCaso.datosSegundoTitular;

      if (this.isSecondSigner) {
        this.sessionStorageSrv.setItem('ibanCuentaSeleccionada', data.infoCaso.IBAN);
      }

      if (this.loginSrv.userData) {
        this.idCliente = this.loginSrv.userData.persona.id_cliente_EVO;
        this.cargarCuentas();
        this.getPersonaById(puntoAbandono, contratoDeDosTitulares);
      }
      else {
        this.authUserService.getClientData().subscribe(clientData => {
          // Comprobamos el punto de abandono  y redirigimos.
          this.loginSrv.userData = clientData;
          this.idCliente = clientData.persona.id_cliente_EVO;
          this.cargarCuentas();
          this.getPersonaById(puntoAbandono, contratoDeDosTitulares);
        }, _error => {
          this.checkErrorStatusAndGoToError(_error, 'SATELITE-C');
        });
      }
    }, error => {
      this.checkErrorStatusAndGoToError(error, 'ASTRONAUTA');
    });
  }

  getPersonaByIdAndCodProducto() {
    this.showLoading();
    this.idCliente = this.loginSrv.userData.persona.id_cliente_EVO;
    sessionStorage.setItem('idClienteEvo', this.idCliente);
    const codigo = this.loginSrv.userData.listaContratados[0].idProducto;
    this.personaSrv.getPersonaById(this.idCliente, codigo).subscribe(data => {
      this.loginSrv.userInfo = data;
      this.loginSrv.userInfo.idExterno = this.idExterno;
      this.continuarSinRedireccion();
    }, _error => {
      this.checkErrorStatusAndGoToError(_error, 'SATELITE-C');
    });
  }

  /**
   * Recupera los datos de SF y redirige en función del estado
   * @param dataClient userData
   * @param estado estado
   */
  getPersonaAndRedirect(dataClient, estado) {
    this.idCliente = dataClient.persona.id_cliente_EVO;
    this.codProductoContratado = this.sessionStorageSrv.getItem('productoContratando');
    this.personaSrv.getPersonaById(this.idCliente, this.codProductoContratado).subscribe(dataInfo => {
      this.loginSrv.userInfo = dataInfo;
      // Comprobamos el punto de abandono  y redirigimos.
      this.sessionStorageSrv.setItem('userData', JSON.stringify(dataClient));
      if (estado === 'cuenta-cargo' || estado === 'nickname') {
        this.cargarCuentas();
      }
      this.idCliente = dataClient.persona.id_cliente_EVO;
      this.redireccion(estado, this.isSecondSigner);
    }, error => {
      this.checkErrorStatusAndGoToError(error, 'SATELITE-C');
    });
  }

  continuarCaso(caso) {
    this.showLoading();
    this.action = 'abandono';
    this.setIbanAcountSelected();
    this.personaSrv.consultarDatosCasoInversora(this.idCliente, caso.idCaso).subscribe(data => {
      if (data.codigo === 'OK') {
        //Comprobamos si el usuario es primer titular y si el segundo titular ya ha firmado
        if (data.infoCaso.datosSegundoTitular && (data.infoCaso.estadoCaso === 'Pendiente Firma Tit 1' || data.infoCaso.estadoCaso === 'Firma primer titular')) {
          this.sessionStorageSrv.setItem('isTwoHolders', true);
        }
        this.idCaso = caso.idCaso;
        this.sessionStorageSrv.setItem('getCaso', JSON.stringify(data));
        this.sessionStorageSrv.setItem('abandono', 'true');
        this.sessionStorageSrv.setItem('casoSeleccionado', caso.idCaso);
        console.log('redireccion a : ', caso.puntoAbandono)
        this.codProductoContratado = this.sessionStorageSrv.getItem('productoContratando');
        // Comprobamos el punto de abandono  y redirigimos.
        if (caso.puntoAbandono === 'cuenta-cargo' || caso.puntoAbandono === 'nickname') {
          this.cargarCuentas();
        }

        if (data.infoCaso.datosSegundoTitular) {
          if (data.infoCaso.puntoAbandono === 'categorizacion-tit1'
            || data.infoCaso.puntoAbandono === 'gestion-info-precontractual-tit1') {
            // Es un abandono de una invitación de primerTitular a segundoTitular.
            this.completarDatosSegundoTitular(data.infoCaso.datosSegundoTitular.datosPersonales);
          } else if (data.infoCaso.puntoAbandono === 'firma-tit1') {
            this.sessionStorageSrv.setItem('ibanCuentaSeleccionada', data.infoCaso.IBAN);
          }
        }
        this.redireccion(caso.puntoAbandono, false);
      } else {
        this.goToError('ASTRONAUTA');
      }
    }, _error => {
      this.checkErrorStatusAndGoToError(_error, 'ASTRONAUTA');
    });
  }


  getPersonaById(puntoAbandono, contratoDeDosTitulares) {
    this.showLoading();
    this.personaSrv.getPersonaById(this.idCliente, this.codProductoContratado).subscribe(data => {
      this.loginSrv.userInfo = data;
      this.redireccion(puntoAbandono, this.isSecondSigner, contratoDeDosTitulares);
    }, _error => {
      this.checkErrorStatusAndGoToError(_error, 'SATELITE-C');
    });
  }

  redireccion(estado, isSecondSigner, contratoDeDosTitulares?) {
    let nextStep;
    if (!isSecondSigner) {
      this.finalText1Titular = contratoDeDosTitulares;
      this.sessionStorageSrv.setItem('mostrarTexto1Titular', this.finalText1Titular);
      switch (estado) {
        case 'warning-documentacion-tit2':
        case 'modificacion-datos-tit2':
        case 'login-tit2':
        case 'categorizacion-tit2':
        case 'gestion-info-precontractual-tit2':
        case 'previa-firma-tit2':
        case 'firma-tit2':
        case 'mod-dni-tit2':
        case 'mod-drf-tit2':
          nextStep = 'final-cuenta-inversora';
          this.sessionStorageSrv.setItem('esperaFirmaST', 'true');
          break;
        case 'warning-documentacion-tit1':
          nextStep = 'nueva-consola';
          break;
        case 'registro':
        case 'modificacion-datos-tit1':
        case 'mi-perfil':
        case 'mod-dni-tit1':
        case 'mod-drf-tit1':
          nextStep = 'nueva-consola';
          break;
        case 'nickname':
          nextStep = 'configuracion-cuenta-inversora';
          this.triggerInvesmentNavData('Configuración nickname');
          break;
        case 'cuenta-cargo':
          this.showSecondStep = true;
          nextStep = 'configuracion-cuenta-inversora';
          this.triggerInvesmentNavData('Configuración cuenta');
          break;
        case 'categorizacion-tit1':
          nextStep = 'visor-cuenta-inversora';
          break;
        case 'gestion-info-precontractual-tit1':
          this.sessionStorageSrv.setItem('docPrecontractual', 'true');
          nextStep = 'visor-cuenta-inversora';
          break;
        case 'previa-firma-tit1':
        case 'firma-tit1':
          nextStep = 'logalty-ampliacion';
          break;
        case 'casi-lo-tenemos':
          nextStep = 'final-cuenta-inversora';
          this.goToError('ASTRONAUTA');
          break;

        default:
          this.goToError('ASTRONAUTA');
      }
      // SEGUNDO TITULAR.
    } else {
      switch (estado) {
        case 'warning-documentacion-tit1':
        case 'modificacion-datos-tit1':
        case 'nickname':
        case 'cuenta-cargo':
        case 'categorizacion-tit1':
        case 'gestion-info-precontractual-tit1':
        case 'mod-dni-tit1':
        case 'mod-drf-tit1':
          this.goToError('SATELITE-C');
          break;
        case 'mi-perfil':
        case 'previa-firma-tit1':
        case 'firma-tit1':
          nextStep = 'final-cuenta-inversora';
          this.sessionStorageSrv.setItem('preparadoFirmaST', 'true');
          break;
        case 'warning-documentacion-tit2':
        case 'modificacion-datos-tit2':
        case 'login-tit2':
        case 'mod-dni-tit2':
        case 'mod-drf-tit2':
          nextStep = 'nueva-consola';
          break;
        case 'categorizacion-tit2':
          nextStep = 'visor-cuenta-inversora';
          break;
        case 'gestion-info-precontractual-tit2':
          this.sessionStorageSrv.setItem('docPrecontractual', 'true');
          nextStep = 'visor-cuenta-inversora';
          break;
        case 'previa-firma-tit2':
        case 'firma-tit2':
          nextStep = 'logalty-ampliacion';
          break;
        case 'casi-lo-tenemos':
          nextStep = 'final-cuenta-inversora';
          this.errorInversora('ASTRONAUTA', estado, this.idCaso, 'Error servicio consulta Nickname');
          break;
        default:
          this.errorInversora('ASTRONAUTA', estado, this.idCaso, 'Error servicio consulta Nickname');
      }
    }
    if (nextStep) {
      if (nextStep === 'nueva-consola') {
        this.goToConsola();
      } else {
        this.hideErrors();
        this.helpers.navigateTo(nextStep);
      }
    }
  }

  gestionSegundoTitular(idCliente, idCaso) {
    this.showLoading();

    // obtenemos idExterno
    this.personaSrv.consultarDatosCasoInversora(idCliente, idCaso).subscribe(data => {
      if (data.codigo === 'OK') {
        this.sessionStorageSrv.setItem('getCaso', JSON.stringify(data));
        this.sessionStorageSrv.setItem('abandono', 'true');
        this.idExterno = this.sessionStorageSrv.getItemParsed('userInfo').datosPersonales.idExterno;

        if (this.loginSrv.userData) {
          this.hideErrors();
          this.idCliente = idCliente;
          this.redireccion('login-tit2', this.isSecondSigner);
        }
        else {
          this.authUserService.getClientData().subscribe(dataToken => {
            // Comprobamos el punto de abandono  y redirigimos.
            this.sessionStorageSrv.setItem('userData', JSON.stringify(dataToken));
            this.hideErrors();
            this.idCliente = idCliente;
            this.redireccion('login-tit2', this.isSecondSigner);
          }, _error => {
            this.goToError('SATELITE-C');
          });
        }
      } else {
        this.goToError('ASTRONAUTA');
      }
    }, error => {
      this.checkErrorStatusAndGoToError(error, 'ASTRONAUTA');
    });
  }

  modalOpenCondiciones() {
    if (!this.datosForm.get('checkAcceptConditions').value) {
      this.modService.open(this.modalLegal);
    }
  }

  checkCondiciones() {
    this.modService.close(this.modalLegal);
  }

  /**
   * Dependiendo del valor de seleccionarCuenta se muestran o no los campos de email y repetir email
   * si seleccionarCuetna es 1 no se muestra, si es 2 si se muestran para que el cliente pueda
   * introducir el nuevo email
   */
  setEmail(value) {
    if (value === 1) {
      this.showInputEmail = false;
    } else {
      this.showInputEmail = true;
    }
  }


  initProduct() {
    if (!this.showSecondStep) { // No volvemos de visor-cuenta-inversora (Botón atrás)
      if (this.codProductoContratado === ConstantsService.AccountTypes.CuentaInversora.code) {
        this.initCuentaInversora();
      } else if (this.codProductoContratado === ConstantsService.AccountTypes.FondoInteligente.code) {
        this.initFondoInteligente();
      } else {
        this.hideErrors();
      }
    } else {
      this.hideErrors();
    }
    this.cargarCuentas();
    this.checkCondicionesLegales();
    // Matiene los tooltip del nickname actualizados
    this.updateTooltip();

  }

  existeFondoInteligente() {
    const userData = this.sessionStorageSrv.getItemParsed('userData');
    if (userData) {
      const cuentasCliente = userData && userData.listaContratados;
      const totalCuentas = cuentasCliente.length;
      for (let i = 0; i < totalCuentas; i++) {
        if (cuentasCliente[i].idProducto === ConstantsService.AccountTypes.FondoInteligente.code) {
          return true;
        }
      }
    }
    return false;
  }

  cargarCuentas() {
    const userData = this.sessionStorageSrv.getItemParsed('userData');

    this.cuentasCliente = userData && userData.listaContratados;

    const totalCuentas = this.cuentasCliente.length;
    this.cuentas = [];

    for (let i = 0; i < totalCuentas; i++) {

      const idProducto = this.cuentasCliente[i].idProducto;
      let nombreProducto = this.cuentasCliente[i].nombreProducto.concat(' - ' + this.cuentasCliente[i].ibanCC);
      // Mostrar en la lista solo:cuentas inteligentes (006), cuenta base (007), cuenta joven (008).
      switch (idProducto) {
        case ConstantsService.AccountTypes.CuentaDuo.code: // Cuenta Base (CUENTA DUO)
          nombreProducto = "CUENTA BASE - " + this.cuentasCliente[i].ibanCC;
          this.addCuentas(nombreProducto, i);
          break;
        case ConstantsService.AccountTypes.CuentaInteligente.code: // Cuenta inteligente
          nombreProducto = "CUENTA INTELIGENTE - " + this.cuentasCliente[i].ibanCC;
          this.addCuentas(nombreProducto, i);
          break;
        case ConstantsService.AccountTypes.SegundoTitular.code: // Segundo Titular
          nombreProducto = "S. TITULAR INTELIGENTE - " + this.cuentasCliente[i].ibanCC;
          this.addCuentas(nombreProducto, i);
          break;
        case ConstantsService.AccountTypes.CuentaJoven.code: // Cuenta Joven
          nombreProducto = "CUENTA JOVEN - " + this.cuentasCliente[i].ibanCC;
          this.addCuentas(nombreProducto, i);
          break;
        default:
          break;
      }
    }

    // Ordenar cuentas
    this.cuentas.sort((a, b) => {
      if (a.name.indexOf('INTELIGENTE') !== -1 && b.name.indexOf('INTELIGENTE') === -1) {
        return -1
      } else {
        return 0;
      }
    });

    if (this.cuentas.length > 0) {
      this.defaultAcount = this.cuentas.length !== 1 ? this.cuentas[0].value : this.cuentas[0].name;
    }

    this.datosForm.controls['cuentas'].setValue(this.defaultAcount, { onlySelf: true });

    // Para actualizar el segundo titular de cada cuenta
    this.datosForm.get('cuentas').valueChanges.subscribe(data => {
      this.activarST(true);
    })

    this.datosForm.get('checkAcceptConditions').valueChanges.subscribe(data => {
      if (this.datosForm.get('checkAcceptConditions').value === true) {
        this.checkLegales = false;
      }

      if (this.emailErrorClear) {
        this.hideNotification();
      }
      /*else{
       this.checkLegales = true;
      }*/
    });
    this.checkCondicionesLegales();

  }

  addCuentas(nombreProducto, i) {
    this.cuentasProductos = this.cuentasCliente[i].productos;
    if (this.cuentasProductos) {
      const totalCuentasProductos = this.cuentasProductos.length;
      for (let j = 0; j < totalCuentasProductos; j++) {
        if (this.cuentasProductos[j].iban === this.cuentasCliente[i].ibanCC) {
          this.numeroAcuerdo = this.cuentasProductos[j].acuerdo;
        }
        if (this.cuentasProductos[j].codigoGrupo === '55' && this.cuentasProductos[j].codigoLinea === '05') {
          this.acuerdoCuentaCargoElectronica = this.cuentasProductos[j].acuerdo;
          this.cuentaCargoElectronica = this.cuentasProductos[j].iban;
        }
      }
    }
    this.cuentas.push({
      'value': this.cuentasCliente[i].ibanCC,
      'name': nombreProducto,
      'acuerdo': this.numeroAcuerdo
    });
  }

  updateTooltip() {
    let nombreCliente = JSON.parse(sessionStorage.getItem('userInfo')).nombreCliente;
    let apellido1 = JSON.parse(sessionStorage.getItem('userInfo')).apellido1Cliente;
    let apellido2 = JSON.parse(sessionStorage.getItem('userInfo')).apellido2Cliente;
    this.datosForm.get('nickname').valueChanges.subscribe(data => {
      document.getElementById('nickname').classList.remove('custom-bg-noerror');

      const nickname: string = data;
      //  Se ocultan los tooltip de error.
      this.nickNoValido = false;
      this.nicknameErrorCaracteres = false;
      this.nicknameError = false;
      this.contieneEspacio = false;
      this.nicknameExist = false;

      if (nickname === '') {
        this.quitarCampoError();
      }
      for (let index = 0; index < data.length; index++) {
        const car = nickname.charCodeAt(index);
        // Se evita quitar el error del espacio al entrar a comprobar el siguiente carácter
        if (this.contieneEspacio !== true) {
          if (car === 32 && this.nicknameError === false) {
            this.contieneEspacio = true;
            this.nicknameError = true;
            this.mostrarCampoError();
          } else {
            this.contieneEspacio = false;
            this.quitarCampoError();
          }
        }
        // Si no contiene espacios se comprueba si no tiene carácteres especiales
        if (this.nicknameErrorCaracteres === false && this.contieneEspacio === false) {
          if ((car >= 48 && car <= 57) || (car >= 65 && car <= 90)
            || (car >= 97 && car <= 122) || car === 32) {
            // Es un carácter válido
            this.nicknameErrorCaracteres = false;
            this.quitarCampoError();

          } else {
            // Es un carácter especial no válido
            this.nicknameErrorCaracteres = true;
            this.mostrarCampoError();
          }
        }
        // Si no contiene espacios y no tiene carácteres especiales , se comprueba si esta incluidos nombre o apellidos
        // Solo para fondo inteligente
        if (this.nicknameErrorCaracteres === false && this.contieneEspacio === false
            && this.nicknameErrorCaracteres === false && this.codProductoContratado === ACCOUNTS.FINTCode) {
          if(data.toUpperCase().includes(nombreCliente) || data.toUpperCase().includes(apellido1) || data.toUpperCase().includes(apellido2)){
            this.nickNameIncludes = true;
            this.formValido = false;
            this.invalidNickname = true;
            this.datosForm.get('nickname').setErrors({ 'incorrect': true });
            this.mostrarCampoError();
          } else {
            this.nickNameIncludes = false;
            this.quitarCampoError();
          };
        }
      }
    });

  }

  mostrarCampoError() {
    document.getElementById('nickname').classList.remove('ng-pristine');
    document.getElementById('nickname').classList.remove('ng-untouched');
    document.getElementById('nickname').classList.add('ng-dirty');
    document.getElementById('nickname').classList.add('invalid-nickname');
  }

  quitarCampoError() {
    document.getElementById('nickname').classList.add('ng-pristine');
    document.getElementById('nickname').classList.add('ng-untouched');
    document.getElementById('nickname').classList.remove('ng-dirty');
    document.getElementById('nickname').classList.remove('invalid-nickname');
    this.bannerRevisa = false;
  }

  isFondoInteligente(codProducto) {

    if (this.getOperacionInv() === 'fondo-inteligente-nickname' ||
      this.getOperacionInv() === 'fondo-inteligente' ||
      codProducto === ConstantsService.AccountTypes.FondoInteligente.code) {
      this.esFondoInteligente = true;
    }

  }

  completarDatosSegundoTitular(data) {
    this.sessionStorageSrv.setItem('activarSegundoTitular', 'true');
    // Si tenemos nombreCliente, es un segundo titular añadido desde la cuenta
    if (data.nombreCliente) {
      this.sessionStorageSrv.setItem('nombreSegTitular', data.nombreCliente);

    } else {
      // Y si no, es una invitación por mail.
      this.sessionStorageSrv.setItem('emailSegundoTitular', data.email);
    }
  }

  errorInversora(errorMessage, estado, idCaso, errorDescription) {
    this.obj = {
      'estado': estado,
      'idCaso': idCaso,
      'description': errorDescription
    };
    this.showLoading();
    this.personaSrv.modificarCuenta(this.obj).subscribe(
      data => {
        switch (data.codigo) {
          case 'OK':
            this.goToError(errorMessage);
            break;
          case 'KO':
          default:
            this.goToError('SATELITE-C');
            break;
        }
      },
      error => {
        this.checkErrorStatusAndGoToError(error, 'SATELITE-C');
      }
    );
  }

  goToError(errorMessage) {
    this.hideErrors();
    this.errService.navToErrorModData(errorMessage, 'ENTENDIDO', 'bancaelectronica');
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  onKeyUpValidateEmail(event: any) {

    event.preventDefault();
    event.stopPropagation();

    const email = event.target.value;
    const confirmEmail = this.datosForm.get('confirmEmail').value;

    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email === null || email === '') {
      document.getElementById('email').classList.remove('ng-invalid');
      document.getElementById('email').classList.remove('ng-valid');
    } else if (!re.test(email)) {
      document.getElementById('email').classList.add('ng-invalid');
      document.getElementById('email').classList.remove('ng-valid');
    } else {
      document.getElementById('email').classList.add('ng-valid');
      document.getElementById('email').classList.remove('ng-invalid');
    }

    if (confirmEmail === null || confirmEmail === '') {
      document.getElementById('confirmEmail').classList.remove('ng-valid');
      document.getElementById('confirmEmail').classList.remove('ng-invalid');
    } else if (email !== confirmEmail || !re.test(confirmEmail)) {
      document.getElementById('confirmEmail').classList.add('ng-invalid');
      document.getElementById('confirmEmail').classList.remove('ng-valid');
    } else {
      document.getElementById('confirmEmail').classList.add('ng-valid');
      document.getElementById('confirmEmail').classList.remove('ng-invalid');
    }
  }



  checkCondicionesLegales() {
    this.datosForm.get('checkAcceptConditions').valueChanges.subscribe(data => {
      if (this.datosForm.get('checkAcceptConditions').value === true) {
        this.checkLegales = false;
      } else {
        this.checkLegales = true;
      }
    });
  }

  hideBannerNotifyOk() {
    this.hideBannerNewConsoleDocumentation = true;
    // metemos en sesion si ha cerrado el baner.
    // this.sessionStorageSrv.setItem('notificacionOk', 'true');
  }

  hideBannerNewConsoleClientData() {
    this.hideBannerNewConsoleClientDataOk = true;
    // metemos en sesion si ha cerrado el baner.
    //  this.sessionStorageSrv.setItem("notificacionOk", "true");
  }

  goToConsola() {
    this.sessionStorageSrv.setItem('casoSeleccionado', this.idCaso);
    this.sessionStorageSrv.setItem('productoContratando', this.codProductoContratado);
    let redirectUrl = `${environment.endpoint}/#/configuracion-cuenta-inversora`;
    this.helpers.goToConsola(this.codProductoContratado, this.idCaso, redirectUrl);
  }

  triggerInvesmentNavData(pageName, idCliente?) {

    this.analytics.triggerInvestmentDataNavDataNew(pageName,this.idCliente);

  }

  getUserInfoFromKeycloak(saveRefreshToken = true) {
    const userInfo = this.keycloak.getKeycloakInstance().idTokenParsed;
    console.log('userInfo', userInfo);
    if (userInfo) {
      this.loading = false;
      this.loginSrv.userInfo = userInfo;
      this.idCliente = userInfo && userInfo['id_salesforce'];
      this.sessionStorageSrv.setItem('idClienteEvo', this.idCliente);
      this.sessionStorageSrv.setStringifiedItem('userInfo', userInfo);
      this.idExterno = userInfo['idExterno'];
      this.specificAnalyticsSrv.emitHashedDni(this.idExterno);
      this.authSrv.setToken(userInfo['api_access_token'] ? 
        userInfo['api_access_token'] : 
        this.keycloak.getKeycloakInstance().token);
      saveRefreshToken && this.authSrv.setRefreshToken(userInfo['api_refresh_token']);
      this.sessionStorageSrv.setItem('idInternoPe', userInfo['idInternoPe']);
      const hotjarId = environment.type === 'CINV' ? "h._hjSettings={hjid:2694118,hjsv:6};" : "h._hjSettings={hjid:2694119,hjsv:6};";
      this.inject.createScriptHotjarFromSVC(hotjarId, this.idCliente);
    }
  }

  abrirModal() {
    this.modService.open('modal_noCliente');
  }

  getOperacionInv() {
    return this.sessionStorageSrv.getItem('operacion-inversora');
  }

  getUserDataAndContinue() {
    this.authUserService.getClientData().subscribe(data => {
      this.idCliente = data.persona.id_cliente_EVO;
      this.sessionStorageSrv.setItem('idClienteEvo', this.idCliente);
      sessionStorage.setItem('userData', JSON.stringify(data));
      const dni = data.persona.idExterno;
      this.specificAnalyticsSrv.emitHashedDni(dni);
      this.continuarSinRedireccion();
    },
    error => {
      this.errService.modDataError = true;
      this.checkErrorStatusAndGoToError(error, 'SATELITE-C');
    });
  }

  /**
   * Muestra la pantalla de loading (cohete)
   */
  showLoading() {
    this.showError = true;
    this.nameError = 'loadingCustom';
  }

  /**
   * Oculta/Cierra el error o pantalla de loading actual
   */
  hideErrors() {
    this.showError = false;
    this.nameError = 'ocultarErrores';
  }

  updateInversoraTodoOk() {
    let estado = 'nickname';
    if (this.isSecondSigner) {
      estado = 'categorizacion-tit2';
      this.drfSegundoTitular = false;
      this.obligacionesFiscalesSegundoTitular = false;
      this.obj = {
        'estado': estado,
        'idCaso': this.idCaso,
        'drfSegungoTitular': this.drfSegundoTitular,
        'obligacionesFiscalesSegundoTitular': this.obligacionesFiscalesSegundoTitular
      };
    } else {
      this.DRF = false;
      this.obligacionesFiscales = false;
      this.obj = {
        'estado': estado,
        'idCaso': this.idCaso,
        'drf': this.DRF,
        'obligacionesFiscales': this.obligacionesFiscales
      };
    }
    this.putInversora(estado);
  }

  updateInversoraAndGoToErrorBE() {
    if (this.isSecondSigner) {
      this.obj = {
        'estado': '',
        'idCaso': this.idCaso,
        'drfSegungoTitular': this.drfSegundoTitular,
        'obligacionesFiscalesSegundoTitular': this.obligacionesFiscalesSegundoTitular
      };
    } else {
      this.obj = {
        'estado': '',
        'idCaso': this.idCaso,
        'drf': this.DRF,
        'obligacionesFiscales': this.obligacionesFiscales
      };
    }
    this.putInversoraBackFromConsola();
  }

  getInvesments(listaContratados) {
    return listaContratados.filter(prod =>
      prod.idProducto === ConstantsService.AccountTypes.CuentaInversora.code
      ||
      prod.idProducto === ConstantsService.AccountTypes.FondoInteligente.code
    );
  }

  /**
   * Comprobar si el usuario que accede a contratar un fondo inteligente ya tiene algun otro contratado
   */
  checkFint(data){
    let haveOtherFint = false;
    console.log(Array.isArray(data.listaContratados));
    if (Array.isArray(data.listaContratados)){
      for (let i = 0; i < data.listaContratados.length; i++) {
        if (data.listaContratados[i].idProducto === ACCOUNTS.FINTCode) {
          haveOtherFint = true;
        }
      }
    }
    return haveOtherFint;
  }

  setAnalyticData(){
    setTimeout(() => {
      if (!this.showSecondStep &&
        (this.getOperacionInv() === 'operacion-inversora-nickname' || this.getOperacionInv() === 'operacion-inversora')
        || this.getOperacionInv() === 'fondo-inteligente-nickname') {
        this.modificacionOK = (this.sessionStorageSrv.isItemTrue('peticion-modificacion'));
      }
     }, 100);
  }

  checkErrorStatusAndGoToError(error, errorMessage, errorNick = false) {
    if (errorNick) { this.loading = false; }
    if (error && error.status && error.status.toString() === '401') {
      this.errService.isStatus401 = true;
      this.triggerInvesmentNavData('Error - 401');
    } else if(error && error.status && error.status.toString() === '500') {
      this.triggerInvesmentNavData('Error - 500');
    } else {
      this.triggerInvesmentNavData('Error - 99');
      if (errorNick && errorMessage !== 'BATCH') {
        this.errorInversora(errorMessage, 'nickname', this.idCaso, 'Error servicio consulta Nickname');
        return;
      }
    }
    this.goToError(errorMessage);
  }
}

