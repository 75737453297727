import { Injectable } from '@angular/core';
import { PRODUCTS_CONTRACT } from 'app/resources/products-contract.model';
import { GROUP_PRODUCTS } from 'app/resources/group-products.model';
import { HelperService } from './helpers.service';
import { ACCOUNTS } from 'app/resources/account.model';

@Injectable()
export class MyProductsService {

  groupProducts = GROUP_PRODUCTS;
  products = PRODUCTS_CONTRACT;

  constructor(public helpers: HelperService) { }


  getLabelProducto(product){
    const item = this.products.find((item) => {
      return product.idProducto === item.idProducto
      || (product.branchAlias == item.branchAlias && product.branchAlias != '');
    });

    if(typeof item != 'undefined') {
      return item.label;
    }

    return '';
  }

  getCardProducto(product){
    const item = this.products.find((item) => {
      return product.idProducto === item.idProducto
      || (product.branchAlias == item.branchAlias && product.branchAlias != '');
    });

    if(typeof item != 'undefined') {
      return item.card;
    }
    return '';
  }

  getImageProducto(product) {
    const item = this.products.find((item) => {
      return product.idProducto === item.idProducto
      || (product.branchAlias == item.branchAlias && product.branchAlias != '');
    });

    if(typeof item != 'undefined') {
      return item.image;
    }
    return '';
  }


  getDescriptionProducto(product) {
    const item = this.products.find((item) => {
      return product.idProducto === item.idProducto
      || (product.branchAlias == item.branchAlias && product.branchAlias != '');
    });

    if(typeof item != 'undefined') {
      return item.description;
    }
    return '';
  }


  setProductIcon(product) {
    const item = this.products.find((item) => {
      return product.idProducto === item.idProducto;
    });

    if(typeof item != 'undefined') {
      return item.icon;
    }
    return '';
  }

  setProductSelected(product) {
    sessionStorage.setItem("myProductSelected", JSON.stringify(product));
  }


  getProductSelected() {
    let myProductSelected = sessionStorage.getItem("myProductSelected");
    return JSON.parse(myProductSelected);
  }

  goToProductDetail(productContract) {
    if(productContract.branchAlias == "0019") {
      this.helpers.navigateTo("/evo-protect/boletin-adhesion");
      return ;
    }
    switch(productContract.idProducto) {
      case ACCOUNTS.CICode:
      case ACCOUNTS.CDCode:
      case ACCOUNTS.CJCode:
      case ACCOUNTS.STCode:
        this.helpers.navigateTo("/detalle-producto");
        this.setProductSelected(productContract);
        break;
      default:
    }
  }


  managePaintMicroinsurance(listPolicies, productosContracts) {
    //Si vienen pólizas
    if(listPolicies != null) {
      listPolicies.nombreProducto="MI EVO PROTECT DEPORTES";
      productosContracts.push(listPolicies);     
    }
     //Buscamos si vienen algún microseguro en la lista de contratados y lo borramos para evitar duplicados
     const index = productosContracts.findIndex(product => {
      return product.idProducto == ACCOUNTS.MSCode;
    });
    if(index != -1) {
      productosContracts.splice(index, 1);
    }
  }

  getTypeUser(relacionAcuerdoPersona) {
    return ACCOUNTS.relacionAcuerdoPersona[relacionAcuerdoPersona];
  }



  formatAddress(address) {

    let addressFormat = address.nombreVia.toLowerCase();

    if(address.domicilioNumero != null) {
      addressFormat += " " + address.domicilioNumero;
    }

    if(address.domicilioEscalera != null) {
      addressFormat +=  ", Escalera " + address.domicilioEscalera;
    }

    if(address.domicilioPlanta != null) {
      addressFormat +=  ", Piso " + address.domicilioNumero;
    }

    if(address.domicilioPuerta != null) {
      addressFormat += ", Puerta " + address.domicilioPuerta;
    }

    if(address.codigoProvinciaDomicilio != null) {
      addressFormat += ", " + address.codigoProvinciaDomicilio;
    }

    if(address.nombreLocalidad != null) {
      addressFormat += " " + address.nombreLocalidad.toLowerCase();
    }

    return addressFormat;

  }


}
