import { SessionStorageService } from './session-storage.service';
import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ACCOUNTS } from 'app/resources/account.model';
import { Observable } from 'rxjs';

import { ERRORES } from '../resources/errors.model';
import { HeaderService } from '../services/headers.service';
import { ConstantsService } from './constants.service';
import { SpecificAnalyticsService } from './specific-analytics.service';

@Injectable()
export class ErrorService {

  errUsername: string;
  logaltyWrong = false;
  logaltyCancel = false;
  altaClienteCancel = false;
  altaClienteError = false;
  bmiError = false;
  isUnauth = false;
  errEco = false;
  errUnsubscribe = false;
  errNoCorrectCEF = false;
  isTitularInteligente = false;
  isTitularJoven = false;
  isStatus401 = false;
  rsi = false;
  isMinor: boolean;
  hasExpiredDocument: boolean;
  errors = ERRORES;
  errorsArray = [];
  modDataError = false;
  modDataErrorCode = '';
  titulo = '¡MECACHIS! SE HAN CAÍDO LAS COMUNICACIONES';
  imagen = "./assets/images/satelite.gif";
  subtitulo = '¡Hemos perdido el contacto con Houston! Por favor, vuelve a intentarlo más tarde';
  boton = 'ENTENDIDO';
  action = 'login';
  linkToEvo = false;

  isCInvCortoFinalMessage = false;
  isErrorBatch = false;
  messageCinvCortoSesionFinalizada = ConstantsService.FinalMessages.SesionFinalizada;
  messageCinvCortoBatch = ConstantsService.FinalMessages.Batch;

  constructor(private router: Router,
    private specificAnalytics: SpecificAnalyticsService,
    private sessionStorageSrv: SessionStorageService,
    private headerSrv: HeaderService) {

  }

  getErrorFromField(field) {
    let text = '';
    const errorText = this.errors.filter((error) => {
      return error.field === field;
    });
    if (!!errorText) {
      text = errorText[0].text;
    }
    return text;
  }
  desktopErrors (formControl, field) {

    let text = '';

    if (formControl && !formControl.valid && !formControl.untouched) {
      const errorText = this.errors.filter((error) => {
        return error.field === field;
      });
    if (!!errorText) {
      text = errorText[0].text;
      }
    }
    return text;
  }

  mobileErrors (form) {
    this.errorsArray = [];
    Object.keys(form.controls).forEach((control) => {
      const field = form.get(control);
      if (field.disabled) {
        return;
      }
      if (!field.valid && !field.pristine) {
        const fieldError = this.errors.filter((error) => {
          return error.field === control;
        });
        if ( !!fieldError[0]) {
          this.errorsArray.push(fieldError[0]);
        }
      }

    });
  }

  reactiveErrors(form) {
    this.errorsArray = [];
    Object.keys(form.controls).forEach((control) => {
      const field = form.get(control);
      const length = control === 'reactiveEmailPhone' ? 5 : 1;
      if (!field.valid && !field.pristine && field.value.length >= length) {
        const fieldError = this.errors.filter((error) => {
          return error.field === control;
        });
        this.errorsArray.push(fieldError[0]);
        if (fieldError[0].field !== 'reactiveEmailPhone') {
          this.errorsArray.splice(1);
        }
      }
    })
  }

  reactiveDesktopErrors(formControl, field) {
    let text = '';
    const length = field === 'reactiveEmailPhone' ? 5 : 1;
    if (!formControl.valid && formControl.value.length >= length) {
      const errorText = this.errors.filter((error) => {
        return error.field === field;
      });
      text = errorText[0].text;
    }
    return text;
  }

  navToError() {

    if (this.isStatus401){
      this.goToError401Page();
      return;
    }

    const nextStep = '/404',
          navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true
          };
    this.deletePreviousSession();
    this.router.navigate([nextStep], navigationExtras);
  }

  navToErrorTypeA () {

    if (this.isStatus401){
      this.goToError401Page();
      return;
    }

    this.altaClienteError = true;
    const nextStep = '/404',
          navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true
          };
    this.router.navigate([nextStep], navigationExtras);
  }

  navToErrorModData(type, button, action = 'login') {

    this.modDataError = true;
    const sessionIsBMI = sessionStorage.getItem('isBMI');

    if (this.isStatus401){
      this.goToError401Page();
      return;
    }

    this.setErrorMessage(type, button, action);

    // Error por Batch de RSI en el orquestador
    if(this.isErrorBatch){
      this.goToErrorBatchPage();
      return;
    }

    const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'preserve',
          preserveFragment: true
        };

    if (action === 'login') {
      this.deletePreviousSession();
      sessionStorage.setItem('isBMI', sessionIsBMI);
    }

    this.router.navigate(['/404'], navigationExtras);


  }

  navToLess25() {
    const nextStep = '/Edad',
          navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true
          };
    this.router.navigate([nextStep], navigationExtras);
  }


  navToErrorEid() {
    const nextStep = '/OCR',
          navigationExtras: NavigationExtras = {
          queryParamsHandling: 'preserve',
          preserveFragment: true
        };
    this.deletePreviousSession();
    this.router.navigate([nextStep], navigationExtras);
  }

  navToWelcome() {
    let nextStep = '/bienvenido';
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };
    if (location.hash.indexOf('joven') !== -1) {
      nextStep = '/cuenta-joven/bienvenido';
    }

    this.router.navigate([nextStep], navigationExtras);
  }

  handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    this.specificAnalytics.emitErrorPage(error);
    return Observable.throw(error);
  }

  resetErrors() {
    this.altaClienteCancel = false;
    this.altaClienteError = false;
    this.errUsername = '';
    this.logaltyWrong = false;
    this.logaltyCancel = false;
    this.bmiError = false;
    this.modDataError = false;
    this.modDataErrorCode = '';
    this.titulo = '¡MECACHIS! SE HAN CAÍDO LAS COMUNICACIONES';
    this.imagen = "./assets/images/satelite.gif";
    this.subtitulo = '¡Hemos perdido el contacto con Houston! Por favor, vuelve a intentarlo más tarde';
    this.boton = 'ENTENDIDO';
    this.action = 'login';
    this.isUnauth = false;
    this.errEco = false;
    this.rsi = false;
    this.errUnsubscribe = false;
    this.errNoCorrectCEF = false;
    this.linkToEvo = false;
  }

  deletePreviousSession() {
    sessionStorage.clear();
  }

  setErrorMessage(type:string, button:string, action = 'login'){

    this.boton = button;
    this.action = action;
    this.linkToEvo = false;

    switch(type){
      case 'ASTRONAUTA':
        this.titulo = '¡COMUNICACIÓN ENVIADA A HOUSTON!';
        this.imagen="./assets/images/Astroboy.gif";
        this.subtitulo = 'Algo no nos cuadra, tenemos que revisar tu solicitud. Nos pondremos en contacto contigo por email lo antes posible.';
        break;
      case 'ASTRONAUTA-C':
        this.titulo = '¡COMUNICACIÓN ENVIADA A HOUSTON!';
        this.imagen="./assets/images/Astroboy.gif";
        this.subtitulo = 'Estamos revisando tu documentación, cuando esté todo listo te avisaremos para que puedas continuar.';
        break;
      case 'ASTRONAUTA-S':
        this.titulo = '¡COMUNICACIÓN NO ENVIADA A HOUSTON!';
        this.imagen="./assets/images/Astroboy.gif";
        this.subtitulo = '¡Vaya! No podemos enviar la solicitud a este email. Tranquilo, puedes invitar a otra persona.';
        break;
      case 'ALIEN':
        this.titulo = '¡ALGO NO CUADRA DEL TODO!';
        this.imagen="./assets/images/alienID.gif";
        this.subtitulo = 'La imagen del documento que nos has pasado no es correcta. Inténtalo de nuevo.';
        break;
      case 'ALIEN-B':
        this.titulo = '¡HEMOS VISTO QUE NOS FALTA INFORMACIÓN!';
        this.imagen="./assets/images/alienID.gif";
        this.subtitulo = 'Llámanos para que te podamos ayudar a continuar con la modificación de tus datos';
        break;
      case 'ALIEN-C':
        this.titulo = '¡VAYA, PARECE QUE TU CUENTA YA TIENE DOS TITULARES!';
        this.imagen="./assets/images/alienID.gif";
        this.subtitulo = ' Recuerda que como máximo solo puede haber dos titulares por cuenta.';
        break;
      case 'SATELITE':
        this.titulo = '¡MECACHIS! SE HAN CAÍDO LAS COMUNICACIONES';
        this.imagen="./assets/images/satelite.gif";
        this.subtitulo = '¡Hemos perdido el contacto con Houston! Por favor, intenta subir tu DNI/NIE de nuevo';
        break;
      case 'SATELITE-C':
        this.titulo = '¡MECACHIS! SE HAN CAÍDO LAS COMUNICACIONES';
        this.imagen="./assets/images/satelite.gif";
        this.subtitulo = '¡Hemos perdido el contacto con Houston! Por favor, vuelve a intentarlo más tarde';
        break;
      case 'RESIDENCIA-FUERA-ESPANIA':
        this.titulo = '¡Lo sentimos!';
        this.imagen = './assets/images/Astroboy.gif';
        this.subtitulo = 'Si tu país de residencia no es España, no puedes contratar este producto.';
        break;
      case 'RELACION-EEUU':
        this.titulo = '¡VAYA!';
        this.imagen = './assets/images/Astroboy.gif';
        this.subtitulo = 'Nos pondremos en contacto contigo para continuar con esta gestión.';
        break;
      case 'OBLIGACIONES-FUERA-ESPANIA':
        this.titulo = '¡VAYA!';
        this.imagen = './assets/images/Astroboy.gif';
        this.subtitulo = 'Vamos a solicitarte más información por email para poder continuar con tu solicitud.';
        break;
      case 'SIN-DRF':
        this.titulo = '¡VAYA!';
        this.imagen = './assets/images/5_VayaVaya.gif';
        this.subtitulo = 'Hemos visto que nos falta información. Llámanos para que te podamos ayudar a continuar tu contratación.';
        break;
      case 'SATELITE-FONDO-INT':
        this.titulo = '¡VAYA!';
        this.imagen = './assets/images/satelite.gif';
        this.subtitulo = 'Parece que no puedes ser titular de más de un Fondo Inteligente, si quieres seguir invirtiendo en Fondos de Inversión puedes abrir una Cuenta Inversora y seleccionar los fondos que más te interesan.';
        this.linkToEvo = true;
        break;
      case 'BATCH':
        this.isCInvCortoFinalMessage = true;
        this.isErrorBatch = true;
        break;
      case 'SESION-FINALIZADA':
        this.isCInvCortoFinalMessage = true;
        this.isErrorBatch = false;
        break;
      default: //SATELITE
        this.titulo = '¡MECACHIS! SE HAN CAÍDO LAS COMUNICACIONES';
        this.imagen="./assets/images/satelite.gif";
        this.subtitulo = '¡Hemos perdido el contacto con Houston! Por favor, vuelve a intentarlo más tarde';
        break;
    }
  }

  goToError401Page(){
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };
    const codProducto = this.sessionStorageSrv.getItem('productoContratando');
    this.deletePreviousSession();
    this.sessionStorageSrv.setItem('productoContratando',codProducto);
    this.router.navigate(['/sesion-finalizada'], navigationExtras);
  }

  goToErrorBatchPage(){
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };
    const codProducto = this.sessionStorageSrv.getItem('productoContratando');
    this.deletePreviousSession();
    this.sessionStorageSrv.setItem('productoContratando',codProducto);
    this.router.navigate(['/error-batch'], navigationExtras);
  }
}
