import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { environment } from 'environments/environment';
import { TokenInfoService } from './token-info.service';
import { AuthGuardUser } from './auth-guard-user.service';
import { AuthTokenService } from './token/auth.token.service';

@Injectable()
export class AppSSOAuthGuard extends KeycloakAuthGuard {
  constructor(
    private tokenInfoService: TokenInfoService,
    private authGuardUser: AuthGuardUser,
    protected router: Router,
    protected keycloakAngular: KeycloakService,
    protected authTokenSrv: AuthTokenService) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      // En primer lugar, se intenta obtener la información de las cookies.
      if (this.tokenInfoService.checkAuthenticationFromCookies() ) {
        return resolve(true);
      }

      //Login realizado desde SSO
      if (this.authTokenSrv.getToken() && this.authTokenSrv.getToken().length > 0){
        return resolve(true);
      }
      // Se comprueba si se encuentra logado por los parámetros en la url.
      if (!this.authenticated && this.authTokenSrv.haveParamsToAutenticateByUuidAndUsuarioBE(route.queryParams)) {
        return resolve(true);
      }
      // Se comprueba si se encuentra logado por los parámetros en la url.
      if (this.authGuardUser.canActivate(route, state) && (state.url.indexOf('token=') !== -1 || (state.url.indexOf('uuid=') !== -1 && state.url.indexOf('usuarioBE=') !== -1))) {
        return resolve(true);
      }

      if (!this.authenticated) {
        // check if authenticated
        this.keycloakAngular.login();
        return resolve(false);
      }

      this.tokenInfoService.setKeycloakInfo();
      const requiredRoles = route.data.roles;
      console.log('roles from token ' + this.roles);
      console.log('roles from user ' + requiredRoles);
      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      } else {
        if (!this.roles || this.roles.length === 0) {
          resolve(false);
        }
        let granted = false;
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
        resolve(granted);
      }
    });
  }
}
