<loading [showMe]="loading"></loading>

<mobile-error-zone
  *ngIf="errService.errorsArray.length > 0"
  [errors]="errService.errorsArray">
</mobile-error-zone>

<modal [modalTitle]="'Modal Error'" [blocking]='false' [modalId]='modalError' class='modal'>
  <div class="container">
      <h2>¡Ups!</h2>
      <p class="invalid-text">{{msgError}}</p>
  </div>
</modal>

<div class="beneficiaries">
  <div class="image"></div>
  <div class="container">
    <div class="title-container">
      <legend>
          <h2>BENEFICIARIOS EN CASO DE ACCIDENTE</h2>
          <p>Selecciona los beneficiarios de tu EVO PROTECT DEPORTES en caso de accidente.</p>
      </legend>
    </div>
    <form [formGroup]="beneficiaryForm"
    (keydown.enter)="$event.preventDefault()"
    (ngSubmit)="onSubmit()" novalidate>
    <div class="main-content">
      <div class="group-radio-vertical middle">
        <div class="options row">
            <div class="col" *ngFor="let beneficiary of beneficiaries">
                <input type="radio" [id]="beneficiary.id" [value]="beneficiary.id" name="beneficiario" formControlName="beneficiario">
                <label class="button-label round" [for]="beneficiary.id"><span class="color-white">{{ beneficiary.value }}</span></label>
            </div>
        </div>
      </div>
      <div class="other-beneficiary" *ngIf="otherContainer" [@shrinkOut]>
        <p>Por favor, indica el nombre, apellidos y NIF/NIE del beneficiario:</p>
        <br>
        <div class="row">
          <div class="mobile-container">
            <label for="nombre">Nombre</label>
            <input  type="text" id="nombre" name="nombre"
              formControlName="nombre"
              maxlength="45"
              placeholder="Nombre del beneficiario"
              autocomplete="off">
            <div class="error-text" *ngIf="errService.desktopErrors(beneficiaryForm.controls.nombre, 'nombre')">
              {{errService.desktopErrors(beneficiaryForm.controls.nombre, 'nombre')}}
            </div>
          </div>
          <div class="mobile-container">
              <label for="apellidos">Apellidos</label>
              <input  type="text" id="apellidos" name="apellidos"
                formControlName="apellidos"
                maxlength="45"
                placeholder="Apellidos del beneficiario"
                autocomplete="off">
              <div class="error-text" *ngIf="errService.desktopErrors(beneficiaryForm.controls.apellidos, 'apellidos')">
                {{errService.desktopErrors(beneficiaryForm.controls.apellidos, 'apellidos')}}
              </div>
          </div>
        </div>
        <div class="row">          
          <div class="mobile-container">
              <label for="dni">DNI</label>
                maxlength="9"
                placeholder="NIF/NIE"
                autocomplete="off">
              <div class="error-text" *ngIf="errService.desktopErrors(beneficiaryForm.controls.numeroDocumento, 'numeroDocumento')">
                {{errService.desktopErrors(beneficiaryForm.controls.numeroDocumento, 'numeroDocumento')}}
              </div>
          </div>
        </div>
      </div>
      <div class="btn-group" [ngClass]="!otherContainer ? 'space' : ''">
          <button type="submit" class="btn-common btn-common_fix_bold">CONTINUAR</button>
      </div>
    </div>
    </form>
  </div>        
</div>