export class Modal {
  public name: string = 'modal-errores-genericos';
  public withImage: boolean = false;
  public message: string = 'Error genérico.';
  public title: string = '¡Ups!';
  public blocking: boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
