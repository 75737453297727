import {Component, OnInit, OnDestroy, Input, HostBinding } from '@angular/core';

import {SliderComponent, Direction} from '../slider.component';

@Component({
    selector: 'slide',
    template: `
    <div [class.active]="active" class="item text-center">
      <ng-content></ng-content>
    </div>
  `,
    styles: [`
          .item {
            position: relative;
            display: none;
            max-height: 276px;
          }
          .active {
              left:0;
              display: flex;
          }

          `]
})
export class Slide implements OnInit, OnDestroy {
    @Input() public index:number;
    @Input() public direction:Direction;

    @HostBinding('class.active')
    @Input() public active:boolean;

    @HostBinding('class.item')
    @HostBinding('class.carousel-item')
    private addClass:boolean = true;

    constructor(private carousel:SliderComponent) {
    }

    public ngOnInit() {
        this.carousel.addSlide(this);
    }

    public ngOnDestroy() {
        this.carousel.removeSlide(this);
    }
}
