import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Otp } from 'app/collections/otp';
import { ValidateOtp } from 'app/collections/validate.otp';
import { OtpService } from 'app/services/otp.service';
import { ApiOtpService } from 'app/services/api/api.otp.service';
import { ModalService } from 'app/services/modal.service';
import { OtpDetailsComponent } from 'app/components/otp-details/otp-details.component';
import { HelperService } from 'app/services/helpers.service';
import { DeviceService } from 'app/services/device.service';
import { environment } from 'environments/environment';
import { ErrorService } from '../../../services/error.service';


@Component({
  selector: 'app-otp-confirm',
  templateUrl: './otp-confirm.component.html',
  styleUrls: ['./otp-confirm.component.scss'],
  providers : [ModalService]
})
export class OtpConfirmComponent implements OnInit {

  public paramsSMS: Otp;
  @ViewChild(OtpDetailsComponent) otpDetails: OtpDetailsComponent;

  constructor(private authService: AuthService,
              public modService: ModalService,
              private helpers: HelperService,
              private deviceService: DeviceService,
              private otpService: OtpService,
              private error:ErrorService) { }

  ngOnInit() {
    const persona = this.authService.getSessionProspect();
    this.paramsSMS = new Otp({
      product : "CuentaInteligente",
      userId: persona.datosPersonales.id_cliente_EVO
    });
    this.sendSmsOTP();
  }

  sendSmsOTP (phone: string = null) {
    const params = this.paramsSMS;
    let persona = this.authService.getSessionProspect();
    let otpPhone = phone ? phone : persona.datosPersonales.telefonoMovil;

    if (typeof otpPhone === 'undefined') {
        this.otpDetails.loading = false;
        this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
        this.otpService.openModal(false);
        return;
    }

    this.otpService.generateSecurizedOtp(params).subscribe(data => {
      if (data.operationErrorMessage) {
        this.otpDetails.modal.title = '¡Ups!';
        this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
        this.otpDetails.loading = false;
        this.otpService.openModal(false);
        this.otpDetails.analytics.triggerFormData('Datos Confirmación otp', `Envio KO${data.operationErrorMessage}`);
        return
      }
      this.otpDetails.otpSentCount = 0;
      this.otpDetails.otpId = data.id;
      this.otpDetails.loading = false;
    }, error => {
      this.otpDetails.analytics.triggerFormData('Datos Confirmación otp', `Envio KO: Servicio caído`);
      this.otpDetails.loading = false;
    });
  }

  onResendSMS(event) {
    this.sendSmsOTP();
  }


  onSendSMS(event) {
    this.otpDetails.analytics.triggerInteractionData('Enviar SMS');
    this.otpDetails.loading = true;
    if (this.otpDetails.temporalMobilePhone !== this.paramsSMS.telephone){
      this.authService.updateProspect(this.getNewMobilePhone()).subscribe(data => {
        this.otpDetails.loading = false;
        if (data.response.codigo !== 'OK') {
          const modalToShow = this.modService.selectStatusModal(data.response);
          this.otpDetails.modal = modalToShow;
          this.otpDetails.analytics.triggerFormData('Datos Confirmación otp', `Envio KO: ${this.otpDetails.modal.message}`);
          this.otpService.openModal(true);
        }else {
          this.sendSmsOTP(this.otpDetails.temporalMobilePhone);
        }
      }, error => {
        if (error.status == 401)
          this.error.navToError();
        this.otpDetails.analytics.triggerFormData('Datos Confirmación otp', `Envio KO: Fallo en Sms`);
        this.otpDetails.modal.title = '¡Ups!';
        this.otpDetails.modal.withImage = false;
        this.otpDetails.modal.blocking = false;
        this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
        this.otpService.openModal(false);
        this.otpDetails.loading = false;
      });
    }else{
      this.sendSmsOTP(this.otpDetails.temporalMobilePhone);
    }
  }


  onSendCode(event) {
    const params:ValidateOtp = event;

    if (typeof params.code === 'undefined') {
      this.otpDetails.modal.title = '¡Ups!';
      this.otpDetails.modal.blocking = false;
      this.otpDetails.modal.withImage = false;
      this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
      this.otpService.openModal(false);
      return;
    }
    this.otpService.validateOTP(params).subscribe(data => {

      if (!data.validated) {
        this.otpDetails.otpSentCount ++;
        this.otpDetails.modal.title = '¡Ups!';
        this.otpDetails.analytics.triggerFormData('Datos Confirmación otp', `Envio KO: ${data['operationErrorMessage']}`);
        this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
        this.otpDetails.modal.withImage = false;
        this.otpService.openModal(false);

      } else {
        this.authService.validatedOtp = data.validated;
        this.otpDetails.analytics.triggerFormData('Datos Confirmación otp', `Envio OK`);
        this.helpers.navigateTo('metodo-validacion');
      }
    }, error => {
      if (error.status == 401)
        this.error.navToError();

      this.otpDetails.analytics.triggerFormData('Datos Confirmación otp', `Envio KO: 500 Server error`);
      this.otpDetails.modal.title = '¡Ups!';
      this.otpDetails.modal.withImage = false;
      this.otpDetails.modal.blocking = false;
      this.otpDetails.modal.message = 'Parece que ha habido un error con el envío del SMS. Comprueba que el teléfono o el código que nos has indicado es el correcto e inténtalo de nuevo.';
      this.otpService.openModal(false);
    });

  }

  getNewMobilePhone() {
    const canal = this.deviceService.getDevice();
    return {
      canal: canal,
      codigoEntidad : '0239',
      datosPersonales: {
        oficinaCliente: environment.oficina,
        telefonoMovil : this.otpDetails.temporalMobilePhone
      }
    };
  }

}
