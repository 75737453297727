<div  class="dropdown-group" 
      [ngClass]="classAux" 
      (click)="btnClick($event)">
  <button   type="button" 
            [ngClass]="{'dropdown-white': buttonClass, 'open': show ,  'error-border': !isCorrect }" 
            class="dropdown-toggle"
            [title]="_selectName" 
            role="buttom" 
            aria-expanded="false" 
            [attr.disabled]="_disabled?'':null">
    <span  class="filter-option"
           [ngClass]='_selectClass'>
      {{_selectName && _selectName.length > 30 && limitNames? _selectName.substr(0,30)+'...': _selectName }}
    </span>
    <span  class="caret" 
           [ngClass]="show?'up':''" 
           *ngIf="!_autocomplete"></span>
    <span  class="evo info" 
           *ngIf="_autocomplete"
            user-tooltip 
            [specificClass]="'autocomplete'"
            [tooltip]="tooltip"></span>
  </button>
  <div class="dropdown-menu" [ngClass]="show? 'open': ''" [class]="isUp?'up':''" role="combobox" [ngStyle]="{'max-height': maxHeight}">
    <input type="text" class="autocomplete" #autocomplete [placeholder]="placeholder" name="autocomplete" *ngIf="_autocomplete" (keyup)="filterOptions($event)">
    <ul class="inner" role="listbox" aria-expanded="false">
      <li *ngFor="let option of optiontreated | autoFilter:_keyup;">
        <a role="option"
        id='{{option.name}}'
        [ngClass]='option.value === null? "placeholder" : ""'
        (click)="selectItem(option)">
          <span>
            {{option.name}}
          </span>
        </a>
      </li>
    </ul>
  </div>
  <select [id]="id" [name]="name" class="selectpicker" tabindex="-398">
    <option *ngFor="let option of optiontreated" [value]="option.value" [attr.aria-selected]="option.selected === true?true:false"
      [attr.checked]="option.selected === true?true:false">{{option.name}}</option>
  </select>
</div>
