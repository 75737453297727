<mobile-error-zone
*ngIf="errService.errorsArray.length > 0"
[errors]="errService.errorsArray">
</mobile-error-zone>
<loading [showMe]="loading"></loading>

<modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal>

<modal [modalId]='alreadyClientModal' [blocking]='true' class='modal modal-login'>
  <mobile-error-zone 
    *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray"
    class="custom-erros">
  </mobile-error-zone>
  <div class="container">
    <h2>¿Ya eres cliente?</h2>
    <p>Accede con tus datos a tu área personal.</p>
    <p *ngIf="errMsg" class="err-login">
      {{errMsg}}
    </p>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginUser" (ngSubmit)="sendClient()" novalidate>
      <div class="equal-buttons">
        <input  placeholder="Usuario (NIF/NIE)"
        autocomplete="off"
        type="text" name="user"
        formControlName="user"
        maxlength="9"
        (focus)="closeKeyboard()"
        (blur)="validUser()">
        <div class="error-text" *ngIf="errService.desktopErrors(otpDetailsForm.controls.user, 'user')">
          {{errService.desktopErrors(otpDetailsForm.controls.user, 'user')}}
        </div>
        <input keyboard placeholder="Contraseña" type="password"
        autocomplete="off"
        name="pass" formControlName="pass" (numberEmmited)="numberEmmited($event)"
        (deleteLast)="deleteLast($event)" onfocus="blur()">
        <div class="error-text" *ngIf="errService.desktopErrors(otpDetailsForm.controls.pass, 'pass')">
          {{errService.desktopErrors(otpDetailsForm.controls.pass, 'pass')}}
        </div>
      </div>
      <div class="row">
        <button class="btn-common btn-common_fix_bold" type="submit" (click)="closeKeyboard()">
          <span>Enviar</span>
        </button>
      </div>
    </form>
    <div class="row">
      <p class = 'label'>
        ¿No tienes claves para entrar? Llámanos al <a class="link-blue" href="tel:910900900">910 900 900</a> y te ayudaremos.
      </p>
    </div>
  </div>
</modal>


<div class="otpDetails" [ngClass]="isLogged ? 'logged' : ''">
  <div class="image" [ngClass]="{ 'young' : isYoungAccount }"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>VALIDA TU NÚMERO DE TELÉFONO</h2>
        <p>Mira tu móvil. Acabamos de enviarte un código de seguridad para verificarlo.</p>
      </legend>
    </div>

  <form  [formGroup]="otpDetailsForm" (ngSubmit)="onSubmit()"
    (keydown.enter)="$event.preventDefault()"
    novalidate>
    <div class="row">
      <div class="mobile-container">
        <div [class]="setClassKeyPhone()">
          <input type="tel" id="key-phone" name="key-phone" required
          #keyphone
          number-input
          [class]="setClassCleanPhone(keyphone)"
          [minLength]="maxlengthOTP != null ? maxlengthOTP: 6"
          [maxLength]="maxlengthOTP != null ? maxlengthOTP: 6"
          autocomplete="off"
          inputmode="numeric"
          formControlName="smsphone"
          placeholder="Código SMS">
          <div class="error-text" *ngIf="errService.desktopErrors(otpDetailsForm.controls.smsphone, 'smsphone')">
            {{errService.desktopErrors(otpDetailsForm.controls.smsphone, 'smsphone')}}
          </div>
        </div>

        <span class="smsoff">Si no recibes el código pasados unos minutos comprueba que el número es correcto y vuelve a enviarlo. </span>
        <div class="container-phone">
          <div *ngIf="showDatePhones()" class="phone-links">
            <span>Tu móvil</span>
            <span>{{ setPhoneEncoded(temporalMobilePhone || getPhone()) }}</span>
            <div class="group-link" *ngIf="allowEditPhone">
              <a class="link-phone" (click)="showInputPhone">EDITAR TELÉFONO</a>
            </div>
            <div class="group-link">
              <a class="link-phone" [attr.disabled]="getPhone()?null:''" (click)="resendSMS()">VOLVER A ENVIAR CÓDIGO SMS</a>
            </div>
          </div>
          <div class="group-input" *ngIf="hidephone">
            <div class="mobile-container">
              <div class="phone"
              [class]="!otpDetailsForm.controls.mobilePhone.valid && !otpDetailsForm.controls.mobilePhone.untouched ? 'error' : 'phone'">
              <input type="tel" id="phone" name="phone" required
                class="phone"
                number-input
                (paste)='validatePhone($event)'
                [class]="!otpDetailsForm.controls.mobilePhone.valid && !otpDetailsForm.controls.mobilePhone.untouched ? 'error' : 'clean'"
                [minLength]="9"
                [maxLength]="9"
                autocomplete="off"
                inputmode="numeric"
                formControlName="mobilePhone"
                placeholder="Introduce tu móvil">
                <div class="error-text" *ngIf="errService.desktopErrors(otpDetailsForm.controls.mobilePhone, 'mobilePhone')">
                  {{errService.desktopErrors(otpDetailsForm.controls.mobilePhone, 'mobilePhone')}}
                </div>
              </div>
            </div>
            <div class="mobile-container">
              <button type="button" class="btn-common btn-common_fix_bold" [disabled]="!otpDetailsForm.controls.mobilePhone.valid" (click)="sendSMS()">ENVIAR SMS</button>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-container">
        <button type="button" class="btn-common btn-common_fix_bold" [disabled]="!otpDetailsForm.controls.smsphone.valid" (click)="sendCode()">VALIDAR</button>
      </div>
    </div>
  </form>

   <!-- formulario valido : {{otpDetailsForm.valid}}
  {{otpDetailsForm.value | json}} -->
  </div>
</div>
