import { Component, OnInit } from '@angular/core';
import { HelperService } from 'app/services/helpers.service';
import { LoginService } from 'app/services/login.service';
import { MyProductsService } from 'app/services/my-products.service';
import { MyInterestService } from 'app/services/my-interest.service';
import { ACCOUNTS } from 'app/resources/account.model';


@Component({
  selector: 'app-my-products',
  templateUrl: './my-products.component.html',
  styleUrls: ['./my-products.component.scss']
})
export class MyProductsComponent implements OnInit {

  public slides: Array<any> = [];
  public photoInterval = 5000;
  public noLoopSlides = false;
  public userData;
  public listPolicies;
  productosContracts;
  public productStyle: string = '';
  breadcrumbNav = [{ name: 'INICIO', link: '/perfil' }, { name: 'MIS PRODUCTOS', link: '/mis-productos' }];
  fullName;

  constructor(private helpers: HelperService,
              public loginSrv: LoginService,
              public myProductServ: MyProductsService,
              private myInterestSrv: MyInterestService) { }

  ngOnInit() {
    this.userData = this.loginSrv.userData;
    this.addNewSlides();
     this.listPolicies = this.loginSrv.listPolicies;
    if (this.userData != null) {
      this.fullName = this.userData.persona.nombreCliente + " " + this.userData.persona.apellido1Cliente + " " + this.userData.persona.apellido2Cliente;
      this.productosContracts = this.userData.listaContratados.filter(data => {
        return !!data.nombreProducto
      });
    }

    this.myProductServ.managePaintMicroinsurance(this.listPolicies, this.productosContracts);
  }

  public productClass() {
   if (this.productosContracts.length > 3) {
     return 'small';
   } else if (this.productosContracts.length < 2) {
     return 'center';
   }
  }

  private addNewSlides() {
    this.slides = [
      { image: './assets/images/profile/profile-extras/inversion.jpg', title: 'Plan de inversión inteligente',text: '¡Invierte mientras ahorras!', goTo: 'inversion' },
      { image: './assets/images/profile/profile-extras/travel.jpg', title: 'Evo travel cash',text: 'Te llevamos tu moneda extranjera a casa.', goTo: 'travel' },
      { image: './assets/images/profile/profile-extras/laboratorio.jpg', title: 'Laboratorio de ideas',text: 'Lo más importante es tu opinión', goTo: 'laboratorio' }
    ];
    // devuelve la lista de los que puede contratar
    if (this.loginSrv.listPolicies == null && this.helpers.isAdultNot65Years(this.userData.persona.fechaNacimiento)) {
      this.slides.unshift({ image: './assets/images/profile/profile-extras/EVO_protect.png', title: 'Evo protect deportes',text: '', goTo: 'evoProtect' });
    }
    const slidesToContract = this.myInterestSrv.prepareSlides(this.userData.listaParaContratar, this.loginSrv.listPolicies);
    this.slides.push(...slidesToContract);

     // borrar elemento del slide que sea de segundo titular
     for(let i = 0; i < this.slides.length; i++){
      if (this.slides[i].idProducto === ACCOUNTS.STCode) {
        this.slides.splice(i,1);
      }
    }
  }

  goToCancelAdhesion() {
    this.helpers.navigateTo("/evo-protect/boletin-adhesion");
  }

}
