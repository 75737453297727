<div *ngIf="_showMe" class="holaDiv" [ngClass]="_showMe? '': 'hideLoading'">
  <div *ngIf="showHeader" class="fixed-wrapper">
    <header-profile></header-profile>
  </div>
  <div class="cmessage" [ngClass]="showHeader? '': 'cmessage-bmi'">
    <div class="cmessage__container">
      <div class="cmessage__info">
        <div class="cmessage__titulo">
          {{titulo}}</div>
        <img class="cmessage__imagen" src="{{imagen}}">
        <div class="cmessage__subtitulo">
          <p class="cmessage__subtitulo__description">{{subtitulo}}</p>
        </div>
      </div>
      <div class="btn-group_fix">
        <!--<button type="submit" class="btn-common btn-common_fix btn-common_fix_bold" (click)="goToLogin()">Entendido</button>-->
      </div>
    </div>
  </div>
  <app-footer-center *ngIf="showFooter"></app-footer-center>
</div>