import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'autoFilter'
})
export class AutocompletePipe implements PipeTransform {

  transform(items: any[], expre: any): any[] {
    if (!items || !expre) {
      return items;
    }
    // expre = expre.currentTarget.value.toLowerCase();
    return items.filter((item) => {
      return (String(item['name']).toLowerCase()).indexOf(expre) !== -1 ||
        (String(item['value']).toLowerCase()).indexOf(expre) !== -1 ||
        (item['options'] && item['options'].some(elem => String(elem.toLowerCase()).indexOf(expre) !== -1));
    });
  }

}
