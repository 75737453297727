import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { SpecificAnalyticsService } from '../specific-analytics.service';

@Injectable()
export class AuthTokenService {
  token;
  isUnauth: boolean = false;

  constructor(private keycloakService: KeycloakService, private specificAnalyticsSrv: SpecificAnalyticsService) {
  }

  public getToken(): string {
    // Se comprueba si tiene la estructura "Bearer xxxxxxx" y, si no es así, se le añade.
    let token = sessionStorage.getItem('auth_token');
    if (token && token.indexOf("Bearer ") < 0) {
      token = "Bearer " + token;
    }
    return token;
  }
  public isAuthenticated(): boolean {
    this.token = this.getToken();
    return !!this.token;
  }
  public setToken(token) {
    sessionStorage.setItem('auth_token', token);
  }
  public setRefreshToken(refreshToken) {
    sessionStorage.setItem('refresh_token', refreshToken);
  }

  public haveParamsToAutenticateByUuidAndUsuarioBE(params: any){
    return this.validateUrlParam(params, 'uuid') && this.validateUrlParam(params, 'usuarioBE');
  }

  getTokenFromKeycloak() {
    const userInfo = this.keycloakService.getKeycloakInstance().idTokenParsed;
    if (userInfo) {
      const accesToken = this.keycloakService.getKeycloakInstance().idTokenParsed['api_access_token'];
      this.setToken(accesToken ? accesToken : this.keycloakService.getKeycloakInstance().token);
      this.setRefreshToken(userInfo['api_refresh_token']);
      this.specificAnalyticsSrv.emitHashedDni( userInfo['idExterno']);
    }
  }

  private validateUrlParam(params:any, name: string){
    const value = params[name] as string;
    return value && value.trim().length > 0;
  }
  public getUrlParam(params:any, name: string){
    const value = params[name] as string;
    return value;
  }
}
