import { Component } from '@angular/core';

@Component({
  selector: 'logalty-load-slider',
  templateUrl: './logalty-load-slider.component.html',
  styleUrls: ['./logalty-load-slider.component.scss']
})
export class LogaltyLoadSliderComponent {

  

}
