import { Component, OnInit} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-abandonment',
  templateUrl: './abandonment.component.html',
  styleUrls: ['./abandonment.component.scss'],
})
export class AbandonmentComponent implements OnInit {
  abandonmentForm: FormGroup;
  constructor(private router: Router,
              private fb: FormBuilder
  ) {
    this.createForm()
  }

  ngOnInit() {}

  onSubmit() {
    //TODO envio datos de abandono
  }

  createForm() {
    this.abandonmentForm = this.fb.group({
      reason: ['', Validators.required],
      comment: ['']
    })
  }


}
