<loading-custom [showMe]="loadCustom" 
                [titulo]="titulo" 
                [imagen]="imagen"
                [subtitulo]="subtitulo"
                [boton]="boton">
</loading-custom>

<div-show [showMe]="showError" [nameError]="nameError"></div-show>

<mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="errService.errorsArray">
</mobile-error-zone>

<div class="banner banner-todo-ok" *ngIf="showAllOk">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBannerNotifyOk()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo icon-todo-ok"></i>
      </span>
      <span class="banner-message">
        ¡Todo en orden! Tus datos han sido modificados correctamente.
      </span>
    </div>
  </div>
</div>

<div class="banner banner-dni-incorrecto" *ngIf="false">
  <div class="banner-content">
    <div class="evo close banner-close">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <img src="./assets/images/ico-warning-white.svg" height="28px">
      </span>
      <span class="banner-message">
        ¡Vaya! Tenemos tu documento de identificación caducado, necesitamos que lo actualices.
      </span>
    </div>
  </div>
</div>

<div class="banner banner-error-formato" *ngIf="incorrectFormatBanner && !hiddeBanner">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBanner()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo ico-warning-white" style="font-size: 28px;"></i>
      </span>
      <span class="banner-message">
        {{incorrectText}}
      </span>
    </div>
  </div>
</div>

<div class="banner banner-validando-solicitud" *ngIf="showWarningValidating && !hiddeBanner">
  <div class="banner-content">
    <div class="evo close banner-close" (click)="hideBanner()">
    </div>
    <div class="message-container">
      <span class="banner-icon">
        <i class="evo ic-reloj" style="font-size: 28px;"></i>
      </span>
      <span class="banner-message">
        Estamos validando tu petición.
      </span>
    </div>
  </div>
</div>


<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='modalSrvError' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">{{msgSrvError}}</p>
  </div>
</modal>

<modal [modalTitle]="'documentDetail'" [blocking]='false' [modalId]='detailModal'>
  <div class="container">
    <div class="center-image">
      <img *ngIf='image[documentSide]' src="{{image[documentSide]}}" alt="">
    </div>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]='friendlyModal' class='modal'>
  <div class="image-modal"></div>
  <div class="false-container">
    <h2>{{friendlyTitle}}</h2>
    <p>{{friendlyMessage}}</p>
  </div>
</modal>

<modal [modalTitle]="'Modal srv-error'" [blocking]='false' [modalId]='incorrectFormatModal' class='modal'>
  <div class="container">
    <h2>{{incorrectTitle}}</h2>
    <p class="invalid-text">{{incorrectText}}</p>
  </div>
</modal>

<modal [modalTitle]="'Guías para sacar las fotos de forma correcta:'" [blocking]='false' [modalId]='modalDniSpecs' class="modal-fullscreen">
  <div class="list-container">
    <div class="step-box">
      <div class="step-box-img">
        <span>
          <i class="evo ico-encuadre"></i>
        </span>
      </div>
      <div class="step-box-description">
        <p class="header-2">
          Encuadre y fondo
        </p>
        <p>
          Fondo liso, sin objetos ni fondo. Evita cortar información del documento.
        </p>
      </div>
    </div>
    <div class="step-box">
      <div class="step-box-img">
        <span>
          <i class="evo ico-iluminacion"></i>
        </span>
      </div>
      <div class="step-box-description">
        <p class="header-2">
          Iluminación
        </p>
        <p>
          Evita brillos y desactiva el flash de tu teléfono.
        </p>
      </div>
    </div>
    <div class="step-box">
      <div class="step-box-img">
        <span>
          <i class="evo ico-nitidez"></i>
        </span>
      </div>
      <div class="step-box-description">
        <p class="header-2">
          Nitidez y calidad
        </p>
        <p>
          Foto no borrosa, no menor a 2 megapixeles ni mayor de 15MB.
        </p>
      </div>
    </div>
    <div class="step-box">
      <div class="step-box-img">
        <span>
          <i class="evo ico-doc-original"></i>
        </span>
      </div>
      <div class="step-box-description">
        <p class="header-2">
          Documento original
        </p>
        <p>
            Foto de DNI o NIE orginal, no fotocopias o capturas de pantalla (screenshots).
        </p>
      </div>
    </div>
    </div>
  <div class="btn-group">
    <button (click)='closeModal()' type="submit" class="btn-common btn-common_fix_bold">ENTENDIDO</button>
  </div>
</modal>

<modal [modalTitle]="'Excepcion caducado'" [blocking]='false' [modalId]='exceptionModal' class='modal not-adult exception-modal'>
  <h2>{{exceptionTitle}}</h2>
  <p>
    {{exceptionText}}
  </p>
</modal>

<modal [modalTitle]="'Documento'" [blocking]='false' [modalId]='incompleteModal'>
  <div class="container">
    <h2>Ups!</h2>
    <p class='invalid-text'>
      Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.
    </p>
  </div>
</modal>


<!-- <modal [modalId]='modal_problems' class='modal'>
  <div class="image-modal"></div>
  <div class="false-container">
    <h2>Ups!</h2>
    <p>
    Ahora mismo no podemos identificarte.Inténtalo con la videoidentificación o facilitándonos tu IBAN. O si lo que prefieres es continuar más tarde, te enviaremos un email para que puedas continuar donde lo dejaste.
    </p>
    <span class="secondary-text">Llámanos al 910 900 900</span>
    <div class="button-center">
      <div class="equal-buttons">
        <div  class="access" name="button1">
          <a href="http://www.evobanco.com/donde-estamos/" target="_blank">
            <span>PROBAR CON VIDEOIDENTIFACIÓN</span>
          </a>
        </div>
        <div  class="access" name="button1">
          <a href="http://www.evobanco.com/donde-estamos/" target="_blank">
            <span>PROBAR CON IBAN</span>
          </a>
        </div>
      </div>
      <p>Continuar más tarde</p>
    </div>
  </div>
</modal> -->

<modal [modalTitle]="''" [blocking]='modalBlock' [modalId]='modal_ko' class='modal'>
  <div class="container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <div class="equal-buttons">
      <div class="access center" name="continuar" (click)="tryAgain()">
        <span>{{link | uppercase}}</span>
      </div>
    </div>
  </div>
</modal>

<modal [modalTitle]="''" [blocking]='modalBlock' [modalId]='modal_ok_dni' class='modal'>
  <div class="container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <div class="equal-buttons">
      <div class="access center" name="Continuar" (click)="nextStepDni()">
        <span>{{link | uppercase}}</span>
      </div>
    </div>
  </div>
</modal>

<modal [modalTitle]="''" [blocking]='modalBlock' [modalId]='modal_ok' class='modal'>
  <div class="image-modal"></div>
  <div class="false-container">
    <h2>{{title | uppercase}}</h2>
    <p>{{msg}}</p>
    <div class="equal-buttons">
      <div class="access center" name="continuar" (click)="goToProfile()">
        <span>{{link | uppercase}}</span>
      </div>
    </div>
  </div>
</modal>

<section *ngIf="!loadCustom && !isBmi" class="responsive-header">
  <div *ngIf="!isContratacion" (click)="botonVolver()">
    <span class="backbutton" *ngIf="btnShow">
      <i class="evo flecha" height="28px"></i>
    </span>
  </div>
  <div class="page-title" >
    <span>Mis datos</span>
  </div>
</section>

  <div *ngIf="!loadCustom" class="identifyIban" drag (filesChangeEmitter)="onFileParent($event)">


    <div class="image"></div>
    <div class="container">
      <div>
        <div>
          <p class="subheader">
            ¡HEY! TENEMOS TU DNI/NIE CADUCADO
          </p>
          <p class="description">
            Asegúrate que la foto sea del documento original y se vea correctamente.
          </p>
          <p *ngIf='showSpecText' class="specif-img">
            Especificaciones de las imágenes
          </p>
        </div>
        <div class="row info step-container">
        <p class="flex-text dni-steps" (click)='showSpecs()'>
          <span class="">
            <i class="evo ico-info-blue"></i>
          </span>
            Ver especificaciones de las imágenes
          <span class="frontbutton">
            <i class="evo flecha-blue-right bold-icon"></i>
          </span>
        </p>
        <div class="step-box">
          <span>
            <i class="evo ico-encuadre"></i>
          </span>
          <p class="header-2">
              Encuadre y fondo
            </p>
          <p>
              Fondo liso, sin objetos ni fondo. Evita cortar información del documento
            </p>
          </div>
          <div class="step-box">
          <span>
            <i class="evo ico-iluminacion"></i>
          </span>
          <p class="header-2">
              Iluminación
            </p>
          <p>
              Evita brillos y desactiva el flash de tu cámara
            </p>
          </div>
          <div class="step-box">
          <span>
            <i class="evo ico-nitidez"></i>
          </span>
          <p class="header-2">
              Nitidez y calidad
            </p>
          <p>
              Foto no borrosa, no menor a 2 megapíxeles ni mayor de 15MB
            </p>
          </div>
          <div class="step-box">
          <span>
            <i class="evo ico-doc-original"></i>
          </span>
          <p class="header-2">
              Documento original
            </p>
          <p>
              Foto de DNI o NIE orginal, no fotocopias o capturas de pantalla
            </p>
          </div>
        </div>

        <div class="upload-container">
          <div class="upload-box">
            <p class="header-2 title-part">
              Parte Frontal
            </p>
            <div *ngIf='upload.front' class="row upload">
              <div class="image-container" (click)="openDetail(); documentSide = 'front'">
                <img class="preview" *ngIf='image.front' src="{{image.front}}" alt="">
              </div>
              <img class="lupa" src="./assets/images/lupa.png" alt="" (click)="openDetail(); documentSide = 'front'">
              <div class="flex-edit">
                <label for="editFront" class="edit-back">
                <p (click)='resetFrontImage()' class="uppercase-info">
                    Volver a subir
                  </p>
                  <input class="transparent" (change)='updateFrontImage($event)' type="file" id="editFront" #back/>
                </label>
              </div>
            </div>
            <div *ngIf='!upload.front' class="row">
              <label for="frontFile" class="dni-upload-width">
                <div drag id="front" (filesChangeEmitter)="onFilesChange($event)" class='draggable'>
                  <div class="drag-container">
                    <input class="transparent" (change)='updateFrontImage($event)' type="file" id="frontFile" #front/>
                    <i class="evo subir-foto" style="font-size: 50px;"></i>
                    <p>{{uploadImageFirstOptionText}}</p>
                    <p>{{uploadImageSecondOptionText}}</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="upload-box">
            <p class="header-2 title-part">
              Parte Trasera
            </p>
            <div *ngIf='upload.back' class="row upload">
              <div class="image-container" (click)="openDetail(); documentSide = 'back'">
                <img class="preview" *ngIf='image.back' src="{{image.back}}" alt="">
              </div>
              <img class="lupa" src="./assets/images/lupa.png" alt="" (click)="openDetail(); documentSide = 'back'">
              <div class="flex-edit">
                <label for="editBack" class="edit-back">
                <p (click)='resetBackImage()' class="uppercase-info">
                    Volver a subir
                  </p>
                  <input class="transparent" (change)='updateBackImage($event)' type="file" id="editBack" #back/>
                </label>
              </div>
            </div>
            <div *ngIf='!upload.back' class="row">
              <label for="backFile" class="dni-upload-width">
                <div drag id='back' (filesChangeEmitter)="onFilesChange($event)" class='draggable'>
                  <div class="drag-container">
                    <input class="transparent" (change)='updateBackImage($event)' type="file" id="backFile" #back/>
                    <i class="evo subir-foto" style="font-size: 50px;"></i>
                    <p>{{uploadImageFirstOptionText}}</p>
                    <p>{{uploadImageSecondOptionText}}</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>


      </div>
    <div class="btn-group">
      <button (click)='onSubmit()' [disabled]="!(upload.front && upload.back)" type="submit" class="btn-common continuebutton btn-common_fix_bold">CONTINUAR</button>
      </div>
      <!-- formulario valido : {{identifyDetailsForm.valid}}
    {{identifyDetailsForm.value | json}} -->
    </div>
  </div>
