<modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
    <p>{{telf}}</p>
    <button class="btn-common letter-white btn-common_fix_bold" (click)="toProfile()">
      {{txtButtonModal}}
    </button>
  </div>
</modal>

<header-profile></header-profile>
<div class="simulador">
  <div class="image thin-border"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <h2>CONTRATACIÓN DE FONDO INTELIGENTE</h2>
        <p></p>
      </legend>
    </div>
    <div class="main-content">
      <div class="col-8">
        <div class="row info">
          <div>
            <h3>¿Tienes obligaciones tributarias fuera de España?</h3>
            <p>Si tienes obligaciones tributarias fuera de España....</p>
          </div>
        </div>
        <div>
            <button class="btn-common btn-common_fix_bold" (click)="toCancel()">
              SI
            </button>
            <button class="btn-common btn-common_fix_bold" (click)="toContinue()">
              NO
            </button>
        </div>
      </div>
    </div>
    
  </div>
</div>
