import { ErrorType } from '../collections/error-type';

export var ERRORES: ErrorType[] = [

    {field: 'adquisicion', text: 'Introduce el año de adquisición válido', code: 100},
    {field: 'alquilerVivienda', text: 'El importe de la renta mensual no es válido', code: 100},
    {field: 'bloque', text: 'Introduce tu bloque', code: 100},
    {field: 'calle', text: 'Introduce tu calle', code: 100},
    {field: 'ciudad', text: 'Introduce tu localidad de residencia', code: 100},
    {field: 'cnae', text: 'Selecciona tu actividad económica', code: 100},
    {field: 'codigoPostal', text: 'Introduce tu código postal', code: 100},
    {field: 'direccion', text: 'Introduce una dirección y número válidos', code: 100},
    {field: 'dni', text: 'Este formato de DNI no es válido', code: 100},
    {field: 'duracionTrabajo', text: 'Introduce un número', code: 100},
    {field: 'escalera', text: 'Introduce tu escalera', code: 100},
    {field: 'estadoCivil', text: 'Selecciona tu estado civil', code: 100},
    {field: 'fase', text: 'Introduce tu fase', code: 100},
    {field: 'fechaAltaAutonomo', text: 'Introduce una fecha de alta autónomo válida', code: 100},
    {field: 'fechaAltaAlquiler', text: 'Introduce una fecha de alta alquiler válida', code: 100},
    {field: 'fechaAntiguedad', text: 'Introduce una fecha de antigüedad válida', code: 100},
    {field: 'formatoMail', text: 'Este formato de email no es válido', code: 100},
    {field: 'ingresosMensuales', text: 'Introduce unos ingresos mensuales válidos', code: 100},
    {field: 'iban', text: 'Introduce un IBAN válido', code: 100},
    {field: 'localidad', text: 'Introduce tu localidad de residencia', code: 100},
    {field: 'localidadnacimiento', text: 'Introduce tu localidad de nacimiento', code: 100},
    {field: 'email', text: 'Introduce un email válido', code: 100},
    {field: 'confirmEmail', text: 'Introduce un email válido', code: 100},
    {field: 'mobilePhone', text: 'Introduce un número de móvil válido', code: 100},
    {field: 'nie', text: 'Introduce un NIE válido', code: 100},
    {field: 'nombreEmpresa', text: 'Este nombre de empresa no es válido', code: 100},
    {field: 'numero', text: 'Introduce un número', code: 100},
    {field: 'numeroPagas', text: 'El número de pagas no es válido', code: 100},
    {field: 'numeroDocumento', text: 'Introduce un número de identificación válido', code: 100},
    {field: 'pais', text: 'Selecciona un país', code: 100},
    {field: 'paisNacimiento', text: 'Debes seleccionar un país de nacimiento', code: 100},
    {field: 'parcela', text: 'Introduce tu parcela', code: 100},
    {field: 'pass', text: 'Introduce tu contraseña', code: 100},
    {field: 'planta', text: 'Introduce tu planta', code: 100},
    {field: 'portal', text: 'Introduce tu portal', code: 100},
    {field: 'profesion', text: 'Introduce una profesión válida', code: 100},
    {field: 'provincia', text: 'Introduce tu provincia', code: 100},
    {field: 'puerta', text: 'Introduce tu puerta', code: 100},
    {field: 'reactiveEmailPhone', text: 'Error! Hay un problema con el número que has introducido.', code: 100},
    {field: 'reactiveEquals', text: 'Los campos tienen que coincidir', code: 100},
    {field: 'regimenEconomico', text: 'Selecciona tu régimen económico', code: 100},
    {field: 'situacionLaboral', text: 'Selecciona una opción', code: 100},
    {field: 'situacionVivienda', text: 'Selecciona una opción', code: 100},
    {field: 'smsphone', text: 'El código no es válido', code: 100},
    {field: 'telefonoMovil', text: 'Introduce un número de móvil válido', code: 100},
    {field: 'tipoActividad', text: 'Selecciona una opción', code: 100},
    {field: 'tipoContrato', text: 'Selecciona una opción', code: 100},
    {field: 'user', text: 'Introduce tu usuario (DNI/NIE)', code: 100},
    {field: 'hours', text: 'Introduce una hora válida', code: 100},
    {field: 'minutes', text: 'Introduce unos minutos válidos', code: 100},
    {field: 'actividad', text: 'Selecciona una actividad', code: 100},
    {field: 'region', text: 'Selecciona una región', code: 100},
    {field: 'date', text: 'Selecciona una fecha', code: 100},
    {field: 'capital', text: 'Selecciona un capital asegurado', code: 100},
    {field: 'duration', text: 'Selecciona una duración', code: 100},
    {field: 'nombre', text: 'Introduce un nombre de beneficiario válido', code: 100},
    {field: 'apellidos', text: 'Introduce unos apellidos de beneficiario válidos', code: 100},
    {field: 'beneficiario', text: 'Selecciona un beneficiario', code: 100},
    {field: 'cuenta', text: 'Selecciona un método de pago', code: 100},
    {field: 'checkLegal', text: 'Debes leer y aceptar las condiciones legales para continuar', code: 100},
    {field: 'nombreCI', text: 'Introduce un nombre válido', code: 100},
    {field: 'primerApellido', text: 'Introduce un primer apellido válido', code: 100},
    {field: 'segundoApellido', text: 'Introduce un segundo apellido válido', code: 100},
    {field: 'password', text: 'Introduce una contraseña válida', code: 100},
    {field: 'confirmPassword', text: 'Las contraseñas no coinciden', code: 100},
];
