<div class="divContainerMain">
    <div class="calloutUp" [style.marginLeft.px]="marginLeft">
        <div class="calloutUp2"></div>
    </div>
    <div class="divContainerUp">
        <ul class="square-bullet">
            <li class="not-margin"><span>Muerte por accidente: {{ valorFallecimiento }}</span></li>
            <li class="not-margin"><span>Invalidez Permanente Parcial por accidente: {{ valorInvalidezParcial }}</span></li>
            <li class="not-margin"><span>Invalidez Absoluta y Permanente por accidente: {{ valorInvalidezAbsoluta }}</span></li>
        </ul>
    </div>      
</div>

