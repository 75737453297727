import { Component, ViewChild, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ElementScrollService } from 'app/services/element-scroll.service'
import { HelperService } from 'app/services/helpers.service';
import { DeviceService } from 'app/services/device.service';

@Component({
    selector: 'pdf-viewer-evo',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
    providers: [PdfViewerModule,
        ElementScrollService]
})
export class PdfViewerComponent implements OnInit {

  @ViewChild('pdfContainer') private pdfContainer: ElementRef;

  @Input() title: string;
  @Input() button: string;
  @Input() pdfSrc: string;

  public innerScroll: number;
  public pageScroll: number;
  public closeButtonDisabled = true;
  public zoom = 1.0;
  public currentDocument: string = sessionStorage.getItem('currentDocument') || '';
  public alreadyReaded: boolean = sessionStorage.getItem(this.currentDocument) ? sessionStorage.getItem(this.currentDocument) === 'true' : false;
  public isBmi = false;
  public isDesktop = true;

  constructor(public elementScrollService: ElementScrollService,
    private helpers: HelperService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private deviceSrv: DeviceService) {
    this.innerScroll = 0;
    this.pageScroll = 0;
    this.closeButtonDisabled = true;
    this.zoom = 1.0;
  }

  ngOnInit() {
    this.isDesktop = !this.deviceSrv.isMobileOrTablet();
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.origen && params.origen.toLowerCase() === 'bmi') {
        this.isBmi = true;
      }
    });
  }

  afterLoadComplete(pdfData: any) {

    const noScrollWithOnePage = pdfData.numPages === 1;

    if (this.alreadyReaded || noScrollWithOnePage) {
      this.closeButtonDisabled = false;
    } else {
      this.elementScrollService
        // Obtiene el scroll del pdf-viewer, sin parámetros tomaría el scroll del window
        .getScrollAsStream(this.pdfContainer.nativeElement.children[1])
        .subscribe(
          (percent: number): void => {
            this.pageScroll = percent;
            if (this.pageScroll > 97.00) {
              this.closeButtonDisabled = false;
              this.alreadyReaded = true;
            } else if (!this.alreadyReaded) {
              this.closeButtonDisabled = true;
            }
          }
        );
    }
  }

  close() {
    this.closeButtonDisabled = true;
    this.alreadyReaded = false;
    const currentDocument = sessionStorage.getItem('currentDocument');
    sessionStorage.setItem(currentDocument, 'true');
    this.helpers.navigateTo('visor-cuenta-inversora');
  }

  zoomIn() {
    this.zoom = this.zoom + 0.25;
  }

  zoomOut() {
    if (this.zoom > 1) {
      this.zoom = this.zoom - 0.25;
    }
  }

}
