import { Component, HostListener, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { ErrorService } from '../../../services/error.service';
import { DeviceService } from '../../../services/device.service';
import { ModalService } from '../../../services/modal.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HelperService } from '../../../services/helpers.service';
import { InjectScriptService } from '../../../services/inject-script.service';


@Component({
  selector: 'info-my-profile',
  templateUrl: './info-my-profile.component.html',
  styleUrls: ['./info-my-profile.component.scss'],
  providers : [ ModalService,
                DeviceService ]
})
export class InfoMyProfileComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  public firstTouch: boolean = true;
  public firstTouchPrevented: boolean = true;
  public isYoungAccount: boolean = false;

  @HostListener('window:beforeunload', ['$event'])
  preventAbandon($event) {
    $event.returnValue = '¿Estás seguro?'
    return $event.returnValue;
  }
  constructor(private fb: FormBuilder,
    public errService: ErrorService,
    public modService: ModalService,
    public deviceService: DeviceService,
    public analytics: AnalyticsService,
    private helpers: HelperService,
    private inject: InjectScriptService) {}

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  goToMyProfile() {
    this.helpers.navigateTo('/mi-perfil');
  }

}
