import { Component, OnInit, ElementRef, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { ErrorService } from 'app/services/error.service';
import { HelperService } from 'app/services/helpers.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CAPITALS } from 'app/resources/capital.model';
import { DURATIONS } from 'app/resources/duration.model';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { Sport } from 'app/collections/sports';
import { CustomRadioButton } from 'app/collections/custom-radio-button';
import { AuthGuardMicro } from 'app/services/auth-guard-micro.service';
import { REGIONS } from 'app/resources/region.model';
import { SPORTS } from 'app/resources/sports.model';
import { Simulador } from 'app/collections/simulador';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { Tarification, TarificationResponse } from 'app/collections/tarification';
import { InputModalComponent } from 'app/modules/profile/microinsurances/components/input-modal/input-modal.component';
import { zip } from 'rxjs/observable/zip';
import { MicrosegConfigResponse, MicrosegurosConfigCapitales } from 'app/collections/microseg-config';
import { Region } from 'app/collections/region';
import { HelpersMicroinsurancesService } from 'app/services/helpers-microinsurances.service';
import { AfterViewInit } from '@angular/core';
import { LoginService } from 'app/services/login.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { TIME_ZONES } from 'app/resources/timezones.model';
import { AuthService } from 'app/services/auth.service';
import { AnalyticsService } from 'app/services/analytics.service';

@Component({
  selector: 'app-configure',
  animations: [
    trigger('shrinkOut', [
      transition(':enter', [
        style({ height: 0, overflow: 'hidden', opacity: 0 }),
        animate('.5s ease-in-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', overflow: 'hidden', opacity: 1 }),
        animate('.5s ease-in-out', style({ height: 0, opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './configure.component.html',
  styleUrls: ['./configure.component.scss'],
  providers: [ModalService]

})
export class ConfigureComponent implements OnInit {

  private position: number;
  private valueDead = "";
  private valueInvalidParcial = "";
  private valueInvalidAbsolute = "";
  private positionInit = 0;

  public sports = [];
  public regions = []
  public capitals = [];
  public capitalsGet = [];
  public durations = [];
  public timezones = TIME_ZONES;

  public selectedActivity = { id: '', value: '' };
  public selectedRegion = { id: '', value: '', description: '' };
  public selectedDate = { value: undefined, date: null, time: 0 };
  public selectedTimeZone = { id: '', value: '', description: '' };
  public selectedCapital = false;
  public selectedDuration = false;

  private date: Date;
  private dateEndActivity = {
    date: null,
    dateText: ''
  };

  private settingsMicroSession: Simulador = this.authService.getMicroSettingsSession();
  private userData = this.loginService.userData;
  private listpolicies = this.loginService.listPolicies;

  public modalCoverage = "modal-covergage";
  public modalError = 'modal-srv-error';
  public messageError = "";
  private msgErrorActividad = "Revisa que hayas seleccionado una actividad.";
  private msgErrorRegion = "Revisa que hayas seleccionado un región para la actividad.";
  private msgErrorDate = "Revisa que hayas seleccionado una fecha y hora de inicio.";
  private msgErrorTimeZone = "Revisa que hayas seleccionado una zona horaria";
  private msgErrorDuracion = "Revisa que hayas seleccionado la duración de la actividad.";
  private msgErrorCapital = "Revisa que hayas seleccionado el capital asegurado en caso de accidente de la actividad.";
  private msgErrorTarificacion = "Revisa que se haya calculado correctamente el precio total de tu EVO Protect.";
  public messageErrorGeneric = 'Error genérico, vuelva a intentarlo o contacte con el responsable.';
  public messageErrorScopeNotValid = 'Ambito no válido, valores correctos: 1 o 2.';
  public messageErrorCalculation = 'Error en el cálculo';

  public configureForm: FormGroup;
  public loading = false;

  public totalPrice = 0;
  public pricePerDuration = {
    label: '',
    value: 0
  };
  public coverageHeath = 0;
  public tarificationShow = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculatePositionInit();
  }

  constructor(private elRef: ElementRef,
    private fb: FormBuilder,
    private modService: ModalService,
    private cdRef: ChangeDetectorRef,
    private authService: AuthMicroService,
    private helpers: HelperService,
    private helpersMicro: HelpersMicroinsurancesService,
    private authguard: AuthGuardMicro,
    private confService: MicrosegConfigService,
    private loginService: LoginService,
    public errService: ErrorService,
    private analytics: AnalyticsService
  ) {

    this.createForm();
  }


  private createForm() {
    this.configureForm = this.fb.group({
      actividad: ['', [Validators.required]],
      region: ['', [Validators.required]],
      date: ['', [Validators.required]],
      capital: ['', [Validators.required]],
      timezone: ['', [Validators.required]],
      duration: ['', [Validators.required]],
      cobertura: ['0', [Validators.required]]
    });
    this.subscribeToForm();
  }


  ngOnInit() {
    this.errService.mobileErrors(this.configureForm);
    this.loading = true;



    const callStack = zip(
      this.confService.getConfigLists('PDF_DEPORTE1'),        // [0]
      this.confService.getConfigLists('PDF_DURACION'),        // [1]
      this.confService.getConfigLists('PDF_AMBITO'),          // [2]
      this.confService.getConfigCapitales(),                  // [3]
    );
    const subscriptions = callStack.subscribe(res => {
      this.fillData(res);
    },
    error => {
      this.loading = false;
      this.errService.navToError();
    });
  }

  fillData(res) {
    const configs: any = this.helpersMicro.fillConfigSettings(res);
    if (configs.error == null) {
      this.sports = configs.DEPORTE1;
      this.durations = configs.DURACION;
      this.regions = configs.AMBITO;
    } else {
      this.messageError = configs.error.message + " " + configs.error.detail;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
      this.openModal(this.modalError);
    }

    const capitalsConfigs: any = this.helpersMicro.fillCapitalSettings(res[3]);
    if (capitalsConfigs.error == null) {
      this.capitals = capitalsConfigs;
      this.setFields();
    } else {
      this.messageError = configs.error.message + " " + configs.error.detail;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
      this.openModal(this.modalError);
    }

    if (this.settingsMicroSession == null || this.configureForm.controls.date.invalid) {
      this.loading = false;
    }
    this.cdRef.detectChanges();
  }


  setFields() {
    // Seleccionamos el GMT + 1 por defecto
    const defaultTimeZone = {
      id: "+01:00",
      value: "Madrid, Berlín, Bruselas, París, …",
      description: "GMT +1"
    };
    this.selectedTimeZone = defaultTimeZone;
    this.helpers.updateFieldValue(this.configureForm, 'timezone', defaultTimeZone);

    if (this.settingsMicroSession != null) {
      const configure = this.settingsMicroSession.configure;
      // Actividad
      const sport = this.getObjectById(configure.actividad, this.sports);
      this.helpers.updateFieldValue(this.configureForm, 'actividad', sport);
      // Región
      const region = this.getObjectById(configure.region, this.regions);
      this.helpers.updateFieldValue(this.configureForm, 'region', region);
      // Huso horario
      const timezone = this.getObjectById(configure.timezone, this.timezones);
      if (timezone) {
        this.helpers.updateFieldValue(this.configureForm, 'timezone', timezone);
      }
      // Fecha
      if (typeof configure.date !== 'undefined' && configure.date.date != null) {
        const dateCurrent = this.add30MinutesToCurrentDate(),
              dateSelected = new Date(configure.date.time);

        if ((dateSelected.getTime() - dateCurrent.getTime()) < 0) {
          this.helpers.resetFieldsState(this.configureForm, ['date']);
          this.helpers.updateFormStatus(this.configureForm);
          delete this.settingsMicroSession.configure.date;
          this.authService.deleteMicroSettingsSession();
          this.authService.setMicroSettingsSession(this.settingsMicroSession);
        } else {
          this.helpers.updateFieldValue(this.configureForm, 'date', configure.date);
          this.date = new Date(dateSelected);
        }
      }
      // Capital
      this.helpers.updateFieldValue(this.configureForm, 'capital', configure.capital);
      // Duración
      this.helpers.updateFieldValue(this.configureForm, 'duration', configure.duration);
      // Cobertura
      this.helpers.updateFieldValue(this.configureForm, 'cobertura', configure.cobertura);
    }
  }

  // Añade 30 minutos a la hora actual
  add30MinutesToCurrentDate(): Date {
    const date: Date = new Date();
    date.setTime(date.getTime() + 30 * 60 * 1000);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }


  getObjectById(id: string, collections: any[]) {
    const object = collections.find((x) => {
      return x.id == id;
    });
    return object;
  }

  subscribeToForm() {

    this.configureForm.get('actividad').valueChanges.subscribe(actividad => {
      this.selectedActivity = actividad;
    });

    this.configureForm.get('region').valueChanges.subscribe(region => {
      this.selectedRegion = region;
      this.capitalsGet = this.capitals.filter(capital => {
        return this.selectedRegion.id === capital.scope;
      });
    });

    this.configureForm.get('timezone').valueChanges.subscribe(timezone => {
      this.selectedTimeZone = timezone;
    });

    this.configureForm.get('date').valueChanges.subscribe(date => {
      this.selectedDate = date;
      this.date = date.date;
      this.selectedDate.date = new Date(date.time);
      const duration = this.configureForm.get("duration").value;
      if (duration !== "") {
        this.calculateDateEnd(duration);
      }
      this.cdRef.detectChanges();
    });

    this.configureForm.get('capital').valueChanges.subscribe(capital => {
      this.selectedCapital = true;
      const capitalObject = this.capitals.find(item => { return item.id == capital });
      if (capitalObject) {
        this.valueDead = this.helpersMicro.formatDecimal(capitalObject.cfAmount);
        this.valueInvalidParcial = this.helpersMicro.formatDecimal(capitalObject.cipAmount);
        this.valueInvalidAbsolute = this.helpersMicro.formatDecimal(capitalObject.ciaAmount);
      }
    });

    this.configureForm.get('duration').valueChanges.subscribe(duration => {
      this.selectedDuration = true;
      this.coverageHeath = this.getPriceByDuration();
      if (typeof this.selectedDate.value !== 'undefined') {
        this.calculateDateEnd(duration);
      }
    });

    this.configureForm.valueChanges.subscribe((configureForm) => {
      this.errService.mobileErrors(this.configureForm);
      this.checkFormValidAndTarificate();
    });
  }


  checkFormValidAndTarificate() {
    if (this.configureForm.valid) {
      const idCapital = this.configureForm.controls.capital.value,
            capitalObject = this.capitals.find(item => item.id === idCapital ),
            cobertura = this.configureForm.controls.cobertura.value,
            duration = this.configureForm.controls.duration.value,
            tarification: Tarification = Object.assign({
              policyId: this.listpolicies.policyId,
              clientId: this.listpolicies.clientId,
              clientNif: this.userData.persona.idExterno,
              policyNumber: this.listpolicies.policyNumber,
              sport1: this.selectedActivity.id,
              sport2: null,
              sport3: null,
              sport4: null,
              sport5: null,
              discount: "",
              startDate: this.selectedDate.date,
              scope: this.selectedRegion.id,
              duration: duration,
              refund: cobertura,
              deseaseImport: capitalObject.cfAmount,
              disabilityImport: capitalObject.ciaAmount,
              capitalLiability: capitalObject.crcAmount
            });

      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
      this.loading = true;

      this.confService.getTarification(tarification).subscribe(res => {
        this.loading = false;
        window.scrollTo(0, document.body.offsetHeight);
        document.body.scrollTop = document.body.offsetHeight;
        const response: TarificationResponse = res;
        this.analytics.addPageLikeEvent(2);

        if (response.error) {
          const code = response.errorMessages[0].code;
          switch (code) {
            case 'ERG001':
              this.messageError = this.messageErrorGeneric;
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
              this.modService.open(this.modalError);
              break;
            case 'ERR000':
              this.messageError = this.messageErrorScopeNotValid;
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
              this.modService.open(this.modalError);
              break;
            case 'NPOI001':
              this.messageError = this.messageErrorCalculation;
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
              this.modService.open(this.modalError);
              break;
            default:
              this.messageError = response.errorMessages[0].messageText;
              this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
              this.modService.open(this.modalError);
          }
          return;
        }
        this.totalPrice = parseFloat(response.totalPrice);
        this.pricePerDuration = this.getDurationSelected(duration, this.totalPrice);
        this.tarificationShow = true;
      }, error => {
        this.loading = false;
        this.errService.navToError();
      });
    } else {
      this.tarificationShow = false;
    }
  }

  calculatePositionInit() {
    const elementInit = document.getElementById(this.capitals[0].id);
    if (elementInit != null) {
      this.positionInit = elementInit.getBoundingClientRect().left;
    }
  }

  onPosition(event) {
    this.calculatePositionInit();
    this.position = event;
  }

  getDurationSelected(id, totalPrice) {
    const duration = {
      label: '',
      value: 0
    },
          value = parseInt(id.substring(0, id.length - 1)),
          useTime = id.substring(id.length - 1, id.length);

    duration.value = totalPrice / value;

    if (useTime == "h") {
      duration.label = "hora";
    } else if (useTime == "d") {
      duration.label = "día";
    }

    return duration;
  }

  calculateDateEnd(id) {
    let milliseconds = 0;
    const value = parseInt(id.substring(0, id.length - 1)),
          useTime = id.substring(id.length - 1, id.length),
          newDate = Object.assign(this.date);

    if (useTime == "h") {
      milliseconds = value * 60 * 60 * 1000;
    } else if (useTime == "d") {
      milliseconds = value * 24 * 60 * 60 * 1000
    }

    this.dateEndActivity["date"] = new Date(newDate);
    this.dateEndActivity["date"].setTime(this.dateEndActivity["date"].getTime() + milliseconds);
    this.dateEndActivity["dateText"] = this.formatDateEnd(this.dateEndActivity["date"]);
  }


  private formatDateEnd(date) {
    const formatDate = this.helpers.formatDate(date),
          formatHour = this.helpers.formatHour(date),
          dateSelected = `${formatDate} a las ${formatHour}`
    return dateSelected;
  }


  openModal(modalId) {
    this.modService.open(modalId);
  }

  onSelectCoverage() {
    this.modService.close(this.modalCoverage);
    this.helpers.updateFieldValue(this.configureForm, 'cobertura', '1');
  }

  onSubmit() {
    this.helpers.updateFormStatus(this.configureForm);

    if (!this.configureForm.get('actividad').valid) {
      this.messageError = this.msgErrorActividad;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError);
      this.modService.open(this.modalError);
      window.scrollTo(0, 0);
      return false;
    }

    if (!this.configureForm.get('region').valid) {
      this.messageError = this.msgErrorRegion;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
      this.modService.open(this.modalError);
      window.scrollTo(0, 0);
      return false;
    }

    if (!this.configureForm.get('date').valid) {
      this.messageError = this.msgErrorDate;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
      this.modService.open(this.modalError);
      window.scrollTo(0, 0);
      return false;
    }

    if (!this.configureForm.get('timezone').valid) {
      this.messageError = this.msgErrorTimeZone;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
      this.modService.open(this.modalError);
      window.scrollTo(0, 0);
      return false;
    }

    if (!this.configureForm.get('capital').valid) {
      this.messageError = this.msgErrorCapital;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
      this.modService.open(this.modalError);
      window.scrollTo(0, 0);
      return false;
    }

    if (!this.configureForm.get('duration').valid) {
      this.messageError = this.msgErrorDuracion;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
      this.modService.open(this.modalError);
      window.scrollTo(0, 0);
      return false;
    }

    if (!this.tarificationShow) {
      this.messageError = this.msgErrorTarificacion;
      this.analytics.triggerTarificationNotificationAction('Mostrar notificación', this.messageError)
      this.modService.open(this.modalError);
      window.scrollTo(0, 0);
      return false;
    }

    this.selectedDate.time = this.selectedDate.date.getTime();
    const idCapital = this.configureForm.controls.capital.value,
          capitalObject = this.capitals.find(item => { return item.id == idCapital });

    this.settingsMicroSession = Object.assign({
      configure: {
        actividad: this.selectedActivity.id,
        region: this.selectedRegion.id,
        date: this.selectedDate,
        capital: this.configureForm.get('capital').value,
        cfAmount: capitalObject.cfAmount,
        cipAmount: capitalObject.cipAmount,
        crcAmount: capitalObject.crcAmount,
        duration: this.configureForm.get('duration').value,
        timezone: this.selectedTimeZone.id,
        cobertura: this.configureForm.get('cobertura').value,
        totalPrice: this.totalPrice
      }
    });

    this.authService.setMicroSettingsSession(this.settingsMicroSession);
    this.helpers.navigateTo("/evo-protect/configura-beneficiarios");

    return true;
  }


  getPriceByDuration() {
    let price = 0;
    const duration = this.configureForm.get('duration').value;
    switch (duration) {
      case "3h":
        price = 1.01;
        break;
      case "6h":
        price = 2.01;
        break;
      case "12h":
        price = 2.51;
        break;
      case "24h":
        price = 3.01;
        break;
      case "2d":
      case "3d":
      case "4d":
      case "5d":
        const days = duration.charAt(0);
        price = parseInt(days) * 3.01;
    }
    return price;
  }

}
