import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

@Component({
  selector: 'micro-bmi-finish',
  templateUrl: './logaltyFinish.component.html',
  styleUrls: ['./logaltyFinish.component.scss']
})
export class LogaltyBMIFinishComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((url: any) => {
        if (url.url === 'signatureok') {
          window.location.href = 'https://apiuat.evobanco.com:8443/signatureMicroSegurosOk.html'
        } else {
          window.location.href = 'https://apiuat.evobanco.com:8443/signatureMicroSegurosKo.html'
        }
    });
  }
}
