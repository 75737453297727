import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable()
export class InjectScriptService {
  registerInject;
  arrayScript:any[]=[];

  private createGoogleTagManager(tag){
    const node = document.createElement('script');
    node.async=true;
    node.src='https://www.googletagmanager.com/gtm.js?id='+tag;
    return node;
  }

  addScriptGoogle(label, tag){
    this.searchANDdestroy(label, tag);
    let content = this.generateScriptContent(label, tag);
    if (window['dataLayer']) {
      window['dataLayer'] = [];
    }
    this.addScriptContent(label, content, tag);
  }

  private searchANDdestroy(label, tag){
    if (!this.registerInject) return
    const documents = document.querySelectorAll(label);
    let content = "";
    let numPrevioslyCreatedScripts = this.arrayScript.length;
    for(let j = 0; j < numPrevioslyCreatedScripts; j++){
      let node = this.arrayScript[j];
      for (let i = 0; i < documents.length; i++) {
        const contentDoc = documents[i];
        if (contentDoc &&  document.contains(contentDoc) && contentDoc.innerHTML === node.innerHTML) {
          this.removeElementTag(label,contentDoc);
        }
      }
    }
  }

  private generateScriptContent(label, tag) {
    let scriptContent = "";
    const contentFunction = "(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', '" + tag + "');";
    const contentNoScript = '<iframe src="https://www.googletagmanager.com/ns.html?id='+tag+'" height = "0" width = "0" style = "display:none;visibility:hidden" > </iframe>';
    if (label !== 'script') {
      scriptContent = contentNoScript;
    } else {
      scriptContent = contentFunction;
    }
    return scriptContent;
  }

  private addScriptContent(label, content,tag) {
    const node = this.createScriptContent(label, content);
    if (label === 'script')
      document.getElementsByTagName('head')[0].appendChild(node);
    else
      document.getElementsByTagName('body')[0].appendChild(node);
    this.registerInject = node;
    this.arrayScript.push(node);
    const gtm = this.createGoogleTagManager(tag);
      this.arrayScript.push(gtm);
  }

  private removeElementTag (label, element){
    if('remove' in Element.prototype){
      element.remove();
      return;
    }
    //TODO:En IExplorer no existe remove() por lo que usaremos el label para saber de donde eliminarlo
    // Element.prototype.remove = function() {
      if (label !== 'script') {
        document.head.removeChild(element);
      } else {
        document.body.removeChild(element);
      }
    // };
  }

  private createScriptContent(label, content) {
    const node = document.createElement(label);
    node.textContent = content;
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    return node;
  }

  createScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  createScriptHotjar(idSalesforce){
    const node = document.createElement('script');
    node.textContent = "hj('tagRecording', ['" + idSalesforce +"']);";
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  createScriptHotjarFromSVC(idHotjar,idSalesforce) {
    // Si se trata del entorno de Producción, se insertan los scripts de Hotjar.
    //if (environment.production) {
      // Hotjar Tracking Code for https://cuentainversorauat.evobanco.com/
      const node1 = document.createElement('script');
      node1.textContent = "(function(h,o,t,j,a,r){" +
        "h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};" +
        idHotjar +
        "a=o.getElementsByTagName('head')[0];" +
        "r=o.createElement('script');r.async=1;" +
        "r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;" +
        "a.appendChild(r);" +
        "})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";
      document.getElementsByTagName('head')[0].appendChild(node1);

      const node2 = document.createElement('script');
      node2.textContent = "hj('tagRecording', ['" + idSalesforce + "']);";
      node2.type = 'text/javascript';
      node2.async = false;
      node2.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node2);
    //}
  }
  createScriptCookies(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.setAttribute('data-domain-script' , 'd09ff370-4049-4641-8671-4943228a0dcb');
    node.setAttribute('data-language' , 'es');
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  addScriptGlance(url: string, isProduction: string) {
    const node = document.createElement('script');
    node.id = 'glance-cobrowse';
    node.src = url;
    node.type = 'text/javascript';
    node.async = false;
    node.dataset.groupid = '20435';
    node.dataset.site = isProduction === 'true' ? 'production' : 'staging';
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
