import { ProductContract } from "app/collections/product-contract";


export const PRODUCTS_CONTRACT:ProductContract[] = [
    {
        idProducto: "006",
        label: "MI CUENTA INTELIGENTE",
        card: "MI TARJETA INTELIGENTE",
        image:"cuenta-inteligente",
        branchAlias: "",
        icon: 'open-wallet',
        description: 'Son dos cuentas conectadas entre sí: una cuenta para tu día a día y otra para tus ahorros al 0,20% T.A.E.'
      },
      {
        idProducto: "007",
        label: "MI CUENTA BASE",
        card: "MI TARJETA BASE",
        image:"cuenta-inteligente",
        branchAlias: "",
        icon: 'open-wallet',
        description: 'La Cuenta Base es una tarifa plana que incluye: cuenta corriente + tarjeta débito + servicios (transferencias, banca a distancia, ..).'
      },
      {
        idProducto: "008",
        label: "MI CUENTA JOVEN",
        card: "MI TARJETA JOVEN",
        image:"tarjeta-inteligente",
        branchAlias: "",
        icon: 'open-wallet',
        description: 'Una cuenta libre como tú. Te da todo lo que necesitas para moverte.'
      },
      {
        idProducto: "",
        branchAlias: "0019",
        label: "MI EVO PROTECT DEPORTES",
        card: null,
        image:"evo-protect",
        icon: '',
        description: 'El nuevo seguro por horas para disfrutar de tu deporte favorito sin ninguna preocupación. <br>Asegura hasta 75 actividades deportivas desde 0,42€.'
      },
      {
        idProducto: "999",
        label: "SEGUNDO TITULAR",
        card: "MI TARJETA INTELIGENTE",
        image:"cuenta-inteligente",
        branchAlias: "",
        icon: 'open-wallet',
        description: 'Son dos cuentas conectadas entre sí: una cuenta para tu día a día y otra para tus ahorros al 0,20% T.A.E.'
      },
      {
        idProducto: "998",
        label: "MICROSEGUROS",
        card: null,
        image:"microseguros",
        branchAlias: "",
        icon: 'open-wallet',
        description: 'Son dos cuentas conectadas entre sí: una cuenta para tu día a día y otra para tus ahorros al 0,20% T.A.E.'
      }
];
