import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { HelperService } from '../../../services/helpers.service';
import { LoginService } from 'app/services/login.service';
import { NOTIFICATIONS } from '../../../resources/notifications.model'
import { AnalyticsService } from '../../../services/analytics.service';
import { PersonaService } from '../../../services/persona.service';
import { ErrorService } from '../../../services/error.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss']
})
export class HeaderProfileComponent implements OnInit, AfterContentChecked {

  selected: string = "";
  showMenu: boolean = false;
  public username: string = '';
  public notifications = NOTIFICATIONS;
  public personWarnings: any;
  public user: any;
  public menuShow: boolean = true;
  public param = null;
  public inversora: boolean = true;
  public title: string;

  constructor(
    private analytics: AnalyticsService,
    public router: Router,
    private helpers: HelperService,
    public loginSrv: LoginService,
    private personaSrv: PersonaService,
    private authService: AuthService,
    public errService: ErrorService,
    private activatedRoute: ActivatedRoute,


  ) {
    this.subscribeToSelected();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      //console.log("parametros "+JSON.stringify(params));
      if (params.subidaDocumentacion) {
        this.param = params.subidaDocumentacion;
        sessionStorage.setItem("subidaDocumentacion", "true");
      }
      if (params.origen && params.origen.toLowerCase() === "abandono") {
        sessionStorage.setItem("operacion-ampliacion", 'ampliacion');
      }
      if (params && params.codProductoContratado &&
        (params.codProductoContratado === "009" || params.codProductoContratado === '010')) {
        this.inversora = true;
      }
    });

    const subidaDoc = JSON.parse(sessionStorage.getItem("subidaDocumentacion"));
    const showingCurtain = JSON.parse(sessionStorage.getItem("telonActivo"));
    const extendLimit = !!sessionStorage.getItem("operacion-ampliacion");
    if (subidaDoc == true || showingCurtain === true || extendLimit === true || this.inversora) {
      this.menuShow = false;
    }

    this.user = this.loginSrv.userData;
    /*
    if(this.user) {
      this.getWarnings();
    }
    */
  }

  ngAfterContentChecked(): void {
    if (this.authService.product === ACCOUNTS.STCode) {
      this.title = 'INCLUSIÓN 2º TITULAR<br>CUENTA INTELIGENTE';
    }
    else {
      this.title = '';
    }
  }

  get isLogin() {
    const userData = this.loginSrv.userData;
    if (userData) {
      if (userData.persona) {
        this.username = `${userData.persona.nombreCliente} ${userData.persona.apellido1Cliente}`
      } else {
        this.username = `${userData.userinfo.nombreCliente} ${userData.userinfo.apellido1Cliente}`
      }
    }
    return (this.loginSrv.isLoggedIn);
  }

  subscribeToSelected() {
    this.router.events.subscribe((url: any) => {
      if (url instanceof NavigationEnd) {
        const urlbar = url.url;
        this.selected = urlbar.substr(1);
      }
    });
  }

  toggleMenu() {
    if (this.showMenu) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }
    this.showMenu = !this.showMenu;
  }

  placeConnector(item?) {
    let target, dropdown;
    const connector = document.getElementById('connector'),
      arrowWidth = 14;

    if (item) {
      target = document.getElementById(item);
      dropdown = document.getElementById(`${item}-extra`)
    }
    switch (item) {
      case 'notifications':
        connector.style.left = target.offsetLeft + target.offsetWidth / 2 + 'px';
        dropdown.style.left = target.offsetLeft - dropdown.offsetWidth / 2 + target.offsetWidth / 2 + 'px';
        connector.style.bottom = '0';
        break;
      case 'avatar':
        connector.style.left = target.offsetLeft + target.offsetWidth / 2 - arrowWidth + 'px';
        dropdown.style.left = target.offsetLeft - dropdown.offsetWidth / 2 + target.offsetWidth / 2 + 'px';
        connector.style.bottom = '0'
        break;
      default:
        connector.style.bottom = '-10px';
        break;
    }
    connector.style['transition-duration'] = '0.3s'
    connector.style['transition-property'] = 'bottom right'
    connector.style['transition-timing-function'] = 'ease-out'

  }

  goToProfile() {
    this.selected = 'perfil';
    if (this.showMenu) this.toggleMenu();
    this.helpers.navigateTo(this.selected);
  }

  goToMyProducts() {
    this.selected = 'mis-productos';
    if (this.showMenu) this.toggleMenu();
    this.helpers.navigateTo(this.selected);
  }

  goToNotification(notification?) {
    this.selected = 'notificacion';
    if (notification) {
      this.analytics.triggerNotificationAction('Abrir notificación', notification.title);
      return this.helpers.navigateTo(`/notificacion/${notification.title}`);
    }
    if (this.showMenu) this.toggleMenu();
    this.helpers.navigateTo(this.selected);

  }

  logOut() {
    this.loginSrv.logOut();
    if (this.showMenu) this.toggleMenu();
    this.username = '';
    this.helpers.navigateTo('/login');
  }
}
