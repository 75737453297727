<div class="coverage">
    <div class="header">
      <div class="title-container">
        <span class="title">COBERTURA MÉDICA</span>
      </div>
    </div>
    <hr>
    <div class="content-main">
      <div class="subheader">Reembolso de gastos sanitarios por accidente deportivo hasta 4000€</div>
      <p class="description">La compañía tomará a su cargo los gastos de índole sanitaria (hasta 4000 €) que le sean originados al asegurado  a consecuencia de un accidente  por la práctica del deporte asegurado</p>

      <div class="main-icon evo micro-first-aid"></div>
      <div class="addCoverage">Añade cobertura médica por solo:</div>

      <div class="container-prices">
        <div class="row-price">
            <div class="item-duration">3 horas</div>
            <div class="item-price">1,01€</div>
        </div>  
        <div class="row-price">
            <div class="item-duration">6 horas</div>
            <div class="item-price">2,01€</div>
        </div>      
        <div class="row-price">
            <div class="item-duration">12 horas</div>
            <div class="item-price">2,51€</div>
        </div>  
        <div class="row-price">
            <div class="item-duration">De 1 a 5 días</div>
            <div class="item-price">3,01€/día</div>
        </div>  
      </div>


      <!-- <div class="price-hour"><span>1 €</span> / 3 horas</div> -->

    </div>
    <div class="menu-bottom">
        <div class="btn-group">
          <button class="btn-common btn-common_fix_bold" (click)="addCoverage()">AÑADIR COBERTURA MÉDICA</button>
        </div>
    </div>
  </div>
  