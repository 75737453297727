import { Injectable } from '@angular/core';
import * as IBAN from 'iban';
import { FormControl, ValidatorFn } from '@angular/forms';

@Injectable()
export class FormValidatorService {

  constructor() {}

  validateEmailPhone(field: FormControl) {
    const emailRexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRexp = /^[6-7][0-9]{8}|8{9}/i;
    const str = field.value.toString();

    const emailIsValid = emailRexp.test(str);
    const phoneIsValid = phoneRexp.test(str);

    if (emailIsValid || phoneIsValid) {
      return null;
    } else {
      return {
        validateEmailPhone: {
          valid: false
        }
      }
    }
  }

  validateEmail(field: FormControl) {
    const emailRexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const str = field.value.toString();
    const emailIsValid = emailRexp.test(str);
    if (emailIsValid) {
      return null;
    } else {
      return {
        validateEmail: {
          valid: false
        }
      }
    }
  }

  validateEquals(field: FormControl) {
    const str = field.value.toString();
  }

  validateID(field) {
    const invalid = {
      validateID: {
        valid: false
      }
    }
    let validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    let nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    let nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    let str = field.value.toString().toUpperCase();

    if(str.length !== 9) return invalid;

    if (!nifRexp.test(str) &&
      !nieRexp.test(str)) {
      return invalid;
    }

    var nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');

    var letter = str.substr(-1);
    var charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) {
      return null;
    }
    return invalid;
  }

  validateRent(field: FormControl) {
    if (parseInt(field.value,10) === 0) return {
      alquilerVivienda: {
        valid:false
      }
    }
    return null;
  }

  validateHour(field: FormControl) {
    let value = parseInt(field.value);
    if(value < 0 || value > 23) {
      return {
        validateHour: {
          valid:false
        }
      }
    }
    return null;
  }

  validateMinutes(field: FormControl) {
    let value = parseInt(field.value);
    if(value < 0 || value > 59) {
      return {
        validateMinutes: {
          valid:false
        }
      }
    }
    return null;
  }

  validateIncomings(field: FormControl) {

    let incomings = parseInt(field.value, 10);

    if (incomings < 80001) return null;

    return {
      validateIncomings: {
        valid: false
      }
    }
  }

  validateAnnualPayments(field: FormControl) {
    let numberAnnualPayments = parseInt(field.value,10);
    let monthIncomings = 20;


    if (numberAnnualPayments > 0) return null;


    if (numberAnnualPayments * monthIncomings >= 1000000) {
      return null;
    }

    if (numberAnnualPayments === 0) return {
      validateAnnualPayments: {
        valid: false
      }
    }

    return {
      validateAnnualPayments: {
        valid: false
      }
    }
  }

  validateIban(field: FormControl) {

      if(!!field && validateCCC("ES"+field.value) && IBAN.isValid("ES"+field.value)){
        return null;
      }else{
        return {
          iban: {
            valid:false
          }
        }
      };

    function validateCCC(iban) {
      iban = iban.replace(/ /g, '');
      var acountNumber = iban.slice(4);
      var valores = new Array(1, 2, 4, 8, 5, 10, 9, 7, 3, 6);
      var controlCS = 0, controlCC = 0;
      for (var i = 0; i <= 7; i++) {
        controlCS += parseInt(acountNumber.charAt(i)) * valores[i + 2];
      }

      controlCS = 11 - (controlCS % 11);
      if (controlCS == 11) controlCS = 0;
      else if (controlCS == 10) controlCS = 1;

      for (i = 10; i <= 19; i++)
        controlCC += parseInt(acountNumber.charAt(i)) * valores[i - 10];
      controlCC = 11 - (controlCC % 11);
      if (controlCC == 11) controlCC = 0;
      else if (controlCC == 10) controlCC = 1;

      return (acountNumber.charAt(8) == controlCS && acountNumber.charAt(9) == controlCC);
  }

  }

  validateDate(field: FormControl) {
   const invalid = {valid:false};
   const bits = field.value.split('/');
   const d = new Date(bits[2], bits[1] - 1, bits[0]);
   const dataActual = new Date();
   const currentYear = new Date().getFullYear();
   const currentDay = new Date().getDate();

   if (field.value.length != 10) return invalid;
   if(d.getMonth() === dataActual.getMonth()  && currentYear === d.getFullYear() && d.getDate() > currentDay) return invalid;
   if (d.getMonth() > dataActual.getMonth() && currentYear === d.getFullYear()) return invalid;
   if((currentYear - d.getFullYear()) < 0) return invalid;
   if ((currentYear - d.getFullYear()) > 50 ) return invalid;
   if (d && (d.getMonth() + 1) == bits[1]) {
     return null
   } else {
     return invalid;
   }
  }

  validateDateRent(field: FormControl) {
    const invalid = {valid:false};
    const bits = field.value.split('/');
    const d = new Date(bits[2], bits[1] - 1, bits[0]);
    const dataActual = new Date();
    const currentYear = new Date().getFullYear();
    if (field.value.length != 10) return invalid;
    if (d.getMonth() > dataActual.getMonth() && currentYear === d.getFullYear()) return invalid;
    if((currentYear - d.getFullYear()) < 0) return invalid;
    if (d && (d.getMonth() + 1) == bits[1]) {
      return null
    } else {
      return invalid;
    }
   }

  validateMortgage(field: FormControl) {
    const currentYear = new Date().getFullYear();
    if (parseInt(field.value) > currentYear) {
      return {
        valid: false
      }
    }

    return null;
  }

  validateNoFutureDate(field: FormControl){
    const invalid = {valid:false};
    const sDate = field.value.toString();
    const dateToday = new Date();
    let date = new Date();
    if (sDate.indexOf("/")  === -1){
      date = new Date(sDate);
    } else {
      const [day, month, year] = sDate.split("/");
      date = new Date(year, month - 1, day);
    }
    if (dateToday <= date){
      return invalid;
    }
    return null;
  }

  validateDateDDMMYYYY(field: FormControl){
    const invalid = {valid:false};
    const dateRegExp = /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i;
    const date = field.value.toString();
    const isValidDate = dateRegExp.test(date);
    if (isValidDate) {
      return null;
    } else {
      return invalid;
    }
  }



  validatePasswordConfirmation(control: FormControl) {
    const invalid = { valid: false};
    const controlValue = control.value;
    const confirmationControl = control.root.get('password');
    if ( confirmationControl && controlValue !== confirmationControl.value) {
      return invalid;
    }
    return null;
  }

  validateName(field: FormControl) {
    const invalid = {valid: false};
    const nameRexp = /^([À-ÿA-z\- '])*$/;
    const name = field.value.toString();
    const isValidName = nameRexp.test(name);
    if (isValidName) {
      return null;
    } else {
      return invalid;
    }
  }

}
