import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { AuthMicroService } from 'app/services/auth-micro.service';
import { AuthGuardMicro } from 'app/services/auth-guard-micro.service';
import { NavigationMicroService } from 'app/services/navigation-micro.service';
import { MicrosegConfigService } from 'app/services/microinsurance-config.service';
import { GetCertificateResponse } from 'app/collections/get-certificate';
import { HelperService } from 'app/services/helpers.service';
import { Simulador } from 'app/collections/simulador';
import { AnalyticsService } from 'app/services/analytics.service';

@Component({
  selector: 'app-all-ready',
  templateUrl: './all-ready.component.html',
  styleUrls: ['./all-ready.component.scss'],
  providers: [ModalService]
})
export class AllReadyComponent implements OnInit, OnDestroy {
  public loading = false;
  public modalError = 'modal-srv-error';
  public messageError = "";
  private settingsMicroSession: Simulador = this.authService.getMicroSettingsSession();

  constructor(private modService: ModalService,
              private authguard: AuthGuardMicro,
              private navigationService: NavigationMicroService,
              private configService: MicrosegConfigService,
              private helpers: HelperService,
              private authService: AuthMicroService,
              private analytics: AnalyticsService
            ) { }

  ngOnInit() {
    sessionStorage.setItem("reloadUserData", "true");
    //Elimina la clase hide para mostrar los steps
    document.getElementById('user-path').classList.remove('hide');
  }

  ngOnDestroy() {
    this.authService.deleteMicroSettingsSession();
    this.authguard.empty();
  }

  onViewCertificate() {
    this.loading = true;
    this.configService.getCertificate(this.settingsMicroSession.requestId).subscribe(data => {
      const response: GetCertificateResponse = data;
      if (response.resultCode == 1) {
        this.analytics.triggerNotificationAction('Mostrar notificación',"Mostrando certificado");
        const pdfBase64 = response.pdfContent;
        this.helpers.openPDF(pdfBase64);
      } else {
        this.messageError = response.resultMessage;
        this.analytics.triggerNotificationAction('Mostrar notificación',this.messageError);
        this.modService.open(this.modalError);
      }
      this.loading = false;
    },
    error => {
      this.loading = false;
      console.log(error);
    })
  }

  goToGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=es.evobanco.bancamovil&hl=es");
  }

  goToAppStore() {
    window.open("https://itunes.apple.com/app/id542752481 ");
  }

}
