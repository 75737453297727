<loading [showMe]="loading"></loading>

<modal [modalTitle]="'ACEPTACIÓN DE CONDICIONES LEGALES'" [blocking]='false' [modalId]='legalModal' [modal-wide]="true"
  class='modal'>
  <div class="container">
    <h2>ACEPTACIÓN DE CONDICIONES LEGALES</h2>
    <p>De conformidad con lo establecido en el Reglamento 679/2016 General de Protección de Datos y normativa
      complementaria, te informamos de que tus datos serán incorporados a los ficheros de EVO BANCO, S.A. en tanto
      entidad responsable del tratamiento automatizado o no, de los mismos. Los datos facilitados a lo largo de la
      presente solicitud, entre ellos aquellos utilizados para la identificación no presencial mediante videoconferencia
      así como la grabación y conservación del presente proceso de contratación, incluida la IP desde donde se realiza,
      serán tratados con la finalidad de estudiar tu solicitud y posterior gestión de tu cuenta. Asimismo, tus datos
      serán tratados con finalidad estadística conforme a técnicas de agregación y seudonimización para analizar
      patrones globales sobre ingresos y gastos de usuarios, pero en ningún momento como consecuencia de este
      tratamiento se generarán patrones de carácter longitudinal respecto del uso de determinados servicios o la
      singularización de usuarios, no siendo posible la vinculación directa o indirecta de la información con un
      determinado usuario. Para el estudio de tu solicitud, Evo Banco, S.A. podrá consultar ficheros de las empresas
      del grupo, datos que constan en redes sociales, así como en ficheros de solvencia económica y podrán ponerse en
      contacto contigo tanto telefónicamente como por otros medios, incluidos los telemáticos, con la misma finalidad.
      Al marcar la casilla anterior, consientes expresamente que estos datos sean almacenados y tratados en un fichero
      automatizado y manual del que Evo Banco, S.A. es responsable. No aceptar estas condiciones implicaría no poder
      seguir adelante con el proceso de contratación. Los datos objeto de dicho tratamiento se conservarán en la medida
      en que sean necesarios para las finalidades descritas y en cumplimiento de la normativa aplicable. Conservaremos
      la información personal con las medidas de seguridad adecuadas en función de los datos y tratamientos por 10 años.
      No obstante, en todo momento podrás ejercer tus derechos de acceso, rectificación, cancelación, portabilidad,
      limitación y oposición al tratamiento de datos a través del correo electrónicoprotecciondedatos@evobanco.com o
      bien por escrito dirigido a Evo Banco, S.A. en la Calle Don Ramón de la Cruz 84, CP28006 de Madrid, indicando en el asunto
      “Protección de Datos” y adjuntando copia de tu DNI o documento identificativo válido, a cualquiera de ellos.
      Asimismo, te informamos de que tienes derecho a recabar la tutela de la Agencia Española de Protección de Datos a
      través de su página Web www.aepd.es. Por otro lado, quedas informado de la comunicación de los datos de la
      presente solicitud al Fichero CONFIRMA, para la finalidad de prevención de fraude. Los responsables de dicho
      fichero son las Entidades Adheridas al Reglamento del Fichero CONFIRMA, siendo el encargado del tratamiento
      Confirma Sistemas de Información, S.L., con domicilio en la Avda, de la Industria, 18, TRES CANTOS (28760) MADRID.
      Podrás consultar el listado de Entidades que actualmente están adheridas al Reglamento del Fichero CONFIRMA
      en www.confirmasistemas.es. Los datos comunicados al Fichero CONFIRMA podrán ser cedidos a las Entidades Adheridas
      al Reglamento del Fichero CONFIRMA para la finalidad previamente indicada. Podrás ejercer tus derechos de acceso,
      rectificación, cancelación, limitación, portabilidad y oposición, dirigiéndote al domicilio de CONFIRMA SISTEMAS
      DE INFORMACIÓN, S.L. en la dirección arriba indicada.</p>

    <p>He sido informado por EVO Banco que la legislación vigente sobre prevención de blanqueo de capitales le obliga a
      obtener de sus clientes la información de su actividad económica y a realizar una comprobación de la misma, tanto
      en el momento del establecimiento de relaciones de negocio como periódicamente para su debida actualización. Con
      este exclusivo fin de verificación de la información facilitada, presto mi consentimiento expreso a EVO Banco,
      para que en mi nombre pueda solicitar ante la Tesorería General de la Seguridad Social dicha información.
      Igualmente he sido informado que en el supuesto de que los datos por este suministrados para la concesión de la
      financiación no se correspondan con los obrantes en la Tesorería General de la Seguridad Social, la entidad
      financiera trasladará a la Tesorería General de la Seguridad Social la información necesaria que esta le requiera
      para realizar las verificaciones y comprobaciones necesarias en orden a la protección de la seguridad de la
      información de la Seguridad Social y prevención del fraude. Los datos obtenidos de la TGSS serán utilizados
      exclusivamente para la gestión señalada anteriormente. En el caso de incumplimiento de esta obligación por parte
      de la entidad financiera y/o del personal que en ella presta servicios, se ejecutarán todas las actuaciones
      previstas en la Ley Orgánica 15/99, de 13 de diciembre, de Protección de Datos de Carácter Persona y normativa que
      la desarrolla.</p>
    <div class="btn-group">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="checkCondiciones()">ACEPTAR</button>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Sin check'" [blocking]='false' [modalId]='noCheckModal' class='modal'>
  <div class="container">
    <h2>¡Ups!</h2>
    <p class="invalid-text">Debes haber leído y aceptado los términos y condiciones legales para poder continuar.</p>
  </div>
</modal>

<modal [modalTitle]="'Excepcion'" [modalId]='modal.name' class='modal'>
  <div class="image-modal" *ngIf="modal.withImage"></div>
  <div [ngClass]="modal.withImage? 'false-container':'container'">
    <h2>{{modal.title}}</h2>
    <p [ngClass]="modal.withImage?'':'invalid-text'" [innerHTML]='modal.message'></p>
  </div>
</modal>

<app-header></app-header>
<div class="logalty-ready">
  <div class="image" [ngClass]="{ 'young' : isYoungAccount }"></div>
  <div class="container">
    <div class="title-container">
      <legend>
        <span class="main-icon evo sign"></span>
        <h2>¿Listo para firmar?</h2>
        <h3>TE VAMOS A REDIRIGIR A NUESTRO PARTNER DE CONFIANZA</h3>
      </legend>
    </div>
    <div class="row">
      <p>Te vamos a redigir a Logalty, nuestro tercero de confianza, para realizar la firma del contrato de tu Cuenta <span>{{(isYoungAccount)?'Joven':'Inteligente'}}</span> de forma totalmente segura.</p>
    </div>
    <form [formGroup]="logaltyForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="row">
        <div class="checkbox-group">
          <div class="form-checkbox" (click)='openLegal($event)'>
            <input type="checkbox" name="check_conditions" id="check_conditions"
            value="Acepto" formControlName="condiciones">
            <label for="check_conditions"
             tabindex="1">
             He leído y acepto la <a>Política de Privacidad</a> </label>
             <div class="error-text" *ngIf="logaltyForm.get('condiciones').hasError('notChecked')">
              {{errors.politicaPrivacidad}}
            </div>
          </div>
        </div>
      </div>
      <div class="checkbox-group" style="margin-top: -20px; text-align: justify;">
        <div class="form-checkbox">
          <input type="checkbox" name="check_publicity" id="check_publicity" value="Acepto" formControlName="cesion">
          <label id="check_publicity_label" for="check_publicity" (keypress)="selectCheckPubli($event)">
            Consiento que mis datos personales sean cedidos datos a empresas del grupo Bankinter y a sus filiales
            o participadas para que puedan informarme, por cualquier canal (incluido los electrónicos) sobre los
            productos o servicios que comercialicen que sean adecuados a mis necesidades, gustos y preferencias y
            puedan utilizar para el seguimiento, control y análisis del riesgo de los productos y servicios contratados.
          </label>
        </div>
      </div>

      <div class="row btn">
        <div class="btn-group">
          <button type="submit" tabindex="3" class="btn-common btn-common_fix_bold">CONTINUAR</button>
        </div>
      </div>
    </form>
  </div>
</div>
