import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { States } from 'app/collections/states-card';
import { ActivatedRoute, Router } from '@angular/router';
import { MyProductsService } from 'app/services/my-products.service';
import { LoginService } from 'app/services/login.service';
import { AuthUserService } from '../../../services/auth-user.service';
import { SecondHolderService } from '../../../services/second-holder.service';
import { GetContractResponse } from '../../../collections/get-contract';
import { HelperService } from '../../../services/helpers.service';
import { ModalService } from '../../../services/modal.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { ACCOUNTS } from '../../../resources/account.model';
import { User } from '../../../collections/user';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  providers: [ModalService]
})
export class ProductDetailsComponent implements OnInit {

  public secondHolderForm: FormGroup;
  private userData: User;
  public loading;
  public canHaveSecondHolder: boolean = false;
  public isSendSecondUser: boolean = false;
  public modalLegal = 'legalModal';
  public incompleteModal= 'incompleteModal';
  public addSecondHolder: string = "añadir segundo titular";
  public msgSrvError = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.';
  public invalidError = {field: 'email', text: 'Introduce un email válido', code: 100};
  public fieldError = {field: 'reactiveEquals', text: 'Los campos tienen que coincidir', code: 100};

  stateCurrent = States.enviada;
  currentProduct:any;
  fullName:string;
  address:string;
  ACCOUNTS = ACCOUNTS;

  breadcrumbNav = [{name:'INICIO',link:'/perfil'}, {name:'MIS PRODUCTOS', link:'/mis-productos'}];

  modalError = 'modalError';
  //msgSrvError = '';
  msgErrorDownload = 'Error en la descarga del contrato.';
  msgErrorNoneLogalty = 'En estos momentos no podemos mostrarte tu contrato. Inténtalo de nuevo en unos minutos.';
  msgErrorPersona = 'Error al obtener la persona de SFC.';

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private secondHolderSrv: SecondHolderService,
    private loginSrv: LoginService,
    private helpers: HelperService,
    public myProductSrv: MyProductsService,
    private authUserService: AuthUserService,
    private helpersService: HelperService,
    public modService: ModalService,
    private analytics: AnalyticsService,
    public errService: ErrorService) {
    this.createForm();
  }

  ngOnInit() {
    const userData = this.loginSrv.userData;
    const listaParaContratar = userData.listaParaContratar;
    const listaContratados = userData.listaContratados;

    if (userData != null) {
      this.fullName = userData.persona.nombreCliente + " " + userData.persona.apellido1Cliente + " " + userData.persona.apellido2Cliente;
      const address = userData.persona.direccion;
      this.address = this.myProductSrv.formatAddress(address);
    }

    this.currentProduct = this.myProductSrv.getProductSelected();

    if (this.currentProduct.idProducto == ACCOUNTS.CICode) {
      if (!this.currentProduct.segundoTitular) {
        for (const valProd of listaParaContratar) {
          if (valProd.idProducto === '999') {
            this.canHaveSecondHolder = true;
          }
        }
      }
      else {
        this.canHaveSecondHolder = false;
      }
    }
  }


  private createForm() {
    const emailRegex = /.+@.+\..+/i;
    this.secondHolderForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      confirmEmail: ['', [Validators.required]],
      checkConditions: ['', [Validators.required]]
    });
    this.formSubscribe(this.secondHolderForm);
  }

  private formSubscribe(form) {
    /*
    const userMail = form.get('email'),
          confirmMail = form.get('confirmEmail');

    confirmMail.valueChanges.subscribe(confirm => {
      const isNotEqual = !this.helpers.hasEqualsChars(userMail.value, confirmMail.value)
      && !confirmMail.pristine && userMail.valid;
      if (isNotEqual) {
        confirmMail.setErrors({notEquals: true});
      }
    })
    */

   const emailRegex = /.+@.+\..+/i;
   const userMail = form.get('email'),
   confirmEmail = form.get('confirmEmail');

   userMail.valueChanges.subscribe(mail => {
     if (userMail.value === "") {
       this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
     }
     if (confirmEmail.value !== "" && userMail.value !== "" && userMail.value !== confirmEmail.value) {
       if(userMail.valid) {
         this.secondHolderForm.get('confirmEmail').setErrors({notEquals: true});
       }
       else if (!userMail.valid){
         this.secondHolderForm.get('email').setErrors({notEquals: true});
       }
       this.helpers.updateFormStatus(this.secondHolderForm);
       this.errService.errorsArray.push(this.fieldError);
     }
     else if (confirmEmail.value !== "" && userMail.value !== "" && userMail.value === confirmEmail.value) {
       this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
       this.helpers.updateFieldValidation(this.secondHolderForm, 'confirmEmail', [Validators.required, Validators.pattern(emailRegex)]);
       this.errService.errorsArray = [];
     }
   })
   
   confirmEmail.valueChanges.subscribe(confirm => {
     const isNotEqual = !this.helpers.hasEqualsChars(userMail.value, confirmEmail.value)
     && !confirmEmail.pristine && userMail.valid;
     if (isNotEqual) {
       confirmEmail.setErrors({'notEquals': true});
       this.helpers.updateFieldStatus(this.secondHolderForm, 'email');
       this.helpers.updateFieldStatus(this.secondHolderForm, 'confirmEmail');
     
       this.errService.errorsArray.push(this.fieldError);
       if (confirmEmail.value === "") {
         this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
         this.errService.errorsArray = [];
         
       }
     }
     else {
       this.errService.errorsArray = [];
       this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
       this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
       if (userMail.value === "") {
         this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
       }
     }
   })

    this.secondHolderForm.get('checkConditions').valueChanges.subscribe(legal => {
      //this.modService.open(this.modalLegal);

      const conditionsCheck = this.secondHolderForm.get('checkConditions');
      if(conditionsCheck.value === false) {
        this.modService.close(this.modalLegal);
      }
      else if (conditionsCheck.value === true) {
        this.modService.open(this.modalLegal);
      }
    })
  }

  outFocus (input) {
    const userMail = this.secondHolderForm.get('email'),
    confirmEmail = this.secondHolderForm.get('confirmEmail');

    if (input.name === "email") {
      if (userMail.value === "") {
        this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
      }
      else {
        if (!this.secondHolderForm.get('email').valid){
          this.helpers.updateFieldStatus(this.secondHolderForm, 'email');
        }
        else if (this.secondHolderForm.get('email').valid){
          this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
          this.errService.errorsArray = [];
        }
        else if (confirmEmail.value !== "" && userMail.value !== confirmEmail.value){
          this.secondHolderForm.get('email').setErrors({notEquals: true});
          this.secondHolderForm.get('confirmEmail').setErrors({notEquals: true});
          this.helpers.updateFormStatus(this.secondHolderForm);
          this.errService.errorsArray.push(this.fieldError);
        }
      }
    }
    else if (input.name === "confirmEmail") {
      if (confirmEmail.value === ""){
        if (this.secondHolderForm.get('email').valid){
          this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
        }
        this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
        if (userMail.value === "")
        {
          this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
        }
      }
      else {
        if (userMail.value !== confirmEmail.value && userMail.value === "") {
          this.helpers.updateFieldStatus(this.secondHolderForm, 'email');
          this.helpers.updateFieldStatus(this.secondHolderForm, 'confirmEmail');
        }
        else if (userMail.value !== confirmEmail.value && userMail.value !== "") {
          //this.secondHolderForm.get('email').setErrors({notEquals: true});
          this.secondHolderForm.get('confirmEmail').setErrors({notEquals: true});
          this.helpers.updateFormStatus(this.secondHolderForm);
          this.errService.errorsArray.push(this.fieldError);
          
        }
        else if (userMail.value !== confirmEmail.value) {
          this.helpers.resetFieldsState(this.secondHolderForm, ['email']);
          this.helpers.resetFieldsState(this.secondHolderForm, ['confirmEmail']);
        }
      }
    }
  }

  onSubmit() {
    this.loading = true;
    const checkbox = this.secondHolderForm.get('checkConditions'),
          form = this.secondHolderForm;

    if (!form.get('email').valid || !form.get('confirmEmail').valid) {
      this.loading = false;
      this.msgSrvError = 'Revisa que has rellenado bien todos los datos que te indicamos para poder continuar.';
      this.modService.open(this.incompleteModal);
      this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio KO: Email inválido');
      this.helpers.updateFormStatus(this.secondHolderForm);
      return;
    }

    if (form.get('email').value !== form.get('confirmEmail').value) {
      
      this.loading = false;
      this.modService.open(this.incompleteModal);
      this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio KO: Emails no coinciden');
      form.get('confirmEmail').setErrors({notEquals: true});
      this.helpers.updateFormStatus(this.secondHolderForm);
      return;
    }

    if (!checkbox.value) {
      this.loading = false;
      this.msgSrvError = 'Debes haber leído y aceptado los términos y condiciones legales para poder continuar.'
      this.modService.open(this.incompleteModal);
      this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio KO: No se han aceptado los términos y condiciones legales');
      return;
    }

    this.secondHolderSrv.createSecondHolder(form.get('email').value, ACCOUNTS.CICode, this.loginSrv.userData.persona.id_cliente_EVO).subscribe(
      data => {
        this.loading = false;
        sessionStorage.setItem("reloadUserData", "true");
        this.isSendSecondUser = true;
        this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio OK');
      },
      error => {
        this.loading = false;
        this.isSendSecondUser = false;
        if(error.error.resultMessage == "Ya existe un cliente con el email introducido y con el producto a contratar"){
          this.msgSrvError = "El usuario "+form.get('email').value+" ya tiene otra petición para ser segundo titular.";
          this.resetForm();
          this.modService.close(this.modalLegal);
          this.modService.open(this.incompleteModal);
        }
        this.analytics.triggerProfileFormData('Añadir segundo titular', 'Envio KO: 500 Server error');
      }
    );

  }

  downloadContract() {
    const productSelected = this.myProductSrv.getProductSelected(),
          codigoProducto = productSelected.idProducto;

    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    this.loading = true;
    this.authUserService.getMyContract(codigoProducto).subscribe(res => {
      this.loading = false;
      const response: GetContractResponse = res;
      if (res.downloaded) {
        const pdfBase64 = response.content;
        this.helpersService.openPDF(pdfBase64);
      } else {
        switch (response.operationErrorCode) {
          case -3:
            this.msgSrvError = this.msgErrorNoneLogalty;
            this.modService.open(this.modalError);
            break;
          case -2:
            this.msgSrvError = this.msgErrorDownload;
            this.modService.open(this.modalError);
            break;
          case -1:
            this.msgSrvError = this.msgErrorPersona
            this.modService.open(this.modalError);
            break;
          default:
          this.msgSrvError = response.operationErrorMessage;
          this.modService.open(this.modalError);
        }
        return;
      }
    },
    error => {
      this.loading = false;
      this.errService.navToError();
    });
  }

  openLegal() {
    const conditionsCheck = this.secondHolderForm.get('checkConditions');
    if(conditionsCheck.value === false) {
      this.modService.close(this.modalLegal);
    }
    else if (conditionsCheck.value === true) {
      this.modService.open(this.modalLegal);
    }
    
  }

  addOtherSecondHolder() {
    this.addSecondHolder = "añadir otro segundo titular";
    this.isSendSecondUser = !this.isSendSecondUser;
    this.resetForm();
    this.modService.close(this.modalLegal);
  }

  resetForm () {
    this.secondHolderForm.reset({email: '', confirmEmail: '', checkConditions: false});
  }

}
