export var PROVINCES:Array<Object> = [
  {value: null, name:'Provincia', code: null},
  {value: '01', name:'ARABA/ALAVA', code: 'VI'},
  {value: '02', name:'ALBACETE', code: 'AB'},
  {value: '03', name:'ALICANTE', code: 'A'},
  {value: '04', name:'ALMERIA', code: 'AL'},
  {value: '33', name:'ASTURIAS', code: 'O'},
  {value: '05', name:'AVILA', code: 'AV'},
  {value: '06', name:'BADAJOZ', code: 'BA'},
  {value: '07', name:'BALEARES', code: 'PM / IB'},
  {value: '08', name:'BARCELONA', code: 'B'},
  {value: '48', name:'BIZKAIA', code: 'BI'},
  {value: '09', name:'BURGOS', code: 'BU'},
  {value: '10', name:'CACERES', code: 'CC'},
  {value: '11', name:'CADIZ', code: 'CA'},
  {value: '39', name:'CANTABRIA', code: 'S'},
  {value: '12', name:'CASTELLON DE LA PLANA', code: 'CS'},
  {value: '51', name:'CEUTA', code: 'CE'},
  {value: '13', name:'CIUDAD REAL', code: 'CR'},
  {value: '14', name:'CORDOBA', code: 'CO'},
  {value: '15', name:'CORUÑA (A)', code: 'C'},
  {value: '16', name:'CUENCA', code: 'CU'},
  {value: '17', name:'GIRONA', code: 'GE / GI'},
  {value: '18', name:'GRANADA', code: 'GR'},
  {value: '19', name:'GUADALAJARA', code: 'GU'},
  {value: '20', name:'GIPUZKOA', code: 'SS'},
  {value: '21', name:'HUELVA', code: 'H'},
  {value: '22', name:'HUESCA', code: 'HU'},
  {value: '23', name:'JAEN', code: 'J'},
  {value: '24', name:'LEON', code: 'LE'},
  {value: '25', name:'LLEIDA', code: 'L'},
  {value: '26', name:'RIOJA (LA)', code: 'LO'},
  {value: '27', name:'LUGO', code: 'LU'},
  {value: '28', name:'MADRID', code: 'M'},
  {value: '29', name:'MALAGA', code: 'MA'},
  {value: '52', name:'MELILLA', code: 'ML'},
  {value: '30', name:'MURCIA', code: 'MU'},
  {value: '31', name:'NAVARRA', code: 'NA'},
  {value: '32', name:'OURENSE', code: 'OR / OU'},
  {value: '34', name:'PALENCIA', code: 'P'},
  {value: '35', name:'PALMAS (LAS)', code: 'GC'},
  {value: '36', name:'PONTEVEDRA', code: 'PO'},
  {value: '37', name:'SALAMANCA', code: 'SA'},
  {value: '38', name:'SANTA CRUZ DE TENERIFE', code: 'TF'},
  {value: '40', name:'SEGOVIA', code: 'SG'},
  {value: '41', name:'SEVILLA', code: 'SE'},
  {value: '42', name:'SORIA', code: 'SO'},
  {value: '43', name:'TARRAGONA', code: 'T'},
  {value: '44', name:'TERUEL', code: 'TE'},
  {value: '45', name:'TOLEDO', code: 'TO'},
  {value: '46', name:'VALENCIA', code: 'V'},
  {value: '47', name:'VALLADOLID', code: 'VA'},
  {value: '49', name:'ZAMORA', code: 'ZA'},
  {value: '50', name:'ZARAGOZA', code: 'Z'}
]
