<div class="error-container" *ngIf='screenWidth < 996 && errorsHaveText'
  [ngClass]="{'scroll' : scrollTop, 'datepickerzone': datepickerzone}">
  <div [ngClass]="{'message-container' : !isError}">
    <span class="banner-icon" *ngIf="!isError">
      <i class="evo ico-warning-white" style="font-size: 28px;"></i>
    </span>
    <span>
      <ul>
        <li *ngFor='let error of errors; let i=index'>
          <p *ngIf='i<3 && error && error.text'>
            {{isError ? " Error: " : ""}}{{error.text}}
          </p>
        </li>
      </ul>
    </span>
  </div>
</div>