import { Injectable, EventEmitter } from '@angular/core';
import { ApiBySideCarService } from './api/api.bysidecar.service';
import { AuthService } from 'app/services/auth.service';
import { ACCOUNTS } from 'app/resources/account.model';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

declare var $;

@Injectable()
export class BySideCarService {
  private activityTimeoutShow = false;
  private inactiveUser = true;
  private activityTimeout = null;
  public isCampanyaCaptacion: boolean = location.href.indexOf('codRecommendation') !== -1;
  public isAltaClienteTipoA: boolean = (this.authService.product === ACCOUNTS.TipoACode);

  public eventShowModal: Subject<any> = new Subject();

  constructor(
    private api: ApiBySideCarService,
    private authService: AuthService
     ) {
  }

  initTimeInactivity(time){
    // console.log( 'initTimeInactivity', time);
    if(!this.isCampanyaCaptacion) {
      this.isCampanyaCaptacion =  location.href.indexOf('codRecommendation') !== -1;
    }
    if(!this.isAltaClienteTipoA) {
      this.isAltaClienteTipoA =  (this.authService.product === ACCOUNTS.TipoACode);
    }
    if (!this.isCampanyaCaptacion && !this.activityTimeoutShow && !this.isAltaClienteTipoA) {
      this.inactiveUser = true;
      this.initActivity(time);
    }
  }

  initActivity(_time) {
    //Tiempo en minutos
    let time = _time;

    time = time * 90000;
    this.activityTimeout = null;

    $(document).unbind("mousemove keypress touchmove").bind("mousemove keypress touchmove",() =>  {
      if (this.activityTimeout) {
        this.inactiveUser = false;
        clearTimeout(this.activityTimeout);
      }
      if (this.activityTimeoutShow === false) {
        this.activityTimeout = setTimeout(() =>  {
          this.inactiveUser = true;
          this.checkTotalActivity();
        }, time);
      }
    });
  }

  checkTotalActivity() {
    if (this.inactiveUser === true) {
      this.activityTimeoutShow = true;
      //Linea que muestra la modal
      this.eventShowModal.next();
    }
  }

  callUser(phone:string, name:string, step:number = 1, type:number): Observable<any> {

    const userInfo = {
      phoneNumber: phone,
      name: name,
      stepId: step,
      type: type
    }
    return this.api.getCalled(userInfo);
  }

  cancelTimeOut(){
    clearTimeout(this.activityTimeout);
  }

}
