<loading [showMe]="loading"></loading>

<mobile-error-zone *ngIf="errService.errorsArray.length > 0" [errors]="[fieldError]" [is-error]="false">
</mobile-error-zone>
<desktop-error-bar-zone *ngIf="errService.errorsArray.length > 0" [errors]="[fieldError]" [is-error]="false">
</desktop-error-bar-zone>

<modal [modalTitle]="'Segundo titular existente'" [blocking]='true' [full-screen]="true" [modalId]='errorSendModal' class='modal'>
  <div class="container"> 
    <h2 style="text-align: center;">¡VAYA!</h2>
    <p> Ya existe un cliente con el email introducido y con el producto a contratar .</p>
    <div class="btn-group modal-accept-button">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="closeErrorSend()">ACEPTAR</button>
    </div>
  </div>
</modal>

<modal [modalTitle]="'Condiciones Legales'" [blocking]='true' [full-screen]="true" [modalId]='modalLegal' class='modal'>
  <div class="container">
    <h2>Aceptación de las condiciones legales</h2>
    <p>Confirmo haber obtenido el consentimiento para que EVO Banco pueda contactar y tratar lo datos personales de la
      persona que facilito en este apartado, con la finalidad de solicitar la contratación de la Cuenta Inteligente
      como segundo titular.</p>
    <div class="btn-group modal-accept-button">
      <button type="button" class="btn-common btn-common_fix_bold" (click)="checkCondiciones()">ACEPTAR</button>
    </div>
  </div>
</modal>

<div class="main-profile" [ngClass]='isBmi && canHaveSecondHolder ? "" : "bottom-margin"'>
  <div class="background-image"></div>
  <div class="content-contain">
    <div class="row">
      <div *ngIf='canHaveSecondHolder' class="container">
        <div class="constrain-big">
          <form (keydown.enter)='$event.preventDefault()' [formGroup]="secondHolderForm" (ngSubmit)="onSubmit()"
            *ngIf='!isSendSecondUser'>
            <h2>{{addSecondHolder}}</h2>
            <p>Indícanos la cuenta de correo del segundo titular para enviarle un correo y que pueda iniciar el proceso.
            </p>
            <div class="row flex">

              <div class="mobile-container div-secondholder">
                <input type="email" #emailSecond name="email" class="input-email" formControlName="email" value=""
                  placeholder="Email del segundo titular" autocomplete="false" maxlength="45" (focus)="inFocus()"
                  (blur)="outFocus(emailSecond)">
                <div class="error-text"
                  *ngIf="secondHolderForm.get('confirmEmail').hasError('notEquals') && secondHolderForm.get('confirmEmail').touched">
                  {{errors.correosNoCoinciden}}
                </div>
                <div class="error-text"
                  *ngIf="secondHolderForm.get('email').hasError('invalid') && secondHolderForm.get('email').touched ||
                  secondHolderForm.get('confirmEmail').hasError('invalid') && secondHolderForm.get('confirmEmail').touched">
                  {{errors.emailValido}}
                </div>
              </div>
              <div class="mobile-container div-secondholder">
                <input type="email" #confirmSecond onCopy='return false'
                  [attr.disabled]="secondHolderForm.get('email').value === '' ? true : null" onPaste='return false'
                  name="confirmEmail" class="input-email" formControlName="confirmEmail" value=""
                  placeholder="Confirma su email" autocomplete="false" maxlength="45" (focus)="inFocus()"
                  (blur)="outFocus(confirmSecond)">
                <div class="error-text"
                  *ngIf="secondHolderForm.get('confirmEmail').hasError('notEquals') && secondHolderForm.get('confirmEmail').touched">
                  {{errors.correosNoCoinciden}}
                </div>
                <div class="error-text"
                  *ngIf="secondHolderForm.get('email').hasError('invalid') && secondHolderForm.get('email').touched ||
                  secondHolderForm.get('confirmEmail').hasError('invalid') && secondHolderForm.get('confirmEmail').touched">
                  {{errors.emailValido}}
                </div>
              </div>

            </div>
            <div class="row conditions">
              <div class="checkbox-group">
                <div class="form-checkbox">
                  <input type="checkbox" name="checkLegal" formControlName="checkLegal" id="check_conditions"
                    (click)='openLegal()' value="">
                  <label for="check_conditions" tabindex="3">He leído y acepto las </label><a class='legal-conditions'
                    (click)='this.secondHolderForm.get("checkLegal").setValue(true); modService.open(modalLegal)'>condiciones
                    legales</a>
                  <div class="error-text" *ngIf="secondHolderForm.get('checkLegal').hasError('notChecked')">
                    {{errors.condicionesLegales}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mobile-container txt-send mobile">
                <button class="btn-common btn-common_fix_bold" type="submit">
                  Añadir segundo titular
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div *ngIf='isSendSecondUser'>
      <h2>SEGUNDO TITULAR ENVIADO</h2>
      <div class="row">
        <p>Has enviado un email a {{secondHolderForm.controls.email.value}} para darle de alta como segundo
          titular.</p>
      </div>
      <div class="row">
        <div class="mobile-container txt-send txt-entendido mobile" [ngClass]='isBmi ? "txt-entendido-bmi" : ""'>
          <button class="btn-common btn-common_fix_bold" (click)="finish()">
            ENTENDIDO
          </button>
        </div>
      </div>
    </div>
  </div>
</div>