import { Component, OnInit, AfterViewInit} from '@angular/core';
import { HelperService } from '../../../services/helpers.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { AuthService } from '../../../services/auth.service';
import { ModalService } from '../../../services/modal.service';

import { Modal } from '../../../collections/modal';
import { Persona } from '../../../collections/persona';
import { ErrorService } from '../../../services/error.service';



@Component({
  selector: 'app-less-25years-redirect',
  templateUrl: './less-25years-redirect.component.html',
  styleUrls: ['./less-25years-redirect.component.scss'],
  providers: [ModalService]

})
export class Less25yearsComponentRedirect implements AfterViewInit {

  loading:boolean = false;
  persona: Persona;
  public modal = new Modal();


  constructor(
    private helper: HelperService,
    public analytics: AnalyticsService,
    private authService: AuthService,
    public modService: ModalService,
    public errService: ErrorService,
  ) {}

  ngOnInit() {
    this.persona = JSON.parse(sessionStorage.getItem("persona"));
  }

  ngAfterViewInit() {
    document.getElementById('user-path').classList.add('hide');
  }


  gotoCJ() {
      this.analytics.triggerInteractionData('¿Menos de 25?', 'Tengo menos de 25');
      
      this.authService.updateProspect(this.persona).subscribe((data) => {
        if (data.response.codigo === 'OK'){
        this.helper.navigateTo('/previa-firma');
        }else{
        this.modal = this.modService.selectStatusModal(data.response);
        this.analytics.triggerFormData('Confirmacion datos DNI', `Envio KO: ${this.modal.message}`);
        this.modService.open(this.modal.name, this.modal.blocking);

        }
      }, error => {
        this.errService.navToError();
      });
  }

}
