import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';
import { ApiRecoveryService } from './api/api.recovery.service';
import { AuthService } from './auth.service';
import { ProductSelection } from '../services/product-selection.service'
import { SpecificAnalyticsService } from '../services/specific-analytics.service';
import { Product } from 'app/collections/product';
import { ACCOUNTS } from 'app/resources/account.model';

@Injectable()
export class RecoveryService {

  constructor(
    private router: Router,
    private authService: AuthService,
    private apiRecoveryService: ApiRecoveryService,
    private product: ProductSelection,
    private specificAnalytics: SpecificAnalyticsService
  ) { }

  recoveryValidation(token: string, clientID: string, isSecondSigner?, signatureId?, codProduct?): Observable<any> {
    const promise = new Promise((resolve, reject) => {
      this.apiRecoveryService.recoverProcess(token, clientID)
        .subscribe(data => {
          this.recoverSignUser(clientID).subscribe(result => {
            this.restoreAnalytics(result);
            resolve(true);
            if (isSecondSigner) {
              this.product.product = Object.assign({
                isSecondSigner,
                signatureId,
                codProductoContratado: codProduct});
              this.navigateToScreen('/previa-firma');
            } else {
              if (result.logalty && result.logalty.codProductoContratado){
                this.product.product = Object.assign({ codProductoContratado: result.logalty.codProductoContratado });
              }else if(this.authService.product){
                this.product.product = Object.assign({ codProductoContratado: this.authService.product, isSecondSigner: false });
              } else{
                this.product.product = Object.assign({ codProductoContratado: ACCOUNTS.CICode });
              }
              
              if(codProduct === ACCOUNTS.STCode) {
                // Si es un flujo de Segundo Titular, se redirige a la ventana anterior.
                if (result.estado === "datos-personal") {
                  this.navigateToScreen('/datos-personales');
                  return;
                }
                else if (result.estado === "datos-contacto") {
                  this.navigateToScreen('/datos-de-contacto');
                  return;
                }
              }
              if(result.estado === "cliente-existente") {
                this.navigateToScreen('/datos-personal'); //
              }
              /*
              else if(result.estado === "datos-contacto"){
                this.navigateToScreen('/datos-personal');
              }
              */
              else {
                this.navigateToScreen(result.estado);
              }
            }
          }, error => {
            reject(false);
          });
        },
        error => {
          reject(false);
        });
    })
    return from(promise);
  }


  recoveryValidationLogin(clientID: string, token: string){

    const promise = new Promise((resolve, reject) => {
      this.apiRecoveryService.recoverProcess(token, clientID)
        .subscribe(data => {
          this.recoverSignUser(clientID).subscribe(result => {
            resolve(true);
          }, error => {
            reject(false);
          });
        },
        error => {
          reject(false);
        });
    })
    return from(promise);

  }

  restoreAnalytics(persona) {
    let registerType = '';
    if(persona.otrosDatos && persona.otrosDatos.tipoIdentificacion) {
      const identification = persona.otrosDatos.tipoIdentificacion = 'identificacion-iban' ? 'iban' : 'video';
      this.specificAnalytics.emitIdentificationType(identification);
    }
    if(persona.datosPersonales) {
      if(persona.datosPersonales.telefonoMovil && !persona.datosPersonales.email) {
          registerType = 'móvil';
        } else if (!persona.datosPersonales.telefonoMovil && persona.datosPersonales.email) {
          registerType = 'correo';
        }
    }
    this.specificAnalytics.emitRegisterType(registerType);
  }

  recoverSignUser(user: string): Observable<any> {
    const promise = new Promise((resolve, reject) => {
      this.authService.getProspect(user).subscribe(result => {
        this.authService.isLoggedIn = true;
        this.authService.setSessionProspect(result);
        resolve(result);
      }, error => {
        reject(reject);
      });
    })
    return from(promise);
  }

  navigateToScreen(stateToNavigate: string) {
    this.router.navigateByUrl(stateToNavigate);
  }

}
