import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common/src/pipes';

@Pipe({
  name: 'currencycustom'
})
export class CurrencyCustomPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    if(value == null) return "";
    value = value.replace(",", "#");
    value = value.replace(".", ",");
    value = value.replace("#", ".");
    value += " €";
    return value;
  }

}
