import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { ELEMENT_PROBE_PROVIDERS } from "@angular/platform-browser/src/dom/debug/ng_probe";

/**
 * Setup inicial.
 * <div helperSteps [msghelper]=":object"></div>
 */
@Directive({
  selector: '[helperSteps]'
})

export class HelperStepsDirective {

  _msghelper:any = {
    current: "1",
    copy: "Coloca la parte delantera de tu DNI frente a la cámara y espera a que se capture la imagen.",
    total: "3"
  };
  stepsContainer: HTMLElement;

  @Input()
  set msghelper(value:any) {
    this._msghelper = value;
    let step = document.getElementById("currentStep");
    let copyStep = document.getElementById("copyStep");
    if (step != null) {
      step.textContent = this._msghelper.current;
      let progressbar = document.getElementById("progressbar");
      this.calculateStepsToShow(progressbar);
    }
    if (copyStep != null) copyStep.textContent = this._msghelper.copy;
  };

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.stepsContainer = this.renderer.createElement('div');
    this.renderer.setAttribute(this.stepsContainer, 'class', 'steps');
  };

  ngOnInit() {
    const directiveElem = this.elementRef.nativeElement;

    /***********************/
    /* Creo los elementos: */
    /***********************/

    //Creo el párrafo superior
    let initParagraph = this.renderer.createElement('p');
    //Seteo el texto superior
    let initialText = this.renderer.createText('Paso ');
    let spanElem = this.renderer.createElement('span');
    spanElem.id = "currentStep";
    let fromQuantity = this.renderer.createText(this._msghelper.current);
    let preposition = this.renderer.createText(' de ');
    let toQuantity = this.renderer.createText(this._msghelper.total);
    //Seteo el texto inferior
    let copyParagraph = this.renderer.createElement('p');
    copyParagraph.id = "copyStep";
    let copyLiteral = this.renderer.createText(this._msghelper.copy);

    /***********************/
    /*   Uno las piezas.   */
    /***********************/
    // Linea1
    this.renderer.appendChild(directiveElem, initParagraph);
    this.renderer.appendChild(initParagraph, initialText);
    this.renderer.appendChild(initParagraph, spanElem);
    this.renderer.appendChild(spanElem, fromQuantity);
    this.renderer.appendChild(initParagraph, preposition);
    this.renderer.appendChild(initParagraph, toQuantity);
    // Linea2
    this.renderer.appendChild(directiveElem, copyParagraph);
    this.renderer.appendChild(copyParagraph, copyLiteral);
    // Linea3
    this.renderer.appendChild(directiveElem, this.stepsContainer);
    // calculo el esilo del step.
    let stepElem = this.renderer.createElement('div');
    stepElem.id = "progressbar";
    this.calculateStepsToShow(stepElem);
  };

  calculateStepsToShow(stepElem) {
    let calculatedWidht: string = 'width:' + 100 * parseInt(this._msghelper.current) / parseInt(this._msghelper.total) + '%';
    this.renderer.setAttribute(stepElem, 'style', calculatedWidht);
    this.renderer.setAttribute(stepElem, 'class', 'step');
    this.renderer.appendChild(this.stepsContainer, stepElem);
  }

}
