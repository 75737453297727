import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../../services/helpers.service';

@Component({
  selector: 'app-select-card',
  templateUrl: './select-card.component.html',
  styleUrls: ['./select-card.component.scss']
})
export class SelectCardComponent implements OnInit {

  public tarjetas;
  public tarjetasBloqueadas: Array<any> = [];
  public tarjetasActivas: Array<any> = [];
  public infoTarjeta: any;
  public indexSelected;
  public tarjetaSeleccionada: Object;
  public indiceTarjetaSeleccionada;
  public totalTarjetasActivas;

  constructor(
    private helpers: HelperService,
  ) { }

  ngOnInit() {
    sessionStorage.removeItem("submit");
    this.tarjetas = JSON.parse(sessionStorage.getItem("userData")).listaTarjetas;
    if(this.tarjetas){
      for (var i = 0; i < this.tarjetas.length; i++) {
        let lastNum = this.tarjetas[i].numTarjeta.trim().substr(-4, 4);
        let limCredito = this.tarjetas[i].limiteCredito;
  
  
        if (this.tarjetas[i].desBloqueo) {
          let nuevaTarjeta = {
            "tarjeta": this.tarjetas[i],
            "digitos": `TARJETA DE CRÉDITO...${lastNum}`,
            "limite": limCredito,
          }
          if (this.tarjetas[i].codigoLinea === "01") {
            if (this.tarjetas[i].codBloqueo === "10" || this.tarjetas[i].codBloqueo === "20" ||
                this.tarjetas[i].codBloqueo === "21" || this.tarjetas[i].codBloqueo === "32" || 
                this.tarjetas[i].codBloqueo === "33" || this.tarjetas[i].codBloqueo === "71" ||
                this.tarjetas[i].codBloqueo === "72" || this.tarjetas[i].codBloqueo === "80") {
                  this.tarjetasBloqueadas.push(nuevaTarjeta);
                }
            else {
              this.tarjetasActivas.push(nuevaTarjeta);
            }
            
          }
          //this.tarjetasBloqueadas.push(this.tarjetas[i].push(this.infoTarjeta));
          //this.tarjetasBloqueadas.push(this.infoTarjeta);
        }
        else {
          let nuevaTarjeta = {
            "tarjeta": this.tarjetas[i],
            "digitos": `TARJETA DE CRÉDITO...${lastNum}`,
            "limite": limCredito === '1'?'0':limCredito
          }
          //El codigoLinea indica el nombre del producto de la tarjeta
          if (this.tarjetas[i].codigoLinea === "01") {
            if (this.tarjetas[i].codBloqueo === "10" || this.tarjetas[i].codBloqueo === "20" ||
                this.tarjetas[i].codBloqueo === "21" || this.tarjetas[i].codBloqueo === "32" || 
                this.tarjetas[i].codBloqueo === "33" || this.tarjetas[i].codBloqueo === "71" ||
                this.tarjetas[i].codBloqueo === "72" || this.tarjetas[i].codBloqueo === "80") {
                  this.tarjetasBloqueadas.push(nuevaTarjeta);
                }
            else {
              this.tarjetasActivas.push(nuevaTarjeta);
            }
          }
          //this.tarjetasActivas.push(nuevaTarjeta);
          //this.tarjetasActivas.push(this.tarjetas[i].push(this.infoTarjeta));
          //this.tarjetasActivas.push(this.infoTarjeta);
        }
      }
    }
    this.setCardSelected();
  }

  botonVolver() {
    
    /* Se guarda la tarjeta seleccionada en la session */
    if (this.tarjetasActivas.length > 0) {
      sessionStorage.setItem("tarjetaSeleccionada", JSON.stringify(this.tarjetasActivas[this.indexSelected].tarjeta));
      sessionStorage.setItem('cambioTarjetasActivo',  'true');
    }
    // ver si guardamos en sesion
    this.helpers.navigateTo('/ampliacion-step1');
  }

  getRadio(value, indice) {
    console.log("VALOR RADIO " + indice + " " + JSON.stringify(value));
    //Si es la tarjeta ya seleccionada no hacer nada??
    //Si cambia de tarjeta seleccionarla y volver
    if (this.indiceTarjetaSeleccionada !== indice){
      this.indexSelected = indice;
      this.indiceTarjetaSeleccionada = indice;
      this.botonVolver();
    }
  }

  /**
   * Se marca la tarjeta seleccionada por defecto al entrar a la pantalla de selección de tarjeta
   */
  setCardSelected(){
    this.tarjetaSeleccionada = JSON.parse(sessionStorage.getItem("tarjetaSeleccionada"));
    this.totalTarjetasActivas = this.tarjetasActivas.length;
    if (this.tarjetaSeleccionada) {
      for(let i = 0; i < this.totalTarjetasActivas; i++){
        if(this.tarjetasActivas[i].tarjeta.numTarjeta === this.tarjetaSeleccionada['numTarjeta']){
          this.indiceTarjetaSeleccionada = i;
          this.indexSelected = this.indiceTarjetaSeleccionada;
        }
      }
    }
  }
}
