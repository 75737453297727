import { Injectable } from '@angular/core';
import { ApiSecurityService } from './api/api.security.service';
import { Observable, Subject } from 'rxjs';
import { LoginService } from './login.service';
import { DeviceService } from './device.service';

@Injectable()
export class SecurityService {

    constructor(
        private api: ApiSecurityService,
        private deviceSrv: DeviceService
    ) { }

    getPublicIP(): Observable<any> {
        return this.api.getPublicIP();
    }

}
