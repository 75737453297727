<app-header></app-header>
<loading [showMe]="loading && logaltyLoaded"></loading>
<logalty-load *ngIf="!logaltyLoaded  && isYoung"></logalty-load>
<logalty-load-slider *ngIf="!logaltyLoaded && !isYoung"></logalty-load-slider>
<div class="logalty" [hidden]="!logaltyLoaded">
  <div class="image"></div>
  <form action="{{logaltySamlAction}}" method="POST" target="_self">
    <input type="hidden" name="saml_assertion" />
  </form>
</div>
